//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters} from 'vuex';
import {INFO, CHAT, CONTACTS} from '../../store/modulesNames';
import {
    ACT_INFO_PUSH,
    ACT_OPEN_CHAT,
    ACT_LOAD_BOT_CONTACT
} from '../../store/actionsTypes';
import {GET_MERGED_CONTACT_BY_ID} from '../../store/gettersTypes';
import checkMessageDataExist from "./checkMessageDataExist"
import ChatMessageMixin from "./chat-message-mixin"

const maxCharsWithShortMessage = 1000

export default {
    name: "reference-msg",
    props:['message', 'textMsg', 'textEntities', 'modal'],
    mixins: [checkMessageDataExist, ChatMessageMixin],
    data: function () {
        return {
            displayStyle: 'block',
            shortMsg: true,
            animation: this.withAnimation,
            maxHeight: 0,
            withText: false,
            maxCharsWithShortMessage: 1000,
        }
    },
    computed: {
        textContinue() {
            return this.shortMsg && this.textMsg.length > maxCharsWithShortMessage;
        },
        text() {
            let formattedText = this.textMsg
            
            formattedText = formattedText.replace(/</g,'\x7F').replace(/>/g,'\x8F')
            
            if (this.textEntities) {
                formattedText = this.messageTextFormat(this.textEntities, formattedText)
            }
            else {
                let { resultedText } = this.convertOldRefsToNewFormat(formattedText, true)
                formattedText = this.lookForLinks(resultedText)
            }
            formattedText = this.wrapEmoji(formattedText)
            formattedText = fixATags(formattedText)
            if (this.textContinue) {
                let parser = new DOMParser()
                let dom = parser.parseFromString(formattedText, 'text/html')
                formattedText = this.getSlicedText(dom) + '<span>...</span>'
            }
            formattedText = formattedText.replace(/\x7F/g,'&lt;').replace(/\x8F/g,'&gt;')
            return formattedText
        },
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID])
    },
    methods: {
        async action(ref) {
            if(ref.params === false) return;
            if(this.modal) this.$emit('close')
            switch (ref.type) {
                case 'botCommand':
                    this.$parent.$emit("printbotcommand", ref.params[0])
                    //console.log("!! -> file: ReferenceMsg.vue -> line 59 -> action -> ref.params", ref.params[0])
                    break
                case 'bot':
                    await this[ACT_LOAD_BOT_CONTACT](ref.params[0])
                    let botContact = this[GET_MERGED_CONTACT_BY_ID](ref.params[0])
                    if(botContact.cid) {
                        this.$store.dispatch('chats/update');
                        this.$store.dispatch('chats/open', {cid: ref.params, cidType: 'user'});
                    } else {
                        this.modalOpen({
                            name: 'alert',
                            props: {
                                title: this.$t('errors.error'),
                                text: this.$t('chat.bot-not-exist'),
                            }
                        })
                    }
                    break;
                case 'channel':
                    this.checkChanelExist(ref.params[0])
                    break
                case 'publication':
                    this.checkPublicationExist(ref.params[0], ref.params[1])
                    break
                default:
                    if (ref.params !== false) this.$store.dispatch(`${CHAT}/${ACT_OPEN_CHAT}`, {cid: +ref.params, cidType: 'user'});
                    if(ref.params) this[ACT_INFO_PUSH]({type: 'contact-info', params: {cid: +ref.params}});
            }
        },
        makeContextMenu(e) {
            let { event, title, cid, chId, postId } = e.detail
            //console.log("!! 0000 -> file: ReferenceMsg.vue -> line 80 -> makeContextMenu -> e.detail", e.detail)
            let handlers = []
            let data = ''
            if (cid) {
                data = `@[${title}][contact:${cid}]`
                handlers.push({item_name: this.$t('channel-comp.open-profile'), 
                    handler: () => { this[ACT_INFO_PUSH]({type: 'contact-info', params: {cid}}) }
                })
                handlers.push({item_name: this.$t('channel-comp.copy'), handler: this.copy, data} )
            }
            if (chId && !postId) {
                data = `@[${title}][channel:${chId}]`
                handlers.push({item_name: this.$t('channel'), 
                    handler: () => { this.checkChanelExist(chId) }
                })
                handlers.push({item_name: this.$t('channel-comp.copy'), handler: this.copy, data})
            }
            if (chId && postId) {
                data = `@[${title}][publication:${chId}:${postId}]`
                handlers.push({item_name: this.$t('publication'), 
                    handler: () => { this.checkPublicationExist(chId, postId) }
                })
                handlers.push({item_name: this.$t('channel-comp.copy'), handler: this.copy, data})
            }
            this.cmOpen(event, handlers)
        },
        performClick(e, type) {
            let details = e.detail, chId
            let ref = { type, params: []}
            switch (type) {
                case 'contact':
                    let { contactId } = details
                    ref.params.push(contactId)
                    break;
                case 'bot':
                    let { botId } = details
                    ref.params.push(botId)
                    break;
                case 'botCommand':
                    let { command } = details
                    ref.params.push(command)
                    break;                
                case 'channel':
                    chId = details.chId
                    ref.params.push(chId)
                    break;
                case 'publication':
                    chId = details.channelId
                    let postId = details.postId
                    ref.params.push(chId)
                    ref.params.push(postId)
                    break;
                default:
                    break;
            }
            this.action(ref)
        },
        doContextMenu(e, ref) {
            if(ref.params) {
                e.preventDefault()
                e.stopPropagation()
                let handlers = []
                let data
                let tagsRE = /(\<(\/?[^>]+)>)/g
                if(e.target.className === 'e-mail') data = e.target.innerText;
                else if(tagsRE.test(ref.text)) data = ref.text.replace(tagsRE, '')
                else data = `@[${ref.text}][contact:${ref.params}]` // `@[${ref.params}:${ref.text}]`
                handlers.push({item_name: this.$t('channel-comp.open-profile'), 
                    handler: () => { this[ACT_INFO_PUSH]({type: 'contact-info', params: {cid: +ref.params}});}
                })
                handlers.push({item_name: this.$t('channel-comp.copy'), handler: this.copy, data})
                this.cmOpen(event, handlers);
            }
        },
        copy(text) {
            this.$copyText(text);
        },
        checkAuthor(message = null) {
            let isAuthor = false
            if (message && message.hasOwnProperty('author')) {
                if (message.author !== undefined) isAuthor = true
            }
            return isAuthor
        },
        fioById(cid) {
            return this[GET_MERGED_CONTACT_BY_ID](cid).fio || null
        },
        ...mapActions(CONTACTS, [ACT_LOAD_BOT_CONTACT]),
        ...mapActions(INFO, [ACT_INFO_PUSH]),
    }
}

const fixATags = (str) => {
    const isATags = str.match(/<a(.*?)<\/a>/)
    if (!isATags) return str
    let outStr = str.replace('\n','\\\\CR')
    const aTags = outStr.match(/<a(.*?)<\/a>/g)
    const imgTagsRE = /<img\s+[^>]*src="([^"]*)"[^>]*><\/img>/g 
    
    aTags.forEach((aTag) =>{
        let substATag = aTag.replace(imgTagsRE, '')
        outStr = outStr.replace(aTag, substATag)
    })
    return outStr.replace('\\\\CR','\n')
}
