export const formatPhoneNumber = number => {
    if (number[0] === '+') {
        number = number.replace(/\+79([0-9]{2})([0-9]{2})/, '+7(9$1)$2');
        number = number.replace(/(\+7\(9[0-9]{2}\)[0-9]{3})/, '$1-');
        number = number.replace(/(\+7\(9[0-9]{2}\)[0-9]{3}\-[0-9]{2})/, '$1-');
    } else {
        number = number.replace(/89([0-9]{2})([0-9]{2})/, '8(9$1)$2');
        number = number.replace(/(8\(9[0-9]{2}\)[0-9]{3})/, '$1-');
        number = number.replace(/(8\(9[0-9]{2}\)[0-9]{3}\-[0-9]{2})/, '$1-');
    }
    return number
}