//
//
//
//
//
//

import PublicationAudioItem from "./PublicationAudioItem.vue"
import moment from 'moment'

export default {
    name: "PublicationAudioItemSearch",
    components: {
        PublicationAudioItem
    },
    props: {
        message: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
        }
    },
    computed: {
        pubId() {
            return this.message.pubId
        },
        audioItems() {
            this.message.msg.forEach(m => {
                if (m.file.indexOf(declarations.http_post_dirs.CHANNEL_DATA_DIR) === -1)
                    m.file = declarations.http_post_dirs.CHANNEL_DATA_DIR + m.file
            })
            return this.message.msg
        },      
    },
    methods: {
        unixTime() {
            return moment().unix()
        }
    }
}
