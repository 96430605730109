
import Confirm from "./Confirm.vue"

export default {
    name: "ContinueWithoutCamera",
    extends: Confirm,
    data() {
        return {
            compModalProps: {
                width: 480
            }
        }
    },
    computed: {
        text() {
            return this.$t('continue-without-camera')
        },
    },

}
