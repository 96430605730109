
import {mapActions} from 'vuex'

import SelectContact from './SelectContact.vue'
import {CONTACTS_SEARCH_TYPES} from '../../constants'
import {CONTACTS} from '../../store/modulesNames'
import {ACT_SELECT_CONTACT} from '../../store/actionsTypes'

export default {
    name: "SelectContactToPrivate",
    extends: SelectContact,
    data() {
        return {
            searchType: CONTACTS_SEARCH_TYPES.SEND_MESSAGE,
        }
    },
    computed: {
        type: () => null
    },
    methods: {
        onOk(contact) {
            this[ACT_SELECT_CONTACT](contact.cid)
            this.modalClose()
        },
        ...mapActions(CONTACTS, [ACT_SELECT_CONTACT]),
    }
}
