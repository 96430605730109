
import { PHONE_TYPES } from "../../../constants"

import ActivityBase from "./ActivityBase.vue"

import CallAssistant from './CallAssistant.vue'
import CallConference from './CallConference.vue'
import CallWebrtc from "./CallWebrtc.vue"

export default {
    name: "CallActivity",
    extends: ActivityBase,
    props: {
        call: {
            type: Object,
            required: true,
        }
    },
    computed: {
        component() {
            switch (this.type) {
                case PHONE_TYPES.ASSISTANT:
                    return CallAssistant
                case PHONE_TYPES.CONFERENCE:
                    return CallConference
                case PHONE_TYPES.PHONE:
                    return CallWebrtc
            }
        },
        bindProps() {
            return {
                call: this.call,
                type: this.type,
                id: this.id,
            }
        },
        type() {
            return this.call.type
        },
        id() {
            return this.call.id
        },
    }
}
