var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "switch", style: _vm.css_props }, [
    _vm.readonly
      ? _c("input", {
          attrs: { type: "checkbox", disabled: "readonly" },
          domProps: { checked: _vm.def }
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.checked,
              expression: "checked"
            }
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.checked)
              ? _vm._i(_vm.checked, null) > -1
              : _vm.checked
          },
          on: {
            change: function($event) {
              var $$a = _vm.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.checked = $$c
              }
            }
          }
        }),
    _c("span", { staticClass: "checkbox-slider round" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }