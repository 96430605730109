//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { 
    INFO, CHATS, 
    CHAT, USERDATA 
} from '../../store/modulesNames'
import {
    GET_UID,
    GET_CHATS,
    GET_CHAT_ITEM_TEXT,
    GET_INFO_PAYLOAD_BY_MAIN_TYPE,
} from '../../store/gettersTypes'

import ActionsContainer from "./ActionsContainer.vue"
import ChatItem from "../listItems/ChatItem.vue"
let $t

export default {
    name: "CommonChats",
    components: {
        ActionsContainer,
        ChatItem,
    },
    data() {
        return {
        }
    },
    created() {
        $t = this.$t
    },
    computed: {
        myCid() { return this.$store.getters[`${USERDATA}/${GET_UID}`] },
        chats () {
            const chats = this[GET_CHATS]
            let sortedGroupChats = chats.sort(utils.chatAndChannelSortFn).filter(ch => ch.cid && ch.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP)
            const res = sortedGroupChats.map((item) => {
                const infoPayload = this[GET_INFO_PAYLOAD_BY_MAIN_TYPE]
                const contactCid = infoPayload.params && infoPayload.params.cid
                const chat_data = this.$store.getters['chats/getChat']({cid: item.cid, cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP}) || {}
                const chat_members = chat_data.contacts
                const isContactInMembers = chat_members.some(cm => cm.hasOwnProperty('cid') && cm.cid === contactCid)
                if (isContactInMembers) return getChatItem(item)
            })
            return res.filter(chat => chat)
        },
        ...mapGetters(CHATS, [GET_CHATS]),
        ...mapGetters(INFO, [GET_INFO_PAYLOAD_BY_MAIN_TYPE]),
    },
}

function getChatItem(chat) {
    const item = {
        photo: app.getGroupPhotoUrl(chat.cid),
        cid: chat.cid,
        cidType: chat.cidType || declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER,
        lastMessageId: chat.lastMessage && chat.lastMessage.id || 0,
        time: date_helper.unixTimeToTimeOrDateFormat(chat.lastMessage.unixTime),
        text: app.store.getters[`${CHAT}/${GET_CHAT_ITEM_TEXT}`]({message: chat.lastMessage, list: true}),
        senderId: chat.lastMessage.senderId,
        name: chat.name || $t['search-comp']['group-chat'],
    }
    if (chat.cid === 0) {
        item.name = chat.name
        item.photo = chat.photo
    }
    return item
}
