//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'system-notification',
    props: ['show', 'title', 'text'],
    methods: {
        close () {
            this.$emit('close')
        }
    }
}
