
import CustomSwitchItem from '../custom/CustomSwitchItem.vue'

export default {
    name: 'PinSwitch',
    extends: CustomSwitchItem,
    computed: {
        // *** Переопределить в дочернем компоненте start *** //
        switchValue() {},
        // *** Переопределить в дочернем компоненте end *** //
        title() { return this.$t('pinned') },
    },
    methods: {
        // *** Переопределить в дочернем компоненте start *** //
        pin() {},
        unpin() {},
        // *** Переопределить в дочернем компоненте end *** //
        onClick() {
            if (this.switchValue) {
                this.unpin()
            } else {
                this.pin()
            }
        },
    },
}
