/**
 * Created by Aleksey on 07.06.2018.
 */
'use strict';

import { i18n } from '../ext/i18n'
let locale = i18n.messages[i18n.locale]

export default class YandexMapsWorker {
    constructor() {
        var self = this;

        var YANDEX_MAPS_IMAGE_URL = 'https://static-maps.yandex.ru/1.x/';

        var YANDEX_MAPS_LINK_URL = 'https://maps.yandex.ru/';

        var DEFAULT_GEODATA_IMAGE_DATA = {
            zoom: 17,
            width: 300,
            height: 200,
            lang: 'ru_RU'
        };

        var DEFAULT_GEODATA_LINK_DATA = {
            zoom: 17,
            lang: 'ru_RU'
        };

        self.init = function() {}

        /**

         * @param {Object} params
         * @param  {Number|String} params.longitude
         * @param {Number|String} params.latitude
         * @param {Number|String} params.zoom
         * @param {Number|String} params.width
         * @param {Number|String} params.height
         * @param {String} params.lang
         */
        self.getLocationImageUrl = function(params) {
            var url = YANDEX_MAPS_IMAGE_URL;
            params = Object.assign({}, DEFAULT_GEODATA_IMAGE_DATA, params);
            url+='?ll=' + params.longitude + ',' + params.latitude;
            url+= '&pt=' + params.longitude + ',' + params.latitude + ',pm2rdm';
            url+= '&size=' + params.width + ',' + params.height;
            url+= '&z=' + params.zoom;
            url+= '&lang=' + params.lang;
            url+= '&l=map';
            return url;
        };

        /**
         * Получить текст ссылки на локацию в yandex картах
         * @param {Object} params
         * @param  {Number|String} params.longitude
         * @param {Number|String} params.latitude
         * @param {Number|String} params.zoom
         * @param {String} params.lang
         */
        self.getLocationLinkUrl = function(params) {
            var url = YANDEX_MAPS_LINK_URL;
            params = Object.assign({}, DEFAULT_GEODATA_LINK_DATA, params);
            url+= '?ll=' + params.longitude + ',' + params.latitude;
            url+= '&pt=' + params.longitude + ',' + params.latitude;
            url+= '&z=' + params.zoom;
            url+= '&lang=' + params.lang;
            url+= '&l=map';
            return url;
        };
    }
};
