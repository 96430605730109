var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title
      }
    },
    [
      _c(
        "template",
        { slot: "default" },
        [
          _c("custom-search", {
            staticClass: "search-chat",
            attrs: { search: _vm.search, focus: true },
            on: {
              "update:search": function($event) {
                _vm.search = $event
              }
            }
          }),
          _c("selected-items-line", {
            attrs: { selected: _vm.selectedItemsLine },
            on: { remove: _vm.unselectChat }
          }),
          _vm.contactSearch
            ? _c("select-contact-body", {
                attrs: {
                  multiple: true,
                  groups: _vm.groups,
                  filter: _vm.search,
                  selectedDefault: _vm.contactsSelected,
                  selecter: false,
                  unselectedContact: _vm.unselectedContact,
                  searchType: _vm.searchType
                },
                on: {
                  selected: _vm.onContactSelected,
                  unselected: _vm.onContactUnselected
                }
              })
            : _c("div", { staticClass: "select-chat" }, [
                _c(
                  "div",
                  { staticClass: "chats-list" },
                  _vm._l(_vm.chats, function(chat) {
                    return _c(
                      "div",
                      {
                        staticClass: "chat-item",
                        on: {
                          click: function($event) {
                            return _vm.selectChat(chat)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "chat-item-wrapper" },
                          [
                            _c("icon-image", {
                              attrs: {
                                src: chat.photo,
                                text: chat.name,
                                marked: _vm.selected.includes(chat)
                              }
                            }),
                            _c("div", { staticClass: "fio-block" }, [
                              _c("div", { staticClass: "fio name" }, [
                                chat.cidType === "group"
                                  ? _c("i", {
                                      staticClass: "item-icon fa fa-users"
                                    })
                                  : chat.cidType === "public"
                                  ? _c("i", {
                                      staticClass: "item-icon far fa-bullhorn"
                                    })
                                  : _vm._e(),
                                _c("span", [_vm._v(_vm._s(chat.name))])
                              ])
                            ])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "hor-line" })
                      ]
                    )
                  }),
                  0
                )
              ])
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.cancelText },
            on: { click: _vm.modalClose }
          }),
          _vm.multiple
            ? _c("modal-btn", {
                attrs: { text: _vm.okText, disabled: !_vm.selected.length },
                on: {
                  click: function($event) {
                    return _vm.onOk(_vm.selected)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }