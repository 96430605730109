var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-section-default" }, [
    _c("div", { staticClass: "left-section" }, [
      _c(
        "div",
        { staticClass: "info sec-btn" },
        [
          _c("conf-btn", {
            attrs: { imageClasses: "fal fa-info-circle" },
            on: { click: _vm.toggleInfo }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.btsShow,
              expression: "btsShow"
            }
          ],
          staticClass: "mute-all sec-btn"
        },
        [
          _c("conf-btn", {
            attrs: {
              imageClasses:
                "fal " + (_vm.speakerIsOn ? "fa-volume" : "fa-volume-mute"),
              disabled: _vm.noSpeakerDevice
            },
            on: { click: _vm.speakerToggle }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.btsShow,
              expression: "btsShow"
            }
          ],
          staticClass: "view-scheme sec-btn"
        },
        [
          _c("conf-btn", {
            attrs: {
              imageClasses: _vm.schemeIconClasses,
              color: _vm.schema === "share" ? "red" : undefined
            },
            on: { click: _vm.toggleSchemeSelect }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "central-section" }),
    _c("div", { staticClass: "right-section" }, [
      !_vm.videoConfFullScreen
        ? _c(
            "div",
            { staticClass: "minimize-screen sec-btn" },
            [
              _c("conf-btn", {
                staticClass: "exit-btn",
                attrs: { imageClasses: "fal fa-chevron-down" },
                on: { click: _vm.toggleMinimize }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "full-screen sec-btn" },
        [
          _c("conf-btn", {
            staticClass: "exit-btn",
            attrs: {
              imageClasses:
                "fal fa-" + (_vm.videoConfFullScreen ? "compress" : "expand")
            },
            on: { click: _vm.doFullScreen }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "exit sec-btn" },
        [
          _c("conf-btn", {
            staticClass: "exit-btn",
            attrs: { imageClasses: "fal fa-sign-out-alt" },
            on: {
              click: function($event) {
                return _vm.exitOpen($event)
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }