//
//
//
//
//
//
//

import MainContentContainer from "../main/body/MainContentContainer.vue"
import Calendar from "./Calendar.vue"

export default {
    name: "MeetingCalendar",
    components: { MainContentContainer, Calendar },
}
