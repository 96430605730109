var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title
      }
    },
    [
      _c("template", { slot: "default" }, [
        _c(
          "div",
          { staticClass: "wrapper" },
          [
            _c("span", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.$t("chat-read-only-change-desc")))
            ]),
            _c("custom-radio", {
              staticClass: "custom-radio",
              attrs: {
                value_change: _vm.readOnly,
                inputs: _vm.inputs,
                default_data: _vm.readOnly
              },
              on: {
                "update:value_change": function($event) {
                  _vm.readOnly = $event
                }
              }
            })
          ],
          1
        )
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "btn poll-button-cancel", on: { click: _vm.close } },
          [_vm._v(_vm._s(_vm.$t("cancel")))]
        ),
        _c(
          "div",
          {
            staticClass: "btn poll-button-add",
            on: { click: _vm.doChangeSettings }
          },
          [_vm._v(_vm._s(_vm.$t("apply")))]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }