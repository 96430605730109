//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        digits: {
            type: String,
            default: ''
        },
        syncMode: {
            type: Boolean,
            default: false
        },
    },
    name: 'phone-dialer',
    data() {
        return {
            input: '',
            fontSizeInput: 34,
            //opacity: false,
            transition: false,
            rows: [
                ['1','2','3'],
                ['4','5','6'],
                ['7','8','9'],
                ['*','0','#'],
            ]
        };
    },
    directives: {
        focus: {
            inserted: function (el) {
                let input = el.querySelector('input');
                if (input) return setTimeout(() => input.focus(), 200);
                setTimeout(() => el.focus(), 200);
            }
        }
    },
    mounted() {
        if (this.digits) {
            if (!this.syncMode) this.input = `+`
            this.input += `${this.digits}`
        }
    },
    watch: {
        input() {

            if(this.input.slice(-1).search(/['0-9',\*,'#']/) === -1) {
                this.input = this.input.slice(0, -1);
                return;
            }
            if (!this.syncMode && this.input.length === 1 && this.input[0] !== '+') this.input = `+${this.input}`
            if(this.input.length > 13 && this.input.length < 16) {
                this.fontSizeInput = 28;
            } else if(this.input.length > 16 && this.input.length < 19) {
                this.fontSizeInput = 24;
            } else if(this.input.length > 19) {
                let str1 = this.input.slice(0, 8);
                let str2 = this.input.slice(this.input.length - 9, this.input.length);
                this.input = str1 + ' ... ' + str2;
            }
        },
        digits(val) {
            if (val && this.syncMode) this.input = '' + val
        }
    },
    methods: {
        keyPress(value) {
            console.log(value)
            if (!this.syncMode) this.input += value;
            this.$emit('pressDialKey', { digits: value })
        },
        focus(e) {
            e.target.focus();
        }
    }
}
