//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "CustomSearch",
    props: {
        filter: {
            type: Boolean,
            default: false
        },
        focus: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: ''
        },
        isReadOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
      return {
          search: '',
          readonly: true
      }
    },
    computed: {
        placeholderString() {
            return this.placeholder && this.placeholder.length ? this.placeholder : this.$t('search')
        },
        plStyle() {
            if (typeof InstallTrigger !== 'undefined') return "padding: 10px 34px;"  // Firefox check
        },
    },
    methods: {
        checkReadOnly() {
            let ret = false
            if (this.isReadOnly) ret = true
            else ret = this.readonly
            return ret
        },
        // onClick(e) {
        //     e.preventDefault()
        //     e.stopPropagation()
        //     console.log("🚀 ~ file: CustomSearch.vue:70 ~ onClick ~ e", e)
        //     this.$emit('clicked')
        //     console.log("🚀 ~ file: CustomSearch.vue:74 ~ onClick ~ 'clicked'", 'clicked')
        // },
        onFocus() {
            if (this.isReadOnly) this.readonly = true
            else this.readonly = false
        },
        onKeyDown() {
            this.$emit('keyDown')
        },
        onKeyUp() {
            this.$emit('keyUp')
        },
        onEnter(e) {
            e.preventDefault();
            this.$emit('enter')
        },
        onFilter() {
            this.$emit('filter')
        },
        clearSearch() {
            this.search = ''
        }
    },
    watch: {
        search(val) {
            this.$emit('update:search', val)
        }
    },
    mounted () {
        this.focus && this.$el.querySelector('input').focus()
    }
}
