//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex';
import {GET_ALARM_PROPS} from '../store/gettersTypes';
import {ALARM} from "../store/modulesNames";
export default {
    name: 'alarm',
    data() {
        return {}
    },
    computed: {
        ...mapGetters(ALARM, [GET_ALARM_PROPS]),
        alarm_type() {
            return this[GET_ALARM_PROPS].type;
        },
        alarm_msg() {
            return this[GET_ALARM_PROPS].msg;
        }
    }
}
