import VideoPlug from '../../common/VideoPlug'
import AudioPlug from '../../common/AudioPlug'

const CAM_WIDTH = 640
const CAM_HEIGHT = 480
const CAM_FRAMES = 15

const DEFAULT_VIDEO_STREAM_OPTIONS = Object.freeze({
    width: {ideal: CAM_WIDTH},
    height: {ideal: CAM_HEIGHT},
    frameRate: {ideal: CAM_FRAMES},
})

const SHARING_WIDTH = 1920
const SHARING_HEIGHT = 1080
const SHARING_FRAMES = 10

const DEFAULT_SHARING_OPTIONS = Object.freeze({
    width: SHARING_WIDTH,
    height: SHARING_HEIGHT,
    frameRate: {ideal: SHARING_FRAMES},
})

class WebRTCClient {
    constructor ({
                     videoPlugText = 'Roschat WebRTCClient',
                     audioDummy = true,
                     volume = 100,
                     localEl = null,
                     sharingEl = null,
                     video = false
                 }) {
        this.video = video
        this.localEl = localEl
        this.sharingEl = sharingEl
        this.remoteId = 0
        this.remoteEls = new Map()
        this.remoteStreams = new Map()
        this.videoPlugText = videoPlugText
        this.muted = false
        this.mutedVideo = false
        this.mutedSpeaker = false
        this.isHold = false
        this.audioDummy = audioDummy
        this.videoPlug = null
        this.audioPlug = null
        this.localStream = null
        this.localSharingStream = null
        this.sharingStream = null
        this.volume = volume
    }

    setLocalEl(el) {
        this.localEl = el
        if (this.localStream) this.setLocalStream(this.localStream)
    }

    setSharingEl(el) {
        this.sharingEl = el
        if (this.sharingStream) this.setSharingStream(this.sharingStream)
    }

    addRemoteEl(el, id) {
        if (!id) id = this.remoteId++
        this.remoteEls.set(id, el)
        return id
    }

    removeRemoteEl(id) {
        if (id) {
            this.remoteEls.delete(id)
            this.remoteStreams.delete(id)
        }
    }

    getLocalStream() {
        return this.localStream
    }

    getRemoteStream(id = 0) {
        return this.remoteStreams.get(id)
    }

    getRemoteEl(id = 0) {
        return this.remoteEls.get(id)
    }

    setLocalStream(stream) {
        let localVideo = this.localEl
        if (stream) {
            if ('srcObject' in localVideo) localVideo.srcObject = stream // Older browsers may not have srcObject
            else localVideo.src = window.URL.createObjectURL(stream) // Avoid using this in new browsers, as it is going away.
            this.localStream = stream
            localVideo.onloadedmetadata = () => localVideo.play()
            if (this.videoPlug) localVideo.style.transform = 'none'
        } else {
            localVideo.src = ''
            if (this.localStream) this.localStream.stop()
            localVideo.style.removeProperty('transform')
        }
    }

    setRemoteStream(stream, id) {
        let remoteVideo = this.getRemoteEl(id)
        if (!remoteVideo) return
        if (stream) {
            this._streamSpeakerEnabledApply(stream)
            if ('srcObject' in remoteVideo) remoteVideo.srcObject = stream // Older browsers may not have srcObject
            else remoteVideo.src = window.URL.createObjectURL(stream) // Avoid using this in new browsers, as it is going away.
            this._setRemoteStream(id, stream)
            remoteVideo.onloadedmetadata = () =>  remoteVideo.play()
        } else remoteVideo.src = ''
    }

    async startLocalSharing(options) {
        let stream
        if (navigator.getDisplayMedia) {
            stream = await navigator.getDisplayMedia({video: {...DEFAULT_SHARING_OPTIONS, ...options?.video}})
        } else {
            stream = await navigator.mediaDevices.getDisplayMedia({video: {...DEFAULT_SHARING_OPTIONS, ...options?.video}})
        }
        this.setLocalSharingStream(stream)

        return stream
    }

    setLocalSharingStream(stream) {
        if (stream) {
            this.localSharingStream = stream
            if(stream && !stream.stop && stream.getTracks) {
                stream.stop = () => stream.getTracks().forEach((track) => track.stop())
            }
        } else {
            if (this.localSharingStream) this.localSharingStream.stop()
        }
    }

    setSharingStream(stream) {
        let sharingEl = this.sharingEl
        if (!sharingEl) return
        if (stream) {
            if ('srcObject' in sharingEl) sharingEl.srcObject = stream // Older browsers may not have srcObject
            else sharingEl.src = window.URL.createObjectURL(stream) // Avoid using this in new browsers, as it is going away.
            this.sharingStream = stream
            sharingEl.onloadedmetadata = () =>  sharingEl.play()
        } else sharingEl.src = ''
    }

    _setRemoteStream(id = 0, stream) {
        this.remoteStreams.set(id, stream)
    }

    _remoteElsBroadcast(fn) {
        this.remoteEls.forEach((el, id) => fn.apply(this, [el, id]))
    }

    _remoteStreamsBroadcast(fn) {
        this.remoteStreams.forEach((stream, id) => fn.apply(this, [stream, id]))
    }

    updateStreams() {
        setTimeout(()=> {
            this.setLocalStream(this.localStream)
            this._remoteStreamsBroadcast((stream, i) => this.setRemoteStream(stream, i))
        }, 0);
    }

    startLocal(el, options) {
        if (el) this.setLocalEl(el)
        return new Promise(async (resolve, reject) => {
            let stream
            try {
                //stream = await navigator.mediaDevices.getDisplayMedia({video: {width: 1920, height: 1080}})

                stream = await this.getAudioStream({...{audio: options.audio}})
                if (options.video || this.video) {
                    let videoStream = await this.getVideoStream({...{video: options.video}})
                    stream = new MediaStream([...stream.getTracks(), ...videoStream.getTracks()])
                }
            } catch (e) {
                console.log(e)
                console.log('rtc', 'MediaWorker.startLocal: getUserMedia() error: ' + e.message);
                reject(e)
            }
            if(stream && !stream.stop && stream.getTracks) {
                stream.stop = () => stream.getTracks().forEach((track) => track.stop())
            }
            this.setLocalStream(stream)
            resolve([])
        })
    }

    async getAudioStream(options) {
        let audioStream
        try {
            audioStream = await navigator.mediaDevices.getUserMedia(options)
        } catch (e) {
            if (this.audioDummy) {
                this.audioPlug = new AudioPlug()
                audioStream = this.audioPlug.start()
            } else {
                throw e
            }
        }
        return audioStream
    }

    async getVideoStream(options) {
        let videoStream
        try {
            if (options.video) options.video = { ...DEFAULT_VIDEO_STREAM_OPTIONS, ...options.video }
            videoStream = await navigator.mediaDevices.getUserMedia(options)
        } catch (e) {
            this.videoPlug = new VideoPlug({text: this.videoPlugText, width: CAM_WIDTH, height: CAM_HEIGHT, frames: CAM_FRAMES })
            videoStream = this.videoPlug.start()
        }
        return videoStream
    }

    stop() {
        this.setLocalStream(null)
        this.setLocalSharingStream(null)
        this._remoteStreamsBroadcast((stream, id) => this.setRemoteStream(null, id))
        if (this.videoPlug) this.videoPlug.stop()
        if (this.audioPlug) this.audioPlug.stop()
    }

    mute() {
        if (!this.localStream) return
        let enabled = true
        if (this.localStream && this.localStream.getAudioTracks) {
            enabled = !this.localStream.getAudioTracks()[0].enabled
            this.localStream.getAudioTracks()[0].enabled = enabled
        } else {
            console.log('!! localStream getAudioTracks error')
        }
        this.muted = !enabled
        return this.muted
    }

    muteVideo() {
        if (!this.localStream) return
        let enabled = true
        if (this.localStream && this.localStream.getVideoTracks) {
            enabled = !this.localStream.getVideoTracks()[0].enabled
            this.localStream.getVideoTracks()[0].enabled = enabled
        } else {
            console.log('!! localStream getVideoTracks error')
        }
        this.mutedVideo = !enabled
        return this.mutedVideo
    }

    muteSpeaker() {
        this.mutedSpeaker = !this.mutedSpeaker
        this._remoteStreamsBroadcast(this._streamSpeakerEnabledApply)
        return this.mutedSpeaker
    }

    _streamSpeakerEnabledApply(stream) {
        let enabled = !this.mutedSpeaker
        stream.getAudioTracks().forEach((track) => {
            if (track.enabled !== enabled) track.enabled = enabled
        })
    }

    hold(value, isVideo) {
        if (!this.localStream) return
        if (!this.muted) this.localStream.getAudioTracks()[0].enabled = !value
        if (isVideo) this.localStream.getVideoTracks()[0].enabled = !value
        this._remoteStreamsBroadcast((stream, id) => {
            stream.getAudioTracks()[0].enabled = !value
            if(isVideo) stream.getVideoTracks()[0].enabled = !value
        })
        this.isHold = value
    }

    getIsHold() {
        return this.isHold
    }

    changeSpeakerVolume(data) {
        this.volume = data
        //if (this.localEl) this.localEl.volume = data / 100
        this._remoteElsBroadcast((el) => el.volume = data / 100)
    }

    // Важно! Останавливать и удалять трек тут нельзя, если мы хотим бесшовно сменить устройство
    // Правельная последовательеость:
    // 1. MediaWorker.replaceLocalAudioDevice
    // 2. PeerConnection.replaceTrack
    // 3. MediaWorker.removeLocalTrack
    async replaceLocalAudioDevice(id) {
        if (this.localStream) {
            let stream = await navigator.mediaDevices.getUserMedia({audio: {deviceId: { exact: id }}})
            let newTrack = stream.getAudioTracks()[0]
            let oldTrack = this.localStream.getAudioTracks()[0]
            this.localStream.addTrack(newTrack)
            return { oldTrack, newTrack }
        }
    }

    // Важно! Останавливать и удалять трек тут нельзя, если мы хотим бесшовно сменить камеру
    // Правельная последовательеость:
    // 1. MediaWorker.replaceLocalVideoDevice
    // 2. PeerConnection.replaceTrack
    // 3. MediaWorker.removeLocalTrack
    async replaceLocalVideoDevice(id) {
        if (this.localStream) {
            let stream = await this.getVideoStream({video: {deviceId: { exact: id }}})
            let newTrack = stream.getVideoTracks()[0]
            let oldTrack = this.localStream.getVideoTracks()[0]
            this.localStream.addTrack(newTrack)
            return { oldTrack, newTrack }
        }
    }

    removeLocalTrack(track) {
        if (this.videoPlug && this.videoPlug.stream.getTracks().find(t => t === track)) {
            this.videoPlug.stop()
            this.videoPlug = null
        }
        if (this.audioPlug && this.audioPlug.stream.getTracks().find(t => t === track)) {
            this.audioPlug.stop()
            this.audioPlug = null
        }
        track.stop()
        if (this.localStream) this.localStream.removeTrack(track)
    }

    replaceRemoteAudioDevice(id) {
        this._remoteElsBroadcast((el) => {
            el.setSinkId(id)
        })
    }
}

export default WebRTCClient