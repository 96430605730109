const mixin = {
    props: {
        focused: {
            type: Boolean,
            required: false,
            default: false
        },
        noSelection: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};

export default mixin;