var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "acs-entrances" },
    _vm._l(_vm.entrances, function(entrance) {
      return _c("div", { staticClass: "acs-entrance" }, [
        entrance.name
          ? _c("span", { staticClass: "name" }, [_vm._v(_vm._s(entrance.name))])
          : _vm._e(),
        _c("span", { staticClass: "status" }, [
          _vm._v(_vm._s(_vm._f("entranceStatusToText")(entrance.in)))
        ]),
        _c("span", { staticClass: "time" }, [
          _vm._v(_vm._s(_vm._f("moment")(entrance.time, "HH:mm")))
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }