//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import videoConfMixin from "./videoConfMixin"
import VideoConfGridItem from "./videoConfGridItem.vue"

import { MEMBER_PROPS } from "../../store/modules/video-conf-abstract"
import {CONFERENCE_SCHEMAS} from "../../constants"

export default {
    name: "videomostMembersVideo",
    components: { VideoConfGridItem },
    mixins: [ videoConfMixin ],
    props: {
        moderator: {
            type: Boolean,
            required: true,
        },
        schema: {
            type: String,
            required: true
        },
        members: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            activeJid: '',
            canvasCtx: null,
            activeVideo: null,
            canvasWith: 0,
            canvasHeight: 0,
            activeTimeout: 0,
        }
    },
    computed: {
        visibleMembers() {
            return this.members.filter((member) => {
                if (member[MEMBER_PROPS.BANNED] === 2) return false
                return true
            })
        },
        classes() {
            return `schema-${this.schema}`
        },
        styles() {
            let styles = {}
            if (this.schema === CONFERENCE_SCHEMAS.PUZZLE) {
                let membersCount = this.visibleMembers.length
                let columns = Math.ceil(Math.sqrt(membersCount)) || 1
                let rows = Math.ceil(membersCount / columns) || 1
                styles['--puzzle-columns'] = columns
                styles['--puzzle-rows'] = rows
            }
            return styles
        },
        activeMember() {
            let member
            if (this.activeJid) member = this.visibleMembers.find(({id}) => id === this.activeJid)
            if (!member) member = this.visibleMembers.find(({me}) => me)
            if (!member) member = this.visibleMembers[0]
            return member
        },
        shareShow() {
            return this.schema === CONFERENCE_SCHEMAS.SHARE
        },
        isTribuneSchema(){
            return this.schema === CONFERENCE_SCHEMAS.TRIBUNE
        }
    },
    watch: {
        schema(newVal, oldVal) {
            if (oldVal === CONFERENCE_SCHEMAS.TRIBUNE) {
                this.stopActiveShow()
            }
            if (newVal === CONFERENCE_SCHEMAS.TRIBUNE) {
                this.$nextTick(() => this.startActiveShow())
            }
        },
        activeVideo(newVal, oldVal) {
            oldVal && this.unsubscribeOnVideo(oldVal)
            newVal && this.subscribeOnVideo(newVal)
        }
    },
    mounted() {
        this.$emit('ready', this)
        window.addEventListener('resize', this.calcCanvasSize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.calcCanvasSize)
    },
    methods: {
        startActiveShow() {
            let canvas = this.$refs.active
            this.canvasCtx = canvas.getContext('2d')
            let dataJid = this.activeMember && this.activeMember.id
            let selector = dataJid ? `[data-jid="${dataJid}"] video` : 'video'
            let video = this.$refs.remote.querySelector(selector)
            if (video) {
                this.activeVideo = video
                this.calcCanvasSize()
                requestAnimationFrame(this.timerCallback)
            }
        },
        subscribeOnVideo(video) {
            video.addEventListener('play', this.onVideoPlayed)
            video.addEventListener('resize', this.calcCanvasSize)
        },
        unsubscribeOnVideo(video) {
            video.removeEventListener('play', this.onVideoPlayed)
            video.removeEventListener('resize', this.calcCanvasSize)
        },
        onVideoPlayed() {
            if (this.isTribuneSchema) {
                this.stopActiveShow()
                this.startActiveShow()
            }
        },
        stopActiveShow() {
            this.activeVideo = null
            this.canvasWith = 0
            this.canvasHeight = 0
        },
        calcCanvasSize() {
            if (!this.isTribuneSchema) return
            let canvas = this.$refs.active
            let canvasParent = canvas.parentElement
            let parentWith = canvasParent.clientWidth
            let parentHeight = canvasParent.clientHeight
            let videoWidth = this.activeVideo.videoWidth
            let videoHeight = this.activeVideo.videoHeight
            let videoProportions = videoWidth / videoHeight
            let widthPr = parentWith / videoWidth
            let heightPr = parentHeight / videoHeight
            let calcW = 0
            let calcH = 0
            if (widthPr > heightPr) { //считаем по Height
                calcH = parentHeight
                calcW = parentHeight * videoProportions
            } else {
                calcW = parentWith
                calcH = videoWidth / videoProportions
            }
            this.canvasWith = calcW
            this.canvasHeight = calcH
        },
        timerCallback() {
            let isTribuneSchema = this.schema === CONFERENCE_SCHEMAS.TRIBUNE
            if (!isTribuneSchema || this.activeVideo.paused || this.activeVideo.ended) {
                this.stopActiveShow()
                if (isTribuneSchema) this.startActiveShow()
                return
            }
            this.computeFrame()
            requestAnimationFrame(this.timerCallback)
        },
        computeFrame() {
            this.canvasCtx.drawImage(this.activeVideo, 0, 0, this.canvasWith, this.canvasHeight)
        },
        selectMember(member) {
            if (this.schema !== CONFERENCE_SCHEMAS.TRIBUNE) return
            this.activeJid = member.id
            //this.stopActiveShow()
            this.$nextTick(() => {
                this.startActiveShow()
            })
        },
        doMenu(...args) {
            this.$emit('member-menu', ...args)
        }
    }
}
