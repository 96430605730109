//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import {CONTACTS} from '../../store/modulesNames'
import {GET_FILTER_VALUES_LIST_BY_TYPE} from '../../store/gettersTypes'

import RecycleContactsList from './RecycleContactsList.vue'
import NameListItem from '../listItems/NameListItem.vue'

export default {
    name: "FilterTypeList",
    components: { RecycleContactsList, NameListItem },
    props: {
        searchType: {
            type: String,
            required: false,
            default: false,
        },
        filter: {
            type: String,
            required: false,
            default: '',
        },
        searchList: {
            type: Array,
            required: false,
            default: '',
        }
    },
    data: () => {
        return {
            selected: [],
        }
    },
    created () {
        this.selected.push(...this.searchList)
    },
    computed: {
        filterValuesList() {
            let lists = this[GET_FILTER_VALUES_LIST_BY_TYPE](this.searchType)
            return lists.filter(item => {
                return item.toUpperCase().includes(this.filter.toUpperCase())
            })
        },
        ...mapGetters(CONTACTS, [GET_FILTER_VALUES_LIST_BY_TYPE])
    },
    methods: {
        selectFilterValue(item) {
            if (this.selected.includes(item)) {
                this.selected.splice(this.selected.indexOf(item), 1)
            } else {
                this.selected.push(item)
            }
            this.$emit('selected', item)
            //let old = this.selected.splice(0,1, item).shift()
            //if (old) this.$emit('selected', old)
            //this.$emit('selected', item)
        },
        isMarked(item) {
            return this.searchList.includes(item)
        }
    },
    watch: {
        filter() {
            if (this.$refs.scroller && this.$refs.scroller.$el) this.$refs.scroller.$el.scrollTop = 0
        }
    }
}
