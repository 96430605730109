var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "context-menu-item" }, [
    _vm.select
      ? _c("div", { staticClass: "checked" }, [
          _vm.selected ? _c("i", { staticClass: "fal fa-check" }) : _vm._e()
        ])
      : _vm._e(),
    _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }