//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters} from 'vuex'

import contextMenuMixin from "../contextMenu/ContextMenuMixin"
import videoConfMixin from "./videoConfMixin"
import ContextMenuBase from "../contextMenu/ContextMenuBase.vue"
import {GET_VC_CONF_HANDUP} from "../../store/gettersTypes"
import {
    ACT_VC_CONF_HAND_UP,
    ACT_VC_CONF_REACTION,
} from "../../store/actionsTypes"
import {VIDEO_CONF_NEW} from "../../store/modulesNames"

export default {
    name: "videoConfReactions",
    mixins: [ videoConfMixin, contextMenuMixin ],
    components: { ContextMenuBase },
    data() {
        return {
            handupIcon: this.getReactionHtml(0x0270B),
            reactions: [
                0x1F642, 0x1F600, 0x1F44F, 0x1F440, 0x1F602,
                0x1F92B, 0x1F609, 0x1F914, 0x1F632, 0x1F92D,
                0x1F44D, 0x1F629, 0x1F620, 0x1F973, 0x1F913
            ]
        }
    },
    computed: {
        handupText() {
            return this.$t(`videomost.room-action.${this.handup ? 'hand-down' : 'hand-up'}`)
        },
        handup() {
            return this.confIdGetterData && this[GET_VC_CONF_HANDUP](...this.confIdGetterData)
        },
        ...mapGetters(VIDEO_CONF_NEW, [GET_VC_CONF_HANDUP])
    },
    methods: {
        getReactionHtml(reaction) {
            return this.wrapEmoji(String.fromCodePoint(reaction))
        },
        onHandup() {
            this[ACT_VC_CONF_HAND_UP]({...this.confIdActionData, handup: !this.handup})
            this.close()
        },
        onReaction(index) {
            this[ACT_VC_CONF_REACTION]({...this.confIdActionData, reaction: String.fromCodePoint(this.reactions[index])})
            this.close()
        },
        ...mapActions(VIDEO_CONF_NEW, [ACT_VC_CONF_HAND_UP, ACT_VC_CONF_REACTION])
    }
}
