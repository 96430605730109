//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'context-menu',
    props: {
        closeOnScroll: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        /**
         * Generate the CSS styles for positioning the context menu.
         * @returns {object|null}
         */
        style: function () {
            return this.show
                 ? { top: `${this.top}px`, left: `${this.left}px` }
                 : null;
        },
        classes() {
            return 'theme-' + (this.theme ? this.theme : 'default')
        },
        showDefault() {
            return this.show && !this.comp
        },
        showComponent() {
            return this.show && this.comp
        },
        comp() {
            return this.component && this.component.vc
        },
        compProps() {
            let props = { menuProps: { top: this.top, left: this.left } }
            return { ...props, ...(this.component && this.component.props || {}) }
        },
    },
    data() {
        return {
            top: null,
            left: null,
            show: false,
            handlers: null,
            component: null,
            theme: null,
            target: null,
            targetElement: null
        };
    },
    mounted() {
        if (this.closeOnScroll) {
            this.addScrollEventListener();
        }
    },
    beforeDestroy() {
        if (this.closeOnScroll) {
            this.removeScrollEventListener();
        }
    },
    methods: {
        /**
         * Close the context menu.
         */

        close(elem) {
            if(this.targetElement) {
                this.targetElement.style.removeProperty('background-color');
                this.targetElement = null;
            }
            setTimeout(function() {
                this.top = null;
                this.left = null;
                this.show = false;
                this.handlers = null;
                this.component = null;
                if (this.onClose) {
                    this.onClose()
                    this.onClose = null
                }
            }.bind(this), 100);
        },
        /**
         * Add scroll event listener to close context menu.
         */
        addScrollEventListener(elem) {
            if (elem) elem.addEventListener('scroll', this.close);
        },
        /**
         * Open the context menu.
         *
         * @param {MouseEvent} event
         * @param {Object} handlers
         * @param {String} handlers.item_name
         * @param {Function} handlers.handler
         * @param {Object} [handlers.data]
         * @param {position} [position] направление открытия
         * @param {Object} [options] options опции
         * @param {Number} [options.offsetX] отступ по оси x
         * @param {Number} [options.offsetY] отступ по оси y
         * @param {Function} [options.onClose] хук на закрытие
         * @param {Object} [options.component] компонент
         * @param {Object} [options.component.vc] Vue компонент
         * @param {Object} [options.component.props] props компонента
         * @param {Function} [options.component.cb] cb компонента
         */
        open(event, handlers, position = 'default', options = {}) {
            let {
                theme = null,
                offsetX = 0,
                offsetY = 0,
                onClose = null,
                component = null
            } = options
            if(this.targetElement) this.targetElement.style.backgroundColor = null;
            this.target = event.currentTarget || event.target;
            document.querySelector("body").removeEventListener("click", this.close, this.target);
            this.addScrollEventListener(event.target);
            let rectangle, y, x, direction = {};
            rectangle = this.target.getBoundingClientRect();
            if (position) {
                switch (position) {
                    case 'top-left':
                        y = +rectangle.y - offsetY;
                        x = +rectangle.x + rectangle.width - offsetX;
                        direction.up = true;
                        direction.left = true;
                        break;
                    case 'top-right':
                        y = +rectangle.y - offsetY;
                        x = +rectangle.x + offsetX;
                        direction.up = true;
                        break;
                    case 'bottom-left':
                        y = +rectangle.y + +rectangle.height + offsetY;
                        x = +rectangle.x + rectangle.width - offsetX;
                        direction.left = true;
                        break;
                    case 'bottom-right':
                        y = +rectangle.y + +rectangle.height + offsetY;
                        x = +rectangle.x + offsetX;
                        break;
                    case 'middle-center':
                    default:
                        y = event.clientY + offsetY;
                        x = event.clientX + offsetX;
                }
                this.handlers = handlers;
                this.onClose = onClose;
                this.component = component;
                this.show = true;
                this.theme = theme;
                // NEXT
                this.$nextTick(() => {
                    this.positionMenu(y, x, direction, this.target);
                });
            }
        },
        /**
         * Set the context menu top and left positions.
         *
         * @param {number} top
         * @param {number} left
         * @param {Object} direction
         * @param {Boolen} direction.up
         * @param {Boolen} direction.left
         */
        positionMenu(y, x, direction, el) {
            const largestHeight = window.innerHeight - this.$el.offsetHeight - 25;
            const largestWidth = window.innerWidth - this.$el.offsetWidth - 25;

            if (y > largestHeight && !direction.up) y = largestHeight;
            else if (direction.up) y = y - this.$el.offsetHeight;

            if (x > largestWidth && !direction.left) x = largestWidth;
            else if (direction.left) x = x - this.$el.offsetWidth;
            this.top = y;
            this.left = x;
            setTimeout(function() {
                this.targetElement = el;
                document.querySelector("body").addEventListener("click", this.close);
            }.bind(this), 0);
        },
        /**
         * Remove the scroll event listener to close the context menu.
         */
        removeScrollEventListener() {
            window.removeEventListener('scroll', this.close);
        }
    },
    watch: {
        /**
         * Add or remove the scroll event listener when the prop value changes.
         *
         * @param {boolean} value
         * @param {boolean} oldValue
         */
        closeOnScroll(value, oldValue) {
            if (value === oldValue) {
                return;
            }
            if (value) {
                this.addScrollEventListener();
            } else {
                this.removeScrollEventListener();
            }
        }
    }
}
