
import {mapGetters, mapActions} from 'vuex'
import {CHATS} from '../../store/modulesNames'
import {GET_CHAT} from '../../store/gettersTypes'
import {ACT_REMOVE_CHAT_API} from '../../store/actionsTypes'
import Confirm from './Confirm.vue'

export default {
    name: 'RemoveChat',
    extends: Confirm,
    computed: {
        text() { return this.$t('modal.delete-chat-confirm') },
        cid() { return this.props.cid },
        cidType() { return this.props.cidType || declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER },
        chat() { return this[GET_CHAT]({cid: this.cid, cidType: this.cidType}) },
        ...mapGetters(CHATS, [GET_CHAT]),
    },
    methods: {
        async btnOkCb() {
            this[ACT_REMOVE_CHAT_API](this.chat)
        },
        ...mapActions(CHATS, [ACT_REMOVE_CHAT_API]),
    }
}
