var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog hoy",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("videomost.new-conference")
      }
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "newconference" }, [
          _c(
            "div",
            { staticClass: "conf-type conf-item" },
            [
              _c("span", { staticClass: "subheader" }, [
                _vm._v(_vm._s(_vm.$t("videomost.conferences-type")))
              ]),
              _c("custom-select", {
                attrs: {
                  selected_option: _vm.chosenConfType,
                  options: _vm.confTypes
                },
                on: {
                  "update:selected_option": function($event) {
                    _vm.chosenConfType = $event
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "subject conf-item" },
            [
              _c("custom-input-text", {
                key: "0",
                attrs: {
                  input_text: _vm.subjectName,
                  label: _vm.$t("videomost.conf-subject"),
                  warning: _vm.subjectMissed
                },
                on: {
                  "update:input_text": function($event) {
                    _vm.subjectName = $event
                  }
                },
                nativeOn: {
                  click: function($event) {
                    _vm.subjectMissed = false
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "participant conf-item" },
            [
              _c("custom-input-text", {
                key: "1",
                ref: "vmConfPartName",
                attrs: {
                  input_text: _vm.participantName,
                  default_text: _vm.fio,
                  label: _vm.$t("videomost.conf-participant-name")
                },
                on: {
                  "update:input_text": function($event) {
                    _vm.participantName = $event
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "conf-options conf-item" },
            [
              _c("custom-checkbox", {
                attrs: {
                  default: _vm.isNoCamera,
                  label: _vm.$t("videomost.conf-nocamera-on")
                }
              }),
              _c("custom-checkbox", {
                attrs: {
                  default: _vm.isNoMicrophone,
                  label: _vm.$t("videomost.conf-nomic-on")
                }
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.cancelText },
            on: { click: _vm.cancelCreateNewConf }
          }),
          _c("modal-btn", {
            attrs: { text: _vm.$t("videomost.conf-run") },
            on: { click: _vm.createNewConf }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }