var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "publication-main publication-content",
      on: {
        contextmenu: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.doContextMenuRef($event)
        }
      }
    },
    [
      _c("div", { staticClass: "title-block" }, [
        _c("div", { staticClass: "title" }, [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(_vm.msgTextHtml(_vm.publication.title))
            }
          })
        ])
      ]),
      _c(
        "div",
        { staticClass: "info-block" },
        [
          _vm.checkReference(_vm.publication.info)
            ? _c("reference-msg", {
                attrs: {
                  message: _vm.publication.info,
                  textMsg: _vm.getTextEntities(_vm.publication.info).outText,
                  textEntities: _vm.getTextEntities(_vm.publication.info)
                    .entities
                }
              })
            : _c("div", { staticClass: "info-text" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.msgTextHtml(_vm.publication.info))
                  }
                })
              ])
        ],
        1
      ),
      _vm.data.with_imgs.length
        ? _c("div", { staticClass: "with-imgs-block" }, [
            _c(
              "ul",
              { staticClass: "with-imgs-list" },
              _vm._l(_vm.data.with_imgs, function(item) {
                return _c(
                  "li",
                  [
                    item.type === "image"
                      ? _c("publication-image-item", {
                          staticClass: "img-item publication-item",
                          attrs: {
                            data: item,
                            images: _vm.data.with_imgs.length,
                            publication: _vm.publication
                          }
                        })
                      : _c("publication-video-item", {
                          staticClass: "video-item publication-item",
                          attrs: {
                            data: item,
                            images: _vm.data.with_imgs.length,
                            publication: _vm.publication
                          }
                        })
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _vm.data.files.length
        ? _c("div", { staticClass: "files-block" }, [
            _c(
              "ul",
              { staticClass: "files-list vertical-list" },
              _vm._l(_vm.data.files, function(item) {
                return _c(
                  "li",
                  { staticClass: "inline-item" },
                  [
                    _c("publication-document-item", {
                      staticClass: "file-item publication-item inline-item",
                      attrs: { data: item, "unix-last-time": _vm.unixLastTime }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _vm.data.audios.length
        ? _c("div", { staticClass: "audios-block" }, [
            _c(
              "ul",
              { staticClass: "audios-list vertical-list" },
              _vm._l(_vm.data.audios, function(item) {
                return _c(
                  "li",
                  { staticClass: "inline-item" },
                  [
                    _c("publication-audio-item", {
                      staticClass: "audios-item publication-item inline-item",
                      attrs: { data: item, "unix-last-time": _vm.unixLastTime }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _vm.data.contacts.length
        ? _c("div", { staticClass: "contacts-block" }, [
            _c(
              "ul",
              { staticClass: "contacts-list vertical-list" },
              _vm._l(_vm.data.contacts, function(item) {
                return _c(
                  "li",
                  { staticClass: "inline-item" },
                  [
                    _c("publication-contact-item", {
                      staticClass: "contact-item publication-item inline-item",
                      attrs: { data: item }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _vm.data.geo_location
        ? _c(
            "div",
            { staticClass: "geo-location-block inline-item" },
            [
              _c("publication-location-item", {
                staticClass: "geo-location-item publication-item",
                attrs: {
                  data: _vm.data.geo_location,
                  "unix-last-time": _vm.unixLastTime
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.data.poll
        ? _c(
            "div",
            { staticClass: "poll-channel-block inline-item" },
            [
              _c("chat-poll-message", {
                ref: "chat_poll_message",
                staticClass: "chat-poll-message",
                attrs: { message: { msg: _vm.data.poll } }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }