var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "central integrations" },
    [
      _c("main-content-container", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm.showExchange
                  ? _c(
                      "list-item",
                      {
                        attrs: {
                          title: _vm.$t("integrations.ms_exchange"),
                          link: true
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.msExchangeSettings($event)
                          }
                        }
                      },
                      [
                        _c("template", { slot: "media" }, [
                          _c("img", {
                            staticStyle: { "max-width": "42px" },
                            attrs: { src: _vm.icon_src }
                          })
                        ])
                      ],
                      2
                    )
                  : _vm._e(),
                _c("list-item", {
                  attrs: {
                    title: _vm.$t("integrations.add-integration"),
                    link: true
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.addIntegration($event)
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }