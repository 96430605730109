//
//
//
//
//
//
//
//
//
//

import { i18n } from '../../../ext/i18n'
const locale = i18n.messages[i18n.locale]

export default {
    props: {
        hotKeysItem: {
            type: Object,
            required: true
        },
        cancelChange: {
            type: Boolean,
            required: true
        }
    },
    data() { 
        return {
            hotKeysStr: '',
            hotKeysInitialStr: '',
            hotKeysElectronStr: '',
            placeholder: '',
            defaultPlaceholder: '',
            input_value: '',
            special_keys: ['CONTROL','META','SHIFT','ALT'],
            lastEnteredKey: '',
            isFocused: false,
            maxKeys: 3,
            enteredKeysNumber: 0,
            wrapperStyleOnFocus: '',
            inputStyleOnFocus: '',
        }
    },
    mounted() {
        this.defaultPlaceholder = locale['hotkeys']['defaultPlaceholder']
        this.enteredKeysNumber = this.maxKeys
        if (!!this.hotKeysItem.hotKeysStr) {
            this.hotKeysInitialStr = this.hotKeysItem.hotKeysStr
            this.hotKeysStr = this.hotKeysInitialStr
        }
        let hkModal = document.getElementById('SetHotkeysModal')
        hkModal.addEventListener('click', this.modalClick)
        if (!this.hotKeysStr.length) this.placeholder = this.defaultPlaceholder
        this.$refs.hotKeysInput.focus()
        this.isFocused = true
    },
    computed: {
        isItemGlobal() {
            return !!this.hotKeysItem.global
        }
    },
    watch: {
        isFocused(val) {
            if (val && !!this.hotKeysStr.length && !this.placeholder.length) {
                this.wrapperStyleOnFocus = `border: 2px solid rgb(195, 195, 195); border-radius: 14px;`
                this.inputStyleOnFocus = `font-size: 24px; font-weight: 600;`
            }
            if (!val && !!this.placeholder && !!this.placeholder.length) {
                this.wrapperStyleOnFocus = `border: none`
                this.inputStyleOnFocus = 'font-size: 20px;'
            }
        },
        hotKeysStr(val) {
            if (!!val.length) {
                this.placeholder = ''
                this.isFocused = true
            } else {
                this.placeholder = this.defaultPlaceholder
                this.isFocused = false
            }
        },
        cancelChange(newVal) {
            if (newVal) this.hotKeysItem.hotKeysStr = this.hotKeysInitialStr
        }
    },
    methods: {
        modalClick() {
            this.$refs.hotKeysInput.focus()
        },
        onFocus() {
            this.isFocused = true
            if (!!this.hotKeysStr.length) this.placeholder = ''
        },
        onBlur() {
            this.isFocused = false
            if (!this.hotKeysStr.length && this.placeholder !== this.defaultPlaceholder) this.placeholder = this.defaultPlaceholder
        },
        onClear() {
            this.enteredKeysNumber = 0
            this.hotKeysStr = ''
            this.placeholder = this.defaultPlaceholder
            this.isFocused = true
        },
        keysEntered(e) {
            e.preventDefault()
            if (e.key === this.lastEnteredKey) return
            let isSpecialKey = this.special_keys.some(key => e.key.toUpperCase().includes(key))
            if (this.enteredKeysNumber === 0 && !isSpecialKey) return
            if (!this.lastEnteredKey.length || this.enteredKeysNumber === 0 || e.key === 'Escape') {
                this.enteredKeysNumber = 0
                this.hotKeysStr = ''
                this.hotKeysElectronStr = ''
            }
            if (this.enteredKeysNumber >= this.maxKeys && isSpecialKey) {
                this.enteredKeysNumber = 0
                this.hotKeysStr = ''
                this.hotKeysElectronStr = ''
            }
            if (this.enteredKeysNumber >= this.maxKeys && !isSpecialKey) {
                let firstKeyInd = this.hotKeysStr.indexOf('+')
                let firstKeyElectronInd = this.hotKeysElectronStr.indexOf('+')
                this.hotKeysStr = this.hotKeysStr.substring(0, firstKeyInd - 1)
                this.hotKeysElectronStr = this.hotKeysElectronStr.substring(0, firstKeyElectronInd - 1)
                this.enteredKeysNumber = 1
            } 
            
            const isMac = window.navigator.userAgent.indexOf("Mac") !== -1
            this.lastEnteredKey = e.key
            switch (e.key.toUpperCase()) {
                case 'ARROWUP':
                case 'ARROWDOWN':
                case 'ARROWLEFT':
                case 'ARROWRIGHT':
                case 'DELETE':
                case 'CAPSLOCK':
                case 'TAB':
                case 'BACKSPACE':
                case 'ESCAPE':
                    break;
                case 'CONTROL':
                    let ctrl = 'Ctrl'
                    if (!this.isItemGlobal) {
                        if (e.code === 'ControlLeft') ctrl = 'CtrlLeft'
                        else ctrl = 'CtrlRight'
                    }
                    if (!this.hotKeysStr.length) {
                            this.hotKeysStr += ctrl 
                            this.hotKeysElectronStr += ctrl
                    } 
                    else { 
                        this.hotKeysStr += ` + ${ctrl}`
                        this.hotKeysElectronStr += `+${ctrl}`
                    }
                    this.enteredKeysNumber++
                    break;
                case 'META':
                    let meta = isMac ? 'Cmd': 'WinKey'
                    if (e.code === 'MetaLeft') meta += 'Left' 
                    else meta += 'Right'
                    if (!this.hotKeysStr.length) {
                            this.hotKeysStr += meta 
                            this.hotKeysElectronStr += `${e.code}`
                    } 
                    else { 
                        this.hotKeysStr += ` + ${meta}`
                        this.hotKeysElectronStr += `+${e.code}`
                    }
                    this.enteredKeysNumber++
                    break;
                case 'SHIFT':
                case 'ALT':
                    !this.hotKeysStr.length ? this.hotKeysStr += `${e.code}` : this.hotKeysStr += ` + ${e.code}`
                    !this.hotKeysElectronStr.length ? this.hotKeysElectronStr += `${e.code}` : this.hotKeysElectronStr += `+${e.code}`
                    this.enteredKeysNumber++
                    break;
                default:
                    let key = e.code.replace('Key', '').replace('Digit', '')
                    !this.hotKeysStr.length ? this.hotKeysStr += key : this.hotKeysStr += ' + ' + key
                    !this.hotKeysElectronStr.length ? this.hotKeysElectronStr += key : this.hotKeysElectronStr += '+' + key
                    this.enteredKeysNumber++
                    break;
            }
            if (this.isItemGlobal) {
                const reLeft = /Left/ig
                const reRight = /Right/ig
                this.hotKeysStr = this.hotKeysStr.replace(reLeft, '').replace(reRight,'')
                this.hotKeysElectronStr = this.hotKeysElectronStr.replace(reLeft, '').replace(reRight,'')
            }
            this.hotKeysItem.hotKeysStr = this.hotKeysStr
            this.hotKeysItem.electronStr = this.hotKeysElectronStr
        },
    },
    beforeDestroy() {
        let hkModal = document.getElementById('SetHotkeysModal')
        hkModal.removeEventListener('click', this.modalClick)
    }
}
