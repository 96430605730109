//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex'

import { USERDATA, LOGIN, CONTACTS } from '../../store/modulesNames'
import { GET_BIRTHDAY_NOTIFY, GET_SERVER_API } from "../../store/gettersTypes";
import {
    ACT_SET_BIRTHDAY_NOTIFY,
    ACT_SET_BIRTHDAYS_WATCHED,
    ACT_UPDATE_CONTACTS_BIRTHDAYS,
} from '../../store/actionsTypes'

import ModalDialog from './ModalDialog.vue'
import CustomRadio from '../custom/CustomRadio.vue'
import modal_mixin from './modal-mixin'

export default {
    name: "SetBirthday",
    components: {ModalDialog, CustomRadio},
    mixins: [modal_mixin],
    props: {
        modalName: {
            type: String,
            required: true
        },
        props: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data() {
        return {
            compModalProps: {
                delay: 200,
                pivotY: 0.40,
                pivotX: 0.73,
                width: 350,
                height: 'auto'
            },
            birthdayNotify: 3,
            setUnwatched: false,
        }
    },
    mounted() {
        this.birthdayNotify = this[GET_BIRTHDAY_NOTIFY]
    },
    computed: {
        title() {
           return this.$t('bday-notifications')
        },
        isRolesSupported() {
            return this[GET_SERVER_API] > declarations.serverAPILevels.LEVEL_11
        },
        inputs() {
            return [
                {
                    value: 10, 
                    label: this.$t('not-notify')
                }, {
                    value: 0,
                    label: this.$t('notify-on-same-day')
                }, {
                    value: 1,
                    label: this.$t('day-before')
                }, {
                    value: 3,
                    label: this.$t('3days-before')
                }
            ]
        },
        ...mapGetters(LOGIN, [GET_SERVER_API]),
        ...mapGetters(USERDATA, [GET_BIRTHDAY_NOTIFY]),
    },
    watch: {
        birthdayNotify(newVal, oldVal) {
            if (newVal !== 10) this.setUnwatched = true
        }
    },
    methods: {
        okBtn() {
            this[ACT_SET_BIRTHDAY_NOTIFY](this.birthdayNotify)
            if (this.isRolesSupported) this[ACT_UPDATE_CONTACTS_BIRTHDAYS]()
            if (this.setUnwatched) this[ACT_SET_BIRTHDAYS_WATCHED](false)
            this.cancelBtn()
        },
        cancelBtn() {
            this.setUnwatched = false
            this.modalClose();
        },
        ...mapActions(USERDATA, [ACT_SET_BIRTHDAY_NOTIFY]),
        ...mapActions(CONTACTS, [ACT_UPDATE_CONTACTS_BIRTHDAYS, ACT_SET_BIRTHDAYS_WATCHED]),
    },
}
