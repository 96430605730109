var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-wrapper", class: _vm.classes, style: _vm.styles },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.shareShow,
            expression: "shareShow"
          }
        ],
        ref: "share",
        staticClass: "share"
      }),
      _c("div", { staticClass: "active" }, [
        _c("canvas", {
          ref: "active",
          attrs: { width: _vm.canvasWith, height: _vm.canvasHeight }
        }),
        _c(
          "div",
          { staticClass: "grid-wrapper" },
          [
            _vm.activeMember
              ? _c("video-conf-grid-item", {
                  staticClass: "grid-item",
                  class: _vm.activeMember.me ? "own-item" : "",
                  attrs: {
                    moderator: _vm.moderator,
                    member: _vm.activeMember,
                    width: _vm.canvasWith + "px",
                    height: _vm.canvasHeight + "px"
                  },
                  on: { "member-menu": _vm.doMenu }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "videos-list" }, [
        _c("div", { ref: "remote", staticClass: "remote" }),
        _c(
          "div",
          { staticClass: "members-grid" },
          [
            _vm._l(_vm.visibleMembers, function(member) {
              return [
                _c("video-conf-grid-item", {
                  class: member.me ? "own-item" : "",
                  attrs: { moderator: _vm.moderator, member: member },
                  on: { "member-menu": _vm.doMenu },
                  nativeOn: {
                    click: function($event) {
                      return _vm.selectMember(member)
                    }
                  }
                })
              ]
            })
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }