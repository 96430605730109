//
//
//
//
//
//
//
//
//
//

 'use strict';
export default {
     name: 'notice',
     computed: {
        text() {
            return this.$store.getters['notice/getMsg'];
        },
         title() {
            return this.$store.getters['notice/getTitle'];
        },
         life() {
            return this.$store.getters['notice/getLife']*1000;
        }
    },
    data() {
        return {
            timeout_id: null,
            show: true,
            otext: this.text
        };
    },
    methods: {
        close() {
            clearTimeout(this.timeout_id);
            this.show = false;
            setTimeout(function() {
                this.$store.dispatch('notice/setMsg', '');
                this.$store.dispatch('notice/setLife', 0);
            }.bind(this), this.life);
        },
        open(title, text) {

            const close = () => {
                this.$store.dispatch('notice/setMsg', {title: '', msg: ''})
            }

            this.modalOpen({
                name: 'alert',
                props: {
                    title,
                    text,
                    btnOk: {
                        cb: () => close()
                    }
                }
            })


        }
    },
    watch: {
        text(val) {
            if (val) {
                /* if (!!this.life) this.timeout_id = setTimeout(this.close, this.life);
                 * this.otext = this.text;
                 * this.show = true; */
                this.open(this.title, val)
            }
        }
    },
}
