/**
 * Created by Aleksey on 06.06.2018.
 */
import event_bus from './app/eventBus'
import VModal from 'vue-js-modal'
import VueClipboard from 'vue-clipboard2'
import VueResize from 'vue-resize'
import VueResizeObserver from "vue-resize-observer"
import { ObserveVisibility } from 'vue-observe-visibility'

//https://jsfiddle.net/Linusborg/Lx49LaL8/
export default class VueExtensions {
    constructor(Vue) {
        Vue.directive('click-outside', {
            bind: function(el, binding, vNode) {
                // Provided expression must evaluate to a function.
                if (typeof binding.value !== 'function') {
                    const compName = vNode.context.name;
                    let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
                    if (compName) { warn += `Found in component '${compName}'`; }

                    console.warn(warn);
                }
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble;
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e);
                    }
                };
                el.__vueClickOutside__ = handler;

                // add Event Listeners
                document.addEventListener('click', handler);
            },

            // unbind: function(el, binding) {
            //     // Remove Event Listeners
            //     document.removeEventListener('click', el.__vueClickOutside__);
            //     el.__vueClickOutside__ = null;
            //
            // }
        });
        Vue.use(VModal, { dialog: true, dynamic: true, injectModalsContainer: true });
        Vue.use(VueClipboard);
        let originCopyTextFn = Vue.prototype.$copyText
        Vue.prototype.$copyText = (text, container, dlpData) => {
            if (dlpData) {
                dlpData.text = text
                event_bus.$emit('text-copy', dlpData)
            }
            originCopyTextFn(text, container)
        }
        Vue.use(VueResize)
        Vue.use(VueResizeObserver);

        Vue.directive('observe-visibility', ObserveVisibility)
    }
}
