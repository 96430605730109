const COMPACT_CLIENT_WIDTH = 700
const MIN_CLIENT_WIDTH = 990
const MIN_SIDE_WIDTH = 250
const MAX_SIDE_WIDTH = 450

let _event_bus = null

let isFullCompactMode = false, 
    isCompactMode = false, 
    isSideVisible = false, 
    isMainVisible = false, 
    isInfoVisible = false

let resizeDivider = document.getElementById('resize-divider')
let sideElement = document.getElementById('side')
let mainElement = document.getElementById('main')
let infoElement = document.getElementById('info')

const getIsFullCompactMode = () => { return isFullCompactMode }
const getIsCompactMode = () => { return isCompactMode }
const getIsMainVisible = () => { return isMainVisible }

const setFullCompactVisibility = () => {
    mainElement = document.getElementById('main')
    infoElement = document.getElementById('info')
    resizeDivider.classList.add('fullCompactMode')
    setTimeout(() => {
        let mainContent = document.getElementById('main-content-comp')
        let isMainContent = Boolean(mainContent.children.length)
        if (!isMainContent) {
            setSideVisible(true)
            mainElement.classList.add('hidden')
            if (isMainVisible || (!isMainVisible && !isInfoVisible)) infoElement.classList.add('hidden')
        }
        else {
            setSideHidden()
            if (!isInfoVisible) {
                infoElement.classList.add('hidden')
            }
            isMainVisible = true
        }
    }, 50)
}
const setMainInfoVisibility = () => {
    infoElement = document.getElementById('info')
    if (!isMainVisible) mainElement.classList.add('hidden')
    else mainElement.classList.remove('hidden')
    if (!isInfoVisible) infoElement.classList.add('hidden')
    else {
        infoElement.classList.remove('hidden')
        if (!isMainVisible) infoElement.classList.add('mainHidden')
    }
}

const setSideVisible = (isForced = false) => {
    sideElement = document.getElementById('side')
    if (!isSideVisible || isForced) {
        isSideVisible = true
        sideElement.classList.add('sideVisible')
        sideElement.classList.remove('hidden-side')
    }
}

const setSideHidden = (isForced = false) => {
    sideElement = document.getElementById('side')
    if (isSideVisible || isForced) {
        isSideVisible = false
        sideElement.classList.add('hidden-side')
        sideElement.classList.remove('sideVisible')
        mainElement.classList.remove('hidden')
    }
}

const setMainVisible = () => {
    isMainVisible = true
    isInfoVisible = false
    setMainInfoVisibility()
}

const setMainHidden = () => {
    isMainVisible = false
    isInfoVisible = true
    setMainInfoVisibility()
}

const setInfoHidden = () => {
    isInfoVisible = false
}

const setFullCompactMode = () => {
    isFullCompactMode = true
    sideElement = document.getElementById('side')
    sideElement.classList.add('fullCompactMode')
    const sideElementChild = document.getElementById('side').firstChild
    sideElementChild.removeAttribute("style")
    clearResizerEvents()
    if (isMainVisible) sideElement.classList.add('hidden-side')
    mainElement.classList.add('compactMode')
    infoElement = document.getElementById('info')
    infoElement.classList.add('compactMode')
    setFullCompactVisibility()
    _event_bus && _event_bus.$emit('full-compact-mode', true)
}

const removeFullCompactMode = () => {
    isFullCompactMode = false
    sideElement = document.getElementById('side')
    sideElement.classList.remove('sideVisible')
    sideElement.removeAttribute("class")
    resizeDivider.classList.remove('fullCompactMode')
    mainElement = document.getElementById('main')
    mainElement.classList.remove('fullCompactMode')
}

const setCompactMode = () => {
    if (isFullCompactMode) removeFullCompactMode()
    isCompactMode = true 
    mainElement = document.getElementById('main')
    mainElement.classList.add('compactMode')
    resizeDivider.classList.add('compactMode')
    infoElement = document.getElementById('info')
    infoElement.classList.add('compactMode')
    mainElement && setMainVisible()
    resizeDivider.addEventListener('mousedown', (e) => {
        document.addEventListener('mousemove', handleMouseMove, false)
        document.addEventListener('mouseup', clearResizerEvents, false)
        document.addEventListener('blur', clearResizerEvents, false)
    }, false)       
}

const restoreNormalMode = () => {
    if (isFullCompactMode) removeFullCompactMode()
    mainElement.classList.remove('compactMode')
    resizeDivider.classList.remove('compactMode')
    infoElement.classList.remove('compactMode')
    mainElement.classList.remove('hidden')
    infoElement = document.getElementById('info')
    infoElement.classList.remove('hidden')
    infoElement.classList.remove('mainHidden')
    clearResizerEvents()
    const sideElement = document.getElementById('side').firstChild
    sideElement.removeAttribute("style")
    isCompactMode = false
    _event_bus.$emit('full-compact-mode', false)
    _event_bus.$emit('compact-mode', false)
}    

const handleMouseMove = (e) => {
    const sideElement = document.getElementById('side').firstChild
    if (e.clientX >= MIN_SIDE_WIDTH && e.clientX <= MAX_SIDE_WIDTH) 
        sideElement.style.width = e.clientX + 'px'
}

const clearResizerEvents = () => {
    document.removeEventListener('mousemove', handleMouseMove, false)
    document.removeEventListener('mouseup', clearResizerEvents, false)
    document.removeEventListener('blur', clearResizerEvents, false)
}

const setTrackResizes = (event_bus) => {
    
    if (!_event_bus) _event_bus = event_bus
    
    const checkCompactMode = (innerWidth) => {
        if (innerWidth <= COMPACT_CLIENT_WIDTH) {
            if (!isFullCompactMode) {
                setFullCompactMode()
                event_bus.$emit('full-compact-mode', true)
            }
        }
        else if (innerWidth > COMPACT_CLIENT_WIDTH && innerWidth <= MIN_CLIENT_WIDTH) {
            if (isFullCompactMode) {
                removeFullCompactMode()
                event_bus.$emit('full-compact-mode', false)
            }
            setCompactMode()
            event_bus.$emit('compact-mode', true)
        }
        else if (isCompactMode && innerWidth > MIN_CLIENT_WIDTH) {
            if (isFullCompactMode || isCompactMode) restoreNormalMode()
        }
    }

    let { innerWidth } = window
    checkCompactMode(innerWidth)
    const resizeObserver = new ResizeObserver(() => {
        let { innerWidth } = window
        checkCompactMode(innerWidth)
    })
    resizeObserver.observe(document.body)
}

export { 
    setTrackResizes, 
    getIsFullCompactMode,
    setFullCompactVisibility,
    getIsCompactMode, 
    getIsMainVisible,
    setSideVisible,
    setSideHidden,
    setMainVisible, 
    setMainHidden,
    setInfoHidden,
}