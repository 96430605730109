
import { mapActions } from 'vuex'
import MediaViewer from "./MediaViewer.vue"
import PublicationFilesMixin from "../channels/PublicationFilesMixin"
import {AJAX, DLP} from '../../store/modulesNames'
import {ACT_AJAX_GET_FILE, ACT_SEND_FILE_SAVE_EVENT} from '../../store/actionsTypes'
import {DLP_SOURCE_TYPES} from '../../store/modules/dlp'
import {CONTACT_FIELD_TYPES} from '../../constants'

export default {
    name: "PublicationMediaViewer",
    props: {
        chId: {
            type: Number,
            required: true
        },
        pubId: {
            type: Number,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    extends: MediaViewer,
    mixins: [PublicationFilesMixin],
    created() {
        this.currImgIndex = this.index
        this.loadMessageFile()
    },
    computed: {
        user() {
            if(this.publication.cid) {
                let result = this.$store.getters['contacts/getMergedContactById'](this.publication.cid);
                return {'photo': result[CONTACT_FIELD_TYPES.PHOTO], 'fio': result.fio};
            } else {
                let channel = this.$store.getters['channels/getChannel']({chId: this.publication.chId});
                let photo = app.getChannelPhotoUrl(channel.icon);
                return {'photo': photo, 'fio': channel.name};
            }
        },
        path() {
            let file = this.$store.getters['blob/getFileBlob'](this.mediaItem.file)
            if(file) {
                this.preload = false;
                return file;
            }
        },
        preview() {
            return this.getPreview(this.mediaItem)
        },
        time() {
            let time = this.publication.time;
            return this.getTimeFromSecondsLeft(time)
        },
        extension() {
            return this.mediaItem.extension
        },
        publication() {
            return this.$store.getters['channel/getPublicationById']({pubId: this.pubId});
        },
        mediaItems() {
            return this.$store.getters['channel/getPublicationPreparedDataItems']({pubId: this.pubId}).with_imgs
        },
        subtitle() {
            if (this.publication) return this.publication.info
        }
    },
    methods: {
        async loadMessageFile() {
            if (!this.mediaItem) return
            let blob_url = app.store.getters['blob/getFileBlob'](this.mediaItem.file);
            if (blob_url) {
                this.preload = false
                return;
            }
            await this[ACT_AJAX_GET_FILE]({url: this.mediaItem.file, fileSize: this.mediaItem.size, link: true})
            this.preload = false
        },
        onMediaItemChange() {
            this.loadMessageFile()
        },
        onItemSaved() {
            this.$store.dispatch(`${DLP}/${ACT_SEND_FILE_SAVE_EVENT}`, {
                fileName:  this.mediaItem.file,
                sourceType: DLP_SOURCE_TYPES.PUBLICATION,
                sourceData: {
                    chId: this.publication.chId,
                    pubId: this.pubId,
                }
            })
        },
        ...mapActions(AJAX, [ACT_AJAX_GET_FILE])
    },

}
