//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconImage from '../IconImage.vue';
import MaskedInput from 'vue-text-mask';
import PhoneActionButton from './PhoneActionButton.vue'

import { GET_DIALER_NUMBER } from "../../store/gettersTypes";
import { CALLS_CONTROLLER } from "../../store/actionsTypes";
import { SET_DIALER_NUMBER } from "../../store/mutationsTypes";
import { PHONE_CNTL } from "../../store/modulesNames";
import { i18n } from '../../../ext/i18n'

import { getIsFullCompactMode } from '../../../ext/trackResizes';

const default_phone_size = '2.2em';

export default {
    name: 'dialer',
    components: { IconImage, MaskedInput, PhoneActionButton },
    data() {
        return {
            phone: '',
            phone_save: localStorage.getItem('phone_saved') || '',
            phone_size: default_phone_size,
            fake_input: true,
            digits_row1: {
                '2': i18n.messages[i18n.locale].phone['2'],
                '3': i18n.messages[i18n.locale].phone['3']
            },
            digits_row2: {
                '4': i18n.messages[i18n.locale].phone['4'],
                '5': i18n.messages[i18n.locale].phone['5'],
                '6': i18n.messages[i18n.locale].phone['6'],
            },
            digits_row3: {
                '7': i18n.messages[i18n.locale].phone['7'],
                '8': i18n.messages[i18n.locale].phone['8'],
                '9': i18n.messages[i18n.locale].phone['9'],
            },
            input: null,
            backspace_timer_id: null,
            alt_timeout_id: null,
            alt: false,
            backspace_show: false,
            phone_mask() {
                let mask = [];
                for (let i = 0; i < 80; i++) {
                    mask.push(/[0-9\*#\+]/);
                }
                return mask;
            },
            dialerTop: 185,
            dialerLeft: 195,
            mouthLeft: 195,
            resizeObserver: null,
            isFullCompactMode: getIsFullCompactMode(),
        }
    },
    created() {
        this.$bus.$on('compact-mode', (val) => {
            this.isFullCompactMode = false
        })
        this.$bus.$on('full-compact-mode', (val) => {
            this.isFullCompactMode = val
        })
    },    
    computed: {
        contact_found() {
            return this.$store.getters['contacts/getMergedContactByPhone'](this.phone);
        },
        format_phone() {
            return this.resize();
        },
        dialerCssVars() {
            return {
                '--dialer-top': this.dialerTop + 'px',
                '--dialer-left': this.dialerLeft + 'px',
                '--mouth-left': this.isFullCompactMode ? this.mouthLeft + 'px' : '31px',
            }
        }
    },
    watch: {
        phone(val) {
            if (!!val) this.backspace_show = true;
        },
        isFullCompactMode(newVal, oldVal) {
            if (newVal && !oldVal) this.doClose()
        }
    },
    mounted() {
        this.calcDialerPosition()

        this.input = document.querySelector('.phone-string')
        this.focus()
        this.input.onblur = () => this.focus()
        this.phone = this.$store.getters[`${PHONE_CNTL}/${GET_DIALER_NUMBER}`]

        this.resizeObserver = new ResizeObserver(() => { 
            this.calcDialerPosition()
        })
        const sideElement = document.getElementById('side')
        this.resizeObserver.observe(sideElement)


    },
    beforeDestroy() {
        this.$store.commit(`${PHONE_CNTL}/${SET_DIALER_NUMBER}`, this.phone)
        const sideElement = document.getElementById('side')
        this.resizeObserver.unobserve(sideElement)
    },
    methods: {
        calcDialerPosition() {
            let link = document.getElementById('dialer-link')
            let top = 115
            let left = 150
            if (link) {
                let bounding = link.getBoundingClientRect()
                if (bounding) {
                    if (this.isFullCompactMode) {
                        if (bounding.left > 350) {
                            top = bounding.top + 40
                            left = bounding.left - 274
                            this.mouthLeft = 268
                        } else {
                            top = bounding.top + 40
                            left = bounding.left / 2 - 100
                            this.mouthLeft = bounding.left / 2 + 94
                        }
                    } else {
                        top = bounding.top + bounding.height / 2 + 24
                        left = bounding.left + bounding.width - 64
                    }
                }
            }
            this.dialerTop = top
            this.dialerLeft = left
        },
        keyPress(e) {
            if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '#'].includes(e.key)) this.phone += digit          
        },
        prepareMenu(event) {
            event.target.select()
        },
        resize() {
            let phone = this.phone;
            let phone2 = phone;
            const size_val = default_phone_size.replace(/[^0-9]/g, '');
            const size_unit = default_phone_size.replace(/[0-9\.]/g, '');
            if (phone.length > 0 && phone.length < 13) {
                this.phone_size = default_phone_size;
                return this.formatPhoneNumber(phone);
            } else if (phone.length > 13 && phone.length < 17) {
                this.phone_size = `${(size_val - (phone.length - 12) * 1.8) / 10}${size_unit}`;
                return phone2;
            } else if (phone.length >= 17 && phone.length < 22) {
                //this.phone_size = `${(size_val-(phone.length-9)*1.1)/10}${size_unit}`;
                return phone2;
            } else if (phone.length >= 22) {
                return `${phone2.substring(0, 10)}...${phone2.substring(phone2.length - 10, phone2.length)}`;
            } else return phone;
        },
        hideTrueInput() {
            this.fake_input = true;
        },
        saveNumber() {
            localStorage.setItem('phone_saved', this.phone)
        },
        doCall() {
            if (!this.phone) this.phone = this.phone_save;
            else {
                let data = { cid: this.contact_found && this.contact_found.cid, number: this.phone, video: false };
                this.$store.dispatch(`${PHONE_CNTL}/${CALLS_CONTROLLER}`, { type: 'call', data })
                this.saveNumber()
                this.reset()
                this.doClose()
            }
        },
        doClose() {
            this.$store.dispatch(`${PHONE_CNTL}/${CALLS_CONTROLLER}`, { type: 'dialer' })
        },
        reset() {
            this.phone = '';
            this.phone_save = '';
            this.backspace_show = false;
            this.phone_size = default_phone_size;
        },
        focus() {
            this.input.focus();
        },
        setBackspaceTimer() {
            this.backspace_timer_id = setTimeout(function () {
                this.phone = '';
                this.phone_size = default_phone_size;
            }.bind(this), 1500);
        },
        doBackspace() {
            clearTimeout(this.backspace_timer_id)
            if (this.phone.length > 0) this.phone = this.phone.substring(0, this.phone.length - 1);
            if (!this.phone) this.backspace_show = false;
        },
        setDialTimer(digit) {
            this.alt_timeout_id = setTimeout(function () {
                this.alt = true;
                this.phone += digit;
            }.bind(this), 1500);
        },
        doDial(digit) {
            clearTimeout(this.alt_timeout_id);
            if (!this.alt) this.phone += digit
            this.alt = false;
        }
    }
}
