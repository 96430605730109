var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "scheduleMsg", staticClass: "schedule-msg" }, [
    _vm._m(0),
    _c("div", { staticClass: "schedule-msg-title" }, [
      _c("span", [_vm._v(_vm._s(_vm.title))]),
      _c("div", { staticClass: "subtitle" }),
      _c("div", { staticClass: "subject subtitle" }, [
        _c(
          "span",
          {
            attrs: { title: _vm.subjectTooltip },
            on: {
              mouseover: function($event) {
                return _vm.subjectMouseOver($event)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("chat.schedule")) + ": " + _vm._s(_vm.subject))]
        )
      ]),
      _c("span", { staticClass: "subtitle author" }, [
        _vm._v(_vm._s(_vm.$t("chat.author")) + ": " + _vm._s(_vm.author))
      ]),
      _c("span", { staticClass: "subtitle date" }, [
        _vm._v(_vm._s(_vm.$t("date")) + " " + _vm._s(_vm.dateStr))
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "schedule-img" }, [
      _c("div", { staticClass: "icon-block" }, [
        _c("div", { staticClass: "icon-wrapper" }, [
          _c("img", { attrs: { src: "img/MS_Exchange.png" } })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }