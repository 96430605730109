//
//
//
//
//
//
//

import ActionsContainer from "./ActionsContainer.vue"
import ListItem from "../custom/ListItem.vue"
import InfoIcon from "./InfoIcon.vue"

export default {
    name: "MediaSearchInfoItems",
    components: {
        ActionsContainer,
        ListItem,
        InfoIcon,
    },
    props: ['isChannel'],
    data() {
        return {
            items: [
                { key: 0, value: this.$t('media-search.image'), icon: 'camera'},
                { key: 1, value: this.$t('media-search.video'), icon: 'video'},
                { key: 2, value: this.$t('media-search.audio'), icon: 'music'},
                { key: 3, value: this.$t('media-search.file'), icon: 'file'},
                { key: 4, value: this.$t('media-search.link'), icon: 'link'},
            ],
        }
    },
    computed: {
        getItems() {
            if (this.isChannel) return this.items.filter(i => i.key < 4)
            else return this.items
        }
    },
    methods: {
        getIconClass(item) {
            return 'fa-' + item.icon
        },
        openSearchMedia(tabIndexValue) {
            this.$emit('opensearchmedia', tabIndexValue)
        },
    }
}
