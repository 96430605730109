import { mapGetters } from 'vuex'
import { GET_CONNECTION_STATUS, GET_LOGIN_ERROR_TEXT } from '../../store/gettersTypes'
import { SOCKET, LOGIN } from '../../store/modulesNames'

const mixin = {
    data () {
        return {
            error: '',
            remember_me: false,
        }
    },
    computed: {
        isElectron () { return this.$store.getters['clientdata/getIsElectron'] },
        ws_connect_error () {
            return this[GET_CONNECTION_STATUS] !== 'connected'
        },
        ...mapGetters(SOCKET, [GET_CONNECTION_STATUS]),
    },
    methods: {
        getLoginError (e) {
            return this.$store.getters[`${LOGIN}/${GET_LOGIN_ERROR_TEXT}`](e)
        },
        showAlert (text, focus_on) {
            return new Promise((resolve, reject) => {

                const close = () => {
                    focus_on && setTimeout(() => focus_on.focus(), 200)
                    resolve()
                }

                this.modalOpen({
                    name: 'alert',
                    props: {
                        title: this.$t('errors.error'),
                        text,
                        btnOk: {
                            cb: () => close()
                        }
                    }
                });   
            })
        },
        showConfirm (title, text, focus_on) {
            return new Promise((resolve, reject) => {
                this.modalOpen({
                    name: 'confirm',
                    props: {
                        text,
                        title,
                        width: 480,
                        btnOk: {
                            text: this.$t('try-again'),
                            cb () { resolve(true) },
                        },
                        btnCancel: {
                            text: this.$t('exit'),
                            cb () { resolve(false) },
                        },
                    },
                    modalProps: {
                        clickToClose: false,
                    },
                })
            })
        },
    },
    directives: {
        focus: {
            inserted: function (el) {
                let input = el.querySelector('input')
                if (input) return setTimeout(() => input.focus(), 200)
                setTimeout(() => el.focus(), 200)
            },
        },
    },
}

export default mixin
