//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters,mapActions} from 'vuex';
import {ACS} from "../../store/modulesNames";
import {GET_ACS_ROLE, GET_ACS_CID, GET_ACS_INFO} from '../../store/gettersTypes'
import {ACT_ACS_DELETE_RECORD} from '../../store/actionsTypes'
import moment from 'moment'

export default {
    name: "AcsJournalList",
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        journal() {
            let {journal} = this[GET_ACS_INFO](this.contact_id);
            return journal || [];
        },
        locations() {
            let {locations} = this[GET_ACS_INFO](this.contact_id);
            return locations || [];
        },
        journal_sorted() {
            let description;
            for (let i in this.journal) {
                if (!!this.journal[i].comment) description = `${this.journal[i].text}. ${this.journal[i].comment}`;
                else description = this.journal[i].text;
                this.journal[i].description = description;
            }
            if (!this.journal) return [];
            let result =  this.journal.sort((a, b) => {
                if (a.from < b.from) return 1
                else if (a.from > b.from) return -1
                return 0
            });
            this.$emit('show', result);
            return result;
        },
        ...mapGetters(ACS, {hasRights: GET_ACS_ROLE, contact_id: GET_ACS_CID, [GET_ACS_INFO]: GET_ACS_INFO})
    },
    methods: {
        removeAcsRecord(from, to, recId) {
            const removeAcsRecord = async () => {
                try {
                    await this[ACT_ACS_DELETE_RECORD]({from, to, recId});
                } catch(e) {
                    this.modalOpen({
                        name: 'alert',
                        props: {
                            title: this.$t('errors.error'),
                            text: this.$t('cant-del-note')
                        }
                    })
                }
            }
            this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('acs.del-note'),
                    btnOk: {
                        cb: () => removeAcsRecord()
                    }
                }
            })
        },
        doContextMenu(event, from, to, recId) {
            const handlers = [];
            handlers.push({item_name: this.$t('chat.delete'), handler: () => {this.removeAcsRecord(from, to, recId);}});
            this.cmOpen(event, handlers, 'bottom-right');
        },
        translateStatus(val) {
            const acs = this.$t('acs')
            let russian = '';
            switch (val) {
                case 'sick':
                    russian = acs.sick;
                    break;
                case 'other':
                    russian = acs.other;
                    break;
                case 'meeting':
                    russian = acs.meeting;
                    break;
                case 'business':
                    russian = acs.business;
                    break;
                case 'work-home':
                    russian = acs['work-home'];
                    break;
                case 'education':
                    russian = acs.education;
                    break;
                case 'vacation':
                    russian = acs.vacation;
                    break;
                case 'compensatory':
                    russian = acs.dayoff;
                    break;
            }
            return russian;
        },
        ...mapActions(ACS, [ACT_ACS_DELETE_RECORD])
    },
    mounted() {
        this.$emit('show', null);
    },
    filters: {
        cut(str) {
            const limit = 39;
            if (str.length <= limit) return str;
            else return `${str.substring(0, limit)}...`;
        },
        dateCalc(val) {
            if ((''+val).length < 13) val *= 1000;
            return moment(val).format('DD.MM.YYYY HH:mm');
        },
        iconCalc(val) {
            let awesome_class = '';
            switch (val) {
                case 'sick':
                    awesome_class = 'far fa-briefcase-medical'
                    break;
                case 'meeting':
                    awesome_class = 'fa fa-briefcase';
                    break;
                case 'business':
                    awesome_class = 'fa fa-business-time';
                    break;
                case 'work-home':
                    awesome_class = 'fa fa-home';
                    break;
                case 'education':
                    awesome_class = 'fa fa-graduation-cap';
                    break;
                case 'vacation':
                    awesome_class = 'fa fa-suitcase-rolling';
                    break;
                case 'compensatory':
                    awesome_class = 'fa fa-walking';
                    break;
                case 'other':
                    awesome_class = 'fa fa-question';
                    break;
            }
            return awesome_class;
        },
    }
}
