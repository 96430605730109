//
//
//
//
//
//
//
//
//
//

import event_bus from '../../eventBus'
import ModalDialog from './ModalDialog.vue'
import modal_mixin from './modal-mixin'
import CustomInputHotkeys from '../custom/CustomInputHotKeys.vue'

export default {
    name: "SetHotkeysModal",
    components: {ModalDialog, CustomInputHotkeys},
    mixins: [modal_mixin],
    data() {
        return {
            compModalProps: {
                width: 450,
                height: 204,
                pivotY: 0.20,
                pivotX: 0.73,
                clickToClose: false,
            },
            hotKeysItem: {...this.props.hotKeysItem},
            cancelChange: false
        }
    },
    methods: {
        okBtn() {
            if (this.props.hotKeysItem !== this.hotKeysItem) {
                this.props.hotKeysItem = this.hotKeysItem
                event_bus.$emit('hotkeys-changed', this.hotKeysItem)
            }
            this.modalClose()
        },
        cancelBtn() {
            this.cancelChange = true
            // this.$nextTick( () => this.modalClose() )
            this.modalClose()
        },
    },
}
