//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export const PHONE_BTN_TYPES = {
    PAUSE:          'pause',
    MICROPHONE:     'mic',
    SPEAKER:        'speaker',
    MSG:            'msg',
    TRANSIT:        'transit',
    CONFERENCE:     'conference',
    NOTIFY:         'notify',
    CONTINUE:       'continue',
    ADD:            'add',
    BUTTONS:        'buttons',
    PARTICIPANTS:   'participants',
}

export default {
    props: {
        type: {
            type: String,
            required: true
        },
        disable: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        }

    },
    name: 'phone-function-button',
    data() {
        return {
            btns: {
                [PHONE_BTN_TYPES.PAUSE]: {fontClasses: ['fa', 'fa-pause'], name: this.$t('phone.pause')},
                [PHONE_BTN_TYPES.MICROPHONE]: {fontClasses: ['fas', 'fa-microphone'], name: this.$t('phone.mic')},
                [PHONE_BTN_TYPES.SPEAKER]: {fontClasses: ['fas', 'fa-volume'], name: this.$t('phone.speaker')},
                [PHONE_BTN_TYPES.MSG]: {fontClasses: ['fas', 'fa-comment'], name: this.$t('phone.msg')},
                [PHONE_BTN_TYPES.TRANSIT]: {fontClasses: ['fas', 'fa-arrows-alt-h'], name: this.$t('phone.transit')},
                [PHONE_BTN_TYPES.CONFERENCE]: {fontClasses: ['fas', 'fa-arrows-alt'], name: this.$t('phone.conference')},
                [PHONE_BTN_TYPES.NOTIFY]: {fontClasses: ['fas', 'fa-alarm-clock'], name: this.$t('phone.notifyWhenFree')},
                [PHONE_BTN_TYPES.CONTINUE]: {fontClasses: ['fa', 'fa-phone', 'fa-rotate-90'], name: this.$t('phone.continueTheCall')},
                [PHONE_BTN_TYPES.ADD]: {fontClasses: ['far', 'fa-plus'], name: this.$t('add')},
                [PHONE_BTN_TYPES.PARTICIPANTS]: {fontClasses: ['far', 'fa-users'], name: this.$t('phone.participants')},
                [PHONE_BTN_TYPES.BUTTONS]: {name: this.$t('phone.buttons')},
            }
        }
    },
    watch: {
        active(val) {
            if (val) this.btns[PHONE_BTN_TYPES.MICROPHONE].fontClasses =  ['fas', 'fa-microphone-slash']
            else this.btns[PHONE_BTN_TYPES.MICROPHONE].fontClasses =  ['fas', 'fa-microphone']
        }
    },
    computed: {
        typeIsButtons() {
            return this.type === PHONE_BTN_TYPES.BUTTONS
        },
        btnClasses() {
            return {
                'func-button-disable': this.disable,
                long: ['notify', 'continue'].includes(this.type)
            }
        },
        iconClasses() {
            return {'func-button-active': this.active}
        },
        fontClasses() {
            let btn = this.btns[this.type]
            return btn && btn.fontClasses
        },
        name() {
            let btn = this.btns[this.type]
            return btn && btn.name
        },
    },
    methods: {
        click() {
            if (!this.disable) this.$emit('click', this.type)
        }
    },
}
