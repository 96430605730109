//
//
//
//
//
//

import {mapActions, mapGetters} from 'vuex';
import {ACT_INFO_PUSH, ACT_UPDATE_SEARCHED_CONTACT} from '../../store/actionsTypes'
import {CONTACTS, INFO, LOGIN} from '../../store/modulesNames'
import IconImage from '../IconImage.vue';
import {GET_MERGED_CONTACT_BY_ID, GET_SERVER_API} from '../../store/gettersTypes'

export default {
    name: "PublicationContactItem",
    components: {IconImage},
    props: {
        data: {
            type: Object,
            required: true
        },
        onClick: {
            type: Boolean,
            default: true
        },
    },
    created() {
        if (this.data.cid && this.contact && !this.contact.cid && this.$store.getters[`${LOGIN}/${GET_SERVER_API}`] >= declarations.serverAPILevels.LEVEL_12) {
            this.$store.dispatch(`${CONTACTS}/${ACT_UPDATE_SEARCHED_CONTACT}`, {cid: this.data.cid}).catch()
        }
    },
    computed: {
        contact() { return this[GET_MERGED_CONTACT_BY_ID](this.data.cid) },
        text() { return this.contact.fio },
        photo() { return this.contact.photo },
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
    },
    methods: {
        doOnClick() {
            if (!this.onClick) return
            this[ACT_INFO_PUSH]({type: 'contact-info', params: {cid: +this.data.cid}})
        },
        ...mapActions(INFO, [ACT_INFO_PUSH])
    }
}
