'use strict';

export default function (bytes, decimals, kib = false) {
    if (bytes === 0) return '0 Б';
    if (isNaN(parseFloat(bytes)) && !isFinite(bytes)) return 'Not an number';
    const k = kib ? 1024 : 1000;
    const dm = decimals || 2;
    const sizes = kib ? ['Б', 'КиБ', 'МиБ', 'ГиБ', 'ТиБ', 'ПиБ', 'ЭиБ', 'ЗиБ', 'ИиБ'] : ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ', 'PB', 'ЭБ', 'ЗБ', 'ЙБ'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}