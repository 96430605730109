//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import { AJAX } from '../../store/modulesNames'
import { GET_AJAX_CALL_PROGRESS } from '../../store/gettersTypes'
import { ACT_ABORT_AJAX_CALL } from '../../store/actionsTypes'

export default {
    name: 'AjaxProgressCircle',
    props: {
        xhrId: {
            type: Number,
            required: true
        },
        radius: {
            type: Number,
            default: 16
        },
        width: {
            type: Number,
            default: 40
        },
        height: {
            type: Number,
            default: 40
        }
    },
    computed: {
        style() {
            let circumference = 2 * Math.PI * this.radius
            return {
                strokeDasharray: circumference,
                strokeDashoffset: (circumference) * ((100 - this.callProgress) / 100)
            }
        },
        callProgress() {
            return this[GET_AJAX_CALL_PROGRESS](this.xhrId)
        },
        ...mapGetters(AJAX, [GET_AJAX_CALL_PROGRESS])
    },
    methods: {
        cancel() {
            this[ACT_ABORT_AJAX_CALL](this.xhrId)
        },
        ...mapActions(AJAX, [ACT_ABORT_AJAX_CALL])
    }
}
