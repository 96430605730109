//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "confBtn",
    props: {
        size: {
            type: Number,
            required: false,
            default: 30
        },
        color: {
            type: String,
            required: false,
            default: '#ffffff'
        },
        bgColor: {
            type: String,
            required: false,
            default: '#F5F5F5'
        },
        imageType: {
            type: String,
            required: false,
            default: 'icon',
        },
        imageClasses: {
            type: String,
            required: false,
        },
        subTitle: {
            type: String,
            required: false,
        },
        subTitleInline: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false
        },
        clickable: {
            type: Boolean,
            required: false,
        },
        chevron: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    computed: {
        classes() {
            let classes = []
            if (this.disabled) classes.push('disabled')
            if (this.clickable) classes.push('clickable')
            return classes
        },
        cssVars() {
            const iconSize = Math.floor(this.size / 1.6)
            let fontSize = Math.floor(this.size / 2)
            let wrapperFlexDirection = 'column'
            if (this.subTitleInline) {
                fontSize+=((iconSize % 2) - (fontSize % 2)) ? 1 : 0
                wrapperFlexDirection = 'row'
            }
            return {
                '--size': this.size + 'px',
                '--icon-size': iconSize + 'px',
                '--font-size': fontSize + 'px',
                '--color': this.color,
                '--bg-color': this.bgColor,
                '--min-width': this.size + (this.chevron ? 60 : 0) + 'px',
                '--wrapper-flex-direction': wrapperFlexDirection
            }
        },
    }
}
