//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import {
    USERDATA,
    CONTACTS,
    VM_CONF,
} from '../../store/modulesNames'
import {
    GET_MERGED_CONTACT_BY_ID,
    GET_UID,
    GET_VM_CONF_CONNECTION_PARAMS,
} from '../../store/gettersTypes'


import ModalBtn from '../modal/ModalBtn.vue'

import EnterConferenceBody from './enterConferenceBody.vue'

import modal_mixin from '../modal/modal-mixin'

import {CONF_TYPES} from '../../constants'

export default {
    name: 'enterConference',
    components: {
        ModalBtn,
        EnterConferenceBody
    },
    mixins: [modal_mixin],
    props: {
        type: {
            type: String,
            default: CONF_TYPES.VM,
            required: false,
        },
        vcLink: {
            type: String,
            default: '',
            required: false,
        },
    },
    data () {
        return {
            compModalProps: {
                delay: 150,
                pivotY: 0.33,
                width: 458,
                height: 'auto',
                clickToClose: false,
                adaptive: true,
                styles: "min-width: 387px; overflow-x: auto;"                    
            },
            okText: this.$t('videomost.conf-plan'),
            placeholder: this.$t('videomost.conf-enterway'),
        }
    },
    computed: {
        ...mapGetters(CONTACTS, {[GET_MERGED_CONTACT_BY_ID]: GET_MERGED_CONTACT_BY_ID}),
        ...mapGetters(USERDATA, {[GET_UID]: GET_UID}),
        ...mapGetters(VM_CONF, [GET_VM_CONF_CONNECTION_PARAMS]),
        contact () {
            return this[GET_MERGED_CONTACT_BY_ID](this[GET_UID])
        },
        fio () {
            return this.contact.fio
        },
        integrationServer () {
            let {server} = this[GET_VM_CONF_CONNECTION_PARAMS]
            return server
        },
    },
    methods: {
        onOk() {
            this.modalClose()
            this.$refs.enterConfBody.enterConference()
        },
    },
}
