import BufferResizer from '../../common/BufferResizer'
import EventEmitter from "../../common/EventEmitter"
import { getAudioContext } from '../../common/Audio'

import InputMediaDevices from '../../../ext/media-devices';

export const errors = {
    MICROPHONE_NOT_FOUND: 'microphone-not-find',
}

export const events = {
    microphoneSample: 'microphoneSample',
    resampledSample: 'resampledSample',
    encodedSample: 'encodedSample',
    resizedSample: 'resizedSample',
}

class RadioRecorder extends EventEmitter {
    constructor ({outSampleRate = null, outSampleLength = null, outChannels = 1, codecWorker, audioResampler = false,}) {
        super()
        this.outSampleRate = outSampleRate
        this.outSampleLength = outSampleLength
        this.outChannels = outChannels
        this.stream = null
        this.streamNode = null
        this.audioCtx = null
        this.processorNode = null
        this.resampler = null
        this.bufferResizer = null
        this.codecWorker = codecWorker
        this.audioResampler = audioResampler
        this.processorURL = null
    }
    
    async start() {
        this.stop()
        try {
            this.audioCtx = getAudioContext()
        } catch(e) {
            console.log(`RadioRecorder.start error ${e.message}`)
            throw e
        }

        try {
            const audio = true, video = false;
            let options = InputMediaDevices(audio, video);
            this.stream = await navigator.mediaDevices.getUserMedia(options);
        } catch (e) {
            console.log(`RadioRecorder.start error ${e.message}`)
            throw new Error(errors.MICROPHONE_NOT_FOUND)
        }

        const processorSource = `
            class AudioProcessor extends AudioWorkletProcessor {
                constructor() {
                    super()
                    this.outBuff = []
                    this.port.onmessage = ({data}) => {
                        let { type, payload } = data
                        if (type === 'output-sample') {
                            this.outBuff.push(payload)
                        } 
                    }
                }
                
                process(inputs, outputs) {
                    let channelData = inputs[0][0]
                    if (channelData) this.port.postMessage(channelData)
            
                    const output = outputs[0][0]
                    let outSample = this.outBuff.shift()
                    
                    if (outSample) {
                        output.set(outSample) 
                    }
                    return true
                }
            }
            
            try {
                registerProcessor('audio-processor', AudioProcessor)
            } catch(e) {
                console.log('')
            }
        `
        try {
            if (!this.processorNode) {
                let processorBlob = new Blob([processorSource], { type: 'text/javascript' })
                this.processorURL = URL.createObjectURL(processorBlob)
                await this.audioCtx.audioWorklet.addModule(this.processorURL)
                this.processorNode = new AudioWorkletNode(this.audioCtx, 'audio-processor')
            }
        } catch (e) {
            console.log('!! radioRecorder  audioWorklet.addModule error', e)
        }
        
        // Пойманное аудио с микрофона
        this.streamNode = this.audioCtx.createMediaStreamSource(this.stream)

        this.processorNode.port.onmessage = ({data}) => {
            this._onAudioProcessSample(data)
        }

        // Подключение узлов
        this.streamNode.connect(this.processorNode)
        //this.processorNode.connect(this.audioCtx.destination)

        // Количество каналов на выходе
        let outChannels = 1 // this.outChannels || this.streamNode.channelCount

        // Создаем ресемплер
        if (this.outSampleRate && this.audioCtx.sampleRate !== this.outSampleRate) {
            await this._createResampler(this.audioCtx.sampleRate, this.outSampleRate, outChannels)
        }

        // Создаем ресайзер
        // if (this.audioResampler || (this.outSampleLength && this.processorNode.bufferSize !== this.outSampleLength) ) {
        // }
        this._createBufferResizer(outChannels)
    }

    stop() {
        if(this.processorNode){
            this.processorNode.disconnect()
            this.streamNode.disconnect()
            this.stream.getTracks().forEach(track => track.stop())
        }
    }

    close() {
        this.stop()
        if (this.codecWorker) this.codecWorker.terminate()
        if (this.audioResampler) this.audioResampler.terminate()
    }

    async _onAudioProcessSample(sample) {
        this.emit(events.microphoneSample, sample)
        if (this.audioResampler) {
            let resampled = await this.audioResampler.process(sample)
            this.emit(events.resampledSample, resampled)
            this.bufferResizer.push(resampled)
        } else if (this.bufferResizer) this.bufferResizer.push(sample)
    }

    async _createResampler(inSampleRate, outSampleRate, channels) {
        try {
            await this.audioResampler.init({
                channels,
                in_sampling_rate: inSampleRate,
                out_sampling_rate: outSampleRate
            })
        } catch (e) {
            console.log(`RadioRecorder._createResampler error ${e.message}`)
            throw e
        }
    }

    _createBufferResizer(outChannels) {
        this.bufferResizer = new BufferResizer({ outLength: this.outSampleLength * outChannels })
        this.bufferResizer.on('sample', async (sample) => {
            this.emit(events.resizedSample, sample)
            let encoded = await this.codecWorker.encode({data: sample.buffer})
            this.emit(events.encodedSample, encoded)
        })
    }
}

export default RadioRecorder