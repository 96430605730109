var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        headerClose: true,
        title: _vm.title
      }
    },
    [
      _c("div", { staticClass: "settings" }, [
        _c(
          "div",
          { staticClass: "items-list" },
          _vm._l(_vm.mapFilters, function(item, i) {
            return _c("list-item", {
              key: i,
              attrs: {
                title: _vm.$t(item.name),
                link: true,
                close: _vm.dataSearch(item.type),
                footer: _vm.dataSearch(item.type) || _vm.$t(item.default)
              },
              on: {
                clear: function($event) {
                  return _vm.clear(item.type)
                }
              },
              nativeOn: {
                click: function($event) {
                  return _vm.addData(item.type)
                }
              }
            })
          }),
          1
        )
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "btn", on: { click: _vm.onCancel } }, [
          _vm._v(_vm._s(_vm.$t("cancel")))
        ]),
        _vm.existBtn
          ? _c("div", { staticClass: "btn", on: { click: _vm.addFilter } }, [
              _vm._v(_vm._s(_vm.$t("apply")))
            ])
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }