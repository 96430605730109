import Vue from 'vue'

import { modalOpen, modalClose } from './modal'
import lookForLinks from './lookForLinks'
import lookForRefs from './lookForRefs'
import { messageTextFormat, applyInputTextFormat, extractInputTextFormat, convertOldRefsToNewFormat } from './textFormat'
import { formatPhoneNumber } from './otherMixins'
import { getColorClassByMessage, getBGClassByMessage, getColorClassByName, getBgColorClassByName, getColorClassById, getBgColorClassById } from './colorClasses'
import {
    nodeInSelection,
    removeSelectionRanges,
    getSelectedText,
    getPureSelectedText,
    subscribeOnSelectionChange,
    unsubscribeOnSelectionChange,
} from './textSelection'
import { wrapEmoji, unwrapEmojiToNative, unwrapEmojiToNativeText, getEmojiNative, emojiToImg, getEmojisIndexes } from './emojis'
import { getHotKeysTitleByAction } from './hotKeys'
import { cmOpen } from './contextMenu'

const globalMixin = {
    methods: {
        modalOpen,
        modalClose,
        messageTextFormat,
        applyInputTextFormat,
        extractInputTextFormat,
        convertOldRefsToNewFormat,
        lookForLinks,
        lookForRefs,
        getColorClassByMessage,
        getBGClassByMessage,
        getColorClassByName,
        getBgColorClassByName,
        getColorClassById,
        getBgColorClassById,
        nodeInSelection,
        removeSelectionRanges,
        getSelectedText,
        getPureSelectedText,
        subscribeOnSelectionChange,
        unsubscribeOnSelectionChange,
        wrapEmoji,
        unwrapEmojiToNative,
        unwrapEmojiToNativeText,
        getEmojiNative,
        emojiToImg,
        getEmojisIndexes,
        formatPhoneNumber,
        getHotKeysTitleByAction,
        cmOpen,
    }
}

Vue.mixin(globalMixin)