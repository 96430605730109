import {mapActions} from "vuex"
import {CHANNELS} from "../../store/modulesNames"
import {ACT_CHANGE_CHANNEL_USERS, ACT_DELETE_CHANNEL_USERS} from "../../store/actionsTypes"

export default {
    methods: {
        _findChannelMember(cid) {
            return this.channel_members.find((member) => member.cid === cid)
        },
        _setMemberUserPrivilege({cid, privilege}) {
            const status = (this._findChannelMember(cid) || {}).status;
            this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('member-rights-conf'),
                    btnOk: {
                        cb: async () => {
                            await this[ACT_CHANGE_CHANNEL_USERS]({
                                chId: this._channel_data.chId,
                                contacts: [{
                                    cid,
                                    privilege,
                                    status,
                                }]
                            })
                            this.refreshMembers()
                        }
                    },
                }
            })
        },
        _delMember(cid) {
            this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('delete-member-conf'),
                    btnOk: {
                        cb: async () => {
                            await this[ACT_DELETE_CHANNEL_USERS]({chId: this._channel_data.chId, contacts: [{cid}]})
                            const member = this._findChannelMember(cid)
                            if (member) { 
                                this.channel_members.splice(this.channel_members.indexOf(member), 1)
                                await this[ACT_CHANGE_CHANNEL_USERS]({chId: this._channel_data.chId, contacts: this.channel_members})
                            }
                            this.refreshMembers()
                        }
                    },
                }
            })
        },
        ...mapActions(CHANNELS, [ACT_CHANGE_CHANNEL_USERS, ACT_DELETE_CHANNEL_USERS]),
    }
}