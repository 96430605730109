export default {
    emitters: {
        setLocation(params) {
            return new Promise((resolve, reject) => {
                let {locationRid, status, statusTime} = params
                let data = {locationRid, status, statusTime}
                this.log(`set-location: > ${JSON.stringify(data)}`)
                this.socket.emit('set-location', data, err => {
                    if (err && err.error) {
                        this.log(`set-location: < error: ${err.error}`)
                    } else {
                        this.log('set-location: < success')
                    }
                })
            })
        },
        async getLocation(cid) {
            let data = {cid}
            this.log(`get-location: > ${JSON.stringify(data)}`)
            try {
                let res = await this._emitWithTimeOut('get-location', data)
                this.log(`get-location: < ${JSON.stringify(data)}`)
                return res
            } catch (err) {
                this.log(`get-location: < error`)
                throw('get-location failed')
            }
        },
        getLocationsConfig() {
            return new Promise((resolve, reject) => {
                this.log('get-locations-config: >')
                this.socket.emit('get-locations-config', null, result => {
                    if (result) {
                        this.log(`get-locations-config: < ${result}`)
                        resolve(result)
                    } else {
                        this.log('get-locations-config: < error')
                        reject()
                    }
                })
            })
        }
    },
    listeners: {},
}