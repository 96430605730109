import module from '../../modules/video-conf-new'
import {
    ACT_VC_TOGGLE_MINIMIZE,
    ACT_VC_TOGGLE_FULLSCREEN,
} from "../../actionsTypes"

const actions = {
    // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//     [ACT_VC_TOGGLE_MINIMIZE]: () => {},
//     [ACT_VC_TOGGLE_FULLSCREEN]: () => {},
    // #endif
}

Object.assign(module.actions, actions)

export default module;