import EventEmitter from "./EventEmitter"
import {dropLastWhile} from "ramda"

class PerTimeCounter extends EventEmitter {
    constructor (updateInterval = 1000, filterTime = 1000) {
        super()
        this.updateInterval = updateInterval
        this.filterTime = filterTime
        this.timeArray = []
        this.emitInterval = 0
        this.idleCount = 0
    }

    increce() {
        this.timeArray.push(this._getCurrentTime())
        if (!this.emitInterval) {
            this._emitCurrentRate()
            this._startInterval()
        }
    }

    _startInterval() {
        this.emitInterval = setInterval(() => {
            this._emitCurrentRate()
        }, this.updateInterval)
    }

    _emitCurrentRate() {
        let currentTime = this._getCurrentTime()
        this.timeArray = this.timeArray.filter((itemTime) => currentTime - itemTime <=  this.filterTime)
        let count = this.timeArray.length
        if (!count) {
            this.idleCount += 1
            if (this.idleCount > 20) this._stopInterval()
        }
        this.emit('currentRate', this.timeArray.length)
    }

    _stopInterval() {
        if (this.emitInterval) {
            this.emitInterval = clearInterval(this.emitInterval)
            this.idleCount = 0
        }
    }

    _getCurrentTime() {
        return (new Date()).getTime()
    }
}

export default PerTimeCounter