//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'

import CustomInputText from '../custom/CustomInputText.vue'
import DateRangePicker from '../settings/DateRangePicker.vue'
import CustomRadio from '../custom/CustomRadio.vue'
import ListItem from '../custom/ListItem.vue'
import ModalBtn from '../modal/ModalBtn.vue'
import CustomCheckbox from '../custom/CustomCheckboxRound.vue'
import CustomSelect from '../custom/CustomSelect.vue'
import videomostSettings from './videomostSettings.vue'
import addToCalendar from './addToCalendar.vue'
import confManagementMixin from './confManagementMixin'

import {
    CONF_SCHEDULE_PERIODICITY_TYPES,
    CONF_SCHEDULE_PROPS,
    CONF_SCHEDULE_PERIODICITY_DAYS,
    CONF_SCHEDULE_PERIODICITY_PROPS,
} from '../../store/modules/video-conf-abstract'

import {CONF_TYPES} from '../../constants'

const defaultStartTime = () => {
    let dt = moment()
    const currentHour = new Date().getHours()
    const currentMinute = new Date().getMinutes()
    const roundedMinutes = Math.ceil((currentMinute + 5)/5)*5
    dt.hour(currentHour)
    dt.minute(roundedMinutes)
    dt.second(0)
    return dt.unix()
}
 
const defaultEndTime = () => {
    return moment.unix(defaultStartTime()).add(1, 'hour').unix()
}

export default {
    name: 'confSettingsBase',
    components: { 
        CustomInputText, DateRangePicker, CustomRadio, 
        ListItem, ModalBtn, CustomCheckbox, 
        CustomSelect, videomostSettings, addToCalendar 
    },
    mixins: [confManagementMixin],
    props:  {
        conf: {
            type: Object,
            required: false,
            default: () => ({
                [CONF_SCHEDULE_PROPS.TOPIC]: '',
                [CONF_SCHEDULE_PROPS.ID]: Math.random().toString().substr(2, 6),
                [CONF_SCHEDULE_PROPS.PASSWORD]: Math.random().toString().substr(2, 4),
                //[CONF_SCHEDULE_PROPS.START_TIME]: moment().unix(),
                [CONF_SCHEDULE_PROPS.START_TIME]: defaultStartTime(),
                //[CONF_SCHEDULE_PROPS.END_TIME]: moment().unix() + 3600,
                [CONF_SCHEDULE_PROPS.END_TIME]: defaultEndTime(),
                startUTime: 0,
                finishTime: '',
                finishUTime: 0,
            })
        },
        isNew: {
            type: Boolean,
            default: false,
            required: false
        },
        readonly: {
            type: Boolean,
            default: false,
            required: false
        },
        fieldsToShow: {
            type: Array,
            default: () => Object.values(CONF_SCHEDULE_PROPS)
        },
        extended: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {
            mainTitle: this.$t('videomost.settings-title'),
            compModalProps: {
                delay: 100,
                pivotY: 0.5,
                width: 540,
                height: 'auto',
                clickToClose: false,
                adaptive: true,
                styles: "min-width: 300px;"                    
            },
            from: 0,
            to: 0,
            periodItemValue: CONF_SCHEDULE_PERIODICITY_TYPES.DAY,
            periodItems: [
                {
                    value: CONF_SCHEDULE_PERIODICITY_TYPES.DAY,
                    name: this.$t('videomost.periods.days'),
                    selected: true,
                },
                {
                    value: CONF_SCHEDULE_PERIODICITY_TYPES.WEEK,
                    name: this.$t('videomost.periods.weeks'),
                    selected: false,
                },
                {
                    value: CONF_SCHEDULE_PERIODICITY_TYPES.MONTH,
                    name: this.$t('videomost.periods.months'),
                    selected: false,
                },
            ],
            periodCounter: 1,
            periodDays: [
                {
                    label: this.$t('videomost.weekdays.monday'),
                    value: CONF_SCHEDULE_PERIODICITY_DAYS.MN
                },
                {
                    label: this.$t('videomost.weekdays.tuesday'),
                    value: CONF_SCHEDULE_PERIODICITY_DAYS.TU
                },
                {
                    label: this.$t('videomost.weekdays.wednesday'),
                    value: CONF_SCHEDULE_PERIODICITY_DAYS.WE
                },
                {
                    label: this.$t('videomost.weekdays.thursday'),
                    value: CONF_SCHEDULE_PERIODICITY_DAYS.TH
                },
                {
                    label: this.$t('videomost.weekdays.friday'),
                    value: CONF_SCHEDULE_PERIODICITY_DAYS.FR
                },
                {
                    label: this.$t('videomost.weekdays.saturday'),
                    value: CONF_SCHEDULE_PERIODICITY_DAYS.SA
                },
            ],
            confTypes: [],
            okText: this.$t('videomost.conf-run'),
            cancelText: this.$t('videomost.template-save'),
            confSettingsText: this.$t('videomost.conf-settings'),
            subjectMissed: false,
            fromMissed: false,
            toMissed: false,
            confIdMissed: false,
            confPasswordMissed: false,
            initialConfId: '',
            missedFields: Object.values(CONF_SCHEDULE_PROPS).reduce((prev, cur) => {
                prev[cur] = false
                return prev
            }, {}),
            confProps: {},
            isAddCalendar: false,
            attendees: [],
            periodicity: false
        }
    },
    created () {
        console.log('~~~~~~~~~~~this.conf ' + JSON.stringify(this.conf))
        Object.entries(CONF_SCHEDULE_PROPS).forEach(([key, value]) => this[key] = value)
        this.CONF_SCHEDULE_PROPS = CONF_SCHEDULE_PROPS
        this.CONF_SCHEDULE_PERIODICITY_TYPES = CONF_SCHEDULE_PERIODICITY_TYPES
        this.confProps = { ...this.conf }
        this.confTypes = this.getTypeOptions()
        let selectedType = this.conf[CONF_SCHEDULE_PROPS.TYPE] || this.confTypes[0].value
        this.setConfTypeValue(selectedType)

        console.log('this.confProps[CONF_SCHEDULE_PROPS.PERIODICITY]', this.confProps[CONF_SCHEDULE_PROPS.PERIODICITY])
        const periodicity = this.confProps[CONF_SCHEDULE_PROPS.PERIODICITY]
        if (periodicity) {
            this.periodicity = true
            this.periodItemValue = periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.TYPE]
            if (periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.TYPE] === CONF_SCHEDULE_PERIODICITY_TYPES.WEEK) {
                this.$nextTick(() => periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.DAYS].forEach((day) => this.setPeriodDayValue(day, true)))
            }

        }
    },
    mounted() {
        console.log('~~~~~~~~~~~~mounted')
        console.log('~~~~~~~~~~~~conf', JSON.stringify(this.conf))
        //if (this.conf[CONF_SCHEDULE_PROPS.TYPE] === CONF_TYPES.VM) this.getVmExtProps()
    },
    computed: {
        getType() {
            return (this.confTypes.find((item) => item.selected) || {value: CONF_TYPES.VM}).value
        },
        repeatPhrase() {
            let result = { eachWord: '', periodWord: '' }
            if (this.periodItemValue === CONF_SCHEDULE_PERIODICITY_TYPES.DAY) {
                result.eachWord = this.$t('videomost.periods.eachNomM')
                result.periodWord = this.$t('videomost.periods.dayNom')
            }
            else if (this.periodItemValue === CONF_SCHEDULE_PERIODICITY_TYPES.WEEK)  {
                result.eachWord = this.$t('videomost.periods.eachGenF')
                result.periodWord = this.$t('videomost.periods.weekGen')
            }
            else if (this.periodItemValue === CONF_SCHEDULE_PERIODICITY_TYPES.MONTH)  {
                result.eachWord = this.$t('videomost.periods.eachNomM')
                result.periodWord = this.$t('videomost.periods.monthNom')
            }
            return result
        },
        startUTime () {
            return +this.conf.startUTime
        },
        finishUTime () {
            return +this.conf.finishUTime
        },
        correctFromTo () {
            const startUTime = +this.conf.startUTime
            const finishUTime = +this.conf.finishUTime
            return startUTime && finishUTime && startUTime < finishUTime
        },           
    },
    watch: {
        periodicity(val) {
            console.log('!!!!!!!watch periodicity ' + val)
            if (val) {
                this.confProps[CONF_SCHEDULE_PROPS.PERIODICITY] = {
                    [CONF_SCHEDULE_PERIODICITY_PROPS.TYPE]: CONF_SCHEDULE_PERIODICITY_TYPES.DAY
                }
            } else {
                delete this.confProps[CONF_SCHEDULE_PROPS.PERIODICITY]
            }
        },
        periodItemValue(val) {
            this.periodItems.forEach(item => {
                (item.value === val) ? item.selected = true : item.selected = false
            })
            this.confProps[CONF_SCHEDULE_PROPS.PERIODICITY] = { [CONF_SCHEDULE_PERIODICITY_PROPS.TYPE]: val, num: 1 }
        },
        startUTime(val) {
            if (val) this.fromMissed = false
        },
        finishUTime(val) {
            if (val) this.toMissed = false
        },
        correctFromTo(val) {
            if (val) this.fromMissed = this.toMissed = false
        }
    },
    methods: {
        setConfTypeValue(val) {
            console.log('~~~~~~setConfTypeValue', val)
            this.confTypes.forEach(item => {
                (item.value === val) ? item.selected = true : item.selected = false
            })
            this.confProps[CONF_SCHEDULE_PROPS.TYPE] = val
        },
        getFieldDefault(field) {
            return this.confProps[field]
        },
        getFieldValue(field) {
            return this.confProps[field]
        },
        isFieldVisible(field) {
            return this.fieldsToShow.includes(field)
        },
        isFieldMissed(field) {
            return this.isFieldVisible(field) && this.missedFields[field]
        },
        setFieldMissed(field) {
            if (this.isFieldVisible(field)) this.missedFields[field] = true
        },
        dropFieldMissed(field) {
            if (this.isFieldVisible(field)) this.missedFields[field] = false
        },
        periodDayIsSet(day) {
            const period = this.confProps[CONF_SCHEDULE_PROPS.PERIODICITY]
            const days = period[CONF_SCHEDULE_PERIODICITY_PROPS.DAYS]
            console.log(JSON.stringify(days))
            return days && days.includes(day)
        },
        setPeriodDayValue(day, val) {
            const period = this.confProps[CONF_SCHEDULE_PROPS.PERIODICITY]
            let days = period[CONF_SCHEDULE_PERIODICITY_PROPS.DAYS]
            if (val) {
                if (!days) days = period[CONF_SCHEDULE_PERIODICITY_PROPS.DAYS] = []
                if (!days.includes(day)) days.push(day)
            } else {
                period[CONF_SCHEDULE_PERIODICITY_PROPS.DAYS] = days && days.filter((d) => d !== day)
            }
            console.log('!!!!!!!!!days ' + JSON.stringify(days))
        },
        getConf(check = true) {
            const confProps = { ...this.confProps }
            if (check) {
                const test = [
                    CONF_SCHEDULE_PROPS.TOPIC,
                    CONF_SCHEDULE_PROPS.START_TIME,
                    CONF_SCHEDULE_PROPS.END_TIME,
                    CONF_SCHEDULE_PROPS.ID,
                    CONF_SCHEDULE_PROPS.PASSWORD,
                ].reduce((prev, cur) => {
                    let check = true
                    if (this.isFieldVisible(cur) && !confProps[cur]) {
                        this.setFieldMissed(cur)
                        check = false
                    }
                    return prev && check
                }, true)
                if (!test) return false
            }
            let additionalProps = this.$refs.additionalProps && this.$refs.additionalProps.getSettings()
            if (additionalProps) confProps.additionalProps = additionalProps
            const { periodicity, startTime } = confProps
            confProps[CONF_SCHEDULE_PROPS.WAITING] = false //@todo пока так
            confProps[CONF_SCHEDULE_PROPS.VIDEO_DISABLED] = false //@todo пока так
            if (periodicity) {
                let recurrence = this.getCalendarRecurrence(periodicity, startTime)
                confProps.recurrence = recurrence
            }
            if (this.isAddCalendar && this.getType === 'roschat') {
                confProps.isAddCalendar = this.isAddCalendar
                if (this.attendees.length) confProps.attendees = this.attendees
            }
            return confProps
        },
        getStartTime() {
            let startTime = this.conf[CONF_SCHEDULE_PROPS.START_TIME]
            return this.getLocalTime(startTime)
        },
        getFinishTime() {
            return this.getLocalTime(this.conf[CONF_SCHEDULE_PROPS.END_TIME])
        },
        getLocalTime(dt) {
            return moment.unix(dt).local().format('YYYY.MM.DD HH:mm:00')
        },
        attendeesChange(val) {
            if (val && val.length) this.attendees = val
        }
    },
}
