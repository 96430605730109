//
//
//
//
//
//

export default {
    name: "ContextMenuGroup",
    props: {
        header: {
            type: [String, Number],
            required: false
        },
        select: {
            type: Boolean,
            required: false,
            default: false
        },
    }
}
