/**
 * Created by Aleksey on 24.07.2018.
 */
import Vue from 'vue';
import proto from "../../protocol";
import moment from 'moment';
import {
    GET_MAIN_TYPE,
    GET_SCROLL_TO_PUBLICATION,
    GET_CHANNEL_SEARCH_STRING,
} from '../gettersTypes';
import {
    MUT_SCROLL_TO_PUBLICATION,
    MUT_CHANNEL_SET_SEARCH
} from '../mutationsTypes';
import {
    ACT_CHANNELS_OPEN,
    ACT_OPEN_CHANNEL,
    ACT_UPDATE_PUBLICATION,
    ACT_CHECK_PUBLICATION_IN_SERVER,
    ACT_CHAT_UPDATE_REPLY,
    ACT_CHANNEL_SET_SEARCH,
    ACT_CHANNEL_SEARCH,
    ACT_OPEN_CUSTOM_NOTIFICATION,
    ACT_ADD_CONTACT_SHORT_INFO,
    ACT_PUBLICATION_LIKE,
    ACT_ADD_PUBLICATION_COMMENT,
} from '../actionsTypes'
import {
    CONTENT_MANAGER,
    CHAT,
    CHANNELS,
    NOTIFICATIONS,
    CONTACTS,
} from '../modulesNames'

import { i18n } from './../../../ext/i18n'

const locale = i18n.messages[i18n.locale]

const ChannelStore = class {
    constructor() {
        this.state = {
            opened: false,
            publications: [],
            visible: false,
            chId: null,
            comments: [],
            scrollToPublication: null,
            channelSearchString: '',
        };
        this.getters = {
            visible(state) {return state.visible;},
            isOpened(state) { return state.opened; },
            chId(state) { return state.chId; },
            getPublications: state => state.publications.sort((a, b) => b.pubId - a.pubId),
            getPublicationById: state => {
                return params => {
                    return this.getPublicationById(state.publications, params.pubId);
                };
            },
            getPublicationComments(state) {
                return state.comments;
            },
            [GET_SCROLL_TO_PUBLICATION](state) {
                return state.scrollToPublication;
            },
            getPublicationPreparedDataItems: (state, getters) => params => { //@todo можно перенести, если сделать единый компонент для публикаций в канале и диалоге редактирования
                const publication = params.publication ? params.publication : getters.getPublicationById({pubId: params.pubId});
                const data = publication && publication.data || [];
                const result = {contacts: [],with_imgs: [],audios: [],files: []};
                data.forEach(function (current, index) {
                    var item;
                    switch (current.type) {
                        case 'image':
                            item = prepareImageData(current);
                            result.with_imgs.push({...item, ...{originIndex: index, index: result.with_imgs.length}});
                            break;
                        case 'video':
                            item = prepareVideoData(current);
                            result.with_imgs.push({...item, ...{originIndex: index, index: result.with_imgs.length}});
                            break;
                        case 'audio':
                            item = prepareAudioData(current);
                            result.audios.push(item);
                            break;
                        case 'file':
                            item = prepareFileData(current);
                            result.files.push(item);
                            break;
                        case 'contact':
                            item = prepareContactData(current);
                            result.contacts.push(item);
                            break;
                        case 'location':
                            item = current;
                            result.geo_location = item;
                            break;
                        case 'poll':
                            item = current;
                            result.poll = current;
                            break;
                    }
                    if (item) {
                        item.index = index;
                    }
                });
                return result;
            },
            [GET_CHANNEL_SEARCH_STRING](state) {
                return state.channelSearchString
            },
        };
        this.actions = {
            async [ACT_OPEN_CHANNEL]({state, dispatch, commit, rootGetters}, params) {
                let main_type = rootGetters[`${CONTENT_MANAGER}/${GET_MAIN_TYPE}`];
                if(main_type === 'channel' && state.visible && params === state.chId) return;
                dispatch(`${CHANNELS}/${ACT_CHANNELS_OPEN}`, params, {root: true});
                dispatch(`${CHAT}/${ACT_CHAT_UPDATE_REPLY}`, null, {root: true});
            },
            visible(obj, visible) {
                obj.commit('visible', visible);
            },
            update({commit, dispatch}, params) {
                return new Promise((resolve, rejact) => {
                    proto.getPublications(params, publications => {
                        publications = publications.sort((a, b) => {
                            if (a.fio) dispatch(`${CONTACTS}/${ACT_ADD_CONTACT_SHORT_INFO}`, a, {root: true})
                            if (isNaN(a.pubId) || isNaN(b.pubId) || a.pubId === b.pubId) return 0;
                            if (a.pubId < b.pubId) return -1;
                            if (a.pubId > b.pubId) return 1;
                        });
                        commit('updatePublications', { publications: publications, chId: params.chId});
                        resolve(publications) // возвращаем для прекращения отображения загрузки
                    })
                })                 
            },
            async [ACT_CHECK_PUBLICATION_IN_SERVER](obj, params) {
                try {
                    return await proto.getPublicationDetails({chId:params.chId, pubId:params.pubId});
                } catch(e) {
                    return null;
                }
            },
            async [ACT_UPDATE_PUBLICATION](obj, params) {
                let publication = await proto.getPublicationDetails({chId:params.chId, pubId:params.pubId});
                obj.commit('updatePublication', {publication: publication});
            },
            async [ACT_PUBLICATION_LIKE]({commit}, params) {
                try {
                    await proto.publicationLike(params)
                } catch (e) {}
                commit('likeToggle', params)
            },
            async [ACT_ADD_PUBLICATION_COMMENT]({commit, dispatch}, params) {
                try {
                    await proto.addPublicationComment(params)
                    await dispatch('publicationComments', {
                        chId: params.chId,
                        pubId: params.pubId,
                        count: 100
                    })
                    await dispatch(ACT_UPDATE_PUBLICATION, {
                        chId: params.chId,
                        pubId: params.pubId
                    })
                } catch (e) {}
            },
            async publicationComments({dispatch, commit}, data) {
                let comments = await proto.getPublicationComments(data)
                comments.forEach(comment => { if (comment.fio) dispatch(`${CONTACTS}/${ACT_ADD_CONTACT_SHORT_INFO}`, comment, {root: true}) })
                commit('addPublicationComments', comments)
            },
            async addPublication({ dispatch }, publication) {
                try {
                    publication.pubId = await proto.addPublication(publication);
                    dispatch('channels/update', null, {root: true});
                    dispatch('updatePublication', publication);
                    dispatch('channels/watchChannelPublications', {chId: publication.chId}, {root: true});
                } catch (e) {
                    if (e.message === 'dlp-forbidden') {
                        dispatch(`${NOTIFICATIONS}/${ACT_OPEN_CUSTOM_NOTIFICATION}`, {
                            type: 'alert',
                            title: locale['dlp-alert-data-forbidden-title'],
                            subtitle: locale['dlp-alert-data-forbidden-publication'],
                        }, {root: true})
                    }
                }
            },
            async changePublication({dispatch}, publication) {
                try {
                    await proto.changePublication(publication)
                    dispatch('channels/update', null, {root: true})
                    dispatch('updatePublication', publication)
                    dispatch('channels/watchChannelPublications', {chId: publication.chId}, {root: true})
                } catch (e) {
                    if (e.message === 'dlp-forbidden') {
                        dispatch(`${NOTIFICATIONS}/${ACT_OPEN_CUSTOM_NOTIFICATION}`, {
                            type: 'alert',
                            title: locale['dlp-alert-data-forbidden-title'],
                            subtitle: locale['dlp-alert-data-forbidden-publication'],
                        }, {root: true})
                    }
                }
            },
            async removePublication(obj, publication) {
                try {
                    await proto.delPublication(publication);
                    obj.dispatch('channels/update', null, {root: true});
                    obj.commit('removePublication', publication);
                } catch (e) {}
            },
            [ACT_CHANNEL_SET_SEARCH]({commit}, str) {
                commit(MUT_CHANNEL_SET_SEARCH, str)
            },
            async [ACT_CHANNEL_SEARCH]({}, params) {
                return proto.searchChannelPublications(params)
            }, 
        };
        this.mutations = {
            chId(state, chId) {
                state.chId = chId;
            },
            visible(state, visible) {
                state.visible = visible;
            },
            setCurrentChannel(state, params) {
                if (state.chId === params.chId) return;
                Vue.set(state, 'chId', params.chId);
                state.publications.splice(0, state.publications.length);
            },
            updatePublications: (state, params) => {
                if (params.chId) state.publications = state.publications.filter((publication) => publication.chId === params.chId)
                for (var i = 0, count = params.publications.length; i < count; i++) {
                    params.publications[i].unixLastTime = moment().subtract(params.publications[i].time || 0, 'seconds').unix();
                    this.mutations.updatePublication(state, {publication: params.publications[i]});
                }
            },
            clearPublications: (state) => {
                state.publications.length && state.publications.splice(0, state.publications.length);
            },
            updatePublication: (state, params) => {
                if (state.chId !== params.publication.chId) return;
                var old_publication = this.getPublicationById(state.publications, params.publication.pubId),
                    index = state.publications.indexOf(old_publication);
                params.publication.unixLastTime = moment().subtract(params.publication.time || 0, 'seconds').unix();
                if (old_publication) {
                    if (old_publication.unixLastTime === params.publication.unixLastTime) params.publication.unixLastTime+= 0.001
                    Vue.set(state.publications, index, params.publication);
                }  else {
                    state.publications.push(params.publication);
                    state.publications.sort((a, b) => a.time - b.time)
                }
            },
            removePublication: (state, params) => {
                var index = state.publications.indexOf(this.getPublicationById(state.publications, params.pubId));
                if (index !== -1) state.publications.splice(index, 1);
            },
            likeToggle: (state, params) => {
                state.publications.forEach((item, i) => {
                    if(item.pubId === params.pubId) {
                        if(!('like' in item)) state.publications[i].like = false;
                        if (item.like) state.publications[i].likesCount--;
                        else state.publications[i].likesCount++;
                        Vue.set(state.publications[i], 'like', !item.like);
                    }
                });
            },
            addPublicationComments(state, comments) {
                Vue.set(state, 'comments', comments);
            },
            deleteComments(state) {
                Vue.set(state, 'comments', []);
            },
            [MUT_SCROLL_TO_PUBLICATION](state, params) {
                Vue.set(state, 'scrollToPublication', params);
            },
            [MUT_CHANNEL_SET_SEARCH](state, str) {
                state.channelSearchString = str
            },
        };
    }

    getPublicationById(publications, pubId) {
        return publications.find(publication => {
            return publication.pubId === pubId;
        });
    }

    get store() {
        return {
            namespaced: true,
            state: this.state,
            getters: this.getters,
            actions: this.actions,
            mutations: this.mutations
        };
    }
};

const channelStore = (new ChannelStore()).store;
export default channelStore;

function prepareContactData(data) {
    // var contact = app.getContact(data.cid);
    return {
        cid: data.cid,
        //photo: contact.photo,
        //fio: contact.fio
    }
}

function prepareImageData(data) {
    var result = {
        type: "image",
        src: "data:image/png;base64," +  data.preview,
        file: declarations.http_post_dirs.CHANNEL_DATA_DIR + data.file,
        size: data.size,
        text: data.text,
        extension: data.extension,
    };
    if (data.previewFile) {
        result['preview_file'] = declarations.http_post_dirs.CHANNEL_DATA_DIR + data.previewFile;
        //result['preview_width'] = data.previewSize.w;
        //result['preview_height'] = data.previewSize.h;
    }
    return result;
}

function prepareVideoData(data) {
    var result = {
        type: "video",
        src: "data:image/png;base64," +  data.preview,
        file: declarations.http_post_dirs.CHANNEL_DATA_DIR + data.file,
        size: data.size,
        duration: data.duration,
        text: data.text,
        extension: data.extension,
    };
    if (data.previewFile) {
        result['preview_file'] = declarations.http_post_dirs.CHANNEL_DATA_DIR + data.previewFile;
        //result['preview_width'] = data.previewSize.w;
        //result['preview_height'] = data.previewSize.h;
    }
    return result;
}

function prepareAudioData(data) {
    return {
        file: declarations.http_post_dirs.CHANNEL_DATA_DIR + data.file,
        duration: data.duration,
        name: data.name
    };
}

function prepareFileData(data) {
    return {
        file: declarations.http_post_dirs.CHANNEL_DATA_DIR + data.file,
        name: data.name,
        extension: data.extension
    };
}
