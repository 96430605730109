//
//
//
//
//
//
//
//
//
//

import {GET_VC_CONF} from "../../store/gettersTypes"
import {CONF_PROPS, END_REASONS, STATUS} from "../../store/modules/video-conf-abstract"
import {mapActions, mapGetters} from "vuex"
import {VIDEO_CONF_NEW} from "../../store/modulesNames"

import videoConfSectionMixin from "./videoConfSectionMixin"
import {MEETING_ERRORS} from '../../api/roschat/protocolErrors/meetings'

import ModalBtn from '../modal/ModalBtn.vue'
import {ACT_VC_CONF_START} from '../../store/actionsTypes'

export default {
    name: "videoConfStatus",
    mixins: [ videoConfSectionMixin ],
    components: { ModalBtn },
    computed: {
        conf() {
            return this.confIdGetterData && this[GET_VC_CONF](...this.confIdGetterData) || {}
        },
        /*status() {
            return this.conf[CONF_PROPS.STATUS]
        },*/
        errorText() {
            return this.conf[CONF_PROPS.ERROR_TEXT]
        },
        endReason() {
            return this.conf[CONF_PROPS.END_REASON]
        },
        startBtn() {
            return this.status === STATUS.WAITING
        },
        statusText() {
            let text = ''
            switch (this.status) {
                case STATUS.CONNECTING:
                    text = this.$t('videomost.status.connecting')
                    break
                case STATUS.ACTIVE:
                    if (this.isBanned) text = this.$t('videomost.status.temp-ban')
                    break
                case STATUS.WAITING:
                    text = this.$t('videomost.status.waiting')
                    break
                case STATUS.ERROR:
                    if (this.errorText) {
                        switch (this.errorText) {
                            case MEETING_ERRORS.ACCESS_DENIED:
                                text = this.$t('videomost.errors.conf-access')
                                break
                            case MEETING_ERRORS.BAD_PARAMS:
                                text = this.$t('videomost.errors.conf-wrong-param')
                                break
                            case MEETING_ERRORS.NOT_FOUND:
                                text = this.$t('videomost.errors.conf-not-exist')
                                break
                            case MEETING_ERRORS.SERVICE_ERROR:
                                text = this.$t('videomost.errors.conf-service')
                                break
                            case MEETING_ERRORS.BANNED_USER:
                                text = this.$t('videomost.errors.conf-banned')
                                break
                            case MEETING_ERRORS.OUT_OF_TIME_EARLY:
                                text = this.$t('videomost.errors.conference-not-started')
                                break
                            case MEETING_ERRORS.OUT_OF_TIME_LATE:
                                text = this.$t('videomost.errors.out-of-time')
                                break
                            case MEETING_ERRORS.UNKNOWN:
                                text = this.$t('videomost.status.unknown-error')
                                break
                            default:
                                text = this.errorText
                        }
                    } else text = this.$t('videomost.status.unknown-error')
                    break
                case STATUS.ENDED:
                    switch (this.endReason) {
                        case END_REASONS.REMOTE_TERMINATE:
                        case END_REASONS.CONFERENCE_STOPPED:
                        case END_REASONS.UNKNOWN:
                            text = this.$t('videomost.status.ended')
                            break
                        case END_REASONS.BANNED_USER:
                            text = this.$t('videomost.status.ban')
                            break
                        case END_REASONS.BANNED_USER_SOFT:
                            text = this.$t('videomost.status.temp-ban')
                            break
                        default:
                            text = this.$t('videomost.status.ended')
                    }
                    break
            }
            return text
        },
        ...mapGetters(VIDEO_CONF_NEW, [GET_VC_CONF]),
    },
    methods: {
        startConf() {
            this[ACT_VC_CONF_START](this.confIdActionData)
        },
        ...mapActions(VIDEO_CONF_NEW, [ACT_VC_CONF_START])
    }
}
