//
//
//
//

import UpdateButton from './UpdateButton.vue';

export default {
    name: "InfoNotification",
    props: ['component'],
    components: {
        UpdateButton
    }
}    
