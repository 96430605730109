
import CustomSwitchItem from '../custom/CustomSwitchItem.vue'

import moment from 'moment'

export default {
    name: 'MuteSwitch',
    extends: CustomSwitchItem,
    computed: {
        // *** Переопределить в дочернем компоненте start *** //
        muteTime () {},
        // *** Переопределить в дочернем компоненте end *** //
        title() { return this.$t('mainPage.mute') },
        switchValue() { return Boolean(this.muteTime && (this.muteTime > moment().unix())) },
        switchText() {
            const time = this.muteTime
            const timeObj = moment.unix(time)
            if (time && (timeObj.diff(moment(), 'seconds') < 315360000)) {
                //return 'до ' + moment.unix(time).format('DD/MM/YYYY HH:mm:ss')
                return this.$t('until') + timeObj.format('DD.MM.YYYY HH:mm')
                //return 'навсегда'
            } else return ''
        },
    },
    methods: {
        // *** Переопределить в дочернем компоненте start *** //
        mute() {},
        unMute() {},
        // *** Переопределить в дочернем компоненте end *** //
        onClick() {
            if (this.switchValue) {
                this.unMute()
            } else {
                this.mute()
            }
        },
    },
}
