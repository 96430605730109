//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InfoMainBlock from './InfoMainBlock.vue';
import IconImage from '../IconImage.vue';
import ActionsContainer from "./ActionsContainer.vue"
import InfoIcon from "./InfoIcon.vue"
import ListItem from "../custom/ListItem.vue"
import ChatMuteSwitch from "./ChatMuteSwitch.vue"
import {mapGetters, mapActions} from 'vuex';
import {BOTS, MODAL} from "../../store/modulesNames";
import {GET_BOT_BY_ID} from "../../store/gettersTypes";
import {ACT_MODAL_OPEN} from "../../store/actionsTypes";
export default {
    name: "SystemChatInfo",
    props: ['params', 'canBack'],
    components: {
        'icon-image': IconImage,
        ActionsContainer,
        ListItem,
        InfoIcon,
        InfoMainBlock,
        ChatMuteSwitch,
    },
    computed: {
        cid() { return this.params.cid },
        cidType: () => declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER,
        chat() {
            return  this.$store.getters['chats/getChat']({cid: this.cid, cidType: 'user'});
        },
        inChatList() {
            return this.$store.getters['chats/getChat']({
                cid: this.cid,
                cidType: this.cidType
            })
        },
        ...mapGetters(BOTS, [GET_BOT_BY_ID])
    },
    methods: {
        doDeleteChat() {
            this[ACT_MODAL_OPEN]({
                name: 'remove-chat',
                props: {
                    cid: this.cid,
                    cidType: this.cidType
                }
            })
        },
        doShowContactEditor() {
            // this.$modal.show(ContactProfoleEdition, {
            //     type: 'edit',
            //     cid: this.cid
            // },
            // {
            //     name: 'Contact-profile-edition',
            //     delay: 200,
            //     pivotY: 0.05,
            //     width: 450,
            //     height: 'auto'
            // });
            app.contact_profile_editor.openEditionContact(this.cid);
        },
        ...mapActions(MODAL, [ACT_MODAL_OPEN]),
    }
}
