//
//
//
//
//
//

import {mapGetters} from "vuex"
import {PHONE_CNTL} from "../../store/modulesNames"
import {GET_CALL_PARTICIPANTS} from "../../store/gettersTypes"
import ContactsListItem from "../listItems/ContactsListItem.vue"

export default {
    name: "PhoneParticipants",
    components: {ContactsListItem},
    props: {
        type: {
            type: String,
            required: true,
        },
        id: {
            type: [String, Number],
            required: true,
        },
    },
    computed: {
        participants() {
            return this[GET_CALL_PARTICIPANTS](this.type, this.id)
        },
        contactsList() {
            return this.participants.map(({contact, number}, index) => {
                if (!contact) contact = { fio: number }
                else contact = { ...contact }
                // cid в contacts-list-item используется для выбора контакта, здесь мы его не используем
                //contact.cid = 'i' + index
                return contact
            })
        },
        ...mapGetters(PHONE_CNTL, [GET_CALL_PARTICIPANTS])
    },
    methods: {
        getSubtitle(index) {
            const participant = this.participants[index]
            return participant ? participant['number'] : ''
        }
    }
}
