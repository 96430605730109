//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions} from 'vuex'
import {VM_CONF} from '../../store/modulesNames'
import {ACT_VM_CONF_SET_PARAMS, ACT_VM_GET_CONFERENCE_INFO} from '../../store/actionsTypes'

import CustomInputText from '../custom/CustomInputText.vue'
import CustomSelect from '../custom/CustomSelect.vue'
import CustomCheckbox from '../custom/CustomCheckboxRound.vue'
import CustomRadio from '../custom/CustomRadio.vue'
import addToCalendar from './addToCalendar.vue'

import {CONF_SCHEDULE_PROPS} from '../../store/modules/video-conf-abstract'

export default {
    name: 'videomostSettings',
    components: {CustomInputText, CustomSelect, CustomCheckbox, CustomRadio, addToCalendar},
    props: {
        isNew: {
            type: Boolean,
            required: false,
            default: false,
        },
        conf: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            show_only_owner: '0',
            confModes: [
                {
                    value: '0',
                    name: this.$t('videomost.conf-discussion'),
                    selected: true,
                },
                {
                    value: '1',
                    name: this.$t('videomost.conf-selector'),
                    selected: false,
                },
                {
                    value: '3',
                    name: this.$t('videomost.conf-presentation'),
                    selected: false,
                },
                {
                    value: '2',
                    name: this.$t('videomost.conf-seminar'),
                    selected: false,
                },
            ],
            chosenVideoQuality: 'mp_Best',
            videoQuality: [
                {
                    value: 'mp_Best',
                    name: this.$t('videomost.quality-best'),
                    selected: true,
                },
                {
                    value: 'mp_High',
                    name: this.$t('videomost.quality-high'),
                    selected: false,
                },
                {
                    value: 'mp_Good',
                    name: this.$t('videomost.quality-good'),
                    selected: false,
                },
            ],
            chosenVideoQualityClient: '0',
            videoQualityClient: [
                {
                    value: '0',
                    name: this.$t('videomost.qualityclient-default'),
                    selected: true,
                },
                {
                    value: 'o_high',
                    name: this.$t('videomost.qualityclient-hd'),
                    selected: false,
                },
            ],
            isAddCalendar: false,
            attendees: [],
            isAddTerminals: false,
            SIPServerValue: 1,
            SIPServerItems: [
                {
                    value: 2,
                    label: this.$t('videomost.terminals-internalserver'),
                },
                {
                    value: 1,
                    label: this.$t('videomost.terminals-externalserver'),
                },
            ],
            SIPServer: '',
            SIPProxy: '',
            SIPPartiesMixerLabel: this.$t('videomost.terminals-mixertype'),
            SIPPartiesMixerValue: 'default',
            SIPPartiesMixerItems: [
                {
                    value: '0',
                    name: this.$t('videomost.mixertype-default'),
                    selected: true,
                },
                {
                    value: 'individual',
                    name: this.$t('videomost.mixertype-individial'),
                    selected: false,
                },
                {
                    value: 'group',
                    name: this.$t('videomost.mixertype-group'),
                    selected: false,
                },
            ],
            SIPPartiesVideoQualityLabel: this.$t('videomost.video-quality'),
            SIPPartiesVideoQualityValue: 'default',
            SIPPartiesVideoQualityItems: [{
                value: '0',
                name: this.$t('videomost.sipparties-video.default'),
                selected: true,
            },
                {
                    value: 'mp_Best',
                    name: this.$t('videomost.sipparties-video.quality-fhd'),
                    selected: false,
                },
                {
                    value: 'mp_High',
                    name: this.$t('videomost.sipparties-video.quality-hd'),
                    selected: false,
                },
                {
                    value: 'mp_Good',
                    name: this.$t('videomost.sipparties-video.quality-vga'),
                    selected: false,
                },
                {
                    value: 'mp_Low',
                    name: this.$t('videomost.sipparties-video.quality-qvga'),
                    selected: false,
                },
            ],
            rhythm_pattern_enabled: false,
            rhythm: {},
            rhythm_pattern_weekday_check_: null,
            isMixer: false,
            isMixerLabel: this.$t('videomost.settings-mixer'),
        }
    },
    created() {
        console.log('~~~~~~~~~~~~created')
        console.log(`~~~~~~~~~~~~id - ${this.conf[CONF_SCHEDULE_PROPS.ID]}, isNew - ${this.isNew}`)
        let ci = this.conf?.additionalProps
        console.log('!!!!!!!!!ACT_VM_GET_CONFERENCE_INFO', ci)
        if (ci) {
            this.show_only_owner = ci.show_only_owner
            this.chosenVideoQuality = ci.video_mix_profile
            this.chosenVideoQualityClient = ci.video_out_profile
            if (ci.sip_enabled !== '0') {
                this.isAddTerminals = true
                this.SIPServerValue = ci.sip_enabled
                this.SIPServer = ci.sip
                this.SIPProxy = ci.sip_proxy
                this.SIPPartiesMixerValue = ci.sip_mixed_type
                this.SIPPartiesVideoQualityValue = ci.sip_mixed_profile
            }
        }
    },
    watch: {
        show_only_owner (val, oldVal) {
            this.confModes.forEach(item => {
                (item.value === val) ? item.selected = true : item.selected = false
            })
        },
        chosenVideoQuality (val, oldVal) {
            console.log('~~~~~~~~~chosenVideoQuality', val)
            this.videoQuality.forEach(item => {
                (item.value === val) ? item.selected = true : item.selected = false
            })
            //this.videoQuality = [ ...this.videoQuality ]
        },
        chosenVideoQualityClient (val, oldVal) {
            this.videoQualityClient.forEach(item => {
                (item.value === val) ? item.selected = true : item.selected = false
            })
            //this.videoQualityClient = [ ...this.videoQualityClient ]
        },
        SIPPartiesMixerValue (val, oldVal) {
            this.SIPPartiesMixerItems.forEach(item => {
                (item.value === val) ? item.selected = true : item.selected = false
            })
            //this.SIPPartiesMixerItems = [ ...this.SIPPartiesMixerItems ]
        },
        SIPPartiesVideoQualityValue (val, oldVal) {
            this.SIPPartiesVideoQualityItems.forEach(item => {
                (item.value === val) ? item.selected = true : item.selected = false
            })
            //this.SIPPartiesVideoQualityItems = [ ...this.SIPPartiesVideoQualityItems ]
        },
    },
    methods: {
        getSettings () {
            const settings = {
                show_only_owner: this.show_only_owner,
                video_mix_profile: this.chosenVideoQuality,
                video_out_profile: this.chosenVideoQualityClient,
            }
            let sip_enabled = 0
            settings.sip_enabled = sip_enabled
            if (this.isAddTerminals) {
                settings.sip_enabled = this.SIPServerValue
                settings.sip = this.SIPServer
                settings.sip_proxy = this.SIPProxy
                settings.sip_mixed_type = this.SIPPartiesMixerValue
                settings.sip_mixed_profile = this.SIPPartiesVideoQualityValue
            }
            if (this.isAddCalendar) {
                settings.isAddCalendar = this.isAddCalendar
                if (this.attendees.length) settings.attendees = this.attendees
            }
            return settings
        },
        attendeesChange(val) {
            console.log("🚀 ~ file: videomostSettings.vue:262 ~ attendeesChange ~ val:", val)
            if (val && val.length) this.attendees = val
        },
        ...mapActions(VM_CONF, [ACT_VM_CONF_SET_PARAMS, ACT_VM_GET_CONFERENCE_INFO]),
    },
}
