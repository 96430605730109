
import { mapActions } from 'vuex'
import SelectContact from "./SelectContact.vue"
import SendContactWithFields from "./SendContactWithFields.vue"
import {AJAX, BLOB, CONTACTS} from '../../store/modulesNames'
import {ACT_AJAX_SEND_FILE, ACT_GET_FILE_FROM_BLOB_URL} from '../../store/actionsTypes'
import {IS_CONTACT_HAVE_LOCAL_CHANGES} from '../../store/gettersTypes'

export default {
    name: "SelectContactSendInChat",
    extends: SelectContact,
    created() {
        this.showContactsTabs = true
    },
    computed: {
        type: () => null
    },
    methods: {
        async onOk(contact) {
            console.log("🚀 ~ file: SelectContactSendInChat.vue:20 ~ onOk ~ contact:", contact)
            let cid = contact.cid, 
                photoFileName = '', 
                remoteCid = contact && contact.remoteCid,
                photo = contact && contact.photo
            const isLocal = this.$store.getters[`${CONTACTS}/${IS_CONTACT_HAVE_LOCAL_CHANGES}`](cid)
            let localPhoto
            if (remoteCid) localPhoto = photo
            else localPhoto = this.$store.getters['userdata/getLocalPhotoUrl'](cid)
            console.log("🚀 ~ file: SelectContactSendInChat.vue:27 ~ onOk ~ localPhoto:", localPhoto)
            let localPhotoFile = await fetch(localPhoto).catch()
            let globalPhoto
            if (remoteCid) globalPhoto = photo
            else globalPhoto = this.$store.getters['userdata/getPhotoUrlbyCid'](cid)
            console.log("🚀 ~ file: SelectContactSendInChat.vue:28 ~ onOk ~ globalPhoto:", globalPhoto)
            let globalPhotoFile = await fetch(globalPhoto).catch()
            const isPhotoGlobal = !localPhotoFile.ok
            const isPhotoGlobalOk = globalPhotoFile.ok

            if (!isLocal && !this.isLocalContacts) {
                const localFields = this.$store.getters['contacts/getLocalChangedFields'](cid)
                if (!isPhotoGlobal && contact.photo) {
                    photoFileName = await this.getFileName(contact.photo)
                }
                this.modalClose()
                this.props.cb && this.props.cb({ contact: contact, photoFileName, fields: localFields})
            } else {
                this.modalClose()
                this.modalOpen({
                    name: 'select-contact-withfields',
                    component: SendContactWithFields,
                    props: {
                        contact,
                        isLocal,
                        isPhotoGlobal,
                        isPhotoGlobalOk,
                        cb: async ({fields, withPhoto}) => {
                            console.log("🚀 ~ file: SelectContactSendInChat.vue:54 ~ cb: ~ fields:", fields)
                            console.log("🚀 ~ file: SelectContactSendInChat.vue:54 ~ cb: ~ withPhoto:", withPhoto)
                            console.log("🚀 ~ file: SelectContactSendInChat.vue:57 ~ cb: ~ isLocal:", isLocal)
                            if (isLocal) {
                                try {
                                    if (withPhoto && localPhotoFile.ok) photoFileName = await this.getFileName(contact.photo)
                                    console.log("🚀 ~ file: SelectContactSendInChat.vue:60 ~ cb: ~ photoFileName:", photoFileName)
                                } catch (e){
                                    console.log("!! -> file: SelectContactSendInChat.vue -> cb: -> e", e)
                                }
                            } else if (withPhoto) {
                                console.log("🚀 ~ file: SelectContactSendInChat.vue:56 ~ cb: ~ contact:", contact)
                                if (!isPhotoGlobal && contact.photo) photoFileName = await this.getFileName(contact.photo)
                                console.log("🚀 ~ file: SelectContactSendInChat.vue:64 ~ cb: ~ photoFileName:", photoFileName)
                            }
                            console.log("🚀 ~ file: SelectContactSendInChat.vue:70 ~ cb: ~ photoFileName:", photoFileName)
                            this.props.cb && this.props.cb({ contact: contact, photoFileName, fields})
                        }
                    },
                })
            }
        },
        onTabClick(val) {
            this.isLocalContacts = Boolean(val)
        },
        async getFileName(photo) {
            console.log("🚀 ~ file: SelectContactSendInChat.vue:75 ~ getFileName ~ photo:", photo)
            const file = await this[ACT_GET_FILE_FROM_BLOB_URL]({url: photo})
            console.log("🚀 ~ file: SelectContactSendInChat.vue:76 ~ getFileName ~ file:", file)
            return this[ACT_AJAX_SEND_FILE]({url: declarations.http_post_dirs.CHAT_DATA_DIR, file})
        },
        ...mapActions(AJAX, [ACT_AJAX_SEND_FILE]),
        ...mapActions(BLOB, [ACT_GET_FILE_FROM_BLOB_URL]),
    }
}

