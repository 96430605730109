export default (text) => {
    let result = '';
    let arrTextsBlocks = text && text.split(/@\[.+?:.+?\]/) || [];
    let arrRefs = text && text.match(/@\[.+?:.+?\]/g) || [];
    for(let i = 0; i < arrTextsBlocks.length; i++) {
        arrTextsBlocks[i] && (result = result + arrTextsBlocks[i]);
        if(arrRefs[i]) {
            let arrRefElements = arrRefs[i] && arrRefs[i].slice(0, -1).slice(2).split(':');
            arrRefElements && (result = result + arrRefElements[1]);
        }
    }
    return result
}
