//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations, mapActions } from 'vuex'
import { CONTENT_MANAGER, CLIENTDATA } from '../../store/modulesNames';
import {
    MUT_SET_SIDE_TYPE,
    MUT_INFO_NOTIFICATION,
    MUT_SET_NEW_CLIENT_INFO_VIEWED,
} from '../../store/mutationsTypes';
import { GET_NEW_CLIENT_INFO } from '../../store/gettersTypes';
import { SIDE_TYPES, MAIN_TYPES } from '../../store/modules/content-manager';
import { ACT_REPLACE_MAIN_TYPE } from '../../store/actionsTypes'

export default {
    name: "UpdateButton",
    computed: {
        version() {
            return (this[GET_NEW_CLIENT_INFO] || {}).version
        },
        ...mapGetters(CLIENTDATA, [GET_NEW_CLIENT_INFO])
    },
    methods: {
        openUpdatePage() {
            this[MUT_SET_SIDE_TYPE](SIDE_TYPES.MORE)
            this[ACT_REPLACE_MAIN_TYPE]({type: MAIN_TYPES.ABOUT})
            this.close()
        },
        doClose(e) {
            e.preventDefault()
            e.stopPropagation()
            this.close()
        },
        close() {
            this[MUT_INFO_NOTIFICATION](null)
            this[MUT_SET_NEW_CLIENT_INFO_VIEWED](true)
        },
        ...mapActions(CONTENT_MANAGER, [ACT_REPLACE_MAIN_TYPE]),
        ...mapMutations(CONTENT_MANAGER, [MUT_SET_SIDE_TYPE, MUT_INFO_NOTIFICATION]),
        ...mapMutations(CLIENTDATA, [MUT_SET_NEW_CLIENT_INFO_VIEWED]),
    }
}    
