//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import channelComments from './channelComments.vue'
import SelectChatToShare from '../modal/SelectChatToShare.vue'
import { CHANNEL } from '../../store/modulesNames'
import { ACT_PUBLICATION_LIKE } from '../../store/actionsTypes'

export default {
    name: "PublicationFooter",
    props: ['chId', 'pubId', 'publication'],
    methods: {
        doLikePublication (like_state) {
            this[ACT_PUBLICATION_LIKE]({pubId: this.pubId, chId: this.chId, state: !like_state})
        },
        doOpenComments() {
            this.modalOpen({
                component: channelComments,
                props: { 'chId': this.chId, 'publId': this.pubId }
            })
        },
        replyPublication() {
            this.modalOpen({
                component: SelectChatToShare,
                props: {
                    'type': 'publication',
                    'params': this.publication
                }
            })
        },
        ...mapActions(CHANNEL, [ACT_PUBLICATION_LIKE])
    }
}
