//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapActions } from 'vuex'
import store from '../../store/main/store';
import ChatPollMessage from '../chatPollMessage.vue';
import CustomCheckboxSlider from "../custom/CustomCheckboxSlider.vue";
import PublicationAudioItem from './PublicationAudioItem.vue';
import PublicationContactItem from './PublicationContactItem.vue';
import PublicationDocumentItem from './PublicationDocumentItem.vue';
import PublicationImageItem from './PublicationImageItem.vue';
import PublicationLocationItem from './PublicationLocationItem.vue';
import PublicationVideoItem from './PublicationVideoItem.vue';
import PublicationEditIcons from './PublicationEditIcons.vue';
import SelectContact from '../modal/SelectContact.vue'
import modal_mixin from '../modal/modal-mixin'
import { DATA_MESSAGE_BUILDER } from '../../store/modulesNames'
import {
    ACT_BUILD_FILE_MESSAGE,
    ACT_BUILD_GEO_MESSAGE,
    ACT_BUILD_POOL_MESSAGE,
} from '../../store/actionsTypes'

import { FILE_TARGETS } from '../../store/modules/data-message-builder'

export default {
    name: 'ChannelPublicationEditor',
    props: ['chId', 'pubId'],
    mixins: [modal_mixin],
    components: {
        ChatPollMessage,
        CustomCheckboxSlider,
        PublicationAudioItem,
        PublicationImageItem,
        PublicationVideoItem,
        PublicationContactItem,
        PublicationLocationItem,
        PublicationDocumentItem,
        PublicationEditIcons
    },
    data() {
        return {
            edited_data: {},
            warning: false,
            acceptFileTypes: '',
            publication: {
                title: '',
                info: '',
                publisher: 'user',
                data: []
            },
            compModalProps: {
                pivotY: 0.11,
                height: 'auto',
                adaptive: true,
                styles: "min-width: 300px;"
            },
            mapContainer: ''
        }
    },
    methods: {
        checkTitle() {
            this.warning = false
        },
        doAddFile(fileType) {
            const fileLoader = this.$refs.fileLoader
            fileLoader.value = ''
            fileLoader.accept = fileType
            fileLoader.setAttribute('multiple', true)
            fileLoader.click()
        },
        async uploadFile() {
            const fileLoader = this.$refs.fileLoader
            if (!fileLoader.files || !fileLoader.files.length) return
            for (let i = 0; i < fileLoader.files.length; i++) {
                let file = fileLoader.files[i]
                const payload = await this[ACT_BUILD_FILE_MESSAGE]({ file, target: FILE_TARGETS.CHANNEL })
                this.publication.data.push(payload)
            }
        },
        doAddContact() {
            let excludeMembers = this.data.contacts && this.data.contacts.map(function (contact) { return contact.cid; }) || []
            this.modalOpen({
                component: SelectContact,
                props: {
                    excludeMembers,
                    multiple: true,
                    cb: (contacts) => {
                        contacts.forEach(item => {
                            this.publication.data.push({
                                type: 'contact',
                                cid: item.cid,
                                text: ''
                            });
                        });
                    }
                }
            })
        },
        async doSetLocation(latitude, longitude) {
            let payload = await this[ACT_BUILD_GEO_MESSAGE]({ latitude, longitude, withText: true })
            this.publication.data.push(payload)
        },
        paste(e) {
            e.preventDefault();
            const clearText = e.clipboardData.getData('text/plain');
            document.execCommand('inserttext', false, clearText);
        },
        save() {
            if (this.pubId) {
                this.$store.dispatch('channel/changePublication', this.publication);
            } else {
                this.publication.chId = this.chId
                store.dispatch('channel/addPublication', this.publication);
            }

            this.modalClose()
        },
        async doAddPoll(pool) {
            let warningText;
            if (pool) warningText = this.$t('poll-change')
            if (!warningText && this.data.poll) warningText = this.$t('has-poll')
            if (warningText && !(await this.warningConfirm(warningText))) return
            const newPool = await this[ACT_BUILD_POOL_MESSAGE]({ pool })
            let oldIndex = pool && pool.index;
            if (oldIndex > -1) this.publication.data.splice(oldIndex, 1, newPool)
            else this.publication.data.push(newPool)
        },
        doEditItemProp(index, prop_name, status) {
            if (status) {
                if (!(index in this.edited_data)) this.$set(this.edited_data, index, {});
                this.$set(this.edited_data[index], prop_name, this.publication.data[index][prop_name]);
            } else {
                for (let i in this.edited_data[index]) {
                    if (!this.edited_data[index].hasOwnProperty(i)) continue;
                    this.$set(this.publication.data[index], i, this.edited_data[index][i]);
                }
                this.$delete(this.edited_data, index);
            }
        },
        deleteItem(index) {
            delete this.edited_data[index]
            this.publication.data.splice(index, 1)
        },
        doUpdateEditedItemProp(index, prop_name, value) {
            this.$set(this.edited_data[index], prop_name, value);
        },
        warningConfirm(text) {
            return new Promise((resolve) => {
                this.modalOpen({
                    name: 'confirm',
                    props: {
                        text: text,
                        btnOk: {
                            cb: () => resolve(true)
                        },
                        btnCancel: {
                            cb: () => resolve(false)
                        }
                    }
                })
            })
        },
        ...mapActions(DATA_MESSAGE_BUILDER, [
            ACT_BUILD_POOL_MESSAGE,
            ACT_BUILD_GEO_MESSAGE,
            ACT_BUILD_FILE_MESSAGE,
        ])
    },
    computed: {
        user_signature: {
            get: function () { return this.publication.publisher === 'user'; },
            set: function (val) { this.publication.publisher = val ? 'user' : 'channel'; }
        },
        data() { return this.$store.getters['channel/getPublicationPreparedDataItems']({ publication: this.publication }); },
    },
    created() {
        let publication = app.store.getters['channel/getPublicationById']({ pubId: this.pubId })
        if (publication) {
            publication = JSON.parse(JSON.stringify(publication))
            Object.assign(
                this.publication,
                { publisher: publication.cid ? 'user' : 'channel' },
                publication
            )
        }
    },
    mounted() {
        setTimeout(() => {
            this.$refs.info.innerText = this.publication.info
        }, 50)
    }
}

