
import RadioSpeech from "./RadioSpeech.vue"
import event_bus from "../../eventBus"
import {mapGetters, mapActions} from 'vuex'
import {RADIO, RC_RADIO} from '../../store/modulesNames'
import {
    RC_RADIO_IS_TURN_ON,
    GET_RC_RADIO_GRANT_STATUS,
    GET_RC_RADIO_PTT_PUSHED,
    GET_RC_RADIO_ECHO,
} from '../../store/gettersTypes'
import {
    ACT_SET_SPEECH_PACK_RATE,
    ACT_RC_RADIO_SEND_VOICE,
    ACT_RC_RADIO_REQUEST_VOICE,
    ACT_RC_RADIO_STOP_VOICE,
} from '../../store/actionsTypes'

import {GRANT_STATUSES, EVENTS} from '../../store/modules/rc-radio'

export default {
    name: "RcSpeech",
    extends: RadioSpeech,
    computed: {
        isOwnCall() {
            return Boolean(this[GET_RC_RADIO_GRANT_STATUS] === GRANT_STATUSES.GRANTED)
        },
        granted() {
            return this.isOwnCall
        },
        pttPushed() {
            // let testPttPushed = this[GET_RC_RADIO_PTT_PUSHED]
            return this[GET_RC_RADIO_PTT_PUSHED]
        },
        radioIsTurnOn() {
            return Boolean(this[RC_RADIO_IS_TURN_ON])
        },
        getEcho() {
            return this[GET_RC_RADIO_ECHO]
        },
        ...mapGetters(RC_RADIO, [
            RC_RADIO_IS_TURN_ON,
            GET_RC_RADIO_GRANT_STATUS,
            GET_RC_RADIO_PTT_PUSHED,
            GET_RC_RADIO_ECHO,
        ])
    },
    methods: {
        sendSpeech(speech) {
            // console.log("!! -> file: RcSpeech.vue -> line 50 -> sendSpeech -> speech", speech)
            this[ACT_RC_RADIO_SEND_VOICE]({
                audioData: speech,
            })
        },
        subscribeOnIncomingSpeech() {
            event_bus.$off(EVENTS.SPEECH)
            event_bus.$on(EVENTS.SPEECH, this.onIncomingSpeech)
        },
        popPtt() {
            this[ACT_RC_RADIO_STOP_VOICE]()
        },
        pushPtt() {
            this[ACT_RC_RADIO_REQUEST_VOICE]()
        },
        ...mapActions(RADIO, [ACT_SET_SPEECH_PACK_RATE]),
        ...mapActions(RC_RADIO, [ACT_RC_RADIO_SEND_VOICE, ACT_RC_RADIO_REQUEST_VOICE, ACT_RC_RADIO_STOP_VOICE]),
    },
}
