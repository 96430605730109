//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex"
import { USERDATA } from "../../store/modulesNames"
import { IS_MICROPHONE_ENABLED } from "../../store/gettersTypes"

export const PTT_STATUS = {
    IDLE: 'idle',
    REQUEST: 'request',
    PUSHED: 'pushed',
    QUEUED: 'queued',
    PUSHED_OTHER: 'pushedOther',
    CEASING: 'ceasing'
}

export default {
    name: "PttButton",
    props: {
        status: {
            type: String,
            default: PTT_STATUS.CEASING
        },
        pttPushed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        cssVars() {
            return {
                '--color': this.cssValue.color,
                '--border-width': this.cssValue.borderWidth,
                '--box-shadow': this.cssValue.boxShadow,
            }
        },
        cssValue() {
            const vars = {
                color: 'gray',
                borderWidth: '1px',
                boxShadow: '0px 0px 17px #0a0a0a'
            }
            if (this.pttPushed) vars.boxShadow = 'none'
            // console.log("!! -> file: PttButton.vue -> line 53 -> cssValue -> this.pttPushed", this.pttPushed)
            switch (this.status) {
                case PTT_STATUS.QUEUED:
                    case PTT_STATUS.REQUEST:
                        vars.color = 'orange'
                    vars.borderWidth = '2px'
                    break
                case PTT_STATUS.PUSHED:
                    vars.color = 'rgb(69,166,99)'
                    vars.borderWidth = '3px'
                    break
                case PTT_STATUS.PUSHED_OTHER:
                    vars.color = 'red'
                    vars.borderWidth = '2px'
                    break
                case PTT_STATUS.CEASING:
                    vars.color = 'orange'
                    vars.borderWidth = '1px'
                    break
                case PTT_STATUS.IDLE:
                    vars.color = 'gray'
                    vars.borderWidth = '1px'
                    break
            }
            // console.log("!! -> file: PttButton.vue -> line 54 -> cssValue -> this.status", this.status)
            return vars
        },
        ...mapGetters(USERDATA, { isMicEnabled: IS_MICROPHONE_ENABLED }),
    }
}
