//
//
//
//
//
//
//
//
//
//

export default {
    name: "PublicationEditIcons",
    props: {
        editIcon: {
            type: Boolean,
            default: false,
            required: false
        },
        propChange: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
      return {
          propEdited: false
      }
    },
    methods: {
        edit(){
            if (this.propChange) this.propEdited = !this.propEdited
            this.$emit('edit', this.propEdited)
        },
        del() {
            this.$emit('delete')
        }
    }
}
