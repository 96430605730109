//
//
//
//
//
//
//
//
//
//

import MainContentContainer from "../main/body/MainContentContainer.vue"
import ListItem from "../custom/ListItem.vue"
import Logger from '../../common/Logger'

export default {
    name: "technical-journal",
    components: { MainContentContainer, ListItem },
    methods: {
        getLogs(last) {
            Logger.getLogs(last)
        },
    }
}
