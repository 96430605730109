//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContactsListItem from '../listItems/ContactsListItem.vue';
import RecycleContactsList from '../modal/RecycleContactsList.vue'
import ItemListMixin from './ItemListMixin'

import {ACT_SET_SEARCHED_CONTACTS} from '../../store/actionsTypes';
import {USERDATA} from "../../store/modulesNames";

export default {
    name: 'search-contacts',
    mixins: [ItemListMixin],
    components: {ContactsListItem, RecycleContactsList},
    props: {
        items: {
            type: Array,
            required: false,
            default: () => []
        },
        noswitch: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    mounted() {
        let scrollerEl = this.$refs.scroller.$el.querySelector('.vue-recycle-scroller')
        scrollerEl.addEventListener('scroll', (event) => {
            const el = scrollerEl
            if (!el) return
            const scrollHeight = Math.round(el.scrollHeight)
            const scrollTop = Math.round(el.scrollTop)
            const clientHeight = Math.round(el.clientHeight)
            if (clientHeight + scrollTop >= scrollHeight - 100) {
                this.$emit('scrolledToBottom')
            }
        })
    },
    methods: {
        doSelect(contact) {
            this.$store.dispatch(`${USERDATA}/${ACT_SET_SEARCHED_CONTACTS}`, contact)
            this.$emit('update:contact-selected', contact.cid)
        },
        onFocusOnItemChange(newFocusOnItem, oldFocusOnItem) {
            let directionDown = (newFocusOnItem - oldFocusOnItem) > 0;
            let listNode = this.$el.querySelector('.vue-recycle-scroller');
            let listClientHeight = listNode.clientHeight;
            let listScrollHeight = listNode.scrollHeight;
            if (listClientHeight === listScrollHeight) return;
            let itemNode = listNode.querySelector(`[data-index='${newFocusOnItem}']`);
            if (itemNode) {
                let liNode = itemNode.closest('.vue-recycle-scroller__item-view');
                let topOffset = +liNode.style.transform.replace( /[^\d.]/g, '')
                if (directionDown && (listClientHeight + listNode.scrollTop - (topOffset + itemNode.clientHeight)) < 0) {
                    listNode.scrollTop = listNode.scrollTop + itemNode.clientHeight + 1
                } else if (!directionDown && (listNode.scrollTop > topOffset)) {
                    listNode.scrollTop = listNode.scrollTop - itemNode.clientHeight - 1
                }
            }
        },
    },
}
