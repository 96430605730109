//
//
//
//
//
//
//
//
//

import {mapActions} from 'vuex'
import {VIDEO_CONF_NEW, VM_CONF} from '../../store/modulesNames'
import {
    ACT_VM_CONF_SET_PARAMS,
    ACT_VM_GET_CONFERENCE_INFO,
    ACT_VC_CONF_CREATE,
    ACT_VC_CONF_CHANGE,
} from '../../store/actionsTypes'

import ModalBtn from '../modal/ModalBtn.vue'
import confSettingsBase from './confSettingsBase.vue'

import modal_mixin from '../modal/modal-mixin'

export default {
    name: 'ScheduleVCSettings',
    components: {ModalBtn, confSettingsBase},
    mixins: [modal_mixin],
    props: {
        conf: {
            type: Object,
            required: false,
        },
        isNew: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data () {
        return {
            mainTitle: this.$t('videomost.settings-title'),
            compModalProps: {
                delay: 100,
                pivotY: 0.5,
                width: 540,
                height: 'auto',
                clickToClose: false,
                adaptive: true,
                styles: "min-width: 387px; overflow-x: auto;"                     
            },
        }
    },
    computed: {
        getType () {
            return this.$refs && this.$refs.settings && this.$refs.settings.getType
        },
    },
    methods: {
        async saveConf (conf) {
            let confParams = this.$refs.settings.getConf()
            console.log('~~~~~~~~~saveConf ' + JSON.stringify(confParams))

            if (this.isNew) {
                await this[ACT_VC_CONF_CREATE](confParams)
            } else {
                await this[ACT_VC_CONF_CHANGE](confParams)
            }
            this.modalClose()
            this.$bus.$emit('reload-conf-lists')
        },
        ...mapActions(VIDEO_CONF_NEW, [ACT_VC_CONF_CREATE, ACT_VC_CONF_CHANGE]),
        ...mapActions(VM_CONF, [ACT_VM_CONF_SET_PARAMS, ACT_VM_GET_CONFERENCE_INFO]),
    },
}
