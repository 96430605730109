//
//
//
//
//

import videoConfSectionHeaderDefault from "./videoConfSectionHeaderDefault.vue"
import videoConfSectionHeaderShare from "./videoConfSectionHeaderShare.vue"

import videoConfSectionMixin from "./videoConfSectionMixin"
import {CONFERENCE_SCHEMAS} from "../../constants"

export default {
    name: "videoConfSectionHeader",
    mixins: [videoConfSectionMixin],
    props: {
        schema: {
            type: String,
            required: true
        }
    },
    computed: {
        component() {
            switch (this.schema) {
                case CONFERENCE_SCHEMAS.MY_SHARE:
                    return videoConfSectionHeaderShare
                default:
                    return videoConfSectionHeaderDefault
            }
        },
    },
}
