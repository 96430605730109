//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { i18n } from '../../../ext/i18n'
import AjaxProgressCircle from '../custom/AjaxProgressCircle.vue'
import { AJAX } from '../../store/modulesNames'
import { AJAX_CALL_IS_EXIST } from '../../store/gettersTypes'
import CustomVideo from '../custom/CustomVideo.vue'
import CustomInputNumber from '../custom/CustomInputNumber.vue'
import CustomInputText from '../custom/CustomInputText.vue'
import moment from 'moment'
import printJS from 'print-js'

const DEFAULT_TRANSLATE_PROPS = {
    dragging: false,
    startX: 0,
    startY: 0,
    diffX: 0,
    diffY: 0,
    currentX: 0,
    currentY: 0,
}

export default {
    name: "MediaViewer",
    props: {
        src: {
            type: Object,
            required: false
        },
        message: {
            type: Object,
            required: false
        },
        type: {
            type: String,
            required: false
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {CustomVideo, AjaxProgressCircle, CustomInputNumber, CustomInputText},
    watch: {
        pdfCurrentPage(val) {
            this.getPdfPage()
        },
        pdfPages() {
          if (this.$refs && this.$refs.allPages) this.$refs.allPages.input_text = this.allPagesInputText
        },
        mediaItem() {
            this.abortAjaxCall()
            this.preload = true
            this.xhrAborted = false
            this.onMediaItemChange()
        },
        xhr(newVal, oldWal) {
            if (oldWal) this.xhrAborted = true
        },
        deg() {
            this.dropTranslate()
        },
        currentZoom() {
            this.dropTranslate()
        },
    },
    data: () => {
        return {
            locale: i18n.messages[i18n.locale],
            preload: true,
            xhrAborted: false,
            currImgIndex: 0,
            deg: 0,
            trans: 'all .5s ease-out',
            existTrans: true,
            contentWidth: 0,
            contentHeight: 0,
            imageWidthBase: 0,
            imageHeightBase: 0,
            transformProps: { ...DEFAULT_TRANSLATE_PROPS },
            zoomProps: {
                currentZoom: 1,
                stepMax: 0.5,
                stepMin: 0.125,
                sizeMax: 2.5,
                sizeMin: 0.625,
            },
            heightImg: '',
            widthImg: '',
            pdfDoc: null,
            pdfCurrentPage: 1,
            pdfPages: 0,
            pdfPath: '',
        }
    },
    async created() {
        if (this.type === 'pdf') {
            const pdf = (await import(/* webpackChunkName: "vue-pdf-worker" */ 'vue-pdf') || {}).default

            if (pdf) {
                this.pdfDoc = pdf.createLoadingTask({url: this.path})
                this.getPdfPage()
            }
        }
    },
    mounted() {
        // if (this.message && this.message.previewSize) {
        //     this.heightImg = this.message.previewSize.h,
        //     this.widthImg = this.message.previewSize.w
        // }
        document.querySelectorAll('[role="dialog"]')[0].classList.add('no-gap-on-top')
        window.addEventListener('resize', this.onResize)
        this.onResize()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.onResize)
        this.abortAjaxCall()
    },
    computed: {
        allPagesInputText() {
            return `/ ${this.pdfPages}`
        },
        styleImg () {
            return {
                cursor: this.cursor,
                transform: this.imageTransform,
                transition: this.existTrans ? this.trans : '',
                height: this.heightImg < this.contentHeight  ? this.heightImg  + 'px' : '',
                width: this.widthImg < this.contentWidth ? this.widthImg  + 'px' : ''
            }
        },
        stylePreview () {
            return {
                height: this.heightImg ? this.heightImg  + 'px' : '',
                width: this.widthImg ? this.widthImg  + 'px' : ''
            }
        },
        cursor() {
            if (this.transformProps.dragging) return 'grabbing'
            else if (this.widthOverflow || this.heightOverflow) return 'grab'
            else return 'default'
        },
        flipedAxis() {
            return Boolean((this.deg / 90) % 2)
        },
        zoomed() {
            return this.zoomProps.currentZoom !== 1
        },
        currentZoom() {
          return this.zoomProps.currentZoom
        },
        imageTransformedWidth() {
            let width
            if (this.flipedAxis) width = this.imageHeightBase
            else width = this.imageWidthBase
            return width * this.zoomProps.currentZoom
        },
        imageTransformedHeight() {
            let height
            if (this.flipedAxis) height = this.imageWidthBase
            else height = this.imageHeightBase
            return height * this.zoomProps.currentZoom
        },
        imageTransform() {
            let transform = []
            if (this.deg) transform.push(`rotate(${this.deg}deg)`)
            if (this.transformX || this.transformY) transform.push(`translate(${this.transformX}px, ${this.transformY}px)`)
            transform.push(`scale(${this.zoomProps.currentZoom})`)
            return transform.length && transform.join(' ') || 'none'
        },
        transformX() {
            return this.getTransformAxis(this.transformProps.diffX, this.transformProps.diffY, true)
        },
        transformY() {
            return this.getTransformAxis(this.transformProps.diffY, this.transformProps.diffX)
        },
        widthOverflow() {
            return this.contentWidth < this.imageTransformedWidth
        },
        heightOverflow() {
            return this.contentHeight < this.imageTransformedHeight
        },
        zoomInDisable() {
            return this.zoomProps.currentZoom >= this.zoomProps.sizeMax
        },
        zoomOutDisable() {
            return this.zoomProps.currentZoom <= this.zoomProps.sizeMin
        },
        existBtns () {
            if (this.mediaType !== 'video') return true
            return false
        },
        subtitle () {
            return ''
        },
        user() {
            return false
        },
        path() {
            return ''
        },
        extension() {
            return ''
        },
        showSpinner() {
            return !this.xhr && !this.xhrAborted
        },
        showXhrProgress() {
            return this.xhr && !this.xhrAborted
        },
        mediaItems() {
          return []
        },
        mediaItem() {
            if (!this.mediaItems || !this.mediaItems.length) {
                return this.src
            }
            return this.mediaItems[this.currImgIndex]
        },
        mediaType() {
            return this.mediaItem && this.mediaItem.type ?  this.mediaItem.type : this.type
        },
        multiple() {
            return this.mediaItems && this.mediaItems.length > 1
        },
        canBack() {
            if (this.type === 'pdf') {
               return this.pdfCurrentPage !== 1
            } else {
                return this.multiple && this.currImgIndex > 0
            }
        },
        canForward() {
            if (this.type === 'pdf') {
                return this.pdfPages > this.pdfCurrentPage
            } else {
                return this.multiple && this.currImgIndex < this.mediaItems.length - 1
            }
        },
        ...mapGetters(AJAX, [AJAX_CALL_IS_EXIST])
    },
    methods: {
        async getPdfPage() {
            let pdf = await this.pdfDoc.promise
            this.pdfPages = pdf.numPages
            let page = await pdf.getPage(this.pdfCurrentPage)
            let viewport = page.getViewport({scale: 1})
            let canvas = document.createElement('canvas') , ctx = canvas.getContext('2d')
            let renderContext = { canvasContext: ctx, viewport: viewport }

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const mypage = page.render(renderContext)
            await mypage.promise
            this.pdfPath = canvas.toDataURL()
        },
        setCurrentPage(val) {
            if (isNaN(val) || val > this.pdfPages || val < 1) {
                if (this.$refs && this.$refs.currentPage) this.$refs.currentPage.input_text = this.pdfCurrentPage
            } else {
                this.pdfCurrentPage = val
                if (this.$refs && this.$refs.currentPage) this.$refs.currentPage.input_text = val
            }
        },
        onMouseDown(event) {
            //if (!(this.widthOverflow || this.heightOverflow)) return
            this.transformProps.dragging = true
            this.transformProps.startX = this.transformProps.currentX = event.pageX
            this.transformProps.startY = this.transformProps.currentY = event.pageY
            document.addEventListener('mousemove', this.onMouseMove)
            document.addEventListener('mouseup', this.onMouseUp)
        },
        onMouseUp(event) {
            this.transformProps.dragging = false
            document.removeEventListener('mousemove', this.onMouseMove)
            document.removeEventListener('mouseup', this.onMouseUp)
        },
        onMouseMove(event) {
            let even = !this.flipedAxis
            let eventDiffX = this.transformProps.currentX - event.pageX
            let eventDiffY = this.transformProps.currentY - event.pageY
            let newDiffX = this.transformProps.diffX - eventDiffX
            let newDiffY = this.transformProps.diffY - eventDiffY
            let maxWDiff = (this.imageTransformedWidth - this.contentWidth) / 2
            let maxHDiff = (this.imageTransformedHeight - this.contentHeight) / 2
            let wOverflow = this.widthOverflow
            let hOverflow = this.heightOverflow
            if (even ? wOverflow : hOverflow) {
                let left = even ? maxWDiff : maxHDiff
                let right = 0 - left
                let maxW = Math.max(left, right)
                let minW = Math.min(left, right)
                let next = this.getTransformAxis(newDiffX, newDiffY)
                if (next <= maxW && next >= minW) {
                    if (even) {
                        this.transformProps.diffX = newDiffX
                        this.transformProps.currentX = event.pageX
                    } else {
                        this.transformProps.diffY = newDiffY
                        this.transformProps.currentY = event.pageY
                    }
                }
            }
            if (even ? hOverflow : wOverflow) {
                let top = even ? maxHDiff : maxWDiff
                let bottom = 0 - top
                let maxH = Math.max(top, bottom)
                let minH = Math.min(top, bottom)
                let next = this.getTransformAxis(newDiffY, newDiffX)
                if (next <= maxH && next >= minH) {
                    if (even) {
                        this.transformProps.diffY = newDiffY
                        this.transformProps.currentY = event.pageY
                    } else {
                        this.transformProps.diffX = newDiffX
                        this.transformProps.currentX = event.pageX
                    }
                }
            }
        },
        getTransformAxis(diffPrimary, diffSecondary, isX) {
            let axisVal

            switch (this.deg) {
                case 0:
                    axisVal = diffPrimary
                    break
                case -90:
                    axisVal = isX ? 0 - diffSecondary : diffSecondary
                    break
                case -180:
                    axisVal = -diffPrimary
                    break
                case -270:
                    axisVal = isX ? diffSecondary : 0 - diffSecondary
                    break
            }
            return Math.round(axisVal)
        },
        switchImg () {
            this.existTrans = false
            this.deg = 0
            this.zoomProps.currentZoom = 1
            this.imageWidthBase = 0
            this.imageHeightBase = 0
            this.$nextTick(() => this.existTrans = true)
        },
        showPrevImg(e) {
            e.preventDefault()
            e.stopPropagation()
            if (this.canBack) {
                if (this.type === 'pdf') {
                    this.pdfCurrentPage--
                    this.setCurrentPage(this.pdfCurrentPage)
                } else {
                    this.currImgIndex--
                    this.switchImg()
                }
            }
        },
        showNextImg(e) {
            e.preventDefault()
            e.stopPropagation()
            
            if (this.canForward) {
                if (this.type === 'pdf') {
                    this.pdfCurrentPage++
                    this.setCurrentPage(this.pdfCurrentPage)
                } else {
                    this.currImgIndex++
                    this.switchImg()
                }
            }
        },
        zoomIn () {
            let currentZoom = this.zoomProps.currentZoom
            if (!this.zoomInDisable) this.zoomProps.currentZoom += (currentZoom >= 1 ? this.zoomProps.stepMax : this.zoomProps.stepMin)
        },
        zoomOut () {
            let currentZoom = this.zoomProps.currentZoom
            if (!this.zoomOutDisable) this.zoomProps.currentZoom -= (currentZoom > 1 ? this.zoomProps.stepMax : this.zoomProps.stepMin)
        },
        rotate() {
            this.deg -= 90
            if (this.deg <= -360) this.deg = 0
        },
        close() {
            this.$modal.hide('viewer')
        },
        print() {
            if (this.type === 'pdf') printJS(this.path, 'pdf')
            else printJS(this.path, 'image')
        },
        getDownloadFileName(type, name, extension) {
            return ''
        },
        clickedPreview(index) {
            this.currImgIndex = index
        },
        download() {
            if (this.preload) return
            let a = document.createElement('a')
            a.setAttribute('href', this.path)
            a.setAttribute('target', '_blank')
            a.setAttribute('download', this.getDownloadFileName(this.mediaType, null, this.extension))
            // if(this.typeMsg === 'publication') a.setAttribute('download', this.getDownloadFileName(this.src.type, this.extension));
            // else a.setAttribute('download', this.getDownloadFileName(this.message.sub_type, this.extension));
            // a.dispatchEvent(e);
            a.click()
            this.onItemSaved()
        },
        onImageLoaded() {
            this.setImageSize()
        },
        onError(err) {
            // console.log("🚀 ~ file: MediaViewer.vue:492 ~ onError ~ err:", err)
        },
        onResize() {
            this.setContentSizes()
            this.setImageSize()
            if (this.transformProps.diffX || this.transformProps.diffY) this.dropTranslate()
        },
        setImageSize() {
            let img = this.$refs.img
            if (img) {
                this.imageWidthBase = img.clientWidth
                this.imageHeightBase = img.clientHeight
            }
        },
        setContentSizes() {
            this.contentWidth = this.$refs.content.clientWidth
            this.contentHeight = this.$refs.content.clientHeight
        },
        dropTranslate() {
            this.transformProps = { ...DEFAULT_TRANSLATE_PROPS }
        },
        getTimeFromSecondsLeft(time) {
            let result
            let today = moment().startOf('day');
            let yesterday = moment().subtract(1, 'days').startOf('day');
            let timeFormat = moment().subtract(time, 'seconds');

            if(time < 60) {
                // меньше минуты
                result = this.locale.recently;
            } else if(time < 3600) {
                // меньше часа
                if(time >= 300 && time <= 1200) result = Math.floor(time/60) + this.locale.mainPage['min-ago-3'];
                else {
                    switch(Math.floor(time/60) % 10) {
                        case 1:
                            result = Math.floor(time/60) + this.locale.mainPage['min-ago'];
                            break;
                        case 2:
                        case 3:
                        case 4:
                            result = Math.floor(time/60) + this.locale.mainPage['min-ago-2'];
                            break;
                        default:
                            result = Math.floor(time/60) + this.locale.mainPage['min-ago-3'];
                    }
                }
            } else if(time < 43200) {
                // меньше 12 часов
                switch(Math.floor(time/3600)) {
                    case 1:
                        result = Math.floor(time/3600) + this.locale.mainPage['hour-ago'];
                        break;
                    case 2:
                    case 3:
                    case 4:
                        result = Math.floor(time/3600) + this.locale.mainPage['hour-ago-2'];
                        break;
                    default:
                        result = Math.floor(time/3600) + this.locale.mainPage['hour-ago-3'];
                }
                result = Math.floor(time/3600) + this.locale.mainPage['hour-ago-3'];
            } else if(timeFormat.isAfter(today)) {
                // сегодня
                result = this.locale['today-at'] + moment().subtract(time, 'seconds').format('HH:mm');
            } else if(timeFormat.isAfter(yesterday)) {
                // вчера
                result = this.locale['yesterday-at'] + moment().subtract(time, 'seconds').format('HH:mm');
            } else {
                result = moment().subtract(time, 'seconds').format('DD MMMM YYYY');
            }
            return result
        },
        onMediaItemChange() {

        },
        onItemSaved() {

        },
        getPreviewByIndex() {

        },
        onImageContextMenu(e) {
            if (this.readOnly) return
            const handlers = []
            handlers.push({ item_name: this.$t('chat.copy-image'), 
                handler: async () => {
                    const blobFile = await this.getBlobFromURL(this.path)
                    let changedBlob = new Blob([blobFile], {type: 'image/png'})
                    navigator.clipboard.write([new ClipboardItem({ 'image/png' : changedBlob })])
                } 
            })
            handlers.push({ item_name: this.$t('save'), handler: () => this.download() })
            this.cmOpen(e, handlers, 'right-bottom')
        },
        async getBlobFromURL(url) {
            let blob
            await fetch(url).then(res => blob = res.blob())
            return blob
        },
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus();
            }
        }
    }
}
