//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';

import { USERDATA } from "../../store/modulesNames.js"
import { GET_ACTIVE_WEBCAMERA } from "../../store/gettersTypes.js"
import { ACT_ACTIVE_WEBCAMERA } from "../../store/actionsTypes.js"
import CustomDropDown from "../custom/CustomDropDown.vue"

export default {
    name: 'webcameras-settings',
    props:['webcameras'],
    components: { CustomDropDown },
    data() {
        return {
            activeCamera: null,
            activeCameraName: '',
            videoDescription: this.$t("no-camera-chosen"),
        }
    },
    mounted() {
        this.setOrRestoreValues();
    },
    computed: {
        placeholder() {
            let placeHolder = this.$t("choose-camera");
            if (!!this.webcameras && this.webcameras.length > 0 && this.activeCameraName.length) {
                placeHolder = '';
            } else if (!!this.webcameras && !this.webcameras.length) {
                placeHolder = this.$t("no-cameras-found");
            }
            return placeHolder;
        },
        camerasList() {
            let camerasArr = [];
            this.webcameras.map((camera, index) => {
                let arrItem = {
                    value: camera.label,
                    name: camera.label,
                    selected: false
                };
                if (index === 0) arrItem.selected = true;
                if (index > 0 && arrItem.name === this.activeCameraName) {
                    camerasArr[0].selected = false;
                    arrItem.selected = true;
                }
                camerasArr.push(arrItem);
            });
            return camerasArr
        },
        isDescriptionVisible() {
            return !(this.webcameras && this.webcameras.length);
        },
        selectedCamera() {
            return this.activeCameraName ? { value: this.activeCameraName, name: this.activeCameraName } : { value: '', name: '' }
        }
    },
    watch: {
        webcameras() {
            this.setOrRestoreValues()
        },
        activeCameraName(val) {
            if (!val) return
            this.stopVideo(this.$refs.video);
            this.webcameras.forEach(item => {
                if (item.label === val) {
                    this.$store.dispatch(`${USERDATA}/${ACT_ACTIVE_WEBCAMERA}`, item);
                    this.startStream(item.deviceId);
                    this.$emit('change-input-video-device', item);
                }
            })
        }
    },
    methods: {
        setActiveCamera(val) {
            this.activeCameraName = val
        },
        setOrRestoreValues() {
            if (!this.webcameras || this.webcameras.length === 0) {
                this.$store.dispatch(`${USERDATA}/${ACT_ACTIVE_WEBCAMERA}`, null);
                return this.activeCameraName = '';
            }
            this.activeCamera = this.$store.getters[`${USERDATA}/${GET_ACTIVE_WEBCAMERA}`];
            const isDeviceExist = this.checkIfDeviceExist(this.activeCamera && this.activeCamera.deviceId)
            if (this.activeCamera && isDeviceExist) 
                    this.activeCameraName = this.activeCamera.label;
            else if (this.webcameras && this.webcameras[0])
                    this.activeCameraName = this.webcameras[0].label;
        },
        checkIfDeviceExist(deviceId) {
            return this.webcameras.some(dev => dev.deviceId === deviceId)
        },         
        async startStream(deviceId) {
            let constraints = {
                audio: false,
                video: {
                    deviceId: { ideal: deviceId },
                }
            };

            if (this.$refs.video !== null && this.$refs.video.srcObject) {
                this.stopVideo(this.$refs.video);
            }

            let stream = await navigator.mediaDevices.getUserMedia(constraints);
            try {
                if ("srcObject" in this.$refs.video) {
                    // new browsers api
                    this.$refs.video.srcObject = stream;
                } else {
                    // old browsers
                    this.source = window.HTMLMediaElement.srcObject(stream);
                }
            } catch(err) {
                console.log(err.name + ": " + err.message);
            }
        },
        stopVideo(videoElem) {
            let stream = null;
            if (videoElem && videoElem.srcObject) stream = videoElem.srcObject;
            if (!stream) return;
            let tracks = stream.getTracks();
            if (!tracks) return;
            tracks.forEach(track => {
                track.stop();
                this.$refs.video.srcObject = null;
                this.source = null;
            });
        }
    },
    beforeDestroy() {
        this.stopVideo(this.$refs.video);
    }
}
