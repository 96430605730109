//
//
//
//
//
//
//
//
//
//
//
//

'use strict'
import ipc from '../../../electron/ipc'
import MainContentContainer from "../main/body/MainContentContainer.vue"
import ListItem from "../custom/ListItem.vue"
import CustomCheckboxSlider from "../custom/CustomCheckboxSlider.vue"
 
import { getIsFullCompactMode, setSideVisible } from '../../../ext/trackResizes'

export default {
    name: 'general-settings',
    components: { MainContentContainer, ListItem, CustomCheckboxSlider },
    data() {
        return {
            runByStart: false,
            iconInTray: false,
            isFullCompactMode: getIsFullCompactMode(),
        };
    },
    computed: {},
    filters: {},
    methods: {
        _setSideVisible() {
            setSideVisible()
        },
    },
    watch: {
        runByStart(enable) {
            enable ? ipc.send('autolaunch-enable') : ipc.send('autolaunch-disable')
        }
    },
    created() {
        this.$emit('created')
        ipc.on('autolaunch-status/ok', (event, status) => {
            this.runByStart = status
            this.$emit('ready')
        })
        ipc.on('autolaunch-status/err', (event, err) => {
            console.error(err)
            this.$emit('ready')
        })
        ipc.send('autolaunch-status')
    }
}
