import {
    ACT_CONF_JOIN,
    ACT_CONF_JOIN_BY_LINK,
    ACT_CONF_FINISH,
    ACT_CONF_LEAVE,
} from '../../actionsTypes'

import module from '../../modules/video-conf-videomost'

const actions = {
    // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//     async [ACT_CONF_JOIN](obj, {el, share, username, confId, pass, server}) {
//     },
//     async [ACT_CONF_JOIN_BY_LINK]({dispatch, commit}, {el, share, username, link}) {
//     },
//     async [ACT_CONF_FINISH]({getters, commit, dispatch}, {id}) {
//     },
//     async [ACT_CONF_LEAVE]({getters, commit}, {id}) {
//     },
    // #endif
}

Object.assign(module.actions, actions)

export default module