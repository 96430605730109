//
//
//
//
//
//
//
//
//
//
//
//

import ModalBtn from "./ModalBtn.vue"
import modal_mixin from "./modal-mixin"
import RecycleContactsList from "./RecycleContactsList.vue"
import ContactsListItem from "../listItems/ContactsListItem.vue"

export default {
    name: "ContactsListModal",
    components: {RecycleContactsList, ModalBtn, ContactsListItem},
    mixins: [modal_mixin],
    data: () => {
        return {
            compModalProps: {
                delay: 350,
                pivotY: 0.05,
                width: 400,
                height: 'auto',
                clickToClose: false
            },
        }
    },
    beforeDestroy() {
        this.props.cancel && this.props.cancel()
    },
    computed: {
        closeText() { return this.props.closeText || this.$t('close') },
        title() { return this.props.title || this.$t('contacts-list') },
        contactsList() { return this.props.contactsList || [] },
    },
    methods: {
        getSubtitle() {}
    }
}
