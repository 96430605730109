var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("select-point")
      }
    },
    [
      _c("template", { slot: "default" }, [
        _c(
          "div",
          {
            ref: "map",
            staticStyle: { width: "600px", height: "400px" },
            attrs: { id: "map" }
          },
          [
            _c("div", { staticClass: "cross-control" }, [
              _c("i", { staticClass: "far fa-dot-circle" })
            ])
          ]
        )
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "btn", on: { click: _vm.onCancel } }, [
          _vm._v(_vm._s(_vm.cancelText))
        ]),
        _c("div", { staticClass: "btn", on: { click: _vm.onOk } }, [
          _vm._v(_vm._s(_vm.okText))
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }