var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "microphone" }, [
    _c(
      "div",
      { staticClass: "microphone-header" },
      [
        _c("span", [_vm._v(_vm._s(_vm.$t("microphone")))]),
        _c("custom-drop-down", {
          key: "2",
          ref: "select_mic",
          staticClass: "mic-select",
          attrs: {
            options: _vm.microphonesList,
            default: _vm.selectedMic,
            noOptions: _vm.$t("no-microphones-found"),
            firstOption: "true"
          },
          on: { "dd-selected-value": _vm.setActiveMic }
        })
      ],
      1
    ),
    _c("div", { staticClass: "microphone-level" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("microphone-level")))]),
      _c(
        "div",
        { staticClass: "microphone-range-slider" },
        [
          _c("i", { staticClass: "fas fa-volume-off" }),
          _c("range-slider", {
            staticClass: "slider",
            attrs: {
              disabled: _vm.sliderDisabled,
              piecewise: "true",
              min: "0",
              max: "100",
              step: "1"
            },
            model: {
              value: _vm.sliderValue,
              callback: function($$v) {
                _vm.sliderValue = $$v
              },
              expression: "sliderValue"
            }
          }),
          _c("i", { staticClass: "fas fa-volume-up volume-up" })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "microphone-level-check" }, [
      _c(
        "div",
        { staticClass: "microphone-visualizer" },
        _vm._l(_vm.elements, function(i) {
          return _c("div", [
            i % 2 > 0
              ? _c("div", { staticClass: "microphone-visualizer-elem" }, [
                  i < _vm.activeElements
                    ? _c("div", {
                        staticClass: "microphone-visualizer-elem-active"
                      })
                    : _vm._e()
                ])
              : _vm._e()
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }