/**
 * Синглтон audioContext (В сафари создавать audioContext можно ограниченное число раз)
 * @return {AudioContext}
 */
export const getAudioContext = () => {
    if (!window.audioContext) {
        const AudioContext = window.AudioContext || window.webkitAudioContext
        window.audioContext = new AudioContext()
    }
    return window.audioContext
}

/**
 * @param { String } path абсолютный путь
 * @param { String } name имя AudioWorkletProcessor
 * @param audioContext
 */
export const createWorkletNode = (() => {
    let moduleInstances = {}

    return async (path, name, audioContext = getAudioContext()) => {
        //if (moduleInstances[name]) return { audioContext, audioWorkletNode: moduleInstances[name] } //@todo
        try {
            await audioContext.audioWorklet.addModule(path)
        } catch (e) {
            console.log('Audio.createWorkletNode error', e)
            throw e
        }
        const audioWorkletNode = new window.AudioWorkletNode(
            audioContext,
            name
        )
        //moduleInstances[name] = audioWorkletNode //@todo
        return {
            audioWorkletNode,
            audioContext,
        }
    }
})()