//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex';
import {CLIENTDATA, LOGIN} from "../../store/modulesNames";
import {GET_SID} from "../../store/gettersTypes"
import {ACT_LOGIN} from "../../store/actionsTypes";

import login_mixin from './login-mixin';

import VueQRCodeComponent from 'vue-qrcode-component';
import CustomCheckbox from "../custom/CustomCheckbox.vue";

import event_bus from '../../eventBus';
import { decryptText, generateHexString } from '../../common/Encrypter'

export default {
    name: "Remote",
    mixins: [login_mixin],
    components: {'qr-code': VueQRCodeComponent, CustomCheckbox},
    data () {
        return {
            remember_me: false,
            key: ""
        }
    },
    computed: {
        qr_text () {
            return this.key && this.sid && JSON.stringify({
                key: this.key,
                sid: this.sid,
            })
        },
        getQrColor() {
            return this.isDarkTheme ? 'black' : "grey"
        },
        isDarkTheme() {
            return this.$store.getters['clientdata/getTheme'] === 'dark'
        },
        ...mapGetters(CLIENTDATA, {sid: GET_SID})
    },
    methods: {
        /*encrypt() {
         let hex_string = CryptoJS.SHA384(this.key).toString();
         let enc = CryptoJS.AES.encrypt(this.enc, this.getKeyHex(hex_string) , { iv: this.getIvHex(hex_string) });
         console.log(enc.toString());
         },*/
        async decrypt (text) {
            return decryptText(this.key, text);
        },
        async doLogin (data) {
            let error_msg,
                login = data.login,
                password

            try {
                password = await this.decrypt(data.password)
            } catch (e) { }

            if (!login) error_msg = this.$t('login-not-def')
            if (!error_msg && !password) error_msg = this.$t('pass-not-def')

            if (error_msg) return this.showAlert(error_msg);

            try {
                this.$emit('logging')
                const remember_me = this.isElectron ? true : this.remember_me
                let data = await this[ACT_LOGIN]({login, password, remember_me});
                if (data.status === 'pin-required') this.$parent.doAdd('2FA', {login, password, remember_me, uid: data.uid})
            } catch (e) {
                this.showAlert(this.getLoginError(e));
            }
        },
        ...mapActions(LOGIN, [ACT_LOGIN])
    },
    async mounted () {
        this.key = await generateHexString()
        event_bus.$on('qr-login-data-event', this.doLogin);
    },
    destroyed () {
        event_bus.$off('qr-login-data-event', this.doLogin);
    },
}
