//
//
//
//
//
//
//
//

import ChatItem from '../listItems/ChatItem.vue';
import IconImage from '../IconImage.vue';
import ItemListMixin from './ItemListMixin'
export default {
    name: 'search-chats',
    mixins: [ItemListMixin],
    components: {IconImage, ChatItem},
    props: {
        items: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    /* computed: {
     *     chat() {return this.items}
     * }, */
    filters: {
        count_limit(val) {return val}
    },
    watch: {
        items(val) {console.log(val);}
    }
    
}
