var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "central about" },
    [
      _c("main-content-container", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "items-list" },
                  [
                    _c(
                      "list-item",
                      { attrs: { title: _vm.$t("mainPage.roschat") } },
                      [
                        _c("img", {
                          staticClass: "logo left-slot",
                          attrs: { slot: "media", src: _vm.logoImg },
                          slot: "media"
                        })
                      ]
                    ),
                    _c(
                      "list-item",
                      {
                        staticClass: "version",
                        attrs: {
                          header: _vm.versionHeaderText,
                          title: _vm.$t("mainPage.version") + _vm.version
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "left-slot",
                            attrs: { slot: "media" },
                            slot: "media"
                          },
                          [
                            _c("i", {
                              staticClass: "status-icon",
                              class: _vm.versionIconClasses
                            })
                          ]
                        )
                      ]
                    ),
                    [
                      _vm.newClientInfo
                        ? _c(
                            "div",
                            [
                              _c(
                                "list-item",
                                {
                                  staticClass: "new-version-header",
                                  attrs: {
                                    header: _vm.$t("mainPage.new-version"),
                                    title:
                                      _vm.$t("mainPage.version") +
                                      _vm.newClientInfo.version
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "left-slot",
                                      attrs: { slot: "media" },
                                      slot: "media"
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "update-icon far fa-sync-alt"
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "footer-link",
                                      attrs: { slot: "footer-link" },
                                      slot: "footer-link"
                                    },
                                    [
                                      !_vm.buttonFooterText
                                        ? _c("list-item", {
                                            staticClass: "new-version-install",
                                            class: { disabled: _vm.updating },
                                            attrs: {
                                              title: _vm.buttonText,
                                              footer: _vm.buttonFooterText,
                                              link: true,
                                              chevron: false
                                            },
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.updateClient($event)
                                              }
                                            }
                                          })
                                        : _c("list-item", {
                                            staticClass: "new-version-install",
                                            class: { disabled: _vm.updating },
                                            attrs: {
                                              title: _vm.buttonText,
                                              footer: _vm.buttonFooterText,
                                              link: true,
                                              chevron: false
                                            },
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.updateClient($event)
                                              }
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "list-item",
                        {
                          staticClass: "new-version-changes history",
                          attrs: {
                            header: _vm.$t("mainPage.whats-new"),
                            title: _vm.newClientInfo
                              ? _vm.newClientInfo.changes
                              : _vm.lastVersionChange
                          }
                        },
                        [
                          _c("div", {
                            staticClass: "left-slot",
                            attrs: { slot: "media" },
                            slot: "media"
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "footer-link",
                              attrs: { slot: "footer-link" },
                              slot: "footer-link"
                            },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.openChanges()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("settings.versions-history"))
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  ],
                  2
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("main-content-container", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "items-list" },
                  [
                    _c("list-item", {
                      attrs: {
                        title: _vm.$t("loginPage.itsoft"),
                        footer: "2014-" + _vm.this_year
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }