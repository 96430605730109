//
//
//
//
//
//
//
//
//
//

export default {
    name: 'TypingDots'
}
