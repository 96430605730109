//
//
//
//
//
//
//
//
//
//
//

'use strict';

import IconImage from '../IconImage.vue';

import { mapGetters } from 'vuex'
import { CONTACTS } from '../../store/modulesNames'
import {GET_MERGED_CONTACT_BY_ID} from '../../store/gettersTypes'

export default {
    name: 'chat-geo-message',
    props: ['message'],
    components: {
        'icon-image': IconImage
    },
    computed: {
        msg() { return this.message.msg },
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID])
    },
    methods: {
        openLink() {
            // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//             require('electron').shell.openExternal(this.msg.location_link_url)
            // #endif

            // #if process.env.WEBPACK_BUILD_TARGET === 'web'
            window.open(this.msg.location_link_url)
            // #endif
        },
        fioById(cid) {
            return this[GET_MERGED_CONTACT_BY_ID](cid).fio || null
        },
    }
}
