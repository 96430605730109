import {
    ACT_ROSCHAT_CONF_API_CHANGE_MEETING_USER,
    ACT_ROSCHAT_CONF_API_DELETE_MEETING_USER,
    ACT_ROSCHAT_CONF_API_MEETING_INFO,
    ACT_ROSCHAT_CONF_API_MEETING_ENTER,
    ACT_ROSCHAT_CONF_API_MEETING_EXIT,
    ACT_ROSCHAT_CONF_API_MEETING_INVITE_USERS,
    ACT_ROSCHAT_CONF_API_MEETING_REJECT_USER,
    ACT_ROSCHAT_CONF_API_MEETING_START,
    ACT_ROSCHAT_CONF_API_MEETING_STOP,
    ACT_ROSCHAT_CONF_API_SEND_MEETING_ADD_CANDIDATE,
    ACT_ROSCHAT_CONF_API_SEND_MEETING_COMMAND,
    ACT_ROSCHAT_CONF_API_SEND_MEETING_OFFER,
    ACT_ROSCHAT_CONF_API_SEND_MEETING_REMOVE_CANDIDATE,
    ACT_ROSCHAT_CONF_API_SHARING_START,
    ACT_ROSCHAT_CONF_API_SHARING_STOP,
    ACT_ROSCHAT_CONF_API_GET_ICE_SERVERS,
} from '../../store/actionsTypes'

import { ROSCHAT_CONF } from '../../store/modulesNames'
import EventEmitter from '../../common/EventEmitter'

class MeetingsTransportWrapper extends EventEmitter {
    constructor({store}) {
        super()
        this.store = store
    }

    async init() {

    }

    async meetingStart(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_MEETING_START}`, payload)
    }

    async meetingStop(payload){
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_MEETING_STOP}`, payload)
    }

    async meetingInfo(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_MEETING_INFO}`, payload)
    }

    async meetingEnter(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_MEETING_ENTER}`, payload)
    }

    async meetingExit(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_MEETING_EXIT}`, payload)
    }

    async sendMeetingOffer(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_SEND_MEETING_OFFER}`, payload)
    }

    async sendMeetingAddCandidate(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_SEND_MEETING_ADD_CANDIDATE}`, payload)
    }

    async sendMeetingRemoveCandidate(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_SEND_MEETING_REMOVE_CANDIDATE}`, payload)
    }

    async changeMeetingUser(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_CHANGE_MEETING_USER}`, payload)
    }

    async deleteMeetingUser(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_DELETE_MEETING_USER}`, payload)
    }

    async meetingInviteUsers(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_MEETING_INVITE_USERS}`, payload)
    }

    async meetingRejectUser(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_MEETING_REJECT_USER}`, payload)
    }

    async sendMeetingCommand(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_SEND_MEETING_COMMAND}`, payload)
    }

    async meetingSharingStart(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_SHARING_START}`, payload)
    }

    async meetingSharingStop(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_SHARING_STOP}`, payload)
    }

    async meetingGetIceServers(payload) {
        return this.store.dispatch(`${ROSCHAT_CONF}/${ACT_ROSCHAT_CONF_API_GET_ICE_SERVERS}`, payload)
    }

    handleAPIEvent({event, payload}) {
        this.emit(event, payload)
    }
}

export default MeetingsTransportWrapper