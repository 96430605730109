var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        title: _vm.pubId
          ? _vm.$t("channel-comp.edit-publ")
          : _vm.$t("mainPage.new-post"),
        "modal-props": _vm.sumModalProps
      }
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "publication-editor" }, [
          _c(
            "div",
            { staticClass: "publication-editor-main publication-content" },
            [
              _c("div", { staticClass: "title-block" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.publication.title,
                      expression: "publication.title"
                    }
                  ],
                  staticClass: "title-input non-border-input",
                  class: { "warning-input": _vm.warning },
                  attrs: {
                    type: "text",
                    placeholder: _vm.warning
                      ? _vm.$t("enter-title")
                      : _vm.$t("title")
                  },
                  domProps: { value: _vm.publication.title },
                  on: {
                    click: _vm.checkTitle,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.publication, "title", $event.target.value)
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "info-block" }, [
                !_vm.publication.info
                  ? _c(
                      "div",
                      { staticClass: "info-placeholder div-placeholder" },
                      [_vm._v(_vm._s(_vm.$t("information.description")))]
                    )
                  : _vm._e(),
                _vm._m(0)
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "form-group switch-group non-border-input user-signature"
                },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("enter-author")))]),
                  _c("i", {
                    staticClass: "fal fa-question-circle",
                    attrs: { title: _vm.$t("enter-author-or-channel") }
                  }),
                  _c("custom-checkbox-slider", {
                    attrs: {
                      checkbox: _vm.user_signature,
                      default: _vm.user_signature,
                      height: 22
                    },
                    on: {
                      "update:checkbox": function($event) {
                        _vm.user_signature = $event
                      }
                    }
                  })
                ],
                1
              ),
              _vm.data.with_imgs.length
                ? _c("div", { staticClass: "with-imgs-block" }, [
                    _c(
                      "ul",
                      { staticClass: "with-imgs-list" },
                      _vm._l(_vm.data.with_imgs, function(item) {
                        return _c(
                          "li",
                          [
                            item.type === "image"
                              ? _c("publication-image-item", {
                                  staticClass: "img-item publication-item",
                                  attrs: {
                                    data: item,
                                    images: _vm.data.with_imgs.length
                                  }
                                })
                              : _c("publication-video-item", {
                                  staticClass: "video-item publication-item",
                                  attrs: {
                                    data: item,
                                    images: _vm.data.with_imgs.length
                                  }
                                }),
                            _c("publication-edit-icons", {
                              on: {
                                delete: function($event) {
                                  return _vm.deleteItem(item.originIndex)
                                }
                              }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm.data.files.length
                ? _c("div", { staticClass: "files-block" }, [
                    _c(
                      "ul",
                      { staticClass: "files-list vertical-list" },
                      _vm._l(_vm.data.files, function(item) {
                        return _c(
                          "li",
                          { staticClass: "inline-item" },
                          [
                            _c("publication-document-item", {
                              staticClass: "file-item publication-item",
                              attrs: {
                                data: item,
                                edit: item.index in _vm.edited_data
                              },
                              on: {
                                update: function($event) {
                                  var i = arguments.length,
                                    argsArray = Array(i)
                                  while (i--) argsArray[i] = arguments[i]
                                  return _vm.doUpdateEditedItemProp.apply(
                                    void 0,
                                    [item.index].concat(argsArray)
                                  )
                                }
                              }
                            }),
                            _c("publication-edit-icons", {
                              attrs: { "prop-change": true },
                              on: {
                                edit: function($event) {
                                  var i = arguments.length,
                                    argsArray = Array(i)
                                  while (i--) argsArray[i] = arguments[i]
                                  return _vm.doEditItemProp.apply(
                                    void 0,
                                    [item.index, "name"].concat(argsArray)
                                  )
                                },
                                delete: function($event) {
                                  return _vm.deleteItem(item.index)
                                }
                              }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm.data.audios.length
                ? _c("div", { staticClass: "audios-block" }, [
                    _c(
                      "ul",
                      { staticClass: "audios-list vertical-list" },
                      _vm._l(_vm.data.audios, function(item) {
                        return _c(
                          "li",
                          { staticClass: "inline-item" },
                          [
                            _c("publication-audio-item", {
                              staticClass: "audios-item publication-item",
                              attrs: {
                                data: item,
                                edit: item.index in _vm.edited_data
                              },
                              on: {
                                update: function($event) {
                                  var i = arguments.length,
                                    argsArray = Array(i)
                                  while (i--) argsArray[i] = arguments[i]
                                  return _vm.doUpdateEditedItemProp.apply(
                                    void 0,
                                    [item.index].concat(argsArray)
                                  )
                                }
                              }
                            }),
                            _c("publication-edit-icons", {
                              attrs: { "prop-change": true },
                              on: {
                                edit: function($event) {
                                  var i = arguments.length,
                                    argsArray = Array(i)
                                  while (i--) argsArray[i] = arguments[i]
                                  return _vm.doEditItemProp.apply(
                                    void 0,
                                    [item.index, "name"].concat(argsArray)
                                  )
                                },
                                delete: function($event) {
                                  return _vm.deleteItem(item.index)
                                }
                              }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm.data.contacts.length
                ? _c("div", { staticClass: "contacts-block" }, [
                    _c(
                      "ul",
                      { staticClass: "contacts-list vertical-list" },
                      _vm._l(_vm.data.contacts, function(item) {
                        return _c(
                          "li",
                          { staticClass: "inline-item" },
                          [
                            _c("publication-contact-item", {
                              staticClass:
                                "contact-item publication-item inline-item",
                              attrs: { data: item, "on-click": false }
                            }),
                            _c("publication-edit-icons", {
                              on: {
                                delete: function($event) {
                                  return _vm.deleteItem(item.index)
                                }
                              }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm.data.geo_location
                ? _c(
                    "div",
                    { staticClass: "geo-location-block inline-item" },
                    [
                      _c("publication-location-item", {
                        staticClass: "geo-location-item publication-item",
                        attrs: {
                          data: _vm.data.geo_location,
                          edit: _vm.data.geo_location.index in _vm.edited_data,
                          "on-click": false
                        },
                        on: {
                          update: function($event) {
                            var i = arguments.length,
                              argsArray = Array(i)
                            while (i--) argsArray[i] = arguments[i]
                            return _vm.doUpdateEditedItemProp.apply(
                              void 0,
                              [_vm.data.geo_location.index].concat(argsArray)
                            )
                          }
                        },
                        nativeOn: {
                          click: function($event) {
                            _vm.data.geo_location.index in _vm.edited_data
                              ? function() {}
                              : _vm.doSetLocation(
                                  _vm.data.geo_location.latitude,
                                  _vm.data.geo_location.longitude
                                )
                          }
                        }
                      }),
                      _c("publication-edit-icons", {
                        attrs: { "prop-change": true },
                        on: {
                          edit: function($event) {
                            var i = arguments.length,
                              argsArray = Array(i)
                            while (i--) argsArray[i] = arguments[i]
                            return _vm.doEditItemProp.apply(
                              void 0,
                              [_vm.data.geo_location.index, "text"].concat(
                                argsArray
                              )
                            )
                          },
                          delete: function($event) {
                            return _vm.deleteItem(_vm.data.geo_location.index)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.data.poll
                ? _c(
                    "div",
                    { staticClass: "poll-block inline-item" },
                    [
                      _c("chat-poll-message", {
                        attrs: { message: { msg: _vm.data.poll } }
                      }),
                      _c("publication-edit-icons", {
                        attrs: { "edit-icon": true },
                        on: {
                          edit: function($event) {
                            return _vm.doAddPoll(_vm.data.poll)
                          },
                          delete: function($event) {
                            return _vm.deleteItem(_vm.data.poll.index)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _c("div", { staticClass: "publication-editor-footer" }, [
            _c("div", { staticClass: "btns" }, [
              _c("div", { staticClass: "add-files-btns" }, [
                _c("i", {
                  staticClass: "fa fa-user",
                  on: { click: _vm.doAddContact }
                }),
                _c("i", {
                  staticClass: "fa fa-file",
                  on: {
                    click: function($event) {
                      return _vm.doAddFile("*")
                    }
                  }
                }),
                _c("i", {
                  staticClass: "fa fa-file-image",
                  on: {
                    click: function($event) {
                      return _vm.doAddFile("image/*,video/*,audio/*")
                    }
                  }
                }),
                _c("i", {
                  staticClass: "fa fa-map-marker-alt",
                  on: { click: _vm.doSetLocation }
                }),
                _c("i", {
                  staticClass: "fas fa-poll",
                  on: {
                    click: function($event) {
                      return _vm.doAddPoll()
                    }
                  }
                })
              ])
            ])
          ]),
          _c("input", {
            ref: "fileLoader",
            staticStyle: { display: "none" },
            attrs: { type: "file" },
            on: { change: _vm.uploadFile }
          })
        ])
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.$t("cancel") },
            on: {
              click: function($event) {
                return _vm.modalClose()
              }
            }
          }),
          _c("modal-btn", {
            attrs: { text: _vm.$t("publish") },
            on: { click: _vm.save }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {
      ref: "info",
      staticClass: "info-input non-border-input div-input",
      attrs: { contenteditable: "true", "strip-br": "true", required: "" },
      on: {
        paste: _vm.paste,
        input: function($event) {
          _vm.publication.info = $event.target.innerText.trim()
        }
      }
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }