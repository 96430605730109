import Vue from 'vue'
const event_bus = new Vue()
window.onclick = (...args) => {
    event_bus.$emit('window-onclick', ...args)
}
document.body.onfocus = (...args) => {
    console.log('document-body-onfocus')
    event_bus.$emit('body-onfocus', ...args)
}
document.body.onblur = (...args) => {
    console.log('document-body-onblur')
    event_bus.$emit('body-onblur', ...args)
}
document.onmouseout = (...args) => {
    event_bus.$emit('mouse-out', ...args)
}
document.onmouseover = (...args) => {
    event_bus.$emit('mouse-over', ...args)
}
document.onmousemove = (...args) => {
    event_bus.$emit('mouse-move', ...args)
}
export default event_bus
