export default {
    emitters: {
        getContacts(global) {
            return new Promise((resolve, reject) => {
                const data = {type: global ? 'global' : 'local'}
                this.log('getContacts: > ' + JSON.stringify(data))
                this.socket.emit('get-contacts', data, response => {
                    this.log('getContacts: < ' + response.length)
                    resolve(response)
                })
            })
        },
        getContact(params) {
            return new Promise((resolve, reject) => {
                this.log('getContact: > ' + JSON.stringify(params))
                this.socket.emit('get-contact', params, response => {
                    this.log('getContact: < ' + JSON.stringify(response))
                    resolve(response)
                })
            })
        },
        async getContactsStatus(params) {
            this.log('getContactsStatus: >')
            try {
                let response = await this._emitWithTimeOut('get-contacts-status', [params])
                this.log('getContactsStatus: < ' + response.length)
                return response
            } catch (e) {
                this.log('getContactsStatus: < error ' + e.message)
                return [{"cid": params, "status": {"statusTime": -1}}]
            }
        },
        setContactsStatus(params) {
            this.log('setContactsStatus: > ' + params)
            this.socket && this.socket.emit('set-contact-status', { status: params })
        },
        getBusyContacts() {
            return new Promise((resolve, reject) => {
                this.log('getBusyContacts: >')
                this.socket.emit('get-busy-contacts', null, response => {
                    this.log('getBusyContacts: < ' + response.length)
                    resolve(response)
                })
            })
        },
        changeContact(data) {
            return new Promise((resolve, reject) => {
                this.log('changeContact: >')
                this.socket.emit('change-contact', data, response => {
                    if (response.error) {
                        this.log('changeContact error: ' + response.error)
                        return reject(response.error)
                    }
                    this.log('changeContact: < rev - ' + response.rev)
                    resolve(response.rev)
                })
            })
        },
        addContact(data) {
            return new Promise((resolve, reject) => {
                this.log('addContact: >')
                this.socket.emit('add-contact', data, (response = {}) => {
                    let {error, cid} = response
                    if (error) {
                        this.log('addContact error: ' + error)
                        return reject(error)
                    }
                    this.log('addContact: < cid - ' + cid)
                    resolve(cid)
                })
            })
        },
        deleteContact(data) {
            return new Promise((resolve, reject) => {
                this.log('deleteContact: >')
                this.socket.emit('delete-contact', data, response => {
                    let { error } = response
                    if (error) {
                        this.log('deleteContact error: ' + error)
                        return reject(error)
                    }
                    this.log('deleteContact: < ok')
                    resolve(response)
                })
            })
        },
        searchContacts(data) {
            return new Promise((resolve, reject) => {
                this.log('search-contacts: > ' + JSON.stringify(data))
                this.socket.emit('search-contacts', data, (response = {}) => {
                    let {error} = response
                    if (error) {
                        console.log('!! -> file: protocol.js -> line 634 -> socket.emit -> error', error)
                        this.log('search-contacts error: ' + error)
                        return reject(error)
                    }
                    this.log('searchContacts: < response.length - ' + response.length)
                    resolve(response)
                })
            })
        },
        getContactsOrganizationFilter() {
            return new Promise((resolve, reject) => {
                this.log('get-contacts-organization-filter: >')
                this.socket.emit('get-contacts-organization-filter', null, (response) => {
                    if (response?.error) {
                        this.log('get-contacts-organization-filter error: ' + response?.error)
                        return reject(response?.error)
                    }
                    this.log('get-contacts-organization-filter: < ' + response.length)
                    resolve(response)
                })
            })
        },
        addContactConnections(data) {
            return new Promise((resolve, reject) => {
                this.log('add-contact-connections: >' + data)
                this.socket.emit('add-contact-connections', data, (response = {}) => {
                    let {error} = response
                    if (error) {
                        this.log('add-contact-connections error: ' + error)
                        return reject(error)
                    }
                    this.log('add-contact-connections: < ')
                    resolve(response)
                })
            })
        },
        getBirthdays(data) {
            return new Promise((resolve, reject) => {
                this.log('get-birthdays: >')
                this.socket.emit('get-birthdays', data, response => {
                    this.log('get-birthdays: < ')  //+ response)
                    resolve(response)
                })
            })
        },
        inviteGuestContact(data) {
            return new Promise((resolve, reject) => {
                this.log('invite-guest-user: > ' + JSON.stringify(data))
                this.socket.emit('invite-guest-user', data, (response = {}) => {
                    let {error} = response
                    if (error) {
                        this.log('invite-guest-user error: ' + error)
                        return reject(error)
                    }
                    this.log('invite-guest-user: < ')
                    resolve(response)
                })
            })
        },
    },
    listeners: {
        'contact-change-event'(data) {
            this.log('bind: < contact-change-event: ' + JSON.stringify(data))
            this.emit('contact-change-event', { type: 'global', data })
        },
        'local-contact-change-event'(data) {
            this.log('bind: < contact-change-event: ' + JSON.stringify(data))
            this.emit('contact-change-event', { type: 'local', data })
        },
        'contact-status-event'(data) {
            this.log('bind: < contact-status-event: ' + JSON.stringify(data))
            // Данный event не используется
            // store.commit('contacts/updateContactStatus', data);
        },
        'contact-busy-event'(data) {
            this.log('bind: < contact-busy-event: ' + JSON.stringify(data))
            this.emit('contact-busy-event', data)
        },
        'contact-connection-change-event'(data) {
            this.log('bind: < contact-connection-change-event: ' + JSON.stringify(data))
            console.log('bind: < contact-connection-change-event: ', JSON.stringify(data))
            this.emit('contact-connection-change-event', data)
        }
    },
}