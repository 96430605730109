var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-settings" },
    [
      _c(
        "main-content-container",
        {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "items-list" },
                    [
                      _c("list-item", {
                        attrs: { title: _vm.$t("settings.theme"), link: true },
                        nativeOn: {
                          click: function($event) {
                            return _vm.setType($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "items-list" },
                    [
                      _c("list-item", {
                        attrs: { title: _vm.$t("settings.fonts"), link: true },
                        nativeOn: {
                          click: function($event) {
                            return _vm.messagesTextSettings($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("template", { slot: "header" }, [
            _vm._v(_vm._s(_vm.$t("settings.decoration")))
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }