//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';
import { i18n } from '../../../ext/i18n';
import { vCard } from '../../../../js/ext/vCard';
import CustomCheckboxRound from "../custom/CustomCheckboxRound.vue";
import modal_mixin from '../modal/modal-mixin'
import {CONTACT_FIELD_TYPES, PHONE_CONTACT_FIELD_TYPE} from '../../constants'

export default {
    name: "SelectContactInfoExport",
    components: { CustomCheckboxRound },
    mixins: [modal_mixin],
    props: {
        fieldsArr: {
            type: Array,
            required: false,
            default: []
        }
    },
    data: ()=>({
        defChbValue: true,
        isAllChecked: true,
        isCurrentElementChecked: null,
        sumModalProps: {
            delay: 200,
            pivotY: 0.39,
            width: 480,
            height: 'auto',
            adaptive: true,
            styles: "min-width: 300px;"                
        }
    }),
    methods: {
        onChbChecked(infoElement, val) {
            infoElement.checked = val;
            val ? infoElement.elementClass='active-element' : infoElement.elementClass='inactive-element';
        },
        cancel() {
            this.modalClose()
        },
        getTranslation(str) {
            let translate = i18n.messages[i18n.locale];
            // return translate[str] ? translate[str]: translate.information[str];
            return translate.information[str]
        },
        getLabel(infoElement) {
            let title = this.getTranslation(infoElement.title);
            return `${title}: ${infoElement.text}`;
        },
        makeExport() {
            let expFields = this.fieldsArr.filter(f=>f.checked);
            this.exportToVcf(expFields);
        },
        checkUncheckAll() {
            this.isAllChecked = !this.isAllChecked;
            this.$refs.customCheckBox.forEach(checkBox=>{
                checkBox.checked = this.isAllChecked;
            })
        },
        exportToVcf(expFields) {
            let card = vCard.create(vCard.Version.FOUR);
            let fullName = '';
            let notes = '';
            expFields.forEach(k => {
                switch (k.title) {
                    case 'fio':
                        fullName+=k.text;
                        break;
                    case CONTACT_FIELD_TYPES.ORGANIZATION:
                        card.add(vCard.Entry.ORGANIZATION, k.text);
                        break;
                    case CONTACT_FIELD_TYPES.NIK:
                        card.add(vCard.Entry.NICKNAME, k.text);
                        break;
                    case CONTACT_FIELD_TYPES.WORKPHONE:
                        card.add(vCard.Entry.PHONE, k.text, vCard.Type.WORK);
                        break;
                    case CONTACT_FIELD_TYPES.MAIL:
                        card.add(vCard.Entry.EMAIL, k.text, vCard.Type.WORK);
                        break;
                    case CONTACT_FIELD_TYPES.ADDRESS:
                        card.add(vCard.Entry.ADDRESS, k.text);
                        break;
                    default:
                        let title = this.getTranslation(k.title);
                        notes+=`${title}: ${k.text}\\n`;
                        break;
                }
            })
            let revDateTime = new Date().toISOString();
            card.add(vCard.Entry.REVISION, revDateTime);
            card.add(vCard.Entry.NAME, fullName.trim());
            card.add(vCard.Entry.FORMATTEDNAME, fullName.trim());
            card.add(vCard.Entry.NOTE, notes.trim());
            let link = vCard.export(card, fullName.trim(), false);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.$modal.hide('Select-Contact-Info-Export');
        }
    }
}
