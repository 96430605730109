//
//
//
//
//
//

import OpenUrlConfirm from "../modal/OpenUrlConfirm.vue"

export default {
    name: 'bot-keyboard-msg',
    props: ['keyboard', 'chat'],
    data() {
        return {}
    },
    methods: {
        action(btn) {
            if(btn.link) {
                this.modalOpen({
                    component: OpenUrlConfirm,
                    props: {
                        linkUrl: btn.link
                    }
                })
            } else if(btn.callbackData) {
                let { cid, cidType } = this.chat
                app.proto.pressButton({cid, cidType, callbackData: btn.callbackData});
            }
        }
    }
}

