//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';
import {mapGetters, mapActions} from 'vuex';
import store from '../../store/main/store';
import IconImage from '../IconImage.vue';
import AcsStatus from '../acs/AcsStatus.vue';
import AcsAddBtn from "../acs/AcsAddBtn.vue";
import AcsJournalList from "../acs/AcsJournalList.vue";
import {ACS, LOCATIONS} from "../../store/modulesNames";
import {
    GET_ACS_CID,
    GET_ACS_TYPE,
    GET_ACS_ROLE,
    GET_ACS_INFO,
    GET_LOCATION, GET_LOCATIONS_REGION,
    GET_ACS_CURRENT_JOURNAL_ENTRY
} from '../../store/gettersTypes'
import {ACT_ACS_UPDATE_INFO} from '../../store/actionsTypes'
import { i18n } from '../../../ext/i18n'
import InfoMainBlock from "./InfoMainBlock.vue"

export default {
    name: 'acs-journal',
    store,
    components: {
        AcsJournalList,
        AcsAddBtn,
        IconImage,
        AcsStatus,
        InfoMainBlock,
    },
    data () {
        return {
            cont: {},
            show: false,
            acsLoading: true,
            imgLoading: true,
            imgError: false,
        };
    },
    computed: {
        loading () {
            return this.acsLoading || this.imgLoading
        },
        contact () {
            return app.store.getters['contacts/getMergedContactById'](this.contact_id);
        },
        regionImg() {
            if (!this.acsLoading) {
                if (this.region > -1 && !this.imgError) return `${this.$store.getters['userdata/getHost']}/regions/icon/${this.region}.jpg`
                return 'img/geo_location.jpg'
            } else {
                return ''
            }
        },
        imgStyle() {
            const isDarkTheme = this.$store.getters['clientdata/getTheme'] === 'dark'
            return isDarkTheme && this.regionImg === 'img/geo_location.jpg' ? 'filter: invert(80%) hue-rotate(193deg) saturate(3) brightness(0.7);' : ''
        },
        region(){
            if (!this.acsLoading) {
                let acsInfo = this[GET_ACS_INFO](this.contact_id)
                let locationInfo = this[GET_LOCATION](this.contact_id) || {}
                let region = this[GET_LOCATIONS_REGION](locationInfo.beaconRid || locationInfo.locationRid)
                let {locations = []} = acsInfo
                let lastLocation = locations[0] || {}
                let acsInTime = lastLocation.in && lastLocation.time || 0
                let locationInTime = locationInfo.status === 'in' && locationInfo.statusTime || 0
                if (acsInTime && locationInTime) {
                    return acsInTime > locationInTime ? 0 : `${region.id}`
                } else if (acsInTime) {
                    return 0
                } else if (locationInTime) {
                    return region.id
                }
            }
            return -1
        },
        ...mapGetters(ACS, {
            hasRights: GET_ACS_ROLE,
            contact_id: GET_ACS_CID,
            acs_type: GET_ACS_TYPE,
            [GET_ACS_INFO]: GET_ACS_INFO,
            [GET_ACS_CURRENT_JOURNAL_ENTRY]:GET_ACS_CURRENT_JOURNAL_ENTRY
        }),
        ...mapGetters(LOCATIONS, [GET_LOCATION, GET_LOCATIONS_REGION]),
    },
    methods: {
        showTitle (data) {
            if (!!data && data.length) this.show = true;
            else this.acsLoading = false;
        },
        async updateAcsInfo (cid = this.contact_id, oldCid) {
            try {
                this.acsLoading = true;
                await this[ACT_ACS_UPDATE_INFO]({cid, oldCid});
                this.acsLoading = false;
            } catch(err) {
                console.log('ACT_ACS_UPDATE_INFO error: ', err);
                this.acsLoading = false;
            }
        },
        onRegionImgError() {
            this.imgError = true
        },
        onRegionImgLoad() {
            this.imgLoading = false
        },
        ...mapActions(ACS, [ACT_ACS_UPDATE_INFO])
    },
    filters: {
        resolveAcsType (val) {
            return i18n.messages[i18n.locale].mainPage.location;
            // let res = val;
                // switch(val) {
                // case 'om':
                //     res = 'Местоположение';
                //     break;
                // case 'orion':
                //     res = 'Орион';
                //     break;
                // }
            // return res;
        }
    },
    watch: {
        async contact_id (val, oldVal) {
            this.updateAcsInfo(val, oldVal);
        },
    },
    mounted () {
        if (this.contact_id) this.updateAcsInfo(this.contact_id);
    }
}
