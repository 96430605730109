//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {ACT_SET_DND} from "../../../store/actionsTypes";
import {USERPROFILE} from "../../../store/modulesNames";
import CustomRadio from "../../custom/CustomRadio.vue";
import store from "../../../store/main/store";
import { i18n } from '../../../../ext/i18n'

export default {
    name: "DndDialog",
    components: {CustomRadio},
    store,
    data() {
        return {
            hours: 0,
            inputs: [
                {
                    value: 0,
                    label: i18n.messages[i18n.locale].mainPage.available
                }, {
                    value: 0.5,
                    label: i18n.messages[i18n.locale].mainPage['dnd-05']
                }, {
                    value: 1,
                    label: i18n.messages[i18n.locale].mainPage['dnd-1']
                }, {
                    value: 3,
                    label: i18n.messages[i18n.locale].mainPage['dnd-3']
                }, {
                    value: 12,
                    label: i18n.messages[i18n.locale].mainPage['dnd-12']
                }, {
                    value: 24,
                    label: i18n.messages[i18n.locale].mainPage['dnd-24']
                }, {
                    value: 168,
                    label: i18n.messages[i18n.locale].mainPage['dnd-168']
                }
            ]
        }
    },
    methods: {
        doClose() {
            this.$modal.hide('dnd-dialog');
        },
        doSetDnd: async function() {
            try {
                await this.$store.dispatch(`${USERPROFILE}/${ACT_SET_DND}`, 
                        this.hours ? date_helper.getCurrentUnixTime() + (this.hours * 60 * 60) : 0);
            } catch(err) {
                let locale = i18n.messages[i18n.locale];
                this.doClose();
                this.modalOpen({
                name: 'alert',
                props: {
                    title: locale.errors['error'],
                    text: locale.errors['get-profile-error']
                }
            })
            }
            this.doClose();
        }
    }
}
