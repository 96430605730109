//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from "vuex"
import { CHATS, CLIENTDATA, INFO } from "../../../store/modulesNames"
import { GET_INFO_OPEN, GET_LOCALE, GET_SELECTED_CHAT } from "../../../store/gettersTypes"
import { ACT_INFO_CLEAR, ACT_INFO_PUSH, ACT_INFO_TOGGLE } from "../../../store/actionsTypes"

import MainHeaderContainer from "./MainHeaderContainer.vue"


export default {
    name: "MainHeaderNote",
    components: { MainHeaderContainer },
    data() {
        return {
            title: this.$t('notes-chat-name'),
            iconSrc: this.noteImg,
            iconText: this.$t('notes-chat-name'),
        }
    },
    computed: {
        noteImg() {
            const isDarkTheme = this.$store.getters['clientdata/getTheme'] === 'dark'
            return isDarkTheme ? 'img/notes_dark.png' : 'img/note.png'
        },
        cid() { return this[GET_SELECTED_CHAT].cid },
        cidType: () => declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER,
        ...mapGetters(CHATS, [GET_SELECTED_CHAT]),
        ...mapGetters(INFO, [GET_INFO_OPEN]),
        ...mapGetters(CLIENTDATA, [GET_LOCALE]),
    },
    methods: {
        removeChat() {
            this.modalOpen({
                name: 'remove-chat',
                props: {
                    cid: this.cid,
                    cidType: this.cidType
                }
            })
        },
        doMenu(event, position) {
            let handlers = [],
                open = this.cmOpen;
            if (!this[GET_INFO_OPEN]) handlers.push({item_name: this.$t('information.chat-info'), handler: this.doToggleOpenInfo})
            handlers.push({item_name: this.$t('mainPage.delete-chat'), handler: this.removeChat})
            open(event, handlers, position)
        },
        doToggleOpenInfo() {
            const openedInfo = this[GET_INFO_OPEN]
            if (openedInfo && openedInfo.type === 'search-in-chat') this[ACT_INFO_CLEAR]()
            this[ACT_INFO_TOGGLE]({type: 'note-info', params: {cid: this.cid}})
        },
        openSearchChat() {
            this[ACT_INFO_CLEAR]()
            this[ACT_INFO_PUSH]({type: 'search-in-chat', params: {cid: this.cid}})
        },

        ...mapActions(INFO, [ACT_INFO_TOGGLE, ACT_INFO_CLEAR, ACT_INFO_PUSH]),
    }
}
