
import {mapGetters, mapActions} from 'vuex'
import {USERDATA, CHANNELS} from '../../store/modulesNames'
import {GET_UID} from '../../store/gettersTypes'
import {ACT_LEAVE_FROM_CHANNEL} from '../../store/actionsTypes'
import Confirm from '../modal/Confirm.vue'
import { i18n } from '../../../ext/i18n'

export default {
    name: 'LeaveChannel',
    extends: Confirm,
    computed: {
        text: () => i18n.messages[i18n.locale]['channel-comp']['leave-conf'],
        ...mapGetters(USERDATA, [GET_UID]),
    },
    methods: {
        async btnOkCb() {
            await this[ACT_LEAVE_FROM_CHANNEL]({chId: this.props.chId, contacts: [{cid: this[GET_UID]}]})
        },
        ...mapActions(CHANNELS, [ACT_LEAVE_FROM_CHANNEL]),
    }
}
