var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "media-search-images", attrs: { id: "media-search-area" } },
    _vm._l(Object.keys(_vm.messages), function(key) {
      return _c("div", { key: key, staticClass: "months-group" }, [
        _c("span", { staticClass: "months-name" }, [_vm._v(_vm._s(key))]),
        !_vm.isChannel
          ? _c(
              "div",
              { staticClass: "chat-images-wrapper" },
              _vm._l(_vm.messages[key], function(message, i) {
                return _c("chat-image-message", {
                  key: i,
                  staticClass: "media-image-search",
                  style: _vm.style,
                  attrs: { message: message },
                  nativeOn: {
                    contextmenu: function($event) {
                      $event.preventDefault()
                      return _vm.onContextMenu($event, message)
                    }
                  }
                })
              }),
              1
            )
          : _vm._e(),
        _vm.isChannel
          ? _c(
              "div",
              { staticClass: "channel-images-wrapper" },
              _vm._l(_vm.messages[key], function(message) {
                return _c(
                  "div",
                  { staticClass: "pub-image-search" },
                  _vm._l(message.msg, function(file, i) {
                    return _c("publication-image-item", {
                      key: i,
                      style: _vm.style,
                      attrs: { publication: message, file: file, index: i },
                      nativeOn: {
                        contextmenu: function($event) {
                          $event.preventDefault()
                          return _vm.onContextMenu($event, message)
                        }
                      }
                    })
                  }),
                  1
                )
              }),
              0
            )
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }