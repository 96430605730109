'use strict';

import Vue from 'vue';
import {GET_LOCATIONS_CONFIG, GET_LOCATIONS_REGION, GET_LOCATION, GET_LOCATION_IN_LOADING} from '../gettersTypes';
import {ACT_LOCATIONS_UPDATE_CONFIG, ACT_UPDATE_CONTACT_LOCATION} from '../actionsTypes';
import {MUT_SET_LOCATION_CONTACT, MUT_DELETE_LOCATION_CONTACT, MUT_SET_LOCATIONS_CONFIG} from "../mutationsTypes";
import storage from '../../storage';
import proto from "../../protocol";

const state = {
    locationsContacts: {},
    locationsConfig: storage.getLocationsConfig()
};

const getters = {
    [GET_LOCATIONS_CONFIG]: (state) => state.locationsConfig,
    [GET_LOCATIONS_REGION]: (state) => (id) => state.locationsConfig.regions && state.locationsConfig.regions.find((region) => region.id === id),
    [GET_LOCATION]: (state) => (cid) => (state.locationsContacts[cid]) ? state.locationsContacts[cid] : {}
};

const actions = {
    async [ACT_LOCATIONS_UPDATE_CONFIG] ({state, dispatch, commit}, rev) {
        if (state.locationsConfig.revison >= rev) return;
        try {
            let config = await proto.getLocationsConfig();
            storage.setLocationsConfig(config);
            commit(MUT_SET_LOCATIONS_CONFIG, config);
        } catch (e) {}
    },
    async [ACT_UPDATE_CONTACT_LOCATION] ({state, dispatch, commit}, cid) {
        try {
            let location = await proto.getLocation(cid);
            console.log('act location: ', location);
            commit(MUT_SET_LOCATION_CONTACT, {cid, location});
        } catch (e) {}
    }
};

const mutations = {
    [MUT_SET_LOCATION_CONTACT] (state, payload) {
        Vue.set(state.locationsContacts, payload.cid, payload.location);
    },
    [MUT_DELETE_LOCATION_CONTACT] (state, cid) {
        delete state.locationsContacts[cid];
    },
    [MUT_SET_LOCATIONS_CONFIG] (state, data) {
        Vue.set(state, 'locationsConfig', data);
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
