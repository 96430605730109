import EventEmitter from "../../common/EventEmitter"

class SpeechSendLimiter extends EventEmitter {
    constructor ({interval = 55}) {
        super()
        this.stack = []
        this.interval = interval
        this.timeInt = 0
        this.idleCount = 0
    }

    push(sample) {
        if (!this.timeInt) {
            this._setInterval()
            this.emit('outgoingSample', sample)
        } else {
            this.stack.push(sample)
        }
    }

    _setInterval() {
        this.timeInt = setInterval(() => {
            let sample = this.stack.shift()
            if (sample) {
                this.idleCount = 0
                this.emit('outgoingSample', sample)
            }
            else {
                this.idleCount++
                if (this.idleCount > 5) {
                    this.timeInt = clearInterval(this.timeInt)
                    this.emit('empty')
                }
            }
        }, this.interval)
    }
}

export default SpeechSendLimiter