//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';
export default {
    name: 'office-monitor-settings',
    data() {
        return {
            login: null,
            password: null,
            is_admin: false
        };
    },
    computed: {},
    filters: {},
    methods: {
        async auth() {
            try {
                await app.proto.checkOmLogin(this.login, this.password);
                app.store.commit('acs/setIsAdmin', this.is_admin);
                localStorage.setItem('acs_admin', this.is_admin?'true':'');
                localStorage.setItem('om_login', this.login);
                localStorage.setItem('om_password', this.password);
                alert('OK');
            } catch(e) {
                localStorage.removeItem('om_login');
                localStorage.removeItem('om_password');
                alert('FAIL');
            }
        }
    }
}
