//
//
//
//
//
//
//
//
//

import ModalDialog from './ModalDialog.vue';
import modal_mixin from './modal-mixin';
import { i18n } from '../../../ext/i18n'

export default {
    name: "Confirm",
    mixins: [modal_mixin],
    components: {ModalDialog},
    data() {
      return {
          compModalProps: {
            delay: 200,
            pivotY: 0.09,
            width: this.props.width || 450,
            height: 'auto',
            adaptive: true,
            styles: "min-width: 300px;"
          }
      }
    },
    computed: {
        title() { return this.props.title || i18n.messages[i18n.locale]['action-confirm'] },
        text() { return this.props.text || i18n.messages[i18n.locale]['action-confirm'] },
        okText() { return this.props.btnOk && this.props.btnOk.text || i18n.messages[i18n.locale].loginPage.ok },
        cancelText() { return this.props.btnCancel && this.props.btnCancel.text || i18n.messages[i18n.locale].cancel}
    },
    methods: {
        onOk() {
            this.modalClose();
            this.$nextTick(() => {
                this.btnOkCb && this.btnOkCb()
                this.props.btnOk && this.props.btnOk.cb && this.props.btnOk.cb()
            })

        },
        onCancel() {
            this.modalClose();
            this.props.onClose && this.props.onClose();
            this.props.btnCancel && this.props.btnCancel.cb && this.props.btnCancel.cb();
        }
    }
}
