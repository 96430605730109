import io from 'socket.io-client'

import Logger from '../../common/Logger'
import { decryptText, encryptText } from '../../common/Encrypter'
import EventEmitter from '../../common/EventEmitter'

class IOClient extends EventEmitter {
    constructor ({logName, transports = null, withCredentials = true}) {
        super()
        this.socket = null
        this.isConnected = false
        this.server = null
        this.logger = new Logger(logName)
        this.sessionKey = null
        this.reconnect = true
        this.serverApi = 0
        this.withCredentials = withCredentials
        this.transports = transports
        this.log = (str) => this.logger.info(str)
    }

    connect(server = this.server) {
        return new Promise(async (resolve, reject) => {
            if (!server) return reject('Server not specified')
            this.serverApi = 0
            this.server = server
            await this.disconnect()
            let options = {
                reconnection: false,
                ...(this.withCredentials && {withCredentials: this.withCredentials}),
                ...(this.transports && {transports: this.transports}),
                // transports: ['websocket']
                // нельзя websocket, т.к. нужны cookie
            }
            this.log(`connect: to ${server} with options ${JSON.stringify(options)}`)
            this.socket = io.connect(server, options)
            this.socket.on('connect', () => {
                this.reconnect = true
                this.isConnected = true
                this.log('connect: connected to ' + server)
                this.bindSocket()
                resolve(this.socket.io.engine.id)
                this.emit('connect', this.socket)
            })

            this.socket.on('disconnect', (e) => {
                this.log('connect: disconnect from server ' + server)
                this.isConnected = false
                this.emit('disconnect', this.reconnect)
                reject(e)
            })
            this.socket.on('connect_error', (e) => {
                this.log('connect: error ' + e)
                this.isConnected = false
                this.emit('connect_error', this.reconnect)
                reject(e)
            })
        })
    }

    disconnect() {
        return new Promise((resolve, reject) => {
            this.reconnect = false
            if (this.socket && this.socket.close) {
                this.sessionKey = null
                this.socket.close()
                this.log('Socket disconnect')
            }
            setTimeout(resolve, 100)
        })
    }

    setProtoSessionKey (key) {
        this.sessionKey = key
    }
    isEncryptedEvent(event) {
        return !['get-public-key', 'set-session-key'].includes(event)
    }
    /*isEncryptedEvent (event) {
     return !['get-public-key', 'set-session-key'].includes(event)
     }*/
    async decryptProtoData (data) {
        if (!data) return data
        if (!this.sessionKey) throw Error('session key not set')
        if (!data.encryptedData) throw Error('data not encrypted')
        const decryptedString = await decryptText(this.sessionKey, data.encryptedData)
        return JSON.parse(decryptedString)
    }
    async encryptProtoData (data) {
        if (!data) return data
        if (!this.sessionKey) throw Error('session key not set')
        const dataString = JSON.stringify(data)
        const encryptedData = await encryptText(this.sessionKey, dataString)
        return { encryptedData }
    }
    bindSocket () {
        let socketOnOrigin = this.socket.on
        let socketEmitOrigin = this.socket.emit

        this.socket.emit = async (event, data, cb) => {
            let overloadingCb = cb
            if (this.sessionKey && this.isEncryptedEvent(event)) {
                data = data && await this.encryptProtoData(data)
                if (overloadingCb) overloadingCb = async (data) => {
                    if (data && !data.error) data = await this.decryptProtoData(data)
                    cb(data)
                }
            }
            socketEmitOrigin.apply(this.socket, [event, data, overloadingCb])
        }

        this.socket.on = (event, cb) => {
            socketOnOrigin.apply(this.socket, [event, async (data, cbSocket) => {
                let overloadingCb = cbSocket
                if (this.sessionKey && this.isEncryptedEvent(event)) {
                    data = data && await this.decryptProtoData(data)
                    if (overloadingCb) overloadingCb = async (data) => cb(await this.encryptProtoData(data))
                }
                cb && cb(data, overloadingCb)
            }])
        }
    }

    addSocketEmitters(emitters = {}) {
        Object.entries(emitters).forEach(([methodName, method]) => this[methodName] = method)
        //emitters.log = this.log
        //emitters.socket = this.socket
    }

    addSocketListeners(listeners = {}) {
        Object.entries(listeners).forEach(([event, listener]) => this.socket.on(event, listener.bind(this)))
    }

    _emitWithTimeOut(eventName, payload, timeOut = 3000) {
        return new Promise((resolve, reject) => {
            let timeOutId = setTimeout(() => {
                reject(new Error('emitTimeOut'))
            }, timeOut)
            this.socket.emit(eventName, payload, (...args) => {
                clearTimeout(timeOutId)
                resolve(...args)
            })
        })
    }
}

export default IOClient