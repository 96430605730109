/**
 * Created by Aleksey on 25.06.2018.
 */

'use strict';
import Vue from 'vue';
import {
    GET_FILE_BLOB,
} from '../gettersTypes'
import {
    ACT_BLOB_ADD_FILE,
    ACT_GET_FILE_FROM_BLOB_URL,
} from '../actionsTypes'

export default class BlobStore {
    constructor() {
        this.state = {
            files: {},
            xhrs: {}
        };
        this.getters = {
            [GET_FILE_BLOB](state) {
                return function (file_name) {
                    return state.files[file_name];
                };
            },
        };
        this.actions = {
            [ACT_BLOB_ADD_FILE]({commit}, payload) {
                commit('updateFileBlob', payload)
            },
            async [ACT_GET_FILE_FROM_BLOB_URL](obj, { url }) {
                let filename = new URL(url).pathname.split('/').pop()
                let res = await fetch(url)
                let blob = await res.blob()
                return new File([blob], filename)
            },
        };
        this.mutations = {
            updateFileBlob(state, params) {
                Vue.set(state.files, params.file_name, params.link);
            },
            removeFileBlob(state, file_name) {
                delete state.files[file_name]; //@todo удалить из кэша браузера
            },
        };
    }

    get store() {
        return {
            namespaced: true,
            state: this.state,
            getters: this.getters,
            actions: this.actions,
            mutations: this.mutations
        };
    }

    
}
