var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-activity" },
    [
      _vm.activeCall
        ? _c("call-activity", { attrs: { call: _vm.activeCall } })
        : _vm._e(),
      _vm.activeConf
        ? _c("conf-activity", { attrs: { conf: _vm.activeConf } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }