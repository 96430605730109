//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { USERDATA } from "../../store/modulesNames"
import { GET_ACTIVE_SPEAKERS, GET_VOLUME_AUDIO } from "../../store/gettersTypes"

export default {
    name: "CustomAudio",
    props: {
        src: {
            type: String,
            required: false,
            default: '',
        },
        autoplay: {
            type: Boolean,
            required: false,
            default: false,
        },
        loop: {
            type: Boolean,
            required: false,
            default: false,
        },
        type: {
            type: String,
            required: false,
            default: 'audio/mpeg', //@todo ?
        },
        volume: {
            type: Number,
            required: false,
            default: 1,
        },
    },
    async mounted() {
        await this.setSinkId()
        this.compVolume !== 1 && this.setVolume()
        this.inited = true
    },
    watch: {
        async speakerSinkId() {
            await this.setSinkId()
        },
        compVolume(val) {
            this.setVolume(val)
        },
    },
    data() {
        return {
            inited: false
        }
    },
    computed: {
        compAutoplay() {
           return this.autoplay && this.inited
        },
        compVolume() {
            return this[GET_VOLUME_AUDIO] * this.volume
        },
        speakerSinkId() {
            return this[GET_ACTIVE_SPEAKERS] && this[GET_ACTIVE_SPEAKERS].deviceId
        },
        ...mapGetters(USERDATA, [GET_ACTIVE_SPEAKERS, GET_VOLUME_AUDIO])
    },
    methods: {
        play() {
            this.$refs.audio && this.$refs.audio.play()
        },
        stop() {

        },
        pause() {
            this.$refs.audio && this.$refs.audio.pause()
        },
        getCurrentSecond() {
            let audio = this.$refs.audio
            return audio.currentTime
        },
        setCurrentSecond(val) {
            let audio = this.$refs.audio
            audio.currentTime = val
        },
        async setSinkId() {
            let audio = this.$refs.audio
            try {
                this.speakerSinkId && await audio.setSinkId(this.speakerSinkId)
            } catch (e) {
                console.error(e)
            }
        },
        setVolume() {
            let audio = this.$refs.audio
            audio.volume = this.compVolume
        }
    },
}
