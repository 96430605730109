//
//
//
//
//
//

import PhoneWebrtcAudio from './PhoneWebrtcAudio.vue'
import PhoneWebrtcVideo from './PhoneWebrtcVideo.vue'
import PhoneAssistant from "./PhoneAssistant.vue"
import Conference from '../videoConf/VideoConf.vue'

import { PHONE } from '../../store/modulesNames'
import { ACT_EVENT_PHONE, ACT_PHONE_CALL } from '../../store/actionsTypes'

import { PHONE_TYPES } from '../../constants'

export default {
    name: 'phone-container',
    // props: ['call', 'showPhone'],
    props: {
        call: {
            type: Object,
            required: true
        },
        showPhone: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            type: ''
        }
    },
    created () {
        if (this.call) {
             this.onCall(this.call, undefined)
        }
    },
    watch: {
        // #if process.env.WEBPACK_BUILD_TARGET === 'web'
        async call (cur, prev) {
           this.onCall(cur, prev)
        },
        // #endif
    },
    computed: {
        component() {
            switch (this.type) {
                case PHONE_TYPES.PHONE:
                    if (!this.call) return
                    let options = this.call.data && this.call.data.options ? this.call.data.options : this.call.data
                    if (options.video) {
                        return PhoneWebrtcVideo
                    } else {
                        return PhoneWebrtcAudio
                    }
                case PHONE_TYPES.ASSISTANT:
                    return PhoneAssistant
                case PHONE_TYPES.CONFERENCE:
                    return Conference
                default:
                    return this.type
            }
        },
    },
    methods: {
        setType (type) {
            this.type = type
        },
        onCall(cur, prev) {
            if (cur === prev) return //@todo объект prev равен cur, но watch срабатывает (magic)
            if (this.call) {
                this.type = this.call.type
                if (this.call.type === PHONE_TYPES.PHONE && this.call.isIncomming) {
                    this.$store.dispatch(`${PHONE}/${ACT_EVENT_PHONE}`, {
                        eventName: 'rtc-call-event',
                        data: {isIncomming: this.call.isIncomming, ...this.call.data}
                    })
                } else if (this.call.type === PHONE_TYPES.PHONE && !this.call.isIncomming) {
                    this.$store.dispatch(`${PHONE}/${ACT_PHONE_CALL}`, this.call.data, {root: true})
                }
            } else {
                this.type = ''
            }
        }
    }
}
