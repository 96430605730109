//
//
//
//

import SystemNotification from './SystemNotification.vue'
import ipc from "../../../electron/ipc"

//import { GET_OPEN_SYS_NOTIFICATION } from "../../store/gettersTypes";
import { ACT_HIDE_SYSTEM_NOTIFICATION } from '../../store/actionsTypes'
import { NOTIFICATIONS, PHONE_CNTL } from "../../store/modulesNames";

export default {
    name: 'system-notification-ctrl',
    components: { SystemNotification },
    mounted () {
        ipc.on('close-system-notification', () => {
            console.log('close-system-notification')
            this.$store.dispatch(`${NOTIFICATIONS}/${ACT_HIDE_SYSTEM_NOTIFICATION}`)
        })
    },
    computed: {
        show() {
            return false
            //return this.$store.getters[`${PHONE_CNTL}/${GET_OPEN_SYS_NOTIFICATION}`]
        }
    }
}
