//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import prettyBytes from 'pretty-bytes'
import ProgressCircle from '../ProgressCircle.vue'
import Slider from '../Slider.vue'
import CustomAudio from "../custom/CustomAudio.vue"
import ChatMessageMixin from './chat-message-mixin'

import { mapGetters } from 'vuex'
import { CONTACTS } from '../../store/modulesNames'
import { GET_MERGED_CONTACT_BY_ID } from '../../store/gettersTypes'

export default {
        name: 'chat-audio-message',
        props: ['message', 'withDownload', 'showSize'],
        mixins: [ChatMessageMixin],
        data() {
            return {
                played: false,
                playStarted: false,
                current_second: 0,
                volume: 1,
                loaded: false,
                loading: false,
                load_progress: 0,
                timeUpdateInt: 0,
            }
        },
        components: {CustomAudio, slider: Slider, "progress-circle": ProgressCircle},
        computed: {
            id() { return this.message.id },
            msg() { return this.message.msg },
            current_time() { return date_helper.unixTimeToUserFormat(this.link && this.playStarted ? this.current_second : this.msg.duration, "mm:ss") },
            prettySize() {
                return prettyBytes(+this.msg.size, { locale: this.$t('pretty-bytes-locale') })
            },
            fileName() {
                return this.msg.name ? this.msg.name + '.' + this.msg.extension : ''
            },
            ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID])
        },
        methods: {
            doLoad() {
                this.loadMessageFile({
                    id: this.id,
                    file: this.msg.file,
                    size: this.msg.size,
                    remoteHost: this.message.remoteServerHost,
                })
            },
            clearAudioInfo () {
                this.played = false
                this.playStarted = false
                this.load_progress = 0
                this.current_second = 0
                this.timeUpdateInt = clearInterval(this.timeUpdateInt)
            },
            doPlayToggle() {
                let audio = this.$refs.audio
                this.timeUpdateInt = clearInterval(this.timeUpdateInt)

                if (this.played) {
                    audio.pause()
                } else {
                    audio.play()
                    this.playStarted = true
                    this.current_second = audio.getCurrentSecond()
                    this.timeUpdateInt = setInterval( () => {
                        if (!this.played) return clearInterval(this.timeUpdateInt)
                        this.current_second = audio.getCurrentSecond()
                    }, 500)
                }
                this.played = !this.played;
            },
            doChangeCurrentSecond: function (val) {
                let audio = this.$refs.audio
                this.current_second = this.msg.duration * (val / 100)
                audio.setCurrentSecond(this.current_second)
            },
            doChangeVolume: function (val) {
                this.volume = val / 100
            },
            fioById(cid) {
                return this[GET_MERGED_CONTACT_BY_ID](cid).fio || null
            },
            async downloadAudioFile() {
                await this.downloadFile(this.message)
            },
        }
}
