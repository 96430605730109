var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main-header-container", {
    attrs: {
      iconSrc: _vm.iconSrc,
      iconText: _vm.iconText,
      title: _vm.title,
      searchBtn: true
    },
    on: {
      menu: _vm.doMenu,
      info: _vm.doToggleOpenInfo,
      search: _vm.openSearchChat
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }