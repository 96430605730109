var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title
      }
    },
    [
      _c("template", { slot: "default" }, [
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c("custom-radio", {
              attrs: {
                inputs: _vm.locales,
                default_data: _vm.selectedlocale,
                value_change: _vm.newLocale
              },
              on: {
                "update:value_change": function($event) {
                  _vm.newLocale = $event
                }
              }
            })
          ],
          1
        )
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-default", on: { click: _vm.modalClose } },
          [_vm._v(_vm._s(_vm.$t("close")))]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { disabled: _vm.newLocale === _vm.selectedlocale },
            on: { click: _vm.changeLocale }
          },
          [_vm._v(_vm._s(_vm.$t("loginPage.ok")))]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }