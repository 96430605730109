'use strict';
class Notice {
    constructor() {
        this.state = {
            msg: '',
            title: ''
        };
        this.getters = {
            getMsg(state) {
                return state.msg;
            },
            getTitle(state) {
                return state.title;
            }

        };
        this.actions = {
            setMsg(obj, {title, msg}) {
                obj.commit('setMsg', {title, msg});
            }
        };
        this.mutations = {
            setMsg(state, {title, msg}) {
                state.title = title
                state.msg = msg
            }
        };
    }

    get store() {
        return {
            namespaced: true,
            state: this.state,
            getters: this.getters,
            actions: this.actions,
            mutations: this.mutations
        };
    }
}

const notice = (new Notice).store
export default notice
