import EventEmitter from '../common/EventEmitter'
import { unwrapEmojiToNative } from './emojis';

const emitter = new EventEmitter()

let currentSelection = null

export const getSelection = () => currentSelection

export const nodeInSelection = (node) => {
    if (!currentSelection) return false
    return document.getSelection().containsNode(node, true)
}

export const removeSelectionRanges = () => {
    if (window.getSelection) {
        if (window.getSelection().empty) {  // Chrome
            window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {  // Firefox
            window.getSelection().removeAllRanges();
        }
    } else if (document.selection) {  // IE?
        document.selection.empty();
    }
}

export const getPureSelectedText = () => {
    return currentSelection
}

export const getSelectedText = () => {
    return currentSelection
}

export const subscribeOnSelectionChange = (fn) => {
    emitter.on('change', fn)
}

export const unsubscribeOnSelectionChange = (fn) => {
    emitter.off('change', fn)
}

document.onselectionchange = function(e) {
    //console.log("!! -> file: textSelection.js -> line 44 -> e", e)
    currentSelection = ''
    const selection = document.getSelection()
    //console.log("!! -> file: textSelection.js -> line 0000046 -> selection", selection)
    if (!selection) return
    let fragment = null
    try {
        const selections = selection.rangeCount
        if (!selections) return
        fragment = selection.getRangeAt(0).cloneContents() 
    }
    catch { return }
    if (fragment) {
        let messages = fragment.querySelectorAll('div.message.text:not(.system-text)');
        if (!messages.length) {
            let tempspan = document.createElement("span");
            tempspan.appendChild(fragment)
            const nodes = tempspan.childNodes
            //console.log("!! -> file: textSelection.js -> line 60 -> nodes", nodes)
            for (let i = 0; i < nodes.length; i++) {
                let node = nodes[i]
                switch (node.nodeName) {
                    case '#text':
                        currentSelection += node.textContent
                        break;
                    case 'SPAN':
                    case 'A':
                        currentSelection += node.innerText
                        break;
                    case 'IMG':
                        if (node.className = 'emoji emojibgrd') currentSelection += node.alt
                        break;
                    default:
                        currentSelection += node.outerHTML
                        break;
                }
            }
        } else {
            [...messages].forEach(m =>{
                const text = unwrapEmojiToNative(m.innerHTML)
                currentSelection.length ? currentSelection += '\n' + text : currentSelection += text
            })
        }
        //emitter.emit('change', currentSelection)
    }
}