'use strict';

import {GET_MODALS} from '../gettersTypes';
import {ACT_MODAL_OPEN, ACT_MODAL_CLOSE} from '../actionsTypes';
import {MUT_MODAL_OPEN, MUT_MODAL_CLOSE} from '../mutationsTypes';

const state = {
    modals: []
};


const getters = {
    [GET_MODALS]: state => state.modals,
};

const actions = {
    [ACT_MODAL_OPEN]: ({state, dispatch, commit}, payload) => {
        commit(MUT_MODAL_OPEN, payload);
    },
    [ACT_MODAL_CLOSE]: ({state, dispatch, commit}, payload) => {
        commit(MUT_MODAL_CLOSE, payload);
    }
};

const mutations = {
    [MUT_MODAL_OPEN]: (state, payload) => {
        if (payload.component) payload.name = payload.component.name
        state.modals.push(payload)
    },
    [MUT_MODAL_CLOSE]: (state, payload) => {
        let modals = state.modals.filter((modal) => modal.name === payload.name);
        modals.forEach((modal) => state.modals.splice(state.modals.indexOf(modal) ,1))
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
