var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-textarea-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "custom-textarea-placeholder",
        class: _vm.placeholder_class
      },
      [_vm._v(_vm._s(_vm.custom_placeholder))]
    ),
    _vm._m(0),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isFormatter && _vm.formatterShow,
            expression: "isFormatter && formatterShow"
          }
        ],
        staticClass: "textFormatter",
        class: _vm.formatterShow ? "" : "hidden",
        attrs: { id: "textFormatter" }
      },
      [
        _c("div", { staticClass: "tfbuttons" }, [
          _c(
            "button",
            {
              staticClass: "tfbutton",
              attrs: { type: "button", title: _vm.$t("chat.bold-text") },
              on: {
                click: function($event) {
                  return _vm.formatSelection("bold")
                }
              }
            },
            [_c("i", { staticClass: "fal fa-bold" })]
          ),
          _c(
            "button",
            {
              staticClass: "tfbutton",
              attrs: { type: "button", title: _vm.$t("chat.italic-text") },
              on: {
                click: function($event) {
                  return _vm.formatSelection("italic")
                }
              }
            },
            [_c("i", { staticClass: "fal fa-italic" })]
          ),
          _c(
            "button",
            {
              staticClass: "tfbutton",
              attrs: { type: "button", title: _vm.$t("chat.underline-text") },
              on: {
                click: function($event) {
                  return _vm.formatSelection("underline")
                }
              }
            },
            [_c("i", { staticClass: "fal fa-underline" })]
          ),
          _c(
            "button",
            {
              staticClass: "tfbutton",
              attrs: {
                type: "button",
                title: _vm.$t("chat.strikethrough-text")
              },
              on: {
                click: function($event) {
                  return _vm.formatSelection("strikethrough")
                }
              }
            },
            [_c("i", { staticClass: "fal fa-strikethrough" })]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        ref: "custom_input_textarea",
        staticClass: "custom-textarea",
        attrs: { id: "custom-textarea1", contenteditable: !_vm.disabled },
        on: {
          focus: function($event) {
            return _vm.focus(true)
          },
          paste: _vm.paste,
          blur: function($event) {
            return _vm.focus(false)
          },
          keydown: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.send($event)
            },
            _vm.onKeyDown
          ],
          input: _vm.update,
          keyup: _vm.onKeyup,
          contextmenu: _vm.onClick,
          click: _vm.onClick,
          dblclick: _vm.onClick
        }
      },
      [_vm._v(_vm._s(_vm.text))]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }