import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ru from '../app/langs/ru.json';
import en from '../app/langs/en.json';

let vuex = localStorage.getItem('vuex')
let setLocale
if (vuex) {
    vuex = JSON.parse(vuex)
    if (vuex.clientdata && vuex.clientdata.locale) setLocale = vuex.clientdata.locale
    else setLocale = 'ru'
} else setLocale = 'ru'

Vue.use(VueI18n);
Vue.locale = () => {};

export const i18n = new VueI18n({
    locale: setLocale,
    fallbackLocale: 'en',
    messages: { ru, en }
});