'use strict'

import ipc from '../electron/ipc'
import { CONTENT_MANAGER } from '../app/store/modulesNames'
import { MUT_SET_SEARCH_OPENED } from '../app/store/mutationsTypes'
import { HOTKEYS_ACTION_LOCAL, HOTKEYS_USER_SETTINGS_DEFAULT } from '../app/constants'

export function initHotKeys(window, event_bus, store) {
    if (!localStorage.getItem('hotkeys')) localStorage.setItem('hotkeys', JSON.stringify(HOTKEYS_USER_SETTINGS_DEFAULT))
    let hotkeys = JSON.parse(localStorage.getItem('hotkeys'))
    const isMac = window.navigator.userAgent.indexOf("Mac") !== -1
    if (isMac) {
        let changeItems = hotkeys.filter(i=>i.hotKeysStr.indexOf('Meta')!==-1)
        changeItems.forEach(i => {
            i.hotKeysStr = i.hotKeysStr.replace(/Meta/g,'Cmd')
        })
        localStorage.setItem('hotkeys', JSON.stringify(hotkeys))
    }
    let globalHotkeys = hotkeys.filter(hk => hk.global)
    // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//     ipc.send('hotkeys-start-event', { hotkeys: globalHotkeys })
    // #endif

    assignLocalHotkeys()

    event_bus.$on('assign-local-hotkeys', () => {
        assignLocalHotkeys()
    })

    const maxKeysNum = 3
    let keysNum = 0
    let keysStr = ''
    
    event_bus.$on('close-search', () => {
        keysNum = 0
        keysStr = ''
    })

    function assignLocalHotkeys() {
        window.onkeydown = (e) => funcKeyDownEvListener(e)
        window.onkeyup = () => onUpHandle()
    }

    function onUpHandle() {
        setTimeout(() => {
            keysNum = 0
            keysStr = '' 
        }, 300);
    }

    function funcKeyDownEvListener(e) {
        {
            let { id, charsNumber, localHotkeysCompareStr } = getHotKeysCompareStr()
            let localMaxKeysNum = (charsNumber < maxKeysNum) ? charsNumber : maxKeysNum
            let activeElement = document.activeElement
            keysStr += e.code.replace('Key', '').replace('Digit', '')
            keysNum++
            if (keysNum > localMaxKeysNum) {
                keysNum = 0
                keysStr = ''
            }
            if ((!keysNum || keysNum === 1) && !isSpecialKey(e.code)) {
                keysNum = 0
                keysStr = ''
            }
            if (id === HOTKEYS_ACTION_LOCAL.SEARCH) {
                let equal = localHotkeysCompareStr.toLowerCase() === keysStr.toLowerCase()
                if (equal) {
                    store.commit(`${CONTENT_MANAGER}/${MUT_SET_SEARCH_OPENED}`, true)
                }
            }
            if (e.key === "Escape" && activeElement.tagName === "INPUT" && activeElement.className === "search-input") {
                store.commit(`${CONTENT_MANAGER}/${MUT_SET_SEARCH_OPENED}`, false)
                keysNum = 0
                keysStr = ''
            }
        }
    }

    function getHotKeysCompareStr() {
        if (!localStorage.getItem('hotkeys')) localStorage.setItem('hotkeys', JSON.stringify(HOTKEYS_USER_SETTINGS_DEFAULT))
        const hotkeys = JSON.parse(localStorage.getItem('hotkeys'))
        let localHotkeys = hotkeys.filter(hk => hk.local)
        let localHotkeysStr = localHotkeys[HOTKEYS_ACTION_LOCAL.SEARCH].electronStr
        let localHotkeysArr = localHotkeysStr.split('+')
        let charsNumber = localHotkeysArr.length
        let localHotkeysCompareStr = localHotkeysStr.replace(/Ctrl/g, 'Control').replace(/\+/g, '')
        let id = HOTKEYS_ACTION_LOCAL.SEARCH
        return { id, charsNumber, localHotkeysCompareStr }
    }

    function isSpecialKey(code) {
        return code.substring(0,7) === 'Control' || 
            code.substring(0,5) === 'Shift' || 
            code.substring(0,4) === 'Meta' || 
            code.substring(0,3) === 'Alt'
    }
}