//
//
//
//
//
//
//
//
//

export default {
    name: 'custom-checkbox-round',
    props: {
        default: {
            type: Boolean,
            required: false,
            default: false
        },
        value: {
            type: Boolean,
            required: false,
            default: false
        },            
        label: {
            type: String,
            required: false,
            default: ''
        },
        sublabel: {
            type: String,
            required: false,
            default: ''
        },
        tabindex: {
            type: [String, Number],
            required: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },            
    },
    data() {
        return {
            checked: this.default
        };
    },
    watch: {
        checked() {
            this.$emit('update:checkbox', this.checked);
        },
        value(val) {
            this.checked = val
        }
    }
}
