//
//
//
//
//
//
//
//
//
//
//
//

import ConfBtn from "./confBtn.vue"
import ContextMenuBase from "../contextMenu/ContextMenuBase.vue"
import contextMenuMixin from "../contextMenu/ContextMenuMixin"
export default {
    name: "videoConfSchemeSelect",
    mixins: [ contextMenuMixin ],
    components: { ConfBtn, ContextMenuBase },
    props: {
        share: {
            type: Boolean,
            required: false,
            default: false,
        },
        cb: {
            type: Function,
            required: true
        }
    },
    methods: {
        setSchema(schema) {
            this.cb(schema)
            this.close()
        },
    }
}
