//
//
//
//
//
//

import { mapGetters } from "vuex"
import { PHONE_CNTL, VIDEO_CONF_NEW } from "../../../store/modulesNames"
import { GET_ACTIVE_ANSWERED_CALL, GET_VC_ACTIVE_CONF } from "../../../store/gettersTypes"

import CallActivity from './CallActivity.vue'
import ConfActivity from './ConfActivity.vue'

export default {
    name: "MainActivity",
    components: {
        CallActivity,
        ConfActivity
    },
    computed: {
        activeCall() {
            return this[GET_ACTIVE_ANSWERED_CALL]
        },
        activeConf() {
            return this[GET_VC_ACTIVE_CONF]
        },
        ...mapGetters(PHONE_CNTL, [GET_ACTIVE_ANSWERED_CALL]),
        ...mapGetters(VIDEO_CONF_NEW, [GET_VC_ACTIVE_CONF]),
    }
}
