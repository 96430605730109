'use strict'
import Vue from 'vue'

import {
    BLOB,
    USERDATA,
} from '../modulesNames'

import {
    AJAX_CALL_IS_EXIST,
    GET_AJAXX_CALL_IS_EXIST_BY_FILE,
    GET_AJAX_CALL_PROGRESS,
    GET_AJAXX_CALL_PROGRESS_BY_FILE,
    GET_HOST,
} from '../gettersTypes'

import {
    ACT_ABORT_AJAX_CALL,
    ACT_ADD_AJAX_CALL,
    ACT_AJAX_GET_FILE,
    ACT_AJAX_SEND_FILE,
    ACT_BLOB_ADD_FILE,
    ACT_UPDATE_AJAX_CALL_PROGRESS,
} from '../actionsTypes'

import { decryptArrayBuffer, encryptArrayBuffer } from '../../common/Encrypter'

import Logger from '../../common/Logger'
const logger = new Logger('ajax')

const state = {
    call_id: 1,
    ajax_calls: {},
    ajax_files_to_calls: {},
}

const getters = {
    getLast: state => state.call_id,
    //[GET_AJAX_CALL]: state => id => state.ajax_calls[id] && state.ajax_calls[id].call,
    [AJAX_CALL_IS_EXIST]: state => id => !!state.ajax_calls[id],
    [GET_AJAX_CALL_PROGRESS]: state => id => state.ajax_calls[id] && state.ajax_calls[id].progress || 0,
    [GET_AJAXX_CALL_IS_EXIST_BY_FILE]: (state, getters) => (fileName) => {
        return state.ajax_files_to_calls[fileName]
    },
    [GET_AJAXX_CALL_PROGRESS_BY_FILE]: (state, getters) => (fileName) => {
        const callId = state.ajax_files_to_calls[fileName]
        if (callId) return getters[GET_AJAX_CALL_PROGRESS](callId)
    },
}

const actions = {
    [ACT_ABORT_AJAX_CALL]({commit}, id) {
        commit('abortCall', id)
        commit('removeCall', id)
    },
    [ACT_ADD_AJAX_CALL]({commit}, call) {
      commit('addCall', call)
    },
    [ACT_UPDATE_AJAX_CALL_PROGRESS]({commit}, payload) {
        commit('updateCallProgress', payload)
    },
    async [ACT_AJAX_SEND_FILE]({state, commit, dispatch, rootGetters}, {url, file, encKey, set_log = '', cb}) {
        return new Promise(async (resolve, reject) => {
            logger.info(`ACT_AJAX_SEND_FILE: url - ${url}, fileName - ${file.name}, encKey - ${!!encKey}`)
            cb = cb || function (err, fileName) {
                if (err) return reject(err)
                resolve(fileName)
            }
            if (encKey) {
                file = await new Promise((resolve, reject) => {
                    let reader = new FileReader()
                    reader.onload = async () => {
                        let arrayBuffer = reader.result
                        let enc = await encryptArrayBuffer(encKey, arrayBuffer)
                        resolve(new File([new Blob([new Uint8Array(enc)])], file.name))
                    }
                    reader.onerror = reject
                    reader.readAsArrayBuffer(file)
                })
            }

            const xhr = new XMLHttpRequest()

            const id = state.call_id + 1

            commit('addCall', {id, file: url, xhr })

            url = rootGetters[`${USERDATA}/${GET_HOST}`] + '/' + url

            // обработчик для закачки
            xhr.upload.onprogress = (event) => {
                commit('updateCallProgress', {id : id, progress: Math.floor(event.loaded * 100 / event.total)})
                logger.info(`ACT_AJAX_SEND_FILE progress ${Math.floor(event.loaded * 100 / event.total) + '% (' + event.loaded + ' / ' + event.total + ')'}`)
            }

            xhr.onabort = () => {
                commit('removeCall', id)
                logger.info(`ACT_AJAX_SEND_FILE aborted: file ${file.name}`)
                cb && cb(new Error(`xhr aborted`))
            }

            xhr.onerror = (e) => {
                commit('removeCall', id)
                const error = new Error(`xhr error`)
                logger.error(error, `ACT_AJAX_SEND_FILE error: file ${file.name}`)
                cb && cb(error)
            }

            // обработчики успеха и ошибки
            // если status == 200, то это успех, иначе ошибка
            xhr.onload = () => {
                commit('removeCall', id)
                if (xhr.status === 200) {
                    logger.info(`ACT_AJAX_SEND_FILE status ok: file - ${file.name} `)
                    dispatch(`${BLOB}/${ACT_BLOB_ADD_FILE}`, {
                        file_name: url + xhr.responseText,
                        link: window.URL.createObjectURL(file)
                    }, {root: true})
                    cb && cb(null, xhr.responseText)
                } else {
                    const error = new Error(`xhr error status: ${xhr.status}`)
                    logger.error(error, `ACT_AJAX_SEND_FILE status error: status - ${xhr.status},  file - ${file.name} `)
                    cb && cb(error)
                }
            }

            const formData = new FormData()
            formData.append("file", file)

            xhr.open("POST", url)
            xhr.send(formData)
        })
    },
    async [ACT_AJAX_GET_FILE]({state, commit, dispatch, rootGetters}, {url, remoteHost = '', fileSize, link = false, encKey}) {
        return new Promise(async (resolve, reject) => {
            logger.info(`ACT_AJAX_GET_FILE: url - ${url}, fileSize - ${fileSize}, link - ${link}, encKey - ${!!encKey}`)
            const xhr = new XMLHttpRequest()
            //log('msg', 'getFile: ' + url)
            xhr.responseType = 'blob'

            const fileName = url
            const id = state.call_id + 1

            commit('addCall', { id, file: fileName, xhr })


            if (fileSize) xhr.onprogress = (event) => {
                let load_progress = event.loaded / (fileSize / 100)
                if (load_progress > 100) load_progress = 100
                commit('updateCallProgress', {id : id, progress: load_progress})
            }

            xhr.onabort = () => {
                commit('removeCall', id)
                logger.info(`ACT_AJAX_GET_FILE aborted: file ${fileName}`)
                reject(new Error(`xhr aborted`))
            }
            xhr.onerror = (e) => {
                commit('removeCall', id)
                const error = new Error(`xhr error`)
                logger.error(error, `ACT_AJAX_GET_FILE error: file ${fileName}`)
                reject(error)
            }
            xhr.onload = async () => {
                commit('removeCall', id)
                if (xhr.status === 200) {
                    logger.info(`ACT_AJAX_GET_FILE status ok: file - ${fileName} `)
                    let blob = xhr.response
                    if (encKey) {
                        let array = await blob.arrayBuffer()
                        blob = new Blob([await decryptArrayBuffer(encKey, array)])
                    }
                    if (link) {
                        const blobUrl = window.URL.createObjectURL(blob)
                        dispatch(`${BLOB}/${ACT_BLOB_ADD_FILE}`, {
                            file_name: fileName,
                            link: blobUrl,
                        }, {root: blob})
                    }
                    resolve(blob)
                } else {
                    const error = new Error(`xhr error status: ${xhr.status}`)
                    logger.error(error, `ACT_AJAX_GET_FILE status error: status - ${xhr.status},  file - ${fileName}`)
                    reject(error)
                }
            }
            let host
            if (remoteHost) host = `https://${remoteHost}`
            else host = rootGetters[`${USERDATA}/${GET_HOST}`]
            xhr.open('GET', host + '/' + url, true)
            xhr.send()
        })
    },

}

const mutations = {
    addCall(state, {id, file, xhr}) {
        Vue.set(state, 'call_id', id)
        Vue.set(state.ajax_calls, state.call_id, { file, call: xhr, progress: 0 })
        Vue.set(state.ajax_files_to_calls, file, id)
    },
    abortCall(state, id) {
        state.ajax_calls[id] && state.ajax_calls[id].call.abort()
    },
    removeCall(state, id) {
        let file = (state.ajax_calls[id] || {}).file
        Vue.delete(state.ajax_calls, id)
        if (file) Vue.delete(state.ajax_files_to_calls, file)
    },
    updateCallProgress(state, params) {
        Vue.set(state.ajax_calls[params.id], 'progress', params.progress)
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
