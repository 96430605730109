import ipc from '../../../../electron/ipc'
import {
    ACT_PHONE_CALL,
    ACT_PHONE_GET_ICE_SERVERS,
    ACT_PHONE_RTC_CALL_ANSWER_API,
    ACT_PHONE_RTC_CALL_API,
    ACT_PHONE_RTC_CALL_DTMF_API,
    ACT_PHONE_RTC_CALL_OPTS_CHANGED_API,
    ACT_PHONE_RTC_CALL_RINGING_API,
    ACT_PHONE_RTC_CALL_TERMINATION_API,
    ACT_PHONE_RTC_CALL_TRANSIT_API,
    ACT_PHONE_RTC_CALL_CONFERENCE_API,
    ACT_PHONE_RTC_CALL_SEND_REPORT_API,
} from '../../actionsTypes'

import { i18n } from '../../../../ext/i18n'

const locale = i18n.messages[i18n.locale]

import module from '../../modules/phone'
import proto from "../../../protocol"

const getters = {
    getConnectionStatus (state, _getters, _rootState, rootGetters) {
        return rootGetters['socket/getConnectionStatus']
    },
}

Object.assign(module.getters, getters)

const actions = {
    // Переопределяем экшены для электрона
    // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//     [ACT_PHONE_CALL] ({ state, commit, getters, dispatch }, data) {
//         if (getters['getConnectionStatus'] === 'connected') ipc.send('phone-call', data)
//         else dispatch('notice/setMsg', { title: locale.errors.error, msg: locale['no-serv-conn'] }, { root: true })
//     },
    // #endif
    async [ACT_PHONE_GET_ICE_SERVERS]({getters}) {
        let iceServers = [{ "url": "stun:" + getters['getStun'] }]
        let response = await proto.getTurnServer()
        iceServers.push({
            url: 'turn:' + response.server,
            username: response.username,
            credential: response.password
        })
        return iceServers
    },
    async [ACT_PHONE_RTC_CALL_API](obj, {cid, options, number, sdp, candidates}) {
        return proto.rtcCall(cid, options, number, sdp, candidates)
    },
    [ACT_PHONE_RTC_CALL_ANSWER_API](obj, {callId, options, sdp, candidates}) {
        proto.rtcCallAnswer(callId, options, sdp, candidates)
    },
    [ACT_PHONE_RTC_CALL_RINGING_API](obj, callId) {
        proto.rtcCallRinging(callId)
    },
    [ACT_PHONE_RTC_CALL_TERMINATION_API](obj, payload) {
        proto.rtcCallTermination(payload)
    },
    [ACT_PHONE_RTC_CALL_OPTS_CHANGED_API](obj, { callId, options }) {
        proto.rtcCallHold(callId, options)
    },
    [ACT_PHONE_RTC_CALL_DTMF_API](obj, { callId, digits }) {
        proto.rtcCallDtmf(callId, digits)
    },
    [ACT_PHONE_RTC_CALL_TRANSIT_API](obj, {callId, transitId}) {
        proto.rtcCallTransfer(callId, transitId)
    },
    [ACT_PHONE_RTC_CALL_CONFERENCE_API](obj, {callId, confId}) {
        proto.rtcCallConference(confId, callId)
    },
    [ACT_PHONE_RTC_CALL_SEND_REPORT_API](obj, {report}) {
        proto.sendCallsStats(report)
    }
}

Object.assign(module.actions, actions)

export default module;
