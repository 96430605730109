//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

"use strict"
import ProgressCircle from "../ProgressCircle.vue"
import ContentLoader from "../main/body/MainContentLoader.vue"
import ChatMessageMediaViewer from "../mediaViewer/ChatMessageMediaViewer.vue"
import ChatMessageMixin from "./chat-grouped-message-mixin"
import { GET_MERGED_CONTACT_BY_ID } from '../../store/gettersTypes'

const gifMaxSize = 5000000

export default {
    name: "chat-image-message",
    props: {
        message: {
            type: Object,
        },
    },
    components: {
        "progress-circle": ProgressCircle,
        ContentLoader,
    },
    mixins: [ChatMessageMixin],
    data() {
        return {
            groupSrcs: [],
            groupMsgs: [],
            currentGroupIndex: -1,
        }
    },
    created() {
        this.refreshGroupMsgs()
    },
    computed: {
        groupMsgsLen() {
            return this.message && this.message.groupMsgs.length
        }
    },
    watch: {
        groupMsgsLen(newVal, oldVal) {
            if (newVal !== oldVal) this.refreshGroupMsgs()
        }
    },
    methods: {
        async refreshGroupMsgs() {
            this.groupMsgs = this.message.groupMsgs
            this.groupSrcs = []
            let tempSrc = []
            await Promise.all(this.message.groupMsgs.map(async (msg, i)=>{
                let src = await this.getSrc(msg, i)
                tempSrc.push(src)
            }))
            this.groupSrcs = tempSrc
        },
        async getSrc(msg, i) {
            this.currentGroupIndex = i
            const remoteHost = this.message.remoteServerHost || ''
            if (this.link) {
                return this.link
            }
            else {
                let { extension, size } = msg
                if (extension === 'gif' && size <= gifMaxSize) {
                    return await this.getGifPreview(msg, remoteHost)
                }
                let preview = await this.getPreview(msg, remoteHost)
                return preview
            }
        },
        doShowViewer(index) {
            this.$modal.show(
                ChatMessageMediaViewer, {
                    src: this.msg,
                    message: this.message,
                    type: "image",
                    index
                }, {
                    name: "viewer",
                    delay: 200,
                    width: "100%",
                    height: "100%",
                    classes: ["v--modal", "v--modal-box", "no-gap-on-top"]
                })
        },
        fioById(cid) {
            return this[GET_MERGED_CONTACT_BY_ID](cid).fio || null
        },
    }
};
