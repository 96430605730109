
import {mapGetters, mapActions} from 'vuex'
import {CONTACTS} from '../../store/modulesNames'
import {GET_FAVOURITES_CIDS} from '../../store/gettersTypes'
import {ACT_PUSH_FAVOURITES} from '../../store/actionsTypes'
import SelectContact from './SelectContact.vue'
import { i18n } from '../../../ext/i18n'

export default {
    name: 'SelectContactsToFavorites',
    extends: SelectContact,
    computed: {
        multiple: () => true,
        okText: () => i18n.messages[i18n.locale].add,
        excludeMembers() {
            return this[GET_FAVOURITES_CIDS]
        },
        ...mapGetters(CONTACTS, [GET_FAVOURITES_CIDS])
    },
    methods: {
        onOk (contacts) {
            this[ACT_PUSH_FAVOURITES](contacts.map(({cid}) => cid))
            this.modalClose()
        },
        ...mapActions(CONTACTS, [ACT_PUSH_FAVOURITES]),

    }
}
