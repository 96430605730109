//
//
//
//
//
//
//
//
//
//

import MainContentContainer from "./MainContentContainer.vue"
import ListItem from "../../custom/ListItem.vue"
import IconImage from "../../IconImage.vue"
import MSExchangeSettings from "../../modal/integrations/MSExchangeSettings.vue"
import AddIntegration from "../../modal/integrations/AddIntegration.vue"
import {mapGetters} from 'vuex'
import {USERDATA} from '../../../store/modulesNames'
import {GET_INTEGRATION_SETTINGS} from '../../../store/gettersTypes'
import {INTEGRATION_NAMES} from '../../../constants'

export default {
    name: "MainContentIntegrations",
    components: { ListItem, MainContentContainer, IconImage, MSExchangeSettings, AddIntegration },
    mounted() {
   
    },
    data() {
        return {
            icon_src: "img/MS_Exchange.png",
            icon_txt: ""
        }
    },
    watch: {

    },
    computed: {
        showExchange() {
            return Boolean(this[GET_INTEGRATION_SETTINGS](INTEGRATION_NAMES.MS_EXCHANGE))
        },
        ...mapGetters(USERDATA, [GET_INTEGRATION_SETTINGS]),
    },
    methods: {
        msExchangeSettings() {
            this.modalOpen({
                component: MSExchangeSettings,
                props: {}
            })
        },            
        addIntegration() {
            this.modalOpen({
                component: AddIntegration,
                props: {}
            })
        },
    }
}
