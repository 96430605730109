import { render, staticRenderFns } from "./PublicationFooter.vue?vue&type=template&id=aa0b79da&scoped=true&lang=pug&"
import script from "./PublicationFooter.vue?vue&type=script&lang=js&"
export * from "./PublicationFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa0b79da",
  null
  
)

export default component.exports