//
//
//
//

export default {
    name: "ModalBtn",
    props: {
        text: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClick(e) {
            if (!this.disabled) this.$emit('click', e)
        }
    }
}
