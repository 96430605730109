//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import login_mixin from './login-mixin';
import {mapGetters, mapActions} from 'vuex';
import {
    GET_LOGIN_DATA,
    GET_OAUTH2_ENABLED_TYPES,
    GET_ROSCHAT_REDIRECTED_SERVER,
    IS_SELF_REGISTRATION_ENABLED,
} from '../../store/gettersTypes'
import {ACT_LOGIN, ACT_START_OAUTH} from "../../store/actionsTypes";
import {LOGIN, USERDATA} from '../../store/modulesNames'
import CustomInputText from "../custom/CustomInputText.vue";
import CustomCheckbox from "../custom/CustomCheckbox.vue";

export default {
    name: "LoginPass",
    mixins: [login_mixin],
    components: {CustomInputText, CustomCheckbox},
    data() {
        return {
            login:  null,
            password:  null,
            remember_me: false,
            login_status: null,
            show: false,
            disableLogin: false,
            isPasswordRevealed: false
        }
    },
    computed: {
        avanpostEnabled() {
            return this[GET_OAUTH2_ENABLED_TYPES].includes('avanpost')
        },
        adEnabled() {
            return this[GET_OAUTH2_ENABLED_TYPES].includes('ad')
        },
        registrationEnabled() {
            return this[IS_SELF_REGISTRATION_ENABLED]
        },
        ...mapGetters(LOGIN, [GET_LOGIN_DATA, GET_OAUTH2_ENABLED_TYPES]),
        ...mapGetters(USERDATA, [GET_ROSCHAT_REDIRECTED_SERVER, IS_SELF_REGISTRATION_ENABLED]),
    },
    methods: {
        async doLogin() {
            if(!this.login || !this.password || this.disableLogin) return;
            this.disableLogin = true;
            let focus_on,
                password_input = this.$refs.input_pass.$el.querySelector('input'),
                login = this.login,
                password = this.password,
                remember_me = true;

            if (!login) focus_on = this.$refs.input_mail.$el.querySelector('input');
            if (!focus_on && !password) focus_on = password_input;

            if (focus_on) return setTimeout(() => focus_on.focus(), 200);

            try {
                this.$emit('logging');
                const data = await this.ACT_LOGIN({login, password, remember_me});
                if (data.status === 'pin-required') this.$parent.doAdd('2FA', {login, password, remember_me, uid: data.uid})
            } catch (e) {
                focus_on = password_input;
                this.showAlert(this.getLoginError(e), focus_on);
                this.disableLogin = false;
            }
        },
        doOpenEntrance(type, props) {
            this.$parent.doAdd(type, props);
        },
        doOpenRegisterPage() {
            const ourUrl = new URL(this[GET_ROSCHAT_REDIRECTED_SERVER])
            ourUrl.pathname = 'register/newUser'
            window.openLink(ourUrl.toString())
        },
        togglePasswordReveal() {
            this.isPasswordRevealed = !this.isPasswordRevealed
        },
        ...mapActions(LOGIN, {ACT_LOGIN, 'oauth': ACT_START_OAUTH})
    },
    beforeMount() {
        this.login = this[GET_LOGIN_DATA].login;
        this.password = this[GET_LOGIN_DATA].password;
        this.remember_me = this[GET_LOGIN_DATA].remember_me;
    }
}
