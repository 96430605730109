var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.message
    ? _c(
        "div",
        {
          staticClass: "reply-message",
          on: {
            click: function($event) {
              return _vm.scrollingToElement(_vm.id)
            }
          }
        },
        [
          _c("div", { staticClass: "color-line", class: _vm.bgColorClass }),
          _c("div", { staticClass: "reply-block" }, [
            _vm.preview
              ? _c("div", { staticClass: "reply-img" }, [
                  _c("img", { attrs: { src: _vm.preview } })
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "reply-info" },
              [
                _c(
                  "div",
                  { staticClass: "reply-info-name", class: _vm.colorClass },
                  [_vm._v(_vm._s(_vm.infoTitle))]
                ),
                _c("message-text", { attrs: { message: _vm.message } })
              ],
              1
            )
          ]),
          _c("i", { staticClass: "fas fa-times", on: { click: _vm.close } })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }