//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "CustomListItem",
    props: {
        topAccountId: {
            type: [String, Number]
        },
        header: {
            type: [String, Number, Boolean]
        },
        title: {
            type: [String, Number]
        },
        after: {
            type: [String, Number]
        },
        footer: {
            type: [String, Number]
        },
        link: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        noHover: {
            type: Boolean,
            default: false
        },
        dynamicHeight: {
            type: Boolean,
            default: false
        },
        htmlTitle: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        has_media_slot() {
            return !!this.$slots["media"];
        },
        styles() {
            const styles = {};
            if (!this.dynamicHeight)
                Object.assign(styles, {
                    "--list-item-height": "66px",
                    "--list-item-margin": "auto"
                });
            if (this.dynamicHeight)
                Object.assign(styles, {
                    "--list-item-height": "auto",
                    "--list-item-margin": "12px"
                });
            return styles;
        },
        classes() {
            return {
                link: this.link,
                active: this.active,
                "no-hover": this.noHover
            };
        }
    },
    methods: {
        formHtmlLinks(title) {
            return this.lookForLinks(title)
        },
        emitLinkContextMenu(e) {
            this.$emit("linkcontextmenu", e)
        }
    }
};
