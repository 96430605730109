//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import {
    CONTACTS,
    PHONE_CNTL,
} from "../../store/modulesNames"
import {
    GET_CALL_PARTICIPANTS,
    GET_MERGED_CONTACT_BY_PHONE,
    GET_MERGED_CONTACT_BY_ID,
} from "../../store/gettersTypes"

import IconImage from "../IconImage.vue"

export default {
    name: "PhoneAudioIcons",
    components: {
        IconImage
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        id: {
            type: [String, Number],
            required: true,
        },
        pause: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            width: 300,
        }
    },
    computed: {
        cssVars() {
            const defSize = 120
            let iconSize = defSize
            let fontSize = 36
            let border = 0
            const iconsCount = this.icons.length
            if (iconSize * iconsCount > this.width) {
                iconSize = Math.floor(this.width / iconsCount)
                fontSize = Math.floor(iconSize / (defSize / fontSize))
            }
            if (iconsCount > 1) border = Math.ceil(iconSize / 20)
            const borderPart = this.isDarkTheme ? 'var(--bg-color)' : 'white'
            return {
                '--icon-size': iconSize + 'px',
                '--font-size': fontSize + 'px',
                '--negative-margine': (0 - (iconSize / 4)) + 'px',
                '--icon-border': border + 'px solid ' + borderPart
            }
        },
        participants() {
            return this[GET_CALL_PARTICIPANTS](this.type, this.id)
        },
        isDarkTheme() {
            return this.$store.getters['clientdata/getTheme'] === 'dark'
        },
        icons() {
            return this.participants.map(({number, contact}) => {
                const src = contact && contact.photo || ''
                const text = contact && contact.fio || number || this.$t('modal.not-defined-number')
                return { src, text }
            })
        },

        ...mapGetters(PHONE_CNTL, [GET_CALL_PARTICIPANTS]),
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_PHONE, GET_MERGED_CONTACT_BY_ID]),
    },
    methods: {
        togglePause() {
            this.$emit('toggle-pause')
        }
    },
}
