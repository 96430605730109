//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { USERDATA } from "../../store/modulesNames"
import {GET_ACTIVE_SPEAKERS, GET_VOLUME_AUDIO} from "../../store/gettersTypes"

export default {
    name: "CustomVideo",
    props: {
        src: {
            type: String,
            required: false,
            default: '',
        },
        preload: {
            type: [Boolean, String],
            required: false,
            default: false,
        },
        autoplay: {
            type: Boolean,
            required: false,
            default: false,
        },
        loop: {
            type: Boolean,
            required: false,
            default: false,
        },
        volume: {
            type: Number,
            required: false,
            default: 1,
        },
        controls: {
            type: Boolean,
            required: false,
            default: false,
        },
        playsinline: {
            type: Boolean,
            required: false,
            default: false,
        },
        muted: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    async mounted() {
        await this.setSinkId()
        this.$refs.video && this.compVolume !== 1 && this.setVolume(this.compVolume)
        this.inited = true
    },
    watch: {
        async speakerSinkId() {
            await this.setSinkId()
        },
        compVolume(val) {
            this.setVolume(val)
        },
    },
    data() {
        return {
            inited: false
        }
    },
    computed: {
        compAutoplay() {
            return this.autoplay && this.inited
        },
        compVolume() {
            return this[GET_VOLUME_AUDIO] * this.volume
        },
        speakerSinkId() {
            return this[GET_ACTIVE_SPEAKERS] && this[GET_ACTIVE_SPEAKERS].deviceId
        },
        ...mapGetters(USERDATA, [GET_ACTIVE_SPEAKERS, GET_VOLUME_AUDIO])
    },
    methods: {
        play() {
            this.$refs.video && this.$refs.video.play()
        },
        stop() {

        },
        pause() {
            this.$refs.video && this.$refs.video.pause()
        },
        getCurrentSecond() {
            let audio = this.$refs.video
            return audio.currentTime
        },
        setCurrentSecond(val) {
            let audio = this.$refs.video
            audio.currentTime = val
        },
        async setSinkId() {
            let audio = this.$refs.video
            try {
                await audio.setSinkId(this.speakerSinkId)
                //await audio.play()
            } catch (e) {
                console.error(e)
            }
        },
        setVolume() {
            let audio = this.$refs.video
            audio.volume = this.compVolume
        }
    }
}
