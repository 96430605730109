//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'

import {VIDEO_CONF_NEW} from "../../store/modulesNames"
import {GET_VC_CONF_INFO} from "../../store/gettersTypes"

import { INFO_PROPS } from "../../store/modules/video-conf-abstract"

import ListItem from "../custom/ListItem.vue"
import CustomRoundBtn from "../custom/CustomRoundBtn.vue"

import ContextMenuGroup from "../contextMenu/ContextMenuGroup.vue"
import ContextMenuBase from "../contextMenu/ContextMenuBase.vue"

import videoConfMixin from "./videoConfMixin"
import contextMenuMixin from "../contextMenu/ContextMenuMixin"
import Utils from "../../../utils"

export default {
    name: "videoConfInfo",
    components: { ContextMenuGroup, ContextMenuBase, CustomRoundBtn, ListItem },
    mixins: [videoConfMixin, contextMenuMixin],
    data() {
        return {
            title: this.$t('videomost.info.title')
        }
    },
    computed: {
        details() {
            return [
                {
                    title: this.$t('videomost.info.subject'),
                    text: this.info[INFO_PROPS.TOPIC] || {}
                },
                {
                    title: this.$t('videomost.info.owner'),
                    text: this.info[INFO_PROPS.OWNER] || {}
                },
                {
                    title: this.$t('videomost.info.id'),
                    text: this.info[INFO_PROPS.CONF_ID] || {}
                },
                {
                    title: this.$t('videomost.info.password'),
                    text: this.info[INFO_PROPS.PASSWORD] || {}
                },
                {
                    title: this.$t('videomost.info.link'),
                    text: this.info[INFO_PROPS.LINK] || {}
                },
            ]
        },
        info() {
            return this[GET_VC_CONF_INFO](this.type, this.id) || {}
        },
        ...mapGetters(VIDEO_CONF_NEW, [GET_VC_CONF_INFO]),
    },
    methods: {
        copyLink() {
            let utils = new Utils()
            utils.copyTextToClipboard(null, this.info[INFO_PROPS.LINK])
            this.close()
        },
    }
}
