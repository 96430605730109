//
//
//
//
//
//
//
//
//

import CustomCheckboxSlider from './CustomCheckboxSlider.vue'
export default {
    name: 'CustomSwitchItem',
    components: { CustomCheckboxSlider },
    computed: {
        title() { return '' },
        switchValue() { return false },
        switchText() { return '' },
    },
    methods: {
        onClick() { },
    },
}
