var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { staticClass: "members" }, [
        _c("div", { staticClass: "members-container" }, [
          _c("div", { staticClass: "headerr" }, [
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.$t("videomost.conf-members")))
            ]),
            _c(
              "div",
              {
                staticClass: "close",
                on: {
                  click: function($event) {
                    return _vm.close(true)
                  }
                }
              },
              [_c("i", { staticClass: "fal fa-times" })]
            )
          ]),
          _c("div", { staticClass: "contentt" }, [
            _c(
              "div",
              { staticClass: "items-list" },
              _vm._l(_vm.membersSorted, function(member) {
                return _c(
                  "list-item",
                  {
                    key: member.id,
                    class: { banned: !!member.banned },
                    attrs: {
                      title: member.name,
                      footer: _vm.getItemFooter(member),
                      link: true,
                      chevron: false
                    }
                  },
                  [
                    _c("template", { slot: "after" }, [
                      _c("div", { staticClass: "icons-block" }, [
                        _c("div", { staticClass: "icons" }, [
                          !member.microphone
                            ? _c(
                                "svg",
                                {
                                  staticClass: "strikeline",
                                  attrs: { height: "24", width: "24" }
                                },
                                [
                                  _c("line", {
                                    staticStyle: {
                                      stroke: "rgb(255,0,0)",
                                      "stroke-width": "2"
                                    },
                                    attrs: {
                                      x1: "0",
                                      y1: "24",
                                      x2: "24",
                                      y2: "0"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _c("i", {
                            staticClass: "fal fa-microphone",
                            class: member.want_speak ? "requested" : "",
                            style: member.microphone_global
                              ? ""
                              : "opacity: 0.5;"
                          })
                        ]),
                        _c("div", { staticClass: "icons" }, [
                          !member.camera
                            ? _c(
                                "svg",
                                {
                                  staticClass: "strikeline",
                                  attrs: { height: "24", width: "24" }
                                },
                                [
                                  _c("line", {
                                    staticStyle: {
                                      stroke: "rgb(255,0,0)",
                                      "stroke-width": "2"
                                    },
                                    attrs: {
                                      x1: "0",
                                      y1: "24",
                                      x2: "24",
                                      y2: "0"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _c("i", {
                            staticClass: "fal fa-video",
                            class: member.want_speak ? "requested" : "",
                            style: member.camera_global ? "" : "opacity: 0.5;"
                          })
                        ]),
                        _vm.isModerator
                          ? _c(
                              "div",
                              {
                                staticClass: "icons",
                                attrs: { title: _vm.$t("menu") },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "member-menu",
                                      $event,
                                      member,
                                      "bottom-left"
                                    )
                                  },
                                  contextmenu: function($event) {
                                    return _vm.$emit(
                                      "member-menu",
                                      $event,
                                      member,
                                      "bottom-left"
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  { attrs: { width: "10px", height: "24px" } },
                                  [
                                    _c("circle", {
                                      attrs: { cx: "5", cy: "5", r: "2" }
                                    }),
                                    _c("circle", {
                                      attrs: { cx: "5", cy: "12", r: "2" }
                                    }),
                                    _c("circle", {
                                      attrs: { cx: "5", cy: "19", r: "2" }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ])
                  ],
                  2
                )
              }),
              1
            )
          ]),
          _c("div", { staticClass: "footerr" }, [
            _c("div", { staticClass: "buttons" }, [
              _c(
                "div",
                { staticClass: "btns-left" },
                [
                  _c("modal-btn", {
                    attrs: { text: _vm.$t("videomost.conf-members-invite") },
                    on: { click: _vm.openInviteContextMenu }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }