export default {
    emitters: {
        addPoll(cb) {
            this.log(`add-poll: > `)
            this.socket.emit('add-poll', null, (data) => {
                this.log(`add-poll: < ${JSON.stringify(data)}`)
                return cb && cb(data)
            })
        },
        givePollVote(params, cb) {
            this.log(`give-poll-vote: > ${JSON.stringify(params)}`)
            this.socket.emit('give-poll-vote', params, (data) => {
                this.log(`give-poll-vote: < ${JSON.stringify(data)}`)
                return cb && cb(data)
            })
        },
        getPollResult(params) {
            return new Promise(resolve => {
                this.log(`get-poll-result: > ${JSON.stringify(params)}`)
                this.socket.emit('get-poll-result', params, data => {
                    this.log(`get-poll-result: < ${JSON.stringify(data)}`)
                    resolve(data)
                })
            })
        },
    },
    listeners: {},
}