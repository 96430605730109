//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import event_bus from '../../eventBus'
import moment from 'moment'

import {mapActions} from 'vuex'
import {VM_CONF} from '../../store/modulesNames'
import {ACT_VM_CONF_SEARCH_CONFERENCE} from '../../store/actionsTypes'

import ListItem from '../custom/ListItem.vue'
import NewConf from '../videoConfNewManagment/newConference.vue'
import EnterConf from './enterConference.vue'
import PlanConf from '../videoConfNewManagment/scheduleConference.vue'
import ConfInfo from './conferenceInfo.vue'
import MainContentContainer from '../main/body/MainContentContainer.vue'
import MainContentLoader from '../main/body/MainContentLoader.vue'

export default {
    name: 'mainContentVideoConfs',
    components: {
        MainContentContainer,
        MainContentLoader,
        ListItem,
    },
    data () {
        return {
            loader: false,
            mainTitle: this.$t('videomost.menu-conferences'),
            subjectName: '',
            from: 0,
            to: 0,
            confID: '',
            confPassword: '',
            compModalProps: {
                delay: 100,
                pivotY: 0.33,
                width: 500,
                height: 'auto',
                clickToClose: false,
            },
            okText: this.$t('videomost.conf-plan'),
            cancelText: this.$t('videomost.cancel'),
            confSettingsText: this.$t('videomost.conf-settings'),
            confPlanned: [],
            confPassed: [],
        }
    },
    mounted () {
        event_bus.$off('reload-conf-lists')
        event_bus.$on('reload-conf-lists', () => {
            console.log('reload-conf-lists')
            this.loadConfLists()
        })
        this.loadConfLists()
    },
    methods: {
        getLocalTime (dt) {
            return moment.unix(dt).local().format('DD.MM.YYYY HH:mm')
        },
        async loadConfLists () {
            this.loader = true
            const currentUnixTime = Math.floor(Date.now() / 1000)
            const sorting = 'finishTime DESC'
            const pagesize = 20
            try {
                this.confPlanned = await this[ACT_VM_CONF_SEARCH_CONFERENCE]({
                    filter: {'cg-finishUTime': currentUnixTime},
                    pagesize,
                })
                this.confPassed = await this[ACT_VM_CONF_SEARCH_CONFERENCE]({
                    filter: {'cl-finishUTime': currentUnixTime},
                    sorting,
                    pagesize,
                })
            } catch (e) {
                console.log('Error on conf search', e)
            }
            // console.log("!!  - file: mainContentVideoConfs.vue - loadConfLists - this.confPassed", this.confPassed)
            if (this.confPlanned) this.confPlanned = this.confPlanned.sort((a, b) => b.startUTime - a.startUTime)
            // console.log("!!  - file: mainContentVideoConfs.vue - loadConfLists - this.confPlanned", this.confPlanned)
            this.loader = false
        },
        newConference () {
            this.modalOpen({component: NewConf})
        },
        enterConference () {
            this.modalOpen({component: EnterConf})
        },
        planNewConf () {
            this.modalOpen({
                component: PlanConf,
                props: {
                    from: this.from,
                    to: this.to,
                    subjectName: this.subjectName,
                    confID: this.confID,
                    confPassword: this.confPassword,
                    confMode: this.confMode,
                },
            })
        },
        openContextMenu (event, conf, position = 'bottom-right') {
            let handlers = []
            let open = this.cmOpen
            handlers.push({
                item_name: this.$t('videomost.conf-info-context'),
                handler: () => {
                    this.confInfo(conf)
                },
            })
            open(event, handlers)
        },
        async confInfo (conf) {
            this.modalOpen({
                component: ConfInfo,
                props: {conf},
            })
        },
        ...mapActions(VM_CONF, [ACT_VM_CONF_SEARCH_CONFERENCE]),
    },
}
