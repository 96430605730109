//
//
//
//
//
//
//
//
//

import ContactsListItem from '../listItems/ContactsListItem.vue';
import ItemListMixin from './ItemListMixin'

export default {
    name: 'search-contacts',
    mixins: [ItemListMixin],
    components: {ContactsListItem},
    props: {
        items: {
            type: Array,
            required: false,
            default: () => []
        },
        noswitch: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        doSelect(contact_id) {
            this.$emit('update:contact-selected', contact_id);
        },
    },
}
