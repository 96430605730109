//
//
//
//
//
//
//
//
//
//
//
//
//

"use strict";

export default {
    name: "custom-dropdown",
    props: {
        options: {
            type: Array,
            required: true
        },
        labelField : {
            type: String,
            required: false,
            default: 'name'
        },
        default: {
            type: [String, Object],
            required: false,
            default: null
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        noOptions : {
            type: String,
            required: false,
            default: ''
        },        
        tabindex: {
            type: Number,
            required: false,
            default: 0
        },
        firstOption: {
            type: [Boolean, String],
            required: false,
            default: false
        },
        itemsClass: {
            type: Object,
            required: false,
            default: ()=>({})
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },          
    },
    data() {
        return {
            input_required: false,
            selected: '',
            open: false
        };
    },
    watch: {
        default(newVal, oldVal) {
            if (newVal) this.selected = newVal
        }
    },
    mounted() {
        if (this.default) {
            this.options.forEach(option => {
                if (option.name === this.default.name) this.selected = this.default  
            })
        } else if (this.firstOption) {
            this.selected = this.options[0]
        }
    },
    computed: {
        up() {
            return this.selected
        }
    },
    methods: {
        onClick(option) {
            this.input_required = false
            this.selected = option
            this.open = false
            this.$emit("dd-selected-value", option.value)
        }
    }
};
