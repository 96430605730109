var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-wrapper" },
    [
      _c("transition", { attrs: { name: _vm.transitionName } }, [
        _c(
          "div",
          { key: _vm.key, staticClass: "transition-wrapper" },
          [
            _c("info-header", {
              staticClass: "sections",
              attrs: { currentInfo: _vm.currentInfo, canBack: _vm.canBack }
            }),
            _c("info-body", {
              staticClass: "sections",
              attrs: { currentInfo: _vm.currentInfo, canBack: _vm.canBack }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }