var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ptt-btn" }, [
    _c("div", { staticClass: "slot top-left" }, [_vm._t("top-left")], 2),
    _c("div", { staticClass: "slot top-right" }, [_vm._t("top-right")], 2),
    _c("div", { staticClass: "ptt-cont", style: _vm.cssVars }, [
      _c("div", { staticClass: "ptt-inner-cont" }, [
        _vm.isMicEnabled
          ? _c("i", { staticClass: "ptt-icon fas fa-microphone" })
          : _c("i", { staticClass: "ptt-icon fas fa-microphone-slash" })
      ])
    ]),
    _c("div", { staticClass: "slot bottom-left" }, [_vm._t("bottom-left")], 2),
    _c("div", { staticClass: "slot bottom-right" }, [_vm._t("bottom-right")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }