//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { GET_INCOMING_CALLS } from '../../store/gettersTypes';
import { PHONE_CNTL } from "../../store/modulesNames"
import IncommingCall from './IncommingCall.vue'

export default {
	name: 'IncommingCallWrapper',
	components: { IncommingCall },
	computed: {
		calls() {
            return this[GET_INCOMING_CALLS]()
		},
        ...mapGetters(PHONE_CNTL, [GET_INCOMING_CALLS]),
	},
}
