const text =
`class AudioMsgRecorder extends AudioWorkletProcessor {
  static get parameterDescriptors() {
    return [{
      name: 'isRecording',
      defaultValue: 0
    }]
  }

  constructor() {
    super()
    this._stack = []
    this._record = true
    this._init()
  }

  _init() {
    this.port.onmessage = ({data}) => {
      let { type, payload } = data
      this._handleMessage(type, payload)
    }
  }

  _handleMessage(type, payload) {
    switch (type) {
      case 'stop':
        let buffer = this._buildFile()
        this.port.postMessage({
          eventType: 'stopped',
          buffer
        })
        this._record = false
        break
    }
  }

  _buildFile() {

    let leftBuffer = this._flattenArray(this._stack, this._stack.reduce((len, cur) => cur.length + len, 0))
    //var rightBuffer = flattenArray(rightchannel, recordingLength)
    // we interleave both channels together
    // [left[0],right[0],left[1],right[1],...]
    let interleaved = leftBuffer

    // we create our wav file
    let buffer = new ArrayBuffer(44 + interleaved.length * 2)
    let view = new DataView(buffer)

    // RIFF chunk descriptor
    this._writeUTFBytes(view, 0, 'RIFF')
    view.setUint32(4, 44 + interleaved.length * 2, true)
    this._writeUTFBytes(view, 8, 'WAVE')
    // FMT sub-chunk
    this._writeUTFBytes(view, 12, 'fmt ')
    view.setUint32(16, 16, true) // chunkSize
    view.setUint16(20, 1, true) // wFormatTag
    view.setUint16(22, 1, true) // wChannels: stereo (2 channels)
    view.setUint32(24, sampleRate, true) // dwSamplesPerSec
    view.setUint32(28, sampleRate * 4, true) // dwAvgBytesPerSec
    view.setUint16(32, 4, true) // wBlockAlign
    view.setUint16(34, 16, true) // wBitsPerSample
    // data sub-chunk
    this._writeUTFBytes(view, 36, 'data')
    view.setUint32(40, interleaved.length * 2, true)

    // write the PCM samples
    let index = 44
    let volume = 1
    for (let i = 0; i < interleaved.length; i++) {
      view.setInt16(index, interleaved[i] * (0x7FFF * volume), true)
      index += 2
    }

    // our final blob
    return view
    //blob = new Blob([view], { type: 'audio/wav' })
  }

  _writeUTFBytes(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i))
    }
  }

  _flattenArray (channelBuffer, recordingLength) {
    let result = new Float32Array(recordingLength)
    let offset = 0
    for (let i = 0; i < channelBuffer.length; i++) {
      let buffer = channelBuffer[i]
      result.set(buffer, offset)
      offset += buffer.length
    }
    return result
  }


  process(inputs) {

    const input = inputs[0]

    const inputChannel0 = input[0]

    if (inputChannel0) {
      let sample = new Float32Array(inputChannel0.length)
      sample.set(inputChannel0.subarray())
      this._stack.push(sample)
    }

    return this._record
  }

}

registerProcessor('audio-msg-recorder', AudioMsgRecorder)`

export default text