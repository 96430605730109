var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chat-reply-message",
      class: _vm.classes,
      on: {
        click: function($event) {
          return _vm.scrollingToElement(_vm.message.replyId)
        }
      }
    },
    [
      _vm.replymsg
        ? [
            _c("div", {
              staticClass: "color-line",
              class: _vm.getBGClassByMessage(_vm.replymsg)
            }),
            _c("div", { staticClass: "reply-block" }, [
              _vm.preview
                ? _c("div", { staticClass: "reply-img" }, [
                    _c("img", { attrs: { src: _vm.preview } })
                  ])
                : _vm._e(),
              _c("div", { staticClass: "reply-info" }, [
                _c(
                  "div",
                  {
                    staticClass: "reply-info-name",
                    class: _vm.getColorClassByMessage(_vm.replymsg)
                  },
                  [_vm._v(_vm._s(_vm.replymsg.fio))]
                ),
                _vm.replymsg.msg.type === "image"
                  ? _c("div", { staticClass: "reply-info-text" }, [
                      _vm._v(_vm._s(_vm.$t("image")))
                    ])
                  : _vm.replymsg.msg.type === "video"
                  ? _c("div", { staticClass: "reply-info-text" }, [
                      _vm._v(_vm._s(_vm.$t("video")))
                    ])
                  : _vm.replymsg.msg.type === "file"
                  ? _c("div", { staticClass: "reply-info-text" }, [
                      _vm._v(_vm._s(_vm.$t("file")))
                    ])
                  : _vm.replymsg.msg.type === "audio"
                  ? _c("div", { staticClass: "reply-info-text" }, [
                      _vm._v(_vm._s(_vm.$t("audio")))
                    ])
                  : _vm.replymsg.msg.type === "contact"
                  ? _c("div", { staticClass: "reply-info-text" }, [
                      _vm._v(_vm._s(_vm.$t("contact")))
                    ])
                  : _vm.replymsg.msg.type === "location"
                  ? _c("div", { staticClass: "reply-info-text" }, [
                      _vm._v(_vm._s(_vm.$t("mainPage.location")))
                    ])
                  : _vm.replymsg.msg.type === "channel"
                  ? _c("div", { staticClass: "reply-info-text" }, [
                      _vm._v(_vm._s(_vm.$t("channel")))
                    ])
                  : _vm.replymsg.msg.type === "publication"
                  ? _c("div", { staticClass: "reply-info-text" }, [
                      _vm._v(_vm._s(_vm.$t("publication")))
                    ])
                  : _vm.replymsg.msg.type === "poll"
                  ? _c("div", { staticClass: "reply-info-text" }, [
                      _vm._v(_vm._s(_vm.$t("poll")))
                    ])
                  : _vm.replymsg.msg.type === "call-availability"
                  ? _c("div", { staticClass: "reply-info-text" }, [
                      _vm._v(_vm._s(_vm.$t("chat.abonentIsAvailable")))
                    ])
                  : _vm.replymsg.msg.type === "text"
                  ? _c("div", {
                      staticClass: "reply-info-text",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formattedMsgText(_vm.replymsg.msg.text)
                        )
                      }
                    })
                  : _c("div", {
                      staticClass: "reply-info-text",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formattedMsgText(_vm.replymsg.msg)
                        )
                      }
                    })
              ])
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }