var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-input-wrapper", on: { click: _vm.focusInput } },
    [
      _c("div", {
        staticClass: "div-textarea",
        attrs: { contenteditable: _vm.contenteditable, id: _vm.idTextarea },
        on: { input: _vm.update, focus: _vm.onFocus, blur: _vm.onBlur }
      }),
      _vm.placeholder
        ? _c("div", { staticClass: "custom-input-placeholder" }, [
            _vm._v(_vm._s(_vm.placeholderName))
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }