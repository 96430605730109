//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from '../../../vuex'
import { USERPROFILE, INTEGRATIONS } from '../../store/modulesNames'
import { ACT_SCHEDULE_GET_APPOINTMENT } from '../../store/actionsTypes'
import { GET_CONFSCALENDAR } from '../../store/gettersTypes'
import CustomCheckbox from '../custom/CustomCheckboxRound.vue'
import SelectContactsToScheduler from '../modal/SelectContactsToScheduler.vue';

export default {
    name: 'confSettingsBase',
    components: { CustomCheckbox, SelectContactsToScheduler },
    props:  {
        confId: {
            type: [String, Number],
            required: false,
            default: ''
        },        
    },
    data() {
        return {
            isAddCalendar: false,
            attendees: [],
            mailsStr: '',            
        }
    },
    // mounted() {
    //     console.log("🚀 ~ file: addToCalendar.vue:47 ~ this.confId:", this.confId)
    // },
    watch: {
        async isAddCalendar(val) {
            this.$emit('isAddCalendar', val)
            if (val) {
                const confsCalendar = this[GET_CONFSCALENDAR](this.confId)
                if (confsCalendar) {
                    const appointmentId = confsCalendar.appointmentId
                    const appointment = await this.getCalendarAppointment(appointmentId)
                    const { attendees } = appointment
                    if (attendees && attendees.length) this.mailsStr = attendees.map(at => at.address).join(', ')
                }
            }
        },
        mailsStr(newVal, oldVal) {
            const prevAttendees = oldVal.trim().split(/,| |, /).filter(mail => mail.length)
            const newAttendees = newVal.trim().split(/,| |, /).filter(mail => mail.length)
            setTimeout(() => {
                this.attendees = newAttendees
                if (prevAttendees.length !== newAttendees.length) this.$emit('attendees', this.attendees)
            }, 200)
        },
    },
    computed: {
        ...mapGetters(USERPROFILE, [GET_CONFSCALENDAR]),
    },
    methods: {
        ...mapActions(INTEGRATIONS, [ACT_SCHEDULE_GET_APPOINTMENT]),        
        selectContacts() {
            this.modalOpen({
                component: SelectContactsToScheduler,
                props: {
                    preSelectedEmails: this.attendees,
                    cb: (contacts) => {
                        let emails = contacts.map(c => c.mails[0].value)
                        this.attendees = emails
                        let contactsStr = emails.join(', ')
                        if (this.mailsStr.trim().length) this.mailsStr = contactsStr
                        else this.mailsStr = contactsStr
                    }
                }
            })
        },
        async getCalendarAppointment(id) {
            return await this[ACT_SCHEDULE_GET_APPOINTMENT]({ type: "exchange", id })
        },        
    }
}
