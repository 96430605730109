var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.birthdays.length
    ? _c("div", { staticClass: "birthdays-wrapper" }, [
        _c("div", { staticClass: "birthdays" }, [
          _c("div", { staticClass: "header" }, [
            _c("img", { staticClass: "icon", attrs: { src: "img/balls.png" } }),
            _c("h4", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.text))])
          ]),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.opened,
                  expression: "opened"
                }
              ],
              staticClass: "birthdays-list"
            },
            _vm._l(_vm.birthdays, function(birthday) {
              return _c(
                "li",
                {
                  staticClass: "birthday-item",
                  on: {
                    click: function($event) {
                      return _vm.doOnSelected(birthday.cid)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "birthday-data-wrapper" },
                    [
                      _c("icon-image", {
                        attrs: { src: birthday.photo, text: birthday.fio }
                      }),
                      _c("div", { staticClass: "birthday-data" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(!!birthday && birthday.fio))
                        ]),
                        _c("span", { staticClass: "date" }, [
                          _vm._v(_vm._s(!!birthday && birthday.date))
                        ])
                      ])
                    ],
                    1
                  )
                ]
              )
            }),
            0
          )
        ]),
        _c("div", { staticClass: "bd-right" }, [
          _c("i", {
            staticClass: "fal fa-times bd-close",
            on: { click: _vm.doWatched }
          }),
          _c("div", { staticClass: "bd-space" })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }