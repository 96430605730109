var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-header-info" }, [
    _c("div", { staticClass: "header-text" }, [
      _c(
        "div",
        {
          staticClass: "header-left-block",
          on: {
            click: function($event) {
              return _vm.$emit("info")
            }
          }
        },
        [
          _vm.iconSrc || _vm.iconText
            ? _c("icon-image", {
                attrs: { src: _vm.iconSrc, text: _vm.iconText }
              })
            : _vm._e(),
          _c("div", { staticClass: "text-wrapper header-main" }, [
            _c(
              "div",
              { staticClass: "title-block" },
              [
                _vm._t("title-before"),
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.title))
                ]),
                _vm._t("title-after")
              ],
              2
            ),
            _vm.showTitleFooter
              ? _c(
                  "div",
                  { staticClass: "title-footer-block" },
                  [
                    _vm._t("title-footer-before"),
                    _c("div", { staticClass: "description-text sub-text" }, [
                      _vm._v(_vm._s(_vm.titleFooter))
                    ]),
                    _vm._t("title-footer-after")
                  ],
                  2
                )
              : _vm._e()
          ])
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "header-btns buttons-block" },
      [
        _vm._t("btns-before"),
        _vm.searchBtn
          ? _c("div", {
              staticClass: "circle-animation header-chat-search",
              attrs: { title: _vm.$t("search-in-chat") },
              on: {
                click: function($event) {
                  return _vm.$emit("search")
                }
              }
            })
          : _vm._e(),
        _vm.infoToggleBtn
          ? _c(
              "div",
              {
                staticClass: "circle-animation",
                on: {
                  click: function($event) {
                    return _vm.$emit("info")
                  }
                }
              },
              [_c("i", { staticClass: "fal fa-window-maximize fa-rotate-90" })]
            )
          : _vm._e(),
        _vm.menu
          ? _c(
              "div",
              {
                staticClass: "circle-animation",
                attrs: { title: _vm.$t("mainPage.menu") },
                on: {
                  click: function($event) {
                    return _vm.$emit("menu", $event, "bottom-right")
                  }
                }
              },
              [
                _c("svg", { attrs: { width: "10px", height: "24px" } }, [
                  _c("circle", { attrs: { cx: "5", cy: "5", r: "2" } }),
                  _c("circle", { attrs: { cx: "5", cy: "12", r: "2" } }),
                  _c("circle", { attrs: { cx: "5", cy: "19", r: "2" } })
                ])
              ]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }