var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "fade-user-profile" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.open,
                expression: "open"
              }
            ],
            staticClass: "user-profile"
          },
          [
            _c("div", { staticClass: "head" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("mainPage.settings")))
              ]),
              _c("i", {
                staticClass: "fal fa-times close",
                on: { click: _vm.hide }
              })
            ]),
            _c(
              "div",
              { staticClass: "wrapper" },
              [
                _c(
                  "ul",
                  { staticClass: "left-menu" },
                  _vm._l(_vm.menu_groups, function(group) {
                    return _c("div", { staticClass: "left-menu-group" }, [
                      _c("span", { staticClass: "group-name" }, [
                        _vm._v(_vm._s(_vm._f("translateGroup")(group.name)))
                      ]),
                      _c(
                        "ul",
                        { staticClass: "list-items" },
                        _vm._l(group.items, function(item) {
                          return _c(
                            "li",
                            {
                              staticClass: "item",
                              class: { active: item === _vm.selected_item },
                              on: {
                                click: function($event) {
                                  return _vm.doSelectItem(item)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm._f("translateItem")(item)))
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  }),
                  0
                ),
                _c(_vm.selected_item, {
                  tag: "div",
                  staticClass: "content",
                  attrs: { contact_id: _vm.cid }
                })
              ],
              1
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }