var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { attrs: { id: "publication" + _vm.pubId } }, [
    _c(
      "div",
      { staticClass: "publication" },
      [
        _c("publication-header", {
          attrs: {
            "ch-id": _vm.chId,
            "pub-id": _vm.pubId,
            publication: _vm.publication,
            channel: _vm.channel
          }
        }),
        _c("div", { staticClass: "hor-line light-line" }),
        _c("publication-body", {
          attrs: {
            "ch-id": _vm.chId,
            "pub-id": _vm.pubId,
            channel: _vm.channel
          }
        }),
        _c("div", { staticClass: "hor-line light-line" }),
        _c("publication-footer", {
          attrs: {
            "ch-id": _vm.chId,
            "pub-id": _vm.pubId,
            publication: _vm.publication,
            channel: _vm.channel
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }