var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main-header-container", {
    attrs: {
      title: _vm.$t("videomost.menu-conferences"),
      infoToggleBtn: false,
      menu: false
    },
    scopedSlots: _vm._u([
      {
        key: "btns-before",
        fn: function() {
          return [
            _c("div", { staticClass: "menu-icons" }, [
              _c(
                "div",
                {
                  staticClass: "circle-animation conference",
                  attrs: { title: _vm.$t("videomost.conf-run") },
                  on: { click: _vm.newConference }
                },
                [_c("i", { staticClass: "fal fa-video" })]
              ),
              _c(
                "div",
                {
                  staticClass: "circle-animation conference",
                  attrs: { title: _vm.$t("videomost.enter-inconference") },
                  on: { click: _vm.enterConference }
                },
                [_c("i", { staticClass: "fal fa-arrow-alt-right" })]
              ),
              _c(
                "div",
                {
                  staticClass: "circle-animation conference",
                  attrs: { title: _vm.$t("videomost.new-conference") },
                  on: { click: _vm.planNewConf }
                },
                [_c("i", { staticClass: "fal fa-calendar-alt" })]
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }