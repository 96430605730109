//
//
//
//
//
//
//
//
//
//
//

import CustomRadio from '../custom/CustomRadio.vue'
import ModalDialog from "./ModalDialog.vue"
import modal_mixin from "./modal-mixin"
import moment from 'moment'

export default {
    name: 'MuteDialog',
    components: {ModalDialog, CustomRadio},
    mixins: [modal_mixin],
    data() {
        return {
            title: this.$t('disabling-noti'),
            compModalProps: {
                delay: 200,
                pivotY: 0.05,
                width: 500,
                height: 'auto',
                adaptive: true,
                styles: "min-width: 300px;"                      
            },
            hours: 24,
            inputs: [
                {
                    value: 24,
                    label: this.$t('day')
                }, {
                    value: 168,
                    label: this.$t('week')
                }, {
                    value: 175200,
                    label: this.$t('forever')
                }
            ]
        };
    },
    methods: {
        // *** Переопределить в дочернем компоненте start *** //
        setMute(muteUntil) {},
        // *** Переопределить в дочернем компоненте end *** //
        doMute() {
            let muteUntil = moment().unix() + (this.hours * 3600)
            if (muteUntil) this.setMute(muteUntil)
            this.close()
        },
        close() {
            this.modalClose()
        },
    }
}
