//
//
//
//
//
//
//
//
//
//

import Toolbar from './Toolbar.vue'
import Dialer from '../phone/Dialer.vue'
import SideContent from "./SideContent.vue"
import InfoNotification from '../infoNotification/InfoNotification.vue'
import SearchItems from '../search/Items.vue'

import {CONTENT_MANAGER, PHONE_CNTL, LOGIN, ASISTANTS, CONTACTS, CLIENTDATA } from '../../store/modulesNames'
import {
    GET_MAIN_TYPE,
    GET_INFO_NOTIFICATION,
    GET_OPEN_DIALER,
    GET_IS_LOGINED,
    GET_ASSISTANTS,
    GET_CONTACTS_MERGED,
    GET_FONTS_SETTINGS,
} from '../../store/gettersTypes'
import { ACT_SELECT_ASSISTANT } from '../../store/actionsTypes'

export default {
    name: "Side",
    components: {
        SideContent,
        Toolbar,
        InfoNotification,
        Dialer,
        SearchItems,
    },
    data() {
        return {
            selectedAssistant: false,
        }
    },
    watch: {
        isLogined () {   
            this.callSelectAssistant()                
        },
        assistants () {
            this.callSelectAssistant()
        },
        contacts () {
            this.callSelectAssistant()                
        },
    },
    computed: {
        infoNotificationShow() {
            return this.$store.getters[`${CONTENT_MANAGER}/${GET_INFO_NOTIFICATION}`]                
        },
        getMainType() {
            return this.$store.getters[`${CONTENT_MANAGER}/${GET_MAIN_TYPE}`]
        },
        showDialer() {
            return this.$store.getters[`${PHONE_CNTL}/${GET_OPEN_DIALER}`]
        },
        isLogined () {
            return this.$store.getters[`${LOGIN}/${GET_IS_LOGINED}`]
        },
        assistants () {
            return this.$store.getters[`${ASISTANTS}/${GET_ASSISTANTS}`]
        },
        contacts () {
            return this.$store.getters[`${CONTACTS}/${GET_CONTACTS_MERGED}`]
        },
        extraStyles() {
            const style = {}
            const fontSettings = this.$store.getters[`${CLIENTDATA}/${GET_FONTS_SETTINGS}`]
            const { titleFontSizeSliderValue } = fontSettings
            if (titleFontSizeSliderValue) {
                if (titleFontSizeSliderValue > 80 )
                    Object.assign(style, {'--titleMaxHeight': '1.2em'})
                else
                    Object.assign(style, {'--titleMaxHeight': '1.3em'})
            }
            return style
        },
    },
    methods: {
        callSelectAssistant () {
            if (this.isLogined && this.assistants.length > 1 && this.contacts.length && !this.selectedAssistant) this.selectAssistant()
        },
        selectAssistant () {
            let savedAssistant = localStorage.getItem('assistant') || ''
            let savedAssistantInAssistantsList = this.assistants.some(item => item.type === savedAssistant)

            if (savedAssistantInAssistantsList)  this.$store.dispatch(`${ASISTANTS}/${ACT_SELECT_ASSISTANT}`, savedAssistant)
            else {
                if (this.assistants.length > 2) this.modalOpen({ name: 'select-assistant' })
                else if(this.assistants.length === 2) this.$store.dispatch(`${ASISTANTS}/${ACT_SELECT_ASSISTANT}`, this.assistants[1].type)
            }
            this.selectedAssistant = true
        },
    }
}


