//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalDialog from './ModalDialog.vue';
import modal_mixin from './modal-mixin';

import VueSlider from 'vue-range-slider'
import { ACT_VOLUME_NOTIFICATION, ACT_VOLUME_RING, ACT_VOLUME_RADIO } from "../../store/actionsTypes";
import { GET_NOTIFICATION_SOUND, GET_VOLUME_NOTIFICATION, GET_RING_SOUND, GET_VOLUME_RING, GET_VOLUME_RADIO } from "../../store/gettersTypes";
import { USERDATA } from "../../store/modulesNames";

const NOTIF_AUDIO = 'NotifAudio', RING_AUDIO = 'RingAudio', RADIO_AUDIO = 'RadioAudio'

export default {
    name: "SoundSettings",
    components: { ModalDialog, VueSlider },
    mixins: [modal_mixin],
    data() {
        return {
            compModalProps: {
                delay: 200,
                pivotY: 0.40,
                pivotX: 0.73,
                width: 400,
                height: 'auto'
            },
            playNotifAudio: false,
            playRingAudio: false,
            playRadioAudio: false,
            sliderDisabled: false,
            notificationSliderValue: 0,
            ringSliderValue: 0,
            radioSliderValue: 0,
            notifAudio: [NOTIF_AUDIO],
            ringAudio: [RING_AUDIO],
            radioAudio: [RADIO_AUDIO],
        }
    },
    mounted() {
        this.notificationSliderValue = this.notificationsVolume * 100
        this.ringSliderValue = this.ringVolume * 100
        this.radioSliderValue = this.radioVolume * 100
    },
    computed: {
        title() {
           return this.$t('settings.sounds')
        },
        notificationSoundSrc() {
            return this.$store.getters[`${USERDATA}/${GET_NOTIFICATION_SOUND}`] 
        },
        ringSoundSrc() {
            return this.$store.getters[`${USERDATA}/${GET_RING_SOUND}`]
        },
        radioSoundSrc() {
            return 'audio/radio_call_release.wav'
        },
        notificationsVolume() {
            return this.$store.getters[`${USERDATA}/${GET_VOLUME_NOTIFICATION}`] 
        },
        ringVolume() {
            return this.$store.getters[`${USERDATA}/${GET_VOLUME_RING}`] 
        },
        radioVolume() {
            return this.$store.getters[`${USERDATA}/${GET_VOLUME_RADIO}`] 
        }
    },
    watch: {
        notificationSliderValue(newVal, oldVal) {
            this.changeVolume(newVal, this.notifAudio)
            if (!!oldVal) this.startPlay(this.notifAudio)
        },
        ringSliderValue(newVal, oldVal) {
            this.changeVolume(newVal, this.ringAudio)
            if (!!oldVal) this.startPlay(this.ringAudio)
        },
        radioSliderValue(newVal, oldVal) {
            this.changeVolume(newVal, this.radioAudio)
            if (!!oldVal) this.startPlay(this.radioAudio)
        },
    },
    methods: {
        changeVolume(val, refName) {
            switch (refName) {
                case [NOTIF_AUDIO]:
                    this.notificationSliderValue = val
                    break
                case [RING_AUDIO]:
                    this.ringSliderValue = val
                    break
                case [RADIO_AUDIO]:
                    this.radioSliderValue = val
                    break                        
            }
            let audioElement = this.$refs[refName]
            this.$nextTick(() => {
                audioElement = this.$refs[refName]
                if (!audioElement) return
                audioElement.volume = val / 100
            })
        },
        togglePlaySound(refName) {
            const playPropName = 'play' + refName
            try {
                if(!this[playPropName]) {
                    this.startPlay(refName)
                }
                else {
                    this.stopPlay(null, refName)
                }
            } catch(e) { this.stopPlay(null, refName) }
        },
        startPlay(refName) {
            if (!refName) return
            const playPropName = 'play' + refName
            this[playPropName] = true
            const audioElement = this.$refs[refName]
            audioElement.play()
        },
        stopPlay(e, refName) {
            const audioElement = this.$refs[refName]
            if (!audioElement) return
            !audioElement.paused && audioElement.pause()
            audioElement.currentTime = 0;
            const playPropName = 'play' + refName
            this[playPropName] = false
        },
        okBtn() {
            this.$store.dispatch(`${USERDATA}/${ACT_VOLUME_NOTIFICATION}`, this.notificationSliderValue)
            this.$store.dispatch(`${USERDATA}/${ACT_VOLUME_RING}`, this.ringSliderValue)
            this.$store.dispatch(`${USERDATA}/${ACT_VOLUME_RADIO}`, this.radioSliderValue)
            this.cancelBtn()
        },
        cancelBtn() {
            this.modalClose();
        }
    },
}
