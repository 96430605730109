//
//
//
//
//
//
//
//
//

export default {
    name: 'custom-radio',
    props: {
        header: {
            type: [String, Number],
            required: false
        },
        inputs: {
            type: Array,
            required: true
        },
        default_data: {
            type: [String, Number, Boolean],
            default: null
        },
        isHorizontal: {
            type: Boolean,
            default: false,
            required: false
        },
        isDisabled: {
            type: Boolean,
            default: false,
            required: false
        },
    },
    data() {
        return { 
            value: this.default_data
        }
    },
    methods: {
        isDisabledClass(input) { 
            let retClass = this.isDisabled ? 'noMicrophone' : 'micOK'
            retClass = input.isDisabled ? 'isDisabled' : 'isEnabled'
            return retClass
        },
    },
    watch: {
        value(val) {
            this.$emit('update:value_change', val);
        },
        default_data(val) {
            this.value = val
        }
    }
}
