var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title
      }
    },
    [
      _c("template", { slot: "default" }, [
        _c(
          "div",
          { staticClass: "contacts-list-modal" },
          [
            _c("recycle-contacts-list", {
              attrs: { contactsList: _vm.contactsList },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var item = ref.item
                    var i = ref.i
                    return [
                      _c("contacts-list-item", {
                        attrs: {
                          contact: item,
                          noswitch: true,
                          noSelection: true,
                          subTitle: _vm.getSubtitle(i)
                        }
                      }),
                      _c("div", { staticClass: "hor-line" })
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.closeText },
            on: { click: _vm.modalClose }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }