//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomDropDown from "../../custom/CustomDropDown.vue"
import CustomInput from '../../custom/CustomInputText.vue'
import CustomCheckbox from '../../custom/CustomCheckboxRound.vue'
import ModalBtn from "../ModalBtn.vue"

import moment from 'moment'

export default {
    name: "RepeatListOther",
    components: { CustomDropDown, ModalBtn, CustomInput, CustomCheckbox },
    props: {
        recurrence: {
            type: Object,
            required: true,
            default: {}
        }, 
        isEdit: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },                              
    },
    data() {
        return {
            cancelChange: false,
            repeatsList: [
                { value: 0, name: this.$t('integrations.repeat-daily')},
                { value: 1, name: this.$t('integrations.repeat-weekly')},
                { value: 2, name: this.$t('integrations.repeat-weekly-same-day')},
                { value: 3, name: this.$t('integrations.repeat-monthly-same-week')},
                { value: 4, name: this.$t('integrations.repeat-yearly-same-day')},                    
                { value: 5, name: this.$t('integrations.repeat-yearly-same-week')},                    
            ],
            selectedVal: 0,
            selected: {},
            interval: 1,
            defaultInterval: 1,
            daysOfTheWeek: [],
            isMo: false, isTu: false, isWe: false, isTh: false, isFr: false, isSa: false, isSu: false,
            weekDays: {
                0: {name: this.$t('integrations.mon')},
                1: {name: this.$t('integrations.tue')},
                2: {name: this.$t('integrations.wed')},
                3: {name: this.$t('integrations.thu')},
                4: {name: this.$t('integrations.fri')},
                5: {name: this.$t('integrations.sat')},
                6: {name: this.$t('integrations.sun')},
            },
            month: '',
            selectedMonth: {},
            dayOfMonth: 1,
            selectedWeekDay: {},
            dayOfTheWeek: 1,
            dayOfTheWeekIndex: 1,
            selectedDayIndex: { value: 0, name: this.$t('integrations.day-st') },
            dayIndexNames: [
                { value: 0, name: this.$t('integrations.day-st') },
                { value: 1, name: this.$t('integrations.day-nd') },
                { value: 2, name: this.$t('integrations.day-d') },
                { value: 3, name: this.$t('integrations.day-fourth') },
                { value: 4, name: this.$t('integrations.day-last') }
            ],
            recurrenceObj: {
                type: 0,
                startDate: '',
                endDate: '',
                interval: 1,
                daysOfTheWeek: [],
                dayOfMonth: 0,
                dayOfTheWeekIndex: 0,
                dayOfTheWeek: 0,
                month: 0,
            },
            weekDaysList: [],
            monthsList: []
        }
    },
    mounted() {
        this.weekDaysList = this.getWeekDaysList()
        this.monthsList = this.getListOfMonths
        ({...this.recurrenceObj} = {...this.recurrence })
        let type = this.recurrenceObj.repeatOtherTypeVal || 0
        let selectedRepeat = this.repeatsList.find(obj => obj.value === type)
        this.selected = selectedRepeat
    },
    // computed: {
    // },
    watch: {
        recurrenceObj(newVal, oldVal) {
            this.selectedVal = newVal.repeatOtherTypeVal
            if (this.selectedVal === 1) {
                const daysOfTheWeek = newVal.hasOwnProperty('daysOfTheWeek') && newVal.daysOfTheWeek
                if (Array.isArray(daysOfTheWeek) && daysOfTheWeek.length) this.setDaysCheckboxes(daysOfTheWeek)
            }
            if (newVal && newVal.type && oldVal && oldVal.type) {
                this.$emit('changerecurrence', newVal)
            }
        },            
        selectedVal: {
            handler(val) {
                //console.log("🚀 ~ file: RepeatListOther.vue:119 ~ selectedVal ~ val:", val)
                this.recurrenceObj.repeatOtherTypeVal = val
                const startDate = moment(this.recurrenceObj.startDate) || moment()
                this.dayOfMonth = startDate.date()
                const weekDay = startDate.day()
                if (!val) {
                    let { daysOfTheWeek, dayOfMonth, dayOfTheWeekIndex, dayOfTheWeek, month, ...rest } = this.recurrenceObj
                    let tempRO = {...rest}
                    tempRO.interval = +this.interval
                    this.recurrenceObj = tempRO
                }
                if (val === 1) {
                    // console.log("🚀 ~ file: RepeatOptions.vue:173 ~ selectedVal ~ this.isEdit:", this.isEdit)
                    // console.log("🚀 ~ file: RepeatOptions.vue:175 ~ selectedVal ~ this.recurrenceObj:", this.recurrenceObj)
                    let { dayOfMonth, dayOfTheWeekIndex, dayOfTheWeek, month, ...rest } = this.recurrenceObj
                    let tempRO = {...rest}
                    tempRO.interval = this.interval
                    this.recurrenceObj = tempRO
                    if (!this.isEdit) {
                        const wdArr = []
                        wdArr.push(weekDay - 1)
                        this.setDaysCheckboxes(wdArr)
                    }                
                }
                if (val === 2) {
                    let { daysOfTheWeek, dayOfTheWeekIndex, dayOfTheWeek, month, ...rest } = this.recurrenceObj
                    let tempRO = {...rest}
                    this.recurrenceObj = tempRO                    
                }                
                if (val === 3) {
                    let { interval, daysOfTheWeek, dayOfMonth, month, ...rest } = this.recurrenceObj
                    let tempRO = {...rest}
                    this.recurrenceObj = tempRO                    
                    const weekDayName = this.weekDays[weekDay].name
                    this.selectedWeekDay = { value: weekDay, name: weekDayName }
                    this.recurrenceObj.dayOfTheWeekIndex = this.dayOfTheWeekIndex
                    this.recurrenceObj.dayOfTheWeek = this.dayOfTheWeek
                }
                if (val === 4 || val === 5) {
                    let { interval, daysOfTheWeek, dayOfTheWeekIndex, dayOfTheWeek, ...rest } = this.recurrenceObj
                    let tempRO = {...rest}
                    const m_month = startDate.month()
                    const monthNameObj = this.monthsList.find(m => m.value === m_month)
                    this.selectedMonth = monthNameObj
                    tempRO.month = monthNameObj.name
                    tempRO.dayOfMonth = this.dayOfMonth
                    this.recurrenceObj = tempRO
                }
            },
            immediate: true,
        },
        interval(val) {
            if (!val) return
            let tempRO = {...this.recurrenceObj}
            tempRO.interval = val
            this.recurrenceObj = tempRO
        },
        isMo() {
            let daysOfTheWeek= this.getArrFromCheckboxes()
            let tempRO = {...this.recurrenceObj}
            tempRO.daysOfTheWeek = daysOfTheWeek
            this.recurrenceObj = tempRO
        },
        isTu() {
            let daysOfTheWeek= this.getArrFromCheckboxes()
            let tempRO = {...this.recurrenceObj}
            tempRO.daysOfTheWeek = daysOfTheWeek
            this.recurrenceObj = tempRO
        },
        isWe() {
            let daysOfTheWeek= this.getArrFromCheckboxes()
            let tempRO = {...this.recurrenceObj}
            tempRO.daysOfTheWeek = daysOfTheWeek
            this.recurrenceObj = tempRO
        },
        isTh() {
            let daysOfTheWeek= this.getArrFromCheckboxes()
            let tempRO = {...this.recurrenceObj}
            tempRO.daysOfTheWeek = daysOfTheWeek
            this.recurrenceObj = tempRO
        },
        isFr() {
            let daysOfTheWeek= this.getArrFromCheckboxes()
            let tempRO = {...this.recurrenceObj}
            tempRO.daysOfTheWeek = daysOfTheWeek
            this.recurrenceObj = tempRO
        },
        isSa() {
            let daysOfTheWeek= this.getArrFromCheckboxes()
            let tempRO = {...this.recurrenceObj}
            tempRO.daysOfTheWeek = daysOfTheWeek
            this.recurrenceObj = tempRO
        },
        isSu() {
            let daysOfTheWeek= this.getArrFromCheckboxes()
            let tempRO = {...this.recurrenceObj}
            tempRO.daysOfTheWeek = daysOfTheWeek
            this.recurrenceObj = tempRO
        },
    },
    methods: {
        setSelectedVal(val) {
            this.selectedVal = val
        },
        setSelectedDayOfTheWeekIndex(val) {
            // console.log("🚀 ~ file: RepeatOptions.vue:169 ~ setSelectedDayOfTheWeekIndex ~ val:", val)
        },
        setSelectedDayOfWeek(val) {
        // console.log("🚀 ~ file: RepeatOptions.vue:173 ~ setSelectedDayOfWeek ~ val:", val)
        },
        setSelectedMonth(val) {
        // console.log("🚀 ~ file: RepeatOptions.vue:178 ~ setSelectedMonth ~ val:", val)
        },
        setSelectedDayIndexVal(val) {
            // console.log("🚀 ~ file: RepeatListOther.vue:175 ~ setSelectedDayIndexVal ~ val:", val)
        },
        setDaysCheckboxes(cbArr = []){
            // console.log("🚀 ~ file: RepeatOptions.vue:265 ~ setDaysCheckboxes ~ cbArr:", cbArr)
            cbArr.forEach((cbVal, i) => {
                switch (cbArr[i]) {
                    case 0:
                        this.isMo = true
                        break
                    case 1:
                        this.isTu = true
                        break
                    case 2:
                        this.isWe = true
                        break
                    case 3:
                        this.isTh = true
                        break
                    case 4:
                        this.isFr = true
                        break
                    case 5:
                        this.isSa = true
                        break
                    case 6:
                        this.isSu = true
                        break
                }
            })
        },
        getArrFromCheckboxes() {
            let resArr = []
            if (this.isMo) resArr.push(0)
            if (this.isTu) resArr.push(1)
            if (this.isWe) resArr.push(2)
            if (this.isTh) resArr.push(3)
            if (this.isFr) resArr.push(4)
            if (this.isSa) resArr.push(5)
            if (this.isSu) resArr.push(6)
            return resArr
        },
        getWeekDaysList() {
            const weekDays = Object.keys(this.weekDays).map(k => { return { value: k, name: this.weekDays[k].name } })
            return weekDays
        },
        getListOfMonths() {
            const m_months = moment && moment.months(true)
            return m_months.map((name, i) => { return { value: i, name } }) || []
        },
    },
}
