//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'SelectServerInput',
    props:  {
        default_text: {
            type: String,
            required: false,
            default: ''
        },
        items: {
            type: Array,
            required: false,
            default: () => []
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        tabindex: {
            type: [String, Number],
            required: false
        },
        autocomplete: {
            type: String,
            required: false,
            default: /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) ? 'none' : 'off'
        }
    },
    data() {
        return {
            list_open: false,
            list_full: false,
            input_text: this.default_text,
        };
    },
    computed: {
        servers() {
            if (this.list_full) {
                return this.items;
            }
            return this.items.filter(
                server => server.name.toLowerCase().indexOf(this.input_text.toLowerCase()) > -1 || (`${server.address.toLowerCase()}:${server.port}`).indexOf(this.input_text.toLowerCase()) > -1
            )
        },
    },
    methods: {
        setFilteredList(event) {
            if (!!this.input_text) {
                this.list_full = false;
                this.list_open = true;
            }
            const server = event.target.value.replace('https://').replace('http://').split(':');
            let [host, port] = server;
            port = port && port !== '80' ? port : ''
            this.$emit('update:set-server', `${host}${port ? ':' + port : ''}`);
        },
        setServer(i) {
            let host = this.items[i].address.replace('https://', '').replace('http://', '');
            let port = this.items[i].port;
            this.list_open = false;
            this.list_full = false;
            this.input_text = `${host}:${port}`.replace(':80', '');
            this.$emit('update:set-server', this.input_text);
        },
        toggleFullList() {
            this.list_full = !this.list_full;
            this.list_open = !this.list_open;
        },
    },
    watch: {
        input_text(text) {
            this.$emit('update:input_text', this.input_text);
            if (!text) this.list_open = false;
        }
    }
}
