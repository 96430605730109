var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "context-menu-base",
    _vm._b({}, "context-menu-base", _vm.menuProps, false),
    [
      _c("div", { staticClass: "reaction-select" }, [
        _c(
          "div",
          { staticClass: "handup-wrapper", on: { click: _vm.onHandup } },
          [
            _c("div", {
              staticClass: "icon",
              domProps: { innerHTML: _vm._s(_vm.handupIcon) }
            }),
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.handupText))])
          ]
        ),
        _c(
          "div",
          { staticClass: "reactions-wrapper" },
          _vm._l(_vm.reactions, function(reaction, index) {
            return _c("div", {
              key: index,
              staticClass: "reaction",
              domProps: { innerHTML: _vm._s(_vm.getReactionHtml(reaction)) },
              on: {
                click: function($event) {
                  return _vm.onReaction(index)
                }
              }
            })
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }