//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ListItem from '../custom/ListItem.vue'
import ModalBtn from '../modal/ModalBtn.vue'
import ContextMenuGroup from "../contextMenu/ContextMenuGroup.vue"
import ContextMenuBase from "../contextMenu/ContextMenuBase.vue"
import { mapGetters, mapActions } from "vuex"
import { VIDEO_CONF_NEW } from "../../store/modulesNames"
import {
    GET_VC_CONF_INFO,
    GET_VC_CONF_INVITE_TEXT,
} from "../../store/gettersTypes"

import {INFO_PROPS, MEMBER_PROPS} from "../../store/modules/video-conf-abstract"
import {
    ACT_VC_CONF_BAN_MEMBER,
    ACT_VC_CONF_KICK_OUT_MEMBER,
    ACT_VC_CONF_SEND_INVITE,
    ACT_VC_CONF_SET_MEMBER_MIC,
    ACT_VC_CONF_SET_MEMBER_MODERATOR,
    ACT_VC_CONF_SET_MEMBER_VIDEO,
} from "../../store/actionsTypes"
import videoConfMixin from "./videoConfMixin"
import Utils from '../../../utils'

export default {
    name: "videoConfMembers",
    components: {ContextMenuGroup, ContextMenuBase, ListItem, ModalBtn},
    mixins: [videoConfMixin],
    props: {
        show: {
            type: Boolean,
            required: true
        },
        isModerator: {
            type: Boolean,
            required: true,
        },
        members: {
            type: Array,
            required: true
        },
    },
    computed: {
        confInfo () {
            return this[GET_VC_CONF_INFO](...this.confIdGetterData)
        },
        membersSorted() {
            return this.members.slice().sort((a, b) => {
                if (a[MEMBER_PROPS.NAME] > b[MEMBER_PROPS.NAME]) return 1
                if (a[MEMBER_PROPS.NAME] < b[MEMBER_PROPS.NAME]) return -1
                return 0
            })
        },
        ...mapGetters(VIDEO_CONF_NEW, [
            GET_VC_CONF_INFO,
            GET_VC_CONF_INVITE_TEXT,
        ]),
    },
    methods: {
        getItemFooter (member) {
            let footer = []
            if (member[MEMBER_PROPS.BANNED]) {
                footer.push(this.$t('videomost.member.banned'))
            } else {
                if (member[MEMBER_PROPS.OWNER]) footer.push(this.$t('videomost.member.owner'))
                else if (member[MEMBER_PROPS.MODERATOR]) footer.push(this.$t('videomost.member.moderator'))
                else footer.push(this.$t('videomost.member.participant'))
            }
            if (member[MEMBER_PROPS.ME]) footer.push(this.$t('videomost.member.me'))
            return footer.join(', ')
        },
        openInviteContextMenu (event) {
            const conf = this.confInfo
            let handlers = []
            handlers.push({
                item_name: this.$t('videomost.conf-members-choose'),
                handler: () => {
                    this[ACT_VC_CONF_SEND_INVITE](this.confIdActionData)
                }
            })
            handlers.push({
                item_name: this.$t('videomost.conf-members-sendinvitemail'),
                handler: () => {
                    this[ACT_VC_CONF_SEND_INVITE]({...this.confIdActionData, inviteType: 'mail'})
                }
            })
            handlers.push({
                item_name: this.$t('videomost.conf-members-copyconflink'),
                handler: () => {
                    let utils = new Utils()
                    utils.copyTextToClipboard(null, this.getConfLink(conf))
                }
            })
            this.cmOpen(event, handlers, 'top-right')
        },
        getConfLink () {
            return this[GET_VC_CONF_INFO](...this.confIdGetterData)[INFO_PROPS.LINK]
        },
        wantToSpeak (member) {
            member[MEMBER_PROPS.WANT_SPEAK] = !member[MEMBER_PROPS.WANT_SPEAK]
        },
        close (click) {
            this.$emit('close')
        },
        ...mapActions(VIDEO_CONF_NEW, [
            ACT_VC_CONF_SEND_INVITE,
            ACT_VC_CONF_SET_MEMBER_MODERATOR,
            ACT_VC_CONF_SET_MEMBER_MIC,
            ACT_VC_CONF_SET_MEMBER_VIDEO,
            ACT_VC_CONF_BAN_MEMBER,
            ACT_VC_CONF_KICK_OUT_MEMBER,
            ACT_VC_CONF_SEND_INVITE,
        ])
    },
}
