var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade-contextmenu" } },
    [
      _vm.showDefault
        ? _c(
            "div",
            {
              staticClass: "v-context",
              class: _vm.classes,
              style: _vm.style,
              attrs: { tabindex: "-1", closeOnScroll: true },
              on: {
                click: _vm.close,
                "!contextmenu": function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "ul",
                { staticClass: "options" },
                _vm._l(_vm.handlers, function(handler, index) {
                  return _c(
                    "li",
                    {
                      class: { divider: !handler.item_name },
                      attrs: { id: "item-" + index },
                      on: {
                        click: function($event) {
                          return handler.handler(handler.data)
                        }
                      }
                    },
                    [_vm._v(_vm._s(handler.item_name))]
                  )
                }),
                0
              )
            ]
          )
        : _vm.showComponent
        ? _c(
            _vm.comp,
            _vm._b(
              {
                tag: "component",
                class: _vm.classes,
                on: { close: _vm.close }
              },
              "component",
              _vm.compProps,
              false
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }