//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import { CHAT } from "../../store/modulesNames"
import {
    GET_CHAT_EDIT_MESSAGE_ID,
    GET_CHATT_REPLY_MESSAGE_ID,
} from "../../store/gettersTypes"

import {
    ACT_CHAT_UPDATE_EDITED,
    ACT_CHAT_UPDATE_REPLY,
} from "../../store/actionsTypes"


import ReplyMessage from './ReplyMessage.vue'
import EditedMessage from './EditedMessage.vue'


export default {
    name: "ChatSelectedWrapper",
    components: { ReplyMessage, EditedMessage },
    props: {
        load_id: {
            type: Number,
            required: true,
        }
    },
    watch: {
        load_id() {
            if (!this.component) this.fireLoaded()
        },
    },
    computed: {
        id() { return this.replyId || this.editId },
        component() {
            if (this.replyId) {
                return ReplyMessage
            } else if (this.editId) {
                return EditedMessage
            }
        },
        ...mapGetters(CHAT, {
            replyId: GET_CHATT_REPLY_MESSAGE_ID,
            editId: GET_CHAT_EDIT_MESSAGE_ID,
        }),
    },
    methods: {
        clear() {
            if (this.replyId) {
                this[ACT_CHAT_UPDATE_REPLY]()
            } else if (this.editId) {
                this[ACT_CHAT_UPDATE_EDITED]()
            }
            this.fireLoaded()
        },
        fireLoaded() {
            this.$emit('loaded')
        },
        ...mapActions(CHAT, [ACT_CHAT_UPDATE_REPLY, ACT_CHAT_UPDATE_EDITED])
    },
}
