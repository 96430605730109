import { getAudioContext } from './Audio'

class AudioPlug {
    constructor () {
        this.oscNode = null
        this.gainNode = null
        this.stream = null
    }

    start() {
        let ctx = getAudioContext()
        this.oscNode = ctx.createOscillator()
        this.gainNode = ctx.createGain()
        this.oscNode.connect(this.gainNode)
        this.gainNode.connect(ctx.destination)
        this.gainNode.gain.value = 0.0
        let dst = this.oscNode.connect(this.gainNode).connect(ctx.createMediaStreamDestination())
        this.oscNode.start()
        this.stream = new MediaStream([Object.assign(dst.stream.getAudioTracks()[0])])
        return this.stream
    }

    stop() {
        if (this.stream) {
            this.gainNode.disconnect()
            this.oscNode.disconnect()
            this.stream.getTracks().forEach(track => track.stop())
        }
    }
}

export default AudioPlug