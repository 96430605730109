
import { mapGetters, mapActions } from 'vuex'
import { PHONE_CNTL } from '../../../store/modulesNames'
import { GET_IS_PHONE_MINIMIZED, GET_CALL_TALK_TIME, GET_CALL_TALK_TITLE } from '../../../store/gettersTypes'
import { ACT_TOGGLE_PHONE_MINIMIZE } from '../../../store/actionsTypes'

import { ANSWERED_CALL_STATUSES } from "../../../constants"

import ActivityLine from "./ActivityLine.vue"

export default {
    name: "CallBase",
    extends: ActivityLine,
    props: {
        call: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        id: {
            type: [Number, String],
            required: true,
        },
    },
    data: () => ({
    }),
    computed: {
        leftText () {
            if (this.call.status === ANSWERED_CALL_STATUSES.TALK) {
                return this[GET_CALL_TALK_TIME](this.type, this.id)
            } else if (this.call.status === ANSWERED_CALL_STATUSES.HOLD) {
                return this.$t('phone.hold')
            } else {
                return this.call.statusText || ''
            }
        },
        title() {
            return this[GET_CALL_TALK_TITLE](this.type,  this.id)
        },
        ...mapGetters(PHONE_CNTL, [GET_IS_PHONE_MINIMIZED, GET_CALL_TALK_TIME, GET_CALL_TALK_TITLE])
    },
    methods: {
        toggleCall() {
            this[ACT_TOGGLE_PHONE_MINIMIZE]()
        },
        endCall() {
        },
        ...mapActions(PHONE_CNTL, [ACT_TOGGLE_PHONE_MINIMIZE])
    },
}
