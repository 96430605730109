//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex'
import {CONTENT_MANAGER, CHANNELS, CHANNEL, INFO} from '../../store/modulesNames'
import {GET_MAIN_TYPE, GET_SELECTED_CHANNEL, GET_CHANNEL, IS_CHANNEL_PINNED} from '../../store/gettersTypes'
import {ACT_OPEN_CHANNEL, ACT_INFO_PUSH, ACT_SET_CHANNEL_MARKED, ACT_SET_CHANNEL_PINED} from '../../store/actionsTypes'

import IconImage from '../IconImage.vue'
import SelectChatToShare from '../modal/SelectChatToShare.vue'
import ChatItemRightBlock from './ChatItemRightBlock.vue'
import ListItemMixin from './ListItemMixin'

import {MAIN_TYPES} from '../../store/modules/content-manager'

export default {
    name: 'channel-item',
    mixins: [ListItemMixin],
    components: { IconImage, ChatItemRightBlock },
    props: ['channel', 'type', 'focused'],
    data() {
        return {
            showTitle: false,
        }
    },
    computed: {
        chId() {
            return this.channel && this.channel.chId
        },
        channelTitle() {
            return this.showTitle ? this.channel.name : ''
        },
        marked() {
            return this.$store.getters['channels/isMarked']({chId: this.chId})
        },
        isPinned() {
            return this[IS_CHANNEL_PINNED]({chId: this.chId})
        },
        isSelected() {
            return (!this.noSelection && this[GET_MAIN_TYPE] === MAIN_TYPES.CHANNEL && this.channel && this[GET_SELECTED_CHANNEL] && this.chId === this[GET_SELECTED_CHANNEL])
        },
        photo() {
            if('photo' in this.channel) return  this.channel.photo
            else if('icon' in this.channel) return app.getChannelPhotoUrl(this.channel.icon)
            else return ''
        },
        verifed() {
            if(!this.chId) return false
            else return this[GET_CHANNEL]({chId: this.chId}).verified
        },
        ...mapGetters(CONTENT_MANAGER, [GET_MAIN_TYPE]),
        ...mapGetters(CHANNELS, [GET_SELECTED_CHANNEL, GET_CHANNEL, IS_CHANNEL_PINNED])
    },
    methods: {
        watchChannelPublications(chId) {
            this.$store.dispatch('channels/watchChannelPublications', {chId})
            this[ACT_SET_CHANNEL_MARKED]({chId, marked: false})
        },
        mouseOver(e) {
            if (e.target.className.indexOf('check-trim') > -1) {
                const oW = e.target.offsetWidth, sW = e.target.scrollWidth, pW = e.target.parentElement.offsetWidth
                oW < sW || pW < oW ? this.showTitle = true : this.showTitle = false 
            }
        },
        doSelectChannel() {
            this.$store.dispatch(`${CHANNEL}/${ACT_OPEN_CHANNEL}`, this.chId)
        },
        doMute() {
            this.modalOpen({
                name: 'channel-mute',
                props: {
                    chId: this.chId
                }
            })
        },
        doUnMute() {
            this.$store.dispatch('channels/delChannelSettingsParams', {chId: this.chId, settings: ['mute']})
        },
        doMenu(event) {
            let handlers = []
            let open = this.cmOpen
            const chId = this.chId
            event.currentTarget.style.backgroundColor = 'var(--content-container-bgcolor)'
            if (!this.marked) {
                handlers.push({ item_name: this.$t('channel-comp.mark'), handler: () => { this[ACT_SET_CHANNEL_MARKED]({chId, marked: true}) } })
            } else if (!!this.channel.unwatched || this.marked) {
                handlers.push({ item_name: this.$t('channel-comp.read'), handler: () => { this.watchChannelPublications(chId) } })
            }
            if (this.isPinned) {
                handlers.push({ item_name: this.$t('channel-comp.unpin'), handler: () => { this[ACT_SET_CHANNEL_PINED]({chId, pinned: false}) } })
            } else {
                handlers.push({ item_name: this.$t('channel-comp.pin'), handler: () => { this[ACT_SET_CHANNEL_PINED]({chId, pinned: true}) } })
            }
            handlers.push({ item_name: this.$t('mainPage.share'), handler: () => {
                    this.modalOpen({
                        component: SelectChatToShare,
                        props: {
                            type: declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL,
                            params: { chId }
                        }
                    })
            }})
            if (this.$store.getters['channels/isMuted']({ chId })) {
                handlers.push({ item_name: this.$t('mainPage.unmute'), handler: this.doUnMute })
            } else {
                handlers.push({ item_name: this.$t('mainPage.mute'), handler: this.doMute })
            }
            if (Channel.prototype.isOwner(this.channel.privilege)) {
                handlers.push({ item_name: this.$t('mainPage.delete-channel'), handler: () => {
                        this.modalOpen({
                            name: 'delete-channel',
                            props: {chId}
                        })
                }})
            } else {
                handlers.push({ item_name: this.$t('unsub-channel'), handler: () => {
                        this.modalOpen({
                            name: 'leave-channel',
                            props: { chId }
                        })
                }})
            }
            open(event, handlers)
        },
        channelItemDragenter(e) {
            e.preventDefault()
            const chat = { cidType: 'channel', cid: 0 }
            this.$emit('dragtochat', chat)
        },
        channelItemDragover(e) {
            e.preventDefault()
            e.stopPropagation()
        },
        channelItemDragleave(e) {
            e.preventDefault()
            this.$emit('dragleavechat', this.chat)
        },
        ...mapActions(CHANNELS, [ACT_SET_CHANNEL_MARKED, ACT_SET_CHANNEL_PINED]),
        ...mapActions(INFO, [ACT_INFO_PUSH]),
    }
}
