
import { mapActions } from 'vuex'

import {
    CHAT,
} from "../../store/modulesNames"
import {
    ACT_CHAT_UPDATE_EDITED,
} from "../../store/actionsTypes"

import ChatSelectedMsg from "./ChatSelectedMsg.vue"

export default {
    name: 'EditedMessage',
    extends: ChatSelectedMsg,
    computed: {
        infoTitle() {
            return this.$t('editing')
        },
    },
    methods: {
        onClose() {
            this[ACT_CHAT_UPDATE_EDITED]()
        },
        ...mapActions(CHAT, [ACT_CHAT_UPDATE_EDITED])
    }
}
