var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title
      },
      on: { closed: _vm.onClosed }
    },
    [
      _c("template", { slot: "default" }, [
        _c(
          "div",
          { staticClass: "select-radio-group" },
          [
            _c("custom-search", {
              staticClass: "search-radio-group",
              attrs: { search: _vm.search, focus: true },
              on: {
                "update:search": function($event) {
                  _vm.search = $event
                }
              }
            }),
            _c("div", { staticClass: "hor-line" }),
            _vm.groups.length
              ? _c(
                  "div",
                  { staticClass: "group-list" },
                  _vm._l(_vm.groups, function(group) {
                    return _c(
                      "list-item",
                      {
                        key: group.cid,
                        attrs: {
                          title: group.name,
                          footer: _vm.getGroupFooterText(group.cid),
                          link: true,
                          chevron: false
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.connect(group.cid)
                          }
                        }
                      },
                      [
                        _c("icon-image", {
                          attrs: {
                            slot: "media",
                            src: group.photo,
                            text: group.name
                          },
                          slot: "media"
                        }),
                        _vm.isGroupActive(group.cid)
                          ? _c(
                              "div",
                              {
                                staticClass: "selected",
                                attrs: { slot: "after" },
                                slot: "after"
                              },
                              [_c("i", { staticClass: "far fa-check" })]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.cancelText },
            on: { click: _vm.modalClose }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }