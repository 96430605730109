//
//
//
//

import ContactInfo from './ContactInfo.vue';
import BotInfo from './BotInfo.vue';
import SystemChatInfo from './SystemChatInfo.vue';
import ChatInfo from './ChatInfo.vue';
import ContactDetailedInfo from './ContactDetailedInfo.vue';
import ChannelInfo from './ChannelInfo.vue';
import ChatMembers from './ChatMembersInfo.vue';
import ChannelMembers from './ChannelMembersInfo.vue'
import AcsJournal from './AcsJournal.vue';
import DetailsMsg from './DetailsMsg.vue';
import NoteInfo from './NoteInfo.vue';
import SearchInChat from './SearchChatMessages.vue'
import SearchInChannel from './SearchInChannel.vue'
import MediaSearch from './MediaSearch.vue'
import CommonChats from './CommonChats.vue';

export default {
    name: "InfoBody",
    components: {
        ContactInfo,
        BotInfo,
        SystemChatInfo,
        ChatInfo,
        ContactDetailedInfo,
        ChannelInfo,
        ChatMembers,
        ChannelMembers,
        AcsJournal,
        DetailsMsg,
        NoteInfo,
        SearchInChat,
        SearchInChannel,
        MediaSearch,
        CommonChats,
    },
    props: {
        currentInfo: {
            type: Object,
            required: true
        },
        canBack: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        type() { 
            return this.currentInfo && this.currentInfo.type 
        },
        params() { 
            return this.currentInfo && this.currentInfo.params 
        },
    }
}
