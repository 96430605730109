import EventEmitter from "./EventEmitter"

class BufferResizer extends EventEmitter {
    constructor ({outLength}) {
        super()
        this.inStack = []
        this.outLength = outLength
        // console.log("!! -> file: BufferResizer.js -> line 8 -> constructor -> outLength", outLength)
        this.readPos = 0
    }

    push(buff) {
        //console.log("!! -> file: BufferResizer.js -> line 12 -> push -> buff", buff)
        // this.outLength
        //console.log("!! -> file: BufferResizer.js -> line 14 -> push -> this.outLength", this.outLength)
        this.inStack.push(buff)
        // console.log("!! -> file: BufferResizer.js -> line 16 -> push -> buff", buff)
        while (this._haveNeededSamples()) {
            this.emit('sample', this._get())
        }
    }

    _get() {
        // console.log('!! BufferResizer read')
        let outPos = 0
        let result = new Float32Array(this.outLength)
        // console.log("!! -> file: BufferResizer.js -> line 26 -> _get -> outLength", outLength)
        while (this.inStack.length && outPos !== this.outLength) {
            let sample = this.inStack[0]
            let sampleLength = sample.length
            let needLength = this.outLength - outPos
            if ((this.readPos + needLength) < sampleLength) {
                // длина сэпла больше необходимой
                let readEnd = this.readPos + needLength
                result.set(sample.subarray(this.readPos, readEnd), outPos)
                outPos += readEnd - this.readPos
                this.readPos += readEnd - this.readPos
            } else {
                // длина сэпла меньше необходимой
                let readEnd = sampleLength
                result.set(sample.subarray(this.readPos, readEnd), outPos)
                outPos += readEnd - this.readPos
                this.readPos = 0
                this.inStack.shift()
            }
        }
        // console.log("!! -> file: BufferResizer.js -> line 49 -> _get -> result", result)
        return result
    }

    _haveNeededSamples() {
        return this.outLength <= (this.inStack.reduce((len, inSample) => len + inSample.length, 0 ) - this.readPos)
    }

    clear() {
        this.inStack = []
        this.readPos = 0
    }
}

export default BufferResizer