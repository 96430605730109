//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex';
import {USERDATA, CONTACTS, INFO} from "../../store/modulesNames";
import {GET_MERGED_CONTACT_BY_ID, GET_UID, GET_SERVER_DISPLAY_NAME} from '../../store/gettersTypes'
import {ACT_INFO_PUSH} from "../../store/actionsTypes";
import ActionsButton from "./ActionsButton.vue";

export default {
    name: "MembersInfo",
    props: ['params'],
    components: { ActionsButton },
    computed: {
        title () {
            return this.$data._title || this.$t('information.members')
        },
        btns() {
            return []
        },
        members () {
            let resMembers = (this._all_members || []).map(member => {
                let contact = this[GET_MERGED_CONTACT_BY_ID](member.cid),
                    data = {
                        photo: contact.photo,
                        name: contact.fio,
                        privilege: member.privilege,
                        cid: contact.cid,
                        objectId: contact?.objectId
                    }
                if (member.text) data.text = member.text
                return data
            })
            const author = resMembers.find(m => m.privilege === declarations.userPrivilege.OWNER)
            let filteredAdmins = resMembers.filter(m => m.privilege === declarations.userPrivilege.ADMIN)
                                .sort(({name:a}, {name:b}) => utils.textSortFn(a, b))
            let filteredUsers = resMembers.filter(m => m.privilege === declarations.userPrivilege.USER)
                                .sort(({name:a}, {name:b}) => utils.textSortFn(a, b))
            if (this.searchMembers.length) {
                let searchStr = this.searchMembers.toLowerCase()
                filteredAdmins = filteredAdmins.filter(m => m.name.toLowerCase().indexOf(searchStr) > -1)
                filteredUsers = filteredUsers.filter(m => m.name.toLowerCase().indexOf(searchStr) > -1)
            }
            if (author) resMembers = [author, ...filteredAdmins, ...filteredUsers]
            else resMembers = [...filteredAdmins, ...filteredUsers]
            return resMembers
        },
        userPrivilege() {
            return declarations.userPrivilege.USER
        },
        ...mapGetters(USERDATA, {uid: GET_UID}),
        ...mapGetters(USERDATA, [GET_SERVER_DISPLAY_NAME]),
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
    },
    methods: {
        doOpenContactInfo (cid, isGroup = false) {
            this[ACT_INFO_PUSH]({type: 'contact-info', params: {cid, isGroup}});
        },
        doContextMenu (event, cid) {
            let handlers = this._buildMenu && this._buildMenu(cid) || [];
            this.cmOpen(event, handlers);
        },
        getRemoteServerDisplayName(objectId) {
            return this[GET_SERVER_DISPLAY_NAME](objectId) || ''
        },
        ...mapActions(INFO, [ACT_INFO_PUSH])
    }
}
