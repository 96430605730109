const mixin = {
    props: {
        focus: {
            type: [Number],
            required: false,
            default: () => -1
        }
    },
    data () {
        return {
            focusOnItem: ('focus' in this) ? this.focus : -1
        }
    },
    methods: {
        onFocusOnItemChange(newFocusOnItem, oldFocusOnItem) {
            let directionDown = (newFocusOnItem - oldFocusOnItem) > 0;
            let listNode = this.$el;
            let listClientHeight = listNode.clientHeight;
            let listScrollHeight = listNode.scrollHeight;
            if (listClientHeight === listScrollHeight) return;
            let itemNode = listNode.querySelector(`li:nth-child(${newFocusOnItem + 1})`);
            if (itemNode) {
                let liNode = itemNode.closest('li');
                let topOffset = itemNode.offsetTop - this.$el.offsetTop; // Растояние от верха
                if (directionDown && (listClientHeight + listNode.scrollTop - (topOffset + itemNode.clientHeight)) < 0) {
                    liNode.scrollIntoView({block: 'end'});
                } else if (!directionDown && (listNode.scrollTop > topOffset)) {
                    liNode.scrollIntoView({block: 'start'});
                }
            }
        }
    },
    watch: {
        focus (newFocus) {
            if (newFocus >= this.items.length) return this.$emit('update:focus-on', this.items.length - 1);
            if (newFocus < -1) return this.$emit('update:focus-on', 0);
            this.focusOnItem = newFocus;
        },
        focusOnItem(...args) {
            this.onFocusOnItemChange(...args)
        }
    }
};

export default mixin;