//
//
//
//
//
//
//
//
//
//

 
import ListItemMixin from './ListItemMixin';

export default {
    name: 'NameListItem',
    mixins: [ListItemMixin],
    props: {
        contact: {
            type: String,
            required: true
        },
        marked: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            showTitle: false,
        }
    },
    methods: {
        mouseOver(e) {
            const spanText = e.target
            const parent = e.target.parentElement
            parent.clientHeight < spanText.offsetHeight ? this.showTitle = true : this.showTitle = false
        },
    }    
}
