//
//
//
//
//

export default {
    name: "ContextMenuBase",
    props: {
        top: {
            type: Number,
            required: false,
        },
        left: {
            type: Number,
            required: false,
        },
        theme: {
            type: String,
            required: false
        },
    },
    computed: {
        cssVars() {
            return {
                '--top': this.top + 'px',
                '--left': this.left + 'px',
            }
        }
    }
}
