var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bot-commands" },
    _vm._l(_vm.commands, function(command) {
      return _c(
        "div",
        {
          staticClass: "bot-command",
          on: {
            click: function($event) {
              return _vm.sendCommand(command.command)
            }
          }
        },
        [
          _c("span", { staticClass: "command" }, [
            _vm._v(_vm._s(command.command))
          ]),
          _c("span", { staticClass: "description" }, [
            _vm._v(_vm._s(command.description))
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }