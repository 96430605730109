//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';

import {mapGetters, mapActions} from 'vuex';
import {CHANNELS, MODAL, CONTACTS} from '../../store/modulesNames';
import {ACT_GET_CHANNEL_DETAILS, ACT_MODAL_OPEN} from '../../store/actionsTypes';
import {GET_CHANNEL, GET_MERGED_CONTACT_BY_ID} from '../../store/gettersTypes';
import checkMessageDataExist from "./checkMessageDataExist"

import ProgressCircle from '../ProgressCircle.vue';
import IconImage from '../IconImage.vue';
import { i18n } from '../../../ext/i18n';


export default {
    name: 'chat-channel-message',
    components: {"progress-circle": ProgressCircle, IconImage},
    props: ['message'],
    mixins: [checkMessageDataExist],
    computed: {
        id() { return this.message.id },
        msg() { return this.message.msg },
        channel() { return this[GET_CHANNEL]({chId: this.msg.chId}) },
        name() { return this.channel && this.channel.name || i18n.messages[i18n.locale].chat['unknown-channel'] },
        subscribers() { return this.channel && this.channel.subscribersCount || 0 },
        photo() { return this.channel && this.channel.icon && app.getChannelPhotoUrl(this.channel.icon) || '' },
        ...mapGetters(CHANNELS, [GET_CHANNEL]),
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
    },
    methods: {
        openChannel() {
            this.checkChanelExist(this.msg.chId)
        },
        fioById(cid) {
            return this[GET_MERGED_CONTACT_BY_ID](cid).fio || null
        },
        ...mapActions(CHANNELS, [ACT_GET_CHANNEL_DETAILS]),
        ...mapActions(MODAL, [ACT_MODAL_OPEN])
    },
    created() {
        if (!this.channel) this[ACT_GET_CHANNEL_DETAILS](this.msg.chId)
    }
}
