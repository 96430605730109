import ipc from '../../electron/ipc'

/*
let reqId = 1

const promises = {}

ipc.on('get-credential-val-resp', ({error, reqId, val}) => {
    let promise = promises[reqId]
    if (promise) {
        if (error) promise.reject(error)
        else promise.resolve(val)
    }
    delete promises[reqId]
})

ipc.on('set-credential-val-resp', ({error, reqId}) => {
    let promise = promises[reqId]
    if (promise) {
        if (error) promise.reject(error)
        else promise.resolve()
    }
    delete promises[reqId]
})

export const getCredential = (key) => new Promise((resolve, reject) => {
    promises[reqId++] = {resolve, reject}
    ipc.send('get-credential-val', { key, reqId })
})

export const setCredential = (key, val) => new Promise((resolve, reject) => {
    promises[reqId++] = {resolve, reject}
    ipc.send('set-credential-val', { key, val, reqId })
})*/

export const getCredential = (key) => {
    // #if process.env.WEBPACK_BUILD_TARGET === 'web'
    return null
    // #endif
    let resp = ipc.sendSync('get-credential-val', { key })
    return resp && !resp.error && resp.val || null
}

export const setCredential = (key, val) => {
    // #if process.env.WEBPACK_BUILD_TARGET === 'web'
    return null
    // #endif
    let resp = ipc.sendSync('set-credential-val', { key, val })
    return resp && !resp.error
}