//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex"
import MainContentContainer from "../main/body/MainContentContainer.vue";
import ListItem from "../custom/ListItem.vue";
import SelectAssistant from '../modal/SelectAssistant.vue'
import MediaSettings from '../modal/MediaSettings.vue';
import {
    ASISTANTS,
    USERDATA,
} from '../../store/modulesNames';
import {
    GET_TYPE_ASISTANT,
    GET_ASSISTANTS,
    GET_NUMBER_ASSISTANT,
} from '../../store/gettersTypes';
import {
    ACT_CHECK_MEDIA_DEVICES,
} from '../../store/actionsTypes'

export default {
    name: 'assistant-settings',
    components: {MainContentContainer, ListItem, SelectAssistant},
    computed: {
        assistant() {
            let assTest = this.$store.getters[`${ASISTANTS}/${GET_TYPE_ASISTANT}`]
            if (!assTest && !!localStorage.getItem('assistant')) return localStorage.getItem('assistant')
            return this.$store.getters[`${ASISTANTS}/${GET_TYPE_ASISTANT}`]
        },
        assistants() {
            return this.$store.getters[`${ASISTANTS}/${GET_ASSISTANTS}`]
        }
    },
    methods: {
        openAssistantModal () {
            this.modalOpen({
                component: SelectAssistant
            })
        },
        async openMediaDevicesModal () {
            try {
                await this[ACT_CHECK_MEDIA_DEVICES]({ audio: true, video: true, audioAlert: false, videoAlert: false })
            } catch (e) {}
            this.modalOpen({
                component: MediaSettings
            })
        },
        getNumber () {
            let number = this.$store.getters[`${ASISTANTS}/${GET_NUMBER_ASSISTANT}`](this.assistant)
            return {'number': number ? `(${number})` : number}
        },
        ...mapActions(USERDATA, [ACT_CHECK_MEDIA_DEVICES])
    }
}
