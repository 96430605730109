//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';
import {ACS} from "../../store/modulesNames";
import {GET_ACS_INFO} from '../../store/gettersTypes';
import { i18n } from '../../../ext/i18n'
import moment from 'moment'

export default {
    name: "acs-entrances",
    props: {
        contact_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        entrances() {
            let {locations} = this[GET_ACS_INFO](this.contact_id)
            return locations
        },
        ...mapGetters(ACS, {[GET_ACS_INFO]: GET_ACS_INFO})
    },
    filters: {
        entranceStatusToText(val) {
            let locale = i18n.messages[i18n.locale]
            return `${val ? locale.acs.enter : locale.acs.leave}`
        }
    }

}
