export default {
    emitters: {
        radioConnect(params) {
            return new Promise((resolve, reject) => {
                this.socket.emit('radio-connect', params, result => {
                    if (result && result.hasOwnProperty('error')) {
                        this.log("!! radioprotocol.js radio-connect error:", result.error)
                        reject(result.error)
                    }
                    resolve()
                })
            })
        },
        radioGetStatus(params) {
            return new Promise((resolve, reject) => {
                try {
                    this.socket.emit('radio-get-status', params, (result) => {
                        resolve(result)
                    })
                } catch (e) {
                    this.log("!! radioprotocol.js radioGetStatus error: ", e)
                    reject(e)
                }
            })
        },
        radioDisconnect(params = {}) {
            return new Promise((resolve, reject) => {
                try {
                    this.socket.emit('radio-disconnect', params, (result) => {
                        if (result && result.error) {
                            this.log(`radio-disconnect error: < ${result.error}`)
                            return reject(result.error)
                        }
                        resolve()
                    })
                } catch (e) {
                    reject(e)
                }
            })
        },
        radioRequestVoice(params) {
            return new Promise((resolve, reject) => {
                //this.log('radio-request-voice: > ' + JSON.stringify(params))
                this.socket.emit('radio-request-voice', params, (result) => {
                    if (result && result.error) {
                        this.log(`radio-request-voice: < failed ${result.error}`)
                        return reject(result.error)
                    }
                    resolve()
                })
            })
        },
        radioStopVoice(params) {
            return new Promise((resolve, reject) => {
                //this.log('radio-stop-voice: > ' + JSON.stringify(params))
                this.socket.emit('radio-stop-voice', params, (result) => {
                    if (result && result.error) {
                        this.log(`radio-stop-voice: < failed ${result.error}`)
                        return reject(result.error)
                    }
                    resolve()
                })
            })
        },
        radioSendVoice(params) {
            //this.log('radio-send-voice: > ' + JSON.stringify(params))
            this.socket.emit('radio-send-voice', params, (result) => {
                if (result && result.error) {
                    this.log(`radio-send-voice: < failed ${result.error}`)
                }
            })
        },
    },
    listeners: {
        'radio-status-event'(data) {
            this.log('bind: < radio-status-event: ' + JSON.stringify(data))
            this.emit('radio-status-event', data)
        },

        'radio-leave-event'(data) {
            this.log('bind: < radio-leave-event: ' + JSON.stringify(data))
            this.emit('radio-leave-event', data)
        },

        'radio-grant-voice-event'(data) {
            this.log('bind: < radio-grant-voice-event: ' + JSON.stringify(data))
            this.emit('radio-grant-voice-event', data)
        },

        'radio-stop-voice-event'(data) {
            // this.log('bind: < radio-stop-voice-event: ' + JSON.stringify(data))
            this.emit('radio-stop-voice-event', data)
        },

        'radio-voice-event'(data) {
            // this.log('bind: < radio-voice-event: ' + JSON.stringify(data))
            this.emit('radio-voice-event', data)
        },

        'radio-disconnected-event'(data) {
            this.log('bind: < radio-disconnected-event: ' + JSON.stringify(data))
            this.emit('radio-disconnected-event', data)
        },

        'radio-message-event'(data) {
            this.log('bind: < radio-message-event: ' + JSON.stringify(data))
            this.emit('radio-message-event', data)
        },
    },
}