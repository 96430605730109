//
//
//
//
//
//
//
//
//
//




export default {
    name: "CustomTabs",
    props:  {
        tabItems: {
            type: Array,
            required: true,
            default: () => []
        },
        defaultTabValue: {
            type: Number,
            required: false,
            default: 0
        },
        railBottomBorder: {
            type: Boolean, 
            default: false
        },
        isInfo: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            chosenTab: this.defaultTabValue,
            tabsWrapper: null,
            resizeObserver: null,
            tabsWrapperWidth: 0,
        }
    },
    mounted() {
        this.resizeObserver = new ResizeObserver((entries) => { 
            const entry = entries[0]
            this.tabsWrapperWidth = entry.contentRect.width
        })
        let elementWrapper
        if (this.isInfo) elementWrapper = document.getElementById('info-wrapper')
        else elementWrapper = document.getElementById('tabsWrapper')
        this.tabsWrapper = this.$refs.tabsWrapper
        this.resizeObserver.observe(elementWrapper)
    },
    beforeDestroy() {
        let elementWrapper
        if (this.isInfo) elementWrapper = document.getElementById('info-wrapper')
        else elementWrapper = document.getElementById('tabsWrapper')
        this.resizeObserver.unobserve(elementWrapper)
    },
    computed: {
        tabsCss() {
            const wrapperWidth = this.tabsWrapperWidth
            if (!wrapperWidth) return
            const tabsCss = {}
            const tabWidth = (wrapperWidth / this.tabItems.length) + "px"
            Object.assign(tabsCss, {
                "--tab-width": tabWidth,
            })

            if (this.railBottomBorder) {
                Object.assign(tabsCss, {
                    "--tabs-border": "none",
                    "--tabs-border-radius": "0px"
                })
            } else {
                Object.assign(tabsCss, {
                    "--tabs-border": "1px solid",
                    "--tabs-border-radius": "46px"
                })
            }
            return tabsCss
        },
        railCss() {
            const wrapperWidth = this.tabsWrapperWidth
            if (!wrapperWidth) return
            const railCss = {}
            const tabWidth = (wrapperWidth / this.tabItems.length)
            Object.assign(railCss, {
                "--rail-width": tabWidth + "px",
            })
            let tabsItems = this.tabItems
            for (let index = 0; index < tabsItems.length; index++) {
                let marginName = '--marginLeft' + index
                let marginShift = index * tabWidth
                if (index === 1) marginShift += 1
                let marginShiftPx = marginShift + 'px'
                Object.assign(railCss, { [marginName]: marginShiftPx, "--tab-width": tabWidth + "px" })
            }
            return railCss
        },
    },
    methods: {
        tabClick(tab) {
            this.chosenTab = tab.key
            this.$emit('tabClick', tab.key)
        },
        tabName(key) {
            return this.tabItems.find(t => t.key === key).value
        },
    },
}
