var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "context-menu-group" },
    [
      _vm.header
        ? _c(
            "div",
            {
              staticClass: "header",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [_vm._v(_vm._s(_vm.header))]
          )
        : _vm._e(),
      _vm._t("default", null, { select: _vm.select })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }