var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "right-block" }, [
    _c("div", { staticClass: "time" }, [
      _c("span", [_vm._v(_vm._s(_vm.item.time))])
    ]),
    _c(
      "div",
      {
        staticClass: "unwatched-wrapper",
        class: _vm.item.muted ? "muted" : ""
      },
      [
        _vm.item.unwatched || _vm.item.marked
          ? _c(
              "div",
              {
                staticClass: "unwatched",
                class: { shrink: _vm.item.marked && !_vm.item.unwatched }
              },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.item.unwatched,
                        expression: "item.unwatched"
                      }
                    ]
                  },
                  [_vm._v(_vm._s(_vm._f("countLimit")(_vm.item.unwatched)))]
                ),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.item.marked && !_vm.item.unwatched,
                      expression: "item.marked && !item.unwatched"
                    }
                  ],
                  staticClass: "chat-marked fas fa-asterisk"
                })
              ]
            )
          : _vm.item.muted
          ? _c("i", { staticClass: "mute-icon fal fa-volume-slash" })
          : _vm.item.pinned
          ? _c("i", { staticClass: "pin-icon fal fa-thumbtack" })
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }