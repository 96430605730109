var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", class: { activeWrap: _vm.calls.length > 2 } },
    _vm._l(_vm.calls, function(call, index) {
      return _c(
        "div",
        { key: index },
        [
          _c("incomming-call", {
            attrs: { id: call.id, type: call.type, isRinging: !index }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }