//
//
//
//
//
//
//
//
//
//
//

'use strict';

import {mapGetters, mapActions} from 'vuex';
import {GET_CHAT_ATTACHED_MESSAGE_ID, GET_SELECTED_CHAT, IS_CHAT_ADMIN} from "../../store/gettersTypes";
import {CHAT, CHATS} from "../../store/modulesNames";
import {ACT_CHANGE_CHAT, ACT_CHAT_GET_MESSAGE_BY_ID} from "../../store/actionsTypes"

import ScrollToMessageMixin from "./ScrollToMessageMixin"

import MessageText from "./MessageText.vue"

export default {
    name: 'attach-message',
    components: { MessageText },
    mixins: [ScrollToMessageMixin],
    props: {
        load_id: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            message: null,
            loaded: false,
        };
    },
    watch: {
        id() {
            this.$nextTick(() => {
                if (this.loaded) this.loadMessage()
            })
        },
        chat() {
            this.loaded = false
        },
        load_id() {
            this.loaded = false
            this.loadMessage()
        },
    },
    computed: {
        chat() {
            return this[GET_SELECTED_CHAT] || {}
        },
        cid() {
            return this.chat.cid
        },
        cidType() {
            return this.chat.cidType
        },
        isAdmin() {
          return this[IS_CHAT_ADMIN](this.chat)
        },
        id() {
            return this[GET_CHAT_ATTACHED_MESSAGE_ID](this.chat)
        },
        ...mapGetters(CHATS, [GET_SELECTED_CHAT, IS_CHAT_ADMIN, GET_CHAT_ATTACHED_MESSAGE_ID]),
    },
    methods: {
        close(e) {
            e.stopPropagation()
            this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('unpin'),
                    btnOk: {
                        cb: () => {
                            let {cid, cidType} = this
                            this[ACT_CHANGE_CHAT]({cid, cidType, pinMessage: 0})
                            let el = document.getElementById('chat')
                            el.style.paddingTop = ''
                        }
                    },
                }
            })
        },
        async loadMessage() {
            const id = this.id
            let message = id && await this[ACT_CHAT_GET_MESSAGE_BY_ID]({id})
            if (message) this.message = message
            else this.message = null
            this.$nextTick(() => this.fireLoaded())
        },
        fireLoaded() {
            this.loaded = true
            this.$emit('loaded')
        },
        ...mapActions(CHAT, [ACT_CHAT_GET_MESSAGE_BY_ID]),
        ...mapActions(CHATS, [ACT_CHANGE_CHAT]),
    }
}
