var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title
      }
    },
    [
      _c("template", { slot: "default" }, [
        _c(
          "div",
          { staticClass: "poll" },
          [
            _c("custom-textarea", {
              staticClass: "custom-textarea poll-input-title",
              class: { focus: _vm.focus },
              attrs: {
                custom_placeholder: _vm.$t("modal.question"),
                default_text: _vm.topic,
                text: _vm.topic,
                focus: _vm.focus
              },
              on: {
                "update:text": function($event) {
                  _vm.topic = $event
                },
                "update:focus": function($event) {
                  _vm.focus = $event
                }
              }
            }),
            _vm._l(_vm.maxAnswers, function(i) {
              return _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.answers[i - 1],
                    expression: "answers[i - 1]",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "poll-input-question",
                attrs: { placeholder: _vm.$t("modal.answer-var") },
                domProps: { value: _vm.answers[i - 1] },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.answers, i - 1, $event.target.value.trim())
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              })
            })
          ],
          2
        )
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass: "btn poll-button-cancel",
            on: { click: _vm.cancelPoll }
          },
          [_vm._v(_vm._s(_vm.$t("cancel")))]
        ),
        _c(
          "div",
          { staticClass: "btn poll-button-add", on: { click: _vm.addPoll } },
          [_vm._v(_vm._s(_vm.okBtn))]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }