export default {
    emitters: {
        /** schedule-credentials-check
         * @param type {String}  // 'exchange'
         * @param credentials {Object} // { login, password }
         */
        scheduleCredentialsCheck (data) {
            return new Promise((resolve, reject) => {
                this.log('schedule-credentials-check: >', data)
                this.socket.emit('schedule-credentials-check', data, (response = {}) => {
                    let isError = response && response.hasOwnProperty('error')
                    if (isError) {
                        const error = response.error
                        return reject(error)
                    }
                    this.log('schedule-credentials-check: < ', response)
                    resolve(true)
                })
            })
        },

        /** schedule-delete-credentials
         * @param type {String}  // 'exchange'
         */
        scheduleDeleteCredentials (data) {
            return new Promise((resolve, reject) => {
                this.log('schedule-delete-credentials: >', data)
                this.socket.emit('schedule-delete-credentials', data, (response = {}) => {
                    let isError = response && response.hasOwnProperty('error')
                    if (isError) {
                        const error = response.error
                        return reject(error)
                    }
                    this.log('schedule-delete-credentials: < ', response)
                    resolve(true)
                })
            })
        },

        /** schedule-update-credentials
         * @param type {String}  // 'exchange'
         * @param credentials {Object} // { login, password }
         */
        scheduleUpdateCredentials (data) {
            return new Promise((resolve, reject) => {
                this.log('schedule-update-credentials: >', data)
                this.socket.emit('schedule-update-credentials', data, (response = {}) => {
                    let isError = response && response.hasOwnProperty('error')
                    if (isError) {
                        const error = response.error
                        return reject(error)
                    }
                    this.log('schedule-update-credentials: < ', response)
                    resolve(true)
                })
            })
        },

        /** schedule-sync-appointments
         * @param type {String}  // 'exchange'
         */
        scheduleSyncAppointments (data) {
            return new Promise((resolve, reject) => {
                this.log('schedule-sync-appointments: >', data)
                this.socket.emit('schedule-sync-appointments', data, (response = {}) => {
                    let isError = response && response.hasOwnProperty('error')
                    if (isError) {
                        const error = response.error
                        return reject(error)
                    }
                    this.log('schedule-sync-appointments: < ', response)
                    resolve(response)
                })
            })
        },

        /** schedule-add-appointment
         * @param type {String}  // 'exchange'
         * @param appointment {Object} // {
         *  id	int	Идентификатор встречи
            type	scheduleType	Тип расписания
            subject	string	Тема встречи
            startTime	int	Время начала встречи
            endTime	int	Время окончания встречи
            allDayEvent	bool	Встреча на весь день
            isCancelled	bool	Встреча была отменена
            myResponseType	  Что я ответил на запрос   // ????
            organizer	string	Почта организатора встречи
            location	string	Место проведения встречи
            isPrivate	bool	Частная встреча
            attendees	scheduleAttendee	Участники встречи
            recurrence	recurrence	Сценарий повторений
         * }
         */
        scheduleAddAppointment (data) {
            return new Promise((resolve, reject) => {
                this.log('schedule-add-appointment: >', data)
                this.socket.emit('schedule-add-appointment', data, (response = {}) => {
                    let isError = response && response.hasOwnProperty('error')
                    if (isError) {
                        const error = response.error
                        return reject(error)
                    }
                    this.log('schedule-add-appointment: < ', response)
                    resolve(response)
                })
            })
        },

        /** schedule-edit-appointment
         * @param type {String}  // 'exchange'
         * @param appointment {Object} // {
         *  id	int	Идентификатор встречи
            type	scheduleType	Тип расписания
            subject	string	Тема встречи
            startTime	int	Время начала встречи
            endTime	int	Время окончания встречи
            allDayEvent	bool	Встреча на весь день
            isCancelled	bool	Встреча была отменена
            myResponseType	  Что я ответил на запрос   // ????
            organizer	string	Почта организатора встречи
            location	string	Место проведения встречи
            isPrivate	bool	Частная встреча
            attendees	scheduleAttendee	Участники встречи
            recurrence	recurrence	Сценарий повторений
         * }
         */
        scheduleEditAppointment (data) {
            return new Promise((resolve, reject) => {
                this.log('schedule-edit-appointment: >', data)
                this.socket.emit('schedule-edit-appointment', data, (response = {}) => {
                    let {error} = response
                    if (error) {
                        return reject(error)
                    }
                    this.log('schedule-edit-appointment: < ', response)
                    resolve(response)
                })
            })
        },

        /** schedule-delete-appointment
         * @param type {String}  // 'exchange'
         * @param id {int} // appointment id
         */
        scheduleDeleteAppointment (data) {
            return new Promise((resolve, reject) => {
                this.log('schedule-delete-appointment: >', data)
                this.socket.emit('schedule-delete-appointment', data, (response = {}) => {
                    let {error} = response
                    if (error) {
                        return reject(error)
                    }
                    this.log('schedule-delete-appointment: < ', response)
                    resolve(response)
                })
            })
        },


        /** schedule-get-appointment
         * @param type {String}  // 'exchange'
         * @param id {int} // appointment id
         */
        scheduleGetAppointment (data) {
            return new Promise((resolve, reject) => {
                this.log('schedule-get-appointment: >', data)
                this.socket.emit('schedule-get-appointment', data, (response = {}) => {
                    let isError = response && response.hasOwnProperty('error')
                    if (isError) {
                        const error = response.error
                        return reject(error)
                    }
                    this.log('schedule-get-appointment: < ', response)
                    resolve(response)
                })
            })
        },

        /** schedule-get-calendar-appointments
         * @param type {String}  // 'exchange'
         * @param startDate {date} //
         * @param endDate {date} //
         */
        scheduleGetCalendarAppointments (data) {
            return new Promise((resolve, reject) => {
                // this.log('schedule-get-calendar-appointments: >', data)
                this.socket.emit('schedule-get-calendar-appointments', data, (response = {}) => {
                    let {error} = response
                    if (error) {
                        this.log('schedule-get-calendar-appointments error: ', error)
                        return reject(error)
                    }
                    // this.log('schedule-get-calendar-appointments: < ', response)
                    resolve(response)
                })
            })
        },

        /** schedule-response-to-invitation
         * @param type {String}  // 'exchange'
         * @param id {int} // appointment id
         * @param response {String} // invitationResponseType: accept, accept-tentatively, decline
         */
        scheduleResponseToInvitation (data) {
            return new Promise((resolve, reject) => {
                this.log('schedule-response-to-invitation: >', data)
                this.socket.emit('schedule-response-to-invitation', data, (response = {}) => {
                    let isError = response && response.hasOwnProperty('error')
                    if (isError) {
                        const error = response.error
                        return reject(error)
                    }
                    this.log('schedule-response-to-invitation: < ', response)
                    resolve(response)
                })
            })
        }
    }
}