//
//
//
//
//
//

export default {
    name: 'progress-circle',
    props: ['radius', 'load_progress', 'width', 'height'],
    computed: {
        style() {
            let circumference = 2 * Math.PI * this.radius;
            return {
                strokeDasharray: circumference,
                strokeDashoffset: (circumference) * ((100 - this.load_progress) / 100)
            };
        }
    }
}
