//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import ModalDialog from '../ModalDialog.vue'
import modal_mixin from '../../modal/modal-mixin'
import CustomInput from '../../custom/CustomInputText.vue'
import DateRangePicker from '../../settings/DateRangePicker.vue'
import CustomDiv from '../../custom/CustomDiv.vue'
import CustomCheckbox from '../../custom/CustomCheckboxRound.vue'
import CustomDropDown from '../../custom/CustomDropDown.vue'
import ListItem from '../../custom/ListItem.vue'
import ModalBtn from '../../modal/ModalBtn.vue'
import SelectContactsToScheduler from '../SelectContactsToScheduler.vue'
import RepeatOptions from './RepeatOptions.vue'
import DeleteEvent from './DeleteEvent.vue'

import { INTEGRATIONS } from '../../../store/modulesNames'
import { 
    ACT_SCHEDULE_SYNC_APPOINTMENTS,
    ACT_SCHEDULE_ADD_APPOINTMENT,
    ACT_SCHEDULE_EDIT_APPOINTMENT,
    ACT_SCHEDULE_DELETE_APPOINTMENT,
} from '../../../store/actionsTypes'

import { mapActions } from '../../../../vuex'

import { INTEGRATION_NAMES } from '../../../constants'

const appointmentType = INTEGRATION_NAMES.MS_EXCHANGE

const TYPES = { 
    EDIT: 'edit', 
    CREATE: 'create' 
}
const defaultMinutes = 30

export default {
    name: "MeetingScheduler",
    components: { ModalDialog, ModalBtn, DateRangePicker, 
        ListItem, CustomInput, CustomCheckbox, CustomDropDown, CustomDiv, 
        SelectContactsToScheduler, RepeatOptions, DeleteEvent,
    },
    mixins: [modal_mixin],
    data() {
        return {
            compModalProps: {
                delay: 100,
                width: 500,
                height: 'auto',
                clickToClose: false,
                adaptive: true,
                styles: "min-width: 300px;"                
            },
            eventName: '',
            eventLocation: '',
            from: 0,
            defaultFrom: '',
            to: 0,
            defaultTo: '',
            repeat: false,
            note: '',
            attendees: [],
            mailsStr: '',
            selectedRepeat: {},
            repeatVal: -1,
            repeatOtherTypeVal: 0,
            repeatIntervalVal: 1,
            repeatList: [
                { value: 0, name: this.$t('integrations.never') },
                { value: 1, name: this.$t('integrations.every-day') },
                { value: 2, name: this.$t('integrations.every-week') },
                { value: 3, name: this.$t('integrations.every-week-workdays') },
                { value: 4, name: this.$t('integrations.every-month') },
                { value: 5, name: this.$t('integrations.every-first-week') },
                { value: 6, name: this.$t('integrations.every-year') },
                { value: 7, name: this.$t('integrations.other') },
            ],
            repeatFrom: 0,
            defaultRepeatFrom: '',
            repeatTo: 0,
            defaultRepeatTo: '',
            notifyBeforeStart: -1,
            selectedReminder: {},
            reminderNumVal: [
                { number: 0, value: 1 },
                { number: 5, value: 2 },
                { number: 10, value: 3 },
                { number: 15, value: 4 },
                { number: 30, value: 5 },
                { number: 60, value: 6 },
                { number: 120, value: 7 },
                { number: 180, value: 8 },
                { number: 240, value: 9 },
                { number: 480, value: 10 },
                { number: 720, value: 11 },
                { number: 1440, value: 12 },
                { number: 2880, value: 13 },
                { number: 4320, value: 14 },
                { number: 10080, value: 15 },
                { number: 20160, value: 16 },
            ],
            reminderList: [
                { value: 0, name: this.$t('integrations.reminder-no') },
                { value: 1, name: this.$t('integrations.reminder-0m') },
                { value: 2, name: this.$t('integrations.reminder-5m') },
                { value: 3, name: this.$t('integrations.reminder-10m') },
                { value: 4, name: this.$t('integrations.reminder-15m') },
                { value: 5, name: this.$t('integrations.reminder-30m') },
                { value: 6, name: this.$t('integrations.reminder-1h') },
                { value: 7, name: this.$t('integrations.reminder-2h') },
                { value: 8, name: this.$t('integrations.reminder-3h') },
                { value: 9, name: this.$t('integrations.reminder-4h') },
                { value: 10, name: this.$t('integrations.reminder-8h') },
                { value: 11, name: this.$t('integrations.reminder-12h') },
                { value: 12, name: this.$t('integrations.reminder-1d') },
                { value: 13, name: this.$t('integrations.reminder-2d') },
                { value: 14, name: this.$t('integrations.reminder-3d') },
                { value: 15, name: this.$t('integrations.reminder-1w') },
                { value: 16, name: this.$t('integrations.reminder-2w') },
            ],
            recurrence: {},
            isRecurrenceUpdatedOther: false,
            isAllDay: false,
            isPrivate: false,
            isRecurring: false,
            eventNote: '',
            updatedEventNote: '',
            showRepeatOptions: false,
            showRepeatOptionsExt: false
        }
    },
    mounted() {
        // console.log("🚀 ~ file: MeetingScheduler.vue:107 ~ mounted ~ this.type:", this.type)
        // console.log("🚀 ~ file: MeetingScheduler.vue:140 ~ mounted ~ this.event:", this.event)
        if (this.type === TYPES.EDIT) {
            this.from = this.event.startTime
            this.defaultFrom = moment.unix(this.from).format('YYYY-MM-DD HH:mm') // this.getEventStartTime
            this.to = this.event.endTime
            this.defaultTo = moment.unix(this.to).format('YYYY-MM-DD HH:mm') // this.getEventEndTime
            this.isAllDay = this.getIsAllDay
            this.eventNote = this.getParcedBody(this.event.body)
            this.mailsStr = ''
            if (this.event && this.event.attendees) this.mailsStr = this.event.attendees.map(at => at.address).join(', ')
            const notifyBeforeStart = this.event.hasOwnProperty('notifyBeforeStart') && this.event.notifyBeforeStart
            const reminderNumValObj = this.reminderNumVal.find(nv => nv.number === notifyBeforeStart)
            if (reminderNumValObj) {
                const reminderListObj = this.reminderList.find(vn => vn.value === reminderNumValObj.value)
                this.selectedReminder = reminderListObj
            } else this.selectedReminder = this.reminderList[0]
        } else {
            const currentHour = new Date().getHours()
            const currentMinute = new Date().getMinutes()
            const roundedMinutes = currentMinute < defaultMinutes ? 0 : defaultMinutes
            const dateFrom = moment(this.day.date)
            dateFrom.hour(currentHour)
            dateFrom.minute(roundedMinutes) 
            this.from = dateFrom.add(defaultMinutes, 'minutes').unix()
            this.defaultFrom = moment.unix(this.from).format('YYYY-MM-DD HH:mm')
            this.to = dateFrom.add(30, 'minutes').unix()
            this.defaultTo = moment.unix(this.to).format('YYYY-MM-DD HH:mm')
            this.selectedReminder = this.reminderList[0]
        }
        this.recurrence = this.event && this.event.recurrence || {}
        // console.log("🚀 ~ file: MeetingScheduler.vue:209 ~ mounted ~ this.recurrence:", this.recurrence)
        if (this.recurrence.hasOwnProperty('type')) this.recurrence.repeatOtherTypeVal = this.recurrence.type
        // console.log("🚀 ~ file: MeetingScheduler.vue:209 ~ mounted ~ this.recurrence:", this.recurrence)
        this.repeatIntervalVal = this.recurrence.interval
        this.eventName = this.getEventName
        this.eventLocation = this.getEventLocation
        this.eventNote = this.getEventNote
    },
    watch: {
        mailsStr(val) {
            setTimeout(() => {
                this.attendees = val.trim().split(/,| |, /).filter(mail => mail.length)
            }, 200)
        },
        recurrence(val, oldVal) {
            // console.log("🚀 ~ file: MeetingScheduler.vue:225 ~ recurrence ~ val:", val)
            if (this.isRecurrenceUpdatedOther) return
            let type = val.type + 1 || 0
            if (Object.keys(val).length > 5) type = 7
            this.selectedRepeat = this.repeatList.find(obj => obj.value === type)
            let startDate = val.startDate, endDate = val.endDate
            if (type) {
                this.isRecurring = true
                this.repeatFrom = moment(startDate).unix()
                this.repeatTo = moment(endDate).unix()
                this.defaultRepeatFrom = moment.unix(this.repeatFrom).format('YYYY-MM-DD')
                this.defaultRepeatTo = moment.unix(this.repeatTo).format('YYYY-MM-DD')
                if (type < 7) this.showRepeatOptions = true
                else {
                    if (!this.isEdit) this.recurrence = this.formRecurrence(this.recurrence)
                    this.showRepeatOptionsExt = true
                }
            }
        },
        repeatFrom(val) {
            this.recurrence.startDate = moment.unix(val).format('YYYY-MM-DD')
        },
        repeatTo(val) {
            this.recurrence.endDate = moment.unix(val).format('YYYY-MM-DD')
        },
    },
    computed: {
        day() {
            return this.props.day
        },
        todaysDate() {
            return moment().format('YYYY-MM-DD')
        },
        event() {
            return this.props.event
        },
        id() {
            return this.event.id
        },
        type() {
            return this.props.type
        },
        isEdit() {
            return this.type === TYPES.EDIT
        },
        isOwner() {
            if (this.type === TYPES.CREATE) return true
            if (this.event.hasOwnProperty('isOwner')) return this.event.isOwner
            return true
        },
        getEventName() {
            return this.event && this.event.subject ? this.event.subject : ''
        },
        getEventLocation() {
            return this.event && this.event.location ? this.event.location : ''
        },
        getEventStartTime() {
            let retVal = this.event.date + ' ' + this.event.startTime
            return retVal
        },
        getEventEndTime() {
            let retVal = this.event.date + ' ' + this.event.endTime
            return retVal
        },
        getIsAllDay() {
            return !!this.event.allDay
        },
        getEventNote() {
            return this.event && this.event.body ? this.event.body : ''
        },                
        startTimeUtc() {
            return !!this.from ? moment.unix(this.from).utc().format("YYYY-MM-DD HH:mm") : ''
        },
        finishTimeUtc() {
            return !!this.to ? moment.unix(this.to).utc().format("YYYY-MM-DD HH:mm") : ''
        },
        startTimeLocal() {
            return !!this.from ? moment.unix(this.from).local().format("YYYY-MM-DD HH:mm") : ''
        },
        finishTimeLocal() {
            return !!this.to ? moment.unix(this.to).local().format("YYYY-MM-DD HH:mm") : ''
        },
        attendeesStr() {
            const alen = this.attendees.length
            if (alen === 1) return this.$t('integrations.address-nom')
            if (alen > 1 && alen < 5) return this.$t('integrations.address-gen')
            else return this.$t('integrations.address-pl')
        }
    },
    methods: {
        ...mapActions(INTEGRATIONS, [    
            ACT_SCHEDULE_SYNC_APPOINTMENTS,
            ACT_SCHEDULE_ADD_APPOINTMENT,
            ACT_SCHEDULE_EDIT_APPOINTMENT,
            ACT_SCHEDULE_DELETE_APPOINTMENT,
        ]),
        setUpdatedEventNote(val) {
            this.updatedEventNote = val
        },     
        setRepeatValue(newVal, oldVal) {
            if (!newVal) {
                this.recurrence = {}
                this.repeatVal = -1
                this.isRecurring = false
                this.showRepeatOptions = false
                this.showRepeatOptionsExt = false
                return
            }
            else if (newVal === 7) {
                if (!this.isRecurring) this.isRecurring = true
                this.showRepeatOptions = false
                this.showRepeatOptionsExt = true
                if (!this.repeatFrom) {
                    this.repeatFrom = this.from
                    this.repeatTo = moment.unix(this.from).add(1, 'day').unix()
                    this.defaultRepeatFrom = moment.unix(this.repeatFrom).format('YYYY-MM-DD')
                    this.defaultRepeatTo = moment.unix(this.repeatTo).format('YYYY-MM-DD')                    
                }
            }
            else if (newVal) {
                this.recurrence = {}
                if (!oldVal) this.repeatIntervalVal = 1
                this.showRepeatOptions = true
                this.showRepeatOptionsExt = false
            }
            if (!oldVal) {
                this.repeatFrom = this.from
                this.repeatTo = moment.unix(this.from).add(1, 'day').unix()
                this.recurrence.startDate = moment.unix(this.repeatFrom).format('YYYY-MM-DD')
                this.recurrence.endDate = moment.unix(this.repeatTo).format('YYYY-MM-DD')
                this.recurrence.repeatOtherTypeVal = 0
            } else {
                this.recurrence.startDate = moment.unix(this.repeatFrom).format('YYYY-MM-DD')
                this.recurrence.endDate = moment.unix(this.repeatTo).format('YYYY-MM-DD')
                this.recurrence.repeatOtherTypeVal = oldVal
            }
            this.repeatVal = newVal
            this.recurrence.type = newVal - 1
            this.recurrence.interval = this.repeatIntervalVal || 1
            // console.log("🚀 ~ file: MeetingScheduler.vue:250 ~ setRepeatValue ~ this.recurrence:", this.recurrence)
        },
        setReminderValue(val) {
            const reminderNumValObj = this.reminderNumVal.find(nv => nv.value === val)
            if (reminderNumValObj) this.notifyBeforeStart = reminderNumValObj.number
        },
        selectContacts() {
            this.modalOpen({
                component: SelectContactsToScheduler,
                props: {
                    preSelectedEmails: this.attendees,
                    cb: (contacts) => {
                        let emails = contacts.map(c => c.mails[0].value)
                        this.attendees = emails
                        let contactsStr = emails.join(', ')
                        if (this.mailsStr.trim().length) this.mailsStr = this.mailsStr + ', ' + contactsStr
                        else this.mailsStr = contactsStr
                    }
                }
            })
        },
        async saveEvent() {
            let attendees = []
            this.attendees.map(email => {
                attendees.push({ address: email })
            })
            let startTime = this.from, endTime = this.to
            const payload = {
                    type: appointmentType,
                    startTime,
                    endTime,
                    allDay: this.isAllDay,
                    // isRecurring: this.isRecurring,
                    isPrivate: this.isPrivate,
                    subject: this.eventName,
                    location: this.eventLocation,
                    body: this.updatedEventNote.replace(/\n/g, '\r\n')
            }
            if (this.isRecurring) {
                if (this.recurrence.hasOwnProperty('repeatOtherTypeVal')) {
                    if (this.recurrence.type === 6) this.recurrence.type = this.recurrence.repeatOtherTypeVal
                    delete this.recurrence.repeatOtherTypeVal
                }
                if (this.isRecurrenceUpdatedOther) payload.recurrence = this.recurrence
                else payload.recurrence = this.formRecurrence(this.recurrence)
                if (typeof payload.recurrence.interval === "string") payload.recurrence.interval = +payload.recurrence.interval
            }
            if (attendees.length) payload.attendees = attendees
            if (this.notifyBeforeStart > -1) payload.notifyBeforeStart = this.notifyBeforeStart
            if (this.type === TYPES.EDIT) {
                payload.id = this.id
                let serverReply = await this[ACT_SCHEDULE_EDIT_APPOINTMENT](payload)
                this.props.cb && this.props.cb({ actionType: TYPES.EDIT, changedEvent: payload, serverReply })
            } else {
                let serverReply = await this[ACT_SCHEDULE_ADD_APPOINTMENT](payload)
                let id = serverReply && serverReply.id
                payload.id = id
                this.props.cb && this.props.cb({ actionType: TYPES.CREATE, createdEvent: payload, serverReply })
            }
            this.modalClose()
        },
        formRecurrence(recurrence) {
            let type = recurrence.type
            let daysOfTheWeek = []
            const m_week_day = moment(this.day.date).day()
            const eventWeekDay = m_week_day === 0 ? 6 : m_week_day - 1
            const m_month_day = moment().date()
            switch (type) {
                case 0:
                    recurrence.interval = this.repeatIntervalVal
                    break
                case 1:
                    recurrence.interval = this.repeatIntervalVal
                    daysOfTheWeek.push(eventWeekDay)
                    recurrence.daysOfTheWeek = daysOfTheWeek
                    break
                case 2:
                    recurrence.interval = this.repeatIntervalVal
                    daysOfTheWeek = [0,1,2,3,4]
                    recurrence.daysOfTheWeek = daysOfTheWeek
                    break
                case 3:
                    recurrence.interval = this.repeatIntervalVal
                    recurrence.dayOfMonth = m_month_day
                    break
                case 4:
                    recurrence.interval = this.repeatIntervalVal
                    recurrence.dayOfTheWeekIndex = 1
                    recurrence.dayOfTheWeek = eventWeekDay
                    recurrence
                    break
                case 5:
                    recurrence.interval = this.repeatIntervalVal
                    recurrence.dayOfMonth = m_month_day
                    recurrence.dayOfTheWeekIndex = 1
                    recurrence.dayOfTheWeek = eventWeekDay                    
                    recurrence.month = moment().format('MMMM')
                    break                    
            }
            // console.log("🚀 ~ file: MeetingScheduler.vue:423 ~ formRecurrence ~ recurrence:", recurrence)
            return recurrence
        },
        deleteEvent() {
            let payload = { type: "exchange", id: this.id }
            this.modalOpen({ component: DeleteEvent,
                props: {
                    title: this.$t('integrations.confirm-delete-title'),
                    date: this.day.date,
                    isRecurring: this.isRecurring,
                    btnOk: {
                        cb: async (isDeleteOnDay) => {
                            if (isDeleteOnDay) payload.occurrenceDate = this.day.date
                            let serverReply = await this[ACT_SCHEDULE_DELETE_APPOINTMENT](payload) || {}
                            this.props.cb && this.props.cb({ actionType: 'delete', payload, serverReply })
                            this.modalClose && this.modalClose()
                        }
                    }
                }             
            })
        },
        onRecurrenceChangeOther(val) {
            // console.log("🚀 ~ file: MeetingScheduler.vue:481 ~ onRecurrenceChangeOther ~ val:", val)
            this.isRecurrenceUpdatedOther = true
            this.recurrence = val
        },
        getParcedBody(body) {
            let noteText = new DOMParser().parseFromString(body, "text/html").documentElement.textContent
            noteText = noteText.trim().replace("undefined", "").replace("<!-- P {margin-top:0;margin-bottom:0;} -->","").replace("\n\n<!-- P {margin-top:0;margin-bottom:0;} -->\n\n\n\n\n","").trim()
            this.event.body = noteText       
            return noteText
        },
    },
}
