//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { MEMBER_PROPS } from "../../store/modules/video-conf-abstract"

export default {
    name: "videoConfGridItem",
    props: {
        member: {
            type: Object,
            required: true
        },
        moderator: {
            type: Boolean,
            required: true,
        },
        width: {
            type: String,
            required: false,
        },
        height: {
            type: String,
            required: false,
        }
    },
    data() {
      return {
          handupIcon: this.getReactionHtml(String.fromCodePoint(0x0270B)),
      }
    },
    computed: {
        styles() {
            let styles = {}
            if (this.width) styles['width'] = this.width
            if (this.height) styles['height'] = this.height
            return styles
        },
        classes() {
            return !this.cameraIsOn ? 'no-camera' : ''
        },
        name() {
            return this.member.name
        },
        cameraIsOn() {
            return this.member[MEMBER_PROPS.CAMERA] && this.member[MEMBER_PROPS.CAMERA_GLOBAL]
        },
        microphoneIsOn() {
            return this.member[MEMBER_PROPS.MICROPHONE]
        },
        cameraIconClasses() {
            let classes = []
            if (this.member[MEMBER_PROPS.WANT_SPEAK]) classes.push('fas', 'fa-video', 'color-red')
            else if (!this.member[MEMBER_PROPS.CAMERA_GLOBAL]) classes.push('fal', 'fa-video', 'transparent')
            else if (!this.member[MEMBER_PROPS.CAMERA]) classes.push('fal', 'fa-video-slash', 'color-red')
            else classes.push('fal', 'fa-video')
            return classes
        },
        microphoneIconClasses() {
            let classes = []
            if (this.member[MEMBER_PROPS.WANT_SPEAK]) classes.push('fas', 'fa-microphone', 'color-red')
            else if (!this.member[MEMBER_PROPS.MICROPHONE_GLOBAL]) classes.push('fal', 'fa-microphone', 'transparent')
            else if (!this.member[MEMBER_PROPS.MICROPHONE]) classes.push('fal', 'fa-microphone-slash', 'color-red')
            else classes.push('fal', 'fa-microphone')
            return classes
        },
        handup() {
            return this.member[MEMBER_PROPS.HANDUP]
        },
        reaction() {
            return this.member[MEMBER_PROPS.REACTION]
        },
    },
    methods: {
        getReactionHtml(reaction) {
            return this.wrapEmoji(reaction)
        },
        doMenu(e, position) {
            this.$emit('member-menu', e, this.member, position, {theme: 'videoconf'})
        },
    }
}
