var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelsearchwrapper" },
    [
      _vm.isLoader || _vm.isLookingFor
        ? _c("content-loader")
        : _vm.isNoResults
        ? _c("list-item", {
            attrs: { title: _vm.noRecords, link: false, chevron: false }
          })
        : _vm._e(),
      _c("RecycleScroller", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.foundPubs.length,
            expression: "foundPubs.length"
          }
        ],
        staticClass: "scroller",
        style: _vm.scrollerStyle,
        attrs: {
          id: "channel-search-area",
          items: _vm.foundPubs,
          "item-size": _vm.itemSize,
          "key-field": "id",
          buffer: _vm.bufferSize
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "list-item",
                  {
                    staticClass: "listItemCustom",
                    attrs: {
                      header: item.fio + " - " + item.title,
                      withHighlights: true,
                      highlights: item.highlights,
                      title: item.excerpt,
                      link: true,
                      chevron: false
                    },
                    nativeOn: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.scrollToElement(item)
                      },
                      contextmenu: function($event) {
                        $event.preventDefault()
                        return _vm.onContextMenu($event, item)
                      }
                    }
                  },
                  [
                    _c(
                      "template",
                      { slot: "media" },
                      [
                        _c("icon-image", {
                          attrs: { src: item.photo, text: item.fio }
                        })
                      ],
                      1
                    ),
                    _c("template", { slot: "after" }, [
                      _c("div", { staticClass: "dateTimeItems" }, [
                        _c("span", [_vm._v(_vm._s("" + item.date))])
                      ])
                    ])
                  ],
                  2
                )
              ]
            }
          }
        ])
      }),
      _vm.showSearchMore && _vm.foundPubs.length
        ? _c("div", { staticClass: "btnwrapper" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                on: {
                  click: function($event) {
                    return _vm.addPubs()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("media-search.search-more")))]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }