
import { mapGetters, mapActions } from 'vuex'
import {BOTS, CHATS} from "../../store/modulesNames"
import { GET_CHAT_MEMBERS, IS_CHAT_ADMIN } from "../../store/gettersTypes"
import { ACT_BOT_ADD } from "../../store/actionsTypes"

import SelectChat from "./SelectChat.vue"

import { CHAT_TYPES } from '../../constants'


export default {
    name: "SelectChatToBotAdd",
    extends: SelectChat,
    props: {
        botCid: {
            type: Number,
            required: true
        }
    },
    computed: {
        multiple() {
            return false
        },
        ...mapGetters(CHATS, [GET_CHAT_MEMBERS, IS_CHAT_ADMIN])
    },
    methods: {
        isExcludedChat({cid, cidType = CHAT_TYPES.USER}) {
            if (cidType === CHAT_TYPES.USER || !this[IS_CHAT_ADMIN]({ cid, cidType })) return true
            let members = this[GET_CHAT_MEMBERS]({ cid, cidType })
            return members.some(({ cid }) => cid === this.botCid )
        },
        async onOk (chat) {
            let payload = {
                cid: chat.cid,
                cidType: chat.cidType,
                botId: this.botCid
            }
            await this[ACT_BOT_ADD](payload)
            this.modalClose()
            this.cbOk && this.cbOk()
        },
        ...mapActions(BOTS, [ACT_BOT_ADD])
    }
}
