import module from '../../modules/phone-controller'
import {
    ACT_GET_RTC_CALL_AVAILABILITY,
    ACT_TOGGLE_PHONE_MINIMIZE,
    ACT_TOGGLE_PHONE_FULLSCREEN,
    ACT_SUBSCRIBE_AVAILABILITY,
} from "../../actionsTypes"
import {LOGIN} from "../../modulesNames"
import {GET_SERVER_API} from "../../gettersTypes"
import proto from "../../../protocol"

const actions = {
    // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//     [ACT_TOGGLE_PHONE_MINIMIZE]: () => {},
//     [ACT_TOGGLE_PHONE_FULLSCREEN]: () => {},
    // #endif
    [ACT_GET_RTC_CALL_AVAILABILITY]: async ({rootGetters}, payload) => {
        let availability = true
        let error = null
        try {
            if (rootGetters[`${LOGIN}/${GET_SERVER_API}`] >= declarations.serverAPILevels.LEVEL_8) {
                availability = await proto.rtcCallAvailability(payload)
            }
        } catch (e) {
            error = e
        }
        return { availability, error }
    },
    [ACT_SUBSCRIBE_AVAILABILITY]: async ({rootGetters}, payload) => {
        let error = null
        let availability = false
        try {
            if (rootGetters[`${LOGIN}/${GET_SERVER_API}`] >= declarations.serverAPILevels.LEVEL_8) {
                availability = await proto.rtcCallSubscribeAvailability(payload)
            }
        } catch (e) {
            error = e
        }
        return { availability, error }
    },
}

Object.assign(module.actions, actions)

export default module;