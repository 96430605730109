import WebRTCClient from './WebRTCClient'
import {MediaWorker, PeerConnection} from '../../../ext/webrtc'

import EventEmitter from '../../common/EventEmitter'

import {MEETINGS_API_EVENTS} from '../../api/roschat/protocols/meetings'
import {USERDATA} from '../../store/modulesNames'
import {ACT_USERDATA_GET_TURN_SERVER} from '../../store/actionsTypes'

export const CONFERENCE_STATES = {
    UNKNOWN: 'unknown',
    ONLINE: 'online',
    OFFLINE: 'offline',
    WAITING: 'waiting',
}

export const CONNECTION_STATUSES = {
    DISCONNECTED: 'disconnected',
    CONNECTING: 'connecting',
    CONNECTED: 'connected',
}

export const CLIENT_EVENTS = {
    CONNECTION_STATUS_CHANGED: 'connection-status-changed',
    CONF_STATE_CHANGED: 'conf-state-changed',
    CONF_OPTIONS_CHANGED: 'conf-options-changed',
    CONF_PARTICIPANTS_INITED: 'conf-participants-inited',
    CONF_PARTICIPANT_ADDED: 'conf-participant-add',
    CONF_PARTICIPANT_UPDATED: 'conf-participant-updated',
    CONF_PARTICIPANT_REMOVED: 'conf-participant-removed',
    CONF_DEVICES_CHANGED: 'conf-devices-changed',
    CONF_COMMAND: 'conf-command',
    SHARE_STARTED: 'share-started',
    SHARE_ENDED: 'share-ended',
}

const DEFAULT_CONF_OPTIONS = {
    audio: true,
    video: false,
    waiting: false,
    atOnce: false,
}

const PARTICIPANT_CONNECTION_STATE = {
    DISCONNECTED: 'disconnected',
    CONNECTING: 'connecting',
    CONNECTED: 'connected',
}

export const PARTICIPANT_PROPS = {
    ID: 'id',
    CID: 'cid',
    NAME: 'name',
    ROLE: 'role',
    BANNED: 'banned',
    CAM: 'cam',
    MIC: 'mic',
    CAN_CAM: 'canCam',
    CAN_MIC: 'canMic',
    LET_ME_SPEAK: 'letMeSpeak',

    CONNECTION_STATE: 'connectionState',
    OWNER: 'owner',
    MODERATOR: 'moderator',
    IS_ME: 'isMe',
}

export const SHARING_PROPS = {
    MEETING_ID: 'meetingId',
    USER_ID: 'userId',
    ID: 'id',

    MY_SHARING: 'mySharing'
}

export const PARTICIPANT_ROLES = {
    USER: 'user',
    ORGANIZER: 'organizer',
    MODERATOR: 'moderator',
}


const MEDIA_DEVICES = {
    AUDIO_INPUT: 'audioinput',
    AUDIO_OUTPUT: 'audiooutput',
    VIDEO_INPUT: 'videoinput',
}

export const CONF_PROPS = {
    ID: 'id',
    TOPIC: 'topic',
    AUTHOR: 'author',
    START_TIME: 'startTime',
    DURATION: 'duration',
}

class MeetingsClient extends EventEmitter {
    constructor ({
                     transport,
                     meetingId,
                     password = '',
                     name = '',
                     activeDevices,
                     logger
                 }) {
        super()
        if (!meetingId) throw new Error('meetingId should be set')
        this.el = null
        this.share = null
        this.sharing = null
        this.videoWrapperEl = null
        this.iceServers = []
        this.transport = transport
        this.meetingId = meetingId
        this.password = password
        this.name = name
        this.activeDevices = activeDevices || {}
        this.mic = false
        this.cam = false
        this.connectionStatus = CONNECTION_STATUSES.DISCONNECTED
        this.confState = CONFERENCE_STATES.UNKNOWN
        this.participants = new Map()
        this.meetingUserId = ''
        this.confOptions = DEFAULT_CONF_OPTIONS
        this.meetingInitAttempts = 30
        this.webRTCClient = null
        this.settings = {}
        this.logger = logger || {
            info: (text) => console.log(`MeetingsClient: ${text}`),
            error: (e, description) => {
                if (description) console.log(`MeetingsClient: ${description}`)
                console.error(e)
            }
        }
        this._init()
    }

    _init () {
        this._subscribeOnTransport()
    }

    _subscribeOnTransport () {
        this.transport.on(MEETINGS_API_EVENTS.CHANGE_MEETING, () => {

        })
        this.transport.on(MEETINGS_API_EVENTS.DELETE_MEETING, () => {

        })
        this.transport.on(MEETINGS_API_EVENTS.MEETING_STATE, (payload) => {
            const { meetingId, state } = payload
            this.logger.info(`transport event - ${MEETINGS_API_EVENTS.MEETING_STATE}, payload - ${JSON.stringify(payload)}`)
            if (this.meetingId === meetingId) this._setConfState(state)
        })
        this.transport.on(MEETINGS_API_EVENTS.MEETING_CONNECT, (payload) => {
            this.logger.info(`transport event - ${MEETINGS_API_EVENTS.MEETING_CONNECT}, payload - ${JSON.stringify(payload)}`)
            this._onParticipantConnected(payload)
        })
        this.transport.on(MEETINGS_API_EVENTS.CHANGE_MEETING_USER, (payload) => {
            this.logger.info(`transport event - ${MEETINGS_API_EVENTS.CHANGE_MEETING_USER}, payload - ${JSON.stringify(payload)}`)
            this._onParticipantChanged(payload)
        })
        this.transport.on(MEETINGS_API_EVENTS.MEETING_DISCONNECT, (payload) => {
            this.logger.info(`transport event - ${MEETINGS_API_EVENTS.MEETING_DISCONNECT}, payload - ${JSON.stringify(payload)}`)
            this._onParticipantDisconnected(payload)
        })
        this.transport.on(MEETINGS_API_EVENTS.MEETING_ANSWER, (payload) => {
            this.logger.info(`transport event - ${MEETINGS_API_EVENTS.MEETING_ANSWER}, payload - ${JSON.stringify(payload)}`)
            this._onMeetingAnswer(payload)
        })
        this.transport.on(MEETINGS_API_EVENTS.MEETING_ADD_CANDIDATE, (payload) => {
            this.logger.info(`transport event - ${MEETINGS_API_EVENTS.MEETING_ADD_CANDIDATE}, payload - ${JSON.stringify(payload)}`)
            this._onMeetingAddCandidate(payload)
        })
        this.transport.on(MEETINGS_API_EVENTS.MEETING_REMOVE_CANDIDATE, (payload) => {
            this.logger.info(`transport event - ${MEETINGS_API_EVENTS.MEETING_REMOVE_CANDIDATE}, payload - ${JSON.stringify(payload)}`)
            this._onMeetingRemoveCandidate(payload)
        })
        this.transport.on(MEETINGS_API_EVENTS.MEETING_INVITE_USER, () => {

        })
        this.transport.on(MEETINGS_API_EVENTS.MEETING_REJECT_USER, () => {

        })
        this.transport.on(MEETINGS_API_EVENTS.MEETING_INVITE, () => {

        })
        this.transport.on(MEETINGS_API_EVENTS.MEETING_COMMAND, (payload) => {
            this.logger.info(`transport event - ${MEETINGS_API_EVENTS.MEETING_COMMAND}, payload - ${JSON.stringify(payload)}`)
            this.emit(CLIENT_EVENTS.CONF_COMMAND, payload)
        })
        this.transport.on(MEETINGS_API_EVENTS.MEETING_SHARING_START, (payload) => {
            this.logger.info(`transport event - ${MEETINGS_API_EVENTS.MEETING_SHARING_START}, payload - ${JSON.stringify(payload)}`)
            this._onSharingStart(payload)
        })
        this.transport.on(MEETINGS_API_EVENTS.MEETING_SHARING_STOP, (payload) => {
            this.logger.info(`transport event - ${MEETINGS_API_EVENTS.MEETING_SHARING_STOP}, payload - ${JSON.stringify(payload)}`)
            this._onSharingStop(payload)
        })
    }

    getActiveConfId () {
        return this.meetingId
    }

    getSettings() {
        return this.settings
    }

    start () {

    }

    stop () {

    }

    async join (payload) {
        this.logger.info(`join: ${JSON.stringify(payload)}`)
        const {el, share = null, mic = false, cam = false} = payload
        this.el = el
        this.share = share
        this.mic = mic
        this.cam = cam
        this.logger.info(`join: transport meetingEnter called`)
        this._initDOM()
        try {
            let meetingInfo = await this.transport.meetingInfo({
                meetingId: this.meetingId,
                password: this.password,
            })

            if (meetingInfo.settings && !meetingInfo.settings.video) this.cam = false

            this.logger.info(`join: transport meetingEnter called`)
            let response = await this.transport.meetingEnter({
                meetingId: this.meetingId,
                password: this.password,
                name: this.name,
                mic: this.mic,
                cam: this.cam,
            })
            this.logger.info(`join: transport meetingEnter response received ${JSON.stringify(response)}`)

            let iceServer = await this.transport.meetingGetIceServers()
            this.iceServers = [{urls: 'turn:' + iceServer.server, username: iceServer.username, credential: iceServer.password}]
            this.logger.info(`join: iceServers formed ${JSON.stringify(this.iceServers)}`)

            this.settings = response
            this.meetingUserId = response.id
            this._updateParticipants(response.contacts)
            this.sharing = response.sharing && this._extendSharingProps(response.sharing)
            this._setConfState(response.state)
        } catch (e) {
            this.logger.error(e, 'join: transport meetingEnter error')
            throw e
        }
    }

    leave() {
        this.transport.meetingExit({meetingId: this.meetingId})
        if (this.webRTCClient) this.webRTCClient.stop()
    }

    async changeMediaDevice (kind, val) {
        this.activeDevices[kind] = val

        switch (kind) {
            case MEDIA_DEVICES.AUDIO_INPUT:
                await this._changeMicrophone(val)
                break
            case MEDIA_DEVICES.VIDEO_INPUT:
                await this._changeCamera(val)
                break
            case MEDIA_DEVICES.AUDIO_OUTPUT:
                await this._changeSpeaker(val)
                break
        }

        this.emit(CLIENT_EVENTS.CONF_DEVICES_CHANGED, {...this.activeDevices})
    }

    async _changeMicrophone (id) {
        if (this.webRTCClient) {
            let {oldTrack, newTrack} = await this.webRTCClient.replaceLocalAudioDevice(id)
            let participant = this._getParticipant(this.meetingUserId)
            if (participant) await participant.peerConnection.replaceTrack(oldTrack, newTrack)
            this.webRTCClient.removeLocalTrack(oldTrack)
        }

    }

    async _changeCamera (id) {
        if (this.webRTCClient) {
            let {oldTrack, newTrack} = await this.webRTCClient.replaceLocalVideoDevice(id)
            let participant = this._getParticipant(this.meetingUserId)
            if (participant) await participant.peerConnection.replaceTrack(oldTrack, newTrack)
            this.webRTCClient.removeLocalTrack(oldTrack)
        }
    }

    async _changeSpeaker(id) {
        if (this.webRTCClient) {
            this.webRTCClient.replaceRemoteAudioDevice(id)
        }
    }

    async toggleCamera () {
        if (this.webRTCClient) {
            let isMuted = this.webRTCClient.muteVideo()
            this.cam = !isMuted
            if (this.cam && this.webRTCClient.videoPlug) {
                await this._changeCamera(this.activeDevices[MEDIA_DEVICES.VIDEO_INPUT])
            }
            this.changeParticipantProps({meetingId: this.meetingId, id: this.meetingUserId, props: {cam: !isMuted}})
        }
    }

    async toggleMicrophone () {
        if (this.webRTCClient) {
            let isMuted = this.webRTCClient.mute()
            this.mic = !isMuted
            if (this.mic && this.webRTCClient.audioPlug) {
                await this._changeMicrophone(this.activeDevices[MEDIA_DEVICES.AUDIO_INPUT])
            }
            this.changeParticipantProps({meetingId: this.meetingId, id: this.meetingUserId, props: {mic: !isMuted}})
        }
    }

    toggleSpeaker () {
        if (this.webRTCClient) {
            return this.webRTCClient.muteSpeaker()
        }
    }

    changeSpeakerVolume(volume) {
        if (this.webRTCClient) {
            this.webRTCClient.changeSpeakerVolume(volume)
        }
    }

    changeParticipantProps({meetingId, id, props}) {
        if (this.meetingId !== meetingId) return
        this.transport.changeMeetingUser({meetingId: this.meetingId, id, ...props})
    }

    sendCommand (payload) {
        this.transport.sendMeetingCommand({meetingId: this.meetingId, ...payload})
    }

    async shareToggle () {
        if (this.webRTCClient) {
            if (!this.sharing) await this.startSharing()
            else await this.stopSharing()
        }
    }

    async startSharing () {
        if (!this.sharing && this.webRTCClient) {
            let stream = await this.webRTCClient.startLocalSharing()

            let { id } = await this.transport.meetingSharingStart({meetingId: this.meetingId})
            this.sharing = this._extendSharingProps({userId: this.meetingUserId, id})
            this.emit(CLIENT_EVENTS.SHARE_STARTED, { ...this.sharing })
            await this._createConferenceOffer(this.sharing, stream)
            stream.getVideoTracks().forEach((track) => track.addEventListener('ended', () => this.stopSharing()))
        }
    }

    async stopSharing () {
        if (this.sharing && this.sharing.userId === this.meetingUserId) {
            this.webRTCClient.setLocalSharingStream()
            await this.transport.meetingSharingStop({meetingId: this.meetingId, id: this.sharing.id})
            this.emit(CLIENT_EVENTS.SHARE_ENDED)
            this.sharing = null
        }
    }

    _initDOM () {
        {
            let remoteViewWrapper = document.createElement('div')
            remoteViewWrapper.setAttribute('class', 'remoteViewWrapper')
            remoteViewWrapper.setAttribute('style', 'width: 100%; height: 100%;')
            let remotesLayout = document.createElement('div')
            remotesLayout.setAttribute('class', 'remotes_layout')
            remoteViewWrapper.appendChild(remotesLayout)
            let videosClass = document.createElement('div')
            videosClass.setAttribute('class', 'videos_class')
            remotesLayout.appendChild(videosClass)
            this.el.appendChild(remoteViewWrapper)
            this.videoWrapperEl = videosClass
        }

        {
            let remoteSharingWrapper = document.createElement('div')
            remoteSharingWrapper.setAttribute('class', 'remoteSharingWrapper')
            remoteSharingWrapper.setAttribute('style', 'width: 100%; height: 100%;')
            let remotesLayout = document.createElement('div')
            remotesLayout.setAttribute('class', 'remotes_layout')
            remotesLayout.setAttribute('style', 'width: 100%; height: 100%;')
            remoteSharingWrapper.appendChild(remotesLayout)
            let videosClass = document.createElement('div')
            videosClass.setAttribute('class', 'videos_class')
            videosClass.setAttribute('style', 'width: 100%; height: 100%;')
            remotesLayout.appendChild(videosClass)
            this.share.setAttribute('style', 'width: 100%; height: 100%; display: none;')
            this.share.appendChild(remoteSharingWrapper)
            this.shareWrapperEl = videosClass
        }
    }

    _onMeetingAnswer ({meetingId, id, sdp}) {
        if (meetingId !== this.meetingId) return
        const answer = {type: 'answer', sdp}
        let participant = this._getParticipant(id)
        if (participant) {
            participant.peerConnection.setAnswer(answer)
            participant[PARTICIPANT_PROPS.CONNECTION_STATE] = PARTICIPANT_CONNECTION_STATE.CONNECTED
        }
    }

    getParticipantsList() {
        return [ ...this.participants.values() ]
    }

    _getParticipant(id) {
        let participant = this.participants.get(id)
        if (!participant && this.sharing?.id === id) participant = this.sharing
        return participant
    }

    _onMeetingAddCandidate ({meetingId, id, candidate}) {
        if (meetingId !== this.meetingId) return
        let participant = this._getParticipant(id)
        if (participant) participant.peerConnection.addCandidate(candidate)
    }

    _onMeetingRemoveCandidate ({meetingId, id, candidate}) { //@todo
        /*if (meetingId !== this.meetingId) return
        let participant = this._getParticipant(id)
        if (participant) participant.peerConnection.removeCandidate(candidate)*/
    }

    _onParticipantConnected ({meetingId, ...participant}) {
        if (meetingId !== this.meetingId) return
        this._addParticipant(participant)
        this._connectParticipant(participant[PARTICIPANT_PROPS.ID])
    }

    _onParticipantChanged ({meetingId, ...participant}) {
        let participantOld = this._getParticipant(participant[PARTICIPANT_PROPS.ID])
        if (meetingId !== this.meetingId || !participantOld) return
        let participantNew = this._extendParticipantProps(participant)
        Object.assign(participantOld, participantNew)
        this.emit(CLIENT_EVENTS.CONF_PARTICIPANT_UPDATED, {...participantOld})
    }

    _onParticipantDisconnected ({meetingId, id, reason}) {
        if (meetingId !== this.meetingId) return
        if (this.meetingUserId === id) {
            this._setConfState(CONFERENCE_STATES.OFFLINE, reason)
        } else {
            this._removeParticipant(id)
        }
    }

    async _onSharingStart (payload) {
        const {meetingId, userId, id} = payload
        this.logger.info(`_onSharingStart: ${JSON.stringify(payload)}`)
        if (this.meetingId !== meetingId) return
        this.sharing = this._extendSharingProps(payload)
        this.emit(CLIENT_EVENTS.SHARE_STARTED, { ...this.sharing })
        await this._connectSharing()
    }

    _onSharingStop (payload) {
        const {meetingId} = payload
        if (this.meetingId !== meetingId) return
        this.emit(CLIENT_EVENTS.SHARE_ENDED)
        this.sharing = null
        this.webRTCClient.setSharingStream()
        this._removeShareDomElements()
    }

    _setConnectionStatus (status) {
        if (this.connectionStatus !== status) {
            this.connectionStatus = status
            this.emit(CLIENT_EVENTS.CONNECTION_STATUS_CHANGED, status)
        }
    }

    _setConfState (state, reason) {
        if (this.confState !== state) {
            this.confState = state
            switch (state) {
                case CONFERENCE_STATES.ONLINE: {
                    this._startTranslation()
                    break
                }
                case CONFERENCE_STATES.OFFLINE: {
                    this._stopTranslation()
                    break
                }
                case CONFERENCE_STATES.WAITING: {

                    break
                }
            }
            this.emit(CLIENT_EVENTS.CONF_STATE_CHANGED, {state, reason})
        }
    }

    _updateConfOptions (options) {
        this.confOptions = {...DEFAULT_CONF_OPTIONS, ...options}
        this.emit(CLIENT_EVENTS.CONF_OPTIONS_CHANGED, {...this.confOptions})
    }

    _updateParticipants (participantList) {
        this.participants = new Map()
        participantList.forEach((participant) => this._addParticipant(participant))
        this.emit(CLIENT_EVENTS.CONF_PARTICIPANTS_INITED, this.getParticipantsList())
    }

    _extendParticipantProps (participant) {
        let props = {
            [PARTICIPANT_PROPS.CAM]: (PARTICIPANT_PROPS.CAM in participant) ? participant[PARTICIPANT_PROPS.CAM] : false,
            [PARTICIPANT_PROPS.MIC]: (PARTICIPANT_PROPS.MIC in participant) ? participant[PARTICIPANT_PROPS.MIC] : false,
            [PARTICIPANT_PROPS.CAN_CAM]: (PARTICIPANT_PROPS.CAN_CAM in participant) ? participant[PARTICIPANT_PROPS.CAN_CAM] : true,
            [PARTICIPANT_PROPS.CAN_MIC]: (PARTICIPANT_PROPS.CAN_MIC in participant) ? participant[PARTICIPANT_PROPS.CAN_MIC] : true,
            [PARTICIPANT_PROPS.LET_ME_SPEAK]: (PARTICIPANT_PROPS.LET_ME_SPEAK in participant) ? participant[PARTICIPANT_PROPS.LET_ME_SPEAK] : false,

            //[PARTICIPANT_PROPS.CONNECTION_STATE]: participant[PARTICIPANT_PROPS.CONNECTION_STATE] ?? PARTICIPANT_CONNECTION_STATE.DISCONNECTED,
            [PARTICIPANT_PROPS.OWNER]: participant[PARTICIPANT_PROPS.ROLE] === PARTICIPANT_ROLES.ORGANIZER,
            [PARTICIPANT_PROPS.MODERATOR]: [PARTICIPANT_ROLES.ORGANIZER, PARTICIPANT_ROLES.MODERATOR].includes(participant[PARTICIPANT_PROPS.ROLE]),
            [PARTICIPANT_PROPS.IS_ME]: participant.id === this.meetingUserId,
        }
        if (this.settings && this.settings.settings && !this.settings.settings.video) props[PARTICIPANT_PROPS.CAN_CAM] = false
        return Object.assign(participant, props)
    }

    _extendSharingProps (sharing) {
        return Object.assign(sharing, {
            connectionState: PARTICIPANT_CONNECTION_STATE.DISCONNECTED,
            [SHARING_PROPS.MEETING_ID]: this.meetingId,
            [SHARING_PROPS.MY_SHARING]: sharing[SHARING_PROPS.USER_ID] === this.meetingUserId
        })
    }

    _addParticipant (participant) {
        this.logger.info(`_addParticipant: ${JSON.stringify(participant)}`)
        if (!this.participants.has(participant[PARTICIPANT_PROPS.ID])) {
            participant = this._extendParticipantProps(participant)
            participant[PARTICIPANT_PROPS.CONNECTION_STATE] = PARTICIPANT_CONNECTION_STATE.DISCONNECTED
            this.participants.set(participant[PARTICIPANT_PROPS.ID], participant)
        }
    }

    _removeParticipant (id) {
        const participant = this._getParticipant(id)
        if (participant) {
            this._removeParticipantDomElements(id)
            this.participants.delete(id)
            this.webRTCClient.removeRemoteEl(id)
            this.emit(CLIENT_EVENTS.CONF_PARTICIPANT_REMOVED, {...participant})
        }
    }

    _removeAllParticipants() {
        const participantsList = [ ...this.participants.keys() ]
        participantsList.forEach(id => this._removeParticipant(id))
    }

    async _startTranslation () {
        //add self peer
        this.logger.info(`_startTranslation: called`)
        if (this.webRTCClient) {
            this.webRTCClient.stop()
            this.webRTCClient = null
        }
        this.webRTCClient = new WebRTCClient({videoPlugText: this.name, video: true})
        await this._connectParticipant(this.meetingUserId)
        this.logger.info('_startTranslation: local mounted')
        this._connectRemoteParticipants().catch((e) => {
            this.logger.info('_connectRemoteParticipants: error')
            this.logger.error(e)
        })
        if (this.sharing) setTimeout(() => this._onSharingStart(this.sharing), 5000) //@todo
    }

    async _stopTranslation () {
        this.logger.info('_stopTranslation: called')
        this._removeAllParticipants()
        if (this.sharing) this._onSharingStop({ meetingId: this.meetingId })
        if (this.webRTCClient) {
            this.webRTCClient.stop()
            this.webRTCClient = null
        }
    }

    async _connectRemoteParticipants () {
        this.logger.info('_connectRemoteParticipants: called')
        let participantList = [...this.participants.values()]
        for (let i = 0, listCount = participantList.length; i < listCount; i++) {
            this._connectParticipant(participantList[i].id)
        }
        this.logger.info('_connectRemoteParticipants: remote participants mounted')

    }

    async _connectParticipant (id) {
        this.logger.info(`_connectParticipant: connection for ${id} started`)
        let participant = this._getParticipant(id)
        if (!participant) {
            this.logger.info(`_connectParticipant: participant ${id} not found`)
            return
        }
        if (participant.connectionState !== PARTICIPANT_CONNECTION_STATE.DISCONNECTED) {
            this.logger.info(`_connectParticipant: participant ${id} connectionState ${participant.connectionState}, so ignore`)
            return
        }
        participant.connectionState = PARTICIPANT_CONNECTION_STATE.CONNECTING
        participant.options = this.confOptions
        participant.attempts = this.meetingInitAttempts
        this._addParticipantDomElements(id)
        this.emit(CLIENT_EVENTS.CONF_PARTICIPANT_ADDED, {...participant})
        if (id === this.meetingUserId) {
            this.logger.info(`_connectParticipant: add local ${id}`)
            participant.direction = 'sendonly'
            participant.videoCanvas = '#localConferenceVideo'
        } else {
            this.logger.info(`_connectParticipant: add remote ${id}`)
            participant.direction = 'recvonly'
            participant.videoCanvas = '#remoteConferenceVideo' + id
        }

        this.logger.info('_connectParticipant: _initConferenceMedia called')
        await this._initConferenceMedia(participant)
        this.logger.info('_connectParticipant: _initConferenceMedia resolved')
    }

    async _connectSharing () {
        if (this.share && this.sharing) {
            this.sharing.attempts = this.meetingInitAttempts
            if (this.sharing.userId !== this.meetingUserId) {
                this._addShareDomElements()
                this.webRTCClient.setSharingEl(this.shareWrapperEl.querySelector('#sharing'))
                await this._createConferenceOffer(this.sharing, this.webRTCClient.getLocalStream(), (remoteStream) => {
                    this.webRTCClient.setSharingStream(remoteStream)
                })
            }
        }
    }

    _addShareDomElements () {
        if (!this.shareWrapperEl) return
        let shareVideo = document.createElement('video')
        shareVideo.setAttribute('id', 'sharing')
        shareVideo.setAttribute('style', 'width: 100%; height: 100%;')
        shareVideo.muted = true
        shareVideo.autoplay = true
        this.shareWrapperEl.appendChild(shareVideo)
        this.share.setAttribute('style', 'width: 100%; height: 100%; display: block;')
    }

    _removeShareDomElements () {
        if (!this.shareWrapperEl) return
        this.share.setAttribute('style', 'width: 100%; height: 100%; display: none;')
        this.shareWrapperEl.querySelector('#sharing').remove()
    }

    _addParticipantDomElements (id) {
        if (this.videoWrapperEl.querySelector(`[data-jid="${id}"]`)) return

        const isMe = id === this.meetingUserId

        let wrapperEl = document.createElement('span')
        wrapperEl.setAttribute('data-jid', id)
        wrapperEl.setAttribute('class', 'videocontainer' + (isMe ? ' view_self' : ''))

        const elId = isMe ? 'localConferenceVideo' : 'remoteConferenceVideo' + id
        const local = document.createElement('video')
        local.setAttribute('id', elId)
        if (isMe) local.muted = true
        local.autoplay = true
        local.setAttribute('style', 'background-color: grey')
        wrapperEl.appendChild(local)

        this.videoWrapperEl.appendChild(wrapperEl)
    }

    _removeParticipantDomElements (id) {
        let el = this.videoWrapperEl.querySelector(`[data-jid="${id}"]`)
        if (el) el.remove()
    }

    async _onInitConferenceMedia (participant) {
        if (!participant.candidates.length && participant[PARTICIPANT_PROPS.IS_ME]) {
            if (!participant.attempts--) return
            this.logger.info(`Trying again of the WebRTC media creation (${participant.attempts})`)
            setTimeout(async () => {
                await this._initConferenceMedia(participant)
                this._onInitConferenceMedia(participant)
            }, 500)
        } else {
            let data = {id: participant.id, meetingId: this.meetingId, sdp: participant.sdp}
            if (!participant.offerSend) {
                participant.offerSend = true
                this.logger.info(`send-meeting-offer: ${JSON.stringify(data)}`)
                let response = await this.transport.sendMeetingOffer(data)
                if (response && response.error) {
                    return this.logger.error(`send-meeting-offer error: ${response.error}`)
                }
                this.logger.info(`send-meeting-offer: < ${JSON.stringify(response)}`)
            }
            let candidate
            while (candidate = (participant.candidates || []).shift()) {
                let data = {
                    id: participant.id, meetingId: this.meetingId, candidate: {
                        candidate: candidate.candidate,
                        sdpMLineIndex: candidate.sdpMLineIndex,
                        sdpMid: candidate.sdpMid,
                    },
                }
                this.logger.info(`send-meeting-add-candidate: ${JSON.stringify(data)}`)
                this.transport.sendMeetingAddCandidate(data)
            }
        }
    }

    _getLocalOptions () {
        let options = {
            audio: this.mic,
            video: this.cam,
        }
        let activeMicrophone = this.activeDevices[MEDIA_DEVICES.AUDIO_INPUT]
        if (options.audio && activeMicrophone && activeMicrophone !== 'default')
            options.audio = {deviceId: {exact: activeMicrophone}}
        let activeCamera = this.activeDevices[MEDIA_DEVICES.VIDEO_INPUT]
        if (options.video && activeCamera && activeCamera !== 'default')
            options.video = {deviceId: {exact: activeCamera}}
        return options
    }

    async _initConferenceMedia (participant) {
        return new Promise((async resolve => {
            if (!participant) resolve()
            this.logger.info(`initConferenceMedia: participant(${participant.id}), videoCanvas = ${JSON.stringify(participant.videoCanvas)}`)
            if (participant[PARTICIPANT_PROPS.IS_ME]) {
                await this.webRTCClient.startLocal(document.querySelector(participant.videoCanvas), this._getLocalOptions())
                if (!this.mic) this.webRTCClient.mute()
                if (!this.cam) this.webRTCClient.muteVideo()
            } else {
                this.webRTCClient.addRemoteEl(document.querySelector(participant.videoCanvas), participant[PARTICIPANT_PROPS.ID])
            }
            //@todo нет у mediaWorker onremovetrack
            /*participant.mediaWorker.onremovetrack = (event) => {
             console.log('conference', 'mediaWorker: onremovetrack: event = ' + JSON.stringify(event))
             participant.mediaWorker.setRemoteStream(null)
             }*/
            let localStream
            if (participant[PARTICIPANT_PROPS.IS_ME]) localStream = this.webRTCClient.getLocalStream()
            else localStream = this.webRTCClient.getLocalStream()
            await this._createConferenceOffer(participant, localStream, (remoteStream) => {
                if (!participant[PARTICIPANT_PROPS.IS_ME]) this.webRTCClient.setRemoteStream(remoteStream, participant[PARTICIPANT_PROPS.ID])
            })
            resolve()
        }))
    }

    async _createConferenceOffer (participant, localStream, onRemoteStream) {
        return new Promise((resolve => {
            participant.candidates = []
            participant.sdp = null
            let release = false
            let timerIceCandidate
            participant.peerConnection = new PeerConnection(this.iceServers, localStream,
                //(finish, candidate) => { //todo у нас нет finish
                async (candidate) => {
                    if (candidate) {
                        participant.candidates.push(candidate)
                    }

                    if (!release) {
                        if (timerIceCandidate) clearTimeout(timerIceCandidate)
                        timerIceCandidate = setTimeout(() => {
                            this.logger.info(`onCandidate: participant(${participant.id}) candidates collect complete`)
                            release = true
                            resolve(participant)
                            this._onInitConferenceMedia(participant)
                        }, 200)
                    } else {
                        this.logger.info(`onCandidate: participant(${participant.id}) receive additional candidate ${JSON.stringify(candidate)}`)
                        this._onInitConferenceMedia(participant)
                    }
                },
                ({stream}) => {
                    this.logger.info(`onRemoteStream: participant(${participant.id}) stream receive`)
                    onRemoteStream && onRemoteStream(stream)
                },
                (participant[PARTICIPANT_PROPS.IS_ME]  || participant[SHARING_PROPS.MY_SHARING]) ? 'sendonly' : 'recvonly'
            )
            participant.peerConnection.getOffer((offer) => {
                participant.sdp = offer.sdp
            })

            timerIceCandidate = setTimeout(() => {
                this.logger.error(`_createConferenceOffer: Error IceCandidate participant(${participant.id})`)
                resolve(participant)
                release = true
            }, 1000)
        }))
    }

}

export default MeetingsClient