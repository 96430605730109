var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contacts-list-item-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "side-list-item contacts-list-item",
        class: { selected: _vm.isSelected, focused: _vm.focused },
        style: { height: _vm.height + "px" },
        on: {
          click: function($event) {
            return _vm.doSelect(_vm.contact.cid)
          },
          contextmenu: function($event) {
            $event.preventDefault()
            return _vm.showContextMenu($event, _vm.contact)
          }
        }
      },
      [
        _c("icon-image", {
          attrs: { src: _vm.contact.photo, text: _vm.fio, marked: _vm.marked }
        }),
        _c("div", { staticClass: "text-block" }, [
          _c("div", { staticClass: "fio name" }, [
            _c("span", [_vm._v(_vm._s(_vm.fio))]),
            _vm.contact.official
              ? _c("div", { staticClass: "official" }, [
                  _c("i", { staticClass: "far fa-check" })
                ])
              : _vm._e()
          ]),
          _vm.subTitle
            ? _c("div", { staticClass: "sub-title" }, [
                _c("span", [_vm._v(_vm._s(_vm.subTitle))])
              ])
            : _vm._e()
        ]),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }