//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex"
import {
    GET_VC_CONF_MEMBERS,
    IS_VC_CONF_OWNER,
    GET_VC_CONF_SHARE,
    IS_VC_OWN_SHARE,
    GET_VC_CONF_STATUS,
    GET_VC_CONF_ERROR_TEXT,
    IS_VC_BANNED,
    IS_VC_CONF_MODERATOR,
    GET_VC_CONF,
    IS_VC_MINIMIZED,
    IS_VC_IN_FULLSCREEN,
    GET_CALL_TALK_TIME,
    GET_VC_CONF_DIALOG_OPENED,
    GET_IS_ELECTRON,
} from "../../store/gettersTypes"
import {
    CLIENTDATA,
    PHONE_CNTL,
    VIDEO_CONF_NEW,
} from "../../store/modulesNames"
import {
    ACT_SET_CALL_STATUS,
    ACT_VC_CONF_BAN_MEMBER,
    ACT_VC_CONF_CAMERA_TOGGLE,
    ACT_VC_CONF_FINISH,
    ACT_VC_CONF_KICK_OUT_MEMBER,
    ACT_VC_CONF_LEAVE,
    ACT_VC_CONF_MICROPHONE_TOGGLE,
    ACT_VC_CONF_OPEN_DIALOG,
    ACT_VC_CONF_SET_MEMBER_MIC,
    ACT_VC_CONF_SET_MEMBER_MODERATOR,
    ACT_VC_CONF_SET_MEMBER_VIDEO,
    ACT_VC_CONF_SHARE,
    ACT_VC_CONF_SPEAKER_TOGGLE,
    ACT_VC_CONF_WANT_SPEAK,
} from "../../store/actionsTypes"
import {
    CAM_STATUS,
    MIC_STATUS,
    MEMBER_PROPS,
    STATUS,
    CONF_PROPS,
    END_REASONS
} from "../../store/modules/video-conf-abstract"

import VideoConfStatus from "./videoConfStatus.vue"
import videoConfSchemeSelect from "./videoConfSchemeSelect.vue"
import videoConfReactions from "./videoConfReactions.vue"
import videoConfCamSelect from "./videoConfCamSelect.vue"
import videoConfMicSelect from "./videoConfMicSelect.vue"
import videoConfInfo from "./videoConfInfo.vue"
import videoConfMembers from "./videoConfMembers.vue"
import VideoConfSectionHeader from "./videoConfSectionHeader.vue"
import VideoConfSectionMain from "./videoConfSectionMain.vue"
import VideoConfSectionFooter from "./videoConfSectionFooter.vue"
import VideoConfSourceSelect from "./videoConfSourceSelect.vue"

import ipc from '../../../electron/ipc'

import {CONF_TYPES, CONFERENCE_SCHEMAS} from '../../constants'

export default {
    name: "videoConfBase",
    components: {
        VideoConfSourceSelect,
        VideoConfSectionFooter,
        VideoConfSectionMain,
        VideoConfSectionHeader,
        videoConfMembers,
        VideoConfStatus,
    },
    data() {
        return {
            type: null,
            id: null,
            schema: CONFERENCE_SCHEMAS.PUZZLE,
            schemeSelect: false,
            exitOpen: false,
            reactionSelect: false,
            membersListOpened: false,
            infoOpened: false,
            camListOpened: false,
            micListOpened: false,
            electronWinTransformation: false
        }
    },
    mounted () {
        window.addEventListener('unload', this.onPageUnload)
    },
    beforeDestroy() {
        window.removeEventListener('unload', this.onPageUnload)
    },
    created() {
        let theme = this.$store.getters['clientdata/getTheme']
        if (theme === 'light' || (theme === 'system' && !isDarkSystemTheme)) document.documentElement.setAttribute("data-theme", "")
        if (theme === 'dark' || (theme === 'system' && isDarkSystemTheme)) document.documentElement.setAttribute("data-theme", "dark")
    },
    watch: {
        share(newVal) {
            if (newVal) {
                if (this.ownShare) {
                    if (this.isElectron && this.schema !== CONFERENCE_SCHEMAS.MY_SHARE) {
                        this.convertWindowToView('share')
                        this.schema = CONFERENCE_SCHEMAS.MY_SHARE
                        this.membersListOpened = false
                    }
                } else {
                    if (this.schema !== CONFERENCE_SCHEMAS.SHARE) this.schema = CONFERENCE_SCHEMAS.SHARE
                }
            } else {
                if (this.schema === CONFERENCE_SCHEMAS.MY_SHARE) {
                    this.convertWindowToView('default')
                }
                if ([CONFERENCE_SCHEMAS.SHARE, CONFERENCE_SCHEMAS.MY_SHARE].includes(this.schema)) {
                    this.schema = CONFERENCE_SCHEMAS.PUZZLE
                }
            }
        },
    },
    computed: {
        confIdGetterData() {
            return this.type && this.id && [ this.type, this.id ]
        },
        confIdActionData() {
            return this.type && this.id && { type: this.type, id: this.id }
        },
        conf() {
            return this.confIdGetterData && this[GET_VC_CONF](...this.confIdGetterData) || {}
        },
        status() {
            return this.confIdGetterData && this.conf[CONF_PROPS.STATUS] || ''
        },
        owner() {
            return Boolean(this[IS_VC_CONF_OWNER](...this.confIdGetterData))
        },
        share() {
            return Boolean(this.confIdGetterData && this[GET_VC_CONF_SHARE](...this.confIdGetterData))
        },
        ownShare() {
            return Boolean(this.confIdGetterData && this[IS_VC_OWN_SHARE](...this.confIdGetterData))
        },
        showShare() {
            return this.share && !this.ownShare
        },
        isBanned() {
            return Boolean(this.confIdGetterData && this[IS_VC_BANNED](...this.confIdGetterData))
        },
        isActive() {
            return this.status === STATUS.ACTIVE
        },
        btsShow() {
            return this.isActive && !this.isBanned
        },
        isModerator() {
            return Boolean(this.confIdGetterData && this[IS_VC_CONF_MODERATOR](...this.confIdGetterData))
        },
        membersOpened() {
            return Boolean(this.confIdGetterData && this[GET_VC_CONF_DIALOG_OPENED](...this.confIdGetterData, 'members' ))
        },
        sectionBindProps() {
            return {
                type: this.type,
                id: this.id,
                schema: this.schema,
                'bts-show': this.btsShow,
                share: this.share,
                'own-share': this.ownShare,
                'is-banned': this.isBanned,
                'is-moderator': this.isModerator,
                status: this.status,
            }
        },
        ...mapGetters(CLIENTDATA, {isElectron: GET_IS_ELECTRON}),
        ...mapGetters(VIDEO_CONF_NEW, {
            minimize: IS_VC_MINIMIZED,
            videoConfFullScreen: IS_VC_IN_FULLSCREEN,
        }),
        ...mapGetters(VIDEO_CONF_NEW, [
            GET_VC_CONF,
            GET_VC_CONF_STATUS,
            GET_VC_CONF_ERROR_TEXT,
            GET_VC_CONF_MEMBERS,
            IS_VC_CONF_OWNER,
            GET_VC_CONF_SHARE,
            IS_VC_OWN_SHARE,
            IS_VC_BANNED,
            IS_VC_CONF_MODERATOR,
            GET_VC_CONF_DIALOG_OPENED,
        ]),
        ...mapGetters(PHONE_CNTL, {
            [GET_CALL_TALK_TIME]: GET_CALL_TALK_TIME,
        }),
    },
    methods: {
        toggleInfo(e, position, options = {}) {
            if (!this.btsShow) return
            if (this.infoOpened) return this.infoOpened = false
            this.infoOpened = true
            const defOptions = {
                theme: 'videoconf',
                component: {
                    vc: videoConfInfo,
                    props: this.confIdActionData,
                },
                onClose: () => this.infoOpened = false,
            }
            this.cmOpen(e, undefined, position, {...defOptions, ...options})
        },
        toggleSchemeSelect(e, position, options = {}) {
            if (!this.btsShow) return
            if (this.schemeSelect) return this.schemeSelect = false
            this.schemeSelect = true
            const defOptions = {
                theme: 'videoconf',
                component: {
                    vc: videoConfSchemeSelect,
                    props: { share: this.showShare, cb: this.setSchema },
                },
                onClose: () => this.schemeSelect = false,
            }
            this.cmOpen(e, undefined, position, { ...defOptions, ...options })
        },
        toggleExit(e, position, options = {}) {
            if (!this.isActive) {
                this[ACT_VC_CONF_LEAVE](this.confIdActionData)
                this.$emit('close')
                return
            }
            if (this.exitOpen) return this.exitOpen = false
            this.exitOpen = true
            let handlers = []
            let open = this.cmOpen

            let canFinish = false
            if (this.type === CONF_TYPES.VM) canFinish = this.owner
            if (this.type === CONF_TYPES.ROSCHAT) canFinish = this.owner || this.isModerator
            if (canFinish) handlers.push({
                item_name: this.$t('videomost.conf-finish'), handler: () => {
                    this[ACT_VC_CONF_FINISH](this.confIdActionData)
                    this.$emit('close')
                },
            })
            handlers.push({
                item_name: this.$t('videomost.conf-exit'), handler: () => {
                    this[ACT_VC_CONF_LEAVE](this.confIdActionData)
                    this.$emit('close')
                },
            })
            const defOptions = { theme: 'videoconf' }
            open(e, handlers, position, { ...defOptions, ...options })
        },
        toggleWantSpeck(e, position, options = {}) {
            let handlers = []
            let open = this.cmOpen

            handlers.push({item_name: this.$t('videomost.room-action.let-me-speak'), handler: () => this[ACT_VC_CONF_WANT_SPEAK]({...this.confIdActionData, value: true}) })
            const defOptions = { theme: 'videoconf' }
            open(e, handlers, position, { ...defOptions, ...options })
        },
        toggleCamList(e, position, options = {}) {
            if (this.camListOpened) return this.camListOpened = false
            this.camListOpened = true
            const defOptions = {
                theme: 'videoconf',
                component: { vc: videoConfCamSelect, props: {
                        ...this.confIdActionData,
                        opened: this.camListOpened,
                    }},
                onClose: () => this.camListOpened = false,
            }
            this.cmOpen(e, undefined, position, { ...defOptions, ...options })
        },
        toggleMicList(e, position, options = {}) {
            if (this.micListOpened) return this.micListOpened = false
            this.micListOpened = true
            const defOptions = {
                theme: 'videoconf',
                component: {vc: videoConfMicSelect, props: {
                        ...this.confIdActionData,
                        opened: this.micListOpened,
                    }},
                onClose: () => this.micListOpened = false,
            }
            this.cmOpen(e, undefined, position, { ...defOptions, ...options })
        },
        toggleReactionSelect(e, position, options = {}) {
            if (!this.btsShow) return
            if (this.reactionSelect) return this.reactionSelect = false
            this.reactionSelect = true
            const defOptions = {
                theme: 'videoconf',
                component: {
                    vc: videoConfReactions,
                    props: this.confIdActionData,
                },
                onClose: () => this.reactionSelect = false,
            }
            this.cmOpen(e, undefined, position, { ...defOptions, ...options })
        },
        setSchema(schema) {
            this.schema = schema
        },
        membersOpen(val = null) {
            if (!this.btsShow || this.membersOpened) return
            if (val === null) {
                this.membersListOpened = !this.membersListOpened
            } else {
                this.membersListOpened = !!val
            }
        },
        onShare() {
            if (this.shareDisabled) return
            this[ACT_VC_CONF_SHARE](this.confIdActionData)
        },
        onVideoCompReady() {

        },
        doMenu(event, member, position, options = {}) {
            if (!this.isModerator) return
            let handlers = []
            let open = this.cmOpen

            if (member[MEMBER_PROPS.MODERATOR]) handlers.push({item_name: this.$t('videomost.member.set-not-moderator'), handler: () =>
                    this[ACT_VC_CONF_SET_MEMBER_MODERATOR]({...this.confIdActionData, memberId: member.id, value: false})})
            if (!member[MEMBER_PROPS.MODERATOR]) handlers.push({item_name: this.$t('videomost.member.set-moderator'), handler: () =>
                    this[ACT_VC_CONF_SET_MEMBER_MODERATOR]({...this.confIdActionData, memberId: member.id, value: true})})
            if (member[MEMBER_PROPS.MICROPHONE_GLOBAL]) handlers.push({item_name: this.$t('videomost.member.set-not-microphone'), handler: () =>
                    this[ACT_VC_CONF_SET_MEMBER_MIC]({...this.confIdActionData, memberId: member.id, value: false})})
            if (!member[MEMBER_PROPS.MICROPHONE_GLOBAL]) handlers.push({item_name: this.$t('videomost.member.set-microphone'), handler: () =>
                    this[ACT_VC_CONF_SET_MEMBER_MIC]({...this.confIdActionData, memberId: member.id, value: true})})
            if (member[MEMBER_PROPS.CAMERA_GLOBAL]) handlers.push({item_name: this.$t('videomost.member.set-not-video'), handler: () =>
                    this[ACT_VC_CONF_SET_MEMBER_VIDEO]({...this.confIdActionData, memberId: member.id, value: false})})
            if (!member[MEMBER_PROPS.CAMERA_GLOBAL]) handlers.push({item_name: this.$t('videomost.member.set-video'), handler: () =>
                    this[ACT_VC_CONF_SET_MEMBER_VIDEO]({...this.confIdActionData, memberId: member.id, value: true})})
            if (!member[MEMBER_PROPS.BANNED]) handlers.push({item_name: this.$t('videomost.member.set-temp-ban'), handler: () =>
                    this[ACT_VC_CONF_BAN_MEMBER]({...this.confIdActionData, memberId: member.id, value: true})})
            if (member[MEMBER_PROPS.BANNED]) handlers.push({item_name: this.$t('videomost.member.set-un-ban'), handler: () =>
                    this[ACT_VC_CONF_BAN_MEMBER]({...this.confIdActionData, memberId: member.id, value: false})})
            handlers.push({item_name: this.$t('videomost.member.remove-participant'), handler: () =>
                    this[ACT_VC_CONF_KICK_OUT_MEMBER]({...this.confIdActionData, memberId: member.id})})
            open(event, handlers, position, options);
        },
        convertWindowToView(view = 'default') {
            this.electronWinTransformation = true
            ipc.send('convert-window-to-view', view)
        },
        onPageUnload() {
            this[ACT_VC_CONF_LEAVE](this.confIdActionData)
        },
        ...mapActions(VIDEO_CONF_NEW, [
            ACT_VC_CONF_CAMERA_TOGGLE,
            ACT_VC_CONF_MICROPHONE_TOGGLE,
            ACT_VC_CONF_SPEAKER_TOGGLE,
            ACT_VC_CONF_WANT_SPEAK,
            ACT_VC_CONF_LEAVE,
            ACT_VC_CONF_FINISH,
            ACT_VC_CONF_SHARE,
            ACT_VC_CONF_OPEN_DIALOG,

            ACT_VC_CONF_SET_MEMBER_MODERATOR,
            ACT_VC_CONF_SET_MEMBER_MIC,
            ACT_VC_CONF_SET_MEMBER_VIDEO,
            ACT_VC_CONF_BAN_MEMBER,
            ACT_VC_CONF_KICK_OUT_MEMBER,
        ]),
        ...mapActions(PHONE_CNTL, [ACT_SET_CALL_STATUS]),
    },
}
