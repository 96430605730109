'use strict'

import Logger from "./common/Logger"
import io from 'socket.io-client'
import EventEmitter from "./common/EventEmitter"

class RadioProtocol extends EventEmitter {
    constructor() {
        super()
        this.logger = new Logger('radioproto')
        const log = (str) => { this.logger.info(str) }

        let self = this, socket = null
        self.isConnected = false
        let reconnect = true
        
        self.disconnect = () => new Promise((resolve, reject) => {
            reconnect = false
            if (socket) {
                socket.close()
                log('Radio socket disconnected')
                self.isConnected = false
            }
            setTimeout(resolve, 0)
        })
        
        const isConnected = () => { return self.isConnected }

        self.connect = (server) => { 
            return new Promise(async (resolve, reject) => {
                if (self.isConnected) await self.disconnect()
                server = `https://${server}`
                socket = io.connect(server, {
                    reconnection: false,
                    transports: ['websocket']
                })
                
                self.socket = socket
                self.isConnected = true

                socket.on('connect', () => {
                    reconnect = true
                    log('radio server connect: connected to ' + server)
                    resolve(socket.id)
                    self.emit('connect', socket)
                })

                socket.on('disconnect', (e) => {
                    log('radio server disconnect: disconnected from server ' + server)
                    self.emit('disconnect', reconnect)
                    reject(e)
                })
                socket.on('connect_error', (e) => {
                    log('radio server connect: error ' + e)
                    self.emit('connect_error', reconnect)
                    reject(e)
                })
    
                socket.on('radio-status-event', (data) => {
                    log('bind: < radio-status-event: ' + JSON.stringify(data))
                    self.emit('radio-status-event', data)
                })
    
                socket.on('radio-leave-event', (data) => {
                    log('bind: < radio-leave-event: ' + JSON.stringify(data))
                    self.emit('radio-leave-event', data)
                })
    
                socket.on('radio-grant-voice-event', (data) => {
                    log('bind: < radio-grant-voice-event: ' + JSON.stringify(data))
                    self.emit('radio-grant-voice-event', data)
                })
    
                socket.on('radio-stop-voice-event', (data) => {
                    // log('bind: < radio-stop-voice-event: ' + JSON.stringify(data))
                    self.emit('radio-stop-voice-event', data)
                })
    
                socket.on('radio-voice-event', (data) => {
                    // log('bind: < radio-voice-event: ' + JSON.stringify(data))
                    self.emit('radio-voice-event', data)
                })
    
                socket.on('radio-disconnected-event', (data) => {
                    log('bind: < radio-disconnected-event: ' + JSON.stringify(data))
                    self.emit('radio-disconnected-event', data)
                })
    
                socket.on('radio-message-event', (data) => {
                    log('bind: < radio-message-event: ' + JSON.stringify(data))
                    self.emit('radio-message-event', data)
                })

                socket.on('tetra-radio-status-change-event', (data) => {
                    log('bind: < tetra-radio-status-change-event: ' + JSON.stringify(data))
                    self.emit('tetra-radio-status-change-event', data)
                })
    
                socket.on('tetra-radio-message-event', (data) => {
                    log('bind: < tetra-radio-message-event: ' + JSON.stringify(data))
                    self.emit('tetra-radio-message-event', data)
                })
            })
        }

        self.radioConnect = (params) => new Promise((resolve, reject) => {
            socket.emit('radio-connect', params, result => {
                if (result && result.hasOwnProperty('error')) {
                    log("!! radioprotocol.js radio-connect error:", result.error)
                    reject(result.error)
                }
                resolve()
            })
        })

        self.radioGetStatus = (params) => new Promise((resolve, reject) => {
            try {
                socket.emit('radio-get-status', params, (result) => {
                    resolve(result)
                })  
            } catch(e) {
                log("!! radioprotocol.js self.radioGetStatus error: ", e)
                reject(e)
            }
        })

        self.radioDisconnect = (params = {}) => new Promise((resolve, reject) => {
            try {
                socket.emit('radio-disconnect', params, (result) => {
                    if (result && result.error) {
                        log(`radio-disconnect error: < ${result.error}`)
                        return reject(result.error)
                    }
                    resolve()
                })
            } catch (e) {
                reject(e)
            }
        })

        self.radioRequestVoice = (params) => new Promise((resolve, reject) => {
            //log('radio-request-voice: > ' + JSON.stringify(params))
            socket.emit('radio-request-voice', params, (result) => {
                if (result && result.error) {
                    log(`radio-request-voice: < failed ${result.error}`)
                    return reject(result.error)
                }
                resolve()
            })
        })

        self.radioStopVoice = (params) => new Promise((resolve, reject) => {
            //log('radio-stop-voice: > ' + JSON.stringify(params))
            socket.emit('radio-stop-voice', params, (result) => {
                if (result && result.error) {
                    log(`radio-stop-voice: < failed ${result.error}`)
                    return reject(result.error)
                }
                resolve()
            })
        })

        self.radioSendVoice = (params) => {
            //log('radio-send-voice: > ' + JSON.stringify(params))
            socket.emit('radio-send-voice', params, (result) => {
                if (result && result.error) {
                    log(`radio-send-voice: < failed ${result.error}`)
                }
            })
        }

        self.tetraRadioConnect = (params) => new Promise((resolve, reject) => {
            //log('tetra-radio-connect: > ' + JSON.stringify(params))
            socket.emit('tetra-radio-connect', params, (result) => {
                if (result.error || !result.status) {
                    log(`tetra-radio-connect: < failed ${result.error}`)
                    return reject(result.error)
                }
                resolve(result.status)
            })
        })

        self.tetraRadioDisconnect = (params = {}) => new Promise((resolve, reject) => {
            //log('tetra-radio-disconnect: > ' + JSON.stringify(params))
            if (self.isConnected) {
                socket.emit('tetra-radio-disconnect', params, (result) => {
                    if (result && result.error) {
                        log(`tetra-radio-disconnect: < failed ${result.error}`)
                        return reject(result.error)
                    }
                    resolve()
                })
            } else resolve()
        })

        self.tetraRadioGetStatus = (params) => new Promise((resolve, reject) => {
            //log('tetra-radio-get-status: > ' + JSON.stringify(params))
            socket.emit('tetra-radio-get-status', params, (result) => {
                if (!result.status) {
                    log(`tetra-radio-get-status: < failed`)
                    return reject()
                }
                resolve(result.status)
            })
        })

        self.tetraRadioSendCmd = (params) => {
            //log('tetra-radio-send-cmd: > ' + JSON.stringify(params))
            socket.emit('tetra-radio-send-cmd', params)
        }
    }
}

const radioproto = new RadioProtocol()

export default radioproto