//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';
import {mapGetters, mapActions} from 'vuex';
import {GET_CHANNEL, GET_MERGED_CONTACT_BY_ID} from '../../store/gettersTypes';
import {ACT_GET_CHANNEL_DETAILS, ACT_MODAL_OPEN } from '../../store/actionsTypes';
import {CHANNELS, MODAL, CONTACTS} from '../../store/modulesNames';
import { i18n } from '../../../ext/i18n';
import checkMessageDataExist from "./checkMessageDataExist"

export default {
    name: 'chat-publication-message',
    props: ['message'],
    mixins: [checkMessageDataExist],
    computed: {
        id() { return this.message.id },
        msg() { return this.message.msg },
        channel() { return this[GET_CHANNEL]({chId: this.msg.chId}) },
        name() { return this.channel && this.channel.name || i18n.messages[i18n.locale].chat['unknown-channel'] },
        photo() { return this.channel && this.channel.icon && app.getChannelPhotoUrl(this.channel.icon) || '' },
        titlePublication() {
            if(this.msg.text && this.msg.text.title) {
                return this.msg.text.title.replace(/</g,'&lt;').replace(/>/g,'&gt;')
            }
            else if(this.msg.text && this.msg.text.info) { 
                return this.msg.text.info.replace(/</g,'&lt;').replace(/>/g,'&gt;')
            }
            else return i18n.messages[i18n.locale].chat['go-to-publ'];
        },
        ...mapGetters(CHANNELS, [GET_CHANNEL]),
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID])
    },
    methods: {
        async showPublication() {
            this.checkPublicationExist(this.msg.chId, this.msg.pubId)
        },
        fioById(cid) {
            return this[GET_MERGED_CONTACT_BY_ID](cid).fio || null
        },
        ...mapActions(CHANNELS, [ACT_GET_CHANNEL_DETAILS]),
        ...mapActions(MODAL, [ACT_MODAL_OPEN])
    },
    created() {
        if (!this.channel) this[ACT_GET_CHANNEL_DETAILS](this.msg.chId)
    }
}
