//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '../../../ext/i18n';
import modal_mixin from "./modal-mixin"
import ModalBtn from "./ModalBtn.vue"
import IconImage from "../IconImage.vue"
import CustomCheckboxRound from "../../components/custom/CustomCheckboxRound.vue"
const R = require('ramda')

export default {
    name: "SendContactsWithFields",
    components: { ModalBtn, IconImage, CustomCheckboxRound },
    mixins: [modal_mixin],
    props: {
        contact: {
            type: Object,
            required: true,
            default: null
        },
        isLocal: {
            type: Boolean,
            required: false
        },
        isPhotoGlobal: {
            type: Boolean,
            required: false
        },
        isPhotoGlobalOk: {
            type: Boolean,
            required: false
        }
    },
    data: () => ({
        defChbValue: true,
        removedPhoto: false,
        compModalProps: {
            pivotY: 0.39,
            width: 390,
            height: 'auto',
            adaptive: true,
            styles: "min-width: 300px;"
        },
    }),
    computed: {
        title() { return this.$t('chat.contact-send') },
        fieldsArr() {
            if (!this.contact) return []
            const filteredFields = this.contact.fields
            filteredFields.map(field => { field.checked = this.defChbValue })
            return filteredFields
        },
        globalPhoto() {
            let sendContactTest = this.contact
            console.log("🚀 ~ file: SendContactWithFields.vue:72 ~ globalPhoto ~ sendContactTest:", sendContactTest)
            if (this.contact && this.contact.remoteCid) return this.contact.photo
            return this.$store.getters['userdata/getPhotoUrlbyCid'](this.contact.cid)
        },
        photoSrc() {
            let src = this.contact.photo 
            if (this.contact && this.contact.remoteCid) return src
            if (this.removedPhoto && this.isLocal && this.isPhotoGlobalOk) src = this.globalPhoto
            if (this.removedPhoto && this.isLocal && !this.isPhotoGlobalOk) src = false
            if (this.removedPhoto && !this.isLocal) src = this.globalPhoto
            return src
        },
        isPhotoRemovable() {
            return Boolean(!this.removedPhoto && !this.isPhotoGlobal && this.photoSrc !== this.globalPhoto)
        }
    },
    methods: {
        onChbChecked(field, val) {
            field.checked = val;
            val ? field.elementClass='active-element' : field.elementClass='inactive-element';
        },
        cancel() {
            this.modalClose()
        },
        getTranslation(str) {
            let translate = i18n.messages[i18n.locale]
            return translate.information[str]
        },
        getSubLabel(text) {
            return this.getTranslation(text)
        },
        send() {
            this.modalClose()
            const chosenFields = R.clone(this.contact.fields.filter(f => f.checked))
            let fields = chosenFields.map(({checked, ...rest}) => rest)
            let withPhoto = this.photoSrc && this.photoSrc !== this.globalPhoto
            if (this.photoSrc && this.contact && this.contact.remoteCid) withPhoto = true
            this.props.cb && this.props.cb({fields, withPhoto})
        },
        removePhoto() {
            this.removedPhoto = true
        },
        rollbackPhoto() {
            this.removedPhoto = false
        }

    }
}
