//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';
import {ACS, LOCATIONS} from "../../store/modulesNames";
import {GET_IN_ACS, GET_ACS_TYPE, GET_ACS_INFO, GET_LOCATIONS_REGION, GET_LOCATION, GET_ACS_CURRENT_JOURNAL_ENTRY} from '../../store/gettersTypes';
import store from '../../store/main/store';
import acsEntrances from './AcsEntrances.vue';
import AcsAbsenceReason from './AcsAbsenceReason.vue';
import { i18n } from '../../../ext/i18n'
import moment from 'moment'
import { isEmpty } from 'ramda'

export default {
    name: 'acs-status',
    store,
    data() {
        return {
            loaded: false,
            entrancesBtn: false,
            showEntrances: false
        };
    },
    components: {acsEntrances, AcsAbsenceReason},
    props: {
        contact_id: {
            type: Number,
            required: true
        },
        link: {
            type: Boolean,
            default: true,
            required: false
        },
        loading: {
            type: Boolean,
            required: true
        },
    },
    computed: {
        show_link() {
            return !!this.link;
        },
        showAcsStatus() {
           return !(this.loading || (!this.show_link && this.currentJournal.status && !(this.acsInfo.locations && this.acsInfo.locations.length || this.locationStatus.statusTime)));
        },
       //  acs_contacts() { //@todo заменить в тесте и убрать
       //      return this.$store.getters['acs/getContacts'] || [];
       //  },
        acsEnabled() {
           return this[GET_IN_ACS](this.contact_id);
        },
        acsInfo() {
            return this[GET_ACS_INFO](this.contact_id);
        },
        currentJournal() {
            return this[GET_ACS_CURRENT_JOURNAL_ENTRY](this.contact_id) || {};
        },
        currentJournalIsEmpty() {
            return isEmpty(this.currentJournal);
        },
        acsStatus() {
            let status = false,
                time = 0,
                name;
            let acsInfo = this.acsInfo;
            
            if (!acsInfo || this.loading) {
                return {};
            }
            const {locations} = acsInfo;
            const internalLocation = this[GET_LOCATION](this.contact_id);
            if (!!locations && !!locations.length) {
                let lastLocation = locations[0] || {}
                let acsIn = lastLocation.in
                let locationIn = internalLocation.status === 'in'
                let acsTime = lastLocation.time || 0
                let locationTime = internalLocation.statusTime || 0
                status = acsIn ? 'enter' : 'leave'
                if (acsIn === locationIn) {
                    time = Math.max(acsTime, locationTime)
                } else if (acsIn) {
                    time = acsTime
                } else if (locationIn) {
                    time = locationTime
                }
                name = lastLocation.name;
            }
            switch (status) {
                case false:
                case undefined:
                case null:
                    status = 'out';
                    break;
            }
            return {status,time,name};
        },
        locationStatus() {
            let result = {};
            let location = this[GET_LOCATION](this.contact_id);
            let region = this[GET_LOCATIONS_REGION](location && (location.beaconRid || location.locationRid));
            if(location && region && !this.loading) {
                if('beaconRid' in location || 'locationRid' in location) {
                    result.name = region.title
                    result.status = location.status;
                    result.statusTime = location.statusTime;
                }
                else if(('locationRid' in location) && location.locationDistance < 3000) {
                    result.name = region.title
                    result.status = location.status;
                    result.statusTime = location.statusTime;
                }
            } else {
                result.status = 'out';
            }
            return result;
        },
        location() {
            let result = {} // {type, name, status}
            let acs = this.acsStatus;
            let location = this.locationStatus;
            result.type = ''
            result.show_link = this.show_link
            result.currentJournal = this.currentJournal;
            if (['enter', 'leave'].includes(acs.status)) {
                result.status = acs.status;
                result.name = acs.name;
                result.time = acs.time;
                if (acs.status === 'enter' || (!this.currentJournalIsEmpty && this.show_link)) result.colorAccent = true;
            }
            if (acs.status === 'out') {
                switch (location.status) {
                    case 'in':
                        result.colorAccent = true;
                        result.currentJournal = {};
                    case 'out':
                        result.colorAccent = result.colorAccent || !this.currentJournalIsEmpty && this.show_link;
                        result.status = location.status;
                        result.name = location.name;
                        result.time = location.statusTime;
                        break;
                    case 'not_far': // to be deleted
                        result.status = 'out';
                        break;
                }
            }
            if (acs.status === 'leave') {
                switch (location.status) {
                    case 'in':
                        result.status = 'enter';
                        result.name = location.name;
                        result.colorAccent = true;
                        break;
                    case 'not_far':  // to be deleted
                        result.status = 'out';
                        break;
                    case 'out':
                        result.status = acs.status;
                        break;
                }
            }
            if (!acs.status) {
                result.status = (location.status || 'out');
                // result.name = 'unknown';
                // debugger;
            }
            return result;
        },
        entrancesBtnShow() {
            let { locations } = this.acsInfo;
            return Boolean(locations && locations.length &&
                !(this.locationStatus.statusTime && this.acsStatus.time === this.locationStatus.statusTime));
        },
        ...mapGetters(ACS, {[GET_IN_ACS]: GET_IN_ACS, acs_type: GET_ACS_TYPE, [GET_ACS_INFO]: GET_ACS_INFO,
            [GET_ACS_CURRENT_JOURNAL_ENTRY]: GET_ACS_CURRENT_JOURNAL_ENTRY}),
        ...mapGetters(LOCATIONS, {[GET_LOCATIONS_REGION]: GET_LOCATIONS_REGION, [GET_LOCATION]: GET_LOCATION})
    },
    methods: {
        doSwitchToOmJournal() {
            if (!this.show_link) return;
            this.$store.dispatch('info/infoPush', {type: 'acs-journal', params: {cid: this.contact_id}})
        },
        entrancesToggle() {
            this.showEntrances = !this.showEntrances
        }
    },
    watch: {
        loaded(val) {
            val && this.$emit('loaded');
        }
    },
    /*async created() {
        if (this.acsEnabled) {
            this.om = true;

            await this.getAcs();
        } else {
            this.om = false;
        }
    },*/
    filters: {
        acsTypeResolve(val) {
            let main = i18n.messages[i18n.locale].mainPage
            return main.location;
            // let res;
               // switch(val) {
               //     case 'om':
               //         res = 'Местоположение';
               //         break;
               //     case 'orion':
               //         res = 'Орион';
               //         break;
               //     case 'none':
               //         res = null;
               //         break;
               //     default:
               //         res = res+'';
               // }
            // return res;
        },
        timeToFormat(location) {
            let val = {...{}, ...location}
            if (val.time) {
                const DATE_FORMAT = "DD.MM.YYYY",
                    TIME_FORMAT = "HH:mm",
                    DATE_TIME_FORMAT = DATE_FORMAT + " " + TIME_FORMAT,
                    FORMAT = date_helper.isToday(val.time) ? TIME_FORMAT : DATE_TIME_FORMAT;
                val.time = moment.unix(val.time).format(FORMAT);
            }
            return {...{}, ...val};
        },
        locationToStatusText(val) {
            let res;
            let acs = i18n.messages[i18n.locale].acs
            switch (val && ((val.show_link && val.status !== 'enter' && val.currentJournal && val.currentJournal.status) || val.status)) {
                case 'meeting':
                    res = acs.meeting;
                    break;
                case 'business':
                    res = acs.business;
                    break;
                case 'work-home':
                    res = acs['work-home'];
                    break;
                case 'education':
                    res = acs.education;
                    break;
                case 'vacation':
                    res = acs.vacation;
                    break;
                case 'compensatory':
                    res = acs.dayoff;
                    break;
                case 'sick':
                    res = acs.sick;
                    break;
                case 'other':
                    res = acs.other;
                    break;
                case 'in':
                case 'enter':
                    res = val.name ? val.name : acs.in;
                    break;
                case 'not_far':
                case 'near':
                    res = val.name;
                    break;
                case 'out':
                case 'leave':
                    res = acs.out;
                    break;
            }
            return res;
        },
        locationToRightText(val) {
            let res = '';
            let acs = i18n.messages[i18n.locale].acs
            switch (val && val.status) {
                case 'in':
                    res = `${acs.in}\n${val.time}`;
                    break;
                case 'not_far':
                    res = val.distance + '\n' + acs['from-office'];
                    break;
                case 'near':
                    res = acs.near + '\n' + acs['to-office'];
                    break;
                case 'leave':
                case 'enter':
                    res = `${val.status === 'enter' ? acs.enter : acs.leave}\n${val.time}`;
                    break;
                case 'out':
                    res = val.time ? `${acs.leave}\n${val.time}` : '';
                    break
            }
            return res;
        }
    }
}
