//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

 import {mapGetters} from 'vuex';
 import {GET_ACS_TYPE, GET_IS_ELECTRON, GET_ASSISTANTS, GET_USER_PARAMS} from '../../../store/gettersTypes'
 import {ACS, CLIENTDATA, ASISTANTS, USERDATA} from '../../../store/modulesNames'
 import GeneralSettings from "../../settings/GeneralSettings.vue"
 import ChatSettings from "../../settings/ChatSettings.vue"
 import LanguageSettings from '../../settings/LanguageSettings.vue'
 import SelectAssistant from '../../settings/SelectAssistant.vue'
 import NotificationSettings from "../../settings/NotificationSettings.vue"
 import Theme from "../../settings/Theme.vue"
 import HotKeys from "../../settings/HotKeys.vue"
 import Integrations from "../../settings/Integrations.vue"

 import {ACS_TYPES, USER_PARAMS_PROPS} from '../../../constants'

export default {
    name: "MainContentSettings",
    data() {
        return {
            ready: 0
        };
    },
    components: {
        GeneralSettings,
        ChatSettings,
        NotificationSettings,
        Theme,
        LanguageSettings,
        SelectAssistant,
        HotKeys,
        Integrations,
    },
    computed: {
        allIsReady() {
            return this.ready === 0;
        },
        om_settings() {
            return this[GET_ACS_TYPE] === ACS_TYPES.OM;
        },
        showIntegrations() {
            return Boolean(this[GET_USER_PARAMS][USER_PARAMS_PROPS.SCHEDULE_TYPES])
        },
        ...mapGetters(ACS, [GET_ACS_TYPE]),
        ...mapGetters(CLIENTDATA, {
            isElectron: GET_IS_ELECTRON
        }),
        ...mapGetters(ASISTANTS, {
            assistants: GET_ASSISTANTS
        }),
        ...mapGetters(USERDATA, [GET_USER_PARAMS]),
    },
    methods: {
        compCreated() {
            this.ready += 1;
        },
        setReady() {
            this.ready -= 1;
        }
    }
};
