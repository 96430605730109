//
//
//
//
//
//
//
//
//
//
//
//
//

import ProgressCircle from '../ProgressCircle.vue';
import IconImage from '../IconImage.vue';
import PublicationEditableItemText from "./PublicationEditableItemText.vue"
import PublicationFilesMixin from './PublicationFilesMixin';

export default {
    name: "PublicationDocumentItem",
    components: {"progress-circle": ProgressCircle, IconImage, PublicationEditableItemText},
    mixins: [PublicationFilesMixin],
    props: {
        data: {
            type: Object,
            required: true
        },
        edit: {
            type: Boolean,
            default: false
        },
        unixLastTime: {
            type: Number,
            default: 0,
            required: false
        }
    },
    data() {
      return {
          name: this.data.name
      }
    },
    methods: {
        async doDownloadDocumentFile() {
            if (this.link) {
                this.download()
            } else {
                await this.loadFile({ file: this.data.file, size: this.data.size })
                this.$nextTick(() => this.download())
            }
        },
        download() {
            const link = this.$refs.downloadLink;
            link.download = this.getDownloadFileName(declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE, this.data.name, this.data.extension);
            link.click();
        }
    },
    watch: {
        unixLastTime() {
            this.name = this.data.name;
        },
        name(value) {
            this.$emit('update', 'name', value);
        }
    }
}
