//
//
//
//
//
//

export default {
    name: "RadioStatus",
    props: {
        title: {
            type: [String,Number],
            default: ''
        },
        subTitle: {
            type: [String,Number],
            default: ''
        }
    }
}
