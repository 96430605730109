//
//
//
//
//
//
//
//
//
//
//
//

'use strict';

export default {
    name: 'custom-select',
    props: {
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            required: false
        },
        label: {
            type: String,
            required: false
        },
        showClearSign: {
            type: Boolean,
            required: false
        },
        readonly: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            input_required: false,
            selected_option: null,
            up: false,
            //inited: false,
        }
    },
    mounted() {
        for (let i in this.options) {
                if (this.options[i].selected) this.selected_option = this.options[i].value;
        }
        //this.$nextTick(() => this.inited = true)
    },
    watch: {
        options: {
            handler(options) {
                let { value } = options.find((option) => option.selected)
                if (this.selected_option !== value) this.selected_option = value
            },
            deep: true
        },
        /*options() {
            for (let i in this.options) {
                if (this.options[i].selected) this.selected_option = this.options[i].value;
            }
        },*/
        selected_option(val) {
            if (!!val) this.input_required = false
            this.$emit('update:selected_option', val)
            //if (this.inited) this.$emit('update:selected_option', val)
        }
    },
    computed: {
        isDisabled() {
            return this.readonly || !this.options.length;
        },
        isPlaceHolderShown() {
            return !this.selected_option || this.isDisabled;
        }
    },
    methods: {
        reset() {
            this.selected_option = null;
            this.up = false;
            this.input_required = false;
        },
        // openSelect() {
        //     const el = document.querySelector('.custom-select');
        //     el.click();
        // },
        // doClick() {
        //     const el = document.querySelector('.custom-select');
        //     this.up = true;
        //     this.selected_option = 'selected';
        //     el.dropdown();
        // },
        doSelect() {
            const el = document.querySelector('.custom-select');
            this.input_required = false;
            this.up = true;
            // this.selected_option = null;
            // el.size = 0;
        },
        doClear() {
            const el = document.querySelector('.select-label+.custom-select');
            this.up = false;
            this.$emit('update:selected_option', this.selected_option);
            this.selected_option = null;
        }
    }
}
