//
//
//
//
//

export default {
    name: "ActivityBase",
    computed: {
        component() {
            return null
        },
        bindProps() {
            return {}
        }
    },
}
