var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-alarm" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.alarm_msg,
            expression: "alarm_msg"
          }
        ],
        staticClass: "top-alarm"
      },
      [
        _c("div", { staticClass: "top-alarm-wrapper" }, [
          _c(
            "div",
            { staticClass: "top-alarm-msg", attrs: { title: "alarm_msg" } },
            [_vm._v(_vm._s(_vm.alarm_msg))]
          )
        ]),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }