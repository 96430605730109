//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { VIDEO_CONF_NEW } from '../../store/modulesNames'
import {
    GET_VC_CONF_CAM_STATUS, GET_VC_CONF_MEDIA_DEVICE_BY_KIND,
    GET_VC_CONF_MIC_STATUS,
} from '../../store/gettersTypes'
import {
    ACT_VC_CONF_CAMERA_TOGGLE,
    ACT_VC_CONF_MICROPHONE_TOGGLE,
    ACT_VC_CONF_SHARE,
} from '../../store/actionsTypes'

import videoConfSectionMixin from './videoConfSectionMixin'
import ConfBtn from './confBtn.vue'

import { CAM_STATUS, MIC_STATUS } from '../../store/modules/video-conf-abstract'
import { CONFERENCE_SCHEMAS, MEDIA_DEVICES } from '../../constants'

export default {
    name: 'videoConfSectionFooter',
    mixins: [videoConfSectionMixin],
    components: {
        ConfBtn,
    },
    data() {
        return {
            camListOpened: false,
            micListOpened: false,
            reactionSelect: false,
        }
    },
    computed: {
        show() {
            return this.schema !== CONFERENCE_SCHEMAS.MY_SHARE
        },
        camera() {
            return this.confIdGetterData && this[GET_VC_CONF_CAM_STATUS](...this.confIdGetterData)
        },
        cameraIsOn() {
            return this.camera === CAM_STATUS.ENABLE
        },
        cameraClasses() {
            return 'fal ' + (this.cameraIsOn ? 'fa-video' : 'fa-video-slash')
        },
        cameraDisabled() {
            return !this.btsShow || this.camera === CAM_STATUS.MUTED || this.noCamDevice
        },
        noCamDevice() {
            return !this[GET_VC_CONF_MEDIA_DEVICE_BY_KIND](...this.confIdGetterData, MEDIA_DEVICES.VIDEO_INPUT)
        },
        microphone() {
            return this.confIdGetterData && this[GET_VC_CONF_MIC_STATUS](...this.confIdGetterData)
        },
        microphoneIsOn() {
            return this.microphone === MIC_STATUS.ENABLE
        },
        microphoneClasses() {
            return 'fal ' + (this.microphoneIsOn ? 'fa-microphone' : 'fa-microphone-slash')
        },
        microphoneDisabled() {
            return !this.btsShow || this.microphone === MIC_STATUS.MUTED || this.noMicDevice
        },
        noMicDevice() {
            return !this[GET_VC_CONF_MEDIA_DEVICE_BY_KIND](...this.confIdGetterData, MEDIA_DEVICES.AUDIO_INPUT)
        },
        shareDisabled() {
            return !this.btsShow || (this.share && !this.ownShare)
        },
        ...mapGetters(VIDEO_CONF_NEW, [
            GET_VC_CONF_CAM_STATUS,
            GET_VC_CONF_MIC_STATUS,
            GET_VC_CONF_MEDIA_DEVICE_BY_KIND,
        ]),
    },
    methods: {
        cameraToggle(e) {
            if (!this.cameraDisabled) {
                this[ACT_VC_CONF_CAMERA_TOGGLE](this.confIdActionData)
            } else if (this.camera === CAM_STATUS.MUTED) {
                this.toggleWantSpeck(e)
            }
        },
        microphoneToggle(e) {
            if (!this.microphoneDisabled) {
                this[ACT_VC_CONF_MICROPHONE_TOGGLE](this.confIdActionData)
            } else if (this.microphone === MIC_STATUS.MUTED) {
                this.toggleWantSpeck(e)
            }
        },
        toggleWantSpeck(e) {
            this.$emit('toggle-want-speck', e, 'top-right', { offsetY: 15 })
        },
        toggleCamList(e) {
            this.$emit('toggle-cam-list', e, 'top-right', { offsetY: 10 })
        },
        showMicList(e) {
            this.$emit('toggle-mic-list', e, 'top-right', { offsetY: 10 })
        },
        toggleReactionSelect(e) {
            this.$emit('toggle-reaction-select', e, 'top-left', { offsetY: 15 })
        },
        onShare() {
            if (this.shareDisabled) return
            this[ACT_VC_CONF_SHARE](this.confIdActionData)
        },
        ...mapActions(VIDEO_CONF_NEW, [
            ACT_VC_CONF_CAMERA_TOGGLE,
            ACT_VC_CONF_MICROPHONE_TOGGLE,
            ACT_VC_CONF_SHARE,
        ])
    },
}
