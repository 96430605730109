import event_bus from './eventBus'
import store from './store/main/store'
import moment from 'moment'
import { CLIENTDATA, USERDATA } from './store/modulesNames'
import { ACT_SET_CONTACT_STATUS, ACT_SET_LAST_TIME_ACTIVE } from './store/actionsTypes'
const mouseMoveTimeout = 600000 // milliseconds
const windowFocusedTimeout = 20000 // milliseconds

class UserActivityMonitor {
    constructor () {
        this.focused = false
        this.online = false
        this.timeout_id = null

        let lastTimeMouseMove = new moment().unix()
        store.dispatch(`${CLIENTDATA}/${ACT_SET_LAST_TIME_ACTIVE}`, { data: lastTimeMouseMove })
        event_bus.$on('mouse-move', () => {
            if (this.focused) this.offlineAfter(mouseMoveTimeout)
            let newTime = new moment().unix()
            if (newTime !== lastTimeMouseMove) {
                lastTimeMouseMove = newTime
                store.dispatch(`${CLIENTDATA}/${ACT_SET_LAST_TIME_ACTIVE}`, { data: newTime })
            }
        })
        const setOnline = () => {
            if (!this.online) this.isOnline = true
            this.focused = true
            this.offlineAfter(mouseMoveTimeout)
        }
        event_bus.$on('body-onfocus', setOnline)
        event_bus.$on('body-onblur', () => {
            this.focused = false
            this.offlineAfter(windowFocusedTimeout)
        })
    }
    offlineAfter (timeout = 0) {
        if (this.timeout_id) clearTimeout(this.timeout_id)
        this.timeout_id = setTimeout(() => {
            this.isOnline = false
        }, timeout)
    }
    get isOnline () { return this.online }
    set isOnline (online = false) {
        clearTimeout(this.timeout_id)
        this.online = online
        store.dispatch(`${USERDATA}/${ACT_SET_CONTACT_STATUS}`, online ? 'online' : 'offline')
    }
}

const uam = new UserActivityMonitor()
export default uam
