import {mapGetters} from 'vuex'
import {ROSCHAT_CONF, VM_CONF} from '../../store/modulesNames'
import {GET_VM_CONF_CONNECTION_PARAMS, IS_ROSCHAT_CONF_ENABLED} from '../../store/gettersTypes'
import {CONF_TYPES} from '../../constants'
import moment from 'moment'

const periodRecurrenceTypes = { "day": 0, "week": 1, "month": 2 }
const weekDays = {
    "Mn": 0, "Tu": 1, "We": 2, "Th": 3, "Fr": 4, "Sa": 5, "Su": 6
}

const getStartDate = (unixStartTime) => {
    return moment.unix(unixStartTime).local().format("YYYY-MM-DD")
}
const getEndDate = (unixStartTime) => {
    return moment.unix(unixStartTime).add(1, 'year').local().format("YYYY-MM-DD")
}

const getMonthDay = (unixTime) => { return moment.unix(unixTime).date() }

const confManagementMixin = {
    computed: {
        ...mapGetters(VM_CONF, [GET_VM_CONF_CONNECTION_PARAMS]),
        ...mapGetters(ROSCHAT_CONF, [IS_ROSCHAT_CONF_ENABLED]),
    },
    methods: {
        getTypeOptions() {
            let types = []
            if (this[GET_VM_CONF_CONNECTION_PARAMS].server) types.push(
                {
                    value: CONF_TYPES.VM,
                    name: this.$t('videomost.conferences-type-videomost'),
                    selected: false,
                }
            )
            if (this[IS_ROSCHAT_CONF_ENABLED])  types.push({
                value: CONF_TYPES.ROSCHAT,
                name: this.$t('videomost.conferences-type-roschat'),
                selected: false,
            })
            if (types.length) types[0].selected = true
            return types
        },
        getCalendarRecurrence(periodicity, startTime) {
            const recurrence = {}
            const type = periodRecurrenceTypes[periodicity.type]
            recurrence.type = type
            recurrence.interval = 1
            if (type === 1) {
                const { days } = periodicity
                const daysOfTheWeek = []
                if (days && days.length) {
                    days.forEach(day => { daysOfTheWeek.push(weekDays[day]) })
                    recurrence.daysOfTheWeek = daysOfTheWeek
                }
            }
            if (type === 2) recurrence.dayOfMonth = getMonthDay(startTime)
            recurrence.startDate = getStartDate(startTime)
            recurrence.endDate = getEndDate(startTime)
            return recurrence
        }
    }
}

export default confManagementMixin