//
//
//
//
//
//
//

import ChannelItem from '../listItems/ChannelItem.vue';
import ItemListMixin from './ItemListMixin'
export default {
    name: 'search-channels',
    mixins: [ItemListMixin],
    components: {ChannelItem},
    props: {
        items: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    watch: {
        items(val) {console.log(val);}
    }
}
