import videoConfMixin from "./videoConfMixin"

export default {
    mixins: [videoConfMixin],
    props: {
        schema: {
            type: String,
            required: true
        },
        btsShow: {
            type: Boolean,
            required: true
        },
        share: {
            type: Boolean,
            required: true
        },
        ownShare: {
            type: Boolean,
            required: true
        },
        status: {
            type: [String, Number],
            required: true,
        },
        isBanned: {
            type: Boolean,
            required: true
        },
        isModerator: {
            type: Boolean,
            required: true
        },
    },
    computed: {
    }
}