//
//
//
//
//
//

import CustomNotification from './CustomNotification.vue'
import {NOTIFICATIONS} from '../../store/modulesNames'
import {GET_CUSTOM_NOTIFICATION_DATA} from '../../store/gettersTypes'

export default {
    name: 'CustomNotificationWrapper',
    components: { CustomNotification },
    data() {
        return {
            show: false,
            num: 1,
            timerId: null,
        }
    },
    computed: {
        dataNotify () {
            return this.$store.getters[`${NOTIFICATIONS}/${GET_CUSTOM_NOTIFICATION_DATA}`]
        },
    },
    methods: {
        hide () {
            this.show = false
        },
    },
    watch: {
        dataNotify () {
            this.num++
            this.show = true
            clearTimeout(this.timerId)
            this.timerId = setTimeout( () =>  this.show = false, 6000)
        },
    },
}
