//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'

import ContextMenuBase from "../contextMenu/ContextMenuBase.vue"
import ContextMenuGroup from "../contextMenu/ContextMenuGroup.vue"
import ContextMenuItem from "../contextMenu/ContextMenuItem.vue"
import {
    USERDATA,
    VIDEO_CONF_NEW
} from "../../store/modulesNames"
import {
    GET_MEDIA_DEVICES_BY_KIND,
    GET_VC_CONF_MEDIA_DEVICE_BY_KIND,
} from "../../store/gettersTypes"
import {
    ACT_VC_CONF_SET_DEVICE,
} from "../../store/actionsTypes"
import videoConfMixin from "./videoConfMixin"
import contextMenuMixin from "../contextMenu/ContextMenuMixin"
import {MEDIA_DEVICES} from "../../constants"

export default {
    name: "micSelect",
    components: { ContextMenuItem, ContextMenuGroup, ContextMenuBase },
    mixins: [videoConfMixin, contextMenuMixin],
    computed: {
        speakerList() {
            let selected = this[GET_VC_CONF_MEDIA_DEVICE_BY_KIND](...this.confIdGetterData, MEDIA_DEVICES.AUDIO_OUTPUT)
            return this[GET_MEDIA_DEVICES_BY_KIND](MEDIA_DEVICES.AUDIO_OUTPUT).map(device => {
                return {
                    id: device.deviceId,
                    text: device.label,
                    selected: device.deviceId === selected,
                    kind: device.kind
                }
            })
        },
        micList() {
            let selected = this[GET_VC_CONF_MEDIA_DEVICE_BY_KIND](...this.confIdGetterData, MEDIA_DEVICES.AUDIO_INPUT)
            return this[GET_MEDIA_DEVICES_BY_KIND](MEDIA_DEVICES.AUDIO_INPUT).map(device => {
                return {
                    id: device.deviceId,
                    text: device.label,
                    selected: device.deviceId === selected,
                    kind: device.kind
                }
            })
        },
        ...mapGetters(USERDATA, [GET_MEDIA_DEVICES_BY_KIND]),
        ...mapGetters(VIDEO_CONF_NEW, [GET_VC_CONF_MEDIA_DEVICE_BY_KIND]),
    },
    methods: {
        onSelected(kind, deviceId) {
            this[ACT_VC_CONF_SET_DEVICE]({...this.confIdActionData, kind, deviceId})
        },
        ...mapActions(VIDEO_CONF_NEW, [ACT_VC_CONF_SET_DEVICE])
    }
}
