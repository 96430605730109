import { render, staticRenderFns } from "./HotKeys.vue?vue&type=template&id=6457401b&scoped=true&lang=pug&"
import script from "./HotKeys.vue?vue&type=script&lang=js&"
export * from "./HotKeys.vue?vue&type=script&lang=js&"
import style0 from "./HotKeys.vue?vue&type=style&index=0&id=6457401b&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6457401b",
  null
  
)

export default component.exports