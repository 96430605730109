//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';

import MainContentContainer from "../main/body/MainContentContainer.vue";
import ListItem from "../custom/ListItem.vue";
import CustomCheckboxSlider from "../custom/CustomCheckboxSlider.vue";
import { USERDATA } from "../../store/modulesNames.js";
import { MUT_SEND_BY_ENTER } from "../../store/mutationsTypes.js";

export default {
    name: 'chat-settings',
    components: { MainContentContainer, ListItem, CustomCheckboxSlider },
    data() {
        return {
            sendMsgByEnter: false,
            sendMsgByCtrlEnter: true
        };
    },
    created() {
        let sendMsgByEnter = JSON.parse(localStorage.getItem('sendMsgByEnter')) || false;
        this.$store.commit(`${USERDATA}/${MUT_SEND_BY_ENTER}`, sendMsgByEnter);
        this.sendMsgByEnter = sendMsgByEnter;
        this.sendMsgByCtrlEnter = !sendMsgByEnter;
    },
    watch: {
        sendMsgByEnter() {
            localStorage.setItem('sendMsgByEnter', this.sendMsgByEnter);
            this.$store.commit(`${USERDATA}/${MUT_SEND_BY_ENTER}`, this.sendMsgByEnter);
        },
        sendMsgByCtrlEnter() {
            this.sendMsgByEnter = !this.sendMsgByCtrlEnter;
        }
    }
}
