var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "phone" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loaded,
            expression: "loaded"
          }
        ],
        staticClass: "phone"
      },
      [
        _c("div", { staticClass: "header" }, [
          _c(
            "div",
            { staticClass: "call-quality" },
            [
              _vm.callQualityText && _vm.connected
                ? _c("signal-level", {
                    attrs: { level: _vm.mos, title: _vm.qualityTitle }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "close-btn",
              on: {
                mousedown: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                },
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.minimizePhone($event)
                }
              }
            },
            [_c("i", { staticClass: "fas fa-chevron-down" })]
          )
        ]),
        _vm.keysDialer
          ? _c(
              "div",
              { staticClass: "body" },
              [
                _c("phone-dialer", {
                  attrs: { phone: _vm.phone },
                  on: { pressDialKey: _vm.pressDialKey }
                })
              ],
              1
            )
          : _vm.participantsList
          ? _c(
              "div",
              { staticClass: "body" },
              [
                _c("call-participants", {
                  attrs: { type: _vm.type, id: _vm.id }
                })
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "body" },
              [
                _c("phone-audio-icons", {
                  attrs: {
                    type: _vm.type,
                    id: _vm.id ? _vm.id : "",
                    pause: _vm.pause
                  },
                  on: { "toggle-pause": _vm.togglePause }
                }),
                _c("div", { staticClass: "status-n-functions" }, [
                  _c(
                    "span",
                    {
                      staticClass: "phone-name",
                      class: { "is-conf": _vm.isConf },
                      style: _vm.titleStyle,
                      attrs: { title: _vm.talkTitle }
                    },
                    [_vm._v(_vm._s(_vm.talkTitle || " "))]
                  ),
                  _c("span", { staticClass: "phone-type-call" }, [
                    _vm._v(_vm._s(_vm.typeCallText))
                  ]),
                  _c("span", { staticClass: "phone-status" }, [
                    _vm._v(_vm._s(_vm.statusText || " "))
                  ])
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.phoneTerminated,
                        expression: "!phoneTerminated"
                      }
                    ],
                    staticClass: "func-buttons",
                    class: { busy: _vm.busy }
                  },
                  _vm._l(_vm.btns, function(btn) {
                    return _c(
                      "phone-function-button",
                      _vm._b(
                        { key: btn, on: { click: _vm.onFuncBtnClick } },
                        "phone-function-button",
                        _vm.getFuncBtnBindProps(btn),
                        false
                      )
                    )
                  }),
                  1
                )
              ],
              1
            ),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _vm.keysDialer || _vm.participantsList
              ? _c(
                  "div",
                  {
                    staticClass: "back-btn",
                    on: {
                      mousedown: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                      },
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.keysDialerHide($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-long-arrow-left" })]
                )
              : _vm._e(),
            _c("phone-action-button", {
              attrs: { type: "terminate", disable: _vm.phoneTerminatedLocal },
              on: {
                click: function($event) {
                  return _vm.terminate()
                }
              }
            })
          ],
          1
        ),
        _c("calls-selector", {
          staticClass: "calls-selector-audio",
          attrs: { type: "audio" }
        }),
        _c("video", {
          attrs: { id: "localAudio", autoplay: "", muted: "" },
          domProps: { muted: true }
        }),
        _c("custom-video", { attrs: { id: "remoteAudio", autoplay: true } }),
        _c("custom-audio", {
          ref: "audio",
          attrs: {
            src: _vm.audioSrc,
            loop: _vm.audioLoop,
            type: "audio/mpeg",
            autoplay: true
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }