//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';

import ModalDialog from "./ModalDialog.vue";
import modal_mixin from "./modal-mixin";
import ListItem from "../custom/ListItem.vue";
import WebcamerasSettings from "../settings/WebcamerasSettings.vue";
import MicrophonesSettings from "../settings/MicrophonesSettings.vue";
import SpeakersSettings from "../settings/SpeakersSettings.vue";

import { mapGetters } from "vuex";
import { USERDATA } from "../../store/modulesNames.js";
import { GET_MEDIA_DEVICES_BY_KIND } from "../../store/gettersTypes"
import { MEDIA_DEVICES } from "../../constants"

export default {
    name: 'MediaSettings',
    components: {
        ModalDialog,
        ListItem,
        WebcamerasSettings,
        MicrophonesSettings,
        SpeakersSettings
    },
    mixins: [modal_mixin],
    data() {
        return {
            title: this.$t('settings.audio-video-title'),
            compModalProps: {
                delay: 200,
                pivotY: 0.05,
                width: 500,
                height: 'auto',
                adaptive: true,
                styles: "min-width: 300px;"                    
            },
        }
    },
    computed: {
        webcameras() {
            return this[GET_MEDIA_DEVICES_BY_KIND](MEDIA_DEVICES.VIDEO_INPUT)
        },
        microphones() {
            return this[GET_MEDIA_DEVICES_BY_KIND](MEDIA_DEVICES.AUDIO_INPUT)
        },
        speakers() {
            return this[GET_MEDIA_DEVICES_BY_KIND](MEDIA_DEVICES.AUDIO_OUTPUT)
        },
        ...mapGetters(USERDATA, [GET_MEDIA_DEVICES_BY_KIND]),
    },
    methods: {
        close() {
            this.modalClose();
        },
        changeInputVideoDevice(device) {
            console.log('~~~ change Input Video Device ', device);
        },
        changeInputAudioDevice(device) {
            console.log('~~~ change Input Audio Device ', device);
        },
        changeOutputAudioDevice(device) {
            console.log('~~~ change Output Audio Device ', device);
        },
    }
}
