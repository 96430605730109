//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import {
    GET_MAIN_TYPE,
    SHOW_NEW_VERSION_NOTIFY,
    GET_IN_ACS,
    GET_SUPPORT,
    GET_DND,
    GET_USER_PARAMS,
    GET_IS_ELECTRON,
    GET_ACS_NAME,
    GET_INTEGRATION_SETTINGS,
} from '../../store/gettersTypes'
import { CONTENT_MANAGER, CLIENTDATA, USERDATA, USERPROFILE, ACS, CONTACTS, INFO, CHAT } from "../../store/modulesNames";
import IconImage from "../IconImage.vue";
import UserProfile from "../settings/UserProfile.vue";
import ListItem from "../custom/ListItem.vue";

import { MAIN_TYPES } from "../../store/modules/content-manager";

import { ACT_OPEN_CHAT, ACT_REPLACE_MAIN_TYPE } from '../../store/actionsTypes'
import {INTEGRATION_NAMES} from '../../constants'

export default {
    name: "More",
    components: { ListItem, UserProfile, IconImage },
    data() {
        return {
            active: '',
            profile_type: 'profile'
        }
    },
    computed: {
        items() {
            const items = [
                { type: MAIN_TYPES.NOTE, text: this.$t('notes-chat-name') },
                { type: MAIN_TYPES.SESSIONS, text: this.$t('mainPage.sessions') },
                { type: MAIN_TYPES.SETTINGS, text: this.$t('mainPage.settings') },
                { type: MAIN_TYPES.ABOUT, text: this.$t('mainPage.about') }
            ];

            if (this.showCalendar) {
                items.splice(items.findIndex(({ type }) => type === MAIN_TYPES.SESSIONS), 0, { type: MAIN_TYPES.MEETINGCALENDAR, text: this.$t('integrations.calendar') })
            }

            if (this.showSupport) {
                items.splice(items.findIndex(({ type }) => type === MAIN_TYPES.ABOUT), 0, { type: MAIN_TYPES.SUPPORT, text: this.$t('mainPage.support') })
            }

            const acsName = this.acsName

            if (acsName && this[GET_IN_ACS](this.uid)) items.unshift({ type: MAIN_TYPES.ACS, text: acsName });
            return items;
        },
        uid() {
            return app.store.state.userdata.uid;
        },
        fio() {
            const contact = app.store.getters['contacts/getMergedContactById'](this.uid);
            return contact && contact.fio;
        },
        photo: function () {
            return app.store.getters['contacts/getMergedContactById'](this.uid).photo
        },
        dnd: function () {
            return app.store.getters[`${USERPROFILE}/${GET_DND}`];
        },
        events() {
            return {
                settings: 0,
                sessions: 0,
                support: 0,
                about: this[SHOW_NEW_VERSION_NOTIFY] ? 1 : 0
            }
        },
        showSupport() {
            return Boolean(this[GET_IS_ELECTRON] || this[GET_SUPPORT] || this[GET_USER_PARAMS] && this[GET_USER_PARAMS].echoContact)
        },
        showCalendar() {
            return Boolean(this[GET_INTEGRATION_SETTINGS](INTEGRATION_NAMES.MS_EXCHANGE))
        },
        acsName() {
            return this[GET_ACS_NAME]
        },
        ...mapGetters(CONTACTS, [GET_SUPPORT]),
        ...mapGetters(ACS, [GET_IN_ACS]),
        ...mapGetters(CONTENT_MANAGER, [GET_MAIN_TYPE]),
        ...mapGetters(CLIENTDATA, [SHOW_NEW_VERSION_NOTIFY, GET_IS_ELECTRON]),
        ...mapGetters(USERDATA, [GET_USER_PARAMS, GET_ACS_NAME, GET_INTEGRATION_SETTINGS]),
    },
    methods: {
        ...mapActions(CONTENT_MANAGER, [ACT_REPLACE_MAIN_TYPE]),
        doOnMoreItemClick(type) {
            switch (type) {
                case MAIN_TYPES.ACS:
                    this.$store.dispatch('acs/setCid', this.uid) //@todo в помойку
                    this[ACT_REPLACE_MAIN_TYPE]({ type, params: { cid: this.uid } })
                    break;
                case MAIN_TYPES.NOTE:
                    this.$store.dispatch(`${CHAT}/${ACT_OPEN_CHAT}`, { cid: this.uid }) //@todo в помойку
                    this[ACT_REPLACE_MAIN_TYPE]({ type, params: { cid: this.uid } })
                    break;
                case MAIN_TYPES.ABOUT:
                case MAIN_TYPES.PROFILE:
                case MAIN_TYPES.SESSIONS:
                case MAIN_TYPES.SUPPORT:
                case MAIN_TYPES.SETTINGS:
                case MAIN_TYPES.MEETINGCALENDAR:
                    this[ACT_REPLACE_MAIN_TYPE]({ type })
                    break;
            }
        },
        isActive(type) {
            return type === this.active;
        }
    },
    watch: {
        [GET_MAIN_TYPE](value) {
            this.active = ([
                MAIN_TYPES.ACS,
                MAIN_TYPES.ABOUT,
                MAIN_TYPES.PROFILE,
                MAIN_TYPES.SESSIONS,
                MAIN_TYPES.SUPPORT,
                MAIN_TYPES.SETTINGS,
                MAIN_TYPES.NOTE,
                MAIN_TYPES.MEETINGCALENDAR,
            ].includes(value)) ? value : '';
        }
    },
    mounted() {

    }
}
