var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        name: _vm.$parent.$parent
          ? _vm.$parent.$parent.components_transition_name
          : _vm.$parent.components_transition_name,
        mode: "out-in"
      }
    },
    [
      _c("div", { staticClass: "mixin-wrapper" }, [
        _c("div", { staticClass: "section-body" }, [
          _c(
            "div",
            {
              staticClass: "msg",
              style: _vm.position,
              on: { click: _vm.stopAction, contextmenu: _vm.stopAction }
            },
            [
              _c("chat-message", {
                key: _vm.message.id,
                attrs: {
                  message: _vm.message,
                  cid: _vm.message.cid,
                  type: "detail-msg"
                }
              })
            ],
            1
          ),
          _vm.params.cidType === "group"
            ? _c("div", { staticClass: "info-msg-group" }, [
                _c("div", { staticClass: "info-msg-group-item" }, [
                  _vm.params.edited
                    ? _c("div", { staticClass: "info-msg-item" }, [
                        _c("div", { staticClass: "status" }, [
                          _c("i", {
                            staticClass: "far fa-pencil-alt greyFont"
                          }),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("information.edited")))
                          ])
                        ]),
                        _c("div", { staticClass: "date-time" }, [
                          _c("span", { staticClass: "date" }, [
                            _vm._v(
                              _vm._s(_vm.time.editedDate) +
                                " " +
                                _vm._s(_vm.time.editedTime)
                            )
                          ])
                        ])
                      ])
                    : _vm._e()
                ]),
                _c(
                  "div",
                  { staticClass: "info-msg-group-item" },
                  [
                    _c("div", { staticClass: "info-msg-item" }, [
                      _c("div", { staticClass: "status" }, [
                        _c("i", {
                          staticClass: "far fa-check-double blueFont"
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.$t("information.seen")))])
                      ])
                    ]),
                    _vm._l(_vm.detailsGroupWatched, function(item, index) {
                      return _c("div", { staticClass: "info-msg-users" }, [
                        _c(
                          "div",
                          {
                            staticClass: "user",
                            on: {
                              click: function($event) {
                                return _vm.showDetail("watched", index)
                              }
                            }
                          },
                          [
                            _c("icon-image", {
                              staticClass: "medium",
                              attrs: {
                                src: _vm.photo(item.cid),
                                text: _vm.user(item.cid)
                              }
                            }),
                            _c("div", { staticClass: "user-info" }, [
                              _c("div", {
                                staticClass: "fio",
                                domProps: {
                                  innerHTML: _vm._s(_vm.user(item.cid))
                                }
                              }),
                              _c("div", { staticClass: "time" }, [
                                _vm._v(_vm._s(item.watchedTime))
                              ])
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            ref: "watched",
                            refInFor: true,
                            staticClass: "details",
                            staticStyle: { display: "none" }
                          },
                          [
                            _c("div", [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(_vm.$t("information.seen")))
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(item.watchedTime))
                              ])
                            ]),
                            _c("div", [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(_vm.$t("information.delivered")))
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(item.receivedTime))
                              ])
                            ]),
                            _c("div", [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(_vm.$t("information.sent")))
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(
                                  _vm._s(_vm.time.sentDate) +
                                    " " +
                                    _vm._s(_vm.time.sentTime)
                                )
                              ])
                            ])
                          ]
                        )
                      ])
                    })
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "info-msg-group-item" },
                  [
                    _c("div", { staticClass: "info-msg-item" }, [
                      _c("div", { staticClass: "status" }, [
                        _c("i", {
                          staticClass: "far fa-check-double greyFont"
                        }),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("information.delivered")))
                        ])
                      ])
                    ]),
                    _vm._l(_vm.detailsGroupReceived, function(item, index) {
                      return _c("div", { staticClass: "info-msg-users" }, [
                        _c(
                          "div",
                          {
                            staticClass: "user",
                            on: {
                              click: function($event) {
                                return _vm.showDetail("received", index)
                              }
                            }
                          },
                          [
                            _c("icon-image", {
                              staticClass: "medium",
                              attrs: {
                                src: _vm.photo(item.cid),
                                text: _vm.user(item.cid)
                              }
                            }),
                            _c("div", { staticClass: "user-info" }, [
                              _c("div", {
                                staticClass: "fio",
                                domProps: {
                                  innerHTML: _vm._s(_vm.user(item.cid))
                                }
                              }),
                              _c("div", { staticClass: "time" }, [
                                _vm._v(_vm._s(item.receivedTime))
                              ])
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            ref: "received",
                            refInFor: true,
                            staticClass: "details",
                            staticStyle: { display: "none" }
                          },
                          [
                            _c("div", [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(_vm.$t("information.delivered")))
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(item.receivedTime))
                              ])
                            ]),
                            _c("div", [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(_vm.$t("information.sent")))
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(
                                  _vm._s(_vm.time.sentDate) +
                                    " " +
                                    _vm._s(_vm.time.sentTime)
                                )
                              ])
                            ])
                          ]
                        )
                      ])
                    })
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "info-msg-group-item" },
                  [
                    _c("div", { staticClass: "info-msg-item" }, [
                      _c("div", { staticClass: "status" }, [
                        _c("i", { staticClass: "far fa-check greyFont" }),
                        _c("span", [_vm._v(_vm._s(_vm.$t("information.sent")))])
                      ])
                    ]),
                    _vm._l(_vm.detailsGroupSent, function(item, index) {
                      return _c("div", { staticClass: "info-msg-users" }, [
                        _c(
                          "div",
                          {
                            staticClass: "user",
                            on: {
                              click: function($event) {
                                return _vm.showDetail("sent", index)
                              }
                            }
                          },
                          [
                            _c("icon-image", {
                              staticClass: "medium",
                              attrs: {
                                src: _vm.photo(item.cid),
                                text: _vm.user(item.cid)
                              }
                            }),
                            _c("div", { staticClass: "user-info" }, [
                              _c("div", {
                                staticClass: "fio",
                                domProps: {
                                  innerHTML: _vm._s(_vm.user(item.cid))
                                }
                              }),
                              _c("div", { staticClass: "time" }, [
                                _vm._v(
                                  _vm._s(_vm.time.sentDate) +
                                    " " +
                                    _vm._s(_vm.time.sentTime)
                                )
                              ])
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            ref: "sent",
                            refInFor: true,
                            staticClass: "details",
                            staticStyle: { display: "none" }
                          },
                          [
                            _c("div", [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(_vm.$t("information.sent")))
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(
                                  _vm._s(_vm.time.sentDate) +
                                    " " +
                                    _vm._s(_vm.time.sentTime)
                                )
                              ])
                            ])
                          ]
                        )
                      ])
                    })
                  ],
                  2
                )
              ])
            : _c("div", { staticClass: "info-msg" }, [
                _vm.params.edited
                  ? _c("div", { staticClass: "info-msg-item" }, [
                      _c("div", { staticClass: "status" }, [
                        _c("i", { staticClass: "far fa-pencil-alt greyFont" }),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("information.edited")))
                        ])
                      ]),
                      _c("div", { staticClass: "date-time" }, [
                        _c("span", { staticClass: "date" }, [
                          _vm._v(_vm._s(_vm.time.editedDate))
                        ]),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.time.editedTime))
                        ])
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "info-msg-item" }, [
                  _c("div", { staticClass: "status" }, [
                    _c("i", { staticClass: "far fa-check-double blueFont" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("information.seen")))])
                  ]),
                  _c("div", { staticClass: "date-time" }, [
                    _c("span", { staticClass: "date" }, [
                      _vm._v(_vm._s(_vm.time.watchedDate))
                    ]),
                    _c("span", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.time.watchedTime))
                    ])
                  ])
                ]),
                _c("div", { staticClass: "info-msg-item" }, [
                  _c("div", { staticClass: "status" }, [
                    _c("i", { staticClass: "far fa-check-double greyFont" }),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("information.delivered")))
                    ])
                  ]),
                  _c("div", { staticClass: "date-time" }, [
                    _c("span", { staticClass: "date" }, [
                      _vm._v(_vm._s(_vm.time.receivedDate))
                    ]),
                    _c("span", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.time.receivedTime))
                    ])
                  ])
                ]),
                _c("div", { staticClass: "info-msg-item" }, [
                  _c("div", { staticClass: "status" }, [
                    _c("i", { staticClass: "far fa-check greyFont" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("information.sent")))])
                  ]),
                  _c("div", { staticClass: "date-time" }, [
                    _c("span", { staticClass: "date" }, [
                      _vm._v(_vm._s(_vm.time.sentDate))
                    ]),
                    _c("span", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.time.sentTime))
                    ])
                  ])
                ])
              ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }