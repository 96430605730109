import event_bus from '../../../eventBus'

const API_LEVEL = 13

export default {
    emitters: {
        login({login, password, silentMode, oauth2 }) {
            return new Promise((resolve, reject) => {
                let data = {}
                if (silentMode) data = {
                    login: login,
                    password: password,
                    APILevel: API_LEVEL,
                    silentMode: silentMode,
                    oauth2
                }
                else data = {login: login, password: password, APILevel: API_LEVEL, oauth2}
                if (this.socket && !this.socket.disconnected) {
                    this.log('login: > ' + (oauth2 ? 'with tokens' : login))
                    this.socket.emit('login', data, (data) => {
                        this.log('login: < ' + JSON.stringify(data))
                        if (data.error) {
                            if (data.error && data.status === 'relogin-required') reject(data.status)
                            else reject(data.error)
                        } else {
                            if (data.APILevel) this.serverApi = data.APILevel
                            resolve(data)
                        }
                    })
                } else {
                    this.emit('disconnect')
                    reject('not-connected')
                }
            })
        },
        logout() {
            this.log('logout: >')
            this.reconnect = false
            this.socket.emit('logout')
        },
        sendLoginPin(pin) {
            return new Promise((resolve, reject) => {
                this.socket.emit('send-login-pin', {'pin': pin}, (data) => {
                    if (data.error) {
                        reject(data.error)
                    } else resolve(data)
                })
            })
        },
        requestLoginPin(pin) {
            return new Promise((resolve, reject) => {
                this.socket.emit('request-login-pin', {'pin': pin}, (data) => {
                    if (data.error) {
                        reject(data.error)
                    } else resolve(data)
                })
            })
        },
        sendPassRestoreMail(mail) {
            return new Promise((resolve, reject) => {
                this.log('passRestore: >')
                if (this.socket && !this.socket.disconnected) {
                    this.socket.emit('send-pass-restore-mail', {mail: mail}, (data) => {
                        this.log('passRestore: < ' + JSON.stringify(data))
                        if (data.error) {
                            reject(data.error)
                        }
                        return resolve(data)
                    })
                } else {
                    this.emit('disconnect')
                    reject('not-connected')
                }
            })
        },
        getUserInfo(cb) {
            this.log('getUserInfo: >')
            this.socket.emit('get-user-info', null, (data) => {
                this.log('getUserInfo: < ' + JSON.stringify(data))
                return cb && cb(data)
            })
        },
        getUserParams() {
            return new Promise((resolve, reject) => {
                this.log("get-user-params: >");
                this.socket.emit('get-user-params', null, (data) => {
                    if (!data) {
                        this.log('getUserParams: < failed')
                        return reject()
                    }
                    this.log("get-user-params: < " + JSON.stringify(data));
                    resolve(data)
                })
            })
        },
        getTurnServer(cb) {
            return new Promise((resolve, reject) => {
                this.log('getTurnServer: >')
                this.socket.emit('get-turn-server', null, (data) => {
                    this.log('getTurnServer: < ' + JSON.stringify(data))
                    resolve(data)
                    return cb && cb(data)
                })
            })
        },
        setUserPhone(data) {
            this.log(`set-user-phone: > ${JSON.stringify(data)}`)
            this.socket.emit('set-user-phone', data, ({error}) => this.log(`set-user-phone: < ${error}`))
        },
        getSessions() {
            return new Promise((resolve, reject) => {
                this.log('get-sessions: >')
                this.socket.emit('get-sessions', null, data => {
                    if (data) {
                        this.log(`get-sessions: < success count - ${data.length}`)
                        resolve(data)
                    } else {
                        this.log(`get-sessions: < failed`)
                        reject()
                    }
                })
            })
        },
        dropSessions() {
            return new Promise((resolve, reject) => {
                this.log('drop-sessions: >')
                this.socket.emit('drop-sessions', null, data => {
                    if (data.result) {
                        this.log(`drop-sessions: < success`)
                        resolve(data)
                    } else {
                        this.log(`drop-sessions: < failed`)
                        reject()
                    }
                })
            })
        },
        addOptions(options) {
            this.log('addOptions: ' + JSON.stringify(options))
            this.socket.emit('add-options', options)
        },
        getObjectList() {
            return new Promise((resolve, reject) => {
                this.log('getObjectList: >')
                this.socket.emit('get-object-list', null, (response) => {
                    if (response && response.error) {
                        this.log(`getObjectList: error ${response.error}`)
                        reject(response.error)
                    } else {
                        this.log(`getObjectList: < response.length ${response.length}`)
                        resolve(response)
                    }
                })
            })
        },
        getObjectInfo(objectId) {
            return new Promise((resolve, reject) => {
                this.log('getObjectInfo: >')
                this.socket.emit('get-object-info', { objectId }, (response) => {
                    if (response && response.error) {
                        this.log(`getObjectInfo: error ${response.error}`)
                        reject(response.error)
                    } else {
                        this.log(`getObjectInfo: < ${JSON.stringify(response)}`)
                        resolve(response)
                    }
                })
            })
        }
    },
    listeners: {
        'tokens-event'(data) {
            this.log('bind: < tokens-event: ' + JSON.stringify(data))
            this.emit('tokens-event', data)
        },
        'qr-login-data-event'(data) {
            this.log('bind: < qr-login-data-event: ' + JSON.stringify(data))
            event_bus.$emit('qr-login-data-event', data)
        },
    },
}