//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "videoConfSourceSelect",
    props: {
        share: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            sources: [],
            sourceSelect: false,
        }
    },
    mounted() {
        navigator.getDisplayMedia = () => this.selectSource()
    },
    watch: {
        share(val) {
            if (val) this.$emit('source-select-canceled')
        },
    },
    methods: {
        selectSource() {
            return new Promise(async (resolve, reject) => {

                let sources = []
                try {
                    let { ipcRenderer } = await require('electron')
                    sources = await ipcRenderer.invoke('get-capturer-sources', { types: ['window', 'screen'], thumbnailSize: {
                            width: 320,
                            height: 240
                        }})
                } catch (e) {
                    return reject(e)
                }
                this.sources = sources.map((source) => {
                    return {
                        img: source.thumbnail.toDataURL(),
                        id: source.id,
                        name: source.name,
                    }
                }).sort((a,b) => {
                    if (a.id > b.id) return 1
                    else if (a.id < b.id) return -1
                    else return 0
                })
                if (this.sources.length) {
                    this.sourceSelect = true
                    this.$off('source-selected')
                    this.$on('source-selected', async (id) => {
                        this.sourceSelect = false
                        this.sources = []
                        let source = sources.find(source => source.id === id)
                        if (!source) return reject(new Error('source not find'))
                        try {
                            const stream = await navigator.mediaDevices.getUserMedia({
                                audio: false,
                                video: {
                                    mandatory: {
                                        chromeMediaSource: 'desktop',
                                        chromeMediaSourceId: source.id,
                                        minWidth: 1280,
                                        maxWidth: 1920,
                                        minHeight: 720,
                                        maxHeight: 1080
                                    }
                                }
                            })
                            resolve(stream)
                        } catch (e) {
                            reject(e)
                        }
                    })
                    this.$off('source-select-canceled')
                    this.$on('source-select-canceled', () => {
                        this.sourceSelect = false
                        this.sources = []
                        reject(new Error('cancel'))
                    })
                }
                else reject(new Error('no-sources'))
            })

        },
    },
}
