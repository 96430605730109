//
//
//
//
//
//
//

export default {
    name: "ContextMenuItem",
    props: {
        text: {
            type: [Number, String],
            required: false,
            default: ''
        },
        select: {
            type: Boolean,
            required: false,
            default: false
        },
        selected: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {

    }
}
