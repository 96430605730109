var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { staticClass: "system-notification" }, [
        _c("div", { staticClass: "close-btn" }, [
          _c("i", { staticClass: "fas fa-times", on: { click: _vm.close } })
        ]),
        _vm._m(0),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text) + " ")])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-exclamation" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }