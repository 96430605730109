var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "select-contact-modal dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title
      }
    },
    [
      _c(
        "template",
        { slot: "default" },
        [
          _c("custom-input-text", {
            ref: "input_text",
            staticClass: "mail",
            attrs: { input_text: _vm.email, label: _vm.$t("email") },
            on: {
              "update:input_text": function($event) {
                _vm.email = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.$t("cancel") },
            on: {
              click: function($event) {
                return _vm.modalClose()
              }
            }
          }),
          _c("modal-btn", {
            attrs: { text: _vm.$t("send") },
            on: { click: _vm.send }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }