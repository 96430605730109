import store from "../../store/main/store"

const mixin = {
    methods: {
        async scrollingToElement(id, count = 30) {
            if (!id) return
            let element = document.querySelector('#msg' + id)

            if (!element) {
                store.dispatch("chat/setUpdating", true)
                await store.dispatch('chat/update', { // грузим с id
                    minId: id,
                    non_clear: true
                })
                await this.$store.dispatch('chat/update', { // догружаем предшествующие сообщения
                    startId: Math.max(id - 1, 1),
                    count,
                    non_clear: true
                })
                await new Promise((resolve) => this.$nextTick(resolve))
                element = document.querySelector('#msg' + id)
                store.dispatch("chat/setUpdating", false)
            }

            element.scrollIntoView({behavior: "smooth", block: "center"})

            setTimeout(function() {
                element.classList.add('scroll-msg');
                element.style.opacity = 0.3;
            }, 500);
            setTimeout(function() {
                element.style.opacity = 1;
            }, 800);
            setTimeout(function() {
                element.style.opacity = 0.3;
            }, 1100);
            setTimeout(function() {
                element.style.opacity = 1;
            }, 1400);
            setTimeout(function() {
                element.classList.remove('scroll-msg');
            }, 1700);
        },
    }
}

export default mixin