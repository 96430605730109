import { XmlRpcMessage, XmlRpcResponse, } from 'xmlrpc-parser'

XmlRpcMessage.prototype.dataTypeOf = function(o) {
    var type = typeof o;
    type = type.toLowerCase();
    switch (type) {
        case 'number':
            if (Math.round(o) === o) type = 'int';
            else type = 'double';
            break;
        case 'object':
            var con = o.constructor;
            if (con === Date) type = 'date';
            else if (con === Array) type = 'array';
            else type = 'struct';
            break;
    }
    return type;
};

XmlRpcMessage.prototype.toBooleanXML = function(data) {
    var value = Boolean(data);
    var xml = '<boolean>' + value + '</boolean>';
    return xml;
}

export {
    XmlRpcMessage,
    XmlRpcResponse
}