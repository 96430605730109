//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import event_bus from '../../eventBus'

import {mapActions, mapGetters} from 'vuex'
import {USERDATA, CONTACTS, VIDEO_CONF_NEW} from '../../store/modulesNames'
import {GET_MERGED_CONTACT_BY_ID, GET_UID} from '../../store/gettersTypes'
import {ACT_VC_CONF_CREATE} from '../../store/actionsTypes'

import CustomInputText from '../custom/CustomInputText.vue'
import DateRangePicker from '../settings/DateRangePicker.vue'
import CustomRadio from '../custom/CustomRadio.vue'
import ListItem from '../custom/ListItem.vue'
import ModalBtn from '../modal/ModalBtn.vue'
import CustomCheckbox from '../custom/CustomCheckboxRound.vue'
import CustomSelect from '../custom/CustomSelect.vue'

import modal_mixin from '../modal/modal-mixin'
import confManagementMixin from './confManagementMixin'

export default {
    name: 'NewVC',
    components: {
        CustomInputText,
        DateRangePicker,
        CustomRadio,
        CustomCheckbox,
        ListItem,
        ModalBtn,
        CustomSelect,
    },
    mixins: [modal_mixin, confManagementMixin],
    data () {
        return {
            subjectName: '',
            subjectMissed: false,
            participantName: '',
            confModes: [{
                value: true,
                label: this.$t('videomost.conf-discussion'),
            },
                {
                    value: false,
                    label: this.$t('videomost.conf-presentation'),
                },
            ],
            newCreatedConf: {},
            confMode: {},
            confID: '',
            confPassword: '',
            compModalProps: {
                delay: 150,
                pivotY: 0.4,
                width: 420,
                height: 'auto',
                clickToClose: false,
                adaptive: true,
                styles: "min-width: 300px; overflow-x: auto;"                    
            },
            runConferenceText: this.$t('videomost.conf-run'),
            cancelText: this.$t('videomost.cancel'),
            confSettingsText: this.$t('videomost.conf-settings'),
            isNoCamera: false,
            isNoMicrophone: false,
            chosenConfType: '',
            confTypes: []
        }
    },
    created () {
        this.confTypes = this.getTypeOptions()
        this.chosenConfType = this.confTypes[0]?.value
    },
    computed: {
        contact () {
            return this[GET_MERGED_CONTACT_BY_ID](this[GET_UID])
        },
        fio () {
            return this.contact.fio
        },
        ...mapGetters(CONTACTS, {[GET_MERGED_CONTACT_BY_ID]: GET_MERGED_CONTACT_BY_ID}),
        ...mapGetters(USERDATA, {[GET_UID]: GET_UID}),
    },
    methods: {
        cancelCreateNewConf () {
            this.modalClose()
        },
        async createNewConf () {
            if (!this.subjectName) this.subjectMissed = true
            if (this.subjectMissed) return

            const type = this.chosenConfType
            const newConfSubject = this.subjectName
            const participantName = this.contact.fio
            let newConfParams = {type, topic: newConfSubject, username: participantName}
            this.newCreatedConf = await this[ACT_VC_CONF_CREATE](newConfParams)
            this.modalClose()
            event_bus.$emit('reload-conf-lists')
        },
        ...mapActions(VIDEO_CONF_NEW, [ACT_VC_CONF_CREATE]),
    },
}
