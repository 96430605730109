//
//
//
//
//
//
//
//
//
//
//

"use strict";

import ChatVideoMessage from "../chat/ChatVideoMessage.vue";
import PublicationVideoItemSearch from "../channels/PublicationVideoItemSearch.vue"

export default {
    name: "media-search-videos",
    props: { 
        messages: {
            type: Object,
            default: []
        }, 
        isChannel: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ChatVideoMessage,
        PublicationVideoItemSearch
    },
    data() {
        return {};
    },
    // watch: {
    //     messages(val) {
    //     console.log("!! -> file: MediaSearchVideos.vue -> line 39 -> messages -> val", val)
    //     }
    // },
    computed: {
        style() {
            const size = "110px"
            const previewSize = "108px"
            const styles = {}
            Object.assign(styles, {
                "--image-width": `${size}`,
                "--image-height": `${size}`,
                "--preview-block-max-width": `${previewSize}`,
                 "--preview-block-max-height": `${previewSize}`,
                "--image-max-width": "100%",
                "--image-max-height": `${size}`
            })
            return styles
        },

    },
    methods: {
        onContextMenu(e, message) {
            this.$emit("mediasearch-common-contextmenu", { e, message })
        },
    }
};
