var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$t("recently-searched")))
    ]),
    _c(
      "ul",
      { staticClass: "list" },
      _vm._l(_vm.items, function(contact, i) {
        return _c(
          "li",
          [
            _c("contacts-list-item", {
              staticClass: "contacts-list-item",
              attrs: {
                contact: contact,
                noswitch: _vm.noswitch,
                focused: _vm.focusOnItem === i,
                parent: "search",
                noSelection: true
              },
              on: { "update:contact-selected": _vm.doSelect }
            }),
            _c("div", { staticClass: "hor-line light-line" })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }