//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconImage from '../IconImage.vue';
import ProgressCircle from '../ProgressCircle.vue';
import PublicationMediaViewer from '../mediaViewer/PublicationMediaViewer.vue';
import PublicationFilesMixin from './PublicationFilesMixin';

export default {
    name: 'PublicationVideoItem',
    components: {'progress-circle': ProgressCircle, IconImage},
    mixins: [PublicationFilesMixin],
    props: {
        message: {
            type: Object,
            required: true
        },
        file: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        customStyle: {
            type: Object
        }
    },
    // watch: {
    //     message(val) {
    //     console.log("!! -> file: PublicationVideoItemSearch.vue -> line 49 -> message -> val", val)
    //     }
    // },
    computed: {
        pubId() { return this.message.pubId },
        src() {
            return this.getPreview(this.file)
        },
        publication() {
            return this.$store.getters['channel/getPublicationById']({pubId: this.pubId})
        },
    },
    methods: {
        openViewer(index) {
            if (!this.publication) return;
            this.$modal.show(PublicationMediaViewer, {
                'chId': this.publication.chId,
                'pubId': this.publication.pubId,
                'index': index,
            }, {
                name: 'viewer',
                delay: 200,
                width: '100%',
                height: '100%',
                classes: ['v--modal', 'v--modal-box', 'no-gap-on-top']
            });
        },
    }
}
