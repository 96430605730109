//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import modal_mixin from '../modal/modal-mixin'
import DateRangePicker from '../settings/DateRangePicker.vue';
import CustomSelect from '../custom/CustomSelect.vue';
import CustomInputText from '../custom/CustomInputText.vue';
import ModalBtn from '../modal/ModalBtn.vue'

import {ACS} from "../../store/modulesNames"
import {ACT_ACS_ADD_RECORD} from '../../store/actionsTypes'
import {mapActions} from 'vuex'
import moment from 'moment'
import CustomDropDown from "../custom/CustomDropDown.vue"


import { i18n } from '../../../ext/i18n'
const acs = i18n.messages[i18n.locale].acs

export default {
    name: "AcsEdit",
    components: {DateRangePicker, CustomSelect, CustomInputText, ModalBtn, CustomDropDown},
    mixins: [modal_mixin],
    data() {
        return {
            input_required: [],
            login: localStorage.getItem('om_login'),
            password: localStorage.getItem('om_password'),
            login_checked: false,
            statuses: [
                {value: 'sick', name: acs.sick, selected: false},
                {value: 'meeting', name: acs.meeting, selected: false},
                {value: 'business', name: acs.business, selected: false},
                {value: 'work-home', name: acs['work-home'], selected: false},
                {value: 'education', name: acs.education, selected: false},
                {value: 'vacation', name: acs.vacation, selected: false},
                {value: 'compensatory', name: acs.dayoff, selected: false},
                {value: 'other', name: acs.other, selected: false}
            ],
            from: 0,
            to: 0,
            selected_status: '',
            text: '',
            comment: '',
            compModalProps: {
                delay: 100,
                pivotY: 0.2,
                width: 400,
                height: 366,
                clickToClose: false
            },
        };
    },
    created() {
        this.from = moment().unix()
        this.to = moment().add(1, 'hours').unix()
    },
    watch: {
        input_required(required) {
            for (let i in required) {
                switch(required[i]) {
                case 'from':
                case 'to':
                    this.$refs.daterange.input_required[required[i]] = true;
                    break;
                case 'status':
                    this.$refs.select_status.input_required = true;
                    break;
                }
            }
        },
        from(val) {
            const index = this.input_required.indexOf('from')
            if (!!val && index > -1) this.input_required.splice(index, 1)
        },
        to(val) {
            const index = this.input_required.indexOf('to')
            if (!!val && index > -1) this.input_required.splice(index, 1)
        },
        selected_status(val) {
            const index = this.input_required.indexOf('status')
            if (!!val && index > -1) { 
                this.input_required = this.input_required.filter(i => i !== 'status')
            }
        },
    },
    computed: {
        defaultFrom() {
            if (!!this.from) {
                this.from = this.roundTo5Mins(moment.unix(this.from).startOf('minute').local())
                return this.from.format("YYYY-MM-DD HH:mm:ss")
            }
        },
        defaultTo() {
            if (!!this.to) {
                this.to = this.roundTo5Mins(moment.unix(this.to).startOf('minute').local())
                return this.to.format("YYYY-MM-DD HH:mm:ss")
            }
        },
    },
    methods: {
        setStatus(val) {
            this.selected_status = val
        },
        async save() {
            let record = {
                from: this.from ? this.from.unix() : null,
                to: this.to ? this.to.unix() : null,
                status: this.selected_status,
                text: this.text,
                comment: this.comment
            }
            
            for (let i in record) {
                if (['from', 'to', 'status'].includes(i)  && !record[i]) this.input_required.push(i);
            }
            if (this.input_required.length > 0) return
            
            try {
                await this[ACT_ACS_ADD_RECORD](record)
                this.modalClose()
            } catch (e) {
                this.modalOpen({
                    name: 'alert',
                    props: {
                        title: this.$t('errors.error'),
                        text: this.$t('cant-add-note')
                    }
                })
            }
        },
        roundTo5Mins(time){
            const round_interval = 5
            let intervals = Math.floor(time.minutes() / round_interval)
            let minutesToRound = time.minutes() % round_interval
            let minutesRounded = minutesToRound>round_interval/2 ? round_interval: 0
            let minutes = intervals * round_interval + minutesRounded
            return time.minutes(minutes)
        },
        ...mapActions(ACS, [ACT_ACS_ADD_RECORD])
    }
}
