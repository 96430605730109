//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalDialog from '../ModalDialog.vue'
import modal_mixin from '../modal-mixin'

import CustomCheckbox from '../../custom/CustomCheckboxRound.vue'

export default {
    name: "DeleteEvent",
    components: { ModalDialog, CustomCheckbox },
    mixins: [modal_mixin],
    data() {
      return {
          compModalProps: {
              delay: 200,
              pivotY: 0.45,
              width: 380,
              height: 'auto',
          },
          isOnDay: true,
          isAllRecurrence: false,
      }
    },
    computed: {
        date() {
            let dateArr = this.props.date.split('-')
            let formatted_date = dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]
            return formatted_date
        },            
        isRecurring() {
            return this.props.isRecurring || false
        },
        title() { return this.props.title },
        okText() { return this.$t('loginPage.ok') },
        cancelText() { return this.$t('cancel') }
    },
    methods: {
        onDayChecked(val) {
           this.isAllRecurrence = !val
        },
        allRecurrenceChecked(val) {
           this.isOnDay = !val
        },            
        onOk() {
            this.$nextTick(() => {
                this.props.btnOk && this.props.btnOk.cb && this.props.btnOk.cb(this.isRecurring && this.isOnDay)
            })
            this.modalClose()
        },
        onCancel() {
            this.modalClose();
        }
    }
}
