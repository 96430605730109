//
//
//
//
//
//
//

export default {
    name: 'custom-checkbox',
    props: {
        default: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        tabindex: {
            type: [String, Number],
            required: false
        }
    },
    data() {
        return {
            checked: this.default
        };
    },
    watch: {
        checked() {
            this.$emit('update:checkbox', this.checked);
        }
    }
}
