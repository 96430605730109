export default {
    emitters: {
        pressDialKey(key) {
            this.log(`assistant-press-dial-key: > ${key}`)
            let event = 'press-dial-key'
            if (this.serverApi >= 11) event = `assistant-${event}`
            this.socket.emit(event, {key})
        },
        dialNumber(digits) {
            this.log(`assistant-dial-number: > ${digits}`)
            let event = 'dial-number'
            if (self.serverApi >= 11) event = `assistant-${event}`
            this.socket.emit(event, {digits})
        },
        setActiveCall(id) {
            this.log(`assistant-set-active-call: > ${id}`)
            let event = 'set-active-call'
            if (self.serverApi >= 11) event = `assistant-${event}`
            this.socket.emit(event, {id})
        },
        releaseCall(id) {
            this.log(`assistant-release-call: > ${id}`)
            let event = 'release-call'
            if (self.serverApi >= 11) event = `assistant-${event}`
            this.socket.emit(event, {id})
        },
        transit(data) {
            this.log(`assistant-transit: > ${JSON.stringify(data)}`)
            let event = 'transit'
            if (self.serverApi >= 11) event = `assistant-${event}`
            this.socket.emit(event, data)
        },
        conference(data) {
            this.log(`assistant-conference: > ${data}`)
            let event = 'conference'
            if (self.serverApi >= 11) event = `assistant-${event}`
            this.socket.emit(event, data)
        },
        microphone() {
            this.log('assistant-microphone: > ')
            let event = 'microphone'
            if (self.serverApi >= 11) event = `assistant-${event}`
            this.socket.emit(event)
        },
        speaker() {
            this.log('assistant-speaker: > ')
            let event = 'speaker'
            if (self.serverApi >= 11) event = `assistant-${event}`
            this.socket.emit(event)
        },
        dnd() {
            this.log('assistant-dnd: > ')
            let event = 'dnd'
            if (self.serverApi >= 11) event = `assistant-${event}`
            this.socket.emit(event)
        },
        getCallInfo(id) {
            return new Promise((resolve, reject) => {
                this.log('assistant-get-call-info: > ' + id)
                let event = 'get-call-info'
                if (self.serverApi >= 11) event = `assistant-${event}`
                this.socket.emit(event, {id}, res => resolve(res))
            })
        },
        getCallsInfo() {
            return new Promise((resolve, reject) => {
                this.log('assistant-get-calls-info: >')
                let event = 'get-calls-info'
                if (self.serverApi >= 11) event = `assistant-${event}`
                this.socket.emit(event, null, res => {
                    this.log(`assistant-get-calls-info: < ${JSON.stringify(res)}`)
                    resolve(res)
                })
            })
        },
        getTaInfo() {
            return new Promise((resolve, reject) => {
                this.log('assistant-get-ta-info: >')
                let event = 'get-ta-info'
                if (self.serverApi >= 11) event = `assistant-${event}`
                this.socket.emit(event, null, res => {
                    this.log(`assistant-get-ta-info: < ${JSON.stringify(res)}`)
                    resolve(res)
                })
            })
        },
        setBoosterStatus(status) {
            this.socket.emit(event, {status}, result => {

            })
        },
    },
    listeners: {
        'call-info-event': onAssistantCallInfoEvent,
        'assistant-call-info-event': onAssistantCallInfoEvent,
        'calls-info-event': onAssistantCallsInfoEvent,
        'assistant-calls-info-event': onAssistantCallsInfoEvent,
        'ta-info-event': onAssistantTaInfoEvent,
        'assistant-ta-info-event': onAssistantTaInfoEvent,
    },
}

function onAssistantCallInfoEvent (data) {
    this.log(`bind: < : assistant-call-info-event ${JSON.stringify(data)}`)
    this.emit('assistant-call-info-event', data)
}

function onAssistantCallsInfoEvent(data) {
    this.log(`bind: < : assistant-calls-info-event ${JSON.stringify(data)}`)
    this.emit('assistant-calls-info-event', data)
}

function onAssistantTaInfoEvent (data) {
    this.log(`bind: < : assistant-ta-info-event ${JSON.stringify(data)}`)
    this.emit('assistant-ta-info-event', data)
}