//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ChatItemRightBlock',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    filters: {
        "countLimit": function(val) {
            if (+val >= 99) {
                val = '99+';
            }
            return val;
        }
    }
}
