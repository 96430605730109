//
//
//
//
//
//
//
//
//

import {ACT_INVITE_GUEST_CONTACT} from '../../store/actionsTypes'
import {mapActions} from 'vuex'
import {CONTACTS} from '../../store/modulesNames'

import CustomInputText from '../custom/CustomInputText.vue';
import ModalBtn from '../modal/ModalBtn.vue'

import modal_mixin from '../modal/modal-mixin'

export default {
    name: 'AddGuestContact',
    components: {CustomInputText, ModalBtn},
    mixins: [modal_mixin],
    data() {
        return {
            email: '',
            title: this.$t('invite-contact'),
            compModalProps: {
                delay: 200,
                width: 400,
                height: 'auto',
                clickToClose: false,
            },
        }
    },
    methods: {
        async send() {
            let data = { email: this.email }

            try {
                await this[ACT_INVITE_GUEST_CONTACT](data)
                this.modalClose()
            } catch (e) {
                this.modalOpen({
                    name: 'alert',
                    props: {
                        title: this.$t('errors.error'),
                        text: this.$t('invite-contact-error')
                        //text: this.$t('cant-add-note')
                    }
                })
            }
        },
        ...mapActions(CONTACTS, [ACT_INVITE_GUEST_CONTACT])
    }
}
