//
//
//
//
//
//
//
//
//
//
//


import { i18n } from '../../../ext/i18n'
import moment from 'moment'

    export default {
        name: "acs-absence-reason",
        props: {
            currentJournal: {
                type: Object,
                required: true
            }
        },
        filters: {
            toDataFormat(val) {
              return  moment.unix(val).format('DD.MM.YYYY HH:mm')
            },
            statusToText(val) {
                let acs = i18n.messages[i18n.locale].acs
                let res;
                switch (val) {
                    case 'meeting':
                        res = acs.meeting;
                        break;
                    case 'business':
                        res = acs.business;
                        break;
                    case 'work-home':
                        res = acs['work-home'];
                        break;
                    case 'education':
                        res = acs.education;
                        break;
                    case 'vacation':
                        res = acs.vacation;
                        break;
                    case 'compensatory':
                        res = acs.dayoff;
                        break;
                    case 'sick':
                        res = acs.sick;
                        break;
                    case 'other':
                        res = acs.other;
                        break;
                }
                return res;
            },
        }
    }
