//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex"
import {USERDATA} from "../../store/modulesNames"
import {GET_ACTIVE_MICROPHONE} from "../../store/gettersTypes"

import AudioMsgRecorder from "./AudioMsgRecorder.js"
import { createWorkletNode } from "../../common/Audio.js"

export default {
    name: "AudioMsgRecorder",
    data() {
        return {
            recordingAudioMessage: false,
            recordTime: 0,
            recordAudioInterval: null,
            recordAudioContext: null,
            recordAudioWorkletNode: null,
            mediaRecorder: null,
        }
    },
    computed: {
        recordTimeText() {
            let minutes = Math.max((this.recordTime / 60) || 0, 0)
            let seconds = Math.max((this.recordTime % 60) || 0, 0)
            minutes = Math.floor(minutes).toString().padStart(2, '0')
            seconds = seconds.toString().padStart(2, '0')
            return `${minutes}:${seconds}`
        },
        ...mapGetters(USERDATA, [GET_ACTIVE_MICROPHONE]),
    },
    mounted() {
        this.recordAudio()
    },
    methods: {
        async recordAudio() {
            if (!this[GET_ACTIVE_MICROPHONE]) {
                this.modalOpen({
                    name: 'alert',
                    props: {
                        title: this.$t('errors.error'),
                        text: this.$t('no-mic')
                    }
                });
                return this.$emit('close');
            }
            const processorBlob = new Blob([AudioMsgRecorder], { type: 'text/javascript' });
            const processorURL = URL.createObjectURL(processorBlob);
            let { audioContext, audioWorkletNode } = await createWorkletNode(processorURL, 'audio-msg-recorder')
            this.recordAudioContext = audioContext
            this.recordAudioWorkletNode = audioWorkletNode
            try {
                this.recordStream = await navigator.mediaDevices.getUserMedia({audio: true, video: false})
            } catch (e) {
                console.log(`recordAudio error ${e.message}`)
                this.$emit('close')
                throw e
            }

            this.recordStreamNode = audioContext.createMediaStreamSource(this.recordStream)

            this.recordStreamNode.connect(audioWorkletNode).connect(audioContext.destination)

            this.recordingAudioMessage = true;
            this.disablingWindow = document.querySelector('#disabled');
            this.disablingWindow.style.display = 'block';
            this.recordAudioInterval = setInterval(() => {
                this.recordTime += 1;
            }, 1000);

            /*navigator.mediaDevices.getUserMedia({ audio: true})
             .then(stream => {
             this.mediaRecorder = new MediaRecorder(stream);
             const voice = [];
             this.mediaRecorder.start();
             this.mediaRecorder.addEventListener("dataavailable", function(event) {
             voice.push(event.data);
             });
             this.mediaRecorder.addEventListener("stop", function() {
             const voiceBlob = new Blob(voice, {
             type: 'audio/wav'
             });
             self.sendFile(voiceBlob);
             });
             });*/
        },
        async cancelVoiceMessage () {
            //this.mediaRecorder = null;
            await this.clearVoiceMessage()
            this.recordTime = 0
        },
        async sendVoiceMessage () {
            //this.mediaRecorder.stop();
            if (!this.recordTime || this.recordTime < 1) return
            let buffer = await new Promise((resolve, reject) => {
                if (!this.recordAudioWorkletNode) reject()

                this.recordAudioWorkletNode.port.onmessage = (e) => {
                    if (e.data.eventType === 'stopped') resolve(e.data.buffer)
                }

                this.recordAudioWorkletNode.port.postMessage({type: 'stop'})
            })


            try {
                const voiceBlob = new Blob([buffer], { type: 'audio/wav' })
                this.$emit('voice-msg', voiceBlob)
                //await self.sendFile(voiceBlob)
            } catch (e) {}

            await this.clearVoiceMessage()
            setTimeout(() => this.recordTime = 0, 1000)

            /*const voiceBlob = new Blob([buffer], { type: 'audio/wav' })

             var url = URL.createObjectURL(voiceBlob);

             var a = document.createElement("a");
             document.body.appendChild(a);
             a.style = "display: none";
             a.href = url;
             a.download = "sample.wav";
             a.click();
             window.URL.revokeObjectURL(url);*/
        },
        async clearVoiceMessage() {
            this.recordStream && this.recordStream.getTracks().forEach(track => track.stop())
            this.recordStreamNode && this.recordStreamNode.disconnect()
            this.recordAudioWorkletNode && this.recordAudioWorkletNode.disconnect()
            //await this.recordAudioContext.close()
            this.recordAudioWorkletNode = null
            this.recordAudioContext = null
            this.recordingAudioMessage = false
            clearInterval(this.recordAudioInterval)
            this.recordAudioInterval = null
            this.disablingWindow.style.display = 'none'
            this.$emit('close')
        },
    }
}
