var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "remote" }, [
    _c("div", { staticClass: "component-header text-center" }),
    _c(
      "div",
      { staticClass: "component-section" },
      [
        _c(
          "div",
          { staticClass: "qr-code" },
          [
            _c("qr-code", {
              attrs: { text: _vm.qr_text, size: +250, color: _vm.getQrColor }
            })
          ],
          1
        ),
        !_vm.isElectron
          ? _c("custom-checkbox", {
              staticClass: "input",
              attrs: {
                checkbox: _vm.remember_me,
                label: _vm.$t("loginPage.logged-in"),
                tabindex: 1
              },
              on: {
                "update:checkbox": function($event) {
                  _vm.remember_me = $event
                }
              }
            })
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "component-footer" }, [
      _c("div", { staticClass: "description text-center" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("loginPage.mobile-desc")))])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }