//
//
//
//
//
//
//
//
//
//

import MainContentContainer from "../main/body/MainContentContainer.vue";
import ListItem from "../custom/ListItem.vue";
import LanguageSelect from '../modal/LanguageSelect.vue'
export default {
  name: 'language-settings',
  components: { MainContentContainer, ListItem, LanguageSelect },
  data () {
    return {

    }
  },
  methods: {
    openModal () {
      this.modalOpen({
        component: LanguageSelect
      })
    }
  }
}
