var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "central profile" },
    [
      _c(
        "main-content-container",
        [
          _c("template", { slot: "default" }, [
            _c(
              "div",
              { staticClass: "items-list" },
              [
                _c(
                  "list-item",
                  {
                    attrs: { title: _vm.fio, footer: _vm.login, link: true },
                    nativeOn: {
                      click: function($event) {
                        return _vm.toggleSideProfile($event)
                      }
                    }
                  },
                  [
                    _c("icon-image", {
                      attrs: {
                        slot: "media",
                        src: _vm.contact.photo,
                        text: _vm.fio
                      },
                      slot: "media"
                    })
                  ],
                  1
                ),
                _c(
                  "list-item",
                  {
                    attrs: {
                      title: _vm.$t("mainPage.change-status"),
                      link: true
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.showDndDialog($event)
                      }
                    }
                  },
                  [
                    _c("ul", { attrs: { slot: "after" }, slot: "after" }, [
                      _c(
                        "li",
                        {
                          staticClass: "middle",
                          class: _vm.dnd ? "color-red" : ""
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.dnd
                                ? _vm.$t("mainPage.dnd")
                                : _vm.$t("mainPage.available")
                            )
                          )
                        ]
                      ),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showExpTime,
                              expression: "showExpTime"
                            }
                          ],
                          staticClass: "middleExpTime"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("mainPage.until")) +
                              " " +
                              _vm._s(_vm._f("formatTime")(_vm.expTime))
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _c("list-item", {
                  staticClass: "color-red",
                  attrs: {
                    title: _vm.$t("mainPage.logout"),
                    link: true,
                    chevron: false
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.logout($event)
                    }
                  }
                })
              ],
              1
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }