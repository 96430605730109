//
//
//
//
//
//

import AcsEdit from "./AcsEdit.vue";

export default {
    name: "AcsAddBtn",
    components: {AcsEdit},
    methods: {
       openAcsEdit() {
           this.modalOpen({ component: AcsEdit })
       },
    }
}
