//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import {RADIO} from '../../store/modulesNames'
import {
    RADIO_IS_TURN_ON,
    GET_CURRENT_RADIO_TYPE,
} from '../../store/gettersTypes'

import { RADIO_TYPE } from '../../store/modules/radio'

import TetraSpeech from "./TetraSpeech.vue"
import TetraSignal from "./TetraSignal.vue"
import RcSpeech from "./RcSpeech.vue"
import RcSignal from "./RcSignal.vue"
export default {
    name: "RadioAudio",
    components: {
        TetraSignal,
        RcSignal,
        'rc-speech': RcSpeech,
        TetraSpeech
    },
    computed: {
        isRc() { return this[GET_CURRENT_RADIO_TYPE] === RADIO_TYPE.ROSCHAT },
        isTetra() { return this[GET_CURRENT_RADIO_TYPE] === RADIO_TYPE.TETRA },
        ...mapGetters(RADIO, {
            isTurnOn: RADIO_IS_TURN_ON,
            [GET_CURRENT_RADIO_TYPE]: GET_CURRENT_RADIO_TYPE,
        })
    },
}
