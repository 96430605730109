//
//
//
//
//
//
//
//
//
//

import PublicationHeader from './PublicationHeader.vue'
import PublicationFooter from './PublicationFooter.vue'
import PublicationBody from './PublicationBody.vue'

export default {
    name: "Publication",
    components: {PublicationBody, PublicationFooter, PublicationHeader},
    props: ['chId', 'pubId'],
    computed: {
        channel() { return this.$store.getters['channels/getChannel']({chId: this.chId}); },
        publication() { return this.$store.getters['channel/getPublicationById']({pubId: this.pubId}); }
    }
}
