//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex'
import {
    GET_SELECTED_CHAT,
    GET_BOT_BY_ID,
    GET_PHONE_SHOW,
    GET_INFO_OPEN,
    GET_CHAT,
    GET_MY_CONTACT,
    GET_MERGED_CONTACT_BY_ID,
    GET_CONTACT_STATUS_TEXT,
    GET_CHAT_TYPINGS,
    GET_SERVER_API,
    GET_IS_ROLES_SUPPORTED,
    GET_USER_PARAMS,
    GET_PHONE_LIST_BY_ID,
} from "../../../store/gettersTypes"
import {
    ACT_INFO_PUSH,
    ACT_INFO_TOGGLE,
    ACT_MODAL_OPEN,
    ACT_INFO_CLEAR,
    ACT_SET_CHAT_MUTED,
    ACT_UPDATE_CONTACT_STATUS,
    CALLS_CONTROLLER
} from "../../../store/actionsTypes"
import {
    CHATS,
    BOTS,
    PHONE,
    INFO,
    MODAL,
    CONTACTS,
    PHONE_CNTL,
    LOGIN,
    USERDATA,
} from "../../../store/modulesNames"

import { i18n } from '../../../../ext/i18n'
import IconImage from '../../IconImage.vue'
import TypingStatus from "../../listItems/TypingStatus.vue"
import {CONTACT_FIELD_TYPES} from '../../../constants'

export default {
    name: "MainHeaderContact",
    components: {
        'icon-image': IconImage,
        TypingStatus,
    },
    created() {
        this[ACT_UPDATE_CONTACT_STATUS](this.cid)
    },
    watch: {
        cid(cid) {
            this[ACT_UPDATE_CONTACT_STATUS](cid)
        }
    },
    data() {
        return {
            showTitle: false,
            canBack: true,
        }
    },
    computed: {
        cid() { 
            return this[GET_SELECTED_CHAT].cid 
        },
        cidType: () => declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER,
        getTitle() {
            return this.showTitle ? this.contact.fio : ''
        },
        contact() {
            let bot = this.$store.getters[`${BOTS}/${GET_BOT_BY_ID}`](this.cid);
            if (bot) return {photo: bot.photo, fio: bot[CONTACT_FIELD_TYPES.BOTTITLE], isBot: true, temp: Boolean(bot.temp)};
            if(this.cid === 0) {
                let result = this[GET_CHAT]({cid: this.cid, cidType: 'user'});
                result.fio = result.name;
                return result;
            }
            return this[GET_MERGED_CONTACT_BY_ID](this.cid);
        },
        status_text() {
            let mainPage = i18n.messages[i18n.locale].mainPage
            if (this.contact.isBot) return mainPage.bot;
            if (this.cid === 0) return mainPage['sys-msgs'];
            return this[GET_CONTACT_STATUS_TEXT]({cid: this.cid});
        },
        myContactActions() {
            return this[GET_MY_CONTACT].actions || []
        },
        contactActions() {
            return this.contact && this.contact.actions || {}
        },
        isRolesModelSupported() {
            return this[GET_IS_ROLES_SUPPORTED]
        },
        isViewProfileAllowed() {
            if (!this.isRolesModelSupported) return true
            let isAllowed =true
            if (this.contactActions.hasOwnProperty('view-contact')) {
                isAllowed = this.contactActions["view-contact"]
            }
            return isAllowed
        },
        isCallAllowed() {
            if (!this.isRolesModelSupported) return true
            if (!this.isViewProfileAllowed) return false
            let isMyCallAllowed, isUserCallAllowed
            const myActions = this.myContactActions
            isMyCallAllowed = !!myActions["make-call"]
            const userActions = this.contactActions
            isUserCallAllowed = !!userActions["make-call"] && !!userActions["accept-call"]
            return isMyCallAllowed && isUserCallAllowed
        },
        isChatAllowed() {
            if (!this.isRolesModelSupported) return true
            if (!this.isViewProfileAllowed) return false
            let isMyChatAllowed, isUserChatAllowed
            const myActions = this.myContactActions
            isMyChatAllowed = !!myActions["send-message"]
            const userActions = this.contactActions
            isUserChatAllowed = !!userActions["send-message"] && !!userActions["receive-message"]
            return isMyChatAllowed && isUserChatAllowed
        },
        isDisabledDirectCall() {
            const myUserParams = this[GET_USER_PARAMS]
            let isDisabled = myUserParams.hasOwnProperty("disableDirectCall") && myUserParams.disableDirectCall
            return isDisabled
        },
        isClientCallsProhibited() {
            if (localStorage.getItem('assistant') === 'none') return true
            return false
        },
        contactPhone() { 
            let phones
            if(this.cid) phones = this[GET_PHONE_LIST_BY_ID](this.cid, this.isSearched)
            return phones && phones.length && phones[0].value || false
        },
        isCallBtnEnabled() {
            let isEnabled = this.isCallAllowed
            if (this.isClientCallsProhibited) return false
            let userPhone = this.contactPhone
            if (userPhone) return true
            isEnabled = isEnabled && !this.isDisabledDirectCall
            return isEnabled
        },
        isAssistantType() {
            const storedType = localStorage.getItem('assistant')
            if (storedType !== 'none' && storedType !== 'roschat' && storedType !== 'sip') return true
            return false
        },
        callIconTitle() {
            let retVal = this.isCallAllowed ? this.$t('mainPage.voice-call') : ''
            return retVal
        },
        typing: function () {
            let { cid, cidType } = this
            return Boolean(this[GET_CHAT_TYPINGS]({cid, cidType}).length)
        },
        ...mapGetters(CHATS, [GET_SELECTED_CHAT, GET_CHAT, GET_CHAT_TYPINGS]),
        ...mapGetters(INFO, [GET_INFO_OPEN]),
        ...mapGetters(CONTACTS, [GET_IS_ROLES_SUPPORTED, GET_MY_CONTACT, GET_MERGED_CONTACT_BY_ID, GET_CONTACT_STATUS_TEXT, GET_PHONE_LIST_BY_ID]),
        ...mapGetters(LOGIN, [GET_SERVER_API]),
        ...mapGetters(USERDATA, [GET_USER_PARAMS]),
    },
    methods: {
        headerMouseOver(e) {
            if (e.target.className === 'fiospan') {
                const childEl = e.target
                const childW = childEl.offsetWidth
                const parentW = e.relatedTarget && e.relatedTarget.offsetWidth
                childW > parentW ? this.showTitle = true : this.showTitle = false
            }
        },
        doCall(type) {
            let phone = this.$store.getters[`${PHONE}/${GET_PHONE_SHOW}`];
            if(!phone.show) {
                let data;
                if(type === 'audio') data = {cid: this.cid, number: false, video: false}
                if(type === 'video') data = {cid: this.cid, number: false, video: true}
                if ((this.isDisabledDirectCall || this.isAssistantType) && this.contactPhone) {
                    data.number = this.contactPhone
                }
                this.$store.dispatch(`${PHONE_CNTL}/${CALLS_CONTROLLER}`, {type: 'call', data})
            }
        },
        doOpenInfo: function () {
            this[ACT_INFO_PUSH]({type: 'contact-info', params: {cid: this.cid}})
        },
        doMute: function () {
            this.modalOpen({
                name: 'chat-mute',
                props: {
                    cid: this.cid,
                    cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER
                }
            })
        },
        doUnMute: function () {
            this[ACT_SET_CHAT_MUTED]({ cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER, cid: this.cid})
        },
        doMenu: function (event, position) {
            let mainPage = i18n.messages[i18n.locale].mainPage
            let handlers = [],
                open = this.cmOpen;
            if (!this[GET_INFO_OPEN]) handlers.push({item_name: mainPage['contact-info'], handler: this.doToggleOpenInfo});
            if (this.$store.getters['chats/isMuted']({
                cid: this.cid,
                cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER
            })) {
                handlers.push({item_name: mainPage.unmute, handler: this.doUnMute});
            } else {
                handlers.push({item_name: mainPage.mute, handler: this.doMute});
            }
            handlers.push({
                item_name: mainPage['delete-chat'], handler: () => {
                    this[ACT_MODAL_OPEN]({
                        name: 'remove-chat',
                        props: {
                            cid: this.cid,
                            cidType: this.cidType
                        }
                    })
                }
            });

            open(event, handlers, position);
        },
        doToggleOpenInfo() {
            const openedInfo = this[GET_INFO_OPEN]
            if (openedInfo && openedInfo.type === 'search-in-chat') this[ACT_INFO_CLEAR]()
            this[ACT_INFO_TOGGLE]({type: 'contact-info', params: {cid: this.cid}})
        },
        toggleSearchChat() {
            this[ACT_INFO_TOGGLE]({type: 'search-in-chat', params: {cid: this.cid}})
        },
        openSearchChat() {
            this[ACT_INFO_CLEAR]()
            this[ACT_INFO_PUSH]({type: 'search-in-chat', params: {cid: this.cid}})
        },
        ...mapActions(CHATS, [ACT_SET_CHAT_MUTED]),
        ...mapActions(INFO, [ACT_INFO_PUSH, ACT_INFO_TOGGLE, ACT_INFO_CLEAR]),
        ...mapActions(MODAL, [ACT_MODAL_OPEN]),
        ...mapActions(CONTACTS, [ACT_UPDATE_CONTACT_STATUS]),
    }
}
