var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("label", { staticClass: "custom-checkbox" }, [
      _vm._v(_vm._s(_vm.label)),
      _vm.checked
        ? _c("i", { staticClass: "fas fa-check-circle" })
        : _c("i", { staticClass: "fal fa-circle" }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.checked,
            expression: "checked"
          }
        ],
        attrs: {
          type: "checkbox",
          tabindex: _vm.tabindex ? _vm.tabindex : 0,
          disabled: _vm.readonly
        },
        domProps: {
          checked: Array.isArray(_vm.checked)
            ? _vm._i(_vm.checked, null) > -1
            : _vm.checked
        },
        on: {
          change: function($event) {
            var $$a = _vm.checked,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.checked = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.checked = $$c
            }
          }
        }
      })
    ]),
    _vm.sublabel
      ? _c("div", { staticClass: "sublabel" }, [_vm._v(_vm._s(_vm.sublabel))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }