//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProgressCircle from '../ProgressCircle.vue';
import Slider from '../Slider.vue';
import IconImage from '../IconImage.vue';
import PublicationEditableItemText from "./PublicationEditableItemText.vue"
import PublicationFilesMixin from './PublicationFilesMixin';
import CustomAudio from "../custom/CustomAudio.vue"

export default {
    name: "PublicationAudioItem",
    components: {
        PublicationEditableItemText, slider: Slider, "progress-circle": ProgressCircle, IconImage, CustomAudio},
    mixins: [PublicationFilesMixin],
    props: {
        data: {
            type: Object,
            required: true
        },
        edit: {
            type: Boolean,
            default: false
        },
        unixLastTime: {
            type: Number,
            default: 0,
            required: false
        },
        progressWidth: {
            type: Number,
            default: 48,
            required: false
        },
        progressHeight: {
            type: Number,
            default: 48,
            required: false
        },
    },
    data() {
        return {
            played: false,
            playStarted: false,
            timeUpdateInt: 0,
            current_second: 0,
            volume: 1,
            loaded: false,
            loading: false,
            load_progress: 0,
            name: this.data.name,
        }
    },
    computed: {
        current_time() { return date_helper.unixTimeToUserFormat(this.link  && this.playStarted ? this.current_second : this.data.duration, "mm:ss") },
    },
    methods: {
        async doPlayToggle() {
            const audio = this.$refs.audio;
            if (this.played) {
                audio.pause();
                this.played = !this.played;
            } else {
                if (this.link) {
                    this.play();
                } else {
                    await this.loadFile({ file: this.data.file, size: this.data.size })
                    this.$nextTick(() => this.play())
                }
            }
        },
        play() {
            const audio = this.$refs.audio;
            if (this.current_second >= this.data.duration) this.doChangeCurrentSecond(0)
            audio.play();
            this.playStarted = true
            this.timeUpdateInt = setInterval(() => {
                if (!this.played) return clearInterval(this.timeUpdateInt);
                this.current_second = audio.getCurrentSecond();
            }, 100);
            this.played = !this.played;
        },
        clearAudioInfo () {
            this.played = false
            this.playStarted = false
            this.load_progress = 0
            this.current_second = 0
            this.timeUpdateInt = clearInterval(this.timeUpdateInt)
        },
        doChangeCurrentSecond(val) {
            let audio = this.$refs.audio
            this.current_second = this.data.duration * (val / 100)
            audio.setCurrentSecond(this.current_second)
        },
        doChangeVolume(val) {
            this.volume = val / 100
        },
    },
    watch: {
        unixLastTime() {
            this.name = this.data.name;
        },
        name(value) {
            this.$emit('update', 'name', value);
        }
    }
}
