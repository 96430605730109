import ipc from "../../electron/ipc"
import moment from "moment"

class Logger {
    constructor (module) {
        this.module = module
    }

    info(text) {
        this._logWrite('info', text)
    }

    warn(text) {
        this._logWrite('warn', text)
    }

    error(e, description) {
        if (description) this._logWrite('info', description)
        this._logWrite('error', e)
    }

    _logWrite(level, data) {
        let isElectron = navigator.userAgent.indexOf('Electron') >= 0
        if (isElectron) {
            ipc.send('logs', {module: this.module, level, data: (level === 'error' ? {message: data.message, stack: data.stack} : data)})
        } else {
            if (typeof data === 'string') {
                console[level](`${moment().format('YYYY-MM-DD HH:mm:ss.SSS')} [${this.module}] ${data}`)
            } else {
                console[level](`${moment().format('YYYY-MM-DD HH:mm:ss.SSS')} [${this.module}]`, data)
            }

        }
    }

    static getLogs(last = false) {
        ipc.send('get-logs', { last })
    }
}

export default Logger