var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-settings" },
    [
      _c(
        "main-content-container",
        {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "items-list", on: { click: _vm.openModal } },
                    [
                      _c(
                        "list-item",
                        {
                          attrs: { title: _vm.$t("selected-lang"), link: true }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "after" }, slot: "after" },
                            [_vm._v(_vm._s(_vm.$t("locale")))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("template", { slot: "header" }, [
            _vm._v(_vm._s(_vm.$t("language")))
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }