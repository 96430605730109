//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import {
    GET_PROCESSINGS,
    GET_ROOM_ID,
    GET_UID,
    GET_CONTACTS,
    GET_OPTIONS,
    GET_ANSWER_STATUS,
    GET_CREATOR_CONF,
    GET_TURN_SERVER,
    GET_HOLD_CONF,
    GET_COMPONENT_DATA,
    GET_LOCALSTREAM,
    GET_PEER_CONNECTION,
    GET_IS_PHONE_MINIMIZED,
    GET_CALL_TALK_TIME,
    IS_ADD_DIALOG_OPENED,
    IS_CONF_ROOM_FULL,
    GET_PHONE_IN_FULLSCREEN,
} from '../../store/gettersTypes'
import {
    ACT_SEND_OFFER_CONFERENCE,
    ACT_CONFERENCE_TERMINATION,
    ACT_SAVE_DATA_CONF,
    ACT_ADD_LOCALSTREAM,
    ACT_ADD_PEER_CONNECTION,
    ACT_DELETE_PEER_CONNECTION,
    ACT_TOGGLE_PHONE_FULLSCREEN,
    ACT_OPEN_ADD_DIALOG,
    ACT_TOGGLE_PHONE_MINIMIZE,
} from '../../store/actionsTypes'
import { VIDEO_CONF, USERDATA, PHONE, PHONE_CNTL } from '../../store/modulesNames'

import IconImage from '../IconImage.vue'
import PhoneActionButton from '../phone/PhoneActionButton.vue'
import PhoneFunctionButton from '../phone/PhoneFunctionButton.vue'
import CallsSelector from '../phone/CallsSelector.vue'
import Vue from 'vue'
import ipc from '../../../electron/ipc'
import {PHONE_TYPES} from "../../constants"
import CustomAudio from "../custom/CustomAudio.vue"
import CustomVideo from "../custom/CustomVideo.vue"

import VideoPlug from "../../common/VideoPlug"

export default {
    name: "VideoConf",
    data() {
        return {
            width: 300,
            height: 500,
            remoteStreams: [],
            panelShow: false,
            cidsPeerConnections: [],
            draggable: false,
            mute: false,
            camera: false,
            backgrounds: [],
            sender: {},
            isVideoConf: false,
            closing: false,
            videoPlug: null,
        }
    },
    components: { CustomVideo, CustomAudio, IconImage, PhoneActionButton, PhoneFunctionButton, CallsSelector },
    created () {
        this.isVideoConf = this.videoOption
        this.updateContainerSize(true)
        window.addEventListener('unload', this.onPageUnload)

    },
    beforeDestroy() {
        window.removeEventListener('unload', this.onPageUnload)
        if (this.localStream && this.localStream.getTracks()) {
            this.localStream.getTracks().forEach(function(track) {
                track.stop()
            })
        }
        this.videoPlug && this.videoPlug.stop()
    },
    watch: {
        videoOption(val) {
            if (!this.closing) this.isVideoConf = val
        },
        processings() {
            if (this.holdConf) return
            this.processings.forEach(processing => {
                this.peerConnections.forEach(peerConnection => {
                    if(processing.cid === peerConnection.cid) {
                        peerConnection.pc.setRemoteDescription(new RTCSessionDescription({type: 'answer', sdp: processing.sdp}))  
                        processing.candidates.forEach(candidate => {
                            peerConnection.pc.addIceCandidate(new RTCIceCandidate(candidate))
                        })                            
                    }
                })
            })
        },
        contacts() {
            this.refreshVideos()
            const count = this.contacts.length
            if (this.peerConnections.length > count) this.deletePeerConections()
            this.updateContainerSize()
        },
        time() {
            this.$emit('timeconf', this.time)
        },
        holdConf () {
            this.doHoldConf()
        },
        roomId() {
            let data = this.$store.getters[`${VIDEO_CONF}/${GET_COMPONENT_DATA}`](this.roomId)
            if (data) this.activeConf(data)
        }
    },
    computed: {
        styleParams () {
            if(this.videoConfFullScreen) {
                return { height: 100 + '%', width: 100 + '%', position: 'absolute' }
            } else {
                return { height: this.height + 'px', width: this.width + 'px', /*position: 'sticky',*/ borderRadius: 10 + 'px' }
            }
        },
        iconImageClass() {
             switch (this.contacts.length) {
                case 0:
                case 1:                                          
                case 2:
                    return 'extra-big' 
                case 3:
                    return 'icon-medium' 
                case 4:
                case 5:
                case 6:                        
                    return 'icon-small'
            }     
        },
        audioIconWidth() {
            switch (this.contacts.length) {    
                case 0:
                case 1:                                        
                case 2:
                    return '100%'
                case 3:
                    return '50%'
                case 4:
                case 5:
                case 6:                        
                    return '33%'
            } 
        },
        videoWidth() {
            switch (this.contacts.length) {    
                case 0:
                case 1:                        
                case 2:
                    return '100%'
                case 3:
                case 4: 
                    return '50%'
                case 5:
                case 6:
                    return '33.3%'
            }
        },
        uid() {
            return this.$store.getters[`${USERDATA}/${GET_UID}`]
        },
        contacts() {
            if(!this.turnServer) return []
            let contacts = []
            console.log('CONTACTS', this[GET_CONTACTS])

            this[GET_CONTACTS].forEach(async (item, i) => {

                let contact = this.getContact(item.cid)

                if (this.holdConf) {
                    contacts.push({contact, status: item.status})
                    return
                }

                if(this.cidsPeerConnections.includes(item.cid)) {
                    contacts.push({contact, status: item.status})
                    return
                }
                contacts.push({contact, status: item.status})
                this.backgrounds[i] = true
                this.cidsPeerConnections.push(item.cid)
                if(item.cid !== this.uid) {
                    let pc = await this.createPC({cid: item.cid, stream: false, index: i})
                    this.$store.dispatch(`${VIDEO_CONF}/${ACT_ADD_PEER_CONNECTION}`, { pc, cid: item.cid })             
                } else this.showLocalVideo(i)
               //if(!this.timerId) this.startTimer()
            })
            return contacts
        },
        time() {
            return this[GET_CALL_TALK_TIME](PHONE_TYPES.CONFERENCE, this.roomId)
        },
        addDisabled() {
            return this[IS_ADD_DIALOG_OPENED] || this[IS_CONF_ROOM_FULL]
        },
        videoOption() {
            return Boolean(this.options && this.options.video)
        },
        ...mapGetters(VIDEO_CONF, {
            processings: GET_PROCESSINGS,
            roomId: GET_ROOM_ID,
            [GET_CONTACTS]: GET_CONTACTS,
            options: GET_OPTIONS,
            answerStatus: GET_ANSWER_STATUS,
            isCreatorConf: GET_CREATOR_CONF,
            turnServer: GET_TURN_SERVER,
            holdConf: GET_HOLD_CONF,
            localStream: GET_LOCALSTREAM,
            peerConnections: GET_PEER_CONNECTION,
            [IS_ADD_DIALOG_OPENED]: IS_ADD_DIALOG_OPENED,
            [IS_CONF_ROOM_FULL]: IS_CONF_ROOM_FULL,
        }),
        ...mapGetters(PHONE_CNTL, {
            minimize: GET_IS_PHONE_MINIMIZED,
            videoConfFullScreen: GET_PHONE_IN_FULLSCREEN,
            [GET_CALL_TALK_TIME]: GET_CALL_TALK_TIME,
        })
    },
    methods: {
        getContact(cid) {
            return this.$store.getters['contacts/getMergedContactById'](cid)
        },
        setBackground(index) {
            this.$set(this.backgrounds, index, false)
        },
        getStatus(status) {
            let res = ''
            switch(status) {
                case 'joined':
                    res = this.$t('phone.connecting')
                    break
                case 'invited':
                    res = this.$t('phone.invitation-sent')
                    break
                case 'ringing': 
                    res = this.$t('phone.waiting-for-reply')
                    break        
            }
            return res
        },
        deletePeerConections() {
            let contactsCids = this.contacts.map(contact => contact.contact.cid)

            deleteArrElements(this.cidsPeerConnections, contactsCids)
            this.$store.dispatch(`${VIDEO_CONF}/${ACT_DELETE_PEER_CONNECTION}`, contactsCids)
            deleteArrElements(this.remoteStreams, contactsCids)

            function deleteArrElements(arr, contactsCids) {
                let indexes = []
                arr.forEach((item, i) => {
                    let cid = item.cid || item
                    let res = contactsCids.includes(cid)
                    if(!res) indexes.push(i)
                })
                indexes.forEach(index => arr.splice(index, 1))
            }
            this.refreshVideos()          
        },
        onDraggable(value) {
            this.draggable = value
        },
        refreshVideos() {
            setTimeout(() => {
                //if (!this.minimize) {
                    const video = document.getElementById('video-conf-item-'+ this.uid)
                    if (video) video.srcObject = this.localStream
                //}
                this.remoteStreams.forEach(item => {
                    let video = document.getElementById('video-conf-item-' + item.cid)
                    if (video) video.srcObject = item.stream
                })
            }, 500)                
        },
        async createPC({cid, stream}) {
            var config = {iceServers: [{ url: 'turn:' + this.turnServer.server, username: this.turnServer.username, credential: this.turnServer.password}]}
            let candidates = []
            let sdp
            let pc = new RTCPeerConnection(config)

            if(stream) {
                if (pc.addTransceiver) {
                    let direction = (cid === this.uid) ? 'sendonly' : 'recvonly'
                    pc.addTransceiver(stream.getAudioTracks()[0], {direction})
                    if (this.options.video) pc.addTransceiver(stream.getVideoTracks()[0], {direction})
                } else {
                    this.sender.audioTrack = pc.addTrack(stream.getAudioTracks()[0], stream)
                    if (this.options.video) this.sender.videoTrack = pc.addTrack(stream.getVideoTracks()[0], stream)
                }
            } else {
                pc.ontrack = event => {
                    const video = document.getElementById('video-conf-item-' + cid)
                    video.srcObject = event.streams[0]
                    let include = this.remoteStreams.some(item => item.cid === cid)
                    if(!include) this.remoteStreams.push({cid, stream: event.streams[0]})
                }
            }
            let offer = await pc.createOffer({'offerToReceiveAudio': 1, 'offerToReceiveVideo': 1})               
            try {                        
                sdp = offer.sdp
                pc.setLocalDescription(offer)
            } catch(e) {
                console.log('reason', e)
            }

            let timer = null
            let offerSended = false
            pc.onicecandidate = event => {
                if(offerSended) return

                if (event.candidate) {
                    candidates.push(event.candidate)
                    clearTimeout(timer)
                    timer = setTimeout(() => {
                        this.$store.dispatch(`${VIDEO_CONF}/${ACT_SEND_OFFER_CONFERENCE}`, {
                            cid,
                            roomId: this.roomId,
                            msg: 'offer',
                            sdp,
                            candidates: JSON.stringify(candidates)
                        })

                        offerSended = true   
                    }, 500)
                }
            }
            return pc       
        },
        async showLocalVideo() {
            try {
                let stream = await this.getStream()
                if(this.options.video) {
                    const video = document.getElementById('video-conf-item-'+ this.uid)
                    video.srcObject = stream
                }
                this.$store.dispatch(`${VIDEO_CONF}/${ACT_ADD_LOCALSTREAM}`, stream)
                let pc = await this.createPC({cid: this.uid, stream})
                this.$store.dispatch(`${VIDEO_CONF}/${ACT_ADD_PEER_CONNECTION}`, { pc, cid: this.uid })
            } catch(e) {
                console.log('rtc', 'MediaWorker.startLocal: getUserMedia() error: ' + e.message);
            }
        },
        async getStream() {
            let stream = await navigator.mediaDevices.getUserMedia({...{audio: this.options.audio}})
            if(this.options.video) {
                let videoStream
                try {
                    videoStream = await navigator.mediaDevices.getUserMedia({...{video: this.options.video}})
                } catch (e) {
                    this.videoPlug = new VideoPlug({text: (this.getContact(this.uid) || {}).fio})
                    videoStream = this.videoPlug.start()
                }
                stream = new MediaStream([...stream.getTracks(), ...videoStream.getTracks()])
            }
            return stream
        },
        showPanels(e, value) {
            this.panelShow = value             
        },
        doFullScreen() {
            this[ACT_TOGGLE_PHONE_FULLSCREEN]()
        },
        close() {
            this.closing = true
            this.$store.dispatch(`${VIDEO_CONF}/${ACT_CONFERENCE_TERMINATION}`, this.roomId)
        },
        toggleMinimize() {
            // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//             //this.minimize = !this.minimize
//             //setTimeout(() => { ipc.send('phone-minimize', { minimize: this.minimize }) }, 40)
//             setTimeout(this[ACT_TOGGLE_PHONE_MINIMIZE], 40)
//             this.refreshVideos()
            // #endif

            // #if process.env.WEBPACK_BUILD_TARGET === 'web'
            if(this.minimize) {
                this[ACT_TOGGLE_PHONE_MINIMIZE]()
            } else {
                setTimeout( ()=> {
                    this[ACT_TOGGLE_PHONE_MINIMIZE]()
                    this.$store.dispatch(`${PHONE}/${ACT_PHONE_COMMANDS}`, {params: this.phone, command: 'updateStreams'});
                }, 400);
            }
            // #endif
        },
        getAlignItems(number, count) {
            if (this.backgrounds[index]) 
            switch(count) {
                case 1:
                    return 'center'
                    break
                case 2:
                    if(number === 0) return 'flex-end'
                    else if(number === 1) return 'flex-start'
                    break
                case 3:
                case 4:
                    if([0, 1].includes(number)) return 'flex-end'
                    else if([2, 3].includes(number)) return 'flex-start'
                    break
                case 5:
                case 6:
                    if([0, 1, 2].includes(number)) return 'flex-end'
                    else if([3, 4, 5].includes(number)) return 'flex-start'
                    break        
            }
        },
        addContact() {
            if (this.addDisabled) return
            this.$store.dispatch(`${VIDEO_CONF}/${ACT_OPEN_ADD_DIALOG}`)
        },
        async toggleMicrophone () {
            /*if(this.localStream.getAudioTracks()[0].enabled) { //@todo смысла кода я не понял
                let stream = await this.getStream()
                this.peerConnections.forEach(item => {
                    if(item.cid === this.uid) this.sender.audioTrack = item.pc.addTrack(stream.getAudioTracks()[0], stream)
                })                    
            } else {
                this.peerConnections.forEach(item => {
                    if(item.cid === this.uid) item.pc.removeTrack(this.sender.audioTrack)
                })
            }*/
            this.localStream.getAudioTracks()[0].enabled = !this.localStream.getAudioTracks()[0].enabled
            this.mute = !this.mute
        },
        async toggleCamera() {
            /*if(this.localStream.getVideoTracks()[0].enabled) { //@todo смысла кода я не понял + тут еще ошибка была (getAudioTracks)
                let stream = await this.getStream()
                this.peerConnections.forEach(item => {
                    if(item.cid === this.uid) {
                        this.sender.videoTrack = item.pc.addTrack(stream.getVideoTracks()[0], stream)
                    }
                })                    
            } else {
                this.peerConnections.forEach(item => {
                    if(item.cid === this.uid) {
                        item.pc.removeTrack(this.sender.videoTrack)
                    }
                })
            }*/
            this.localStream.getVideoTracks()[0].enabled = !this.localStream.getVideoTracks()[0].enabled
            this.camera = !this.camera
        },
        doHoldConf () {
            this.$store.dispatch(`${VIDEO_CONF}/${ACT_SAVE_DATA_CONF}`, {
                remoteStreams: this.remoteStreams,
                panelShow: this.panelShow,
                cidsPeerConnections: this.cidsPeerConnections,
                draggable: this.draggable,
                mute: true,
                camera: this.camera,
                //time: this.time,
                //timerId: this.timerId,
                backgrounds: this.backgrounds,
                sender: this.sender,
                //minimize: this.minimize
            })
            
        },
        activeConf (data) {                
            this.remoteStreams= data.remoteStreams
            this.panelShow = data.panelShow
            this.cidsPeerConnections = data.cidsPeerConnections
            this.draggable = data.draggable
            this.mute = data.mute
            this.camera = data.camera
            //this.time = data.time
            //this.timerId = data.timerId
            this.backgrounds = data.backgrounds
            this.sender = data.sender
            //this.minimize = data.minimize
            this.toggleMicrophone()
        },
        onPageUnload() {
            if (!this.closing) this.close()
        },
        updateContainerSize(first) {
            let { width, height } = this.calculateSizeWindow(this.contacts.length, this.isVideoConf)
            if (this.width !== width || this.height !== height) {
                this.width = width
                this.height = height
                if (!first) ipc.send('set-phone-size', { width: width + 44, height: height + 44 })
            }
        },
        calculateSizeWindow (countUsers, video) {
            if (!video) return { width: 300, height: 500 }
            switch (countUsers) {
                case 1:
                case 2:
                    return { width: 400, height: 600 }
                case 3:
                case 4:
                    return { width: 800, height: 600 }
                default:
                    return { width: 1200, height: 600 }
            }
        },
        ...mapActions(PHONE_CNTL, [ACT_TOGGLE_PHONE_MINIMIZE, ACT_TOGGLE_PHONE_FULLSCREEN]),
    },
}
