var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("videomost.conf-info-context")
      }
    },
    [
      _c(
        "template",
        { slot: "default" },
        [
          _c("confSettingsBase", {
            attrs: {
              conf: _vm.conf,
              readonly: _vm.readonly,
              fieldsToShow: _vm.fieldsToShow
            }
          })
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "edit" }, [
          _c(
            "div",
            {
              staticClass: "circle-animation conference",
              attrs: { title: _vm.$t("videomost.conf-members-choose") },
              on: {
                click: function($event) {
                  return _vm.openInviteContextMenu(_vm.conf)
                }
              }
            },
            [_c("i", { staticClass: "far fa-users" })]
          ),
          _c(
            "div",
            {
              staticClass: "circle-animation conference",
              attrs: { title: _vm.$t("videomost.conf-edit") },
              on: {
                click: function($event) {
                  return _vm.openEditConf(_vm.conf)
                }
              }
            },
            [_c("i", { staticClass: "far fa-edit" })]
          ),
          _c(
            "div",
            {
              staticClass: "circle-animation conference",
              attrs: { title: _vm.$t("videomost.conf-delete") },
              on: {
                click: function($event) {
                  return _vm.deleteConfConfirm()
                }
              }
            },
            [_c("i", { staticClass: "fal fa-trash-alt" })]
          )
        ]),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _c("modal-btn", {
              attrs: { text: _vm.$t("close") },
              on: { click: _vm.closeModal }
            }),
            _c("modal-btn", {
              attrs: { text: _vm.startConfText },
              on: { click: _vm.startConf }
            })
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }