'use strict'
// #if process.env.WEBPACK_BUILD_TARGET === 'electron'
// import {ipcRenderer} from 'electron'
// 
// let reqId = 0
// 
// const promises = {}
// 
// ipcRenderer.on('ipc-async-response', (event, {resolvedData, rejectedData, reqId}) => {
//     let promise = promises[reqId]
//     if (promise) {
//         if (rejectedData) promise.reject(rejectedData)
//         else promise.resolve(resolvedData)
//     }
//     delete promises[reqId]
// })
// 
// ipcRenderer.sendAsync = (requestName, payload) => new Promise((resolve, reject) => {
//     promises[++reqId] = { resolve, reject }
//     ipcRenderer.send('ipc-async-request', { reqId, requestName, payload })
// })
// 
// export default ipcRenderer
// #endif

// #if process.env.WEBPACK_BUILD_TARGET === 'web'
export default {
    on() {return false},
    send() {return false},
    sendSync() {return false}
}
// #endif
