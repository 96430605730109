
import MediaViewer from "./MediaViewer.vue"
import axios from 'axios'
import { DLP } from '../../store/modulesNames'
import { ACT_SEND_FILE_SAVE_EVENT } from '../../store/actionsTypes'
import { DLP_SOURCE_TYPES } from '../../store/modules/dlp'

export default {
    name: "IconViewer",
    extends: MediaViewer,
    props: ['iconType', 'id'],
    data() {
        return {
            blob: null,
            loadError: false
        }
    },
    async created() {
        try {
            let blob = await axios({
                url: this.originPath,
                method: 'GET',
                responseType: 'blob',
            })
            blob = new Blob([blob.data])
            this.blob = window.URL.createObjectURL(blob)
        } catch (e) {
            this.loadError = true
        }
        this.preload = false
    },
    destroyed() {
        if (this.blob) window.URL.revokeObjectURL(this.blob)
    },
    computed: {
        path() {
            return this.loadError ? this.originPath : this.blob
        },
        originPath() {
            return this.src.split('?').shift()
        },
        extension() {
            let file = this.originPath.split('/').pop()
            let splitedFile = file.split('.')
            return splitedFile.length > 1 ? splitedFile.pop() : ''
        },
    },
    methods: {
        getDownloadFileName() {
            return this.iconType + (this.id ? '-' + this.id : '') + '.' + (this.extension ? this.extension : 'jpeg')
        },
        onError() {
            this.preload = true
        },
        onItemSaved() {
            let payload = {
                fileName: this.originPath
            }
            switch (this.iconType) {
                case 'contact':
                    payload.sourceType = DLP_SOURCE_TYPES.CONTACT
                    payload.sourceData = { cid: this.id }
                    break
                case 'chat':
                    if (isNaN(this.id)) return
                    payload.sourceType = DLP_SOURCE_TYPES.CHAT
                    payload.sourceData = { cid: this.id, cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP }
                    break
                case 'channel':
                    payload.sourceType = DLP_SOURCE_TYPES.CHANNEL
                    payload.sourceData = { chId: this.id }
                    break
                default:
                    return
            }
            this.$store.dispatch(`${DLP}/${ACT_SEND_FILE_SAVE_EVENT}`, payload)
        },
    }
}

