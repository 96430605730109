var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-detailed-info" },
    [
      _vm._l(_vm.details, function(item, i) {
        return _c("list-item", {
          key: i,
          attrs: { header: item.title, title: item.text },
          nativeOn: {
            contextmenu: function($event) {
              $event.preventDefault()
              return _vm.doContextMenu($event, item.text)
            }
          }
        })
      }),
      _c(
        "actions-container",
        [
          _c(
            "list-item",
            {
              staticClass: "color-blue",
              attrs: {
                slot: "action",
                title: _vm.$t("export-contact"),
                link: true,
                chevron: false
              },
              nativeOn: {
                click: function($event) {
                  return _vm.showModalExportWindow($event)
                }
              },
              slot: "action"
            },
            [
              _c(
                "info-icon",
                {
                  attrs: {
                    slot: "media",
                    "icon-color": "#00aafb",
                    "position-left": true
                  },
                  slot: "media"
                },
                [
                  _c("i", {
                    staticClass: "fal fa-file-export",
                    attrs: { slot: "default" },
                    slot: "default"
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }