//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions} from 'vuex';
import login_mixin from './login-mixin';
import {ACT_LOGIN} from "../../store/actionsTypes";
import {LOGIN} from "../../store/modulesNames";

export default {
    name: "twoFA",
    props: {
        uid: {
            type: Number,
            required: true
        },
        login: {
            type: String,
            required: false
        },
        password: {
            type: String,
            required: false
        },
        remember_me: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mixins: [login_mixin],
    data() {
        return {
            input1: '',
            input2: '',
            input3: '',
            input4: '',
            resendingBtn: false,
            currentTime: 120,
            timerMin: '',
            timerSec: ''
        }
    },
    mounted() {
        this.timer();
    },
    watch: {
        currentTime() {
           if(this.currentTime === 0) this.resendingBtn = true;
        },
    },
    methods: {
        tab(e, sec) {
            if(this.input1.length && this.input2.length && this.input3.length && this.input4.length) {this.send()}
            else if(sec !== 4 && this['input' + sec] !== '') {
                e.target.nextSibling.focus();
            }
        },
        del(e, sec) {
            if(this['input' + sec] === '') {
                this['input' + (--sec)] = '';
                e.target.previousSibling.focus();
            }
        },
        async send() {
            console.log('send');
            let pin = this.input1 + this.input2 + this.input3 + this.input4;

            try {
                await proto.sendLoginPin(pin);
                let obj = {
                    'pinRequired': true,
                    'uid': this.uid,
                    'login': this.login,
                    'password': this.password,
                    'remember_me': this.remember_me,
                };
                await this.ACT_LOGIN(obj);
            } catch(e) {
                console.log(e)
                if(e === 'incorrect') this.showAlert(this.$t('errors.incorrect-code'))
                else if(e === 'expired') this.showAlert(this.$t('errors.code-expired'))
                else if(e === 'no-attempts') this.showAlert(this.$t('errors.attempts-number'))
                else if(e === 'unknown') this.showAlert(this.$t('errors.unknown'))
                this.clearInputs()
            }
        },
        clearInputs() {
            this.input1 = ''
            this.input2 = ''
            this.input3 = ''
            this.input4 = ''
        },
        async resending() {
            try {
                await proto.requestLoginPin();
            } catch(e) {
                console.log(e);
            }

        },
        timer() {
            setInterval(() => {
                this.currentTime--
                let min = Math.floor(this.currentTime/60)
                if(min) this.timerMin = min + this.$t('min')
                else this.timerMin = ''
                this.timerSec = this.currentTime%60 + this.$t('sec')
            }, 1000)
        },
        ...mapActions(LOGIN, {ACT_LOGIN})
    }
}
