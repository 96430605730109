'use strict';

import { MEDIA_DEVICES } from "../app/constants"

export default function InputMediaDevices(audioParam = true, videoParam) {

    const DEFAULT_AUDIO_ID = 'default', DEFAULT_VIDEO_ID = 'default';

    let devicesIds = { webCameraId: null, microphoneId: null };
    let localStorageUserData = null;
    if (localStorage.getItem('vuex')) {
        localStorageUserData = JSON.parse(localStorage.getItem('vuex')).userdata;
        let { activeMicrophone, activeWebcamera } = localStorageUserData;
        if (!!activeWebcamera) devicesIds.webCameraId = activeWebcamera.deviceId;
        if (!!activeMicrophone) devicesIds.microphoneId = activeMicrophone.deviceId;
    }

    let webCameraId = devicesIds.webCameraId;
    let microphoneId = devicesIds.microphoneId;

    let constraints = { audio: audioParam, video: videoParam };

    if (!!microphoneId) constraints.audio = { deviceId: { exact: microphoneId } }
    else {
        let defaultDevice = checkDefaultDevices(DEFAULT_AUDIO_ID, MEDIA_DEVICES.AUDIO_INPUT);
        if (!!defaultDevice) { 
            constraints.audio = true;
        } else {
            constraints.audio = false;
        }
    }

    if (!!webCameraId && videoParam) constraints.video = { deviceId: { exact: webCameraId } }
    else if (videoParam) {
        let defaultDevice = checkDefaultDevices(DEFAULT_VIDEO_ID, MEDIA_DEVICES.VIDEO_INPUT);
        if (!!defaultDevice) {
            constraints.video = true;
        }
    } else constraints.video = false;

    async function checkDefaultDevices(deviceId, kind) {
        try {
            let mediaDevices = await navigator.mediaDevices.enumerateDevices();
            let defaultDevice = mediaDevices.find(dev => dev.deviceId === deviceId && dev.kind === kind);
            return defaultDevice; 
        } catch (e) {
            console.error(`Media devices error: ${e.message}`);
        }
    }
    return constraints;
}