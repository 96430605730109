//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions} from 'vuex'
import {VIDEO_CONF_NEW} from '../../store/modulesNames'
import {ACT_VC_CONF_CHANGE} from '../../store/actionsTypes'

import ModalBtn from '../modal/ModalBtn.vue'
import ExtConfSettings from '../videoConfNewManagment/scheduleConferenceSettings.vue'
import confSettingsBase from './confSettingsBase.vue'

import modal_mixin from '../modal/modal-mixin'

import {CONF_SCHEDULE_PROPS} from '../../store/modules/video-conf-abstract'

export default {
    name: 'vcEdit',
    components: {ModalBtn, confSettingsBase},
    mixins: [modal_mixin],
    props: {
        conf: {
            type: Object,
            required: true,
            default: {},
        },
    },
    data () {
        return {
            mainTitle: this.$t('videomost.menu-title'),
            compModalProps: {
                delay: 100,
                pivotY: 0.44,
                width: 464,
                height: 'auto',
                clickToClose: false,
                adaptive: true,
                styles: "min-width: 387px; overflow-x: auto;"
            },
            startConfText: this.$t('videomost.conf-run'),
            editConfText: this.$t('videomost.conf-edit'),
            deleteConfText: this.$t('videomost.conf-delete'),
            confSettingsText: this.$t('videomost.conf-settings'),
            placeholder: this.$t('videomost.conf-enterway'),
            initialConfId: '',
            fieldsToShow: [
                CONF_SCHEDULE_PROPS.ID,
                CONF_SCHEDULE_PROPS.TOPIC,
                CONF_SCHEDULE_PROPS.PASSWORD,
                CONF_SCHEDULE_PROPS.START_TIME,
                CONF_SCHEDULE_PROPS.END_TIME,
            ],
        }
    },
    mounted () {
        if (!!this.conf.confid) this.initialConfId = this.conf.confid
    },
    computed: {
        startUTime () {
            return +this.conf.startUTime
        },
        finishUTime () {
            return +this.conf.finishUTime
        },
        correctFromTo () {
            const startUTime = +this.conf.startUTime
            const finishUTime = +this.conf.finishUTime
            return startUTime && finishUTime && startUTime < finishUTime
        },
    },
    methods: {
        async confirmConfChange (conf) {
            let changes = this.$refs.settings.getConf()
            console.log("🚀 ~ file: editConference.vue:83 ~ confirmConfChange ~ changes:", changes)
            console.log(changes)
            const result = await this[ACT_VC_CONF_CHANGE](changes)

            this.modalClose()
            this.$bus.$emit('reload-conf-lists')
        },
        openExtendedSettings (conf) {
            this.modalClose()
            this.modalOpen({
                component: ExtConfSettings,
                props: {conf},
            })
        },
        ...mapActions(VIDEO_CONF_NEW, [ACT_VC_CONF_CHANGE]),
    },
}
