//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';

import MainContentContainer from "./MainContentContainer.vue"
import ListItem from "../../custom/ListItem.vue"
import CustomCheckboxSlider from "../../custom/CustomCheckboxSlider.vue"
const isDarkSystemTheme = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches

export default {
    name: "MainContentDecoration",
    components: { ListItem, MainContentContainer, CustomCheckboxSlider },
    mounted() {
        let theme = this.$store.getters['clientdata/getTheme']
        this.isSystemTheme = (theme === 'system' || !theme)
        this.isLightTheme = (theme === 'light' || theme === '')
        this.isDarkTheme = theme === 'dark'
    },
    data() {
        return {
            isSystemTheme: true,
            isLightTheme: false,
            isDarkTheme: false,
        }
    },
    watch: {
        isSystemTheme(val) {
            if (val) {
                this.isLightTheme = false
                this.isDarkTheme = false
                this.setSystemTheme()
            }
        },
        isLightTheme(val) {
            if (val) {
                this.isSystemTheme = false
                this.isDarkTheme = false
                //this.$store.dispatch('clientdata/setTheme', "light")
                this.$store.commit('clientdata/mutSetTheme', "light")
                document.documentElement.setAttribute("data-theme", "light")
            } else if (!this.isDarkTheme && !this.isSystemTheme) this.setSystemTheme()
        },
        isDarkTheme(val) {
            if (val) {
                this.isSystemTheme = false
                this.isLightTheme = false
                //this.$store.dispatch('clientdata/setTheme', "dark")
                this.$store.commit('clientdata/mutSetTheme', "dark")
                document.documentElement.setAttribute("data-theme", "dark")
            } else if (!this.isLightTheme && !this.isSystemTheme) this.setSystemTheme()
        },
    },
    methods: {
        setSystemTheme() {
            if (!this.isSystemTheme) this.isSystemTheme = true
            this.$store.commit('clientdata/mutSetTheme', "system")
            if (isDarkSystemTheme) 
                document.documentElement.setAttribute("data-theme", "dark")
            else    
                document.documentElement.setAttribute("data-theme", "light")
        }
    }
}
