export default {
    emitters: {
        async radioGetToken(data) {
            try {
                let answer = await this._emitWithTimeOut('radio-get-token', data)
                this.log('radio-get-token: < ' + JSON.stringify(answer))
                return answer
            } catch(err) {
                this.log('radio-get-token error '+  JSON.stringify(err))
                throw('radio-get-token error')
            }
        }
    },
    listeners: {},
}