//
//
//
//
//
//

import modal_mixin from "../modal/modal-mixin"
import videomostConf from "./videomostConf.vue"

import {CONF_TYPES} from "../../constants"

export default {
    name: "videoConfModal",
    mixins: [modal_mixin],
    props: {
        type: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            compModalProps: {
                delay: 200,
                pivotY: 0.05,
                width: 800,
                height: 'auto',
                clickToClose: false
            },
        }
    },
    computed: {
        componentProps() {
            let { type, ...props } = this.$props.props
            return props
        },
        component() {
            switch (this.type) {
                case CONF_TYPES.VM:
                    return videomostConf
            }
        }
    }
}
