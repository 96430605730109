//
//
//
//

import IncommingCall from './IncommingCall.vue'
import ipc from "../../../electron/ipc";

import { GET_OPEN_INCOMMING_CALL, GET_ACTIVE_MICROPHONE, GET_ACTIVE_WEBCAMERA } from "../../store/gettersTypes";
import { ACT_ANSWER_INCOMMING_CALL, ACT_TERMINATE_INCOMMING_CALL, ACT_HIDE_INCOMMING_CALL, ACT_MODAL_OPEN } from "../../store/actionsTypes";
import { PHONE_CNTL, USERDATA, MODAL } from "../../store/modulesNames";
import { i18n } from '../../../ext/i18n';
import { PHONE_TYPES } from '../../constants'
const messagesI18n = i18n.messages[i18n.locale]

export default {
    name: 'incomming-call-ctrl',
    components: { IncommingCall },
    data() {
        return {
            id: 0,
            type: '',
        }
    },
    mounted() {
        ipc.on('answer-incomming-call', (event, data) => {
            const isVoiceCall = (data.typeCall === messagesI18n['mainPage']['voice-call'])
            const isGroupCall = (data.name === messagesI18n['group-call'])
            const isVideoCall = (data.typeCall === messagesI18n['mainPage']['video-call'])
            const isGroupVideoCall = (data.name === messagesI18n['group-video-call'])
            if ((this.isWebcameraPresent && this.isMicrophonePresent) ||
                ((isVoiceCall || isVideoCall || isGroupCall || isGroupVideoCall) && this.isMicrophonePresent) ||
                data.type  ===  PHONE_TYPES.ASSISTANT)
                this.$store.dispatch(`${PHONE_CNTL}/${ACT_ANSWER_INCOMMING_CALL}`, data)
            else {
                ipc.send('show-main-window')
                const messages = i18n.messages[i18n.locale]
                let alertMessage = messages['no-mic']
                if ((isVideoCall || isGroupVideoCall) && !this.isWebcameraPresent) alertMessage = messages['no-camera']
                if ((isVideoCall || isGroupVideoCall) && !this.isWebcameraPresent && !this.isMicrophonePresent) alertMessage = messages['no-mic-camera']
                this.$store.dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                    name: 'alert',
                    props: {
                        title: messages.errors['error'],
                        text: alertMessage  
                    }
                }, {
                    root: true
                });
            }
        })
        ipc.on('terminate-incomming-call', (event, data) => {
            console.log('terminate-incomming-call')
            this.$store.dispatch(`${PHONE_CNTL}/${ACT_TERMINATE_INCOMMING_CALL}`, data)
        })
        ipc.on('close-incomming-call', (event, data) => {
            console.log('close-incomming-call')
            this.$store.dispatch(`${PHONE_CNTL}/${ACT_HIDE_INCOMMING_CALL}`, data)
        })
    },
    computed: {
        show() {
            return this.$store.getters[`${PHONE_CNTL}/${GET_OPEN_INCOMMING_CALL}`]
        },
        isMicrophonePresent() {
            return this.$store.getters[`${USERDATA}/${GET_ACTIVE_MICROPHONE}`];
        },
        isWebcameraPresent() {
            return this.$store.getters[`${USERDATA}/${GET_ACTIVE_WEBCAMERA}`];
        }
    }
}
