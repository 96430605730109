//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "ActivityLine",
    data: () => ({
        bgColor: 'var(--base-blue-color)'
    }),
    computed: {
        leftText () { return '' },
        title() { return '' },
        styles() {
            return {
               '--bg-color': this.bgColor
            }
        }
    },
    methods: {
        toggleCall() { },
        endCall() { },
    },
}
