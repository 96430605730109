
import {mapGetters, mapActions} from 'vuex'
import {CHATS} from '../../store/modulesNames'
import {GET_CHAT_MEMBERS} from '../../store/gettersTypes'
import {ACT_CHATS_ADD_MEMBERS} from '../../store/actionsTypes'
import SelectContact from './SelectContact.vue'
import {CONTACTS_SEARCH_TYPES} from '../../constants'

/**
 *  @param {Object} props
 *  @param {Number} props.cid ид. чата
 *  @param {String} props.privilege привилегии добавляемого контакта
 *  @param {Boolean} props.excludeByPrivilege исключить из списка только контакты с совпадающей привилегией
 *  @return Object
 */
export default {
    name: "SelectContactsToChat",
    extends: SelectContact,
    data() {
        return {
            searchType: CONTACTS_SEARCH_TYPES.SEND_MESSAGE,
        }
    },
    props: {
        cid: {
            type: Number,
            required: true,
        },
        privilege: {
            type: String,
            required: false,
        },
        excludeByPrivilege: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    computed: {
        botsIsVisible() { return this.privilege === 'user' },
        multiple: () => true,
        okText() { return this.$t('add') },
        filters() { return this.excludeByPrivilege ? {privilege: this.privilege } : {} },
        excludeMembers() {
            return this[GET_CHAT_MEMBERS]({cid: this.cid, filters: this.filters}).map(({cid}) => cid)
        },
        ...mapGetters(CHATS, [GET_CHAT_MEMBERS])
    },
    methods: {
        onOk (contacts) {
            contacts = contacts.map(contact => ({cid: contact.cid, privilege: this.privilege}))
            let payload = {
                'chat_cid': this.cid,
                contacts
            }
            this[ACT_CHATS_ADD_MEMBERS](payload)
            this.modalClose()
        },
        ...mapActions(CHATS, [ACT_CHATS_ADD_MEMBERS])
    }
}
