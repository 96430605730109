//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "BaseContainerWebWrapper",
    data() {
        return {
            component: null,
            notFullScreenHeight: 'auto',
            notFullScreenWidth: 'auto',

            newX: 0,
            newY: 0,
            is_moving: false,
            X: 0,
            Y: 0,
            activePosition: true,
            position: 'absolute',
            limits: {},
            widthResize: {},
            heightResize: {}
        }
    },
    watch: {
        minimized() {
            let { width, height } = this.$refs.phone.getBoundingClientRect()
            this.widthResize = {...{ width }}
            this.heightResize = {...{ height }}
        },
        call(val) {
            console.log('~~~~~~~~~~~~~~~call', val)
            if (!val) this.activePosition = true
            //this.$refs.phoneContainer.setType && this.$refs.phoneContainer.setType(val && val.type ? val.type : 'none')
        },
    },
    computed: {
        call() {
            return null
        },
        minimized() {
            return true
        },
        fullScreen () {
            return false
        },

        styleParams() {
            return {
                left: this.fullScreen ? 0 + 'px' : this.X+'px',
                top: this.fullScreen ? 0 + 'px' : this.Y+'px',
                position: this.position,
                height: this.fullScreen ? 100 + '%' : this.notFullScreenHeight,
                width: this.fullScreen ? 100 + '%' : this.notFullScreenWidth,
                borderRadius: this.fullScreen ? 0 + 'px' : 10 + 'px'
            }
        },
    },
    methods: {
        coordinates(event) {
            let phone = this.$refs.phone
            let wrapper = this.$refs.wrapper

            let startx = event.x || event.target.innerWidth
            let starty = event.y || event.target.innerHeight

            if (wrapper && phone) {
                this.limits = {
                    top: wrapper.offsetTop,
                    right: wrapper.offsetWidth + wrapper.offsetLeft - phone.offsetWidth,
                    bottom: wrapper.offsetHeight + wrapper.offsetTop - phone.offsetHeight,
                    left: wrapper.offsetLeft
                }
            }
            return {startx, starty, phone}
        },
        limitBlock (endx, endy) {
            if (endx > this.limits.right) {
                this.X = this.limits.right
            } else if (endx < this.limits.left) {
                this.X = this.limits.left
            } else this.X = endx

            if (endy > this.limits.bottom) {
                this.Y = this.limits.bottom
            } else if (endy < this.limits.top) {
                this.Y = this.limits.top
            } else this.Y = endy
        },
        eventResize(event) {
            if (!this.call) return
            let { startx, starty, phone } = this.coordinates(event)
            if (!phone) return
            let { x, y } = phone.getBoundingClientRect()

            this.newX = startx - x
            this.newY = starty - y

            let endx = startx - this.newX
            let endy = starty - this.newY

            this.limitBlock(endx, endy)

            if (this.minimized) {
                let width = endx + this.widthResize.width
                let height = endy + this.heightResize.height

                if (width > this.limits.right) {
                    this.X = this.limits.right - this.widthResize.width
                }

                if (height > this.limits.bottom) {
                    this.Y = this.limits.bottom - this.heightResize.height
                }

            }

        },
        mousedown(event) {
            if (this.is_moving) return
            window.addEventListener("mouseup", this.mouseup)
            window.addEventListener("mousemove", this.mousemove)
            this.is_moving = true
            let { startx, starty, phone } = this.coordinates(event)
            let { x, y } = phone.getBoundingClientRect()
            this.newX = startx - x
            this.newY = starty - y
        },
        mousemove(event) {
            if (!this.is_moving) return
            let { startx, starty } = this.coordinates(event)
            let endx = startx - this.newX
            let endy = starty - this.newY
            this.activePosition = false
            this.position = 'initial'
            this.limitBlock(endx, endy)
        },
        removeDragListeners () {
            window.removeEventListener("mouseup", this.mouseup)
            window.addEventListener("mousemove", this.mousemove)
        },
        mouseup(event) {
            if (!this.is_moving) return
            this.is_moving = false
            this.removeDragListeners()
        },
    },
    created() {
        window.addEventListener("resize", this.eventResize)
    },
    destroyed() {
        window.removeEventListener("resize", this.eventResize)
        this.removeDragListeners()
    }
}
