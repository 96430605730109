/**
 * @enum {string}
 */
export const MEETING_ERRORS = {
    BAD_PARAMS: 'bad-params', // Ошибка в параметрах запроса.
    NOT_FOUND: 'not-found', // Конференция не найдена.
    SERVICE_ERROR: 'service-error', // Ошибка инициализации сервиса конференции.
    BANNED_USER: 'banned-user', // Заблокированный пользователь
    ACCESS_DENIED: 'access-denied', // Недостаточно привилегий.
    OUT_OF_TIME_EARLY: 'out-of-time-early', // Конференция еще не началась.
    OUT_OF_TIME_LATE: 'out-of-time-late', // Конференция уже завершилась.
    ALREADY_EXISTS: 'already-exists', // Конференция с указанным идентификатором уже существует.
    UNKNOWN: 'unknown', // Неизвестная ошибка.
}