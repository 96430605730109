//
//
//
//
//
//
//
//
//
//
//

'use strict';

import MainContentContainer from "../main/body/MainContentContainer.vue";
import ListItem from "../custom/ListItem.vue";
import CustomCheckboxSlider from "../custom/CustomCheckboxSlider.vue";

import { GET_IS_ELECTRON, GET_BIRTHDAY_NOTIFY } from "../../store/gettersTypes";
import { ACT_SET_BIRTHDAY_NOTIFY } from "../../store/actionsTypes";
import { CLIENTDATA, USERDATA } from "../../store/modulesNames";
import { mapGetters } from 'vuex';

export default {
    name: 'notification-settings',
    components: { MainContentContainer, ListItem, CustomCheckboxSlider },
    data() {
        return {
            showNotification: false,
            soundNotification: false,
            previewNotification: false
        };
    },
    mounted() {
        let birthdayNotify = JSON.parse(localStorage.getItem('birthdayNotify'))
        if(typeof birthdayNotify === 'number') this.$store.commit(`${USERDATA}/${ACT_SET_BIRTHDAY_NOTIFY}`, birthdayNotify)
    },
    computed: {
        birthdayNotify() {
            let value = this.$store.getters[`${USERDATA}/${GET_BIRTHDAY_NOTIFY}`]
            switch(value) {
                case 10:
                    return this.$t('not-notify')
                    break
                case 0:
                    return this.$t('notify-on-same-day')
                    break
                case 1:
                    return this.$t('day-before')
                    break
                case 3:
                    return this.$t('3days-before')
                    break
            }
        },
        ...mapGetters(CLIENTDATA, {
            isElectron: GET_IS_ELECTRON,
        })
    },
    filters: {},
    methods: {
        showSetBirthday() {
            this.modalOpen({
                name: 'set-birthday',
                props: {}
            })
        },
        openSoundSettings() {
            this.modalOpen({
                name: 'sound-settings',
            })
        },
    },
    created() {
        this.$emit('update:ready', true)
    },
}
