export default {
    emitters: {
        searchBots (params) {
            return new Promise((resolve, reject) => {
                this.log('search-bots: > ' + JSON.stringify(params))
                this.socket.emit('search-bots', params, result => {
                    if (result) {
                        this.log('search-bots: < success')
                        resolve(result)
                    } else {
                        this.log('search-bots: < failed')
                    }
                })
            })
        },
        botManage (params) {
            return new Promise((resolve, reject) => {
                this.log('bot-manage: > ' + JSON.stringify(params))
                this.socket.emit('bot-manage', params, result => {
                    if (result) {
                        this.log('bot-manage: < success')
                        resolve(result)
                    } else {
                        this.log('bot-manage: < failed')
                    }
                })
            })
        },
        pressButton (params) {
            return new Promise((resolve, reject) => {
                this.log('press-button: > ' + JSON.stringify(params))
                this.socket.emit('press-button', params, result => {
                    if (result) {
                        this.log('press-button: < success')
                        resolve(result)
                    } else {
                        this.log('press-button: < failed')
                    }
                })
            })
        },
    },
    listeners: {
        'set-chat-keyboard' (data) {
            this.log('bind: < set-chat-keyboard: ' + JSON.stringify(data))
            this.emit('set-chat-keyboard', data)
        },
    },
}