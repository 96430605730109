//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions} from 'vuex';
import {MODAL} from "../../store/modulesNames";
import {ACT_MODAL_CLOSE} from "../../store/actionsTypes";

export default {
    name: "ModalDialog",
    props: {
        name: {
            type: String,
            required: true
        },
        modalProps: {
            type: Object,
            required: false,
            default: () => ({})
        },
        title: {
            type: String,
            required: false
        },
        headerClose: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        hasHeader() { return this.title || this.headerClose },
        hasDefaultSlot () { return !!this.$slots.default },
        hasFooterSlot () { return !!this.$slots['footer'] }
    },
    methods: {
        close() {
            this.$modal.hide(this.name);
            this.closeTimeout = setTimeout(() => {
                this.onClosed()
            }, 200)
        },
        onClosed() {
            clearTimeout(this.closeTimeout)
            this.$emit('closed')
            this[ACT_MODAL_CLOSE]({name: this.name});
        },
        ...mapActions(MODAL, [ACT_MODAL_CLOSE])
    },
    mounted() {
        this.$modal.show(this.name)
    }
}
