import { HOTKEYS_ACTION_GLOBAL, HOTKEYS_ACTION_LOCAL } from '../constants'
import { i18n } from '../../ext/i18n'
const locale = i18n.messages[i18n.locale]

export const getHotKeysTitleByAction = (hotkeysActionId, global = false) => {
    let result = ''
    if (global) {
        switch (hotkeysActionId) {
            case HOTKEYS_ACTION_GLOBAL.SHOW_HIDE:
                result = locale['hotkeys']['show-hide']
                break;
            case HOTKEYS_ACTION_GLOBAL.CALL_CLIPBOARD_NUMBER:
                result = locale['hotkeys']['call-number']
                break;
            default:
                break;
        }
    } else {
        switch (hotkeysActionId) {
            case HOTKEYS_ACTION_LOCAL.SEARCH:
                result = locale['hotkeys']['search']
                break;
            default:
                break;
        }
    }
    return result
}