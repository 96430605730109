//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconImage from '../IconImage.vue'
import Birthdays from '../Birthdays.vue'
import ContactsListItem from '../listItems/ContactsListItem.vue'
import ContactProfoleEdition from "../ContactProfoleEdition.vue"
import store from '../../store/main/store'
import {mapGetters, mapActions} from 'vuex'
import {CONTACTS} from '../../store/modulesNames'
import {
    ACT_SET_FAVOURITES,
    ACT_CHANGE_FAVOURITES_MODE,
    ACT_ADD_CONTACT_CONNECTIONS,
    ACT_SELECT_CONTACT,
} from '../../store/actionsTypes'
import {
    GET_FAVOURITES,
    FAVOURITES_IN_CHANGE_MODE,
    GET_MY_CONTACT,
} from '../../store/gettersTypes'
import SelectContactsToFavorites from '../modal/SelectContactsToFavorites.vue'
import AddGuestContact from '../modal/AddGuestContact.vue'

export default {
    name: "ContactsList",
    components: {IconImage, Birthdays, ContactsListItem},
    store,
    data() {
        return {
            search: '',
            exclude: [],
            drag: false,
            dragOptions: {
                animation: 300,
                group: "description",
                ghostClass: "ghost"
            },
            birthdays_show: true,
            noswitch: true,
            draggable_contacts: []
        }
    },
    computed: {
        contact_id() { return app.getUid() },
        contacts: {
            get() {
                let contacts = this.$store.getters[`${CONTACTS}/${GET_FAVOURITES}`]
                if (!!contacts.length && contacts[0].cid) this.draggable_contacts = contacts
                return contacts
            },
            set(list) {
                let cids = []
                for (let i in list) cids.push(list[i].cid)
                this.$store.dispatch(`${CONTACTS}/${ACT_SET_FAVOURITES}`, cids)
            }
        },
        ...mapGetters(CONTACTS, {
            [GET_FAVOURITES]: GET_FAVOURITES,
            favouritesInChangeMode: FAVOURITES_IN_CHANGE_MODE,
            [GET_MY_CONTACT]: GET_MY_CONTACT,
        }),
    },
    methods: {
        removeFavourite(contact) {
            const removeFavourite = () => {
                const index = this.draggable_contacts.indexOf(contact)
                const favourites = this.draggable_contacts
                favourites.splice(index, 1)
                this.draggable_contacts = favourites 
            }
            this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('delete-fav-conf'),
                    btnOk: {
                        cb: () => removeFavourite()
                    }
                }
            })
        },
        doContactMenu(event, cid, position="bottom-right") {
            const handlers = []
            handlers.push({
                item_name: this.$t('contacts-comp.del-from-fav'),
                handler: () => {this.removeFavourite(cid)}
            })
            this.cmOpen(event, handlers, position)
        },
        doOnSelected(cid) {
            this[ACT_SELECT_CONTACT](cid)
        },
        disableChangeFavouritesMode() {
            if (!!this.draggable_contacts.length && this.draggable_contacts[0].cid) this.contacts = this.draggable_contacts
            else this.contacts = []
            this[ACT_CHANGE_FAVOURITES_MODE](false)
        },
        doMenu(event, position) {
            let handlers = [],
                open = this.cmOpen
            handlers.push({
                item_name: this.$t('create-contact'),
                handler: () => {
                    this.modalOpen({
                        component: ContactProfoleEdition,
                        props: { type: 'create', cid: this.cid }
                    })
                }
            })
            handlers.push({
                item_name: this.$t('contacts-comp.add-to-fav'),
                handler: () => {
                    this.modalOpen({
                        component: SelectContactsToFavorites
                    })
                }
            })
            handlers.push({
                item_name: this.$t('contacts-comp.change-fav'),
                handler: () => {
                    if (!!this.contacts.length && !!this.contacts[0].cid) this.draggable_contacts = this.contacts
                    this[ACT_CHANGE_FAVOURITES_MODE](true)
                }
            })

            const actions = this[GET_MY_CONTACT].actions || {}

            if (actions['invite-guest']) {
                handlers.push({
                    item_name: this.$t('contacts-comp.add-guest-contact'),
                    handler: () => {
                        this.modalOpen({
                            component: AddGuestContact
                        })
                    }
                })
            }
            open(event, handlers, position)
        },
        ...mapActions(CONTACTS, [ACT_CHANGE_FAVOURITES_MODE, ACT_ADD_CONTACT_CONNECTIONS, ACT_SELECT_CONTACT]),
    }
}
