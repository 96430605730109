//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import PhoneActionButton from './PhoneActionButton.vue'
import CustomAudio from "../custom/CustomAudio.vue"
import IconImage from '../IconImage.vue'
import { CONTACTS, PHONE_CNTL, ASISTANTS, USERDATA } from "../../store/modulesNames";
import {
    GET_MERGED_CONTACT_BY_ID,
    GET_MERGED_CONTACT_BY_PHONE,
    GET_INCOMING_CALL,
    GET_TYPE_ASISTANT,
    GET_ASSISTANT_CALL_BY_ID,
    GET_VOLUME_RING,
    GET_RING_SOUND,
} from '../../store/gettersTypes'
import {
    ACT_ANSWER_INCOMMING_CALL,
    ACT_TERMINATE_INCOMMING_CALL,
    ACT_HIDE_INCOMMING_CALL,
} from "../../store/actionsTypes"

import {ASSISTANT_TYPES, PHONE_TYPES} from '../../constants'
const isDarkSystemTheme = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches

export default {
    name: 'IncommingCall',
    components: { CustomAudio, PhoneActionButton, IconImage },
    props: {
        type: {
            type: String,
            required: true,
        },
        id: {
            type: [Number, String],
            required: true,
        },
        isRinging : {
            type: Boolean,
            default: true,
            required: false
        }
    },
    created() {
        let theme = this.theme
        if (!theme || theme === 'light' || (theme === 'system' && !isDarkSystemTheme)) document.documentElement.setAttribute("data-theme", "light")
        if (theme === 'dark' || (theme === 'system' && isDarkSystemTheme)) document.documentElement.setAttribute("data-theme", "dark")
    },
    watch: {
        theme(val) {
            if (!val || val === 'light' || (val === 'system' && !isDarkSystemTheme)) document.documentElement.setAttribute("data-theme", "light")
            if (val === 'dark' || (val === 'system' && isDarkSystemTheme)) document.documentElement.setAttribute("data-theme", "dark")
        },
        ringVolume(val) {
            this.$nextTick(() => {
                if (this.$refs.callAudio) this.$refs.callAudio.volume = val
            })
        }
    },
    computed: {
        theme() {
            return this.$store.getters['clientdata/getTheme']
        },
        callData() {
            return this[GET_INCOMING_CALL](this.type, this.id)
        },
        cid() {
            return (this.callData.data || {}).cid
        },
        number() {
            return (this.callData.data || {}).number
        },
        contact() {
            const cid = this.cid
            const number = this.number
            if (cid) return this[GET_MERGED_CONTACT_BY_ID](cid)
            if (number) return this[GET_MERGED_CONTACT_BY_PHONE](number)
        },
        name() {
            return this.contact && this.contact.fio || this.number || ''
        },
        icon() {
            if (this.callData.type !== PHONE_TYPES.CONFERENCE) return this.contact && this.contact.photo || ''
            return 'img/logo_video_conf.png'
        },
        callTypeNames() {
            let retObj = {}

            if (this.type === PHONE_TYPES.PHONE) {
                const isVideo = this.callData && this.callData.data.options.video
                retObj = {type: 'voice-call', title: this.$t('mainPage.voice-call')}
                if (isVideo) retObj = {type: 'video-call', title: this.$t('mainPage.video-call')}
            }
            if (this.type === PHONE_TYPES.CONFERENCE) {
                const isVideo = this.callData && this.callData.data.options.video
                retObj = {type: 'answer-conf', title: this.$t('group-call')}
                if (isVideo) retObj = {type: 'video-call', title: this.$t('group-video-call')}
            }
            if (this.type === PHONE_TYPES.ASSISTANT) {
                const assistant = this[GET_TYPE_ASISTANT]
                let number = (this[GET_ASSISTANT_CALL_BY_ID](this.id) || {}).digits
                retObj = {
                    type: [ASSISTANT_TYPES.SIP_BOOSTER, ASSISTANT_TYPES.DX_BOOSTER_ANALOG].includes(assistant) ? '' : 'voice-call',
                    'title': this.$t(`assistants.name.${assistant}`, {number: number ? `(${number})` : number})
                }
            }
            return retObj
        },
        ringSound() {
            return this.$store.getters[`${USERDATA}/${GET_RING_SOUND}`] 
        },
        ringVolume() {
            return this.$store.getters[`${USERDATA}/${GET_VOLUME_RING}`] 
        },
        ringToneSrc () {
            this.$nextTick(() => {
                this.$refs.callAudio && (this.$refs.callAudio.volume = this.ringVolume)
            })
            return this.isRinging ? this.ringSound : ''
        },
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID, GET_MERGED_CONTACT_BY_PHONE]),
        ...mapGetters(PHONE_CNTL, [GET_INCOMING_CALL]),
        ...mapGetters(ASISTANTS, [GET_TYPE_ASISTANT, GET_ASSISTANT_CALL_BY_ID]),
    },
    methods: {
        answer() {
            this[ACT_ANSWER_INCOMMING_CALL]({type: this.type, id: this.id})
        },
        terminate() {
            this[ACT_TERMINATE_INCOMMING_CALL]({type: this.type, id: this.id})
        },
        hide() {
            this[ACT_HIDE_INCOMMING_CALL]({type: this.type, id: this.id})
        },
        ...mapActions(PHONE_CNTL, [ACT_ANSWER_INCOMMING_CALL, ACT_TERMINATE_INCOMMING_CALL, ACT_HIDE_INCOMMING_CALL])
    },
}
