//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'signal-level',
    props: {
        level: {
            type: Number,
            default: 0
        }
    },
    // watch: {
    //     level(val) {
    //         console.log("🚀 ~ file: SignalLevel.vue:54 ~ level ~ val", val)
    //     }
    // }
}
