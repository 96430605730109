export default {
    emitters: {
        getHistoryCalls(params, cb) {
            let data = { startId: params.startId, count: params.count, minId: params.minId }
            this.log('getHistoryCalls: > ' + JSON.stringify(data))
            this.socket.emit('get-history-calls', data, (data) => {
                this.log('getHistoryCalls: < ' + data.length)
                return cb && cb(data)
            })
        },
        setHistoryCallStatus(params, cb) {
            this.log('setHistoryCallStatus: > ' + JSON.stringify(params))
            this.socket.emit('set-history-call-status', params, (data) => {
                this.log('setHistoryCallStatus: < ' + data.length)
                return cb && cb(data)
            })
        }
    },
    listeners: {
        'history-call-event'(data) {
            this.log('bind: < history-call-event: ' + JSON.stringify(data))
            this.emit('history-call-event', data)
        },
        'history-call-change-event'(data) {
            this.log('bind: < history-call-change-event: ' + JSON.stringify(data))
            this.emit('history-call-change-event', data)
        },
    },
}