//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    ACT_VC_CONF_SPEAKER_TOGGLE,
    ACT_VC_TOGGLE_FULLSCREEN,
    ACT_VC_TOGGLE_MINIMIZE
} from "../../store/actionsTypes"
import {
    GET_VC_CONF_MEDIA_DEVICE_BY_KIND,
    GET_VC_CONF_SPEAKER_STATUS,
    IS_VC_IN_FULLSCREEN,
    IS_VC_MINIMIZED,
} from "../../store/gettersTypes"


import {mapActions, mapGetters} from "vuex"
import {VIDEO_CONF_NEW} from "../../store/modulesNames"

import videoConfSectionMixin from "./videoConfSectionMixin"

import ConfBtn from "./confBtn.vue"
import {CONFERENCE_SCHEMAS, MEDIA_DEVICES} from "../../constants"

export default {
    name: "videoConfSectionHeaderDefault",
    mixins: [videoConfSectionMixin],
    components: {
        ConfBtn,
    },
    data () {
        return {
            infoOpened: false,
        }
    },
    computed: {
        speaker() {
            return this.confIdGetterData && this[GET_VC_CONF_SPEAKER_STATUS](...this.confIdGetterData)
        },
        speakerIsOn() {
            return Boolean(this.speaker)
        },
        noSpeakerDevice() {
            if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) return false
            return !(this.confIdGetterData && this[GET_VC_CONF_MEDIA_DEVICE_BY_KIND](...this.confIdGetterData, MEDIA_DEVICES.AUDIO_OUTPUT))
        },
        schemeIconClasses() {
            let classes = ['fal']
            switch (this.schema) {
                case CONFERENCE_SCHEMAS.PUZZLE:
                    classes.push('fa-th')
                    break
                case CONFERENCE_SCHEMAS.TRIBUNE:
                    classes.push('fa-window-maximize', 'fa-rotate-180')
                    break
                case CONFERENCE_SCHEMAS.SHARE:
                    classes.push('fa-share-alt-square')
                    break
            }
            return classes.join(' ')
        },
        ...mapGetters(VIDEO_CONF_NEW, {
            minimize: IS_VC_MINIMIZED,
            videoConfFullScreen: IS_VC_IN_FULLSCREEN,
        }),
        ...mapGetters(VIDEO_CONF_NEW, [
            GET_VC_CONF_SPEAKER_STATUS,
            GET_VC_CONF_MEDIA_DEVICE_BY_KIND,
        ]),
    },
    methods: {
        toggleInfo(e) {
            this.$emit('toggle-info', e, 'bottom-right', { offsetY: 15 })
        },
        toggleSchemeSelect(e) {
            this.$emit('toggle-scheme-select', e, 'bottom-right', { offsetY: 15 })
        },
        exitOpen(e) {
            this.$emit('toggle-exit', e, 'bottom-left', { offsetY: 15 })
        },
        speakerToggle() {
            if (!this.btsShow || this.noSpeakerDevice) return
            this[ACT_VC_CONF_SPEAKER_TOGGLE](this.confIdActionData)
        },
        toggleMinimize() {
            this[ACT_VC_TOGGLE_MINIMIZE]()
        },
        doFullScreen() {
            this[ACT_VC_TOGGLE_FULLSCREEN]()
        },
        ...mapActions(VIDEO_CONF_NEW, [
            ACT_VC_CONF_SPEAKER_TOGGLE,
            ACT_VC_TOGGLE_FULLSCREEN,
            ACT_VC_TOGGLE_MINIMIZE,
        ])
    },
}
