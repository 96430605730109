//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex';
import {GET_UID, GET_LOGIN, GET_MERGED_CONTACT_BY_ID, GET_DND, GET_EXP_TIME, GET_INFO_OPEN} from "../../../store/gettersTypes";
import {ACT_OPEN_LOGOUT_DIALOG, ACT_INFO_TOGGLE, ACT_INFO_PUSH} from "../../../store/actionsTypes";
import {LOGIN, USERDATA, CONTACTS, USERPROFILE, INFO} from "../../../store/modulesNames";
import IconImage from "../../IconImage.vue";
import MainContentContainer from "./MainContentContainer.vue";
import ListItem from "../../custom/ListItem.vue";
import DndDialog from "./DndDialog.vue";
import ContactProfoleEdition from "../../ContactProfoleEdition.vue";
import moment from 'moment'

export default {
    name: "MainContentProfile",
    components: {MainContentContainer, ListItem, IconImage},
    computed: {
        ...mapGetters(USERDATA, {[GET_UID]: GET_UID, login: GET_LOGIN}),
        ...mapGetters(CONTACTS, {[GET_MERGED_CONTACT_BY_ID]: GET_MERGED_CONTACT_BY_ID}),
        ...mapGetters(USERPROFILE, {dnd: GET_DND, expTime: GET_EXP_TIME}),
        ...mapGetters(INFO, [GET_INFO_OPEN]),
        contact() {
            return this[GET_MERGED_CONTACT_BY_ID](this[GET_UID])
        },
        fio() {
            return this.contact.fio
        },
        showExpTime() {
            return !!this.expTime;
        },
    },
    methods: {
        ...mapActions(LOGIN, {logout: ACT_OPEN_LOGOUT_DIALOG}),
        ...mapActions(INFO, [ACT_INFO_TOGGLE, ACT_INFO_PUSH]),
        editSelfContact() {
            this.modalOpen({
                component: ContactProfoleEdition,
                props: { type: 'edit', cid: this.contact.cid }
            })
        },
        showDndDialog() {
            this.$modal.show(DndDialog, {}, {
                name: 'dnd-dialog',
                delay: 450,
                pivotY: 0.05,
                width: 350,
                height: 'auto'
            });
        },
        toggleSideProfile() {
            const openedInfo = this[GET_INFO_OPEN]
            if (!openedInfo) {
                this[ACT_INFO_PUSH]({type: 'contact-info', params: {cid: this.contact.cid, isMyProfile: true}})
            }
            else this[ACT_INFO_TOGGLE]({type: 'contact-info', params: {cid: this.contact.cid}})
        }
    },
    filters: {
         formatTime(unixTime) {
             const DATE_FORMAT = "DD.MM.YYYY",
                 TIME_FORMAT = "HH:mm",
                 DATE_TIME_FORMAT = DATE_FORMAT + " " + TIME_FORMAT,
                 FORMAT = date_helper.isToday(unixTime) ? TIME_FORMAT : DATE_TIME_FORMAT;
             return moment.unix(unixTime).format(FORMAT);
        },
    }
}
