export default {
    emitters: {
        getFavouritesList() {
            return new Promise(resolve => {
                // this.socket.emit('get-favorites-list', null, favorites => {resolve(favorites);}); //DEPRECATED
                this.log('get-favorites: >')
                this.socket.emit('get-favorites', null, favourites => {
                    // Андроидяне сохраняют cid с типом string, а мы с number это для совместимости
                    this.log(`get-favorites: < ${JSON.stringify(favourites)}`)
                    for (let i = 0; i < favourites.length; i++) favourites[i].cid = +favourites[i].cid
                    resolve(favourites)
                })
            })
        },
        setFavouritesList(favourites) {
            return new Promise((resolve, reject) => {
                if (favourites.constructor !== Array) {
                    reject('incorrect data')
                    return
                }
                // Андроидяне сохраняют cid с типом string, а мы с number это для совместимости
                favourites = favourites.map(({cid}) => ({cid: '' + cid}))
                this.log(`set-favorites: > ${JSON.stringify(favourites)}`)
                this.socket.emit('set-favorites', favourites, result => {
                    this.log(`set-favorites: < ${JSON.stringify(result)}`)
                    if (result) resolve()
                    else reject('Error')
                })
            })
        },
    },
    listeners: {
        'favorites-change-event'(favourites) {
            this.log('bind: < favorites-change-event: ' + JSON.stringify(favourites))
            this.emit('favorites-change-event', favourites)
        },
    },
}