//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';
import OfficeMonitorSettings from './OfficeMonitorSettings.vue';
import OfficeMonitorJournal from '../info/AcsJournal.vue';
import { i18n } from '../../../ext/i18n'

export default {
    name: 'user-profile',
    components: {
        // 'om-journal': OfficeMonitorJournal,
        'om-settings': OfficeMonitorSettings
    },
    props: {
        contact_id: {
            type: Number,
            // default: app.getUid(),
            required: false
        }
    },
    data() {
        return {
            open: false,
            menu_groups: [
                {
                    name: 'menu',
                    items: [
                        // 'om-journal',
                        'notifications',
                        'security'
                    ]
                },
                {
                    name: 'settings',
                    items: [
                        'om-settings'
                    ]
                }
            ],
            selected_item: 'om-settings',
            title: 'TITLE',
               cid: null
        };
    },
    computed: {
        
    },
    filters: {
        translateGroup(val) {
            let locale = i18n.messages[i18n.locale]
            let res;
            switch(val) {
            case 'menu':
                res = locale.mainPage.menu
                break;
            case 'settings':
                res = locale.mainPage.settings
                break;
            default:
                res = val;
            }
            return res;
        },
        translateItem(val) {
            let locale = i18n.messages[i18n.locale]
            let res;
            switch (val) {
                case 'om-status':
                case 'om-journal':
                case 'om-settings':
                    res = locale.mainPage.location
                    break;
                case 'notifications':
                    res = locale.settings.notifications
                    break;
                case 'security':
                    res = locale.settings.security
                    break;
                default:
                    res = val;
            }
            return res;
        }
    },
    methods: {
        show(acs_cid) {
            if (!!acs_cid) this.cid = acs_cid;
            else this.cid = this.contact_id;
            this.open = true;
        },
        hide() {
            this.open = false;
        },
        doSelectItem(item) {
            this.selected_item = item;
        }
    }
}
