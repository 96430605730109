/**
 * Created by Aleksey on 26.06.2018.
 */

export const msgStatuses = {
    MSG_STATUS_RECEIVED:    'received', //доставлено
    MSG_STATUS_WATCHED:     'watched', //просмотрено
    MSG_STATUS_DELETED:     'deleted'  //удалено
}

export const msgDataTypes = {
    MSG_DATA_TYPE_TEXT:      'text', //текст
    MSG_DATA_TYPE_DATA:      'data', //данные
    MSG_DATA_TYPE_SYSTEM:    'system', //системное сообщение с параметрами и ид. отправителя =0
    MSG_DATA_TYPE_UNSTORED:  'unstored', //нехранимые сообщения
    MSG_DATA_TYPE_UNKNOWN:   'unknown'
}

export const msgDataSubTypes = {
    MSG_DATA_SUB_TYPE_TEXT: 'text',
    MSG_DATA_SUB_TYPE_REPLY: 'reply',
    MSG_DATA_SUB_TYPE_CONTACT: 'contact',
    MSG_DATA_SUB_TYPE_CHANNEL: 'channel',
    MSG_DATA_SUB_TYPE_PUBLICATION: 'publication',
    MSG_DATA_SUB_TYPE_LOCATION: 'location',
    MSG_DATA_SUB_TYPE_IMAGE: 'image',
    MSG_DATA_SUB_TYPE_VIDEO: 'video',
    MSG_DATA_SUB_TYPE_AUDIO: 'audio',
    MSG_DATA_SUB_TYPE_FILE: 'file',
    MSG_DATA_SUB_TYPE_POLL: 'poll',
    MSG_DATA_SUB_TYPE_CALL_AVAILABILITY: 'call-availability',
    MSG_DATA_SUB_TYPE_SURVEY: 'survey',
    MSG_DATA_SUB_TYPE_SCHEDULE: 'schedule'
}

export const msgSystemTypes = {
    MSG_SYSTEM_TYPE_CHAT_CREATED: 'chat-created', //Создана беседа
    MSG_SYSTEM_TYPE_CHAT_ADDED:   'chat-user-added', //В беседу добавлен контакт
    MSG_SYSTEM_TYPE_CHAT_CHANGED: 'chat-user-changed', //Изменение привелегий контакта
    MSG_SYSTEM_TYPE_CHAT_DELETED: 'chat-user-deleted', //Из беседы удален контакт
    MSG_SYSTEM_TYPE_CHAT_UPDATED: 'chat-updated' //Беседа обновлена
}

export const chatTargetTypes = {
    CHAT_TARGET_TYPE_USER:   "user",
    CHAT_TARGET_TYPE_GROUP:   "group"
}

export const userPrivilege = {
    USER:   "user",
    ADMIN:   "admin",
    OWNER:   "owner"
}

export const chatUserPrivilege = {
    CHAT_USER_PRIVILEGE_USER:   "user",
    CHAT_USER_PRIVILEGE_ADMIN:   "admin",
    CHAT_USER_PRIVILEGE_OWNER:   "owner"
}

export const channelUserPrivilege = {
    CHANNEL_USER_PRIVILEGE_USER:   "user",
    CHANNEL_USER_PRIVILEGE_ADMIN:   "admin",
    CHANNEL_USER_PRIVILEGE_OWNER:   "owner"
}

export const channelUserStatus = {
    CHANNEL_USER_STATUS_JOINED:   "joined",
    CHANNEL_USER_STATUS_INVITED:   "invited",
    CHANNEL_USER_STATUS_BANNED:   "banned",
    CHANNEL_USER_STATUS_ABSENT:   "absent"
}

export const fileSignatures = {
    "FFD8FFDB": "JPEG",
    "FFD8FFE0": "JPEG",
    "FFD8FFE1": "JPEG1",
    "FFD8FFE2": "JPEG2",
    "FFD8FFE3": "JPEG3",
    "FFD8FFE8": "JPEG8",
    "47494638": "GIF",
    "89504E47": "PNG",
    "66747970": "MP4",
    "4D345620": "M4V",
    "FFFAD344": "MP3",
    "494433": "MP3",
    "52494646": "RIFF",
    "255044462D": "PDF",

    //@todo использовать сигнатуры вместо расширения файла
    //    MP3: '494433',
    //    WAV: '52494646'
}

export const riffFileSignatures = {
    '57415645': 'WAV',
    '57454250': 'WEBP'
}

export const channel_types = {
    PRIVATE: 'private',
    PUBLIC: 'public'
}

export const channel_user_statuses = {
    JOINED: 'joined',
    INVITED: 'invited',
    BANNED: 'banned',
    ABSENT: 'absent'
}

export const channel_user_privilege = {
    OWNER: 'owner',
    ADMIN: 'admin',
    USER: 'user'
}

export const http_get_dirs = {
    CHATS_ICONS: 'chats/icon',
    CHANNELS_ICONS: 'channels/icon',
    CHAT_DATA_DIR: 'messages/data/',
    CHANNEL_DATA_DIR: 'channels/data/',
    CONTACTS_IMG: 'img/contacts'
}

export const http_post_dirs = {
    CHATS_ICONS: 'chats/icon',
    CHANNELS_ICONS: 'channels/icon',
    CHAT_DATA_DIR: 'messages/data/',
    CHANNEL_DATA_DIR: 'channels/data/',
    CONTACTS_IMG: 'photos/'
}

export const msgLimits = {
    maxFileSize: 1024*1024*50,
    msgLength: 5000
}

export const typingSendInterval = 2000; //ms

export const serverAPILevels = {
    LEVEL_1: 1,
    LEVEL_2: 2,
    LEVEL_3: 3,
    LEVEL_4: 4,
    LEVEL_5: 5,
    LEVEL_6: 6,
    LEVEL_7: 7,
    LEVEL_8: 8,
    LEVEL_9: 9,
    LEVEL_10: 10,
    LEVEL_11: 11,
    LEVEL_12: 12,
    LEVEL_13: 13,
    LEVEL_14: 14,
    LEVEL_15: 15,
}

export const extToMimeType = {
    //   File Extension   MIME Type
    'abs':           'audio/x-mpeg',
    'ai':            'application/postscript',
    'aif':           'audio/x-aiff',
    'aifc':          'audio/x-aiff',
    'aiff':          'audio/x-aiff',
    'aim':           'application/x-aim',
    'art':           'image/x-jg',
    'asf':           'video/x-ms-asf',
    'asx':           'video/x-ms-asf',
    'au':            'audio/basic',
    'avi':           'video/x-msvideo',
    'avx':           'video/x-rad-screenplay',
    'bcpio':         'application/x-bcpio',
    'bin':           'application/octet-stream',
    'bmp':           'image/bmp',
    'body':          'text/html',
    'cdf':           'application/x-cdf',
    'cer':           'application/pkix-cert',
    'class':         'application/java',
    'cpio':          'application/x-cpio',
    'csh':           'application/x-csh',
    'css':           'text/css',
    'dib':           'image/bmp',
    'doc':           'application/msword',
    'dtd':           'application/xml-dtd',
    'dv':            'video/x-dv',
    'dvi':           'application/x-dvi',
    'eot':           'application/vnd.ms-fontobject',
    'eps':           'application/postscript',
    'etx':           'text/x-setext',
    'exe':           'application/octet-stream',
    'gif':           'image/gif',
    'gtar':          'application/x-gtar',
    'gz':            'application/x-gzip',
    'hdf':           'application/x-hdf',
    'hqx':           'application/mac-binhex40',
    'htc':           'text/x-component',
    'htm':           'text/html',
    'html':          'text/html',
    'ief':           'image/ief',
    'jad':           'text/vnd.sun.j2me.app-descriptor',
    'jar':           'application/java-archive',
    'java':          'text/x-java-source',
    'jnlp':          'application/x-java-jnlp-file',
    'jpe':           'image/jpeg',
    'jpeg':          'image/jpeg',
    'jpg':           'image/jpeg',
    'js':            'application/javascript',
    'jsf':           'text/plain',
    'json':          'application/json',
    'jspf':          'text/plain',
    'kar':           'audio/midi',
    'latex':         'application/x-latex',
    'm3u':           'audio/x-mpegurl',
    'mac':           'image/x-macpaint',
    'man':           'text/troff',
    'mathml':        'application/mathml+xml',
    'me':            'text/troff',
    'mid':           'audio/midi',
    'midi':          'audio/midi',
    'mif':           'application/x-mif',
    'mov':           'video/quicktime',
    'movie':         'video/x-sgi-movie',
    'mp1':           'audio/mpeg',
    'mp2':           'audio/mpeg',
    'mp3':           'audio/mpeg',
    'mp4':           'video/mp4',
    'm4v':           'video/mp4',
    'mpa':           'audio/mpeg',
    'mpe':           'video/mpeg',
    'mpeg':          'video/mpeg',
    'mpega':         'audio/x-mpeg',
    'mpg':           'video/mpeg',
    'mpv2':          'video/mpeg2',
    'ms':            'application/x-wais-source',
    'nc':            'application/x-netcdf',
    'oda':           'application/oda',
    'odb':           'application/vnd.oasis.opendocument.database',
    'odc':           'application/vnd.oasis.opendocument.chart',
    'odf':           'application/vnd.oasis.opendocument.formula',
    'odg':           'application/vnd.oasis.opendocument.graphics',
    'odi':           'application/vnd.oasis.opendocument.image',
    'odm':           'application/vnd.oasis.opendocument.text-master',
    'odp':           'application/vnd.oasis.opendocument.presentation',
    'ods':           'application/vnd.oasis.opendocument.spreadsheet',
    'odt':           'application/vnd.oasis.opendocument.text',
    'otg':           'application/vnd.oasis.opendocument.graphics-template',
    'oth':           'application/vnd.oasis.opendocument.text-web',
    'otp':           'application/vnd.oasis.opendocument.presentation-template',
    'ots':           'application/vnd.oasis.opendocument.spreadsheet-template',
    'ott':           'application/vnd.oasis.opendocument.text-template',
    'ogx':           'application/ogg',
    'ogv':           'video/ogg',
    'oga':           'audio/ogg',
    'ogg':           'audio/ogg',
    'otf':           'application/x-font-opentype',
    'spx':           'audio/ogg',
    'flac':          'audio/flac',
    'anx':           'application/annodex',
    'axa':           'audio/annodex',
    'axv':           'video/annodex',
    'xspf':          'application/xspf+xml',
    'pbm':           'image/x-portable-bitmap',
    'pct':           'image/pict',
    'pdf':           'application/pdf',
    'pgm':           'image/x-portable-graymap',
    'pic':           'image/pict',
    'pict':          'image/pict',
    'pls':           'audio/x-scpls',
    'png':           'image/png',
    'pnm':           'image/x-portable-anymap',
    'pnt':           'image/x-macpaint',
    'ppm':           'image/x-portable-pixmap',
    'ppt':           'application/vnd.ms-powerpoint',
    'pps':           'application/vnd.ms-powerpoint',
    'ps':            'application/postscript',
    'psd':           'image/vnd.adobe.photoshop',
    'qt':            'video/quicktime',
    'qti':           'image/x-quicktime',
    'qtif':          'image/x-quicktime',
    'ras':           'image/x-cmu-raster',
    'rdf':           'application/rdf+xml',
    'rgb':           'image/x-rgb',
    'rm':            'application/vnd.rn-realmedia',
    'roff':          'text/troff',
    'rtf':           'application/rtf',
    'rtx':           'text/richtext',
    'sfnt':          'application/font-sfnt',
    'sh':            'application/x-sh',
    'shar':          'application/x-shar',
    'sit':           'application/x-stuffit',
    'snd':           'audio/basic',
    'src':           'application/x-wais-source',
    'sv4cpio':       'application/x-sv4cpio',
    'sv4crc':        'application/x-sv4crc',
    'svg':           'image/svg+xml',
    'svgz':          'image/svg+xml',
    'swf':           'application/x-shockwave-flash',
    't':             'text/troff',
    'tar':           'application/x-tar',
    'tcl':           'application/x-tcl',
    'tex':           'application/x-tex',
    'texi':          'application/x-texinfo',
    'texinfo':       'application/x-texinfo',
    'tif':           'image/tiff',
    'tiff':          'image/tiff',
    'tr':            'text/troff',
    'tsv':           'text/tab-separated-values',
    'ttf':           'application/x-font-ttf',
    'txt':           'text/plain',
    'ulw':           'audio/basic',
    'ustar':         'application/x-ustar',
    'vxml':          'application/voicexml+xml',
    'xbm':           'image/x-xbitmap',
    'xht':           'application/xhtml+xml',
    'xhtml':         'application/xhtml+xml',
    'xls':           'application/vnd.ms-excel',
    'xml':           'application/xml',
    'xpm':           'image/x-xpixmap',
    'xsl':           'application/xml',
    'xslt':          'application/xslt+xml',
    'xul':           'application/vnd.mozilla.xul+xml',
    'xwd':           'image/x-xwindowdump',
    'vsd':           'application/vnd.visio',
    'wav':           'audio/x-wav',
    'wbmp':          'image/vnd.wap.wbmp',
    'wml':           'text/vnd.wap.wml',
    'wmlc':          'application/vnd.wap.wmlc',
    'wmls':          'text/vnd.wap.wmlsc',
    'wmlscriptc':    'application/vnd.wap.wmlscriptc',
    'wmv':           'video/x-ms-wmv',
    'woff':          'application/font-woff',
    'woff2':         'application/font-woff2',
    'wrl':           'model/vrml',
    'wspolicy':      'application/wspolicy+xml',
    'z':             'application/x-compress',
    'zip':           'application/zip'
};

