export default {
    emitters: {
        async getProfile() {
            try {
                let res = await this._emitWithTimeOut('get-profile', null, 1000)
                this.log('get-profile: < ' + JSON.stringify(res))
                return res;
            } catch(err) {
                this.log('get-profile error '+  JSON.stringify(err))
                throw('get-profile error')
            }
        },
        async setProfile(params) {
            this.log('set-profile: > ' + JSON.stringify(params))
            const data = {
                key: params.key,
                value: params.value,
            };
            try {
                let profile = await this._emitWithTimeOut('set-profile', data)
                this.log('profile from server: ' + JSON.stringify(profile))
                if (profile.error) {
                    throw("set-profile error: < " + profile.error)
                }
                return profile
            } catch(err) {
                this.log('set-profile error '+  JSON.stringify(err))
                throw('set-profile error')
            }
        },
    },
    listeners: {
        'profile-event'(data) {
            this.log('bind: < profile-event: ' + JSON.stringify(data))
            this.emit('profile-event', data)
        }
    },
}