'use strict';

import {
    ACT_OPEN_DEFAULT_NOTIFICATION,
    ACT_OPEN_CUSTOM_NOTIFICATION,
    ACT_OPEN_ELECTRON_NOTIFICATION,
    ACT_ADD_NOTIFICATION,
    ACT_NOTIFICATION_ELECTRON_STATUS,
    ACT_CLOSE_ELECTRON_NOTIFICATION,
    ACT_ONCLICK_NOTIFICATION,
    ACT_CLOSE_DEFAULT_NOTIFICATION,
    ACT_OPEN_CHANNEL,
    ACT_SHOW_SYSTEM_NOTIFICATION,
    ACT_HIDE_SYSTEM_NOTIFICATION
} from '../actionsTypes';
import {
    GET_NOTIFICATION,
    GET_CUSTOM_NOTIFICATION_DATA,
    GET_NOTIFICATION_ELECTRON_STATUS,
    GET_NOTIFICATION_DEFAULT_STATUS,
    GET_ACTIVE_SPEAKERS,
    GET_VOLUME_NOTIFICATION,
} from '../gettersTypes';
import ipc from "../../../electron/ipc";
import {CONTENT_MANAGER, PHONE, CHANNEL, USERDATA} from "../modulesNames";
import {
    MUT_SET_SIDE_TYPE,
    ADD_NOTIFICATION,
    CLEAR_STACK_NOTIFICATION,
    MUT_CLEAR_RELOGIN_INTERVAL,
    UPDATE_CUSTOM_NOTIFICATION_DATA,
    NOTIFICATION_ELECTRON_STATUS,
    NOTIFICATION_DEFAULT_STATUS,
    MUT_SCROLL_TO_PUBLICATION,
    DELETE_SYSTEM_NOTIFICATION, 
    ADD_SYSTEM_NOTIFICATION
} from "../mutationsTypes";

export default  {
    namespaced: true,
    state: {
        customNotificationData: null,
        stackNotification: [],
        showNotificationDefault: false,
        showNotificationElectron: false,
        notification: null,
        systemNotification: null
    },
    getters: {
        [GET_CUSTOM_NOTIFICATION_DATA](state) {
            return state.customNotificationData;
        },
        [GET_NOTIFICATION](state) {
            return state.stackNotification[state.stackNotification.length - 1];
        },
        [GET_NOTIFICATION_ELECTRON_STATUS](state) {
            return state.showNotificationElectron;
        },
        [GET_NOTIFICATION_DEFAULT_STATUS](state) {
            return state.showNotificationDefault;
        },
    },
    actions: {
        [ACT_OPEN_DEFAULT_NOTIFICATION]({state, rootGetters, dispatch, commit}, data) {
            if (!('Notification' in window)) console.log('This browser does not support desktop notification');
            else if (Notification.permission === 'granted') createNotify.call(this);
            else if (Notification.permission !== 'denied') {
                Notification.requestPermission(permission => {if(permission === 'granted') createNotify();});
            };

            function createNotify() {
                if(this.notification) {
                    this.notification.close();
                }
                // Событие onClose приходит позже чем создается нотификация
                setTimeout(() => {
                    this.notification = new Notification(data.params.title, data.params.options);
                    this.notification.onclick = () => click(data);
                    this.notification.onclose = () => close();
                    commit(NOTIFICATION_DEFAULT_STATUS, true)
                }, 0)
            };

            function close() {
                commit(NOTIFICATION_DEFAULT_STATUS, false)
            }

            function click(data) {
                dispatch(ACT_ONCLICK_NOTIFICATION, {type: data.type, params: data.params.options.data})
                commit(NOTIFICATION_DEFAULT_STATUS, false)
            }
        },
        [ACT_ONCLICK_NOTIFICATION]({state, dispatch, commit}, data) {
            window.focus();
            if(data.type === 'chat') {
                dispatch('chats/update', null, {root: true});
                dispatch('chats/open', data.params, {root: true});
                commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_TYPE}`, 'chats', {root: true});
            } else if(data.type === 'calls-list') commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_TYPE}`, 'calls-list', {root: true});
            else if(data.type === 'chats-list') commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_TYPE}`, 'chats', {root: true});
            else if(data.type === 'publication') {
                dispatch(`${CHANNEL}/${ACT_OPEN_CHANNEL}`, data.params.chId, {root: true});
                commit(`${CHANNEL}/${MUT_SCROLL_TO_PUBLICATION}`, { chId: data.params.chId, pubId: data.params.pubId}, {root: true});
            }
        },
        [ACT_OPEN_CUSTOM_NOTIFICATION]({commit}, data) {
            console.log('%c ipc.ACT_OPEN_CUSTOM_NOTIFICATION', 'color: orange;')
            commit(UPDATE_CUSTOM_NOTIFICATION_DATA, data);
        },
        [ACT_OPEN_ELECTRON_NOTIFICATION]({rootGetters}, data) {
            console.log('%c ipc.ACT_OPEN_ELECTRON_NOTIFICATION', 'color: orange;')
            let speakers = rootGetters[`${USERDATA}/${GET_ACTIVE_SPEAKERS}`]
            if (speakers && speakers.deviceId) data.speakerDeviceId = speakers.deviceId
            data.speakerVolume = rootGetters[`${USERDATA}/${GET_VOLUME_NOTIFICATION}`]
            ipc.send('notification', data);
        },
        [ACT_CLOSE_ELECTRON_NOTIFICATION]() {
            console.log('%c ipc.ACT_CLOSE_ELECTRON_NOTIFICATION', 'color: orange;')
            ipc.send('close-notification');
        },
        [ACT_CLOSE_DEFAULT_NOTIFICATION]({commit}) {
            console.log('%c ipc.ACT_CLOSE_DEFAULT_NOTIFICATION', 'color: orange;')
            this.notification && this.notification.close();
            commit(NOTIFICATION_DEFAULT_STATUS, false)
        },
        [ACT_ADD_NOTIFICATION]({commit}, data) {
            commit(ADD_NOTIFICATION, data);
        },
        [ACT_NOTIFICATION_ELECTRON_STATUS]({commit}, data) {
            commit(NOTIFICATION_ELECTRON_STATUS, data);
        },
        [ACT_SHOW_SYSTEM_NOTIFICATION]: ({ commit, state, rootGetters }, data) => {
            let theme = rootGetters['clientdata/getTheme']
            data.theme = theme
            if (state.systemNotification) ipc.send('system-notification-update', data)
            else ipc.send('system-notification-show', data)
            commit(ADD_SYSTEM_NOTIFICATION, data)         
        },
        [ACT_HIDE_SYSTEM_NOTIFICATION]: ({ state, commit }) => {
            if (state.systemNotification) {
                ipc.send('system-notification-hide')
                commit(DELETE_SYSTEM_NOTIFICATION)
            }            
        }
    },
    mutations: {
        [ADD_NOTIFICATION](state, data) {
            state.stackNotification.push(data);
        },
        [CLEAR_STACK_NOTIFICATION]() {
            state.stackNotification.splice(0,state.stackNotification.length);
        },
        [UPDATE_CUSTOM_NOTIFICATION_DATA](state, data) {
            state.customNotificationData = data;
        },
        [NOTIFICATION_ELECTRON_STATUS](state, data) {
            state.showNotificationElectron = data;
        },
        [NOTIFICATION_DEFAULT_STATUS](state, data) {
            state.showNotificationDefault = data;
        },
        [ADD_SYSTEM_NOTIFICATION](state, data) {
            state.systemNotification = data
        },
        [DELETE_SYSTEM_NOTIFICATION](state) {
            state.systemNotification = null
        }
    }
}
