import { render, staticRenderFns } from "./AcsJournal.vue?vue&type=template&id=2a9a94c7&scoped=true&lang=pug&"
import script from "./AcsJournal.vue?vue&type=script&lang=js&"
export * from "./AcsJournal.vue?vue&type=script&lang=js&"
import style0 from "./AcsJournal.vue?vue&type=style&index=0&id=2a9a94c7&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a9a94c7",
  null
  
)

export default component.exports