
import {mapActions} from 'vuex'
import {CHANNELS} from '../../store/modulesNames'
import {ACT_DEL_CHANNEL} from '../../store/actionsTypes'
import Confirm from '../modal/Confirm.vue'
import { i18n } from '../../../ext/i18n'

export default {
    name: 'DeleteChannel',
    extends: Confirm,
    computed: {
        text: () => i18n.messages[i18n.locale]['channel-comp']['delete-conf']
    },
    methods: {
        async btnOkCb() {
            await this[ACT_DEL_CHANNEL]({chId: this.props.chId})
        },
        ...mapActions(CHANNELS, [ACT_DEL_CHANNEL]),
    }
}
