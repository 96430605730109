//
//
//
//
//
//
//
//
//
//

'use strict';
import { i18n } from '../../../ext/i18n'
import EnterConf from '../videoConfNewManagment/enterConference.vue';
export default {
    name: 'custom-div',
    props: {
        id: {
            type: String,
            required: false,
            default: 'customEditableDiv'
        },
        content: {
            type: String,
            required: false,
            default: null
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
        width: {
            type: [Number,String],
            required: false,
            default: 'auto'
        },
        height: {
            type: [Number,String],
            required: false,
            default: 'auto'
        },
    },
    data() {
        return {
            locale: i18n.messages[i18n.locale],
            placeholder: false,
            focused: false,
        }
    },
    mounted() {        
        window.vueInstance = this
        document.getElementById(this.id).innerHTML = this.lookForLinks(this.content)
    },
    // watch: {
    //     content(val) {
    //         console.log("🚀 ~ file: CustomDiv.vue:66 ~ content ~ val:", val)
    //     },
    // },
    computed: {
        style() {
            let width = '', height = ''
            if (typeof this.width === 'number') width = this.width + 'px'
            if (typeof this.height === 'number') height = this.height + 'px'
            return { outline: 'none', width, height}
        },
    },
    methods: {
        update(event) {
            this.$emit('updated-content', event.target.innerText)
        },
        focusInput() {
            setTimeout(() => {
                let elem = document.getElementById(this.id)
                elem.focus()
            }, 0)
        },
        openContextMenu(event, message) {
            const handlers = []

            this.cmOpen(event, handlers, 'right-bottom')
        },
        async openVCContextMenu(e) {
            if (navigator.platform.toUpperCase().indexOf('MAC')>=0) await (m => new Promise((resolve) => setTimeout(resolve, m)))(100)
            let { event, type, vcLink } = e.detail
            let handlers = []
            let open = this.cmOpen
            handlers.push({ item_name: this.$t('videomost.join'), handler: this.joinVideoConf, data: {type, vcLink} })
            handlers.push({ item_name: this.$t('copy-link'), handler: this.copyToClipboard, data: vcLink })
            open(event, handlers, 'right-bottom')
        },
        joinVideoConf({type, vcLink}) {
            this.modalClose({ name: 'MeetingScheduler' })
            this.modalOpen({ component: EnterConf, props: { type, vcLink: vcLink } })
        },
        copyToClipboard(text) {
            if (!text) return
            this.$copyText(text, undefined, undefined)
        },
    }    
}
