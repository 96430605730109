var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.calls.length
    ? _c("div", { staticClass: "calls-selector" }, [
        _vm.calls.length > 1
          ? _c(
              "div",
              {
                staticClass: "show-calls-btn",
                on: { click: _vm.toggleCallsList }
              },
              [
                _vm.showCallsList
                  ? _c("i", { staticClass: "impulse-animation fas fa-sort-up" })
                  : _c("i", {
                      staticClass: "impulse-animation fas fa-sort-down"
                    })
              ]
            )
          : _vm._e(),
        _vm.showCallsList && _vm.calls.length > 1
          ? _c(
              "div",
              { staticClass: "calls-list" },
              _vm._l(_vm.calls, function(call) {
                return _c(
                  "div",
                  {
                    staticClass: "call",
                    on: {
                      click: function($event) {
                        return _vm.showSelectCall(call)
                      }
                    }
                  },
                  [
                    _c("icon-image", {
                      staticClass: "icon-image",
                      attrs: {
                        src: call.user && call.user.photo,
                        text: call.user && call.user.fio,
                        title: call.user && call.user.fio
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm.calls.length === 1
          ? _c(
              "div",
              {
                staticClass: "call",
                on: {
                  click: function($event) {
                    return _vm.showSelectCall(_vm.calls[0])
                  }
                }
              },
              [
                _c("icon-image", {
                  staticClass: "icon-image",
                  attrs: {
                    src: _vm.calls[0].user && _vm.calls[0].user.photo,
                    text: _vm.calls[0].user && _vm.calls[0].user.fio,
                    title: _vm.calls[0].user && _vm.calls[0].user.fio
                  }
                }),
                _vm._m(0)
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "icon-pause-btn impulse-animation-background" },
      [
        _c("i", {
          staticClass: "impulse-animation fas fa-pause",
          attrs: { id: "pause" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }