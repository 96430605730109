function addMessageDefaultData (msg) {
    if (!msg) return false
    if (!('cidType' in msg)) msg.cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER
    try {
        if (msg.dataType && (msg.dataType === 'data' || msg.dataType === 'unstored')) {
            msg.data = JSON.parse(msg.data)
        }
        return true
    } catch (e) {
        // this.log('addMessageDefaultData: < error: invalid data: ' + msg.data)
        return false
    }
}

export default {
    emitters: {
        /**
         * @param params {Object}
         * @param [params.cidType='user'] {String}
         * @param params.cid {Number}
         * @param [params.dataType='text']
         * @param [params.data] {String}
         * @param [params.dataFile] {String}
         * @param cb
         */
        sendMessage(params, cb) {
            // var data = { cidType: params.cidType, cid: params.cid, dataType: params.dataType, data:  params.data, dataFile: params.dataFile, replyId: params.replyId }; //@todo здесь в строку преоброзововать
            this.log('sendMessage: > ' + JSON.stringify(params))
            this.socket.emit('send-message', params, (data) => {
                this.log('sendMessage: < ' + JSON.stringify(data))
                return cb && cb(data)
            })
        },
        sendMessageAsync(params) {
            return new Promise((resolve, reject) => this.sendMessage(params, resolve))
        },
        /**
         * @param cidType {String}
         * @param cid {Number}
         */
        sendTypingEvent(cidType, cid) {
            let dataType = 'unstored'
            let data = { cidType, cid, dataType, data: JSON.stringify({ 'type': 'message-writing' }) }
            this.log('sendTyping: > ' + JSON.stringify(data))
            this.socket.emit('send-message', data, (data) => {
                this.log('sendTyping: < ' + JSON.stringify(data))
            })
        },
        editMessage(payload) {
            return new Promise((resolve, reject) => {
                this.log('editMessage: > ' + JSON.stringify(payload))
                this.socket.emit('edit-message', payload, (data) => {
                    let {rev, error} = data || {}
                    if (error) {
                        this.log('editMessage: < edit-message error: ' + error)
                        return reject(new Error(error))
                    }
                    this.log('edit-message: < rev: ' + rev)
                    resolve(rev)
                })
            })
        },
        getMessages(params) {
            return new Promise((resolve, reject) => {
                let data = {
                    cidType: params.cidType,
                    cid: params.cid,
                    startId: params.startId,
                    count: params.count,
                    minId: params.minId
                }
                this.log('getMessages: > ' + JSON.stringify(data))
                this.socket.emit('get-messages', data, (data) => {
                    this.log('getMessages: < ' + data.length)
                    data.forEach((cur, index) => {
                        if (!addMessageDefaultData(cur)) delete data[index]
                    })
                    resolve(data)
                })
            })
        },
        getMessagesMedia(params) {
            return new Promise((resolve, reject) => {
                let data = {
                    cidType: params.cidType,
                    cid: params.cid,
                    type: params.type,
                    filter: params.filter,
                    startId: params.startId,
                    count: params.count
                }
                this.log('getMessagesMedia: > ' + JSON.stringify(data))
                this.socket.emit('get-messages-media', data, (data) => {
                    this.log('getMessagesMedia: < ' + data.length)
                    data.forEach((cur, index) => {
                        if (!addMessageDefaultData(cur)) delete data[index]
                    })
                    resolve(data)
                })
            })
        },
        getMessage(params) {
            return new Promise((resolve, reject) => {
                let data = {cidType: params.cidType, cid: params.cid, id: params.id}
                this.log('getMessage: > ' + JSON.stringify(params))
                this.socket.emit('get-message', data, (data) => {
                    this.log('getMessage: < ' + JSON.stringify(data))
                    if (!addMessageDefaultData(data)) data = null
                    resolve(data)
                })
            })
        },
        getMessageDetails(params, cb) {
            let data = { cidType: 'group', cid: params.cid, id: params.id }
            this.log('getMessageDetails: > ' + JSON.stringify(data))
            data && this.socket.emit('get-message-details', data, (data) => {
                this.log('getMessageDetails: < ' + data.length)
                return cb && cb(data)
            })
        },
        setMessageReceived(id) {
            return new Promise((resolve, reject) => {
                const data = {id}
                this.log('setMessageReceived: > ' + JSON.stringify(data))
                this.socket.emit('set-message-received', data, (data) => {
                    if (!data || !data.rev) {
                        this.log('setMessageReceived: < set-message-received error: ' + JSON.stringify(data))
                        return reject()
                    }
                    this.log('setMessageReceived: < ' + JSON.stringify(data))
                    resolve(data.rev)
                })
            })
        },
        setMessageWatched(id, cb) {
            let data = { id: id }
            this.log('setMessageWatched: > ' + JSON.stringify(data))
            this.socket.emit('set-message-watched', data, (data) => {
                this.log('setMessageWatched: < ' + JSON.stringify(data))
                return cb && cb(data)
            })
        },
        setMessageWatchedAsync(id) {
            return new Promise((resolve, reject) => {
                let data = {id}
                this.log('setMessageWatched: > ' + JSON.stringify(data))
                this.socket.emit('set-message-watched', data, (data) => {
                    this.log('setMessageWatched: < ' + JSON.stringify(data))
                    resolve(data)
                })
            })
        },
        setMessageDeleted(id, everyone) {
            return new Promise((resolve, reject) => {
                let data = {id: id}
                if (everyone) data.everyone = everyone
                this.log('setMessageDeleted: > ' + JSON.stringify(data))
                this.socket.emit('set-message-deleted', data, (data) => {
                    this.log('setMessageDeleted: < ' + JSON.stringify(data))
                    resolve(data)
                })
            })
        },
        getUnwatchedMessages(msgIds, cb) {
            let data = msgIds
            this.log('getUnwatchedMessages: > ' + JSON.stringify(data))
            this.socket.emit('get-unwatched-messages', data, (data) => {
                this.log('getUnwatchedMessages:  < ' + JSON.stringify(data))
                data.forEach((cur) => { addMessageDefaultData(cur) })
                cb && cb(data)
            })
        },
        getUnwatchedMessagesAsync(msgIds) {
            return new Promise((resolve, reject) => {
                let data = msgIds
                this.log('getUnwatchedMessagesAsync: > ' + JSON.stringify(data))
                this.socket.emit('get-unwatched-messages', data, (data) => {
                    this.log('getUnwatchedMessagesAsync:  < ' + JSON.stringify(data))
                    data.forEach((cur) => addMessageDefaultData(cur))
                    resolve(data)
                })
            })
        }
    },
    listeners: {
        'message-event'(data) {
            this.log('bind: < message-event: ' + JSON.stringify(data))
            if (addMessageDefaultData(data)) this.emit('message-event', data)
        },
        'message-change-event'(data) {
            this.log('bind: < message-change-event: ' + JSON.stringify(data))
            this.emit('message-change-event', data)
        },
        'message-update-event'(data) {
            this.log('bind: < message-update-event: ' + JSON.stringify(data))
            if (addMessageDefaultData(data)) this.emit('message-update-event', data)
        },
    },
}