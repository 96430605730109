//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters, mapActions } from 'vuex';
import {
    GET_LOGIN_STATUS,
    GET_IS_LOGINED,
    GET_IS_LOADED,
    GET_LOGIN_DATA,
    SOCKET_IS_CONNECTED,
    IS_TOKENS_SET,
    GET_IS_ELECTRON,
    GET_CLIENT_VERSION,
} from "../../store/gettersTypes";
import {
    ACT_SET_LOGGING_IN,
    ACT_LOGIN,
    ACT_RELOGIN,
    ACT_DROP_LOGIN_DATA,
} from "../../store/actionsTypes";
import {
    SOCKET,
    LOGIN,
    CLIENTDATA,
} from "../../store/modulesNames";
import entrance from './Entrance.vue';
import login_mixin from './login-mixin';
// import storage from "../../storage"
import { LOGIN_STATUSES } from "../../store/modules/login";

export default {
    name: "login",
    components: { entrance },
    mixins: [login_mixin],
    data() {
        return {
            section_hidden: false,
            changed: false,
            error: '',
            year: (new Date()).getFullYear(),
            pinrequired: false,
            isLeftBlock: true,
            resizeObserver: null,
        }
    },
    mounted() {
        this.resizeObserver = new ResizeObserver(() => {
            let { innerWidth } = window
            if (innerWidth < 500) this.isLeftBlock = false
            else this.isLeftBlock = true
        })
        this.resizeObserver.observe(document.body)
    },
    computed: {
        logoImg() {
            const isDarkTheme = this.$store.getters['clientdata/getTheme'] === 'dark'
            return isDarkTheme ? 'img/logo_roschat_dark.png' : 'img/logo_roschat_light.png'
        },
        auto() {
            return this[GET_LOGIN_DATA] && (this[GET_LOGIN_DATA].server_host || !this[GET_IS_ELECTRON]) && this[GET_LOGIN_DATA].remember_me && !this.changed;
        },
        autoLoginInProgress() {
            return this.auto && !this.error && !this.pinrequired
        },
        ...mapGetters(SOCKET, [SOCKET_IS_CONNECTED]),
        ...mapGetters(LOGIN, {
            [GET_LOGIN_STATUS]: GET_LOGIN_STATUS,
            'logined': GET_IS_LOGINED,
            'loaded': GET_IS_LOADED,
            [GET_LOGIN_DATA]: GET_LOGIN_DATA,
            [IS_TOKENS_SET]: IS_TOKENS_SET,
        }),
        clientVersion() {
            return this[GET_CLIENT_VERSION]
        },
        ...mapGetters(CLIENTDATA, [GET_IS_ELECTRON, GET_CLIENT_VERSION]),
    },
    methods: {
        async autoLogIn() {
            const remember_me = this[GET_LOGIN_DATA].remember_me
            if (remember_me) {
                let login = this[GET_LOGIN_DATA].login,
                    password = this[GET_LOGIN_DATA].password,
                    tokens = this[GET_LOGIN_DATA].tokens
                if (!((login && password) || tokens)) return
                try {
                    const res = await this[ACT_LOGIN]({ login, password, remember_me, tokens })
                    if (res.status === 'pin-required') {
                        this.pinrequired = true
                        this.$refs.entrance.doAdd('2FA', { login, password, remember_me, uid: res.uid })
                    } else this.pinrequired = false
                    this.error = ''
                    this.$store.dispatch('alarm/setAlarmProps', {})
                } catch (e) {
                    this.pinrequired = false
                    if (e === 'access-denied') throw 'access-denied'
                    if (e === 'relogin-required') return this[ACT_DROP_LOGIN_DATA]()
                    if (tokens) throw 'connection-refused'
                    this.error = e
                    this.$nextTick(() => {
                        this.$refs.entrance.doAdd('login')
                        this.$nextTick(async () => {
                            if (this.logined) return
                            await this.showAlert(this.getLoginError(e))
                            this.$nextTick(() => {
                                this[ACT_DROP_LOGIN_DATA]()
                            })
                        })
                    })
                }
            }
        },
        toggleLoginScreen(hide) {
            document.querySelector('.columns-layout').style.display = hide ? '' : 'none'; //@todo
        },
        ...mapActions(LOGIN, [ACT_SET_LOGGING_IN, ACT_LOGIN, ACT_RELOGIN, ACT_DROP_LOGIN_DATA])
    },
    async created() {
        const login = async () => {
            this[ACT_SET_LOGGING_IN]()
            try {
                await app.init()
                await this.autoLogIn()
            } catch (e) {
                let title = this.$t('no-serv-conn'), errorText = this.getLoginError(e)
                if (e === 'access-denied') {
                    title = this.$t('serv-conn')
                    e = 'access-denied'
                }
                if (e === 'connection-refused') e = 'connection-refused-retry'

                if (await this.showConfirm(title, errorText)) {
                    await login()
                }
                else {
                    await this[ACT_DROP_LOGIN_DATA]()
                    history.go(0)
                }
            }
        }
        if (this.auto) login()
    },
    watch: {
        [GET_LOGIN_STATUS](new_val) {
            if (new_val === LOGIN_STATUSES.LOGGED_IN) this.toggleLoginScreen(true)
            else if (new_val === LOGIN_STATUSES.NOT_LOGGED_IN) this.toggleLoginScreen(false)
        },
        [SOCKET_IS_CONNECTED](new_val) {
            if (!new_val) return
            if (this[GET_LOGIN_STATUS] === LOGIN_STATUSES.NOT_LOGGED_IN) this.autoLogIn()
            if ([LOGIN_STATUSES.RELOGGING_IN, LOGIN_STATUSES.LOGGED_IN].includes(this[GET_LOGIN_STATUS])) this[ACT_RELOGIN]()
        },
        loaded(new_val) {
            if (new_val) this.changed = false;
        }
    },
    beforeDestroy() {
        this.resizeObserver.unobserve(document.body)
    },
}
