
import CustomRoundBtn from "../custom/CustomRoundBtn.vue"

export default {
    name: "InfoIcon",
    extends: CustomRoundBtn,
    props: {
        iconColor: {
            type: String,
            required: false,
            default: 'var(--icon-roundbtn-color)'
        },
    },
}
