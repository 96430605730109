
import SelectChatToSendMsg from "./SelectChatToSendMsg.vue"

export default {
    name: "SelectChatToShare",
    extends: SelectChatToSendMsg,
    computed: {
        okText() { return this.$t('mainPage.share') }
    }
}
