//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import { CHATS } from '../../store/modulesNames'
import {ACT_CHANGE_CHAT } from '../../store/actionsTypes'


import CustomRadio from '../custom/CustomRadio.vue';
import ModalDialog from "./ModalDialog.vue"
import modal_mixin from "./modal-mixin"


export default {
    name: 'ChatReadOnlyDialog',
    components: { ModalDialog, CustomRadio },
    mixins: [ modal_mixin ],
    data() {
        return {
            title: this.$t('chat-read-only'),
            compModalProps: {
                delay: 200,
                pivotY: 0.05,
                width: 500,
                height: 'auto'
            },
            readOnly: this.props.readOnly || false,
            inputs: [
                {
                    value: false,
                    label: this.$t('chat-read-only-long-off'),
                },
                {
                    value: true,
                    label: this.$t('chat-read-only-long-on'),
                },
            ]
        };
    },
    computed: {
        cid() { return this.props.cid },
        cidType() { return this.props.cidType }
    },
    methods: {
        doChangeSettings() {
            this[ACT_CHANGE_CHAT]({
                cid: this.cid,
                cidType: this.cidType,
                readOnly: this.readOnly,
            })
            this.close()
        },
        close() {
            this.modalClose()
        },
        ...mapActions(CHATS, [ACT_CHANGE_CHAT])
    }
}
