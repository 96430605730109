var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "custom-div-wrapper",
      on: {
        click: _vm.focusInput,
        vccontextmenu: function($event) {
          return _vm.openVCContextMenu($event)
        }
      }
    },
    [
      _c("div", {
        staticClass: "edit-div",
        style: _vm.style,
        attrs: { id: _vm.id, contenteditable: _vm.editable },
        domProps: { innerHTML: _vm._s(_vm.content) },
        on: { input: _vm.update }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }