//
//
//
//
//
//
//
//
//
//
//
//
//
//

import proto from '../../protocol';
import login_mixin from './login-mixin';
import CustomInputText from "../custom/CustomInputText.vue";

const MAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
    name: "PassRestore",
    mixins: [login_mixin],
    components: {CustomInputText},
    data() {
        return {
            login: null,
            show: false,
            sended: false,
            sending: false
        }
    },
    methods: {
        async doPassRestore() {
            let mail = this.login,
                input = this.$refs.input_mail.$el.querySelector('input');

            if (!mail) return setTimeout(() => input.focus(), 200);
            if (!MAIL_REGEX.test(mail)) return this.showAlert(this.$t('errors.wrong-email'), input);
            this.sending = true
            try {
                await proto.sendPassRestoreMail(mail);
                this.sended = true;
            } catch (e) {
                log('sendPassRestoreMail error: ' + e);
                let error_msg;
                switch (e) {
                    case 'not-connected':
                        error_msg = this.$t('errors.not-connected')
                        break;
                    case 'addressee_not_set':
                        error_msg = this.$t('errors.email-not-set')
                        break;
                    case 'wrong_mail_format':
                        error_msg = this.$t('errors.wrong-email')
                        break;
                    default: error_msg = this.$t('errors.unknown')
                }
                this.showAlert(error_msg);
            }
            this.sending = false
        }
    }
}
