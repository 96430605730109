var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select" },
    [
      _c("recycle-contacts-list", {
        ref: "scroller",
        attrs: { contactsList: _vm.filterValuesList },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("name-list-item", {
                  attrs: {
                    contact: item,
                    noswitch: true,
                    marked: _vm.isMarked(item),
                    noSelection: true
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.selectFilterValue(item)
                    }
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }