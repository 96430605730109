//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChatPollMessage from '../chatPollMessage.vue';
import PublicationAudioItem from './PublicationAudioItem.vue'
import PublicationContactItem from './PublicationContactItem.vue'
import PublicationDocumentItem from './PublicationDocumentItem.vue'
import PublicationImageItem from './PublicationImageItem.vue'
import PublicationLocationItem from './PublicationLocationItem.vue'
import PublicationVideoItem from './PublicationVideoItem.vue'
import ReferenceMsg from '../chat/ReferenceMsg.vue';

export default {
    name: "PublicationBody",
    components: {ChatPollMessage, PublicationAudioItem, PublicationContactItem, PublicationDocumentItem, PublicationImageItem, PublicationLocationItem, PublicationVideoItem, ReferenceMsg},
    props: ['chId', 'pubId'],
    computed: {
        publication() {
            return this.$store.getters['channel/getPublicationById']({pubId: this.pubId});
        },
        data() { return this.$store.getters['channel/getPublicationPreparedDataItems']({pubId: this.pubId}) },
        title() {
            return this.text(this.publication.title, false);
        },
        info() {
            return this.text(this.publication.info, true);
        },
        unixLastTime() {
            return this.publication.unixLastTime
        }
    },
    methods: {
        text(value, title) {
            let arr = [];
            let count, arrText, arrRef;
            if(value && title) {
                arrText =  value.split(/@\[.+?\]/) || [];
                arrRef = value.match(/@\[.+?\]/g) || [];
            }else if(value && !title) {
                arrText = [value];
                arrRef = [];
            } else {
                arrText = [];
                arrRef = [];
            }
            if(arrText.length >= arrRef.length) count = arrText.length;
            else count = arrRef.length;
            for(let i = 0; i < count; i++) {
                arrText[i] && arr.push({text: arrText[i], cid: false});
                let arrUSer = arrRef[i] && arrRef[i].slice(0, -1).slice(2).split(':');
                arrUSer && arr.push({text: arrUSer[1],cid: arrUSer[0]});
            }
            return arr;
        },
        msgTextHtml(text) {
            if (!text) return ''
            text = text.replace(/</g,'\x7F').replace(/>/g,'\x8F')
            let { outText, entities} = this.extractInputTextFormat(text)
            let formattedText = this.messageTextFormat(entities, outText)
            formattedText = formattedText.replace(/\x7F/g,'&lt;').replace(/\x8F/g,'&gt;')
            return formattedText
        },
        getTextEntities(text) {
            if (!text) return { outText: "", entities: [] }
            return this.extractInputTextFormat(text)
        },
        openInfo(cid) {
            if(cid) app.openInfo('contact-info', {cid: +cid});
        },
        doContextMenuRef(e, user) {
            e.preventDefault();
            e.stopPropagation();
            let handlers = [];
            let open = this.cmOpen;
            let pubId = app.store.getters['channel/getPublicationById']({pubId: this.pubId});
            let voteResult, pollId;
            if(user) {
                let data;
                let tagsRE = /(\<(\/?[^>]+)>)/g;
                if(e.target.className === 'e-mail') data = e.target.innerText;
                else if(tagsRE.test(user.text)) data = user.text.replace(tagsRE, '');
                else data = user.text;
                handlers.push({item_name: this.$t('copy'), handler: this.copy, data: data});
            }
            if(user && user.cid) handlers.push({item_name: this.$t('channel-comp.open-profile'), handler: this.openInfo, data: user.cid});
            pubId && pubId.data.forEach(item => { if(item.type === 'poll') pollId = item.pollId });
            if(pollId) voteResult = app.store.getters['chat/getPollVoteById'](pollId);
            if(voteResult) handlers.push({item_name: this.$t('cancel-vote'), handler: () => this.$refs.chat_poll_message.cancelPoll() });
            handlers.length && open(event, handlers);
        },
        copy(text) {
            this.$copyText(text);
        },
        checkReference(text) {
            if(text && text.search(/^@\[|\s@\[/) > -1) return true;
        },
    }

}
