'use strict'

import {
    GET_UID,
    GET_CHATS,
    RC_RADIO_IS_TURN_ON,
    GET_RC_RADIO_CONNECTION_STATUS,
    GET_RC_RADIO_GROUPS,
    GET_RC_RADIO_ACTIVE_GROUP,
    GET_RC_RADIO_PTT_PUSHED,
    GET_RC_RADIO_CONTACTS,
    GET_RC_RADIO_GRANT_CID,
    GET_RC_RADIO_GRANT_STATUS,
    GET_RC_RADIO_ECHO,
    GET_RC_RADIO_TOKEN,
    GET_USER_PARAMS,
} from '../gettersTypes'

import {
    ACT_RC_RADIO_CONNECT,
    ACT_RC_RADIO_DISCONNECT,
    ACT_RC_RADIO_GET_STATUS,
    ACT_RC_RADIO_REQUEST_VOICE,
    ACT_RC_RADIO_STOP_VOICE,
    ACT_RC_RADIO_SEND_VOICE,
    ACT_SET_RC_RADIO_CONNECTION_STATUS,
    ACT_RC_RADIO_SET_ECHO,
    ACT_RC_RADIO_EXIT_BTN,
    ACT_RC_RADIO_HANDLE_STATUS_EVENT,
    ACT_RC_RADIO_HANDLE_GRANT_EVENT,
    ACT_RC_RADIO_HANDLE_VOICE_EVENT,
    ACT_RC_RADIO_HANDLE_DISCONNECT_EVENT,
    ACT_RC_RADIO_CONNECT_STATE_TOGGLE,
    ACT_RC_RADIO_HANDLE_MESSAGE_EVENT,
    ACT_RC_RADIO_REQUEST_TOKEN, ACT_RC_RADIO_GET_PROTO,
} from '../actionsTypes'

import {
    MUT_RC_RADIO_CONNECTION_STATUS,
    MUT_RC_RADIO_SET_CHANNEL_ID,
    MUT_RC_RADIO_SET_CHANNEL_STATUS,
    MUT_RC_RADIO_SET_PTT_STATUS,
    MUT_RC_RADIO_SET_GRANT_ERROR,
    MUT_RC_RADIO_SET_CH_GRANT_CID,
    MUT_RC_RADIO_SET_CH_CID_STATUS,
    MUT_RC_RADIO_SET_TOKEN,
} from '../mutationsTypes'

import {
    USERDATA,
    CHATS, RC_RADIO,
} from '../modulesNames'

import event_bus from "../../eventBus"
import mainproto from '../../protocol'
let proto = null

import { SUPPORT_GROUP_CID } from '../../constants'
import RadioIOClient from '../../api/roschat/RadioIOClient'
import store from '../main/store'

export const CONNECTION_STATUS = {
    OFFLINE: 'offline',
    GROUP_NOT_SET: 'groupNotSet',
    CONNECTING: 'connecting',
    ONLINE: 'online',
    ERROR: 'error',
}

export const TOKEN_EXPIRED = 'token-expired'

export const SET_STATUS_ERROR = {
    PERMISSION: 'permission-denied',
}

export const GRAND_ERROR = {
    PERMISSION: 'permission-denied',
    BUSY: 'channel-is-busy',
}

export const SEND_VOICE_ERROR = {
    PERMISSION: 'permission-denied',
    BUSY: 'channel-is-busy',
}

export const GRANT_STATUSES = {
    NONE: 'none',
    REQUEST: 'request',
    GRANTED: 'granted',
    REQUEST_QUEUED: 'requestQueued',
    TO_ANOTHER_USER: 'toAnotherUser',
    ANOTHER_USER_INTERRUPTED: 'anotherUserInterrupted',
    NOT_GRANTED: 'notGranted',
}

export const GRAND_REASONS = {
    GSM_INTERRUPT: 'gsm-interrupt',
}

export const MESSAGE_TYPES = {
    REQUEST_VOICE: 'request-voice',
}

export const EVENTS = {
    GRAND:              'rc-radio-grand-event',
    SPEECH:             'rc-radio-speech',
    MESSAGE:            'rc-radio-message-event',
    CONTACT_CHANGED:    'rc-radio-contacts-event'
}

const state = {
    connectionStatus: CONNECTION_STATUS.OFFLINE,
    channelId: -1,
    channelStatus: {contacts: [], grantCid: -1, grantTime: 0},
    pttPushed: false,
    grantError: '',
    echo: 0,
    exitPushed: false,
    pttPopReason: '',
    token: '',
}

const getters = {
    getRadioServer: (state, getters, rootState, rootGetters) => {
        let server = rootGetters[`${USERDATA}/${GET_USER_PARAMS}`].radioServerV4 || ''
        if (server) server = `https://${server}`
        return server
    },
    [RC_RADIO_IS_TURN_ON]: (state) => {
        return !([CONNECTION_STATUS.OFFLINE, CONNECTION_STATUS.GROUP_NOT_SET].includes(state.connectionStatus))
    },
    [GET_RC_RADIO_CONNECTION_STATUS]: (state) => {
        return state.connectionStatus
    },
    [GET_RC_RADIO_GROUPS]: (state, getters, rootState, rootGetters) => rootGetters[`${CHATS}/${GET_CHATS}`],
    [GET_RC_RADIO_ACTIVE_GROUP]: (state) => {
        return state.channelId
    },
    [GET_RC_RADIO_PTT_PUSHED]: (state) => state.pttPushed,
    [GET_RC_RADIO_GRANT_CID]: (state) => state.channelStatus.grantCid,
    [GET_RC_RADIO_CONTACTS]: (state) => state.channelStatus.contacts.filter(({status}) => status === 'online'),
    [GET_RC_RADIO_GRANT_STATUS]: (state, getters, rootState, rootGetters) => {
        let status = GRANT_STATUSES.NONE
        let grantCid = getters[GET_RC_RADIO_GRANT_CID]
        let pttPushed = getters[GET_RC_RADIO_PTT_PUSHED]
        if (grantCid === -1) {
            if (pttPushed) {
                if (state.grantError) {
                    if (state.grantError === GRAND_ERROR.BUSY) status = GRANT_STATUSES.REQUEST_QUEUED
                    else status = GRANT_STATUSES.NOT_GRANTED
                }
                else status = GRANT_STATUSES.REQUEST
            } else if (state.pttPopReason) return GRANT_STATUSES.ANOTHER_USER_INTERRUPTED
            return status
        }
        if (grantCid === rootGetters[`${USERDATA}/${GET_UID}`]) status = GRANT_STATUSES.GRANTED
        else if (pttPushed) status = GRANT_STATUSES.REQUEST_QUEUED
        else status = GRANT_STATUSES.TO_ANOTHER_USER
        return status
    },
    [GET_RC_RADIO_ECHO]: (state) => {
        return state.echo
    },
    [GET_RC_RADIO_TOKEN]: (state) => {
        return state.token
    },
}

const actions = {
    [ACT_RC_RADIO_CONNECT]: async ({state, dispatch, commit, getters}, payload = {}) => {
        const { channelId } = payload
        try {
            const isConnected = proto && proto.isConnected
            if (!isConnected) {
                proto = await dispatch(ACT_RC_RADIO_GET_PROTO)
                const radioServer = getters['getRadioServer']
                await proto.connect(radioServer)
            }
            if (!state.token || channelId !== state.channelId) {
                await dispatch(ACT_RC_RADIO_REQUEST_TOKEN, { channelId })
            }
            if (!!channelId || channelId === SUPPORT_GROUP_CID) {
                dispatch(ACT_SET_RC_RADIO_CONNECTION_STATUS, CONNECTION_STATUS.CONNECTING)
                const token = state.token
                await proto.radioConnect({...payload, token})
                commit(MUT_RC_RADIO_SET_CHANNEL_ID, payload.channelId)
                const radioStatus = await dispatch(ACT_RC_RADIO_GET_STATUS, payload)
                commit(MUT_RC_RADIO_SET_CHANNEL_STATUS, radioStatus)
                dispatch(ACT_SET_RC_RADIO_CONNECTION_STATUS, CONNECTION_STATUS.ONLINE)
            } else {
                dispatch(ACT_SET_RC_RADIO_CONNECTION_STATUS, CONNECTION_STATUS.GROUP_NOT_SET)
            }
        } catch (e) {
            if (e === TOKEN_EXPIRED) {
                await dispatch(ACT_RC_RADIO_REQUEST_TOKEN, { channelId })
                dispatch(ACT_RC_RADIO_CONNECT, payload)
            }
            else dispatch(ACT_SET_RC_RADIO_CONNECTION_STATUS, CONNECTION_STATUS.ERROR)
        }
    },
    [ACT_RC_RADIO_GET_PROTO]: async ({dispatch}) => {
        if (proto) return proto

        proto = new RadioIOClient()

        proto.on('radio-status-event', (payload) => {
            dispatch(ACT_RC_RADIO_HANDLE_STATUS_EVENT, payload)
        })

        proto.on('radio-leave-event', (payload) => {
            dispatch(ACT_RC_RADIO_HANDLE_STATUS_EVENT, payload)
        })

        proto.on('radio-grant-voice-event', (payload) => {
            dispatch(ACT_RC_RADIO_HANDLE_GRANT_EVENT, payload)
        })

        proto.on('radio-stop-voice-event', (payload) => {
            dispatch(ACT_RC_RADIO_HANDLE_GRANT_EVENT, payload)
        })

        proto.on('radio-voice-event', (payload) => {
            dispatch(ACT_RC_RADIO_HANDLE_VOICE_EVENT, payload)
        })

        proto.on('radio-disconnected-event', (payload) => {
            dispatch(ACT_RC_RADIO_HANDLE_DISCONNECT_EVENT, payload)
        })

        proto.on('radio-message-event', (payload) => {
            dispatch(ACT_RC_RADIO_HANDLE_MESSAGE_EVENT, payload)
        })

        return proto
    },
    [ACT_RC_RADIO_DISCONNECT]: async ({state, dispatch}) => {
        try {
            if (state.channelId) await proto.radioDisconnect({channelId: state.channelId})
            await proto.disconnect()
        } catch (e) {}
        dispatch(ACT_SET_RC_RADIO_CONNECTION_STATUS)
    },
    [ACT_RC_RADIO_CONNECT_STATE_TOGGLE]: async ({state, dispatch, commit, getters}, payload) => {
        if (getters[RC_RADIO_IS_TURN_ON]) dispatch(ACT_RC_RADIO_DISCONNECT)
        else dispatch(ACT_RC_RADIO_CONNECT, payload)
    },
    [ACT_RC_RADIO_GET_STATUS]: async ({state, dispatch, commit}, payload = {}) => {
        let { channelId } = payload
        if (!channelId) channelId = state.channelId
        return proto.radioGetStatus({channelId})
    },
    [ACT_RC_RADIO_REQUEST_VOICE]: async ({state, commit, rootGetters}, payload) => {
        try {
            commit(MUT_RC_RADIO_SET_GRANT_ERROR)
            commit(MUT_RC_RADIO_SET_PTT_STATUS, true)
            await proto.radioRequestVoice({channelId: state.channelId})
            commit(MUT_RC_RADIO_SET_CH_GRANT_CID, {cid: rootGetters[`${USERDATA}/${GET_UID}`]})
        } catch (e) {
            commit(MUT_RC_RADIO_SET_GRANT_ERROR, e)
        }
    },
    [ACT_RC_RADIO_STOP_VOICE]: async ({state, commit}, payload) => {
        try {
            commit(MUT_RC_RADIO_SET_GRANT_ERROR)
            commit(MUT_RC_RADIO_SET_PTT_STATUS, false)
            await proto.radioStopVoice({channelId: state.channelId})
            commit(MUT_RC_RADIO_SET_CH_GRANT_CID, {})
        } catch (e) {}
    },
    [ACT_RC_RADIO_SEND_VOICE]: ({state, dispatch, commit}, payload) => {
        proto.radioSendVoice({...payload, ...{channelId: state.channelId}})
    },
    [ACT_SET_RC_RADIO_CONNECTION_STATUS]: ({state, dispatch, commit}, status = CONNECTION_STATUS.OFFLINE) => {
        commit(MUT_RC_RADIO_CONNECTION_STATUS, status)
        if (status === CONNECTION_STATUS.OFFLINE) {
            commit(MUT_RC_RADIO_SET_PTT_STATUS)
            commit(MUT_RC_RADIO_SET_GRANT_ERROR)
            commit(MUT_RC_RADIO_SET_CHANNEL_STATUS, {})
        }
    },
    [ACT_RC_RADIO_SET_ECHO]: ({state}) => {
        state.echo = Math.random()
    },
    [ACT_RC_RADIO_EXIT_BTN]: ({commit}) => {
        commit(MUT_RC_RADIO_SET_CH_GRANT_CID, {})
    },
    [ACT_RC_RADIO_HANDLE_STATUS_EVENT]: ({state, commit}, {channelId, cid, status}) => {
        if (channelId !== state.channelId) return
        commit(MUT_RC_RADIO_SET_CH_CID_STATUS, {cid, status})
    },
    [ACT_RC_RADIO_HANDLE_GRANT_EVENT]: ({state, commit, getters}, { channelId, cid, reason }) => {
        if (channelId !== state.channelId) return
        commit(MUT_RC_RADIO_SET_GRANT_ERROR)
        if (cid >= 0) {
            commit(MUT_RC_RADIO_SET_CH_GRANT_CID, {cid})
            event_bus.$emit(EVENTS.GRAND, {grand: true, cid, reason})
        } else {
            cid = getters[GET_RC_RADIO_GRANT_CID]
            commit(MUT_RC_RADIO_SET_CH_GRANT_CID, {reason})
            event_bus.$emit(EVENTS.GRAND, {grand: false, cid, reason})
        }
    },
    [ACT_RC_RADIO_HANDLE_VOICE_EVENT]: ({state, commit, getters}, {channelId, audioData: speech}) => {
        if (channelId !== state.channelId) return
        if (channelId === SUPPORT_GROUP_CID || [GRANT_STATUSES.TO_ANOTHER_USER, GRANT_STATUSES.REQUEST_QUEUED].includes(getters[GET_RC_RADIO_GRANT_STATUS]))
            event_bus.$emit(EVENTS.SPEECH, {speech})
    },
    [ACT_RC_RADIO_HANDLE_DISCONNECT_EVENT]: ({dispatch}, {reason}) => {
        dispatch(ACT_RC_RADIO_DISCONNECT)
    },
    [ACT_RC_RADIO_HANDLE_MESSAGE_EVENT]: ({dispatch}, payload) => {
        event_bus.$emit(EVENTS.MESSAGE, payload)
    },
    [ACT_RC_RADIO_REQUEST_TOKEN]: async ({commit}, {channelId}) => {
        try {
            const replyObj = await mainproto.radioGetToken({type: 'roschat', params: {channelId}})
            const isToken = replyObj.hasOwnProperty('token')
            const token = isToken ? replyObj.token : ''
            commit(MUT_RC_RADIO_SET_TOKEN, token)
        } catch(error) {
            console.log("!! [ACT_RC_RADIO_REQUEST_TOKEN]: -> error", error)

        }
    },
}

const mutations = {
    [MUT_RC_RADIO_CONNECTION_STATUS]: (state, status = CONNECTION_STATUS.OFFLINE) => {
        state.connectionStatus = status
    },
    [MUT_RC_RADIO_SET_CHANNEL_ID]: (state, channelId = 0) => {
        state.channelId = channelId
    },
    [MUT_RC_RADIO_SET_CHANNEL_STATUS]: (state, {contacts = [], grantCid = -1, grantTime = 0}) => {
        Object.assign(state.channelStatus, {contacts, grantCid, grantTime})
    },
    [MUT_RC_RADIO_SET_PTT_STATUS]: (state, status = false) => {
        state.pttPushed = status
    },
    [MUT_RC_RADIO_SET_GRANT_ERROR]: (state, error = '') => {
        state.grantError = error
    },
    [MUT_RC_RADIO_SET_CH_GRANT_CID]: (state, {cid = -1, reason}) => {
        state.channelStatus.grantCid = cid
        state.pttPopReason = reason
    },
    [MUT_RC_RADIO_SET_CH_CID_STATUS]: (state, {cid, status}) => {
        state.exitPushed = false
        let oldStatus = state.channelStatus.contacts.find(({cid: itemCid}) => +cid === +itemCid)
        if (oldStatus) {
            if (status) oldStatus.status = status
            else state.channelStatus.contacts.splice(state.channelStatus.contacts.indexOf(oldStatus), 1)
            event_bus.$emit(EVENTS.CONTACT_CHANGED, {type: 'del', cid})
        } else {
            state.channelStatus.contacts.push({cid, status})
            event_bus.$emit(EVENTS.CONTACT_CHANGED, {type: 'new', cid})
        }
    },
    [MUT_RC_RADIO_SET_TOKEN]: (state, token) => {
        state.token = token
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
