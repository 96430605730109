//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import modal_mixin from "./modal-mixin"
import ListItem from '../custom/ListItem.vue'
import {mapGetters, mapActions} from 'vuex'
import {TETRA} from '../../store/modulesNames'
import {
    GET_TETRA_ACTIVE_GROUP,
    GET_TETRA_GROUPS,
} from '../../store/gettersTypes'
import {
    ACT_TETRA_ACTIVATE_GROUP,
} from '../../store/actionsTypes'

import {COFU} from '../../store/modules/tetra-radio'

export default {
    name: "TetraRadioGroupSelect",
    components: {ListItem},
    mixins: [modal_mixin],
    data() {
        return {
            title: this.$t('group-selecion'),
            emptyText: this.$t('no-groups'),
            compModalProps: {
                delay: 200,
                pivotY: 0.05,
                width: 450,
                height: 'auto'
            }
        }
    },
    computed: {
        groupsList() {
            return Object.entries(this[GET_TETRA_GROUPS]).map(([key, {name, cofu}]) => {
                return {
                    gssi: key,
                    titleText: name,
                    footerText: `ssi ${key} (${this.getCofuText(cofu)})`
                }
            })
        },
        ...mapGetters(TETRA, {active: GET_TETRA_ACTIVE_GROUP, [GET_TETRA_GROUPS]: GET_TETRA_GROUPS})
    },
    methods: {
        getCofuText(cofu) {
            let text = ''
            switch (cofu) {
                case COFU.NON_SCANNED_SELECTABLE:
                    text = this.$t('selectable')
                    break
                case COFU.SCANNED_SELECTABLE:
                    text = this.$t('scan&select')
                    break
                case COFU.SCANNED_NON_SELECTABLE:
                    text = this.$t('scannable')
                    break
            }
            return text
        },
        setGroupActive(group) {
            this[ACT_TETRA_ACTIVATE_GROUP]({group})
            this.modalClose()
        },
        isGroupActive(group) {
            return this.active && this.active.gssi === +group.gssi
        },
        ...mapActions(TETRA, [ACT_TETRA_ACTIVATE_GROUP])
    }
}
