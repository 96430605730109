
import { mapActions, mapGetters } from "vuex"
import { PHONE } from "../../store/modulesNames"
import { GET_PHONE_SIP_PAIR } from "../../store/gettersTypes"
import {
    ACT_PHONE_RTC_CALL_CONFERENCE,
    ACT_PHONE_RTC_CALL_TRANSIT
} from "../../store/actionsTypes"

import WebrtcPhoneMixin from "./WebrtcPhoneMixin"
import PhoneAudio from "./PhoneAudio.vue"
import {PHONE_BTN_TYPES} from "./PhoneFunctionButton.vue"

export default {
    name: "PhoneWebrtcAudio",
    extends: PhoneAudio,
    mixins: [WebrtcPhoneMixin],
    computed: {
        // *** Переопределение start *** //
        btns() {
            let btns = []
            if (this.busy) {
                btns = [PHONE_BTN_TYPES.NOTIFY, PHONE_BTN_TYPES.CONTINUE]
            } else {
                btns = [PHONE_BTN_TYPES.PAUSE, PHONE_BTN_TYPES.MICROPHONE, PHONE_BTN_TYPES.MSG]
                if (this.number) {
                    if (this.isConf) btns.unshift(PHONE_BTN_TYPES.PARTICIPANTS)
                    else btns.unshift(PHONE_BTN_TYPES.BUTTONS)
                    btns = [PHONE_BTN_TYPES.CONFERENCE, PHONE_BTN_TYPES.TRANSIT, ...btns]
                }
            }
            return btns
        },
        typeCallText() {
            if (!this.number) {
                return this.$t('mainPage.voice-call')
            } else {
                return this.numberText
            }
        },
        // *** Переопределение stop *** //
        sipPair() {
            return this[GET_PHONE_SIP_PAIR]
        },
        ...mapGetters(PHONE, [GET_PHONE_SIP_PAIR])
    },
    methods: {
        // *** Переопределение start *** //
        isFuncBtnActive(btn) {
            switch (btn) {
                case PHONE_BTN_TYPES.PAUSE:
                    return this.pause
                case PHONE_BTN_TYPES.MICROPHONE:
                    return this.mute
            }
        },
        isFuncBtnDisabled(btn) {
            switch (btn) {
                case PHONE_BTN_TYPES.CONFERENCE:
                case PHONE_BTN_TYPES.TRANSIT:
                    return this.btnsDisabled || !this.number || !this.sipPair
                case PHONE_BTN_TYPES.BUTTONS:
                    return this.btnsDisabled || !this.number
                case PHONE_BTN_TYPES.PAUSE:
                case PHONE_BTN_TYPES.MICROPHONE:
                    return this.btnsDisabled
                case PHONE_BTN_TYPES.MSG:
                    return !this.contact || !this.contact.cid || this.isConf
                case PHONE_BTN_TYPES.NOTIFY:
                case PHONE_BTN_TYPES.CONTINUE:
                    return false
            }
        },
        doTransit() {
            this[ACT_PHONE_RTC_CALL_TRANSIT]()
        },
        doConference() {
            this[ACT_PHONE_RTC_CALL_CONFERENCE]()
        },
        // *** Переопределение stop *** //
        ...mapActions(PHONE, [
            ACT_PHONE_RTC_CALL_TRANSIT,
            ACT_PHONE_RTC_CALL_CONFERENCE,
        ])
    }

}
