//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import modal_mixin from '../modal/modal-mixin'
import ModalBtn from '../modal/ModalBtn.vue'
import confSettingsBase from './confSettingsBase.vue'
import EditConf from './editConference.vue'
import AddConfMembers from '../modal/AddConfMembers.vue'
import EnterConf from "./enterConference.vue"
import {CHATS, VIDEO_CONF_NEW, VM_CONF} from '../../store/modulesNames'
import {
    ACT_SEND_MESSAGE,
    ACT_VC_CONF_DELETE,
    ACT_VM_GET_CONFERENCE_INFO,
} from '../../store/actionsTypes'

import {mapGetters} from 'vuex'
import {USERDATA, CONTACTS} from "../../store/modulesNames"
import {
    GENERATE_VC_CONF_PROPS,
    GET_MERGED_CONTACT_BY_ID,
    GET_UID,
    GET_VM_CONF_CONNECTION_PARAMS,
} from '../../store/gettersTypes'
import moment from 'moment'
import {CONF_SCHEDULE_PROPS} from '../../store/modules/video-conf-abstract'
import {CONF_TYPES} from '../../constants'

export default {
    name: "vcInfo",
    components: { confSettingsBase, ModalBtn },
    mixins: [modal_mixin],
    props:  {
        conf: {
            type: Object,
            required: true,
            default: {}
        }
    },
    data() {
        return {
            mainTitle: this.$t('videomost.menu-title'),
            compModalProps: {
                delay: 100,
                pivotY: 0.44,
                width: 464,
                height: 'auto',
                clickToClose: false,
                adaptive: true,
                styles: "min-width: 387px; overflow-x: auto;"
            },
            startConfText: this.$t('videomost.conf-run'),
            editConfText: this.$t('videomost.conf-edit'),
            confSettingsText: this.$t('videomost.conf-settings'),
            placeholder: this.$t('videomost.conf-enterway'),
            chosenEnterWay: null,
            readonly: true,
            fieldsToShow: [
                CONF_SCHEDULE_PROPS.TYPE,
                CONF_SCHEDULE_PROPS.ID,
                CONF_SCHEDULE_PROPS.TOPIC,
                CONF_SCHEDULE_PROPS.PASSWORD,
                CONF_SCHEDULE_PROPS.START_TIME,
                CONF_SCHEDULE_PROPS.END_TIME,
            ]
        }
    },
    computed: {
        ...mapGetters(CONTACTS, {[GET_MERGED_CONTACT_BY_ID]: GET_MERGED_CONTACT_BY_ID}),
        ...mapGetters(USERDATA, {[GET_UID]: GET_UID}),
        ...mapGetters(VM_CONF, [GET_VM_CONF_CONNECTION_PARAMS]),
        ...mapGetters(VIDEO_CONF_NEW, [GENERATE_VC_CONF_PROPS]),
        contact() {
            return this[GET_MERGED_CONTACT_BY_ID](this[GET_UID])
        },
        fio() {
            return this.contact.fio
        },
    },
    methods: {
        closeModal() {
            this.modalClose()
        },
        startConf() {
            let conf = this.conf
            let vcLink = this.getConfLink(conf)
            this.modalOpen({ component: EnterConf, props: { type: this.conf[CONF_SCHEDULE_PROPS.TYPE], vcLink } })
            this.modalClose()
        },
        getConfLink(conf) {
            let payload = {
                confId: conf[CONF_SCHEDULE_PROPS.ID],
                pass: conf[CONF_SCHEDULE_PROPS.PASSWORD],
                server: conf.server,
                link: conf.link
            }

            let { generatedLink } = this[GENERATE_VC_CONF_PROPS](this.conf[CONF_SCHEDULE_PROPS.TYPE], payload)
            return generatedLink
        },
        async openEditConf(conf) {
            this.modalClose()
            let propConf = { ...conf }

            if (!this.isNew && propConf[CONF_SCHEDULE_PROPS.ID] && propConf[CONF_SCHEDULE_PROPS.TYPE] === CONF_TYPES.VM) {
                propConf = await this[ACT_VM_GET_CONFERENCE_INFO]({confid: propConf[CONF_SCHEDULE_PROPS.ID]})
            }
            this.modalOpen({
                component: EditConf,
                props: { conf: propConf }
            })
        },
        openInviteContextMenu(conf) {
            let handlers = []
            handlers.push({
                item_name: this.$t('videomost.conf-members-choose'),
                handler: () => {
                    this.modalOpen({
                        component: AddConfMembers,
                        props: {
                            msg: {dataType: 'text', data: this.makeInvitationMessage(conf)}
                        }
                    })
                }
            })
            handlers.push({
                item_name: this.$t('videomost.conf-members-sendinvitemail'),
                handler: () => { this.sendInvitationMail(conf) }
            })
            handlers.push({
                item_name: this.$t('videomost.conf-members-copyconflink'),
                handler: () => {
                    utils.copyTextToClipboard(null, this.getConfLink(conf))
                }
            })
            this.cmOpen(event, handlers)
        },
        sendInvitationMail(conf) {
            const mailSubject = this.$t('videomost.conf-invitemail-subject')
            const mailBody = encodeURIComponent(this.makeInvitationMessage(conf))
            let mailRef = `mailto:?subject=${mailSubject}&body=${mailBody}`
            window.location.href = mailRef
        },
        sendMessage(contact, message) {
            if (!message) return
            let msg = {
                cid: contact.cid,
                cidType: contact.cidType,
                dataType: declarations.msgDataTypes.MSG_DATA_TYPE_TEXT,
                data: message
            }
            this[ACT_SEND_MESSAGE](msg)
        },
        makeInvitationMessage(conf) {
            let vcLink = this.getConfLink(conf)
            let startConfTime =  moment.unix(conf[CONF_SCHEDULE_PROPS.START_TIME]).local().format('DD.MM.YY HH:mm')
            return `${this.fio} ${this.$t('videomost.conf-invitemail-bodyfirstrow')}

${this.$t('videomost.conf-invitemail-bodyconfsubj')}: ${conf[CONF_SCHEDULE_PROPS.TOPIC]}
${this.$t('videomost.conf-invitemail-bodyconftime')}: ${startConfTime}

${this.$t('videomost.conf-invitemail-bodyconflink')}
${vcLink}

${this.$t('videomost.conf-invitemail-bodyconfid')}: ${conf[CONF_SCHEDULE_PROPS.ID]}
${this.$t('videomost.conf-password')}: ${conf[CONF_SCHEDULE_PROPS.PASSWORD]}`
        },
        deleteConfConfirm() {
            const confType = this.conf[CONF_SCHEDULE_PROPS.TYPE]
            let closeUp = async () => {
                await this.deleteConf()
                this.modalClose()
                this.$bus.$emit('reload-conf-lists')
            }

            this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('videomost.conf-delete-confirm'),
                    btnOk: {
                        cb: () => closeUp()
                    }
                }
            })

        },
        async deleteConf() {
            return await this[ACT_VC_CONF_DELETE](this.conf)
        },
        ...mapActions(VIDEO_CONF_NEW, [ACT_VC_CONF_DELETE]),
        ...mapActions(VM_CONF, [ACT_VM_GET_CONFERENCE_INFO]),
        ...mapActions(CHATS, [ACT_SEND_MESSAGE])
    },
}
