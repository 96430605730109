//
//
//
//
//
//
//
//

export default {
    name: "ActionsButton",
    props: {
        btnText: {
            type: String,
            required: false,
            default: ''
        },
        btnClass: {
            type: String,
            required: false,
            default: ''
        }
    },
    methods: {
        click() {
            this.$emit('btnClicked');
        }
    }
    
}
