//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';
import {MODAL} from "../../store/modulesNames";
import {GET_MODALS} from "../../store/gettersTypes";

import Alert from './Alert.vue';
import Confirm from './Confirm.vue';
import Info from './Info.vue';
import Location from './Location.vue';

export default {
    name: 'ModalContainer',
    components: {
        Alert,
        Confirm,
        Info,
        Location
    },
    computed: {
        ...mapGetters(MODAL, {modals: GET_MODALS})
    },
}

