import { render, staticRenderFns } from "./enterConferenceBody.vue?vue&type=template&id=48cf60d2&scoped=true&lang=pug&"
import script from "./enterConferenceBody.vue?vue&type=script&lang=js&"
export * from "./enterConferenceBody.vue?vue&type=script&lang=js&"
import style0 from "./enterConferenceBody.vue?vue&type=style&index=0&id=48cf60d2&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48cf60d2",
  null
  
)

export default component.exports