export default {
    emitters: {
        rtcCall(cid, options, number, sdp, candidates) {
            return new Promise((resolve, reject) => {
                if (!!options.video) options.video = true
                let data = {options, sdp, candidates}
                if (typeof cid === 'number') data.cid = cid
                if (number) data.number = number
                this.log('rtcCall: > ' + JSON.stringify(data))
                this.socket.emit('rtc-call', data, (data) => {
                    this.log('rtcCall: < ' + JSON.stringify(data))
                    resolve(data)
                })
            })
        },
        rtcCallRinging(callId) {
            return new Promise((resolve, reject) => {
                let data = {callId: callId}
                this.log('rtcCallRinging: > ' + JSON.stringify(data))
                this.socket.emit('rtc-call-ringing', data)
                resolve()
            })
        },
        rtcCallAnswer(callId, options, sdp, candidates) {
            return new Promise((resolve, reject) => {
                let data = {callId: callId, options: options, sdp: sdp, candidates: candidates}
                this.log('rtcCallAnswer: > ' + JSON.stringify(data))
                this.socket.emit('rtc-call-answer', data)
                resolve()
            })
        },
        rtcCallTermination(data) {
            this.log('rtcCallTermination: > ' + JSON.stringify(data))
            this.socket.emit('rtc-call-termination', data)
        },
        rtcCallProcessing(callId, msg, data_) {
            let data = { callId, msg, data: data_ }
            this.log('rtcCallProcessing: > ' + JSON.stringify(data))
            this.socket.emit('rtc-call-processing', data)
        },
        rtcCallHold(callId, options) {
            return new Promise((resolve, reject) => {
                let data = {callId: callId, options: options}
                this.log('rtcCallHold: > ' + JSON.stringify(data))
                this.socket.emit('rtc-call-options', data)
                resolve()
            })
        },
        rtcCallTransfer(callId, transferId) {
            let data = { callId, transferId }
            this.log('rtcCallTransfer: > ' + JSON.stringify(data))
            this.socket.emit('rtc-call-transfer', data)
        },
        rtcCallConference(callId, confId) {
            let data = { calls: [callId, confId] }
            this.log('rtcCallConference: > ' + JSON.stringify(data))
            this.socket.emit('rtc-call-conference', data)
        },
        rtcCallDtmf(callId, digits) {
            return new Promise((resolve, reject) => {
                let data = {callId, digits}
                this.log('rtcCallDtmf: > ' + JSON.stringify(data))
                this.socket.emit('rtc-call-dtmf', data)
                resolve()
            })
        },
        rtcSynchronizeCalls(callId, digits) {
            let data = { callId, digits }
            this.log('rtcSynchronizeCalls: > ' + JSON.stringify(data))
            this.socket.emit('rtc-synchronize-calls', data, data => {

            })
        },
        rtcCallAvailability({cid, number}) {
            return new Promise((resolve, reject) => {
                let data = {}
                if (number) data.number = number
                else data.cid = cid
                this.log('rtcCallAvailability: > ' + JSON.stringify(data))
                this.socket.emit('rtc-call-availability', data, (result) => {
                    this.log('rtcCallAvailability: < ' + JSON.stringify(result))
                    resolve(result && result.available)
                })
            })
        },
        rtcCallSubscribeAvailability(data) {
            return new Promise((resolve, reject) => {
                this.log('rtcCallSubscribeAvailability: > ' + JSON.stringify(data))
                this.socket.emit('rtc-call-subscribe-availability', data, result => {
                    this.log('rtcCallSubscribeAvailability: < ' + JSON.stringify(result))
                    if (result.error) return reject(result.error)
                    resolve(result && result.available)
                })
            })
        },
        rtcCallConferenceList(data) {
            return new Promise((resolve, reject) => {
                this.log('rtcCallConferenceList: > ' + JSON.stringify(data))
                this.socket.emit('rtc-call-conference-list', data, result => {
                    this.log('rtcCallConferenceList: < ' + JSON.stringify(result))
                    resolve(result || [])
                })
            })
        },
        sendCallsStats(data) {
            return new Promise((resolve, reject) => {
                this.log('sendCallsStats: > ' + JSON.stringify(data))
                this.socket.emit('report-status', data, result => {})
            })
        }
    },
    listeners: {
        'rtc-call-event'(data) {
            this.log('bind: < rtc-call-event: ' + JSON.stringify(data))
            this.emit('calls-event', {type: 'rtc-call-event', data})
        },
        'rtc-call-ringing-event'(data) {
            this.log('bind: < rtc-call-ringing-event: ' + JSON.stringify(data))
            this.emit('rtc-call-ringing-event', data)
        },
        'rtc-call-answer-event'(data) {
            this.log('bind: < rtc-call-answer-event: ' + JSON.stringify(data))
            this.emit('rtc-call-answer-event', data)
        },
        'rtc-call-termination-event'(data) {
            this.log('bind: < rtc-call-termination-event: ' + JSON.stringify(data))
            // @todo тут был таймаут, мешал схлоповать вызовы для конфы и транзита
            // @todo по-хорошему интервал тоже убрать, можно иными способами узнать о завершении вызова
            // таймаут добавлен по причине описаной в задаче №1242
            // проблема возникает когда евент call-termination приходит быстрей чем создастся
            // окно телефона и класс callWorker.
            this.emit('rtc-call-termination-event', data)
            let attempt = 0
            let interval = setInterval(() => {
                this.emit('rtc-call-termination-event', data)
                if (++attempt > 3) clearInterval(interval)
            }, 1000)
        },
        'rtc-call-processing-event'(data) {
            this.log('bind: < rtc-call-processing-event: ' + JSON.stringify(data))
            this.emit('rtc-call-processing-event', data)
        },
        'rtc-call-options-event'(data) {
            this.log('bind: < rtc-call-options-event: ' + JSON.stringify(data))
            this.emit('rtc-call-options-event', data)
        },
        'rtc-call-conference-event'(data) {
            this.log('bind: < rtc-call-conference-event: ' + JSON.stringify(data))
            this.emit('rtc-call-conference-event', data)
        },
    },
}