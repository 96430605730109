//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '../../../ext/i18n';
import data from "./modApple.json";
import { Picker, Emoji, EmojiIndex, store, frequently } from "emoji-mart-vue-fast";

const lsPrefix = 'rcemoji.' 
let lastRecent = {}
const recentLength = 7
let lastEmo = ''

store.setHandlers({
	getter: (key) => {
        let value = JSON.parse(localStorage.getItem(lsPrefix + key))
        if (!value && key === 'frequently') value = {} 
        if (!value && key === 'last') value = "" 
		return value
	},
	setter: (key, value) => {
        if (key === 'last') { 
            lastEmo = value
            localStorage.setItem(lsPrefix + key, JSON.stringify(value))
        }
        if (key === 'frequently') {
            lastRecent = value
            if (Object.keys(lastRecent).length < Object.keys(value).length)
                typeof lastRecent[lastEmo] === 'undefined' ? lastRecent[lastEmo] = 1 : lastRecent[lastEmo]++
            // else lastRecent = value
            localStorage.setItem(lsPrefix + key, JSON.stringify(lastRecent))
        }
	}
})

frequently.add = function(emoji) {
  let frequently = store.get('frequently')
  let { id } = emoji

  frequently[id] || (frequently[id] = 0)
  frequently[id] += 1

  store.set('last', id)
  store.set('frequently', frequently)
}

export default {
    components: {
        Picker,
    },
    props: {
        isEmojiPickerVisible: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            recent: [],
            i18n_en: {
                search: 'Search',
                notfound: 'No Emoji Found',
                categories: {
                    search: 'Search Results',
                    recent: 'Frequently Used',
                    smileys: 'Smileys & Emoticon',
                    people: 'People & Body',
                    nature: 'Animals & Nature',
                    foods: 'Food & Drink',
                    activity: 'Activity',
                    places: 'Travel & Places',
                    objects: 'Objects',
                    symbols: 'Symbols',
                    flags: 'Flags',
                    custom: 'Custom',
                }
            },
            i18n_ru: {
                search: "Поиск",
                notfound: "Не найдено",
                categories: {
                    search: "Результат поиска",
                    recent: "Недавние",
                    smileys: "Смайлики",
                    people: "Люди",
                    nature: "Природа",
                    foods: "Еда и напитки",
                    activity: "Спорт",
                    places: "Места",
                    objects: "Объекты",
                    symbols: "Символы",
                    flags: "Флаги",
                    custom: "Кастомные",
                },
            },
            style: {
                position: `absolute`,
                bottom: 0,
                right: 0,
                'max-height': `358px`
            },
        }
    },
    // created() {
    //     this.updateRecent()
    // },
    watch: {
        isEmojiPickerVisible(val) {
            this.updateRecent()
            if (val && !this.recent.length) this.style['max-height'] = '368px'
            else this.style['max-height'] = '354px'
        }
    },
    computed: {
        index() {
            return new EmojiIndex(data, { recent: this.recent })
        },
        translation() {
            switch (i18n.locale) {
                case 'ru':
                    return this.i18n_ru;
                    break;
                case 'en':
                    return this.i18n_en;
                    break;
                default:
                    return this.i18n_en;
                    break;
            }
        }
    },
    methods: {
        addEmoji(emoji) {
            this.$emit('add-emoji', emoji)
            this.updateRecent()
        },
        updateRecent() {
            const recentEmoObj = JSON.parse(localStorage.getItem('rcemoji.frequently'))
            if (recentEmoObj) {
                let recentEmo = Object.keys(recentEmoObj).sort((a, b) => recentEmoObj[b] - recentEmoObj[a])
                this.recent = recentEmo.slice(0, recentLength)
            } 
        },
    },
}
