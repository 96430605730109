var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bot-info" },
    [
      _c("info-main-block", {
        attrs: {
          "icon-photo": _vm.chat.photo,
          "icon-text": _vm.chat.name,
          "common-text": _vm.chat.name,
          "additional-text": _vm.$t("mainPage.sys-msgs"),
          official: true,
          "icon-viewer": true,
          "icon-type": "chat",
          "icon-id": "system"
        }
      }),
      !_vm.canBack
        ? _c("chat-mute-switch", {
            attrs: { cid: _vm.cid, cidType: _vm.cidType }
          })
        : _vm._e(),
      !_vm.canBack && _vm.inChatList
        ? _c(
            "actions-container",
            { staticClass: "delete-chat" },
            [
              _c(
                "list-item",
                {
                  staticClass: "color-red",
                  attrs: {
                    slot: "action",
                    title: _vm.$t("mainPage.delete-chat"),
                    link: true,
                    chevron: false
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.doDeleteChat($event)
                    }
                  },
                  slot: "action"
                },
                [
                  _c(
                    "info-icon",
                    {
                      attrs: {
                        slot: "media",
                        "icon-color": "inherit",
                        "position-left": true
                      },
                      slot: "media"
                    },
                    [
                      _c("i", {
                        staticClass: "fal fa-trash-alt",
                        attrs: { slot: "default" },
                        slot: "default"
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }