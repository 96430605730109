import { i18n } from './ext/i18n'
import moment from 'moment'
let locale = i18n.messages[i18n.locale]
export default class DateHelper {
    constructor() {
        moment.locale(locale.moment);
        /**
         * Константы форматов времени для всего проекта
         */
        this.DATE_FORMAT = "DD MMM YYYY";
        this.TIME_FORMAT = "HH:mm";
        this.TIME_FORMAT_FULL = "HH:mm:ss";
        this.DATE_TIME_FORMAT = this.DATE_FORMAT + " " + this.TIME_FORMAT;
        this.DATE_TIME_FORMAT_FULL = this.DATE_FORMAT + " " + this.TIME_FORMAT_FULL;
    }

    /**
     * Получить текущее unix Timestamp
     * @returns {*}
     */
    getCurrentUnixTime() {
        return moment().unix();
    };

    /**
     * Преобразовать unix Timestamp в формат пользовательский формат даты-времени
     * @param {number} unix_time unix Timestamp
     * @param {string} format формат даты-времени
     * @returns {string}
     */
    unixTimeToUserFormat(unix_time, format) {
        return moment.unix(unix_time).format(format);
    };
    
    /**
     * Преобразовать unix Timestamp в формат даты-времени проекта
     * @param {number} unix_time unix Timestamp
     * @returns {string}
     */
    unixTimeToDateTimeFormat(unix_time) {
        return moment.unix(unix_time).format(this.DATE_TIME_FORMAT);
    };

    /**
     * Преобразовать unix Timestamp в полный формат даты-времени проекта
     * @param {number} unix_time unix Timestamp
     * @returns {string}
     */
    unixTimeToDateTimeFormatFull(unix_time) {
        return moment.unix(unix_time).format(this.DATE_TIME_FORMAT_FULL);
    };

    /**
     * Преобразовать unix Timestamp в время проекта
     * @param {number} unix_time unix Timestamp
     * @returns {string}
     */
    unixTimeToTimeFormat(unix_time) {
        return moment.unix(unix_time).format(this.TIME_FORMAT);
    };

    /**
     * Преобразовать unix Timestamp в дату проекта
     * @param {number} unix_time unix Timestamp
     * @returns {string}
     */
    unixTimeToDateFormat(unix_time) {
        return moment.unix(unix_time).format(this.DATE_FORMAT);
    };

    /**
     * Преобразовать unix Timestamp в дату проекта
     * @param {number} unix_time unix Timestamp
     * @returns {string}
     */
    unixTimeToTimeFormatFull(unix_time) {
        return moment.unix(unix_time).format(this.TIME_FORMAT_FULL);
    };

    /**
     * Преобразовать дату-время в формате проекта в unix Timestamp
     * @param {string} date
     * @returns {number}
     */
    dateTimeFormatToUnixTime(date) {
        return Number(moment(date, this.DATE_TIME_FORMAT_FULL, "ru").format("X"));
    };

    /**
     * Преобразование из стандартизированого формата даты в unix Timestamp
     * @param {object} date Принемает объект типа Date
     * @returns {number} Време в миллисекундах вида unix Timestamp
     */
    dateToUnixTime(date) {
        return moment(date.getTime()).unix();
    };

    /**
     * Проверка на текущий день
     * @param {number} unix_time unix Timestamp
     * @returns {Boolean}
     */
    isToday(unix_time) {
        return moment.unix(unix_time).format('DDMMYYYY') === moment().format('DDMMYYYY');
    };

    /**
     * Проверка на текущий год
     * @param {number} unix_time unix Timestamp
     * @returns {Boolean}
     */
    isThisYear(unix_time) {
        return moment.unix(unix_time).format('YYYY') === moment().format('YYYY');
    };

    /**
     * Преобразовать разницу unix Timestamp в дату проекта
     * @param {number} seconds unix Timestamp
     * @returns {String}
     */
    secondsLeftToDateFormat(seconds) {
        let calendarOpt = {
            sameDay: `[${locale.today}]`,
            lastDay: `[${locale.yesterday}]`,
            nextDay: `[${locale.tomorrow}]`,
        }
        calendarOpt.sameElse = calendarOpt.lastWeek = calendarOpt.nextWeek = this.DATE_FORMAT
        return moment().subtract(seconds || 0, 'seconds').calendar(null, calendarOpt);
    };

    /**
     * Преобразовать разницу unix Timestamp в время проекта
     * @param {number} seconds unix Timestamp
     * @returns {String}
     */
    secondsLeftToTimeFormat(seconds) {
        return moment().subtract(seconds || 0, 'seconds').format(this.TIME_FORMAT);
    };

    /**
     * Преобразовать разницу unix Timestamp в время проекта
     * @param {number} seconds unix Timestamp
     * @returns {String}
     */
    secondsLeftToUnix(seconds) {
        return moment().subtract(seconds || 0, 'seconds').unix();
    };

    /**
     * Вернуть время проекта или дату в зависимости от текущего дня
     * @param {number} unix_time unix Timestamp
     * @returns {String}
     */
    unixTimeToTimeOrDateFormat(unix_time) {
        return this.isToday(unix_time) ? this.unixTimeToTimeFormat(unix_time) :
            (this.isThisYear(unix_time) ? this.unixTimeToUserFormat(unix_time, 'DD MMM') : this.unixTimeToDateFormat(unix_time));
    };
}

