import {GET_ALARM_PROPS} from '../gettersTypes';
import {ACT_ALARM_SET_PROPS} from '../actionsTypes';
import {MUT_ALARM_SET_PROPS} from '../mutationsTypes';

// state
const state = {
    props: {}
};

// getters
const getters = {
    [GET_ALARM_PROPS](state) {
        return state.props;
    }
};

// actions
const actions = {
    [ACT_ALARM_SET_PROPS]({commit}, props) {
        commit(MUT_ALARM_SET_PROPS, props)
    },
};

// mutations
const mutations = {
    [MUT_ALARM_SET_PROPS](state, props) {
        state.props = props;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
