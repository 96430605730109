//
//
//
//
//
//
//
//
//
//
//
//

import modal_mixin from "./modal-mixin"
import ModalDialog from "./ModalDialog.vue"

export default {
    name: "ClientChanges",
    mixins: [modal_mixin],
    components: { ModalDialog },
    props: {
        changes: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            compModalProps: {
                delay: 200,
                pivotY: 0.05,
                width: 400,
                height: 'auto',
            },
        }
    },
    methods: {
        onOk() {
            this.modalClose();
        },
    }
}
