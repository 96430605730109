//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "RecycleContactsList",
    props: {
        contactsList: {
            type: Array,
            required: false,
            default: () => []
        },
        itemSize: {
            type: Number,
            required: false,
            default: 71
        },
        isEmitIndexes: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        bufferSize() {
            return this.itemSize * 10;
        }
    },
    methods: {
        emitUpdate(startIndex, endIndex) {
            this.$emit('item-indexes', { startIndex, endIndex })
        },
    },
    watch: {
        contactsList(items, itemsOld) {
            if (itemsOld.length > items.length) {
                this.$refs.scroller.$el.scrollTop = 0
            }
        },
    }
}
