import { render, staticRenderFns } from "./videoConfBase.vue?vue&type=template&id=633e1064&scoped=true&lang=pug&"
import script from "./videoConfBase.vue?vue&type=script&lang=js&"
export * from "./videoConfBase.vue?vue&type=script&lang=js&"
import style0 from "./videoConfBase.vue?vue&type=style&index=0&id=633e1064&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "633e1064",
  null
  
)

export default component.exports