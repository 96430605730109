var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "search", mode: "out-in" } }, [
    _c(
      "div",
      {
        staticClass: "search-contacts",
        class: {
          "search-contacts-none":
            _vm.contacts && !_vm.contacts.length && _vm.search.length >= 2
        },
        style: { height: _vm.heightSearch + "px" }
      },
      [
        _vm.search.length < 2
          ? _c("div", { staticClass: "search-contacts-info" }, [
              _vm._v(_vm._s(_vm.$t("search-comp.search-starts")))
            ])
          : _vm.search.length >= 2
          ? _c(
              "div",
              { staticClass: "search-contacts-list" },
              [
                _c("recycle-contacts-list", {
                  ref: "rcl",
                  attrs: {
                    contactsList: _vm.contacts,
                    itemSize: _vm.listHeight
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("contacts-list-item", {
                            attrs: {
                              contact: item,
                              height: _vm.listHeight,
                              noswitch: true,
                              noSelection: true
                            },
                            on: {
                              "update:contact-selected": function($event) {
                                return _vm.addContact(item)
                              }
                            }
                          }),
                          _c("div", { staticClass: "hor-line" })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }