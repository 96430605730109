//
//
//
//
//
//

import PublicationEditableItemText from "./PublicationEditableItemText.vue"

export default {
    name: "PublicationLocationItem",
    props: {
        data: {
            type: Object,
            required: true
        },
        edit: {
            type: Boolean,
            default: false
        },
        onClick: {
            type: Boolean,
            default: true
        },
        unixLastTime: {
            type: Number,
            default: 0,
            required: false
        }
    },
    components: {PublicationEditableItemText},
    data() {
        return {
            text: this.data.text
        }
    },
    methods: {
        doOnClick: function () {
            if (!this.onClick) return;
            window.openLink(app.getLocationLinkUrl(this.data));
        }
    },
    watch: {
        unixLastTime() {
            this.text = this.data.text;
        },
        text(val) {
            this.$emit('update', 'text', val)
        }
    }
}
