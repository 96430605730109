//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {CONTACTS, INFO} from "../../store/modulesNames"
import {ACT_INFO_PUSH} from "../../store/actionsTypes"
import {mapGetters} from "vuex"
import {GET_MERGED_CONTACT_BY_ID, GET_MERGED_CONTACT_BY_PHONE} from "../../store/gettersTypes"

import IconImage from "../IconImage.vue"

export default {
    name: "ChatCallAvailabilityMessage",
    props: ['message'],
    components: {
        'icon-image': IconImage
    },
    computed: {
        id() { return this.message.id },
        msg() { return this.message.msg },
        contact() {
            if (this.msg.cid) return this[GET_MERGED_CONTACT_BY_ID](this.msg.cid)
        },
        photo() {
            return this.contact && this.contact.photo || ''
        },
        title() {
            if (!this.msg.cid && !this.msg.number) {
                return ''
            } else if (!this.msg.cid) {
                return this.msg.number
            } else if (!this.msg.number) {
                return this.contact.fio
            } else {
                return `${this.contact.fio}(${this.msg.number})`
            }
        },
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID, GET_MERGED_CONTACT_BY_PHONE])
    },
    methods: {
        openInfo() {
            this.$store.dispatch(`${INFO}/${ACT_INFO_PUSH}`, {type: 'contact-info', params: {cid: this.msg.cid}});
        },
        fioById(cid) {
            return this[GET_MERGED_CONTACT_BY_ID](cid).fio || null
        },
    }
}
