"use strict";

 class Storage {
    constructor() {
        var self = this;

        var DATA = {
            login: 'login',
            password: 'password',
            remember_me: 'remember_me',
            tokens: 'tokens',
            locationsConfig: 'locations_config',
            serverHost: 'server_host',
            lastServer: 'last_server',
        };

        self.getLogin = function () {
            return localStorage.getItem(DATA.login) || '';
        };

        self.setLogin = function (login) {
            return localStorage.setItem(DATA.login, login || '');
        };

        self.getPassword = function () {
            return localStorage.getItem(DATA.password) || '';
        };

        self.setPassword = function (password) {
            return localStorage.setItem(DATA.password, password || '');
        };

        self.getRememberMe = function () {
            return localStorage.getItem(DATA.remember_me) === "true";
        };

        self.setRememberMe = function (val) {
            return localStorage.setItem(DATA.remember_me, !!val);
        };
        self.setLocationsConfig = val => localStorage.setItem(DATA.locationsConfig, JSON.stringify(val || '{}'));
        self.getLocationsConfig = () => JSON.parse(localStorage.getItem(DATA.locationsConfig) || '{}');

        self.getServerHost = function () {
            return localStorage.getItem(DATA.serverHost) || '';
        }

        self.setServerHost = function (val) {
            return localStorage.setItem(DATA.serverHost, val);
        }

        self.getLastServer = function () {
            return localStorage.getItem(DATA.lastServer) || '';
        }

        self.setLastServer = function (val) {
            return localStorage.setItem(DATA.lastServer, val);
        }

        self.clear = function() {
            let lastServer = self.getLastServer()
            localStorage.clear()
            self.setLastServer(lastServer)
        }

        /**
         * Чистим более неиспользуемые ключи
         */
        localStorage.removeItem('timeCloseInfoNotification')
        localStorage.removeItem('statusCloseInfoNotification')
    }
};

const storage = new Storage();

export default storage;
