//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "MainContentContainer",
    computed: {
        has_header_slot () {
            return !!this.$slots['header'];
        },
        has_default_slot () {
            return !!this.$slots.default
        },
        has_footer_slot () {
            return !!this.$slots['footer'];
        }
    }
}
