//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconImage from '../IconImage.vue';
import ProgressCircle from '../ProgressCircle.vue';
import PublicationMediaViewer from '../mediaViewer/PublicationMediaViewer.vue';
import PublicationFilesMixin from './PublicationFilesMixin';

export default {
    name: 'PublicationVideoItem',
    components: {'progress-circle': ProgressCircle, IconImage},
    mixins: [PublicationFilesMixin],
    props: {
        data: {
            type: Object,
            required: true
        },
        images: {
            type: Number,
            required: false
        },
        publication: {
            type: Object,
            required: false
        }
    },
    asyncComputed: {
        src() { return this.getPreview(this.data) },
    },
    // computed: {
    //     src() { return this.getPreview(this.data) },
    // },
    methods: {
        doShowViewer() {
            if (!this.publication) return;
            //this.doLoad();
            this.$modal.show(PublicationMediaViewer, {
                'chId': this.publication.chId,
                'pubId': this.publication.pubId,
                'index': this.data.index,
            }, {
                name: 'viewer',
                delay: 200,
                width: '100%',
                height: '100%',
                classes: ['v--modal', 'v--modal-box', 'no-gap-on-top']
            });
        },
        doLoad() {
            this.loadFile({ file: this.data.file, size: this.data.size })
        },
    }
}
