//
//
//
//
//
//
//
//

import videoConfSectionMixin from "./videoConfSectionMixin"
import { mapActions } from "vuex"
import { VIDEO_CONF_NEW } from "../../store/modulesNames"
import {
    ACT_VC_TOGGLE_MINIMIZE,
} from "../../store/actionsTypes"
import ConfBtn from './confBtn.vue'

export default {
    name: "videoConfSectionHeaderShare",
    mixins: [videoConfSectionMixin],
    components: {
        ConfBtn
    },
    methods: {
        toggleMinimize() {
            this[ACT_VC_TOGGLE_MINIMIZE](this.confIdActionData)
        },
        ...mapActions(VIDEO_CONF_NEW, [
            ACT_VC_TOGGLE_MINIMIZE,
        ])
    }
}
