'use strict';

import App from './app/app';

(function start() {
    window.app = new App();
    for (var prop in window.app) {
        if (typeof window.app[prop] === 'function') {
            window.app[prop] = window.app[prop].bind(window.app);
        }
    }
})();

function template(selector) {
    return $(selector).html();
}

