import BufferResizer from '../../common/BufferResizer'
import EventEmitter from "../../common/EventEmitter"
import { getAudioContext } from '../../common/Audio'

export const events = {
    resampledSample: 'resampledSample',
    decodedSample: 'decodedSample',
    resizedSample: 'resizedSample',
}

class RadioPlayer extends EventEmitter {
    constructor ({
                     inSampleRate = null,
                     inSampleLength = null,
                     inChannels = 1,
                     codecWorker,
                     audioResampler,
                     speakerId = null,
                     speakerVolume = null,
    }) {
        super()
        this.inSampleRate = inSampleRate
        this.inSampleLength = inSampleLength
        this.inChannels = inChannels
        this.audioCtx = null
        this.processorNode = null
        this.resampler = null
        this.bufferResizer = null
        this.audio = null
        this.codecWorker = codecWorker
        this.audioResampler = audioResampler
        this.outBuff = []
        this.inBuff = []
        this.id = 0
        this.getInt = 0
        this.speakerId = speakerId
        this.speakerVolume = speakerVolume
        this.processorURL = null
    }

    async start() {
        try {
            this.audioCtx = getAudioContext()
        } catch(e) {
            console.log(`!! RadioPlayer.start error ${e.message}`)
            throw e
        }

        const processorSource = `
            class AudioProcessor extends AudioWorkletProcessor {
                constructor() {
                    super()
                    this.outBuff = []
                    this.port.onmessage = ({data}) => {
                        let { type, payload } = data
                        if (type === 'output-sample') {
                            this.outBuff.push(payload)
                        } 
                    }
                }
                
                process(inputs, outputs) {
                    let channelData = inputs[0][0]
                    if (channelData) this.port.postMessage(channelData)
            
                    const output = outputs[0][0]
                    let outSample = this.outBuff.shift()
                    
                    if (outSample) {
                        output.set(outSample) 
                    }
                    return true
                }
            }
            try {
                registerProcessor('audio-processor', AudioProcessor)
            } catch(e) {
                console.log('')
            }
        `
        try {
            if (!this.processorURL) {
                let processorBlob = new Blob([processorSource], { type: 'text/javascript' })
                this.processorURL = URL.createObjectURL(processorBlob)
                await this.audioCtx.audioWorklet.addModule(this.processorURL)
                this.processorNode = new AudioWorkletNode(this.audioCtx, 'audio-processor')
            }
        } catch (e) {
            console.log('!! radio player audioWorklet.addModule error', e)
        }
        // Созданные узлы соединяем между собой
        let dest = this.audioCtx.createMediaStreamDestination();
        this.processorNode.connect(dest);
        this.audio = new Audio();
        this.audio.srcObject = dest.stream

        await this._setSinkId()

        await this.audio.play()

        // Установка громкости
        this._setVolume()

        let outChannels = 1 // this.outChannels || this.processorNode.channelCount
        let outSampleLength = 128 // this.processorNode.bufferSize * outChannels

        // Создаем ресемплер
        if (this.inSampleRate && this.audioCtx.sampleRate !== this.inSampleRate) {
            await this._createResampler(this.inSampleRate, this.audioCtx.sampleRate, outChannels)
        }

        // Создаем ресайзер
        this._createBufferResizer(outSampleLength)

        if (this.getInt) clearInterval(this.getInt)
        this.getInt = setInterval(() => { this.getSample() }, 10)
    }

    async changeProps({ speakerId = null, speakerVolume = null, }) {
        if (speakerId !== null) await this._changeSinkId(speakerId)
        if (speakerVolume !== null) await this._changeVolume(speakerVolume)
    }

    stop() {
        if(this.processorNode){
            if (this.getInt) clearInterval(this.getInt)
            this.processorNode.disconnect()
            this.audio && this.audio.pause()
            this.audio = null
        }
    }

    close() {
        this.stop()
        if (this.codecWorker) this.codecWorker.terminate()
        if (this.audioResampler) this.audioResampler.terminate()
    }

    addSample(sample) {
        this.inBuff.push(sample)
    }

    async getSample() {
        let sample = this.inBuff.shift()
        // console.log("!! -> file: RadioPlayer.js -> line 116 -> getSample -> sample", sample)
        if (!sample) return
        let decoded = await this.codecWorker.decode({data: sample})
        this.emit(events.decodedSample, decoded)
        let resampled = await this.audioResampler.process(new Float32Array(decoded))
        this.emit(events.resampledSample, resampled)
        this.bufferResizer.push(resampled)
    }

    async _createResampler(inSampleRate, outSampleRate, channels) {
        try {
            await this.audioResampler.init({
                channels,
                in_sampling_rate: inSampleRate,
                out_sampling_rate: outSampleRate
            })
        } catch (e) {
            console.log(`RadioPlayer._createResampler error ${e.message}`)
            throw e
        }
    }

    _createBufferResizer(outSampleLength) {
        this.bufferResizer = new BufferResizer({outLength: outSampleLength})
        this.bufferResizer.on('sample', async (sample) => {
                this.emit(events.resizedSample, sample)
                this.processorNode && this.processorNode.port.postMessage({type: 'output-sample', payload: sample});
        })
    }

    async _changeSinkId(speakerId) {
        this.speakerId = speakerId
        await this._setSinkId()
    }

    async _setSinkId() {
        try {
            this.audio && this.speakerId !== null && await this.audio.setSinkId(this.speakerId)
        } catch (e) {
            console.error(e)
        }
    }

    async _changeVolume(speakerVolume) {
        this.speakerVolume = speakerVolume
        this._setVolume()
    }

    _setVolume() {
        try {
            if (this.audio && this.speakerVolume !== null) this.audio.volume = this.speakerVolume
        } catch (e) {
            console.error(e)
        }
    }
}

export default RadioPlayer