//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "PublicationEditableItemText",
    props: {
        defaultText: {
            type: String,
            required: true
        },
        edit: {
            type: Boolean,
            default: false
        },
        unixLastTime: {
            type: Number,
            default: 0,
            required: false
        }
    },
    data() {
        return {
            text: this.defaultText
        }
    },
    methods: {
        update() {
            this.$emit('update:text', this.$el.innerText)
        }
    },
    watch: {
        edit(val) {
            if (val) {
                this.$nextTick(() => this.$el.focus())
            } else {
                this.$el.scroll(0, 0)
            }
        },
        unixLastTime() {
            this.$el.innerText = this.defaultText
        }
    }
}
