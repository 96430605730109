function addChatDefaultData (chat) {
    if (!('cidType' in chat)) chat.cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER
    if (chat.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP && !chat.contacts) chat.contacts = []
    try {
        if (chat.lastMessage && chat.lastMessage.dataType && chat.lastMessage.dataType === 'data') chat.lastMessage.data = JSON.parse(chat.lastMessage.data)
        return true
    } catch (e) {
        // log('addChatDefaultData: < error: invalid data: ' + chat.lastMessage.data)
        chat.lastMessage.data = {}
        return false
    }
}

export default {
    emitters: {
        addChat({ name, icon, contacts }) {
            return new Promise((resolve, reject) => {
                const data = {contacts: contacts, icon: icon, name: name}
                this.log('addChat: > contacts = ' + JSON.stringify(contacts) + ', name = ' + name + ', icon = ' + icon)
                this.socket.emit('add-chat', data, (res) => {
                    if (!res.cid) {
                        this.log('addChat: < add-chat error: ' + JSON.stringify(res))
                        return reject()
                    }
                    resolve(res.cid)
                })
            })
        },

        /**
         * @param {Object} params
         * @param {String} params.cidType
         * @param {Number} params.cid
         * @param {String} [params.name]
         * @param {String} [params.icon]
         * @param {Array} [params.addContacts] {cid, privilege}
         * @param {Array} [params.deleteContacts] {cid}
         */

        changeChat(params) {
            return new Promise((resolve, reject) => {
                if (!params.cidType || !('cid' in params)) throw new Error('No cidType or cid in params')
                let data = {}
                for (let param in params) data[param] = params[param]
                this.log('changeChat: > ' + JSON.stringify(data))
                this.socket.emit('change-chat', data, (data) => {
                    if (!data.rev) {
                        this.log('changeChat: < change-chat error')
                        return reject()
                    }
                    this.log('changeChat: < rev:' + data.rev)
                    resolve(data.rev)
                })
            })
        },

        /**
         * @param cidType
         * @param cid
         * @param contacts
         */
        addToChat(cidType, cid, contacts) {
            return new Promise((resolve, reject) => {
                // for (let i = 0; i < contacts.length; ++i) contacts[i] = { cid:+contacts[i], privilege:"admin" };
                let data = {cidType: cidType, cid: cid, addContacts: contacts}
                this.log('addToChat > ' + JSON.stringify(data))
                this.socket.emit('change-chat', data, (data) => {
                    if (!data.rev) {
                        this.log('addToChat < error')
                        return reject()
                    }
                    this.log('addToChat < rev:' + data.rev)
                    resolve(data.rev)
                })
            })
        },
        /**
         * @param cidType
         * @param cid
         * @param contacts
         */
        delFromChat(cidType, cid, contacts) {
            return new Promise((resolve, reject) => {
                // for (let i = 0; i < contacts.length; ++i) contacts[i] = { cid:+contacts[i] };
                let data = {cidType: cidType, cid: cid, deleteContacts: contacts}
                this.log('delFromChat > ' + JSON.stringify(data))
                this.socket.emit('change-chat', data, (data) => {
                    if (!data.rev) {
                        this.log('delFromChat < error')
                        return reject()
                    }
                    this.log('delFromChat < rev:' + data.rev)
                    resolve(data.rev)
                })
            })
        },

        /**
         * @param cidType {String}
         * @param cid {Number}
         */
        deleteChat(cidType, cid) {
            return new Promise((resolve, reject) => {
                const data = {cidType, cid}
                this.log('deleteChat > ' + JSON.stringify(data))
                this.socket.emit('delete-chat', data, (data) => {
                    if (data.result) {
                        this.log('deleteChat < success')
                        resolve()
                    } else {
                        this.log('deleteChat < error')
                        reject()
                    }
                })
            })
        },

        /**
         * @param cidType {String}
         * @param cid {Number}
         */
        exitChat(cidType, cid) {
            return new Promise((resolve, reject) => {
                const data = { cidType: cidType, cid: cid }
                this.log('exitChat > ' + JSON.stringify(data))
                this.socket.emit('exit-chat', data, (data) => {
                    if (data.error) {
                        this.log('exitChat < error: ' + data.error)
                        return reject(new Error(data.error))
                    }
                    this.log('exitChat < result:' + data.result)
                    resolve(data.result)
                })
            })
        },

        getChats(payload) {
            return new Promise((resolve, reject) => {
                const data = {
                    startMessageId: payload.startMessageId,
                    count: payload.count,
                    minMessageId: payload.minMessageId
                }
                this.log('getChats: > ' + JSON.stringify(data))
                this.socket.emit('get-chats', data, (data) => {
                    this.log('getChats: < ' + data.length)
                    data.forEach((cur) => addChatDefaultData(cur))
                    resolve(data)
                })
            })
        },

        getChatsChanges(minRev) {
            return new Promise((resolve, reject) => {
                const cb = (data) => {
                    this.log('getChatsChanges < ' + data.length)
                    data.forEach((cur) => addChatDefaultData(cur))
                    resolve(data)
                }
                this.log('getChatsChanges > minRev = ' + minRev)
                this.socket.emit('get-chats-changes', {minRev}, cb)
            })
        },

        getChatsLastRevision() {
            return new Promise((resolve, reject) => {
                const cb = (data) => {
                    if (!('rev' in data)) {
                        this.log('getChatsLastRevision < error')
                        return reject()
                    }
                    this.log('getChatsLastRevision < rev:' + data.rev)
                    resolve(data.rev)
                }
                this.log('getChatsLastRevision >')
                this.socket.emit('get-chats-last-revision', null, cb)
            })
        },
    },
    listeners: {
        'chat-change-event'(data) {
            this.log('bind: < chat-change-event: ' + JSON.stringify(data))
            addChatDefaultData(data)
            this.emit('chat-change-event', data)
        }
    },
}