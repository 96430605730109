//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';

import ProgressCircle from '../ProgressCircle.vue';
import ChatMessageMediaViewer from '../mediaViewer/ChatMessageMediaViewer.vue';
import ChatMessageMixin from './chat-message-mixin'

import { mapGetters } from 'vuex'
import { CONTACTS } from '../../store/modulesNames'
import {GET_MERGED_CONTACT_BY_ID} from '../../store/gettersTypes'

export default {
    name: 'chat-video-message',
    props: ['message'],
    components: {"progress-circle": ProgressCircle},
    mixins: [ChatMessageMixin],
    asyncComputed: {
        preview_src() { 
            const remoteHost = this.message.remoteServerHost || ''
            if (this.link) {
                return this.link
            } else {
                return this.getPreview(this.msg, remoteHost) 
            }
        },
    },
    computed: {
        id() { return this.message.id },
        msg() { return this.message.msg },
        xhrFile() { return this.msg && this.msg.previewFile },
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID])
    },
    methods: {
        doShowViewer() {
            this.$modal.show(ChatMessageMediaViewer, {
                src: this.msg,
                message: this.message,
                type: 'video'
            }, {
                name: 'viewer',
                delay: 200,
                width: '100%',
                height: '100%',
                classes: ['v--modal', 'v--modal-box', 'no-gap-on-top']
            });
        },
        fioById(cid) {
            return this[GET_MERGED_CONTACT_BY_ID](cid).fio || null
        },
        getPubPreview (data = {}) {
            let preview_file = data.preview_file && data.previewFile
            if (!preview_file && data.previewFile) {
                preview_file = declarations.http_post_dirs.CHANNEL_DATA_DIR + data.previewFile
            }
        return this.$store.getters['userdata/getHost'] + '/' + preview_file
    },
    }
}
