import IOClient from './IOClient'
import radioRcProto from './protocols/radioRc'
import radioTetraProto from './protocols/radioTetra'
import encryptionProto from './protocols/encryption'

class RadioIOClient extends IOClient {
    constructor() {
        super({logName: 'RadioIOClient', transports:  ['websocket'], withCredentials: false})
        this.transports = ['websocket']
        this.withCredentials = false
        this._subscribeOnSocket()
    }

    _subscribeOnSocket(encryption) {
        //if (encryption) this.addSocketEmitters(encryptionProto.emitters)
        this.on('connect', this._onSocketConnected.bind(this))
        this.on('disconnect', this._onSocketDisconnected.bind(this))
        this.on('connect_error', this._onSocketDisconnected.bind(this))
    }

    async _onSocketConnected(socket) {
        [
            radioRcProto,
            radioTetraProto,
        ].forEach((proto) => {
            this.addSocketEmitters(proto.emitters)
            this.addSocketListeners(proto.listeners)
        })

        this.emit('connected')
    }

    _onSocketDisconnected() {

    }
}

export default RadioIOClient