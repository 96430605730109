import Vue from 'vue';
import {
    GET_ACS_CID, GET_ACS_CONTACTS, GET_ACS_TYPE, GET_ACS_IS_ADMIN, GET_ACS_OM_LOGIN, GET_ACS_OM_PASSWORD, GET_IN_ACS,
    GET_ACS_SELF_EDIT_RIGHTS, GET_UID, GET_ACS_OM_IS_ADMIN, GET_ACS_EDIT_RIGHTS, GET_ACS_INFO, GET_ACS_CURRENT_JOURNAL_ENTRY,
    GET_ACS_ROLE
} from '../gettersTypes';
import {
    ACT_ACS_UPDATE_CONTACTS,
    ACT_ACS_STOP_UPDATE,
    ACT_ACS_SET_CID,
    ACT_ACS_SET_CONTACTS,
    ACT_ACS_SET_ROLE,
    ACT_ACS_SET_TYPE,
    ACT_ACS_SET_IS_ADMIN,
    ACT_ACS_SET_OM_LOGIN,
    ACT_ACS_SET_OM_PASSWORD,
    ACT_ACS_ADD_RECORD,
    ACT_ACS_DELETE_RECORD,
    ACT_ACS_UPDATE_INFO,
    ACT_UPDATE_CONTACT_LOCATION
} from '../actionsTypes';
import {
    MUT_ACS_SET_UPDATE_INTERVAL,
    MUT_ACS_SET_CLEAR_INTERVAL,
    MUT_ACS_SET_CID,
    MUT_ACS_SET_CONTACTS,
    MUT_ACS_SET_ROLE,
    MUT_ACS_SET_TYPE,
    MUT_ACS_SET_OM_IS_ADMIN,
    MUT_ACS_SET_OM_LOGIN,
    MUT_ACS_SET_OM_PASSWORD,
    MUT_ACS_UPDATE_CONTACT_INFO,
    MUT_ACS_DELETE_CONTACT_INFO,
    MUT_ACS_ADD_JOURNAL_RECORD,
    MUT_ACS_DELETE_JOURNAL_RECORD,
} from '../mutationsTypes';
import {USERDATA, LOCATIONS, ACS} from '../modulesNames';
import proto from '../../protocol';
import {console} from "vuedraggable/src/util/helper"
import moment from 'moment';

import { ACS_TYPES } from '../../constants'

const Acs = class {
    constructor() {
        this.state = {
            cid: null,
            contacts: [],
            role: null,
            type: 'none',
            om_is_admin: JSON.parse(localStorage.getItem('om_is_admin')),
            om_login: localStorage.getItem('om_login'),
            om_password: localStorage.getItem('om_password'),
            update_interval: 0,
            statuses: {},
            acsInfo: {}
        };
        this.getters = {
            acsEnabled: state => contact_id => state.contacts.indexOf(contact_id) !== -1,
            [GET_ACS_CID](state) {
                return state.cid;
            },
            [GET_ACS_CONTACTS](state) {
                return state.contacts;
            },
            [GET_ACS_ROLE](state, getters, rootGetters) {
                if(state.role === 'admin') return true;
                else if(state.role === 'user' && getters[GET_ACS_CID] === rootGetters.userdata.uid) return true;
                else return false;
            },
            [GET_ACS_TYPE](state) {
                return state.type;
            },
            [GET_ACS_OM_LOGIN](state) {
                return state.om_login;
            },
            [GET_ACS_OM_PASSWORD](state) {
                return state.om_password;
            },
            [GET_ACS_OM_IS_ADMIN](state) {
                return state.om_is_admin;
            },
            [GET_IN_ACS]: state => cid => state.contacts.indexOf(cid) !== -1,
            [GET_ACS_IS_ADMIN](state, getters) {
                if (state.role === 'admin') return true;
            },
            [GET_ACS_SELF_EDIT_RIGHTS](state, getters, rootState, rootGetters) {
                let hasRights = false;
                let selfCid = rootGetters[`${USERDATA}/${GET_UID}`];
                if (selfCid !== getters[GET_ACS_CID] || !getters[GET_IN_ACS](selfCid)) return hasRights;
                switch (state.type) {
                    case ACS_TYPES.OM:
                        hasRights = getters[GET_ACS_OM_LOGIN] && getters[GET_ACS_OM_PASSWORD];
                        break;
                    case ACS_TYPES.ORION:
                    case ACS_TYPES.PERCO:
                        hasRights = true;
                        break;
                }
                return hasRights;
            },
            [GET_ACS_EDIT_RIGHTS]: (state, getters) => getters[GET_ACS_SELF_EDIT_RIGHTS] || getters[GET_ACS_IS_ADMIN],
            [GET_ACS_INFO]: (state) => (cid) => state.acsInfo[cid] || {},
            [GET_ACS_CURRENT_JOURNAL_ENTRY]: (state, getters) => (cid) => {
                let {journal} = getters[GET_ACS_INFO](cid);
                let result = {};
                let time = moment().unix();
                if (journal && journal.length) for (let i = journal.length-1; i >= 0; i--) {
                    if (journal[i].from <= time && journal[i].to >= time) result = journal[i];
                }
                return result;
            },
            status: state => contact_id => state.statuses[contact_id],
            extParams: (state, getters) => {
                let ext = {};
                switch (state.type) {
                    case ACS_TYPES.OM:
                        ext = {login: getters[GET_ACS_OM_LOGIN], password: getters[GET_ACS_OM_PASSWORD]};
                        if (getters[GET_ACS_IS_ADMIN]) ext.cid = getters[GET_ACS_CID];
                        break;
                    case ACS_TYPES.ORION:
                    case ACS_TYPES.PERCO:
                }
                return ext;
            }
        };
        this.actions = {
            async [ACT_ACS_UPDATE_CONTACTS]({commit}) {
                let acs_contacts = await proto.getAcsContacts();
                commit(MUT_ACS_SET_CONTACTS, acs_contacts);
                commit(MUT_ACS_SET_UPDATE_INTERVAL, setInterval(this.updateContacts, 10 * 60 * 1000));
            },
            async [ACT_ACS_ADD_RECORD]({commit, getters}, record) {
                let ext = getters['extParams'];
                await proto.addAcsRecord(record, ext);
                commit(MUT_ACS_ADD_JOURNAL_RECORD, {
                    cid: getters[GET_ACS_CID],
                    record
                });
            },
            async [ACT_ACS_DELETE_RECORD]({commit, getters}, payload) {
                let {from, to, recId} = payload;
                let ext = getters['extParams'];
                await proto.removeAcsRecord(recId, from, to, ext);
                commit(MUT_ACS_DELETE_JOURNAL_RECORD, {
                    cid: getters[GET_ACS_CID],
                    record: payload
                });
            },
            [ACT_ACS_STOP_UPDATE](obj) {
                clearInterval(obj.state.update_interval);
                obj.commit(MUT_ACS_SET_UPDATE_INTERVAL, 0);
            },
            [ACT_ACS_SET_CID]({commit}, cid) {
                commit(MUT_ACS_SET_CID, cid);
            },
            [ACT_ACS_SET_CONTACTS]({commit}, contacts) {
                commit(MUT_ACS_SET_CONTACTS, contacts);
            },
            [ACT_ACS_SET_ROLE]({commit}, data) {
                commit(MUT_ACS_SET_ROLE, data);
            },
            [ACT_ACS_SET_TYPE]({commit}, type) {
                commit(MUT_ACS_SET_TYPE, type);
            },
            [ACT_ACS_SET_IS_ADMIN]({commit}, is_admin) {
                localStorage.setItem('om_is_admin', is_admin);
                commit(MUT_ACS_SET_OM_IS_ADMIN, is_admin);
            },
            [ACT_ACS_SET_OM_LOGIN]({commit}, login) {
                localStorage.setItem('om_login', login);
                commit(MUT_ACS_SET_OM_LOGIN, login);
            },
            [ACT_ACS_SET_OM_PASSWORD]({commit}, password) {
                localStorage.setItem('om_password', password);
                commit(MUT_ACS_SET_OM_PASSWORD, password);
            },
            async [ACT_ACS_UPDATE_INFO]({commit, dispatch, getters}, payload) {
                let info;
                let {cid, oldCid} = payload;
                dispatch(ACT_ACS_SET_CID, cid);
                //if (getters[GET_IN_ACS](cid)) 
                info = await proto.getAcs(cid);
                if (info) commit(MUT_ACS_UPDATE_CONTACT_INFO, {cid, info});
                //let currentJournalEntry = getters[GET_ACS_CURRENT_JOURNAL_ENTRY](cid);
                if (!(info && (info.locations && info.locations.length && info.locations[0].in))) {
                    await dispatch(`${LOCATIONS}/${ACT_UPDATE_CONTACT_LOCATION}`, cid, {root: true});
                }
                if (oldCid) {
                    commit(`deleteContactInfo`, oldCid);
                    commit(`${LOCATIONS}/deleteLocationsContacts`, oldCid, {root: true});
                }
            },
        };
        this.mutations = {
            [MUT_ACS_SET_UPDATE_INTERVAL](state, interval) {
                state.update_interval = interval;
            },
            [MUT_ACS_SET_CLEAR_INTERVAL](state) {
                clearInterval(state.update_interval);
            },
            [MUT_ACS_SET_CID](state, cid) {
                state.cid = cid;
            },
            [MUT_ACS_SET_CONTACTS](state, contacts) {
                state.contacts = contacts;
            },
            [MUT_ACS_SET_ROLE](state, data) {
                state.role = data;
            },
            [MUT_ACS_SET_TYPE](state, type) {
                if (!Object.values(ACS_TYPES).includes(type)) type = ACS_TYPES.NONE;
                state.type = type;
            },
            [MUT_ACS_SET_OM_IS_ADMIN](state, is_admin) {
                state.om_is_admin = is_admin;
            },
            [MUT_ACS_SET_OM_LOGIN](state, login) {
                state.om_login = login;
            },
            [MUT_ACS_SET_OM_PASSWORD](state, password) {
                state.om_password = password;
            },
            [MUT_ACS_UPDATE_CONTACT_INFO](state, payload) {
                Vue.set(state.acsInfo, payload.cid, payload.info)
            },
            [MUT_ACS_DELETE_CONTACT_INFO](state, cid) {
                delete state.acsInfo[cid]
            },
            [MUT_ACS_ADD_JOURNAL_RECORD](state, payload) {
                const info = state.acsInfo[payload.cid]
                if (info) info.journal.push(payload.record)
            },
            [MUT_ACS_DELETE_JOURNAL_RECORD](state, payload) {
                const info = state.acsInfo[payload.cid]
                if (!info) return
                const {from, to, recId} = payload.record;
                const recordIndex = info.journal.findIndex((record) => {
                    if (recId) return record.recId === recId
                    else return record.from === from && record.to === to
                })
                if (recordIndex >= 0) info.journal.splice(recordIndex, 1)
            }
        };
    }

    get store() {
        return {
            namespaced: true,
            state: this.state,
            getters: this.getters,
            actions: this.actions,
            mutations: this.mutations
        };    
    }
}

function getRecordExt(state) {

}

const acs = (new Acs()).store;
export default acs;
