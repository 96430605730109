
import {mapGetters} from 'vuex'
import {TETRA} from '../../store/modulesNames'
import {
    GET_TETRA_CONNECT_STATUS,
    GET_TETRA_CALL
} from '../../store/gettersTypes'

import RadioSignal, { SIGNALS } from "./RadioSignal.vue"

import {CONNECT_STATUS, CALL_EVENTS, TX_GRANT} from '../../store/modules/tetra-radio'

export default {
    name: "TetraSignal",
    extends: RadioSignal,
    computed: {
        call () {
            return this[GET_TETRA_CALL] || {}
        },
        audioRepeat() {
            return this.call.txGrant === TX_GRANT.REQUEST_QUEUED
        },
        signal() {
            let signal = -1
            //return audioFile
            let {callEvent, txGrant} = this.call
            if (!callEvent || this[GET_TETRA_CONNECT_STATUS] === CONNECT_STATUS.OFFLINE) return
            switch (callEvent) {
                case CALL_EVENTS.RELEASE:
                    signal = SIGNALS.RELEASE
                    break
                case CALL_EVENTS.TX_GRANT:
                    switch (txGrant) {
                        case TX_GRANT.GRANTED:
                            signal = SIGNALS.GRANTED
                            break
                        case TX_GRANT.NOT_GRANTED:
                            signal = SIGNALS.NOT_GRANTED
                            break
                        case TX_GRANT.REQUEST_QUEUED:
                            signal = SIGNALS.REQUEST_QUEUED
                            break
                        case TX_GRANT.TO_ANOTHER_USER:
                            signal = SIGNALS.TO_ANOTHER_USER
                            break
                    }
                    break
                case CALL_EVENTS.TX_INTERRUPT:
                    signal = SIGNALS.INTERRUPT
                    break
                case CALL_EVENTS.CEASING:
                    signal = SIGNALS.CEASING
                    break
            }
            return signal
        },
        ...mapGetters(TETRA, [GET_TETRA_CONNECT_STATUS, GET_TETRA_CALL])
    }
}
