//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex';
import {GET_UID, GET_ACS_TYPE, GET_ACS_ROLE} from "../../../store/gettersTypes";
import {ACT_ACS_UPDATE_INFO} from "../../../store/actionsTypes";
import {USERDATA, ACS} from "../../../store/modulesNames";
import MainContentContainer from "./MainContentContainer.vue";
import AcsAddBtn from "../../acs/AcsAddBtn.vue";
import AcsJournalList from "../../acs/AcsJournalList.vue";
import AcsStatus from "../../acs/AcsStatus.vue";
import MainContentLoader from "./MainContentLoader.vue";

export default {
    name: "MainContentAcs",
    components: {
        MainContentLoader,
        MainContentContainer, AcsStatus, AcsJournalList, AcsAddBtn},
    data() {
        return {
            acsLoading: true
        }
    },
    computed: {
        ...mapGetters(USERDATA, {contact_id: GET_UID}),
        ...mapGetters(ACS, {hasRights: GET_ACS_ROLE}),
        loaded() {
            return !this.acsLoading;
        },
        name() {
            return this.$t('mainPage.location')
        }
    },
    methods: {
        async updateAcsInfo() {
            this.acsLoading = true;
            await this[ACT_ACS_UPDATE_INFO]({cid : this.contact_id});
            this.acsLoading = false;
        },
        ...mapActions(ACS, [ACT_ACS_UPDATE_INFO])
    },
    watch: {
        loaded(val){
            this.$emit('update:loaded', val);
        }
    },
    beforeMount(){
        this.updateAcsInfo();
        this.$emit('update:need_loader', true)
    }
}
