import proto from "../../protocol";
import {
    GET_BOT_BY_ID,
    GET_BOTS,
    GET_IS_CHAT_OPENED,
    GET_IS_ROLES_SUPPORTED,
} from '../gettersTypes';
import {
    ACT_BOT_ADD,
    ACT_BOT_REMOVE,
    ACT_MAIN_TYPE_CLEAR,
    ACT_SEARCH_BOTS,
} from '../actionsTypes'
import {
    MUT_CHANGE_BOT,
    MUT_CHANGE_BOT_PROP,
    MUT_DELETE_BOT,
} from '../mutationsTypes';
import {CONTENT_MANAGER, CONTACTS, CHATS} from "../modulesNames"
import {BOT_PROPS, CONTACT_PROPS} from '../../constants'

// state
const state = {
    bots: [],
    searchedBots: [],
    keyboardChats: [],
};

// getters
const getters = {
    [GET_BOT_BY_ID](state) {
        return function(cid) {
            let result;
            state.bots.forEach( bot => {
                if(bot.cid === cid) result = bot;
            });
            return result;
        }
    },
    [GET_BOTS](state) {
        return state.bots.filter(bot => !bot.temp);
    },
};

// actions
const actions = {
    async [ACT_SEARCH_BOTS]({commit, rootGetters}, params) {
        let get_bots = await proto.searchBots(params)
        if (rootGetters[`${CONTACTS}/${GET_IS_ROLES_SUPPORTED}`]) {
            get_bots = get_bots.map(bot => {
                let preparedBot = {}
                if (bot[BOT_PROPS.TITLE]) preparedBot[CONTACT_PROPS.BOTTITLE] = bot[BOT_PROPS.TITLE]
                if (bot[BOT_PROPS.NAME]) preparedBot[CONTACT_PROPS.BOTNAME] = bot[BOT_PROPS.NAME]
                if (bot[BOT_PROPS.CID]) preparedBot[CONTACT_PROPS.CID] = bot[BOT_PROPS.CID]
                if (bot[BOT_PROPS.HELLO_DESCRIPTION]) preparedBot[CONTACT_PROPS.BOTHELLODESCRIPTION] = bot[BOT_PROPS.HELLO_DESCRIPTION]
                if (bot[BOT_PROPS.DESCRIPTION]) preparedBot[CONTACT_PROPS.BOTDESCRIPTION] = bot[BOT_PROPS.DESCRIPTION]
                if (bot[BOT_PROPS.COMMANDS]) preparedBot[CONTACT_PROPS.BOTCOMMANDS] = bot[BOT_PROPS.COMMANDS]
                preparedBot[CONTACT_PROPS.PHOTO] = app.store.getters['userdata/getPhotoUrlbyCid'](bot.cid)
                commit(MUT_CHANGE_BOT, preparedBot)
                return preparedBot
            })
        }
        return get_bots
    },
    [ACT_BOT_ADD]: async (obj, {cid, cidType = 'user', botId}) => {
        let params = { cid }
        if (cidType !== 'user') Object.assign(params, {cidType, botId})
        return proto.botManage({...params, command: 'add'})
    },
    [ACT_BOT_REMOVE]: async ({dispatch, commit, getters, rootGetters}, {cid, cidType = 'user', botId, contactRemoved}) => {
        if (!getters[GET_BOT_BY_ID](botId || cid)) return
        let params = { cid }
        if (cidType !== 'user') Object.assign(params, {cidType, botId})
        await proto.botManage({...params, command: 'delete'})
        if (contactRemoved) commit(MUT_DELETE_BOT, cid)
        if (cidType === 'user') {
            commit('chats/removeChat', {cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER, cid}, {root: true})
            if (rootGetters[`${CHATS}/${GET_IS_CHAT_OPENED}`]({cidType, cid})) {
                dispatch(`${CONTENT_MANAGER}/${ACT_MAIN_TYPE_CLEAR}`, null, {root: true});
            }
        }
    }
};

// mutations
const mutations = {
    // [MUT_ADD_BOT](state, bot) {
    //     state.bots.push(bot)
    // },
    [MUT_CHANGE_BOT](state, bot) {
        let oldBotIndex = state.bots.findIndex(({cid}) => cid === bot.cid)
        if (oldBotIndex >= 0) state.bots.splice(oldBotIndex, 1, bot)
        else state.bots.push(bot)
    },
    [MUT_DELETE_BOT](state, cid) {
        for(let i = 0; state.bots.length > i; i++ ) {
            if(cid === state.bots[i].cid) {
                state.bots.splice(i, 1);
            }
        }
    },
    [MUT_CHANGE_BOT_PROP](state, {cid, prop, val}) {
        let oldBot = state.bots.find((bot) => cid === bot.cid)
        if (oldBot) oldBot[prop] = val
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
