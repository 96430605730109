//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex'
import { CONTACTS } from '../store/modulesNames'
import { GET_MERGED_CONTACT_BY_ID } from '../store/gettersTypes'

export default {
    name: "chat-poll-message",
    props:['message', 'nocontextmenu'],
    data() {
        return {
            votes: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0
            },
            voteAll: 0,
            voteUser: 0,
        }
    },
    computed: {
        id() { return this.message.id },
        msg() { return this.message.msg },
        pollId() { return this.msg.pollId },
        answers() { return this.msg.answers },
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
    },
    created() {
        this.updatePoll()    
    },
    methods: {
        doAnswer(index, pollId) {
            if(this.msg.notAction) return;
            app.store.commit('chat/updatePollVote', pollId);
            let obj = {
                pollId: pollId,
                pollAns: index,
            };
            app.proto.givePollVote(obj, async (data) => {
                if(!this.voteUser && data.result) this.updatePoll()
            });
        },
        doVotesText(value) {
            if(value >= 5 && value <= 20) return value + this.$t('votes')
            else {
                switch(value % 10) {
                    case 1:
                        return value + this.$t('vote');
                        break;
                    case 2:
                    case 3:
                    case 4:
                        return value + this.$t('vote-2');
                        break;
                    default:
                        return value + this.$t('votes')
                }
            }
        },
        doContextMenu: function(event) {
            if (this.nocontextmenu) return
            event.stopPropagation()
            if(this.msg.notAction) return;
            if(this.voteUser) {
                event && event.preventDefault();
                let handlers = [];
                let open = this.cmOpen;
                handlers.push({item_name: this.$t('cancel-vote'), handler: this.cancelPoll});
                open(event, handlers);
            }
        },
        cancelPoll() {
            app.store.commit('chat/deletePollVote', this.msg.pollId);
            let obj = {
                pollId: this.msg.pollId,
                pollAns: 0,
            };
            app.proto.givePollVote(obj, async (data) => {
                if(data.result) {
                    for(let key in this.votes) {
                        this.votes[key] = 0;
                        this.voteUser = 0;
                    }
                    if (this.voteAll) this.voteAll--
                }
            })
        },
        async updatePoll() {
            let pollResult = await app.proto.getPollResult({pollId: this.msg.pollId});
            let votesSum = 0;
            if (pollResult.answers) {
                for(let key in pollResult.answers) {
                    votesSum += pollResult.answers[key];
                }
                this.voteAll = votesSum;
            }
            if (pollResult.vote) {
                app.store.commit('chat/updatePollVote', this.msg.pollId);
                for(let key in pollResult.answers) {
                    this.votes[key] = (pollResult.answers[key]/votesSum*100).toFixed(0);
                }
                this.voteUser = pollResult.vote
            }
        },
        fioById(cid) {
            return this[GET_MERGED_CONTACT_BY_ID](cid).fio || null
        },
    },
    watch: {
        pollId(val) {
            Object.keys(this.votes).forEach(key => this.votes[key] = 0)
        }
    }
}

