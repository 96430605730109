export default {
    emitters: {
        addAcsRecord(record, ext) {
            return new Promise((resolve, reject) => {
                this.log(`bind: > add-acs-record: ${JSON.stringify(record)}, ${JSON.stringify(ext)}`)
                this.socket.emit('add-acs-record', {record, ext}, data => {
                    if (data.result) {
                        this.log(`bind: < add-acs-record success`)
                        resolve()
                    } else {
                        this.log(`bind: < add-acs-record failed`)
                        reject()
                    }
                })
            })
        },

        removeAcsRecord(recId, from, to, ext = {}) {
            return new Promise((resolve, reject) => {
                if (!!recId) {
                    this.log(`bind: > delete-acs-record by recId: ${recId}`)
                    this.socket.emit("delete-acs-record", {recId, ext},
                        data => {
                            if (data.result) {
                                resolve()
                                this.log(`bind: < delete-acs-record by recId success`)
                            } else {
                                this.log(`bind: < delete-acs-record by recId failed`)
                                reject()
                            }
                        }
                    )
                } else {
                    this.log(`bind: > delete-acs-record: ${from} - ${to}`)
                    this.socket.emit("delete-acs-record", {from, to, ext},
                        data => {
                            if (data.result) {
                                resolve()
                                this.log(`bind: < delete-acs-record success`)
                            } else {
                                this.log(`bind: < delete-acs-record failed`)
                                reject()
                            }
                        }
                    )
                }
            })
        },

        checkOmLogin(login, password) {
            return new Promise((resolve, reject) => {
                this.log(`bind: > check-om-login`)
                this.socket.emit('check-om-login', {login, password}, data => {
                    if (data.result) {
                        this.log(`bind: < check-om-login success`)
                        resolve()
                    } else {
                        this.log(`bind: < check-om-login failed`)
                        reject()
                    }
                })
            })
        },
        getAcs(contact_id) {
            return new Promise((resolve, reject) => {
                this.log('bind: > get acs info')
                this.socket.emit('get-acs-info', contact_id, (data) => {
                    resolve(data)
                })
            })
        },

        getAcsContacts() {
            return new Promise((resolve, reject) => {
                this.socket.emit('get-acs-contacts', null, (contact_ids) => {
                    resolve(contact_ids)
                })
            })
        },
    },
    listeners: {},
}