//
//
//
//
//
//
//

import TypingDots from './TypingDots.vue'

import { CHATS } from '../../store/modulesNames'
import { GET_CHAT_TYPING_CONTACTS } from '../../store/gettersTypes'

import { mapGetters } from 'vuex'

export default {
    name: "TypingStatus",
    components: {TypingDots},
    props: {
        cid: {
            type: Number,
            required: true
        },
        cidType: {
            type: String,
            default: 'user'
        },
    },
    computed: {
        typingText() {
            let { cid, cidType } = this
            let contacts = this[GET_CHAT_TYPING_CONTACTS]({ cid, cidType })
            let msg = ''
            if (!contacts.length) return msg
            if (cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER) {
                msg = this.$t('typing')
            } else {
                //let limit_show = 2
                let limit_show = contacts.length
                msg = contacts.slice(0, limit_show).map(contact => contact.fio).join(', ')
            }
            return msg
        },
        ...mapGetters(CHATS, [GET_CHAT_TYPING_CONTACTS])
    }
}
