export default {
    emitters: {
        async getRoleRevision() {
            return new Promise((resolve, reject) => {
                this.log('RoleRevision: > ')
                this.socket.emit('get-role-revision', null, response => {
                    this.log('get-role-revision: < ' + response)
                    resolve(response)
                })
            })
        },
    },
    listeners: {
        'role-revision-change-event'(data) {
            this.log('bind: < role-revision-change-event: ' + JSON.stringify(data))
            console.log('bind: < role-revision-change-event: ', JSON.stringify(data))
            this.emit('role-revision-change-event', data)
        },
    },
}