var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-settings" },
    [
      _c(
        "main-content-container",
        {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "items-list" },
                    [
                      _c(
                        "list-item",
                        {
                          staticClass: "restrict-width",
                          attrs: { title: _vm.$t("settings.send-by-enter") }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "item-switch",
                              attrs: { slot: "after" },
                              slot: "after"
                            },
                            [
                              _c("custom-checkbox-slider", {
                                attrs: {
                                  checkbox: _vm.sendMsgByEnter,
                                  default: _vm.sendMsgByEnter
                                },
                                on: {
                                  "update:checkbox": function($event) {
                                    _vm.sendMsgByEnter = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "list-item",
                        {
                          staticClass: "restrict-width",
                          attrs: { title: _vm.$t("settings.send-by-ctrl") }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "item-switch",
                              attrs: { slot: "after" },
                              slot: "after"
                            },
                            [
                              _c("custom-checkbox-slider", {
                                attrs: {
                                  checkbox: _vm.sendMsgByCtrlEnter,
                                  default: !_vm.sendMsgByEnter
                                },
                                on: {
                                  "update:checkbox": function($event) {
                                    _vm.sendMsgByCtrlEnter = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("template", { slot: "header" }, [
            _vm._v(_vm._s(_vm.$t("settings.chats")))
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }