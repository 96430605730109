//
//
//
//
//
//
//
//
//
//
//
//


import {mapActions, mapGetters} from 'vuex'

import {
    BOTS,
    CHAT,
    CHATS,
} from "../../store/modulesNames"
import {
    GET_BOT_BY_ID,
    GET_SELECTED_CHAT,
} from "../../store/gettersTypes"
import {
    ACT_CHAT_GET_MESSAGE_BY_ID,
} from "../../store/actionsTypes"

import ChatMessageMixin from "./chat-message-mixin"
import ScrollToMessageMixin from "./ScrollToMessageMixin"

import MessageText from "./MessageText.vue"
import {CONTACT_FIELD_TYPES} from '../../constants'

export default {
    name: 'chat-selected-message',
    mixins: [ChatMessageMixin, ScrollToMessageMixin],
    components: { MessageText },
    data() {
        return {
            message: null,
            loaded: false
        }
    },
    props: {
        id: {
            type: Number,
            required: true
        },
        load_id: {
            type: Number,
            required: true
        },
    },
    created () {
        this.loadMessage()
    },
    watch: {
        async id() {
            this.$nextTick(() => {
                if (this.loaded) this.loadMessage()
            })
        },
        chat() {
            this.loaded = false
        },
        async load_id() {
            this.loaded = false
            this.loadMessage()
        },
    },
    asyncComputed: {
        preview() {
            if (this.message && this.withPreview(this.message.data && this.message.data.type)) {
                return this.getPreview(this.message.data)
            } else {
                return ''
            }
        },
    },
    computed: {
        chat() {
            return this[GET_SELECTED_CHAT] || {}
        },
        fio: function() {
            let bot = this.$store.getters[`${BOTS}/${GET_BOT_BY_ID}`](this.message.senderId);
            if(bot) return bot[CONTACT_FIELD_TYPES.BOTTITLE];
            else return app.store.getters['contacts/getMergedContactById'](this.message.senderId).fio;
        },
        infoTitle() {
            return ''
        },
        bgColorClass() {
            return this.getBgColorClassById()
        },
        colorClass() {
            return this.getColorClassById()
        },
        ...mapGetters(CHATS, [GET_SELECTED_CHAT]),
    },
    methods: {
        // *** Переопределить в дочернем компоненте start *** //
        onClose() { },
        // *** Переопределить в дочернем компоненте end *** //
        async loadMessage() {
            const id = this.id
            if (id) {
                let message = await this[ACT_CHAT_GET_MESSAGE_BY_ID]({id})
                if (message) this.message = message
                else this.$emit('clear')
            } else this.message = null
            this.$nextTick(() => {
                this.loaded = true
                this.$emit('loaded')
            })
        },
        close(e) {
            e.stopPropagation()
            this.onClose()
        },
        ...mapActions(CHAT, [ACT_CHAT_GET_MESSAGE_BY_ID]),
    }
}
