class EventEmitter {
    constructor () {
        this.listeners = {}
    }

    on(eventName, cb) {
        if (!this.listeners[eventName]) this.listeners[eventName] = []
        cb && this.listeners[eventName].push(cb)
    }

    off(eventName, cb) {
        if (!this.listeners[eventName]) return
        if (cb) {
            let index = this.listeners[eventName].indexOf(cb)
            if (index !== -1) this.listeners[eventName].splice(index, 1)
        } else {
            delete this.listeners[eventName]
        }
    }

    emit(eventName, ...args) {
        (this.listeners[eventName] || []).forEach((cb) => cb(...args))
    }

    clearListeners() {
        this.listeners = {}
    }
}

export default EventEmitter