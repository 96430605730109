export default {
    props: {
        menuProps: {
            type: Object,
            required: false,
            default: () => ({})
        },
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}