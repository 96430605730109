import { USERDATA } from '../app/store/modulesNames'

import {
    ACT_ACTIVE_MICROPHONE,
    ACT_ACTIVE_WEBCAMERA,
    ACT_ACTIVE_SPEAKERS,
    ACT_MEDIA_DEVICES,
} from '../app/store/actionsTypes'

import {
    GET_ACTIVE_MICROPHONE,
    GET_ACTIVE_WEBCAMERA,
    GET_ACTIVE_SPEAKERS,
} from '../app/store/gettersTypes'

import { MEDIA_DEVICES } from '../app/constants'

import { i18n } from './i18n'
const locale = i18n.messages[i18n.locale]

export async function updateDevices(store) {
    let devices
    try {
        devices = await navigator.mediaDevices.enumerateDevices()
    } catch (e) {
        console.log('AV Devices Error :', err)
    }
    devices = JSON.parse(JSON.stringify(devices))

    let webcam_devices = getConnectedDevicesType(devices, MEDIA_DEVICES.VIDEO_INPUT)
    let mic_devices = getConnectedDevicesType(devices, MEDIA_DEVICES.AUDIO_INPUT)
    // console.log("🚀 ~ file: mediaDevices.js:30 ~ updateDevices ~ mic_devices:", mic_devices)
    console.table(mic_devices)
    let speakers_devices = getConnectedDevicesType(devices, MEDIA_DEVICES.AUDIO_OUTPUT)
    //console.log("🚀 ~ file: mediaDevices.js:32 ~ updateDevices ~ speakers_devices:", speakers_devices)
    console.table(speakers_devices)
    await store.dispatch(`${USERDATA}/${ACT_MEDIA_DEVICES}`, [...webcam_devices, ...mic_devices, ...speakers_devices])

    let getActiveMic = store.getters[`${USERDATA}/${GET_ACTIVE_MICROPHONE}`]
    let getActiveWebcamera = store.getters[`${USERDATA}/${GET_ACTIVE_WEBCAMERA}`]
    let getActiveSpeakers = store.getters[`${USERDATA}/${GET_ACTIVE_SPEAKERS}`]

    if ((!getActiveWebcamera || !getActiveWebcamera.deviceId) && webcam_devices && webcam_devices.length > 0) {
        await store.dispatch(`${USERDATA}/${ACT_ACTIVE_WEBCAMERA}`, webcam_devices[0]);
    }
    if (!webcam_devices || webcam_devices.length === 0) {
        await store.dispatch(`${USERDATA}/${ACT_ACTIVE_WEBCAMERA}`, null)
    }

    if ((!getActiveMic || !getActiveMic.deviceId) && mic_devices && mic_devices.length > 0) {
        await store.dispatch(`${USERDATA}/${ACT_ACTIVE_MICROPHONE}`, mic_devices[0]);
    }
    if (!mic_devices || mic_devices.length === 0) {
        await store.dispatch(`${USERDATA}/${ACT_ACTIVE_MICROPHONE}`, null)
    }

    if (!getActiveSpeakers && speakers_devices && speakers_devices.length > 0) {
        await store.dispatch(`${USERDATA}/${ACT_ACTIVE_SPEAKERS}`, speakers_devices[0]);
    }
    if (!speakers_devices || speakers_devices.length === 0) {
        await store.dispatch(`${USERDATA}/${ACT_ACTIVE_SPEAKERS}`, null)
    }

    function getConnectedDevicesType(devices, type) {
        let nameIndex = 1
        const typeFilteredDevices = devices.filter(device => device.kind === type)
        let preparedDevices = typeFilteredDevices.filter((value, index, self) => 
            self.findIndex(v => v.groupId === value.groupId && v.label === value.label) === index
        )
        const isDefaultExist = preparedDevices.some(device => device.deviceId === 'default')
        if (isDefaultExist && preparedDevices.length > 1) {
            const defaultDevice = preparedDevices.find(device => device.deviceId === 'default')
            const defDevGroupId = defaultDevice.groupId
            const sameGroupIdDevice = preparedDevices.find(d => d.deviceId !== 'default' && d.groupId === defDevGroupId)
            if (sameGroupIdDevice) {
                preparedDevices = preparedDevices.filter(d => d.deviceId !== sameGroupIdDevice.deviceId && sameGroupIdDevice.groupId === defDevGroupId)
            }
            // Win fix: filter out 'communications device Ids as they always present as separate devices
            preparedDevices = preparedDevices.filter(d => d.deviceId !== 'communications')
        }
        return preparedDevices.filter(device => {
            if (device.kind !== type) return false
            if (device.label === '') {
                let nameType = ''
                switch (device.kind) {
                    case MEDIA_DEVICES.VIDEO_INPUT:
                        nameType = locale['camera']
                        break
                    case MEDIA_DEVICES.AUDIO_INPUT:
                        nameType = locale['microphone']
                        break
                    case  MEDIA_DEVICES.AUDIO_OUTPUT:
                        nameType = locale['speaker']
                        break
                }
                if (nameType) device.label = `${nameType} ${nameIndex++}`
            }
            return true
        })
    }
}