import { render, staticRenderFns } from "./CustomInputText.vue?vue&type=template&id=5544a038&scoped=true&lang=pug&"
import script from "./CustomInputText.vue?vue&type=script&lang=js&"
export * from "./CustomInputText.vue?vue&type=script&lang=js&"
import style0 from "./CustomInputText.vue?vue&type=style&index=0&id=5544a038&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5544a038",
  null
  
)

export default component.exports