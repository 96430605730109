//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';

import RangeSlider from 'vue-range-slider'
import { USERDATA } from "../../store/modulesNames.js";
import { GET_ACTIVE_SPEAKERS, GET_VOLUME_AUDIO } from "../../store/gettersTypes.js";
import { ACT_VOLUME_AUDIO, ACT_ACTIVE_SPEAKERS } from "../../store/actionsTypes.js";
import CustomDropDown from "../custom/CustomDropDown.vue"
import { getAudioContext } from "../../common/Audio"

export default {
    name: 'SpeakersSettings',
    props:['speakers'],
    components: {
        RangeSlider,
        CustomDropDown
    },
    data() {
        return {
            audioElement: null,
            audioContext: null,
            audioSrc: null,
            playBtnText: '',
            elements: 20,
            activeElements: 0,
            sliderValue: 100,
            sliderValueTimer: null,
            sliderDisabled: false,
            visualizerTimer: null,
            activeSpeaker: null,
            activeSpeakerName: '',
            activeSpeakerNameNew: '',
            first: false,
            playSound: false,
        }
    },
    mounted() {
        this.playBtnText = this.$t("start-play");
        this.audioContext = getAudioContext();
        this.audioElement = this.$refs.speakersRef;
        this.setOrRestoreValues();
    },
    computed: {
        placeholder() {
            let placeHolder = this.$t("choose-speaker");
            if (!!this.speakers && this.speakers.length > 0 && this.activeSpeakerName.length) {
                placeHolder = '';
            } else if (!this.speakers || this.speakers.length === 0) {
                placeHolder = this.$t("no-speakers-found");
            }
            return placeHolder;
        },
        speakersList() {
            let speakersArr = [];
            this.speakers.map((speaker, index) => {
                let arrItem = {value: speaker.label, name: speaker.label, selected: false};
                if (index === 0) arrItem.selected = true;
                if (index > 0 && arrItem.name === this.activeSpeakerName) {
                    speakersArr[0].selected = false;
                    arrItem.selected = true;
                }
                speakersArr.push(arrItem);
            });
            return speakersArr;
        },
        selectedSpeaker() {
            return this.activeSpeakerName ? { value: this.activeSpeakerName, name: this.activeSpeakerName } : { value: '', name: '' }
        }
    },
    watch: {
        speakers() {
            this.setOrRestoreValues()
        },
        async activeSpeakerNameNew(newVal, oldVal) {
            if (newVal) {
                let { deviceId } = this.speakers.find(({ label }) => label === newVal) || {}
                if (!deviceId && this.speakers.length) deviceId = this.speakers[0] && this.speakers[0].deviceId
                if (deviceId) {
                    try {
                        await this.changeDevice(deviceId)
                        this.activeSpeakerName = newVal
                    } catch (e) {
                        this.activeSpeakerName = ''
                    }
                }
            } else {
                this.activeSpeakerName = newVal
            }
            this.inited = true
        },
        activeSpeakerName(newVal, oldVal) {
            if(!!this.activeSpeakerName) {
                this.speakers.forEach(item => {
                    if(item.label === this.activeSpeakerName) {
                        this.$store.dispatch(`${USERDATA}/${ACT_ACTIVE_SPEAKERS}`, item);
                        this.$emit('change-output-audio-device', item);
                    }
                })
            } else if (!this.activeSpeakerName) this.sliderDisabled = true;
        },
        sliderValue() {
            this.changeVolume();
            if (this.sliderValueTimer) clearTimeout(this.sliderValueTimer);
            this.sliderValueTimer = setTimeout(() => {
                this.$store.dispatch(`${USERDATA}/${ACT_VOLUME_AUDIO}`, this.sliderValue);
                clearTimeout(this.sliderValueTimer);
            }, 500)
        },
    },
    methods: {
        setActiveSpeaker(val) {
            this.activeSpeakerName = val
        },
        setOrRestoreValues() {
            if (!this.speakers || this.speakers.length === 0) {
                this.$store.dispatch(`${USERDATA}/${ACT_ACTIVE_SPEAKERS}`, null);
                this.sliderDisabled = true;
                return this.activeSpeakerNameNew = '';
            }
            this.activeSpeaker = this.$store.getters[`${USERDATA}/${GET_ACTIVE_SPEAKERS}`];
            this.sliderValue = this.$store.getters[`${USERDATA}/${GET_VOLUME_AUDIO}`] * 100;
            this.sliderDisabled = false
            const isDeviceExist = this.checkIfDeviceExist(this.activeSpeaker && this.activeSpeaker.deviceId)
            if (this.activeSpeaker && isDeviceExist) {
                if (this.activeSpeaker.deviceId === 'default') {
                    this.activeSpeaker = this.speakers[0]
                    this.activeSpeakerNameNew = this.speakers[0].label
                }
                else this.activeSpeakerNameNew = this.activeSpeaker.label
            }
            else if (this.speakers && this.speakers[0])
                this.activeSpeakerNameNew = this.speakers[0].label;
        },
        checkIfDeviceExist(deviceId) {
            return this.speakers.some(dev => dev.deviceId === deviceId)
        },
        changeVolume() {
            this.$refs.speakersRef.volume = this.sliderValue/100;
        },
        togglePlaySound() {
            try {
                if(!this.playSound) {
                    this.audioElement.play();
                    this.playBtnText = this.$t("stop-play");
                    this.startVisualizer();
                }
                else {
                    this.stopPlay();
                }
            } catch (e) {}
            this.playSound = !this.playSound;
        },
        async changeDevice(sinkId) {
            if (typeof this.$refs.speakersRef.sinkId !== 'undefined') {
                if (sinkId.toLowerCase() === 'default') return;
                try {
                    await this.$refs.speakersRef.setSinkId(sinkId)
                    console.log(`Success, audio output device attached: ${sinkId}`);
                } catch (error) {
                    if (error.name === 'SecurityError') {
                        this.showError(this.$t('speaker-select-error-https'))
                    } else {
                        this.showError(this.$t('speaker-select-error'))
                    }
                    throw error
                }
            } else {
                this.showError(this.$t('speaker-select-error'))
                throw new Error("The browser does not support output devices selection.")
            }
        },
        startVisualizer() {
            clearInterval(this.visualizerTimer);
            this.stream = this.audioElement.captureStream();
            this.audioSrc = this.audioContext.createMediaStreamSource(this.stream);
            this.analyser = this.audioContext.createAnalyser()
            let analyser =  this.analyser;
            this.audioSrc.connect(analyser);
            this.visualizerTimer = setInterval(() => {
                let frequencyData = new Uint8Array(analyser.frequencyBinCount);
                let volume = this.sliderValue / 100;
                analyser.getByteFrequencyData(frequencyData)
                this.activeElements = Math.ceil(volume * frequencyData.reduce((sum, item) => sum + item, 0) / frequencyData.length);
            }, 50);
        },
        stopPlay() {
            clearInterval(this.visualizerTimer);
            !this.audioElement.paused && this.audioElement.pause();
            this.audioElement.currentTime = 0;
            this.activeElements = 0;
            this.playBtnText = this.$t("start-play");
            this.playSound = true;
        },
        beforeDestroy() {
            this.stopPlay();        
        },
        showError(text) {
            this.inited && this.modalOpen({
                name: 'alert',
                props: {
                    title: this.$t('errors.error'),
                    text: text
                }
            })
        }
    }
}
