//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainContentContainer from './MainContentContainer.vue'
import MainContentLoader from './MainContentLoader.vue'
import ListItem from '../../custom/ListItem.vue'
// import NewConf from '../../videoConfNewManagment/newConference.vue'
import EnterConf from '../../videoConfNewManagment/enterConference.vue'
import PlanConf from '../../videoConfNewManagment/scheduleConference.vue'
import PlanConfSettings from '../../videoConfNewManagment/scheduleConferenceSettings.vue'
import event_bus from '../../../eventBus'
import moment from 'moment'
import {
    ACT_VC_GET_CONF_LIST,
    ACT_VM_GET_CONFERENCE_INFO,
} from '../../../store/actionsTypes'
import ConfInfo from '../../videoConfNewManagment/conferenceInfo.vue'
import {mapActions} from 'vuex'
import {VIDEO_CONF_NEW, VM_CONF} from '../../../store/modulesNames'
import {CONF_SCHEDULE_PROPS} from '../../../store/modules/video-conf-abstract'
import {CONF_TYPES} from '../../../constants'

export default {
    name: 'MainContentVideoConfs',
    components: { MainContentContainer, MainContentLoader, ListItem, EnterConf, PlanConf, PlanConfSettings },
    data() {
        return {
            loader: false,
            mainTitle: this.$t('videomost.menu-conferences'),
            subjectName: '',
            from: 0,
            to: 0,
            confID: '',
            confPassword: '',
            compModalProps: {
                delay: 100,
                pivotY: 0.33,
                width: 500,
                height: 'auto',
                clickToClose: false
            },
            okText: this.$t('videomost.conf-plan'),
            cancelText: this.$t('videomost.cancel'),
            confSettingsText: this.$t('videomost.conf-settings'),
            confPlanned: [],
            confPassed: [],
            confTypes: { 
                roschat: this.$t('videomost.conferences-type-roschat'),
                vm: this.$t('videomost.conferences-type-videomost')
            }
        }
    },
    mounted() {
        event_bus.$off('reload-conf-lists')
        event_bus.$on('reload-conf-lists', () => {
            console.log('reload-conf-lists')
            this.loadConfLists()
        })
        this.loadConfLists()
    },
    methods: {
        getLocalTime(dt) {
            return moment.unix(dt).local().format('DD.MM.YYYY HH:mm')
        },
        async loadConfLists() {
            this.loader = true
            const { planned, passed } = await this[ACT_VC_GET_CONF_LIST]()
            this.confPlanned = planned
            this.confPassed = passed
            this.loader = false
        },
        openContextMenu(event, conf, position="bottom-right") {
            let handlers = []
            let open = this.cmOpen
            handlers.push({
                item_name: this.$t('videomost.conf-info-context'),
                handler: () => { this.confInfo(conf) }
            });
            open(event, handlers)
        },
        async confInfo(conf) {
            this.modalOpen({
                component: ConfInfo,
                props: { conf }
            })
        },
        ...mapActions(VIDEO_CONF_NEW, [ACT_VC_GET_CONF_LIST]),
        ...mapActions(VM_CONF, [ACT_VM_GET_CONFERENCE_INFO]),
    }
}
