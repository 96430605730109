
import { mapActions } from 'vuex'
import { VIDEO_CONF} from '../../../store/modulesNames'
import { ACT_CONFERENCE_TERMINATION } from "../../../store/actionsTypes"

import CallBase from "./CallBase.vue"

export default {
    name: "MinimizedConference",
    extends: CallBase,
    methods: {
        endCall() {
            this[ACT_CONFERENCE_TERMINATION](this.id)
        },
        ...mapActions(VIDEO_CONF, [ACT_CONFERENCE_TERMINATION])
    }
}
