//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

"use strict";
import {mapActions, mapGetters} from 'vuex'
import { CONTACTS, LOGIN } from '../store/modulesNames'
import { GET_SERVER_API, GET_IS_ROLES_SUPPORTED, GET_LIST_OF_BIRTHDAYS } from '../store/gettersTypes'

import IconImage from "./IconImage.vue";
import {ACT_SELECT_CONTACT, ACT_SET_BIRTHDAYS_WATCHED} from '../store/actionsTypes'

export default {
	name: "birthdays",
	components: {
		"icon-image": IconImage,
	},
	props: ["contact_id"],
	computed: {
		text() {
			return this.$t('birthdays')
		},
		isRolesModelSupported() {
			return this[GET_IS_ROLES_SUPPORTED]
		},
        birthdays() {
            //let testBirthDays = this[GET_LIST_OF_BIRTHDAYS]
            //console.log("🚀 ~ file: Birthdays.vue:38 ~ birthdays ~ testBirthDays", testBirthDays)
            return this[GET_LIST_OF_BIRTHDAYS]
        },
		...mapGetters(LOGIN, [GET_SERVER_API]),
        ...mapGetters(CONTACTS, [GET_IS_ROLES_SUPPORTED, GET_LIST_OF_BIRTHDAYS]),
	},
	data() {
		return {
			birthdaysList: null,
			opened: true,
			shrink: false,
			grow: true,
			photo: "",
		};
	},
	methods: {
		doWatched() {
		    this[ACT_SET_BIRTHDAYS_WATCHED](true)
		},
		async doOnSelected(cid) {
		    this[ACT_SELECT_CONTACT](cid)
		},
        ...mapActions(CONTACTS, [ACT_SELECT_CONTACT, ACT_SET_BIRTHDAYS_WATCHED]),
	},
};
