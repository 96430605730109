//
//
//
//
//

import { mapGetters } from 'vuex'
import { CONTENT_MANAGER } from '../../store/modulesNames'
import { GET_SIDE_BAR_NOTIFICATION } from '../../store/gettersTypes'
export default {
    name: 'side-notification-bar',
    data() {
        return {}
    },
    computed: {
        side_notif_msg() {
            return this[GET_SIDE_BAR_NOTIFICATION]()
        },
        ...mapGetters(CONTENT_MANAGER, [GET_SIDE_BAR_NOTIFICATION]),
    }
}
