//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'

const appointmentType = {
    INVITE: 'invite',
    APPOINTMENT_START: 'appointment-start'
}

export default {
    name: "chat-schedule-message",
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    components: {
    },
    data() {
        return {
            maxWidth: 160,
            showTitle: false,
        }
    },
    computed: {
        msg() { return this.message.msg },
        eventType() { return this.msg.eventType},
        author() { return this.msg.organizer },
        title() { 
            const titleStr = this.eventType === appointmentType.INVITE ? 
                this.$t('chat.schedule-names.invite') : this.$t('chat.schedule-names.appointment-start')
            return titleStr
        },
        subject() { return this.msg.subject ? this.msg.subject : this.$t('chat.schedule-names.no-name') },
        dateStr() {
            let retStr = ''
            let startTime = this.msg.startTime || false, endTime = this.msg.endTime || false
            if (startTime) startTime = moment.unix(startTime).local()
            if (endTime) endTime = moment.unix(endTime).local()
            const isSameDate = endTime && startTime.format('YYYY.MM.DD') === endTime.format('YYYY.MM.DD')
            if (startTime && endTime && !isSameDate) {
                retStr = startTime.format('DD.MM.YY HH:mm') + ' - ' + endTime.format('DD.MM.YY HH:mm')
            }
            if (startTime && endTime && isSameDate) {
                retStr = startTime.format('DD.MM.YY') + ' ' + startTime.format('HH:mm') + ' - ' + endTime.format('HH:mm')
            }
            if (startTime && !endTime) {
                retStr = startTime.format('DD.MM.YY')
            }
            return retStr
        },
        subjectTooltip() {
            return this.showTitle ? this.subject : ''
        },        
    },
    methods: {
        // openSchedule() {
        //     let test = this.message
        //     console.log("🚀 ~ file: chatScheduleMessage.vue:42 ~ openSchedule ~ test:", test)
        // },
        subjectMouseOver(e) {
            const childEl = e.target
            const childW = childEl.offsetWidth
            const parentW = e.relatedTarget && e.relatedTarget.offsetWidth
            childW > parentW ? this.showTitle = true : this.showTitle = false
        },        
    },
}

