//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

 import IconImage from '../IconImage.vue';
 import {mapGetters, mapActions} from 'vuex';
 import {CONTENT_MANAGER, CONTACTS} from '../../store/modulesNames';
 import {ACT_SELECT_CONTACT, ACT_SPLICE_FAVOURITES, ACT_ADD_CONTACT_CONNECTIONS, ACT_ADD_CONTACT} from '../../store/actionsTypes';
 import {GET_FAVOURITES, GET_SELECTED_CONTACT, GET_MAIN_TYPE} from '../../store/gettersTypes';
 import ListItemMixin from './ListItemMixin';

 import {MAIN_TYPES} from "../../store/modules/content-manager";
 import {CONTACT_FIELD_TYPES} from '../../constants'

 export default {
    name: 'contacts-list-item',
    components: {IconImage},
    mixins: [ListItemMixin],
    props: {
        contact: {
            type: Object,
            required: true
        },
        noswitch: {
            type: Boolean,
            required: false,
            default: false
        },
        parent: {
            type: String,
        },
        marked: {
            type: Boolean,
            required: false,
            default: false
        },
        noSelection: {
            type: Boolean,
            required: false,
            default: false
        },
        height: {
            type: Number,
            required: false,
            default: 70
        },
        subTitle: {
            type: [Number, String],
            required: false,
        },
        fullName: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    computed: {
        isSelected() {
            return (!this.noSelection && this[GET_MAIN_TYPE] === MAIN_TYPES.CONTACT && this.contact && this.contact.cid === this.contactSelected)
        },
        ...mapGetters(CONTACTS, {[GET_FAVOURITES]: GET_FAVOURITES, contactSelected: GET_SELECTED_CONTACT}),
        ...mapGetters(CONTENT_MANAGER, [GET_MAIN_TYPE]),
        fio() {
            return this.fullName ? this.contact.fioFull : this.contact.fio
        }
    },
    methods: {
        doSelect(cid) {
            this.$emit('update:contact-selected', cid)
            if (this.noswitch) return
            this[ACT_SELECT_CONTACT](cid)
        },
        showContextMenu(event, contact) {
            let handlers = [], cids = [], open = this.cmOpen
            cids.push(contact.cid)
            handlers.push({
                item_name: this.$t('roles.add-contact'),
                handler: async () => {
                    await this[ACT_ADD_CONTACT_CONNECTIONS](cids)
                    this[ACT_ADD_CONTACT](contact)
                }
            })
            open(event, handlers)
        },
        ...mapActions(CONTACTS, [
                ACT_SELECT_CONTACT,
                ACT_SPLICE_FAVOURITES, 
                ACT_ADD_CONTACT_CONNECTIONS, 
                ACT_ADD_CONTACT
            ]
        ),
    }
}
