
import SelectChatToSendMsg from "./SelectChatToSendMsg.vue"
import ipc from "../../../electron/ipc"

export default {
    name: "AddConfMembers",
    extends: SelectChatToSendMsg,
    mounted () {
        ipc.send('show-main-window')
    },
    beforeDestroy () {
        ipc.send('toggle-phone-on-top', true)
    },
    computed: {
        okText() { return this.$t('videomost.conf-members-invite') }
    },
}
