var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "radio-signal" },
    [
      _c("custom-audio", {
        ref: "audio",
        attrs: {
          src: _vm.audioFile,
          autoplay: true,
          type: "audio/mpeg",
          loop: _vm.audioRepeat
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }