var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bot-info" },
    [
      _c("info-main-block", {
        attrs: {
          "icon-photo": _vm.bot.photo,
          "icon-text": _vm.bot[_vm.CONTACT_FIELD_TYPES.BOTTITLE],
          "common-text": _vm.bot[_vm.CONTACT_FIELD_TYPES.BOTTITLE],
          "additional-text": _vm.bot[_vm.CONTACT_FIELD_TYPES.BOTDESCRIPTION],
          "icon-viewer": !!_vm.bot.photo,
          "icon-type": "bot",
          "icon-id": _vm.cid
        }
      }),
      _c(
        "actions-container",
        [
          _c(
            "list-item",
            {
              staticClass: "color-blue",
              attrs: {
                slot: "action",
                title: _vm.$t("share-link"),
                link: true,
                chevron: false
              },
              nativeOn: {
                click: function($event) {
                  return _vm.doShare($event)
                }
              },
              slot: "action"
            },
            [
              _c(
                "info-icon",
                {
                  attrs: {
                    slot: "media",
                    "icon-color": "#00aafb",
                    "position-left": true
                  },
                  slot: "media"
                },
                [
                  _c("i", {
                    staticClass: "fal fa-share",
                    attrs: { slot: "default" },
                    slot: "default"
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "list-item",
            {
              staticClass: "color-blue",
              attrs: {
                slot: "action",
                title: _vm.$t("copy-link"),
                link: true,
                chevron: false
              },
              nativeOn: {
                click: function($event) {
                  return _vm.doCopyReference($event)
                }
              },
              slot: "action"
            },
            [
              _c(
                "info-icon",
                {
                  attrs: {
                    slot: "media",
                    "icon-color": "#00aafb",
                    "position-left": true
                  },
                  slot: "media"
                },
                [
                  _c("i", {
                    staticClass: "fal fa-external-link",
                    attrs: { slot: "default" },
                    slot: "default"
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "list-item",
            {
              staticClass: "color-blue",
              attrs: {
                slot: "action",
                title: _vm.$t("add-to-group"),
                link: true,
                chevron: false
              },
              nativeOn: {
                click: function($event) {
                  return _vm.doAddToGroup($event)
                }
              },
              slot: "action"
            },
            [
              _c(
                "info-icon",
                {
                  attrs: {
                    slot: "media",
                    "icon-color": "#00aafb",
                    "position-left": true
                  },
                  slot: "media"
                },
                [
                  _c("i", {
                    staticClass: "fal fa-users-medical",
                    attrs: { slot: "default" },
                    slot: "default"
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showMute
        ? _c(
            "actions-container",
            [
              _c("chat-mute-switch", {
                attrs: { slot: "action", cid: _vm.cid, cidType: _vm.cidType },
                slot: "action"
              }),
              _c("chat-pin-switch", {
                attrs: { slot: "action", cid: _vm.cid, cidType: _vm.cidType },
                slot: "action"
              })
            ],
            1
          )
        : _vm._e(),
      _c("media-search-info-items", {
        on: { opensearchmedia: _vm.openSearchMedia }
      }),
      !_vm.canBack && _vm.inChatList
        ? _c(
            "actions-container",
            { staticClass: "delete-chat" },
            [
              _c(
                "list-item",
                {
                  staticClass: "color-red",
                  attrs: {
                    slot: "action",
                    title: _vm.$t("mainPage.delete-chat"),
                    link: true,
                    chevron: false
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.doDeleteChat($event)
                    }
                  },
                  slot: "action"
                },
                [
                  _c(
                    "info-icon",
                    {
                      attrs: {
                        slot: "media",
                        "icon-color": "#d30000",
                        "position-left": true
                      },
                      slot: "media"
                    },
                    [
                      _c("i", {
                        staticClass: "fal fa-trash-alt",
                        attrs: { slot: "default" },
                        slot: "default"
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }