var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "notification" } },
    [
      _c(
        "transition",
        { attrs: { name: "slide-fade", mode: "out-in" } },
        [
          _vm.show
            ? _c(
                "custom-notification",
                _vm._b(
                  { key: _vm.num, on: { hide: _vm.hide } },
                  "custom-notification",
                  _vm.dataNotify,
                  false
                )
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }