var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "more side-item" },
    [
      _c("user-profile", {
        ref: "userprofile",
        attrs: { contact_id: _vm.uid }
      }),
      _c("div", { staticClass: "side-header" }),
      _c("div", { staticClass: "side-body" }, [
        _c("div", { staticClass: "side-list" }, [
          _c("div", { staticClass: "side-list" }, [
            _c(
              "div",
              { staticClass: "items-list" },
              [
                _c(
                  "list-item",
                  {
                    staticClass: "profile-info",
                    class: _vm.dnd ? "dnd" : "",
                    attrs: {
                      title: _vm.fio,
                      footer: _vm.dnd
                        ? _vm.$t("mainPage.dnd")
                        : _vm.$t("mainPage.available"),
                      link: true,
                      active: _vm.isActive("profile")
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.doOnMoreItemClick(_vm.profile_type)
                      }
                    }
                  },
                  [
                    _c("icon-image", {
                      attrs: { slot: "media", src: _vm.photo, text: _vm.fio },
                      slot: "media"
                    })
                  ],
                  1
                ),
                _vm._l(_vm.items, function(item, i) {
                  return _c(
                    "list-item",
                    {
                      key: i,
                      attrs: {
                        title: item.text,
                        link: true,
                        active: _vm.isActive(item.type)
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.doOnMoreItemClick(item.type)
                        }
                      }
                    },
                    [
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.events[item.type],
                              expression: "events[item.type]"
                            }
                          ],
                          staticClass: "badge",
                          attrs: { slot: "after" },
                          slot: "after"
                        },
                        [_vm._v(_vm._s(_vm.events[item.type]))]
                      )
                    ]
                  )
                })
              ],
              2
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }