//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import objectFitImages from 'object-fit-images'

export default {
    name: 'icon-image',
    props: {
        src: {
            type: String,
            default: ''
        },
        text: {
            type: String
        },
        marked: {
            type: Boolean,
            default: false
        },
        remove: {
            type: Boolean,
            default: false
        },
        rollBack: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            get_error: false,
            get_error_global: false,
            loaded: false
        };
    },
    computed: {
        srcComputed() {
            let src = ''
            if (!this.get_error) src = this.src
            else if (!this.get_error_global && this.src_global) src = this.src_global
            return src
        },
        alter() {
            let splited = this.text && this.text.split && this.text.split(" ").filter(cur => {
                return cur !== ""
            })
            let text = '';

            if (!(splited && splited.length)) return text;

            // if (splited.length === 1) {
            //     let testEmoji = this.getEmojisIndexes(this.text)
            //     text = testEmoji.length && testEmoji[0].offset === 0 ? testEmoji[0].emo : splited.shift().substring(0, 2);
            // } else {
            //     text = splited.splice(0, 2).map(text => {
            //         let testEmoji = this.getEmojisIndexes(text)
            //         return testEmoji.length && testEmoji[0].offset === 0 ? testEmoji[0].emo : text[0]
            //     }).join("");
            // }

            if (splited.length === 1) {
                text = splited.shift().substring(0, 2);
            } else {
                text = splited.splice(0, 2).map(text => {
                    return text[0]
                }).join("");
            }

            return text.toUpperCase();
        },
        src_global() {
            if (this.text === "РОСЧАТ") return ''
            if (!this.src || this.src.search(/photos/) === -1) return ''

            let startPoint = this.src.indexOf('/photos/') + 8
            let endPoint = this.src.indexOf('/', startPoint)
            let result = this.src.replace(this.src.slice(startPoint, endPoint), '0')

            return result !== this.src ? result : ''
        },
        removeIcon() {
            return this.remove
        },
        rollBackIcon() {
            return this.rollBack
        }
    },
    watch: {
        src() {
            this.get_error = false
            this.get_error_global = false
        }
    },
    methods: {
        doOnLoad(e) {
            objectFitImages(e.target)
            this.$emit('loaded', {firstSrc: !this.get_error, loadedSrc: this.srcComputed})
        },
        doOnError(e) {
            if (this.srcComputed === this.src_global) this.get_error_global = true
            else this.get_error = true
        }
    }
}
