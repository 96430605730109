var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "entrance" }, [
    _c("div", { staticClass: "entrance-header" }, [
      _vm.can_back
        ? _c(
            "div",
            {
              staticClass: "entrance-back entrance-header-btns",
              attrs: { title: _vm.$t("loginPage.back") },
              on: { click: _vm.doBack }
            },
            [_c("i", { staticClass: "fal fa-arrow-left" })]
          )
        : _vm._e(),
      _c("span", { staticClass: "component-header" }, [
        _vm._v(_vm._s(_vm.compName))
      ])
    ]),
    _c(
      "div",
      { staticClass: "entrance-section" },
      [
        _c(
          "transition",
          { attrs: { name: _vm.components_transition_name } },
          [
            _c(
              _vm.component,
              _vm._b(
                {
                  tag: "component",
                  staticClass: "component",
                  attrs: { next: _vm.next },
                  on: {
                    "update:next": function($event) {
                      _vm.next = $event
                    },
                    logging: _vm.emitLogging
                  }
                },
                "component",
                _vm.componentProps,
                false
              )
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }