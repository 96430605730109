//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainHeaderContainer from './MainHeaderContainer.vue'
import NewConf from '../../videoConfNewManagment/newConference.vue'
import EnterConf from '../../videoConfNewManagment/enterConference.vue'
import PlanConf from '../../videoConfNewManagment/scheduleConference.vue'

export default {
    name: 'MainHeaderVideoConfs',
    components: { MainHeaderContainer },
    data() {
        return {
            subjectName: '',
            from: 0,
            to: 0,
            confID: '',
            confPassword: '',
        }
    },
    methods: {
        newConference() {
            this.modalOpen({ component: NewConf })
        },
        enterConference() {
            this.modalOpen({ component: EnterConf })
        },
        planNewConf() {
            this.modalOpen({
                component: PlanConf,
                props: {
                    from: this.from,
                    to: this.to,
                    subjectName: this.subjectName,
                    confID: this.confID,
                    confPassword: this.confPassword,
                    confMode: this.confMode
                }
            })
        },
    },
}
