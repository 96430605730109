var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bot-keyboard-chat" },
    _vm._l(_vm.keyboard, function(item) {
      return _c(
        "div",
        { staticClass: "bot-line-btns" },
        _vm._l(item, function(btn) {
          return _c(
            "div",
            {
              staticClass: "bot-btn",
              attrs: { title: btn.text },
              on: {
                click: function($event) {
                  return _vm.action(btn)
                }
              }
            },
            [_vm._v(_vm._s(btn.text))]
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }