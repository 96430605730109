//
//
//
//
//
//
//
//
//
//

import ModalSurvey from "./modal/ModalSurvey.vue"

export default {
    name: "chat-survey-message",
    props: {
        message: {
            type: Object,
            required: true
        },
        surveyOpened: {
            type: Boolean,
            default: false,
            required: false 
        }
    },
    components: {
        ModalSurvey
    },
    data() {
        return {
            maxWidth: 160,
        }
    },
    computed: {
        msg() { return this.message.msg },
        title() { return this.msg.title },
    },
    methods: {
        openSurvey() {
            this.isIFrameLoading = true
            this.srcLink = this.msg.link
            //this.$emit('surveyopened', true)
            this.modalOpen({
                component: ModalSurvey,
                props: { title: this.title, link: this.msg.link }
            })
        },

    },
}

