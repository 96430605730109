//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex';
import {USERDATA, CHANNELS} from "../../store/modulesNames";
import {GET_UID, GET_CHANNEL} from "../../store/gettersTypes"
import {ACT_LEAVE_FROM_CHANNEL, ACT_SUBSCRIBE_ON_CHANNEL} from "../../store/actionsTypes"
import InfoMainBlock from "../info/InfoMainBlock.vue"

export default {
    name: "ChannelInvite",
    props: {
        chId: {
            type: Number,
            required: true
        }
    },
    components: {InfoMainBlock},
    computed: {
        channel() {
            return this[GET_CHANNEL]({chId: this.chId})
        },
        photo() {
            return this.channel && app.getChannelPhotoUrl(this.channel.icon)
        },
        ...mapGetters(USERDATA, [GET_UID]),
        ...mapGetters(CHANNELS, [GET_CHANNEL])
    },
    methods: {
        onInviteBtnClick() {
            this.modalOpen({
                name: 'confirm',
                props: {
                    title: this.$t('invitation'),
                    text: `${this.$t('channel-invite-dialog-1')} "${this.channel.name}". ${this.$t('channel-invite-dialog-2')}`,
                    width: '400px',
                    btnOk: {
                        text: this.$t('accept'),
                        cb: () => this.doJoinChannel()
                    },
                    btnCancel: {
                        text: this.$t('decline'),
                        cb: () => this.doLeaveFromChannel()
                    },
                }
            })
        },
        async doLeaveFromChannel() {
            await this[ACT_LEAVE_FROM_CHANNEL]({chId: this.chId, contacts: [{cid: this[GET_UID]}]})
        },
        async doJoinChannel () {
            await this[ACT_SUBSCRIBE_ON_CHANNEL]({chId: this.chId})
        },
        ...mapActions(CHANNELS, [ACT_LEAVE_FROM_CHANNEL, ACT_SUBSCRIBE_ON_CHANNEL]),
    }
}
