//
//
//
//
//
//
//
//

 import UserProfile from '../settings/UserProfile.vue';
 import IconImage from '../IconImage.vue';
 import Notice from '../Notice.vue';
 import {mapGetters, mapMutations} from 'vuex';
 import {GET_SIDE_TYPE, SHOW_NEW_VERSION_NOTIFY, GET_SEARCH_OPENED, GET_RADIO_PARAMS, GET_SERVER_API, GET_MY_CONTACT, GET_IS_ROLES_SUPPORTED} from '../../store/gettersTypes';
 import {MUT_SET_SIDE_TYPE} from '../../store/mutationsTypes';
 import {CONTENT_MANAGER, CLIENTDATA, RADIO, LOGIN, CONTACTS} from "../../store/modulesNames";
 import { i18n } from '../../../ext/i18n'

 import {SIDE_TYPES} from '../../store/modules/content-manager'

 export default {
     name: "Toolbar",
     components: {
         'user-profile': UserProfile,
         IconImage,
         Notice
     },
     data: () => {
         return {
             dialog: null,
             showNotifyPanel: false,
             notification: null,
             notify: {
                 title: null,
                 body: null,
                 icon: null,
                 image: null,
             },
             locale: i18n.messages[i18n.locale]
         }
     },
     computed: {
        nav_btns() {
            const btns = [
                { name: SIDE_TYPES.CONTACTS_LIST, title: this.locale.contacts, icon_class: "fa fa-user" },
                { name: SIDE_TYPES.CHATS, title: this.locale.settings.chats, icon_class: "fa fa-comments" },
                { name: SIDE_TYPES.CALLS_LIST, title: this.locale.calls, icon_class: "fa fa-phone fa-rotate-90" },
                { name: SIDE_TYPES.MORE, title: this.locale.more, icon_class: "fa fa-equals" }
            ]
            if (this.isRadioAllowed && this[GET_RADIO_PARAMS].length) btns.unshift({ name: SIDE_TYPES.RADIO, title: this.locale.radio, icon_class: "fa fa-walkie-talkie" })
            return btns
        },
        isRadioAllowed() {
            if (!this[GET_IS_ROLES_SUPPORTED]) return true
            let isAllowed = true
            const actions = this[GET_MY_CONTACT].actions || {}
            if (actions.hasOwnProperty('use-radio')) isAllowed = actions["use-radio"]
            return isAllowed
        },
        ...mapGetters(CONTENT_MANAGER, { active_button: GET_SIDE_TYPE, searchOpened: GET_SEARCH_OPENED }),
        ...mapGetters(CLIENTDATA, [SHOW_NEW_VERSION_NOTIFY]),
        ...mapGetters(RADIO, [GET_RADIO_PARAMS]),
        ...mapGetters(LOGIN, [GET_SERVER_API]),
        ...mapGetters(CONTACTS, [GET_MY_CONTACT, GET_IS_ROLES_SUPPORTED]),
        events: function () {
            const events = {
                [SIDE_TYPES.CONTACTS_LIST]: null,
                [SIDE_TYPES.CHATS]: (this.$store.getters['channels/getTotalUnwatched'] + this.$store.getters['chats/getTotalUnwatchedMessages']) || null,
                [SIDE_TYPES.CALLS_LIST]: this.$store.getters['calls/getUnwatchedCallsCount'] || null,
                [SIDE_TYPES.VOICEMAIL]: null,
                [SIDE_TYPES.MORE]: (this[SHOW_NEW_VERSION_NOTIFY] && 1) || null
            };
            // let count = 0;
            // for (let i in events) {
            //     if (!!events[i]) count += +events[i];
            // }
            //this.$store.dispatch('userdata/totalEvents', count); //@todo тсутствует экшен в сторе
            return events;
        }
    },
    watch: {
        dialer_show(val) {
            console.log(val);
            if (val === 'close') this.doCloseDialer();
        }
    },
    methods: {
        call() {
            alert('CALL');
        },
        doOpenProfile() {
            const acs_cid = this.$store.getters['userdata/getUid'];
            this.$refs.userprofile.show(acs_cid);
        },
        doChangeActive(btn) {
            if (this.active_button === SIDE_TYPES.CALLS_LIST && btn !== SIDE_TYPES.CALLS_LIST) {
                let unwatchedCalls = this.$store.getters['calls/getUnwatchedCalls'];
                unwatchedCalls.forEach(item => {
                    this.$store.dispatch('calls/updateStatus', { id: item.id, status: 'watched' });
                });
            }
            if (this.active_button === btn) return;
            switch (btn) {
                case SIDE_TYPES.CONTACTS_LIST:
                case SIDE_TYPES.CHATS:
                case SIDE_TYPES.CALLS_LIST:
                case SIDE_TYPES.VOICEMAIL:
                case SIDE_TYPES.MORE:
                case SIDE_TYPES.RADIO:
                    this[MUT_SET_SIDE_TYPE](btn);
                    break;
            }
            //});
        },
        ...mapMutations(CONTENT_MANAGER, [MUT_SET_SIDE_TYPE])
    },
    filters: {
        maxEventCount(val) {
            return (val > 99) ? '99+' : val;
        }
    },
    // mounted: function () {
    //     /* this.doChangeActive(this.$store.getters['content-manager/getSideType']) */
    //     //this.doChangeActive('calls-list');
    // }
}
