import proto from '../../protocol'

import {CHANNELS, CHATS, CONTENT_MANAGER, USERDATA} from '../modulesNames'
import { MAIN_TYPES } from './content-manager'

import {
    ACT_SEND_DLP_EVENT,
    ACT_SEND_SCREENSHOT_EVENT,
    ACT_SEND_FILE_SAVE_EVENT,
    ACT_SEND_CLIPBOARD_EVENT,
    ACT_SEND_SHARE_EVENT,

} from '../actionsTypes'
import {GET_MAIN_TYPE, GET_SELECTED_CHANNEL, GET_SELECTED_CHAT, GET_USER_PARAMS} from '../gettersTypes'

const state = {
}

const getters = {
}

export const DLP_EVENT_TYPES = {
    CLIPBOARD: 'clipboard',
    FILE_SAVE: 'file-save',
    SCREENSHOT: 'screenshot',
    SHARE: 'share',
}

export const DLP_SOURCE_TYPES = {
    CHAT: 'chat',
    CHAT_MESSAGE: 'chat-message',
    CHANNEL: 'channel',
    PUBLICATION: 'publication',
    CONTACT: 'contact',
}

const actions = {
    [ACT_SEND_DLP_EVENT]: ({rootGetters}, payload) => {
        const userParams = rootGetters[`${USERDATA}/${GET_USER_PARAMS}`]
        if (userParams.dlpType) {
            proto.dlpEvent(payload).catch()
        }
    },
    [ACT_SEND_SCREENSHOT_EVENT]: ({dispatch, rootGetters}, payload) => {
        let focused = rootGetters[`${USERDATA}/focusedP`]
        let mainType = rootGetters[`${CONTENT_MANAGER}/${GET_MAIN_TYPE}`]
        let data = { eventType: DLP_EVENT_TYPES.SCREENSHOT }
        if (focused) {
            let viewer = document.querySelector('.viewer')
            let viewerComponent = viewer && viewer.__vue__
            if (viewerComponent) {
                switch (viewerComponent.$options.name) {
                    case 'PublicationMediaViewer': {
                        data.fileName = viewerComponent.mediaItem.file
                        data.eventData = {
                            sourceType: DLP_SOURCE_TYPES.PUBLICATION,
                            sourceData: {
                                chId: viewerComponent.chId,
                                pubId: viewerComponent.pubId
                            }
                        }
                        break
                    }
                    case 'ChatMessageMediaViewer': {
                        data.fileName = viewerComponent.src.file
                        data.eventData = {
                            sourceType: DLP_SOURCE_TYPES.CHAT_MESSAGE,
                            sourceData: {
                                id: viewerComponent.message.id,
                                cid: viewerComponent.message.cid,
                                cidType: viewerComponent.message.cidType,
                            }
                        }
                        break
                    }
                    case 'IconViewer': {
                        if (viewerComponent.iconType === 'contact') {
                            data.fileName = viewerComponent.originPath
                            data.eventData = {
                                sourceType: DLP_SOURCE_TYPES.CONTACT,
                                sourceData: {
                                    cid: viewerComponent.id,
                                }
                            }
                        }
                        break
                    }
                }
            } else if ([MAIN_TYPES.CONTACT, MAIN_TYPES.CHAT].includes(mainType)) {
                const chat = rootGetters[`${CHATS}/${GET_SELECTED_CHAT}`]
                data.eventData = {
                    sourceType: DLP_SOURCE_TYPES.CHAT,
                    sourceData: chat
                }
            } else if (MAIN_TYPES.CHANNEL === mainType) {
                const channel = rootGetters[`${CHANNELS}/${GET_SELECTED_CHANNEL}`]
                data.eventData = {
                    sourceType: DLP_SOURCE_TYPES.CHANNEL,
                    sourceData: { chId: channel }
                }
            }
            if (data.eventData) {
                if (data.fileName) data.fileName = (data.fileName || '').split('/').pop()
                dispatch(ACT_SEND_DLP_EVENT, data)
            }
        }
    },
    [ACT_SEND_FILE_SAVE_EVENT]: ({dispatch}, payload) => {
        payload.fileName = (payload.fileName || '').split('/').pop()
        dispatch(ACT_SEND_DLP_EVENT, {
            eventType: DLP_EVENT_TYPES.FILE_SAVE,
            eventData: payload
        })
    },
    [ACT_SEND_CLIPBOARD_EVENT]: ({dispatch}, payload) => {
        dispatch(ACT_SEND_DLP_EVENT, {
            eventType: DLP_EVENT_TYPES.CLIPBOARD,
            eventData: payload
        })
    },
    [ACT_SEND_SHARE_EVENT]: (obj, payload) => {

    },
}

const mutations = {
}

function prepareEventData(payload = {}) {
    const { text, fileName, sourceType, sourceData } = payload
    switch (sourceType) {
        case DLP_SOURCE_TYPES.CHAT: {
            const { cid, cidType } = sourceData

            break
        }
        case DLP_SOURCE_TYPES.CHAT_MESSAGE: {
            const { cid, cidType, id } = sourceData
            break
        }
        case DLP_SOURCE_TYPES.CHANNEL: {
            const { chId } = sourceData
            break
        }
        case DLP_SOURCE_TYPES.PUBLICATION: {
            const { chId, pubId } = sourceData
            break
        }
        case DLP_SOURCE_TYPES.CONTACT:
            const { cid } = sourceData

            break
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};