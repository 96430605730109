//
//
//
//
//
//
//
//
//
//
//

import ChooseMethod from './ChooseMethod.vue';
import login_pass from './LoginPass.vue';
import pass_restore from './PassRestore.vue';
import two_fa from './2FA.vue';
import qr_file from './QrFile.vue';
import remote from './Remote.vue';
import SelectServer from './SelectServer.vue';

export default {
    name: "Entrance",
    components: {login_pass, pass_restore, qr_file, remote, SelectServer, two_fa, ChooseMethod},
    data() {
        return {
            history: [{component: ChooseMethod}],
            current: 0,
            components_transition_name: 'none',
            params: null,
            next: null
        }
    },
    computed: {
        component() {
            return this.history[this.current].component;
        },
        componentProps() {
            return this.history[this.current].props;
        },
        can_back: function () {
            return this.history.length > 1 && this.current;
        },
        compName() {
            let result;
            switch (this.component) {
                case SelectServer:
                    result = this.$t('loginPage.select-server')
                    break;
                case login_pass:
                    result = this.$t('loginPage.login-pass')
                    break;
                case qr_file:
                    result = this.$t('loginPage.qr-file')
                    break;
                case remote:
                    result = this.$t('loginPage.remote')
                    break;
                case pass_restore:
                    result = this.$t('loginPage.pass-restore')
                    break;
                case two_fa:
                    result = this.$t('loginPage.two-fa')
                    break;
                case ChooseMethod:
                    result = this.$t('loginPage.login')
                    break;
                default:
                    result = '';
            }
            return result;
        },
    },
    methods: {
        updateComponentTransition: function(name, cb) {
            this.$set(this, 'components_transition_name', name);
            cb && this.$nextTick(cb);
        },
        doBack() {
            this.updateComponentTransition('login-switch-back', () => {
                if (this.current > 0) this.current--;
                this.$nextTick(() => this.history.splice(this.current + 1, this.history.length - 1));
            });
        },
        doAdd(comp, props) {
            this.addComp(comp, props);
            this.updateComponentTransition('login-switch-new',  () => {
                if ((this.current + 1) < this.history.length) this.current++;
            })
        },
        doNext() {
            this.doAdd(this.next);
        },
        addComp(comp, props) {
            let component
            switch (comp) {
                case 'select-server':
                    component = SelectServer
                    break;
                case 'login':
                    component = login_pass
                    break;
                case 'qr':
                    component = qr_file
                    break;
                case 'mobile':
                    component = remote
                    break;
                case 'pass-restore':
                    component = pass_restore
                    break;
                case '2FA':
                    component = two_fa
                    break;
            }
            if (component) this.history.push({component, props})
        },
        emitLogging() {
          this.$emit('update:logging' , true)
        },
    },
}
