//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions} from 'vuex'
import {VIDEO_CONF_NEW} from '../../store/modulesNames'
import {ACT_VC_CONF_CREATE} from '../../store/actionsTypes'

import ModalBtn from '../modal/ModalBtn.vue'
import confSettingsBase from './confSettingsBase.vue'
import ConfSettings from './scheduleConferenceSettings.vue'

import modal_mixin from '../modal/modal-mixin'

import {CONF_SCHEDULE_PROPS} from '../../store/modules/video-conf-abstract'

export default {
    name: 'ScheduleVC',
    components: {ModalBtn, confSettingsBase},
    mixins: [modal_mixin],
    data () {
        return {
            compModalProps: {
                delay: 100,
                pivotY: 0.33,
                width: 462,
                height: 'auto',
                clickToClose: false,
                adaptive: true,
                styles: "min-width: 387px; overflow-x: auto;"                    
            },
            okText: this.$t('videomost.conf-plan'),
            cancelText: this.$t('videomost.cancel'),
            confSettingsText: this.$t('videomost.conf-settings'),
            fieldsToShow: [
                CONF_SCHEDULE_PROPS.TYPE,
                CONF_SCHEDULE_PROPS.ID,
                CONF_SCHEDULE_PROPS.TOPIC,
                CONF_SCHEDULE_PROPS.PASSWORD,
                CONF_SCHEDULE_PROPS.START_TIME,
                CONF_SCHEDULE_PROPS.END_TIME,
            ],
        }
    },
    methods: {
        async createNewConf () {
            let newConfParams = this.$refs.settings.getConf()
            console.log(newConfParams)
            await this[ACT_VC_CONF_CREATE](newConfParams)
            this.modalClose()
            this.$bus.$emit('reload-conf-lists')
        },
        openConfSettings () {
            let newConfParams = this.$refs.settings.getConf(false)
            console.log('!!!!!!!newConfParams', JSON.parse(JSON.stringify(newConfParams)))
            this.modalClose()
            this.modalOpen({
                component: ConfSettings,
                props: {conf: newConfParams, isNew: true, extended: true},
            })
        },
        ...mapActions(VIDEO_CONF_NEW, [ACT_VC_CONF_CREATE]),
    },
}
