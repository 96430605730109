export default {
    props: {
        id: {
            type: [String, Number],
            //required: true
        },
        type: {
            type: [String, Number],
            required: true
        }
    },
    computed: {
        confIdGetterData() {
            return this.type && this.id && [ this.type, this.id ]
        },
        confIdActionData() {
            return this.type && this.id && { type: this.type, id: this.id }
        },
    }
}