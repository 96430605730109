export default {
    emitters: {
        dlpEvent(data) {
            return new Promise((resolve, reject) => {
                this.log('dlp-event: > ' + JSON.stringify(data))
                this.socket.emit('dlp-event', data, res => {
                    if (res.error) {
                        this.log(`dlp-event: < error: ${res.error}`)
                        return reject(new Error(res.error))
                    }
                    this.log('dlp-event: < ok')
                    resolve()
                })
            })
        }
    }
}