//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconImage from '../IconImage.vue';
import {CHANNELS} from "../../store/modulesNames";
import {GET_HAVE_ADMIN_RIGHTS} from "../../store/gettersTypes";
import ChannelPublicationEditor from './ChannelPublicationEditor.vue'

import { i18n } from '../../../ext/i18n';

export default {
    name: "PublicationHeader",
    comments: {IconImage},
    props: ['chId', 'pubId', 'channel', 'publication'],
    computed: {
        publisherContact() { return this.publication.cid && this.$store.getters['contacts/getMergedContactById'](this.publication.cid) },
        publisherPhoto() { return this.publisherContact ? this.$store.getters['contacts/getMergedContactById'](this.publisherContact.cid).photo :  this.channel.icon && app.getChannelPhotoUrl(this.channel.icon) || '' },
        publisherName() { return this.publisherContact ? this.publisherContact.fio : this.channel.name },
        publishedTime() { return date_helper.unixTimeToTimeOrDateFormat(date_helper.getCurrentUnixTime() - this.publication.time) },
        adminRights() { return this.$store.getters[`${CHANNELS}/${GET_HAVE_ADMIN_RIGHTS}`]({chId: this.chId}) },
        link() { return `@[publication\\${this.chId}\\${this.pubId}:${this.publication.title}]` }
    },
    methods: {
        doMenu(e) {
            let channels = i18n.messages[i18n.locale]['channel-comp']
            const handlers = [],
                open = this.cmOpen;
            if (this.adminRights) {
                handlers.push({item_name: channels['edit-publ'], handler: this.doEditPublication});
                handlers.push({item_name: channels['delete-publ'], handler: this.doRemovePublication});
            }
            handlers.push({item_name: this.$t('copy-link'), handler: this.getLink});
            open(event, handlers);
        },
        getLink() {
            utils.copyTextToClipboard(null, this.link)
        },
        doEditPublication() {
            this.modalOpen({
                component: ChannelPublicationEditor,
                props: { pubId: this.pubId, chId: this.chId }
            })
        },
        doRemovePublication() {
            this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('channel-comp.delete-publ-conf'),
                    btnOk: {
                        cb: () => {
                            this.$store.dispatch('channel/removePublication', {pubId: this.pubId, chId: this.chId});
                        }
                    }
                }
            })
        },
    }
}
