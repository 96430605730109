//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import {CLIENTDATA} from '../../../store/modulesNames'
import {GET_SID} from '../../../store/gettersTypes'
import MainContentContainer from './MainContentContainer.vue'
import ListItem from '../../custom/ListItem.vue'
import proto from '../../../protocol'
import { i18n } from '../../../../ext/i18n'
let locale = i18n.messages[i18n.locale]
import moment from 'moment'

export default {
    name: 'MainContentSessions',
    components: {ListItem, MainContentContainer},
    data() {
        return {
            sessions: [],
            current: {},
        }
    },
    computed: {
        ...mapGetters(CLIENTDATA, {sid: GET_SID})
    },
    methods: {
        async update() {
            try {
                let sessions = await proto.getSessions()
                this.sessions = sessions.map((session) => {
                    return {
                        sid: session.id,
                        client: session.user_agent.client_name ? (session.user_agent.client_name + (session.user_agent.client_version ? ' ' + session.user_agent.client_version : '')) : '',
                        device: session.user_agent.device_vendor ? (session.user_agent.device_vendor + (session.user_agent.device_model ? ' ' + session.user_agent.device_model: '')) :
                            (session.user_agent.os_name ? session.user_agent.os_name + ' ' + (session.user_agent.os_version || '') : ''),
                        ip: session.ip + (session.ip_info ? ' ' + [session.ip_info.country, session.ip_info.region, session.ip_info.city].filter((val) => !!val).join(' ') : ''),
                        start_time: session.start_time
                    }
                })
                this.current = this.sessions.find((val) => val.sid === this.sid) || {}
                let index
                if ((index = this.sessions.indexOf(this.current)) !== -1) this.sessions.splice(index, 1)
            } catch (e) {
                throw e
            }

        },
        doCloseSessions() {
            const doCloseSessions = async () => {
                try {
                    await proto.dropSessions()
                    this.update()
                } catch (e) {
                    throw e
                }
            }

            this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('mainPage.logout-all-q'),
                    btnOk: {
                        cb: () => doCloseSessions()
                    }
                }
            })


        }
    },
    filters: {
        dateFormat(unix_time) {
            if (!unix_time) return ''
            const DATE_FORMAT = 'DD MMM YYYY',
                TIME_FORMAT = 'HH:mm',
                DATE_TIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT,
                FORMAT = date_helper.isToday(unix_time)?TIME_FORMAT:DATE_TIME_FORMAT
            return  moment.unix(unix_time).format(FORMAT)
        },
        clientFormat(client) {
            if (!client) return ''
            return !!client ? client : locale.mainPage['unknown-client']
        },
        deviceFormat(device) {
            if (!device) return ''
            return !!device ? device : locale.mainPage['unknown-device']
        }
    },
    async mounted() {
        try {
            this.update()
        } catch (e) {
            throw e
        }

    }
}
