//
//
//
//
//
//
//
//
//
//

"use strict"

import ChatRegularFileMessage from "../chat/ChatRegularFileMessage.vue"

export default {
    name: "media-search-files",
    props: {
        messages: {
            type: Object,
            default: {}
        }, 
    },
    components: {
        ChatRegularFileMessage
    },
    data() {
        return {}
    },
    methods: {
        prepareMessages(messages) {
            let res = []
            messages.forEach(m => {
                const _newObj = Object.assign({}, m)
                _newObj.time = date_helper.secondsLeftToTimeFormat(_newObj.time)
                const { msg, ...rest } = _newObj
                if (msg.constructor === Array) {
                    msg.forEach(msg => { if (msg.type === 'file') res.push({ msg, ...rest }) })
                } else res.push(_newObj)
            })
            return res
        },
        onContextMenu(e, message) {
            this.$emit("mediasearch-common-contextmenu", { e, message })
        }
    }
}
