import store from '../store/main/store'

import {CHATS} from '../store/modulesNames'
import {GET_CHAT_MEMBER_INDEX} from '../store/gettersTypes'

const COLOR_CLASSES_COUNT = 36

let namesCache = {}

export const getColorClassByMessage = (message) => {
    let {senderId: memberCid,cid, cidType} = message
    let index = store.getters[`${CHATS}/${GET_CHAT_MEMBER_INDEX}`]({memberCid,cid, cidType})
    return `item-color-${getClassNumberById(index)}`
}

export const getBGClassByMessage = (message) => {
    let {senderId: memberCid,cid, cidType} = message
    let index = store.getters[`${CHATS}/${GET_CHAT_MEMBER_INDEX}`]({memberCid,cid, cidType})
    return `item-bg-color-${getClassNumberById(index)}`
}

export const getColorClassById = (id = -1) => {
    return `item-color-${getClassNumberById(id)}`
}

export const getBgColorClassById = (id = -1) => {
    return `item-bg-color-${getClassNumberById(id)}`
}

export const getColorClassByName = (name = '') => {
    return `item-color-${getClassNumberByName(name)}`
}

export const getBgColorClassByName = (name = '') => {
    return `item-bg-color-${getClassNumberByName(name)}`
}

function getClassNumberById(id) {
    if (id === -1) return 'gray'
    return id % COLOR_CLASSES_COUNT
}

function getClassNumberByName (name = '') {
    let sum = 0
    let classNumber = namesCache[name]
    if (!classNumber) {
        for (let i = 0, count = name.length; i < count; i++) {
            sum += name.charCodeAt(i)
        }
        classNumber = sum % COLOR_CLASSES_COUNT
        namesCache[name] = classNumber
    }
    return classNumber
}