//
//
//
//
//

export default {
    name: "InfoSection",
    props: {
        text: {
            type: [String, Number],
            default: ''
        }
    }
}
