//
//
//
//
//
//
//

export default {
    name: "CustomCheckboxSlider",
    props: {
        default: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        tabindex: {
            type: [String, Number],
            required: false
        },
        height: {
            type: [Number],
            default: 24
        },
        readonly: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            checked: this.default
        };
    },
    computed: {
        def() { return this.default },
        width() { return Math.round(this.height * 1.764)},
        css_props() {
            return {
            '--switch-width': this.width + 'px',
            '--switch-height': this.height + 'px',
            '--switch-slider-height': (Math.round(this.height * 10 / 1.307) /10) + 'px'
            }
        }
    },
    watch: {
        default() {
            this.checked = this.default;
        },
        checked() {
            this.$emit('update:checkbox', this.checked);
        }
    }
}
