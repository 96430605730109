var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat-geo-message" }, [
    _vm.message.author
      ? _c("div", { staticClass: "forward" }, [
          _c("span", { staticClass: "fwd title" }, [
            _c("i", { staticClass: "fas fa-share" }),
            _c("p", [_vm._v(_vm._s(_vm.$t("chat.fwd-message")))])
          ]),
          _vm.fioById(_vm.message.author)
            ? _c("span", { staticClass: "fwd author" }, [
                _vm._v(
                  _vm._s(_vm.$t("chat.author")) +
                    ": " +
                    _vm._s(_vm.fioById(_vm.message.author))
                )
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _c("div", { on: { click: _vm.openLink } }, [
      _c("img", {
        staticClass: "location",
        attrs: { src: _vm.msg.location_image_url }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }