var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "context-menu-base",
    _vm._b({}, "context-menu-base", _vm.menuProps, false),
    [
      _c("div", { staticClass: "scheme-select" }, [
        _c("div", { staticClass: "select-wrapper" }, [
          _c(
            "div",
            {
              staticClass: "btn-wrapper",
              on: {
                click: function($event) {
                  return _vm.setSchema("puzzle")
                }
              }
            },
            [
              _c("conf-btn", {
                attrs: {
                  imageClasses: "fal fa-th",
                  subTitle: _vm.$t("videomost.scheme.puzzle")
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "btn-wrapper",
              on: {
                click: function($event) {
                  return _vm.setSchema("tribune")
                }
              }
            },
            [
              _c("conf-btn", {
                attrs: {
                  imageClasses: "fal fa-window-maximize fa-rotate-180",
                  subTitle: _vm.$t("videomost.scheme.tribune")
                }
              })
            ],
            1
          ),
          _vm.share
            ? _c(
                "div",
                {
                  staticClass: "btn-wrapper",
                  on: {
                    click: function($event) {
                      return _vm.setSchema("share")
                    }
                  }
                },
                [
                  _c("conf-btn", {
                    attrs: {
                      imageClasses: "fal fa-share-alt-square red-color",
                      subTitle: _vm.$t("videomost.scheme.share"),
                      color: "red"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }