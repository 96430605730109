
import MembersInfo from "./MembersInfo.vue";
import SelectContactsToChannel from "../modal/SelectContactsToChannel.vue";
import ChannelMembersInfoMixin from "./ChannelMembersInfoMixin"

export default {
    name: "ChannelMembersInfo",
    extends: MembersInfo,
    mixins: [ChannelMembersInfoMixin],
    data() {
        return {
            _title: this.$t('chat.subs'),
            channel_members: [],
            _data: {},
            searchMembers: ''
        }
    },
    computed: {
        btns() {
            const btns = []
            if (this._is_admin) btns.push({
                text: this.$t('information.invite-sub'),
                class: 'fal fa-user-plus',
                cb: () => {
                    this.modalOpen({
                        component: SelectContactsToChannel,
                        props: {
                            chId: this.params.chId,
                            privilege: 'user',
                            cb: (newMembers) => {
                                newMembers.forEach(member => this.channel_members.push(member))
                            },
                        },
                    })
                },
            })
            return btns
        },
        _channel_data() {
            return this.$store.getters['channels/getChannel']({chId: this.params.chId}) || {}
        },
        _is_admin() {
            if (!this._channel_data) return false;
            return [
                declarations.userPrivilege.ADMIN,
                declarations.userPrivilege.OWNER
            ].indexOf(this._channel_data.privilege) !== -1 && this._channel_data.status === declarations.channel_user_statuses.JOINED;
        },
        admin_members() { 
            return this._all_members.filter(m => m.privilege === declarations.userPrivilege.ADMIN || m.privilege === declarations.userPrivilege.OWNER)
        },
        ownerCid() {
            let owner = this._all_members.find(m => m.privilege === declarations.userPrivilege.OWNER)
            return owner && owner.cid
        },
        _all_members() {
            const info = this.$t('information')
            return this.channel_members.map((member) => {
                const data = { cid: member.cid, privilege: member.privilege }
                if (member.status === declarations.channel_user_statuses.INVITED) {
                    data.text = info.invitation
                } else if (member.status === declarations.channel_user_statuses.BANNED) {
                    data.text = info.blocked
                }
                return data
            })
        }
    },
    methods: {
        _buildMenu(cid) {
            const mainPage = this.$t('mainPage') 
            const info = this.$t('information')
            const handlers = []
            handlers.push({item_name: mainPage['contact-info'], handler: this.doOpenContactInfo, data: cid})
            if (cid !== this.uid)  {
                handlers.push({item_name: this.$t('information.message'), handler: this.openChat, data: cid})
                if (this._is_admin && cid !== this.ownerCid) {
                    const isParticipantAdmin = this.admin_members.find(member => member.cid === cid)
                    if (!isParticipantAdmin) {
                        handlers.push({item_name: info['promote-to-adm'], handler: this._setMemberUserPrivilege, data: {cid, privilege: declarations.channel_user_privilege.ADMIN}})
                    } else {
                        handlers.push({item_name: info['demote-admin'], handler: this._setMemberUserPrivilege, data: {cid, privilege: declarations.channel_user_privilege.USER}})
                    }
                    handlers.push({item_name: info['remove-member'], handler: this._delMember, data: cid})
                }
            }
            return handlers
        },
        async refreshMembers() {
            const contacts = await this.$store.dispatch('channels/getChannelUsers', { chId: this.params.chId })
            this.channel_members = contacts
        },
        openChat(cid) {
            const payload = {cid, cidType: 'user', isBot: false}
            this.$store.dispatch('chats/open', payload)
        },
    },
    created() {
        this.$bus.$on('info-contacts-search', (data) => {
            this.searchMembers = data
        })
    },
    mounted() {
        this.refreshMembers()
    }
}
