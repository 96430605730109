var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "call-list",
      on: {
        "!contextmenu": function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _c("h4", [_vm._v(_vm._s(_vm.$t("calls")))]),
        _c("div", { staticClass: "header-btns" }, [
          _vm.showDialerBtn
            ? _c(
                "div",
                {
                  staticClass: "circle-animation dialer-link",
                  class: {
                    disabled: !_vm.isCallAllowed || _vm.isDisabledDirectCall
                  },
                  attrs: {
                    id: "dialer-link",
                    title: _vm.isDisabledDirectCall
                      ? _vm.$t("calls-prohibited")
                      : _vm.$t("dialer")
                  },
                  on: {
                    click: function($event) {
                      !_vm.isDisabledDirectCall && _vm.isCallAllowed
                        ? _vm.doOpenDialer()
                        : ""
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      style: _vm.fillColor,
                      attrs: { width: "30px", height: "35px" }
                    },
                    [
                      _c("circle", { attrs: { cx: "6", cy: "5", r: "2" } }),
                      _c("circle", { attrs: { cx: "6", cy: "13", r: "2" } }),
                      _c("circle", { attrs: { cx: "6", cy: "21", r: "2" } }),
                      _c("circle", { attrs: { cx: "14", cy: "5", r: "2" } }),
                      _c("circle", { attrs: { cx: "14", cy: "13", r: "2" } }),
                      _c("circle", { attrs: { cx: "14", cy: "21", r: "2" } }),
                      _c("circle", { attrs: { cx: "22", cy: "5", r: "2" } }),
                      _c("circle", { attrs: { cx: "22", cy: "13", r: "2" } }),
                      _c("circle", { attrs: { cx: "22", cy: "21", r: "2" } }),
                      _c("circle", { attrs: { cx: "14", cy: "29", r: "2" } })
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm.conferenceBtnShow
            ? _c(
                "div",
                {
                  staticClass: "circle-animation conference",
                  class: { disabled: _vm.isDisabledDirectCall },
                  attrs: {
                    title: _vm.isDisabledDirectCall
                      ? _vm.$t("calls-prohibited")
                      : _vm.$t("videomost.menu-title")
                  },
                  on: {
                    click: function($event) {
                      !_vm.isDisabledDirectCall ? _vm.onConference() : ""
                    }
                  }
                },
                [_c("i", { staticClass: "fal fa-video" })]
              )
            : _vm._e()
        ])
      ]),
      _c(
        "div",
        { staticClass: "side-header" },
        [_vm.side_notification ? _c("side-notification-bar") : _vm._e()],
        1
      ),
      _c("div", { staticClass: "side-body" }, [
        _c("div", { staticClass: "side-list" }, [
          _c(
            "ul",
            { staticClass: "list" },
            _vm._l(_vm.calls, function(call) {
              return _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "side-list-item call-list-item",
                    on: {
                      click: function($event) {
                        return _vm.watchCalls($event, call)
                      },
                      contextmenu: function($event) {
                        return _vm.doContextMenu($event, call)
                      }
                    }
                  },
                  [
                    _c("icon-image", {
                      attrs: { src: call.photo, text: call.fio }
                    }),
                    _c("div", { staticClass: "info-block" }, [
                      _c(
                        "div",
                        {
                          staticClass: "fio",
                          attrs: {
                            title: _vm.isShowFioTitle ? _vm.fio(call) : ""
                          },
                          on: {
                            mouseover: function($event) {
                              return _vm.mouseOverElement($event, "fio")
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.fio(call)))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "type",
                          on: {
                            mouseover: function($event) {
                              return _vm.mouseOverElement($event, "type")
                            }
                          }
                        },
                        [
                          call.class === "FL-video"
                            ? [
                                call.type === "out"
                                  ? _c("img", {
                                      attrs: {
                                        src: "img/outgoing-video-call.png"
                                      }
                                    })
                                  : call.type === "in"
                                  ? _c("img", {
                                      attrs: {
                                        src: call.duration
                                          ? "img/incoming-video-call.png"
                                          : "img/incoming-video-call-red.png"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            : [
                                call.type === "out"
                                  ? _c("img", {
                                      staticClass: "phone-img",
                                      attrs: { src: "img/outgoing_call.png" }
                                    })
                                  : call.type === "in"
                                  ? _c("img", {
                                      class: _vm.incommingCallMissedClass,
                                      attrs: {
                                        src: call.duration
                                          ? "img/incoming_call.png"
                                          : "img/incoming_call_red.png"
                                      }
                                    })
                                  : _vm._e()
                              ],
                          !(call.cid || call.foundCid)
                            ? _c(
                                "span",
                                {
                                  class: {
                                    redFont:
                                      call.type === "in" && !call.duration
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("unknown")))]
                              )
                            : call.class === "FL"
                            ? _c(
                                "span",
                                {
                                  class: {
                                    redFont:
                                      call.type === "in" && !call.duration
                                  },
                                  attrs: {
                                    title: _vm.isShowTypeTitle
                                      ? _vm.$t("mainPage.voice-call")
                                      : ""
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("mainPage.voice-call")))]
                              )
                            : call.class === "FL-video"
                            ? _c(
                                "span",
                                {
                                  class: {
                                    redFont:
                                      call.type === "in" && !call.duration
                                  },
                                  attrs: {
                                    title: _vm.isShowTypeTitle
                                      ? _vm.$t("mainPage.video-call")
                                      : ""
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("mainPage.video-call")))]
                              )
                            : call.number
                            ? _c(
                                "span",
                                {
                                  class: {
                                    redFont:
                                      call.type === "in" && !call.duration
                                  }
                                },
                                [_vm._v(_vm._s(call.number))]
                              )
                            : _vm._e(),
                          call.count > 1
                            ? _c(
                                "span",
                                {
                                  class: {
                                    redFont:
                                      call.type === "in" && !call.duration
                                  }
                                },
                                [_vm._v("(" + _vm._s(call.count) + ")")]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ]),
                    _c("div", { staticClass: "right-section" }, [
                      _c("div", { staticClass: "time" }, [
                        _vm._v(_vm._s(call.date))
                      ]),
                      _c("div", { staticClass: "count" }, [
                        !call.status && call.unwatchedcids
                          ? _c("span", { class: { whiteFont: call.status } }, [
                              _vm._v(_vm._s(call.unwatchedcids.length))
                            ])
                          : _vm._e()
                      ])
                    ])
                  ],
                  1
                ),
                _c("div", { staticClass: "hor-line light-line" })
              ])
            }),
            0
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }