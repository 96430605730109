//
//
//
//
//
//
//
//

export default {
    name: 'slider',
    props: ['outher_progress', 'active'],
    data() {
        return {
            progress: this.outher_progress || 0,
            progresss: 0,
            thump_active: false
        }
    },
    computed: {
        is_active() {
            return this.active;
        }
    },
    watch: {
        outher_progress(val) {
            this.$set(this, 'progress', Math.min(val, 100))
        },
        progresss(val) {
            this.$emit('progress_change', val)
        }
    },
    methods: {
        onProgressClick(e) {
            let self = this;
            if (!self.is_active) return;
            self._changeProgressVal(e);
        },
        onThumbMouseDown(e) {
            let self = this;
            self.$set(self, 'thump_active', true);
            document.body.addEventListener('mouseup', onMouseUp);
            document.body.addEventListener('mousemove', onMouseMove);
            document.body.addEventListener('mouseleave', onMouseOut);

            function onMouseUp(e) {
                self._changeProgressVal(e);
                self.$set(self, 'thump_active', false);
                removeListeners();
            }

            function onMouseMove(e) {
                if (!self.thump_active) return removeListeners();
                self._changeProgressVal(e);
            }

            function onMouseOut(e) {
                if (!self.thump_active) return removeListeners();
                self._changeProgressVal(e);
            }

            function removeListeners() {
                document.body.removeEventListener('mouseup', onMouseUp);
                document.body.removeEventListener('mousemove', onMouseMove);
                document.body.removeEventListener('mouseout', onMouseOut);
            }
        },
        _changeProgressVal(e) {
            let self = this,
                el = self.$el,
                el_x = el.getBoundingClientRect().left,
                event_x = e.clientX,
                width = el.clientWidth,
                progress = (event_x - el_x) / (width / 100);
            if (progress > 100) progress = 100;
            if (progress < 0) progress = 0;
            self.$set(self, 'progresss', progress);
            self.$set(self, 'progress', progress);
        }
    }
}
