var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "central decoration" },
    [
      _c("main-content-container", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "items-list" },
                  [
                    _c(
                      "list-item",
                      {
                        attrs: { title: _vm.$t("settings.theme-system") },
                        nativeOn: { click: function($event) {} }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "item-switch",
                            attrs: { slot: "after" },
                            slot: "after"
                          },
                          [
                            _c("custom-checkbox-slider", {
                              attrs: {
                                checkbox: _vm.isSystemTheme,
                                default: _vm.isSystemTheme,
                                readonly: _vm.isSystemTheme
                              },
                              on: {
                                "update:checkbox": function($event) {
                                  _vm.isSystemTheme = $event
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "list-item",
                      {
                        attrs: { title: _vm.$t("settings.theme-light") },
                        nativeOn: { click: function($event) {} }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "item-switch",
                            attrs: { slot: "after" },
                            slot: "after"
                          },
                          [
                            _c("custom-checkbox-slider", {
                              attrs: {
                                checkbox: _vm.isLightTheme,
                                default: _vm.isLightTheme
                              },
                              on: {
                                "update:checkbox": function($event) {
                                  _vm.isLightTheme = $event
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "list-item",
                      {
                        attrs: { title: _vm.$t("settings.theme-dark") },
                        nativeOn: { click: function($event) {} }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "item-switch",
                            attrs: { slot: "after" },
                            slot: "after"
                          },
                          [
                            _c("custom-checkbox-slider", {
                              attrs: {
                                checkbox: _vm.isDarkTheme,
                                default: _vm.isDarkTheme
                              },
                              on: {
                                "update:checkbox": function($event) {
                                  _vm.isDarkTheme = $event
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }