var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "publication-video-message" }, [
    _c("div", { staticClass: "preview-block" }, [
      _vm.isPending
        ? _c("div", { staticClass: "pending" }, [
            _c(
              "div",
              {
                staticClass: "upload-abort-btn message-btn",
                on: { click: _vm.abortAjaxCall }
              },
              [
                _c("progress-circle", {
                  attrs: {
                    load_progress: _vm.loadProgress,
                    radius: 18,
                    width: 48,
                    height: 48
                  }
                }),
                _c("i", { staticClass: "fa fa-ban" })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "img-wrapper",
          class: { "img-wrapper-slider-cont": _vm.images > 1 },
          on: { click: _vm.doShowViewer }
        },
        [
          _c("img", {
            class: { "img-wrapper-slider": _vm.images > 1 },
            attrs: { src: _vm.src, alt: "" }
          }),
          _vm._m(0)
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message-btn-wrapper" }, [
      _c("div", { staticClass: "message-btn" }, [
        _c("i", { staticClass: "fa fa-play" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }