//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict'
import DatePicker from 'vue2-datepicker'
import moment from 'moment'

export default {
    name: 'date-range-picker',
    props:  {
        custom_type: {
            type: String,
            required: false,
            default: 'datetime'
        },        
        default_from: {
            type: String,
            required: false,
            default: ''
        },
        default_to: {
            type: String,
            required: false,
            default: ''
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        required_from: {
            type: Boolean,
            required: false,
            default: false
        },
        required_to: {
            type: Boolean,
            required: false,
            default: false
        },
        forceUpdate: {
            type: Boolean,
            required: false,
            default: false
        },
        minute_step: {
            type: Number,
            required: false,
            default: 5
        },
        popUpStyleFrom: {
            type: Object,
            required: false
        },
        popUpStyleTo: {
            type: Object,
            required: false
        },
        date_format: {
            type: String,
            required: false,
            default: 'DD.MM.YYYY HH:mm'
        }         
    },
    components: {
        datepicker: DatePicker
    },
    mounted() {
        if (this.default_from) {
            this.from = this.default_from
        } 
        if (this.default_to) {
            this.to = this.default_to
        }
    },
    data() {
        return {
            input_required: {
                from: false,
                to: false
            },
           // date_format: 'DD.MM.YYYY HH:mm',
            from: '',
            to: ''
        }
    },
    watch: {
        from(val) {
            if (this.forceUpdate) this.$emit('update:from', this.from_unixtime)
        },
        to(val) {
            if (this.forceUpdate) this.$emit('update:to', this.to_unixtime)
        }
    },
    computed: {
        from_unixtime() {
            return moment(this.from, 'YYYY-MM-DD HH:mm:ss').unix();
        },
        to_unixtime() {
            return moment(this.to, 'YYYY-MM-DD HH:mm:ss').unix();
        },
        lang () {
            return this.$store.getters['clientdata/getLocale']
        }
    },
    methods: {
        reset() {
            this.from = '';
            this.to = '';
            this.required_from = false;
            this.required_to = false;
            this.clearFrom();
            this.clearTo();
        },
        fromClick() {
            this.$emit('fromClick')
        },
        toClick() {
            this.$emit('toClick')
        },
        doClickDatepickerFrom() {
            const el = document.querySelector('.date-picker-input-from');
            el.click();
        },
        doClickDatepickerTo() {
            const el = document.querySelector('.date-picker-input-to');
            el.click();
        },
        clearFrom() {
            this.$emit('update:from', '');
        },
        clearTo() {
            this.$emit('update:to', '');
        },
        setFrom() {
            this.$emit('update:from', this.from_unixtime)
            this.required_from = false
            this.input_required.from = false
        },
        setTo() {
            this.$emit('update:to', this.to_unixtime)
            this.required_to = false
            this.input_required.to = false
        },
    }
}
