var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-main-block" },
    [
      _vm.favorites
        ? _c("div", { staticClass: "fav-icon" }, [
            _vm.favActive
              ? _c("i", {
                  staticClass: "fas fa-star",
                  attrs: { title: _vm.$t("delete-from-fav") },
                  on: {
                    click: function($event) {
                      return _vm.onFavClick(false)
                    }
                  }
                })
              : _vm._e(),
            !_vm.favActive
              ? _c("i", {
                  staticClass: "fal fa-star",
                  attrs: { title: _vm.$t("add-to-fav") },
                  on: {
                    click: function($event) {
                      return _vm.onFavClick(true)
                    }
                  }
                })
              : _vm._e()
          ])
        : _vm._e(),
      _vm.edit && _vm.isAllowedToViewContact
        ? _c("div", { staticClass: "edit-btn" }, [
            _c("i", {
              staticClass: "fas fa-pencil-alt",
              on: { click: _vm.onEditBtnClick }
            })
          ])
        : _vm._e(),
      _c("icon-image", {
        ref: "",
        staticClass: "icon big",
        class: _vm.iconClass,
        attrs: { src: _vm.iconPhoto, text: _vm.iconText },
        on: { loaded: _vm.onImageLoaded },
        nativeOn: {
          click: function($event) {
            _vm.isAllowedToViewContact ? _vm.onIconClick() : ""
          }
        }
      }),
      _c("div", { staticClass: "info-block" }, [
        _c(
          "div",
          { staticClass: "common-info" },
          [
            _c("div", { staticClass: "common-text" }, [
              _c("div", { staticClass: "text-wrapper" }, [
                _c("div", {
                  staticClass: "text",
                  attrs: { title: _vm.commonTitle || _vm.commonText },
                  domProps: { innerHTML: _vm._s(_vm.commonText) },
                  on: { contextmenu: _vm.contextMenuFio }
                }),
                _vm.official
                  ? _c("div", { staticClass: "official" }, [
                      _c("i", { staticClass: "far fa-check" })
                    ])
                  : _vm._e()
              ]),
              !_vm.isAllowedToViewContact
                ? _c("i", { staticClass: "fal fa-lock-alt" })
                : _vm._e()
            ]),
            _vm._t("after-common-text"),
            _vm.additionalText
              ? _c("div", { staticClass: "additional-text" }, [
                  _c("p", [_vm._v(_vm._s(_vm.additionalText))])
                ])
              : _vm._e(),
            _vm._t("after-additional-text")
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }