
import { mapGetters, mapActions } from "vuex"
import { CHANNELS } from "../../store/modulesNames"
import { GET_CHANNEL_MUTE_TIME } from "../../store/gettersTypes"
import { ACT_CHANGE_CHANNEL_SETTINGS } from "../../store/actionsTypes"

import MuteSwitch from "./MuteSwitch.vue"

export default {
    name: "ChannelMuteSwitch",
    extends: MuteSwitch,
    props: {
        chId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        muteTime() {
            return this[GET_CHANNEL_MUTE_TIME]({chId: this.chId})
        },
        ...mapGetters(CHANNELS, [GET_CHANNEL_MUTE_TIME])
    },
    methods: {
        mute() {
            this.modalOpen({
                name: 'channel-mute',
                props: {
                    chId: this.chId,
                }
            })
        },
        unMute() {
            this[ACT_CHANGE_CHANNEL_SETTINGS]({chId: this.chId, mute: null})
        },
        ...mapActions(CHANNELS, [ACT_CHANGE_CHANNEL_SETTINGS])
    },
}
